const widgetCount = {
    "11": 1,
    "12": 2
}

const filterOptions = [
    { label: 'Status', value: 'status' },
    { label: 'User', value: 'user' }
]

const filterOptionsDefault = [
    { label: 'Status', value: 'status' },
    { label: 'User', value: 'user' }
]

const sourceOptions = [
    { label: 'Login', value: 'login' },
    { label: 'Booking', value: 'booking' },
    { label: 'Quote', value: 'quote' }
]

const dataRangeOptions = [
    { label: 'Last week', value: 'lastWeek' },
    { label: 'Last 14 days', value: 'last14Days' },
    { label: 'Last 30 days', value: 'last30Days' },
    { label: 'Last Month', value: 'lastMonth' },
    { label: 'Current Month', value: 'currentMonth' }
]

const rowOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' }
];

const coloumnOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
]

const widgetLayoutHeaders = [
    { 'width': '30%', 'title': 'No of Rows', 'field': 'rows', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'renderOption': 'label', 'titleprop': 'label', 'showBorder': true, 'options': rowOptions },
    { 'width': '40%', 'title': 'No of Columns', 'field': 'colomns', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'renderOption': 'label', 'titleprop': 'label', 'showBorder': true, 'options': coloumnOptions },
]

const groupByOptionsDefault = [
    { label: 'Status', value: "status" },
    { label: 'User', value: "user" }
]

const chartOptionsDefault = [
    { label: 'Pie', value: 'pie' },
    { label: 'Donut', value: 'donut' },
    { label: 'Funnel', value: 'funnel' },
]

export {
    widgetCount, filterOptions, sourceOptions, dataRangeOptions, rowOptions, coloumnOptions,
    widgetLayoutHeaders, filterOptionsDefault, groupByOptionsDefault, chartOptionsDefault
}
import React from 'react'
import { Card, CardContent, withStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl';

const style = theme => ({
    root: {
        minWidth: '275px',
        maxWidth: '100%',
        margin: '1% 0'
    },
    line: {
        margin: 0,
        display: 'flex'
    },
    title: {
        fontWeight: 'bold',
        width: '38%',
        display: 'flex',
        padding: '1%',
        borderRight: '1px solid #E5E8E8'
    },
    value: {
        width: '62%',
        display: 'inline-block',
        padding: '1%'
    }
});
function CardsTable(props) {
    const { classes, data, headers, onCardClick, intl } = props
    return (
        <div>
            {data && data.length > 0 ?
                data.map((el, index) => {
                    return (
                        <Card key={index} className={classes.root} variant="outlined" onClick={() => onCardClick(index)}>
                            <CardContent>
                                {
                                    headers.map((item, index) => {
                                        return (
                                            <div key={index} className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                                <div className={classes.title}> <span >{item.label}</span></div>
                                                <div className={classes.value}> <span >{el[item.key]}</span></div>
                                            </div>
                                        )
                                    })
                                }
                            </CardContent>
                        </Card>
                    )
                })
                :
                <div>
                    {data === null ?
                        <p>{intl.formatMessage({ id: 'data_Loading' })}</p> : <p>{intl.formatMessage({ id: 'noRecordsFound' })} </p>}</div>
            }

        </div>
    )
}

export default injectIntl(withStyles(style)(CardsTable))

import React from 'react';

const defaultCPContext = {
    weightUOM: [],
    piecesUOM: [],
    volumeUOM: [],
    commodityUOM:[],
    dimensionsUOM: [],
    moveTypes: [],
    countries: [],
    shippers: [],
    consignees: [],
    notifies:[],
    airOriginCityList:[],
    seaPolCityList:[],
    airDefaults:[],
    seaDefaults:[],
    roadDefaults:[],
    serviceRequests:{},
    userloggedIn: true,
    accessToQuotes: true,
    accessToBookings: true,
    accessToShipments: true,
    accessToBookingTemplates: true,
    accessToILWidget:true,
    referenceCode: ''
}

const { Provider: CPContextProvider, Consumer: CPContextConsumer } = React.createContext(defaultCPContext);

export { CPContextConsumer, CPContextProvider, defaultCPContext }
import React from 'react'
import { Card, CardContent, CardActions } from '@material-ui/core'
import CpInput from '../common/CpInput'
import { injectIntl } from 'react-intl'
import CpDropDown from '../common/CpDropDown'
import CPButton from '../common/Button'

function EditableContainer(props) {
    const { intl, data, onChange, onSave, onCancel, containerTypes } = props
    return (
        <Card>
            <CardActions style={{ justifyContent: 'center' }}>
                <CPButton variant="primary" disabled={false} onClick={() => onSave(data.id, "CONTAINER")}>{intl.formatMessage({ id: 'save' })}</CPButton>
                <CPButton variant="secondary" disabled={false} onClick={() => onCancel(data.id, "CONTAINER", data)}>{intl.formatMessage({ id: 'cancel' })}</CPButton>
            </CardActions>
            <CardContent>
                <div>
                    <CpDropDown id={data?.id + "type"} value={data?.type} name="type" label={intl.formatMessage({ id: 'type' })} items={containerTypes} onChange={(event) => onChange(data['id'], "type", event.target.value)} />
                </div>
                <div>
                    <CpInput id={data?.id + "qty"} name='qty' type="number" label={intl.formatMessage({ id: 'qty' })} value={data?.qty} onChange={(event) => onChange(data['id'], "qty", event.target.value)} />
                </div>

            </CardContent>
        </Card >
    )
}

export default injectIntl(EditableContainer)

import React from 'react'
import { Card, CardContent, CardActions } from '@material-ui/core'
import CpInput from '../common/CpInput'
import { injectIntl } from 'react-intl'
import CPButton from '../common/Button'
import withCPContext from '../common/withCPContext'
import CpTypeahead from '../common/CpTypeahead'

function EditableItem(props) {
    const { intl, data, onChange, onSave, onCancel, cpContext } = props
    const id = data.id ? data.id : data.itemUniqueRefNo
    return (
        <Card>
            <CardActions style={{ justifyContent: 'center' }}>
                <CPButton variant="primary" disabled={false} onClick={() => onSave(id, "ITEM")}>{intl.formatMessage({ id: 'save' })}</CPButton>
                <CPButton variant="secondary" disabled={false} onClick={() => onCancel(id, "ITEM", data)}>{intl.formatMessage({ id: 'cancel' })}</CPButton>
            </CardActions>
            <CardContent>
                <div>
                    <CpInput id={id ? id : data.itemUniqueRefNo + "description"} name='description' label={intl.formatMessage({ id: 'goodsDescription' })} value={data.description} onChange={(event) => onChange(id, "description", event.target.value)} />
                </div>
                <div style={{ display: 'flex' }}>
                    <CpInput id={id + "itemPcs"} name='itemPcs' type='number' label={intl.formatMessage({ id: 'pcs' })} value={data.itemPcs} onChange={(event) => onChange(id, "itemPcs", event.target.value)} width='60%' />
                    <CpTypeahead styles={{ marginTop: '6%' }} id={id + "itemPcsUt"} value={data.itemPcsUt} name="itemPcsUt" label={''} options={cpContext.piecesUOM} onChange={(event) => onChange(id, "itemPcsUt", event.value)} width='40%' renderOption="both" afterSelectionShow="value" />
                </div>
                <div style={{ display: 'flex' }}>
                    <CpInput id={id + "itemWgt"} name='itemWgt' type='number' label={intl.formatMessage({ id: 'weight' })} value={data.itemWgt} onChange={(event) => onChange(id, "itemWgt", event.target.value)} width='60%' />
                    <CpTypeahead styles={{ marginTop: '6%' }} id={id + "itemWgtUt"} value={data.itemWgtUt} name="itemWgtUt" label={''} options={cpContext.weightUOM} onChange={(event) => onChange(id, "itemWgtUt", event.value)} width='40%' renderOption="both" afterSelectionShow="value" />
                </div>
                <div style={{ display: 'flex' }}>
                    <CpInput id={id + "itemDimLength"} type='number' name='itemDimLength' label='Length' value={data.itemDimLength} onChange={(event) => onChange(id, "itemDimLength", event.target.value)} width='25%' />
                    <CpInput id={id + "itemDimWidth"} type='number' name='itemDimWidth' label='Width' value={data.itemDimWidth} onChange={(event) => onChange(id, "itemDimWidth", event.target.value)} width='25%' />
                    <CpInput id={id + "itemDimHeight"} type='number' name='itemDimHeight' label='Height' value={data.itemDimHeight} onChange={(event) => onChange(id, "itemDimHeight", event.target.value)} width='25%' />
                    <CpTypeahead styles={{ marginTop: '8%' }} id={id + "itemDimUnit"} value={data.itemDimUnit} name="itemDimUnit" label={''} options={cpContext.dimensionsUOM} onChange={(event) => onChange(id, "itemDimUnit", event.value)} width='25%' renderOption="both" afterSelectionShow="value" />
                </div>
                <div style={{ display: 'flex' }}>
                    <CpInput id={id + "itemVol"} type='number' name='itemVol' label={intl.formatMessage({ id: 'volume' })} value={data.itemVol} onChange={(event) => onChange(id, "itemVol", event.target.value)} width='60%' />
                    <CpTypeahead styles={{ marginTop: '6%' }} id={id + "itemVolUt"} value={data.itemVolUt} name="itemVolUt" label={''} options={cpContext.volumeUOM} onChange={(event) => onChange(id, "itemVolUt", event.value)} width='40%' renderOption="both" afterSelectionShow="value" />
                </div>
            </CardContent>
        </Card >
    )
}

export default injectIntl(withCPContext(EditableItem))

import React from 'react'
import { injectIntl } from 'react-intl';
import { Divider, IconButton, withStyles } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import CPButton from '../../common/Button'
import Box from '../../common/Box';
import CpEditableTable from '../../common/CpEditableTable';
import { ReactComponent as PushPinIcon } from '../../../images/pushpin.svg'
import DetailedMileStoneData from './DetailedMileStoneData';
import ItemsMobileView from '../../mobileComponents/ItemsMobileView';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';
import CloseIcon from '@material-ui/icons/Close';

const styles = ({ breakpoints, palette }) => ({
    heading: {
        fontSize: '24px',
        fontWeight: 700
    },
    sectionDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'block',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    header: {
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#E8F0F4'
    },
    headingBox: {
        display: 'flex',
        alignItems: 'center'
    },
    pushIcon: {
        marginBottom: '0',
        marginRight: '0.5rem'
    },
    divider: {
        margin: '0.5rem 1rem',
        backgroundColor: '#979797'
    },
})

function ContainerDetails(props) {

    const containerHeaders = [
        { title: "Container No.", field: "containerNo" },
        { title: "Type", field: "containerType" },
        { title: "Seal.", field: "containerSealNo1" },
        { title: "Pieces", field: "totPcs" },
        { title: "Weight", field: "totWgt" },
        { title: "Total", field: "totGrossWgt" }]

    const { classes, intl, contianerData, handlePinOrUnPin, handleDrawer } = props

    const containerItemheaders = [
        { 'width': '20%', 'title': intl.formatMessage({ id: 'goodsDescription' }), 'field': 'ctnItemCargoDescription', 'showLabel': true, 'type': 'label' },
        { 'width': '5%', 'title': intl.formatMessage({ id: 'pcs' }), 'field': 'ctnItemTotPcs', 'showLabel': true, 'type': 'label', 'showBorder': false },
        { 'width': '5%', 'title': 'UOM', 'field': 'ctnItemTotPcsUt', 'showLabel': false, 'type': 'label' },
        { 'width': '5%', 'title': intl.formatMessage({ id: 'netWeight' }), 'field': 'ctnItemTotWgt', 'showLabel': true, 'type': 'label', 'showBorder': false },
        { 'width': '5%', 'title': 'Weightmesure', 'field': 'ctnItemTotWgtUt', 'showLabel': false, 'type': 'label', 'showBorder': true },
        { 'width': '5%', 'title': intl.formatMessage({ id: 'volume' }), 'field': 'ctnItemTotVol', 'showLabel': true, 'type': 'label', 'showBorder': false },
        { 'width': '5%', 'title': 'Volumemesure', 'field': 'ctnItemTotVolUt', 'showLabel': false, 'type': 'label', 'showBorder': true },
    ]
    return (
        <div id='containerDetails' >
            <div className={classes.header}>
                <div className={classes.headingBox}>
                    <CPButton variant="secondary" id='containerPinorUnpin' onClick={() => handlePinOrUnPin(contianerData.intContainerNo, 'container', !contianerData.favoriteCont)} >
                        {contianerData.favoriteCont ?
                            <>
                                <CloseIcon className={classes.pushIcon} />
                                <span>
                                    Unpin
                                </span>
                            </>
                            :
                            <>
                                <PushPinIcon className={classes.pushIcon} />
                                <span>
                                    Pin
                                </span>
                            </>
                        }
                    </CPButton>
                    <Divider orientation="vertical" flexItem className={classes.divider} />
                    <span className={classes.heading} > {intl.formatMessage({ id: 'container' })} </span>
                </div>
                <div>
                    <CPButton id='cntClose' disabled={false} variant='secondary' styles={{ float: 'right', }} onClick={handleDrawer}>{intl.formatMessage({ id: 'close' })}</CPButton>
                    <a href={`${window.location.protocol + '//' + window.location.host}/webHelp/cp-container-details.html`} target="_blank" rel="noopener noreferrer" style={{ color: "inherit", float: "right" }}>
                        <IconButton style={{ padding: '5px' }}
                            aria-label="account of current user"
                            aria-controls="primary-search-account-menu"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <HelpIcon style={{ fontSize: '28px', color: "#6E787D" }} />
                        </IconButton>
                    </a>
                </div>
            </div>
            <div>
                <div style={{ margin: '1.5%' }}>
                    <NonEditableDataGrid list={containerHeaders} data={contianerData ? contianerData : {}} />
                </div>
                <Box header={intl.formatMessage({ id: 'items' })}>
                    <div className={classes.sectionDesktop}>
                        <CpEditableTable headers={containerItemheaders} readOnly={true} data={contianerData && contianerData.containerItemList ? contianerData.containerItemList : []} />
                    </div>
                    <div className={classes.sectionMobile}>
                        <ItemsMobileView data={contianerData && contianerData.containerItemList ? contianerData.containerItemList : null} readOnly={true} hideDimensions={true} />
                    </div>
                </Box>
                <Box header={intl.formatMessage({ id: 'milestones' })}>
                    <DetailedMileStoneData data={contianerData && contianerData.containerStatusList ? contianerData.containerStatusList : null} type="milestones" />
                </Box>
            </div>
        </div>
    )
}

export default injectIntl(withStyles(styles)(ContainerDetails))
import React from 'react'
import { injectIntl } from 'react-intl';
import CpEditableTable from '../../common/CpEditableTable';
import { Link, Typography, withStyles } from '@material-ui/core';
import PageNumbers from '../../common/Pagination/PageNumbers';
import Box from '../../common/Box';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';
import CpBreadcrumb from '../../common/CpBreadcrumb';

const styles = theme => ({
    header: {
        fontSize: '14px',
        fontWeight: 'bold !important',
        paddingLeft: '5px',
        paddingTop: '10px',
        display: 'flex !important',
        color: '#000000'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
})

function CommercialInvoiceItem(props) {
    const invoiceHeaders = [
        { title: "Invoice", field: "ciNo" },
        { title: "Value", field: "valueForeignAmt" },
        { title: "C/O", field: "countryOrigin" },
        { title: "Weight", field: "weightGross" },
        { title: "Charges", field: "charges" },
        { title: "Cust.Ref.", field: "custRef" },
        { title: "Inv.Lines", field: "ciNoLines" }]

    const { intl, classes, handleDisplayForward, handleDisplayBack, invoiceItemsData, noOfPages, selectedPage, handlePageClick, totalRecords, selectedRecord } = props

    const linesHeaders = [
        { 'width': '15%', 'title': intl.formatMessage({ id: 'cp_line_no' }), 'field': 'ciLineNo', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'Ctry_origin' }), 'field': 'countryOrigin', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': intl.formatMessage({ id: 'value' }), 'field': 'valueForeignAmt', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': intl.formatMessage({ id: 'weight' }), 'field': 'weight', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': intl.formatMessage({ id: 'charges' }), 'field': 'charges', 'showLabel': true, 'type': 'label' },

    ]

    return (
        <div>
            <div style={{ display: 'flex', margin: '2rem 0 0 2rem' }}>
                <CpBreadcrumb>
                    <Link color='inherit' classes={{ underlineHover: classes.underline }} href="#" onClick={() => handleDisplayBack(0)}>{intl.formatMessage({ id: 'commercial_invoice_items_caps' })}</Link>
                    <Typography color='textPrimary' >{intl.formatMessage({ id: 'commercial_invoice_item_caps' }, { val1: selectedRecord, val2: totalRecords })}</Typography>
                </CpBreadcrumb>
            </div>
            <Box showloading={true} header={intl.formatMessage({ id: 'commercial_invoice_item_caps' }, { val1: selectedRecord, val2: totalRecords })} >
                <NonEditableDataGrid list={invoiceHeaders} data={props.invoiceData ? props.invoiceData : {}} />
            </Box>
            <Box header="Lines">
                <CpEditableTable headers={linesHeaders} readOnly={true} data={invoiceItemsData ? invoiceItemsData.data : []} navigateIcon={true} handleDisplayForward={(row, index) => handleDisplayForward(2, row, index)} />
                <PageNumbers noOfPages={noOfPages} selected={selectedPage} onClick={(index) => handlePageClick(index, 1)} />
            </Box>
        </div>
    )
}

export default injectIntl(withStyles(styles)(CommercialInvoiceItem))
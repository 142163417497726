import moment from 'moment';
import { processDate } from './utils'


export const getBookingData = (data, cpContext) => {
    const bizType = data ? data.bizType : ''
    const shipmentType = data ? data.shipmentType : ''
    const remarksData = data && data.remarkList ? data.remarkList : []
    const specialReqData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === 'CPRMKTYPE' }) : {}
    const carrierData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "PC" }) : {}
    const pickupRemarksData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "CPPURMK" }) : {}
    const deliveryRemarksData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "CPDLRMK" }) : {}
    const rejectReasonData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "CPREJRMK" }) : {}
    const cpSupUser = JSON.parse(sessionStorage.getItem('isCpSupUser'));
    const rejectFields = data?.status === "Rejected" ? [
            { title: "Rejection Reason", field: "rejectReason" },
            { title: "Rejected by", field: "rejectedBy" }
        ] : [];

    const getFullAddress =(obj)=>{
        const fullName =obj?.fullName ?? "";
        const addr1= obj?.addr1 ?? "";
        const addr2 = obj?.addr2 ?? "";
        const addr3 = obj?.addr3 ?? "";
        const addr4 = obj?.addr4 ?? "";

        return `${fullName}${addr1}${addr2}${addr3}${addr4}`
    }
    
    return {
        unId: data ? data.unId : null,
        readOnly: data ? data.status ? data.status !== 'In Progress' ? true : false : false : false,
        details: {
            status: data ? data.status ? data.status : 'In Progress' : 'In Progress',
            shipper: data ? data.shipper : '',
            shipperName: data ? data.shipperName : '',
            consignee: data ? data.consignee : '',
            consigneeName: data ? data.consigneeName : '',
            notify : data?.notify ?? '',
            notifyName : data?.notifyName ?? '',
            shipmentType: data ? data.shipmentType : '',
            moveType: data ? data.moveType : '',
            specialRqs: specialReqData && specialReqData.remarkText ? specialReqData.remarkText : '',
            rejectReason: rejectReasonData && rejectReasonData.remarkText ? rejectReasonData.remarkText : '',
            bizType: data ? data.bizType : '',
            pieces: data && data.bkPcs ? data.bkPcs+(data && data.bkPcsUt ? data.bkPcsUt : "") : "",
            volume: data && data.bkgVol ? data.bkgVol+(data && data.bkgVolUt ? data.bkgVolUt : "") : "",
            weight: data && data.bkgWgt ? data.bkgWgt+(data && data.bkgWgtUt ? data.bkgWgtUt : "") : "",
            totalPcs: data ? data.bkPcs : "",
            totalVol: data ? data.bkgVol : "",
            totalPcsUt: data ? data.bkPcsUt : "",
            totalVolUt: data && data.bkgVolUt ? data.bkgVolUt : "",
            totalWgt: data ? data.bkgWgt : "",
            totalWgtUt: data ? data.bkgWgtUt : "",
            destCity: data && data.destCity ? data.destCity : "",
            destCityName: data ? data.destCityName : "",
            destCountry: data && data.destCountry ? data.destCountry : "",
            orgCity: data ? data.orgCity : "",
            orgCountry: data ? data.orgCountry : "",
            orgCityName: data ? data.orgCityName : "",
            polCountry: data ? data.polCtry : "",
            polCity: data ? data.polCity : "",
            polCityName: data ? data.polName : "",
            podCountry: data && data.podCtry ? data.podCtry : "",
            podCity: data && data.podCity ? data.podCity : "",
            podCityName: data ? data.podName : "",
            carrier: carrierData && carrierData.remarkText ? carrierData.remarkText : "",
            commodityCode : data?.commodityCode ?? "",
            commodity: data ? data.commodity : "",
            serviceRequest : data?.serviceRequest ?? [], 
            referenceNumber: data && data.referenceNumber ? data.referenceNumber : "",
            refCode: data && data.refCode ? data.refCode : (cpContext ? cpContext.referenceCode : ''),
            backupRefNo: data && data.referenceNumber ? data.referenceNumber : "",
            deliveryTerms: data ? data.deliveryTerms : "",
            pickupRequired: data && data.pickupInfo ? data.pickupInfo.required === 1 ? true : false : false,
            pickupAddress: data && data.pickupInfo && data.pickupInfo.collectFrom && data.pickupInfo.collectFrom.fullName ? data.pickupInfo.collectFrom.fullName : null,
            deliveryAddress: data && data.pickupInfo && data.pickupInfo.deliverTo && data.pickupInfo.deliverTo.fullName ? data.pickupInfo.deliverTo.fullName : null,
            collectFromObj: data && data.pickupInfo.collectFrom ? data.pickupInfo.collectFrom : {},
            pickupdelivertoObj: data && data.pickupInfo.deliverTo ? data.pickupInfo.deliverTo : {},
            addressTitle :
                {
                    collectFromObj       : getFullAddress(data?.pickupInfo?.collectFrom),
                    pickupdelivertoObj   : getFullAddress(data?.pickupInfo?.deliverTo),
                    deliverycollectfromObj: getFullAddress(data?.deliveryInfo?.collectFrom),
                    deliverydelivertoObj  : getFullAddress(data?.deliveryInfo?.deliverTo)
                },

            readyDate: (data?.pickupInfo.required === 1 || !!cpSupUser) ? data?.pickupInfo.readyDate || null : null,
            fromTime: (data?.pickupInfo.required === 1 || !!cpSupUser) ? data?.pickupInfo.fromTime ? moment(data.pickupInfo.fromTime, 'HH:mm') : null : null,
            toTime: (data?.pickupInfo.required === 1 || !!cpSupUser) ? data?.pickupInfo.toTime ? moment(data.pickupInfo.toTime, 'HH:mm') : null : null,
            pickupTime: (data?.pickupInfo.required === 1 || !!cpSupUser) ? data?.pickupInfo.fromTime ? processDate(data?.pickupInfo.fromTime, 'HH:mm')+' - '+((data && data.pickupInfo.required === 1 || !!cpSupUser)? data?.pickupInfo.toTime ? processDate(data.pickupInfo.toTime, 'HH:mm') : null : null) : null : null,
            pickupRemarks: data && data.pickupInfo.required === 1 && pickupRemarksData && pickupRemarksData.remarkText ? pickupRemarksData.remarkText : '',

            deliveryRequired: data && data.deliveryInfo ? data.deliveryInfo.required === 1 ? true : false : false,
            deliveryCollectAddress: data && data.deliveryInfo && data.deliveryInfo.collectFrom && data.deliveryInfo.collectFrom.fullName ? data.deliveryInfo.collectFrom.fullName : null,
            deliveryDeliveryAddress: data && data.deliveryInfo && data.deliveryInfo.deliverTo && data.deliveryInfo.deliverTo.fullName ? data.deliveryInfo.deliverTo.fullName : null,
            deliverycollectfromObj: data && data.deliveryInfo.collectFrom ? data.deliveryInfo.collectFrom : {},
            deliverydelivertoObj: data && data.deliveryInfo.deliverTo ? data.deliveryInfo.deliverTo : {},
            deliveryreadyDate:(data?.deliveryInfo.required === 1 || !!cpSupUser) ? data?.deliveryInfo.readyDate ? data.deliveryInfo.readyDate : null : null,
            deliveryfromTime: (data?.deliveryInfo.required === 1 || !!cpSupUser ) ? data?.deliveryInfo.fromTime ? moment(data.deliveryInfo.fromTime, 'HH:mm') : null : null,
            deliverytoTime: (data?.deliveryInfo.required === 1 || !!cpSupUser)? data?.deliveryInfo.toTime ? moment(data.deliveryInfo.toTime, 'HH:mm') : null : null,
            deliveryTime: (data?.deliveryInfo.required === 1 || !!cpSupUser) ? data?.deliveryInfo.fromTime ? processDate(data.deliveryInfo.fromTime, 'HH:mm')+' - '+((data && data.deliveryInfo.required === 1 || !!cpSupUser) ? data?.deliveryInfo.toTime ? processDate(data.deliveryInfo.toTime, 'HH:mm') : null : null) : null : null,
            deliveryRemarks: data && data.deliveryInfo.required === 1 && deliveryRemarksData && deliveryRemarksData.remarkText ? deliveryRemarksData.remarkText : '',
            enableShipperAddress: false,
            enableConsigneeAddress: false,
            enableNotifyAddress: false,
            shipperAddress: {
                addr1: data ? data.shprAddr1 : '',
                addr2: data ? data.shprAddr2 : '',
                addr3: data ? data.shprAddr3 : '',
                addr4: data ? data.shprAddr4 : ''
            },
            consigneeAddress: {
                addr1: data ? data.csgnAddr1 : '',
                addr2: data ? data.csgnAddr2 : '',
                addr3: data ? data.csgnAddr3 : '',
                addr4: data ? data.csgnAddr4 : ''
            },
            notifyAddress: {
                addr1: data?.ntfyAddr1 ?? '',
                addr2: data?.ntfyAddr2 ?? '',
                addr3: data?.ntfyAddr3 ?? '',
                addr4: data?.ntfyAddr4 ?? ''
            },
            readOnlyShipperAddress: data && data.shprAddr1 ? data.shprAddr1+(data && data.shprAddr2 ? ','+data.shprAddr2 : '')+(data?.shprAddr3 ? ','+data.shprAddr3 : '')+(data?.shprAddr4 ? ','+data.shprAddr4 : '') : "",
            readOnlyConsigneeAddress: data && data.csgnAddr1 ? data.csgnAddr1+(data && data.csgnAddr2 ? ','+data.csgnAddr2 : '')+(data && data.csgnAddr3 ? ','+data.csgnAddr3 : '')+(data && data.csgnAddr4 ? ','+data.csgnAddr4 : '') : '',
            readOnlyNotifyAddress: data?.ntfyAddr1 ? data?.ntfyAddr1 +(data?.ntfyAddr2 ? ',' +data.ntfyAddr2:'')+(data?.ntfyAddr3 ? ',' +data?.ntfyAddr3:'')+(data?.ntfyAddr4 ? ',' +data.ntfyAddr4:''):'' ,
            shipperDefaultAddress: {},
            consigneeDefaultAddress: {},
            notifyDefaultAddress:{},
            hblAwbNo: data && data.hblAwbNo ? data.hblAwbNo : ''
        },
        shipperAddressList: [],
        consigneeAddressList: [],
        notifyAddressList:[],
        funcCode: data ? 5 : 9,
        jobStageCode: data ? data.jobStageCode : 'P',
        containerslist: data && (bizType === 'SE' || bizType === 'SI') && shipmentType === 'FCL' ? data.containers ? data.containers : [] : [],
        vehiclesContainersList: data && bizType === 'IL' && shipmentType === 'FTL' ? data.vehicles ? data.vehicles : [] :[],
        selectedContainer: null,
        itemslist: data ? data.cargoItems ? data.cargoItems : [] : [],
        selectedItem: null,
        documents: data ? data.documents ? data.documents : [] : [],
        auditTrail: {
            createdDate: (data && data.auditTrail && data.auditTrail.createdDate) ? processDate(data.auditTrail.createdDate, 'dateTime') : "",
            updatedDate: (data && data.auditTrail && data.auditTrail.lastUpdatedDate) ? processDate(data.auditTrail.lastUpdatedDate, 'dateTime') : "",
            submittedDate: (data && data.auditTrail && data.auditTrail.submittedDate) ? processDate(data.auditTrail.submittedDate, 'dateTime') : "",
            acceptOrRejectDate: (data && data.auditTrail && data.auditTrail.acceptOrRejectDate) ? processDate(data.auditTrail.acceptOrRejectDate, 'dateTime') : "",
            bookedDate: (data && data.auditTrail && data.auditTrail.bookedDate) ? processDate(data.auditTrail.bookedDate, 'dateTime') : "",
            rejectedBy: rejectReasonData?.remarkCreateBy ?? "",
            rejectReason: rejectReasonData?.remarkText ?? ""
        },
        auditTrailList: [
            { title: "Created", field: "createdDate" },
            { title: "Last Updated", field: "updatedDate" },
            { title: "Submitted", field: "submittedDate" },
            ...rejectFields
        ],
        error: undefined,
        errordetails: {
            shipper: '',
            bizType: '',
            totalPcs: '',
            totalWgt: '',
            destCity: "",
            destCountry: '',
            podCountry: '',
            podCity: '',
            polCountry:'',
            polCity:'',
            orgCountry:'',
            orgCity:'',
            readyDate:'',
            deliveryreadyDate:'',
            totalPcsUt: "",
            totalWgtUt: "",
            ready: '',
            containerlist:'',
            container:'',
            pickup_timeValidationErrorMessage: '',
            delivery_timeValidationErrorMessage: ''
            //items: ''
        },
        enableIcons: false,
        disableButtons: false,
        anchorEl: null,
        message: null,
        openpopUp: false,
        showConfirm: false,
        showPickupConfirm: false,
        showDeliveryConfirm: false,
        sideHeaders: [
            { 'name': 'Details', 'selected': 'true', 'isDisabled': false },
            { 'name': 'Containers', 'selected': 'false', 'isDisabled': ((bizType === 'SE' || bizType === 'SI') && shipmentType === 'FCL') ? false : true },
            { 'name': 'Load Unit', 'selected': 'false', 'isDisabled': (bizType === 'IL' && shipmentType === 'FTL') ? false : true },
            { 'name': 'Items', 'selected': 'false', 'isDisabled': false },
            { 'name': 'Documents', 'selected': 'false', 'isDisabled': false },
            { 'name': 'Audit Trail', 'selected': 'false', 'isDisabled': false }
        ],
        addresses: [],
        popOverField: '',
        docTypes: [],
        templateName: data && data.templateName ? data.templateName : '',
        templateNo: data && data.templateNo ? data.templateNo : '',
        templateunId: data && data.templateunId ? data.templateunId : '',
        templateLoading: false
    }
}
export const getQuoteData = (data, cpContext) => {
    const bizType = data ? data.bizType : ''
    const shipmentType = data ? data.loadTerm : ''
    const status = data ? data.status : 'In Progress'
    const disableQuote = (status === "Awaiting Acceptance" || status === "Accepted" || status === "Rejected" || status === "Booked") ? false : true
    const remarksData = data && data.remarkList ? data.remarkList : []
    const specialReqData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === 'CPRMKTYPE' }) : {}
    const carrierData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "PC" }) : {}
    const pickupRemarksData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "CPPURMK" }) : {}
    const deliveryRemarksData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "CPDLRMK" }) : {}
    const rejectReasonData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "CPREJRMK" }) : {}

    return {
        unId: data ? data.unId : null,
        readOnly: data ? data.status ? data.status !== 'In Progress' ? true : false : false : false,
        details: {
            status: data ? data.status : 'In Progress',
            rejectedBy: data ? data.rejectedBy : '',
            shipper: data ? data.partyIdShpr : '',
            shipperName: data ? data.shprName : '',
            consignee: data ? data.partyIdCsgn : '',
            consigneeName: data ? data.csgnName : '',
            notify: data?.partyIdNtfy ?? '',
            notifyName : data?.ntfyName ?? '',
            shipmentType: data ? data.loadTerm : '',
            moveType: data ? data.moveType : '',
            specialRqs: specialReqData && specialReqData.remarkText ? specialReqData.remarkText : '',
            bizType: data ? data.bizType : '',
            pieces: data && data.totPcs ? data.totPcs+(data && data.totPcsUt ? data.totPcsUt : "") : "",
            volume: data && data.totVol ? data.totVol+(data && data.totVolUt ? data.totVolUt : "") : "",
            weight: data && data.totgWgt ? data.totgWgt+(data && data.totgWgtUt ? data.totgWgtUt : "") : "",
            totalPcs: data ? data.totPcs : "",
            totalVol: data ? data.totVol : "",
            totalPcsUt: data ? data.totPcsUt : "",
            totalVolUt: data && data.totVolUt ? data.totVolUt : "",
            totalWgt: data ? data.totgWgt : "",
            totalWgtUt: data ? data.totgWgtUt : "",
            destCity: data && data.destCity ? data.destCity : "",
            destCityName: data ? data.destName : "",
            destCountry: data && data.destCtry ? data.destCtry : "",
            orgCity: data ? data.porCity : "",
            orgCountry: data ? data.porCtry : "",
            orgCityName: data ? data.porName : "",
            polCountry: data ? data.polCtry : "",
            polCity: data ? data.polCity : "",
            polCityName: data ? data.polName : "",
            podCountry: data && data.podCtry ? data.podCtry : "",
            podCity: data && data.podCity ? data.podCity : "",
            podCityName: data ? data.podName : "",
            carrier: carrierData && carrierData.remarkText ? carrierData.remarkText : "",
            commodityCode : data?.commodity ?? "",
            commodity: data ? data.comm : "",
            serviceRequest : data?.serviceRequest ?? [], 
            referenceNumber: data && data.referenceNumber ? data.referenceNumber : "",
            refCode: data && data.refCode ? data.refCode : (cpContext ? cpContext.referenceCode : ''),
            backupRefNo: data && data.referenceNumber ? data.referenceNumber : "",
            deliveryTerms: data ? data.frtType : "",
            pickupAddress: data && data.pickupInfo && data.pickupInfo.collectFrom && data.pickupInfo.collectFrom.fullName ? data.pickupInfo.collectFrom.fullName : null,
            deliveryAddress: data && data.pickupInfo && data.pickupInfo.deliverTo && data.pickupInfo.deliverTo.fullName ? data.pickupInfo.deliverTo.fullName : null,
            pickupRequired: data && data.pickupInfo ? data.pickupInfo.required === 1 ? true : false : false,
            collectFromObj: data && data.pickupInfo.collectFrom ? data.pickupInfo.collectFrom : {},
            pickupdelivertoObj: data && data.pickupInfo.deliverTo ? data.pickupInfo.deliverTo : {},
            readyDate: data && data.pickupInfo.required === 1 ? data.pickupInfo.readyDate ?  moment(data.pickupInfo.readyDate,'YYYYMMDD').format("YYYY/MM/DD") : null : null,
            fromTime: data && data.pickupInfo.required === 1 ? data.pickupInfo.fromTime ? moment(data.pickupInfo.fromTime, 'HH:mm') : null : null,
            toTime: data && data.pickupInfo.required === 1 ? data.pickupInfo.toTime ? moment(data.pickupInfo.toTime, 'HH:mm') : null : null,
            pickupTime: data && data.pickupInfo.required === 1 ? data.pickupInfo.fromTime ? processDate(data.pickupInfo.fromTime, 'HH:mm')+' - '+(data && data.pickupInfo.required === 1 ? data.pickupInfo.toTime ? processDate(data.pickupInfo.toTime, 'HH:mm') : null : null) : null : null,
            pickupRemarks: data && data.pickupInfo.required === 1 && pickupRemarksData && pickupRemarksData.remarkText ? pickupRemarksData.remarkText : '',

            deliveryRequired: data && data.deliveryInfo ? data.deliveryInfo.required === 1 ? true : false : false,
            deliveryCollectAddress: data && data.deliveryInfo && data.deliveryInfo.collectFrom && data.deliveryInfo.collectFrom.fullName ? data.deliveryInfo.collectFrom.fullName : null,
            deliveryDeliveryAddress: data && data.deliveryInfo && data.deliveryInfo.deliverTo && data.deliveryInfo.deliverTo.fullName ? data.deliveryInfo.deliverTo.fullName : null,
            deliverycollectfromObj: data && data.deliveryInfo.collectFrom ? data.deliveryInfo.collectFrom : {},
            deliverydelivertoObj: data && data.deliveryInfo.deliverTo ? data.deliveryInfo.deliverTo : {},
            deliveryreadyDate: data && data.deliveryInfo.required === 1 ? data.deliveryInfo.readyDate ? data.deliveryInfo.readyDate : null : null,
            deliveryfromTime: data && data.deliveryInfo.required === 1 ? data.deliveryInfo.fromTime ? moment(data.deliveryInfo.fromTime, 'HH:mm') : null : null,
            deliverytoTime: data && data.deliveryInfo.required === 1 ? data.deliveryInfo.toTime ? moment(data.deliveryInfo.toTime, 'HH:mm') : null : null,
            deliveryTime: data && data.deliveryInfo.required === 1 ? data.deliveryInfo.fromTime ? processDate(data.deliveryInfo.fromTime,'HH:mm')+' - '+(data && data.deliveryInfo.required === 1 ? data.deliveryInfo.toTime ? processDate(data.deliveryInfo.toTime, 'HH:mm') : null : null) : null : null,
            deliveryRemarks: data && data.deliveryInfo.required === 1 && deliveryRemarksData && deliveryRemarksData.remarkText ? deliveryRemarksData.remarkText : '',
            enableShipperAddress: false,
            enableConsigneeAddress: false,
            enableNotifyAddress: false,
            shipperDefaultAddress: {},
            consigneeDefaultAddress: {},
            notifyDefaultAddress:{},
            shipperAddress: {
                addr1: data ? data.shprAddr1 : '',
                addr2: data ? data.shprAddr2 : '',
                addr3: data ? data.shprAddr3 : '',
                addr4: data ? data.shprAddr4 : ''
            },
            consigneeAddress: {
                addr1: data ? data.csgnAddr1 : '',
                addr2: data ? data.csgnAddr2 : '',
                addr3: data ? data.csgnAddr3 : '',
                addr4: data ? data.csgnAddr4 : ''
            },
            notifyAddress: {
                addr1: data?.ntfyAddr1 ?? '',
                addr2: data?.ntfyAddr2 ?? '',
                addr3: data?.ntfyAddr3 ?? '',
                addr4: data?.ntfyAddr4 ?? ''
            },
            readOnlyShipperAddress: data && data.shprAddr1 ? data.shprAddr1+(data && data.shprAddr2 ? ','+data.shprAddr2 : '')+(data && data.shprAddr3 ? ','+data.shprAddr3 : '')+(data && data.shprAddr4 ? ','+data.shprAddr4 : '') : "",
            readOnlyConsigneeAddress: data && data.csgnAddr1 ? data.csgnAddr1+(data && data.csgnAddr2 ? ','+data.csgnAddr2 : '')+(data && data.csgnAddr3 ? ','+data.csgnAddr3 : '')+(data && data.csgnAddr4 ? ','+data.csgnAddr4 : '') : '',
            readOnlyNotifyAddress: data ? (data.ntfyAddr1 ? ',' +data.ntfyAddr1:'') +(data.ntfyAddr2 ? ',' +data.ntfyAddr2:'')+(data.ntfyAddr3 ? ',' +data.ntfyAddr3:'')+(data.ntfyAddr4 ? ',' +data.ntfyAddr4:''):'' ,
        },

        shipperAddressList: [],
        consigneeAddressList: [],
        notifyAddressList:[],
        validFrom: data ? data.validFrom : "",
        validTo: data ? data.validTo : "",
        quoteDetails: {
            quoteNo: data ? data.quoteNo : "",
            validFromTo: data ? data.validFromTo : ""
        },
        revenueList: data ? data.revenueList ? data.revenueList : [] : [],
        containerslist: data && (bizType === 'SE' || bizType === 'SI') && shipmentType === 'FCL' ? data.containers ? data.containers : [] : [],
        vehiclesContainersList: data && bizType === 'IL' && shipmentType === 'FTL' ? data.vehicles ? data.vehicles : [] :[],
        selectedContainer: null,
        itemslist: data ? data.cargoItems ? data.cargoItems : [] : [],
        selectedItem: null,
        documents: data ? data.documents ? data.documents : [] : [],
        auditTrail: {
            createdDate: (data && data.auditTrail && data.auditTrail.createdDate) ? processDate(data.auditTrail.createdDate, 'dateTime') : "",
            updatedDate: (data && data.auditTrail && data.auditTrail.updatedDate) ? processDate(data.auditTrail.updatedDate, 'dateTime') : "",
            submittedDate: (data && data.auditTrail && data.auditTrail.submittedDate) ? processDate(data.auditTrail.submittedDate, 'dateTime') : "",
            acceptOrRejectDate: (data && data.auditTrail && data.auditTrail.acceptOrRejectDate) ? processDate(data.auditTrail.acceptOrRejectDate, 'dateTime') : "",
            bookedDate: (data && data.auditTrail && data.auditTrail.bookedDate) ? processDate(data.auditTrail.bookedDate, 'dateTime') : ""
        },
        auditTrailList: [
            { title: "Created", field: "createdDate" },
            { title: "Last Updated", field: "updatedDate" },
            { title: "Submitted", field: "submittedDate" },
            { title: "Booked", field: "bookedDate" },
            { title: "Accept/Reject", field: "acceptOrRejectDate" }],
        error: undefined,
        errordetails: {
            shipper: '',
            bizType: '',
            totalPcs: '',
            totalWgt: '',
            destCity: "",
            destCountry: '',
            totalPcsUt: "",
            totalWgtUt: "",
            podCountry: '',
            podCity: '',
            polCountry:'',
            polCity:'',
            orgCountry:'',
            orgCity:'',
            ready: '',
            readyDate:'',
            deliveryreadyDate:'',
            pickup_timeValidationErrorMessage: '',
            delivery_timeValidationErrorMessage: ''
            //items: ''
        },
        enableIcons: false,
        disableButtons: false,
        anchorEl: null,
        message: null,
        openpopUp: false,
        showConfirm: false,
        showPickupConfirm: false,
        showDeliveryConfirm: false,
        addresses: [],
        popOverField: '',
        docTypes: [],
        sideHeaders: [
            { 'name': 'Quote', 'selected': 'false', 'isDisabled': disableQuote },
            { 'name': 'Details', 'selected': disableQuote === true ? 'true' : 'false', 'isDisabled': false },
            { 'name': 'Containers', 'selected': 'false', 'isDisabled': ((bizType === 'SE' || bizType === 'SI') && shipmentType === 'FCL') ? false : true },
            { 'name': 'Load Unit', 'selected': 'false', 'isDisabled': (bizType === 'IL' && shipmentType === 'FTL') ? false : true },
            { 'name': 'Items', 'selected': 'false', 'isDisabled': false },
            { 'name': 'Documents', 'selected': 'false', 'isDisabled': false },
            { 'name': 'Audit Trail', 'selected': 'false', 'isDisabled': false }
        ],
        submittedDate: data ? data.submittedDate : "",
        // received: data ? data.received : "",
        booked: data ? data.booked : "",
        jobStageCode: data ? data.jobStageCode : 'H',
        funcCode: data ? 5 : 9,
        rejectReason: rejectReasonData && rejectReasonData.remarkText ? rejectReasonData.remarkText : '',
        showRejectBox: false
    }
}

export const getBookingTemplateData = (data, cpContext) => {
    const bizType = data ? data.bizType : ''
    const shipmentType = data ? data.shipmentType : ''
    const remarksData = data && data.remarkList ? data.remarkList : []
    const specialReqData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === 'CPRMKTYPE' }) : {}
    const carrierData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "PC" }) : {}
    const pickupRemarksData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "CPPURMK" }) : {}
    const deliveryRemarksData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "CPDLRMK" }) : {}

    return {
        templateNo: data ? data.templateNo : null,
        unId: data ? data.unId : null,
        readOnly: false,
        templateName: data ? data.templateName : '',
        templateStatus: data ? data.templateStatus : 'A',
        availablePublicly: data ? data.isPrivate === 1 ? false : true : false,
        availablePubliclyPreviousState: data ? data.isPrivate === 1 ? false : true : false,
        funcCode: data ? data.unId ? 5 : 9 : 9,
        jobStageCode: data ? data.unId ? undefined : 'T' : 'T',
        details: {
            customers: data && data.customer && data.customerName ? [{ label: (data.customerName + " (" + data.customer + ")"), value: data.customer }] : [],
            shippers: data && data.shipper && data.shipperName ? [{ label: (data.shipperName + " (" + data.shipper + ")"), value: data.shipper }] : [],
            consignees: data && data.consignee && data.consigneeName ? [{ label: (data.consigneeName + " (" + data.consignee + ")"), value: data.consignee }] : [],
            notifies: data && data.notify && data.notifyName ? [{ label: (data.notifyName + " (" + data.notify + ")"), value: data.notify }] : [],
            status: data?.status ? data.status : 'In Progress',
            shipper: data && data.shipper ? data.shipper : '',
            shipperName: data && data.shipperName ? data.shipperName : '',
            notify:  data?.notify ?? '',
            notifyName: data?.notifyName ?? '',
            customer: data && data.customer ? data.customer : '',
            customerName: data && data.customerName ? data.customerName : '',
            noOptionMsg: '',
            consignee: data && data.consignee ? data.consignee : '',
            consigneeName: data && data.consigneeName ? data.consigneeName : '',
            shipmentType: data ? data.shipmentType : '',
            moveType: data ? data.moveType : '',
            specialRqs: specialReqData && specialReqData.remarkText ? specialReqData.remarkText : '',
            bizType: data ? data.bizType : '',
            totalPcs: data ? data.bkPcs : "",
            totalVol: data ? data.bkgVol : "",
            totalPcsUt: data ? data.bkPcsUt : "",
            totalVolUt: data ? data.bkgVolUt : "",
            totalWgt: data ? data.bkgWgt : "",
            totalWgtUt: data ? data.bkgWgtUt : "",
            destCity: data ? data.destCity : "",
            destCityName: data ? data.destCityName : "",
            destCountry: data ? data.destCountry : "",
            orgCity: data ? data.orgCity : "",
            orgCountry: data ? data.orgCountry : "",
            orgCityName: data ? data.orgCityName : "",
            polCountry: data ? data.polCtry : "",
            polCity: data ? data.polCity : "",
            polCityName: data ? data.polName : "",
            podCountry: data ? data.podCtry : "",
            podCity: data ? data.podCity : "",
            podCityName: data ? data.podName : "",
            carrier: carrierData && carrierData.remarkText ? carrierData.remarkText : "",
            commodityCode : data?.commodityCode ?? "",
            commodity: data ? data.commodity : "",
            serviceRequest : data?.serviceRequest ?? [], 
            referenceNumber: data && data.referenceNumber ? data.referenceNumber : "",
            refCode: data && data.refCode ? data.refCode : (cpContext ? cpContext.referenceCode : ''),
            backupRefNo: data && data.referenceNumber ? data.referenceNumber : "",
            deliveryTerms: data ? data.deliveryTerms : "",
            pickupRequired: data && data.pickupInfo ? data.pickupInfo.required === 1 ? true : false : false,
            collectFromObj: data && data.pickupInfo.collectFrom ? data.pickupInfo.collectFrom : {},
            pickupdelivertoObj: data && data.pickupInfo.deliverTo ? data.pickupInfo.deliverTo : {},
            readyDate: data && data.pickupInfo.required === 1 ? data.pickupInfo.readyDate ? data.pickupInfo.readyDate : null : null,
            fromTime: data && data.pickupInfo.required === 1 ? data.pickupInfo.fromTime ? moment(data.pickupInfo.fromTime, 'HH:mm') : null : null,
            toTime: data && data.pickupInfo.required === 1 ? data.pickupInfo.toTime ? moment(data.pickupInfo.toTime, 'HH:mm') : null : null,
            pickupRemarks: data && data.pickupInfo.required === 1 && pickupRemarksData && pickupRemarksData.remarkText ? pickupRemarksData.remarkText : '',

            deliveryRequired: data && data.deliveryInfo ? data.deliveryInfo.required === 1 ? true : false : false,
            deliverycollectfromObj: data && data.deliveryInfo.collectFrom ? data.deliveryInfo.collectFrom : {},
            deliverydelivertoObj: data && data.deliveryInfo.deliverTo ? data.deliveryInfo.deliverTo : {},
            deliveryreadyDate: data && data.deliveryInfo.required === 1 ? data.deliveryInfo.readyDate ? data.deliveryInfo.readyDate : null : null,
            deliveryfromTime: data && data.deliveryInfo.required === 1 ? data.deliveryInfo.fromTime ? moment(data.deliveryInfo.fromTime, 'HH:mm') : null : null,
            deliverytoTime: data && data.deliveryInfo.required === 1 ? data.deliveryInfo.toTime ? moment(data.deliveryInfo.toTime, 'HH:mm') : null : null,
            deliveryRemarks: data && data.deliveryInfo.required === 1 && deliveryRemarksData && deliveryRemarksData.remarkText ? deliveryRemarksData.remarkText : '',
            enableShipperAddress: false,
            enableConsigneeAddress: false,
            enableNotifyAddress: false,
            shipperDefaultAddress: {},
            consigneeDefaultAddress: {},
            notifyDefaultAddress:{},
            shipperAddress: {
                addr1: data ? data.shprAddr1 : '',
                addr2: data ? data.shprAddr2 : '',
                addr3: data ? data.shprAddr3 : '',
                addr4: data ? data.shprAddr4 : ''
            },
            consigneeAddress: {
                addr1: data ? data.csgnAddr1 : '',
                addr2: data ? data.csgnAddr2 : '',
                addr3: data ? data.csgnAddr3 : '',
                addr4: data ? data.csgnAddr4 : ''
            },
            notifyAddress: {
                addr1: data ? data.ntfyAddr1 : '',
                addr2: data ? data.ntfyAddr2 : '',
                addr3: data ? data.ntfyAddr3 : '',
                addr4: data ? data.ntfyAddr4 : ''
            },

        },
        shipperAddressList: [],
        consigneeAddressList: [],
        notifyAddressList:[],
        containerslist: data && (bizType === 'SE' || bizType === 'SI') && shipmentType === 'FCL' ? data.containers ? data.containers : [] : [],
        vehiclesContainersList: data && bizType === 'IL' && shipmentType === 'FTL' ? data.vehicles ? data.vehicles : [] :[],
        selectedContainer: null,
        itemslist: data ? data.cargoItems ? data.cargoItems : [] : [],
        selectedItem: null,
        error: undefined,
        errordetails: {
            templateName: '',
            customer: '',
            shipper: '',
            consignee: '',
            bizType: '',
            totalPcs: '',
            totalWgt: '',
            destCity: '',
            destCountry: '',
            podCountry: '',
            podCity: '',
            polCountry:'',
            polCity:'',
            orgCountry:'',
            orgCity:'',
            totalPcsUt: "",
            totalWgtUt: "",
            ready: '',
            pickup_timeValidationErrorMessage: '',
            delivery_timeValidationErrorMessage: ''
            //items: ''
        },
        enableIcons: false,
        disableButtons: false,
        anchorEl: null,
        message: null,
        openpopUp: false,
        showConfirm: false,
        showPickupConfirm: false,
        showDeliveryConfirm: false,
        sideHeaders: [
            { 'name': 'Details', 'selected': 'true', 'isDisabled': false },
            { 'name': 'Containers', 'selected': 'false', 'isDisabled': ((bizType === 'SE' || bizType === 'SI') && shipmentType === 'FCL') ? false : true },
            { 'name': 'Load Unit', 'selected': 'false', 'isDisabled': (bizType === 'IL' && shipmentType === 'FTL') ? false : true },
            { 'name': 'Items', 'selected': 'false', 'isDisabled': false }
        ],
        addresses: [],
        popOverField: '',
        docTypes: [],
        pickupCollectAddress: [],
        pickupDeliveryAddress: [],
        deliverCollectAddress: [],
        deliverDeliveryAddress: []
    }
}
export const getBookingDataOfTemplate = (templateData, docTypes, addresses, cpContext) => {
    const bizType = templateData ? templateData.bizType : ''
    const shipmentType = templateData ? templateData.shipmentType : ''
    const cargoItems = templateData && templateData.cargoItems ? templateData.cargoItems.map(item => {
        return { ...item, itemActionCode: 'A' }
    }) : [];
    const remarksData = templateData && templateData.remarkList ? templateData.remarkList : []
    const specialReqData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === 'CPRMKTYPE' }) : {}
    const carrierData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "PC" }) : {}
    const pickupRemarksData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "CPPURMK" }) : {}
    const deliveryRemarksData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "CPDLRMK" }) : {}
    const rejectReasonData = remarksData.length > 0 ? remarksData.find((type) => { return type.remarkType === "CPREJRMK" }) : {}

    return {
        unId: null,
        readOnly: false,
        details: {
            status: 'In Progress',
            shipper: templateData && templateData.shipper ? templateData.shipper : '',
            shipperName: templateData && templateData.shipperName ? templateData.shipperName : '',
            consignee: templateData && templateData.consignee ? templateData.consignee : '',
            consigneeName: templateData && templateData.consigneeName ? templateData.consigneeName : '',
            notify : templateData && templateData.notify ? templateData.notify : '',
            notifyName : templateData && templateData.notifyName ? templateData.notifyName : '',
            shipmentType: templateData && templateData.shipmentType ? templateData.shipmentType : '',
            moveType: templateData && templateData.moveType ? templateData.moveType : '',
            specialRqs: specialReqData && specialReqData.remarkText ? specialReqData.remarkText : '',
            rejectReason: rejectReasonData && rejectReasonData.remarkText ? rejectReasonData.remarkText : '',
            bizType: templateData && templateData.bizType ? templateData.bizType : '',
            totalPcs: templateData && templateData.bkPcs ? templateData.bkPcs : "",
            totalVol: templateData && templateData.bkgVol ? templateData.bkgVol : "",
            totalPcsUt: templateData && templateData.bkPcsUt ? templateData.bkPcsUt : "",
            totalVolUt: templateData && templateData.bkgVolUt ? templateData.bkgVolUt : "",
            totalWgt: templateData && templateData.bkgWgt ? templateData.bkgWgt : "",
            totalWgtUt: templateData && templateData.bkgWgtUt ? templateData.bkgWgtUt : "",
            destCity: templateData && templateData.destCity ? templateData.destCity : "",
            destCityName: templateData && templateData.destCityName ? templateData.destCityName : "",
            destCountry: templateData && templateData.destCountry ? templateData.destCountry : "",
            orgCity: templateData && templateData.orgCity ? templateData.orgCity : "",
            orgCountry: templateData && templateData.orgCountry ? templateData.orgCountry : "",
            orgCityName: templateData && templateData.orgCityName ? templateData.orgCityName : "",
            polCountry: templateData && templateData.polCtry ? templateData.polCtry : "",
            polCity: templateData && templateData.polCity ? templateData.polCity : "",
            polCityName: templateData && templateData.polName ? templateData.polName : "",
            podCountry: templateData && templateData.podCtry ? templateData.podCtry : "",
            podCity: templateData && templateData.podCity ? templateData.podCity : "",
            podCityName: templateData && templateData.podName ? templateData.podName : "",
            carrier: carrierData && carrierData.remarkText ? carrierData.remarkText : "",
            commodityCode : templateData?.commodityCode ?? "",
            commodity: templateData && templateData.commodity ? templateData.commodity : "",
            serviceRequest : templateData?.serviceRequest?.split(",") ?? [], 
            referenceNumber: templateData && templateData.referenceNumber ? templateData.referenceNumber : "",
            refCode: templateData && templateData.refCode ? templateData.refCode : (cpContext ? cpContext.referenceCode : ''),
            backupRefNo: templateData && templateData.referenceNumber ? templateData.referenceNumber : "",
            deliveryTerms: templateData && templateData.deliveryTerms ? templateData.deliveryTerms : "",

            pickupRequired: templateData && templateData.pickupInfo ? templateData.pickupInfo.required === 1 ? true : false : false,
            collectFromObj: templateData && templateData.pickupInfo.collectFrom ? templateData.pickupInfo.collectFrom : {},
            pickupdelivertoObj: templateData && templateData.pickupInfo.deliverTo ? templateData.pickupInfo.deliverTo : {},
            readyDate: templateData && templateData.pickupInfo.required === 1 ? templateData.pickupInfo.readyDate ? templateData.pickupInfo.readyDate : null : null,
            fromTime: templateData && templateData.pickupInfo.required === 1 ? templateData.pickupInfo.fromTime ? moment(templateData.pickupInfo.fromTime, 'HH:mm') : null : null,
            toTime: templateData && templateData.pickupInfo.required === 1 ? templateData.pickupInfo.toTime ? moment(templateData.pickupInfo.toTime, 'HH:mm') : null : null,
            pickupRemarks: templateData && templateData.pickupInfo.required === 1 && pickupRemarksData && pickupRemarksData.remarkText ? pickupRemarksData.remarkText : '',
            deliveryRequired: templateData && templateData.deliveryInfo ? templateData.deliveryInfo.required === 1 ? true : false : false,
            deliverycollectfromObj: templateData && templateData.deliveryInfo.collectFrom ? templateData.deliveryInfo.collectFrom : {},
            deliverydelivertoObj: templateData && templateData.deliveryInfo.deliverTo ? templateData.deliveryInfo.deliverTo : {},
            deliveryreadyDate: templateData && templateData.deliveryInfo.required === 1 ? templateData.deliveryInfo.readyDate ? templateData.deliveryInfo.readyDate : null : null,
            deliveryfromTime: templateData && templateData.deliveryInfo.required === 1 ? templateData.deliveryInfo.fromTime ? moment(templateData.deliveryInfo.fromTime, 'HH:mm') : null : null,
            deliverytoTime: templateData && templateData.deliveryInfo.required === 1 ? templateData.deliveryInfo.toTime ? moment(templateData.deliveryInfo.toTime, 'HH:mm') : null : null,
            deliveryRemarks: templateData && templateData.deliveryInfo.required === 1 && deliveryRemarksData && deliveryRemarksData.remarkText ? deliveryRemarksData.remarkText : '',
            enableShipperAddress: false,
            enableConsigneeAddress: false,
            enableNotifyAddress: false,
            shipperAddress: {
                addr1: templateData ? templateData.shprAddr1 : '',
                addr2: templateData ? templateData.shprAddr2 : '',
                addr3: templateData ? templateData.shprAddr3 : '',
                addr4: templateData ? templateData.shprAddr4 : ''
            },
            consigneeAddress: {
                addr1: templateData ? templateData.csgnAddr1 : '',
                addr2: templateData ? templateData.csgnAddr2 : '',
                addr3: templateData ? templateData.csgnAddr3 : '',
                addr4: templateData ? templateData.csgnAddr4 : ''
            },
            notifyAddress:{
                addr1: templateData ? templateData.ntfyAddr1 : '',
                addr2: templateData ? templateData.ntfyAddr2 : '',
                addr3: templateData ? templateData.ntfyAddr3 : '',
                addr4: templateData ? templateData.ntfyAddr4 : ''
            },
            shipperDefaultAddress: {},
            consigneeDefaultAddress: {},
            notifyDefaultAddress:{}
        },
        funcCode: 9,
        jobStageCode: 'P',

        shipperAddressList: [],
        consigneeAddressList: [],
        notifyAddressList: [],
        containerslist: templateData && (bizType === 'SE' || bizType === 'SI') && shipmentType === 'FCL' ? templateData.containers ? templateData.containers : [] : [],
        vehiclesContainersList: templateData && bizType === 'IL' && shipmentType === 'FTL' ? templateData.vehicles ? templateData.vehicles : [] :[],
        selectedContainer: null,
        itemslist: cargoItems,
        selectedItem: null,
        documents: [],
        auditTrail: {},
        error: undefined,
        errordetails: {
            shipper: '',
            bizType: '',
            totalPcs: '',
            totalWgt: '',
            destCity: "",
            destCountry: '',
            podCountry: '',
            podCity: '',
            polCountry:'',
            polCity:'',
            orgCountry:'',
            orgCity:'',
            totalPcsUt: "",
            totalWgtUt: "",
            ready: '',
            readyDate:'',
            deliveryreadyDate:'',
            pickup_timeValidationErrorMessage: '',
            delivery_timeValidationErrorMessage: ''
        },
        enableIcons: false,
        disableButtons: false,
        anchorEl: null,
        message: null,
        openpopUp: false,
        showConfirm: false,
        showPickupConfirm: false,
        showDeliveryConfirm: false,
        sideHeaders: [
            { 'name': 'Details', 'selected': 'true', 'isDisabled': false },
            { 'name': 'Containers', 'selected': 'false', 'isDisabled': ((bizType === 'SE' || bizType === 'SI') && shipmentType === 'FCL') ? false : true },
            { 'name': 'Load Unit', 'selected': 'false', 'isDisabled': (bizType === 'IL' && shipmentType === 'FTL') ? false : true },
            { 'name': 'Items', 'selected': 'false', 'isDisabled': false },
            { 'name': 'Documents', 'selected': 'false', 'isDisabled': false },
            { 'name': 'Audit Trail', 'selected': 'false', 'isDisabled': false }
        ],
        addresses: addresses ? addresses : [],
        popOverField: '',
        docTypes: docTypes ? docTypes : [],
        templateName: templateData && templateData.templateName ? templateData.templateName : '',
        templateNo: templateData && templateData.templateNo ? templateData.templateNo : ''
    }
}
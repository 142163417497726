import React from 'react'
import { withStyles, TextField, useTheme, useMediaQuery } from '@material-ui/core'
import CPToolTip from './CPToolTip';

const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            width: '35%',
            fontWeight: 'bold',
            fontSize: '14px'
        }
    },
    inputWrapper: {
        width: '100%',
        display: 'inline-flex',
        padding: '0',
        alignItems: 'center',
        margin: '0.5rem 2px'
    },
    inputWrapper1: {
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center'
    },
    fieldLabel: {    
        fontWeight: 500       
    },
    fieldContent: {
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: '56%'
        }
    },
    fieldContent1: {
        width: '33%',
        marginRight: '3%',
        [theme.breakpoints.up('md')]: {
            width: '23%',
            marginRight: '2%'
        }
    },
    fieldContent2: {
        width: '55%',
        [theme.breakpoints.up('md')]: {
            width: '35%'
        }
    },
    fieldContentWOL: {
        width: '100%'
    },
    fieldContent1WOL: {
        width: '60%',
        marginRight: '2%'
    },
    fieldContent2WOL: {
        width: '37%'
    },
    required: {
        color: 'red',
        width: '2%'
    },
    input: {
        '&.MuiOutlinedInput-root': {
            paddingRight: '0',
            '&	.MuiOutlinedInput-input': {
                display: 'block',
                lineHeight: 'calc(4rem - 4px)',
                minHeight: '3rem',
                padding: '0 1rem',
                position: 'relative',
                verticalAlign: 'middle',
                width: '100%',
                backgroundColor: '#FFFFFF',
                color: '#4A4E50'
            },
            '&  .MuiInputAdornment-root': {
                backgroundColor: theme.palette.primary.main,
                backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.24))',
                padding: '1.7rem 1rem',
                margin: '0px',
                borderTopRightRadius: theme.shape.borderRadius + 'px',
                borderBottomRightRadius: theme.shape.borderRadius + 'px',
                borderLeft: '2px solid #C8CFD2',
                color: 'white'
            },
        }
    },
    notchedOutline: {
        border: '2px solid #C8CFD2',
        borderRadius: '4px',
        transitionDuration: '100ms',
        transitionProperty: 'border-color, background-color, color, box-shadow',
        '&:focus': {
            outlineWidth: '0',
            borderColor: theme.palette.primary.main,
            boxShadow: '0 0 0 3px #e3ecef'
        },
        '&focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px'
        }
    },
    disabled: {

    }
})

function CpInput(props) {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const numberProps = { min: 0 }
    const { width, register, id, classes, error, helperText, label, type, onChange, isRequired, children, name, InputProps, value, isDisabled, placeholder, showTitle, onPaste, onCopy, maxLength, autoFocus } = props
    return (
        <div style={{ width: width }} className={label ? classes.inputWrapper : classes.inputWrapper1} >
            {label ?
                <>
                    <div className={classes.sectionDesktop}><label className={classes.fieldLabel}htmlFor={name}>{label} {isRequired ? <span className={classes.required}>*</span> : ""}</label></div>
                </>
                : null}
            <CPToolTip title={showTitle === true ? value : ''} >
                <TextField inputRef={register}
                    id={id ? id : name}
                    label={isDesktop ? '' : label}
                    error={error}
                    autoFocus={autoFocus}
                    helperText={helperText}
                    placeholder={placeholder}
                    variant="outlined"
                    disabled={isDisabled}
                    className={children ? (label ? classes.fieldContent1 : classes.fieldContent1WOL) : (label ? classes.fieldContent : classes.fieldContentWOL)}
                    name={name}
                    value={value !== null ? value : ''}
                    type={type}
                    fullWidth={true}
                    onChange={onChange}
                    InputLabelProps={{ margin: 'dense' }}
                    InputProps={{ ...InputProps, classes: { root: classes.input, disabled: classes.disabled, notchedOutline: classes.notchedOutline } }}
                    onCopy={onCopy}
                    onPaste={onPaste}
                    size='small'
                    inputProps={type === 'number' ? numberProps : { maxLength: maxLength ? maxLength : null }} />
            </CPToolTip>
            {children ?
                <div className={label ? classes.fieldContent2 : classes.fieldContent2WOL}>
                    {children}
                </div> : null}
        </div>
    )
}

export default withStyles(style)(CpInput)

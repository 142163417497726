import { withStyles } from '@material-ui/core';
import React from 'react';
import { injectIntl } from 'react-intl';
import CpEditableTable from '../../common/CpEditableTable';
import PageNumbers from '../../common/Pagination/PageNumbers';
import Box from '../../common/Box';

const styles = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    recordCount: {
        marginLeft: '10px',
        fontSize: '14px',
        fontFamily: 'sans-serif',
        fontWeight: 400,
        fontStyle: 'normal',
        color: '#545F66'
    }
})

function DelvieryOrders(props) {

    const { classes, handleDisplayForward, delvieryOrdersData, noOfPages, selectedPage, handlePageClick, intl } = props

    const deliveryOrdersHeaders = [
        { 'width': '80%', 'title': intl.formatMessage({ id: 'Delivery_No' }), 'field': 'doNo', 'showLabel': true, 'type': 'label' }
    ]

    return (
        <Box showloading={true} header={intl.formatMessage({ id: 'delivery_orders' })}>
            <div style={{ height: '30px', display: 'flex', alignItems: "center" }}> <span className={classes.recordCount}>{intl.formatMessage({ id: 'shipments_noOfRecords' })} : {delvieryOrdersData ? delvieryOrdersData.length : "0"}</span></div>
            <div>
                <CpEditableTable headers={deliveryOrdersHeaders} readOnly={true} data={delvieryOrdersData ? delvieryOrdersData : []} navigateIcon={true} handleDisplayForward={(row, index) => handleDisplayForward(1, row, index)} />
            </div>
            <PageNumbers noOfPages={noOfPages} selected={selectedPage} onClick={(index) => handlePageClick(index, 0)} />
        </Box>
    );
}

export default injectIntl(withStyles(styles)(DelvieryOrders))

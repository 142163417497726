import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import EventIcon from '@material-ui/icons/Event';
import MomentUtils from '@date-io/moment';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { injectIntl } from "react-intl";
import { withStyles, useTheme, useMediaQuery } from '@material-ui/core';

const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            width: '35%',
            fontWeight: 'bold',
            fontSize: '14px'
        }
    },
    dateTimeWrapper: {
        width: '100%',
        display: 'inline-flex',
        padding: '0',
        margin: '0.5rem 2px',
        alignItems: 'center'
    },
    dateTimeWrapper1: {
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center'
    },
    fieldContent: {
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: '92%'
        }
    },
    fieldContent1: {
        width: '50%',
        marginRight: '3%',
        [theme.breakpoints.up('md')]: {
            width: '40%',
            marginRight: '3%',
        }
    },
    fieldContent2: {
        width: '37%',
        [theme.breakpoints.up('md')]: {
            width: '17%'
        }
    },
    required: {
        color: 'red',
        width: '2%'
    },
    button: {
        padding: '6px'
    },
    input: {
        '&.MuiOutlinedInput-root': {
            '&	.MuiOutlinedInput-input': {
                display: 'block',
                lineHeight: 'calc(4rem - 4px)',
                minHeight: '3rem',
                padding: '0 1rem',
                position: 'relative',
                verticalAlign: 'middle',
                width: '100%',
                backgroundColor: '#FFFFFF',
                color: '#4A4E50'
            }
        }
    },
    divDateField: {
      width: '90%',
      marginRight: '3px',
      [theme.breakpoints.up('md')]: {
        width: '60%',
        marginRight: '3px'
      }
    },
    divDateField1: {
      width: '88%'
    },
    notchedOutline: {
        borderRadius: '4px',
        border: '2px solid #C8CFD2',
        transitionDuration: '100ms',
        transitionProperty: 'border-color, background-color, color, box-shadow',
        '&:focus': {
            outlineWidth: '0',
            borderColor: theme.palette.primary.main,
            boxShadow: '0 0 0 3px #e3ecef'
        },
        '&focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px'
        }
    },
    disabled: {

    }
})
function CpDateTime(props) {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const { classes, isDisabled, isRequired, label, dateValue, timeValue, dateName, timeName, onDateChange, onTimeChange, showTime,error,intl} = props
    return (
        <>
        <div className={classes.dateTimeWrapper} >
            {label ?
                <>
                    <div className={classes.sectionDesktop}><label style={{fontWeight:500}} htmlFor={dateName}>{label} {isRequired ? <span className={classes.required}>*</span> : ""}</label></div>
                </>
                : null}
            <div className={label ? classes.divDateField : classes.divDateField1}>
            
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker className={showTime ? classes.fieldContent1 : classes.fieldContent}
                        format='YYYY/MM/DD'
                        placeholder='YYYY/MM/DD'
                        value={dateValue}
                        label={isDesktop ? '' : label}
                        name={dateName}
                        disabled={isDisabled}
                        error={error}
                        inputVariant="outlined"
                        InputProps={{ classes: { root: classes.input, disabled: classes.disabled, notchedOutline: classes.notchedOutline } }}
                        InputLabelProps={{ margin: 'dense' }}
                        disablePast={isDisabled === true ? false : true}
                        invalidDateMessage={isDisabled ? '' : "Invalid date format"}
                        minDateMessage= {intl.formatMessage({id: 'dateError'})}
                        onChange={(value) => onDateChange(value)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                            className: classes.button
                        }}
                        keyboardIcon={<EventIcon color={isDisabled ? 'disabled' : 'secondary'} style={{ color: isDisabled ? 'disabled' : '#4A4E50' }} />}
                    />
                </MuiPickersUtilsProvider>
                {showTime
                    ?
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        < KeyboardTimePicker className={classes.fieldContent2}
                            name={timeName}
                            ampm={false}
                            disabled={isDisabled}
                            value={timeValue}
                            inputVariant="outlined"
                            InputProps={{ classes: { root: classes.input, disabled: classes.disabled, notchedOutline: classes.notchedOutline } }}
                            InputLabelProps={{ margin: 'dense' }}
                            placeholder='HH:mm'
                            onChange={(value) => onTimeChange(value)}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                                className: classes.button
                            }}
                            keyboardIcon={<ScheduleIcon color={isDisabled ? 'disabled' : 'secondary'} style={{ paddingLeft: '0px !important', color: isDisabled ? 'disabled' : '#4A4E50' }} />}
                        />
                    </MuiPickersUtilsProvider>
                    : null
                }
            </div>
        </div>
        </>
    )
}

export default injectIntl(withStyles(style)(CpDateTime))

import React, { Component } from 'react';
import CommercialInvoiceItems from './CommercialInvoiceItems';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core';
import CommercialInvoiceItem from './CommercialInvoiceItem';
import CommercialInvoiceLine from './CommercialInvoiceLine';
import CommercialInvoiceTariff from './CommercialInvoiceTariff';
import CommericalInvoicePGA from './CommericalInvoicePGA';
import { getShipmentInvoiceItems, getShipmentInvoiceItemDetails, getCIInvoiceLines, getTariffDetails, getPGADetails } from '../../../sevices/CMUS/Services'
import withLoading from '../../../components/common/withLoading';

const styles = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
})
class CommercialInvoiceSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displaySection: {
                level0: { visible: true, data: undefined, noOfPages: 0, selectedPage: 1, totalRecords: 0, selectedRecord: 0 },
                level1: { visible: false, data: undefined, noOfPages: 0, selectedPage: 1, totalRecords: 0, selectedRecord: 0 },
                level2: { visible: false, data: undefined, totalRecords: 0, selectedRecord: 0 },
                level3: { visible: false, data: undefined, totalRecords: 0, selectedRecord: 0 },
                level4: { visible: false, data: undefined, totalRecords: 0, selectedRecord: 0 }
            },
            selectedInvoice: null,
            selectedLineNo: null,
            selectedTariffLineNo: null,
            selectedPgaCode: '',
            pgCd: ''
        }
    }
    componentDidMount = async () => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        await this.getCommInvItems(this.state.displaySection.level0.selectedPage);
        await loading.setLoadindDoc(false);
    }

    getCommInvItems = async (pageSelected) => {

        const { loading } = this.props
        loading.setLoadindDoc(true);
        let invoiceItems = await getShipmentInvoiceItems(pageSelected);
        if (invoiceItems && invoiceItems.status === 200 && invoiceItems.data) {
            await this.setState({
                displaySection: {
                    ...this.state.displaySection,
                    level0: {
                        visible: true,
                        data: invoiceItems.data.data ? invoiceItems.data.data : [],
                        noOfPages: invoiceItems.data.maxResults !== 0 ? Math.ceil(invoiceItems.data.totalRecords / invoiceItems.data.maxResults) : 1,
                        selectedPage: pageSelected ? pageSelected : 1,
                        totalRecords: (invoiceItems.data && invoiceItems.data.data && invoiceItems.data.data.length) ? invoiceItems.data.data.length : this.state.displaySection.level0.totalRecords
                    }
                }
            })
        }
        else {
            await this.setState({
                displaySection: {
                    ...this.state.displaySection,
                    level0: {
                        visible: true,
                        data: [],
                        noOfPages: 0,
                        selectedPage: 0
                    }
                }
            })

        }
        loading.setLoadindDoc(false);
    }
    getCommInvItemWithLines = async (data, pageSelected, index) => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        const itemDetails = await getShipmentInvoiceItemDetails(data.ciNo, pageSelected);
        if (itemDetails && itemDetails.status === 200 && itemDetails.data) {
            await this.setState({
                displaySection: {
                    level0: { ...this.state.displaySection.level0, visible: false, selectedRecord: index ? index : this.state.displaySection.level0.selectedRecord },
                    level1: {
                        visible: true, data: itemDetails.data ? itemDetails.data : null,
                        noOfPages: itemDetails.data.maxResults !== 0 ? Math.ceil(itemDetails.data.totalRecords / itemDetails.data.maxResults) : 1,
                        selectedPage: pageSelected ? pageSelected : 1,
                        totalRecords: (itemDetails.data && itemDetails.data.data && itemDetails.data.data.length) ? itemDetails.data.data.length : this.state.displaySection.level1.totalRecords
                    },
                    level2: { visible: false, data: undefined },
                    level3: { visible: false, data: undefined },
                    level4: { visible: false, data: undefined }
                },
                selectedInvoice: data
            });
        }
        else {
            await this.setState({
                displaySection: {
                    level0: { ...this.state.displaySection.level0, visible: false, selectedRecord: 0 },
                    level1: {
                        visible: true,
                        data: null,
                        noOfPages: 0,
                        selectedPage: 0
                    },
                    level2: { visible: false, data: undefined },
                    level3: { visible: false, data: undefined },
                    level4: { visible: false, data: undefined }

                },
                selectedInvoice: data
            });
        }
        loading.setLoadindDoc(false);
    }
    handleDisplayBack = (level, data) => {
        switch (level) {
            case 0:
                this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: true },
                        level1: { ...this.state.displaySection.level1, visible: false },
                        level2: { ...this.state.displaySection.level2, visible: false },
                        level3: { ...this.state.displaySection.level3, visible: false },
                        level4: { ...this.state.displaySection.level4, visible: false }
                    }
                });

                break;
            case 1:
                this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: false },
                        level1: { ...this.state.displaySection.level1, visible: true },
                        level2: { ...this.state.displaySection.level2, visible: false },
                        level3: { ...this.state.displaySection.level3, visible: false },
                        level4: { ...this.state.displaySection.level4, visible: false }
                    }
                });
                break;
            case 2:
                this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: false },
                        level1: { ...this.state.displaySection.level1, visible: false },
                        level2: { ...this.state.displaySection.level2, visible: true },
                        level3: { ...this.state.displaySection.level3, visible: false },
                        level4: { ...this.state.displaySection.level4, visible: false }
                    }
                });
                break;
            case 3:
                this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: false },
                        level1: { ...this.state.displaySection.level1, visible: false },
                        level2: { ...this.state.displaySection.level2, visible: false },
                        level3: { ...this.state.displaySection.level3, visible: true },
                        level4: { ...this.state.displaySection.level4, visible: false }
                    }
                });
                break;

            default:
                this.setState({
                    displaySection: {
                        level0: { visible: true, data: data },
                        level1: { visible: false, data: undefined },
                        level2: { visible: false, data: undefined },
                        level3: { visible: false, data: undefined },
                        level4: { visible: false, data: undefined }
                    }
                });
                break;
        }
    }

    handleDisplayForward = async (level, data, index) => {
        const { loading } = this.props

        switch (level) {
            case 1:
                this.getCommInvItemWithLines(data, this.state.displaySection.level1.selectedPage, index);
                break;
            case 2:
                loading.setLoadindDoc(true);
                const ciLineDetials = await getCIInvoiceLines(this.state.selectedInvoice.ciNo, data.ciLineNo);
                await this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: false },
                        level1: { ...this.state.displaySection.level1, visible: false, selectedRecord: index ? index : this.state.displaySection.level1.selectedRecord },
                        level2: { visible: true, data: ciLineDetials.data, totalRecords: (ciLineDetials.data && ciLineDetials.data.data && ciLineDetials.data.data.length > 0 && ciLineDetials.data.data[0].ciTariffClassList && ciLineDetials.data.data[0].ciTariffClassList.length > 0) ? ciLineDetials.data.data[0].ciTariffClassList.length : this.state.displaySection.level2.totalRecords },
                        level3: { visible: false, data: undefined },
                        level4: { visible: false, data: undefined }

                    },
                    selectedLineNo: data.ciLineNo
                });
                loading.setLoadindDoc(false);
                break;
            case 3:
                loading.setLoadindDoc(true);
                const tariffDetails = await getTariffDetails(this.state.selectedInvoice.ciNo, this.state.selectedLineNo, data.tariffLineNo);
                await this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: false },
                        level1: { ...this.state.displaySection.level1, visible: false },
                        level2: { ...this.state.displaySection.level2, visible: false, selectedRecord: index ? index : this.state.displaySection.level2.selectedRecord },
                        level3: {
                            visible: true, data: (tariffDetails && tariffDetails.data && tariffDetails.data.data && tariffDetails.data.data.length > 0) ? tariffDetails.data.data[0] : {},
                            totalRecords: (tariffDetails && tariffDetails.data && tariffDetails.data.data && tariffDetails.data.data.length > 0 && tariffDetails.data.data[0].pgEsList) ? tariffDetails.data.data[0].pgEsList.length : 0
                        },
                        level4: { visible: false, data: undefined }
                    },
                    selectedTariffLineNo: data.tariffLineNo
                });
                loading.setLoadindDoc(false);
                break;
            case 4:
                //logic for api call        
                loading.setLoadindDoc(true);
                const pgaResponseData = await getPGADetails(this.state.selectedInvoice.ciNo, this.state.selectedLineNo, this.state.selectedTariffLineNo, data);
                await this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: false },
                        level1: { ...this.state.displaySection.level1, visible: false },
                        level2: { ...this.state.displaySection.level2, visible: false },
                        level3: { ...this.state.displaySection.level3, visible: false, selectedRecord: index ? index : this.state.displaySection.level3.selectedRecord },
                        level4: { visible: true, data: pgaResponseData && pgaResponseData.data && pgaResponseData.data.length > 0 ? pgaResponseData.data[0] : {} }
                    },
                    selectedPgaCode: data.agencyName,
                    pgCd: data.pgAgencyCd === 'APH' ? data.pgProgramCd : data.pgCd
                });
                loading.setLoadindDoc(false);
                break
            default:
                break;
        }

    }
    handlePageClick = (pageIndex, level) => {
        if (level === 0) {
            this.getCommInvItems(pageIndex);
        } else if (level === 1) {
            this.getCommInvItemWithLines(this.state.selectedInvoice, pageIndex)
        }
    }
    render() {
        const { displaySection, selectedPgaCode, pgCd } = this.state
        return (
            <div>
                {displaySection.level0.visible ? <CommercialInvoiceItems invoiceItemsData={displaySection.level0.data} handleDisplayForward={this.handleDisplayForward}
                    noOfPages={displaySection.level0.noOfPages} selectedPage={displaySection.level0.selectedPage} handlePageClick={this.handlePageClick} /> : null}
                {displaySection.level1.visible ? <CommercialInvoiceItem invoiceItemsData={displaySection.level1.data} invoiceData={this.state.selectedInvoice}
                    handleDisplayBack={this.handleDisplayBack} handleDisplayForward={this.handleDisplayForward} noOfPages={displaySection.level1.noOfPages} selectedPage={displaySection.level1.selectedPage} handlePageClick={this.handlePageClick}
                    totalRecords={displaySection.level0.totalRecords} selectedRecord={displaySection.level0.selectedRecord} /> : null}
                {displaySection.level2.visible ? <CommercialInvoiceLine data={displaySection.level2.data} handleDisplayBack={this.handleDisplayBack} handleDisplayForward={this.handleDisplayForward}
                    l1totalRecords={displaySection.level0.totalRecords} l1selectedRecord={displaySection.level0.selectedRecord}
                    l2totalRecords={displaySection.level1.totalRecords} l2selectedRecord={displaySection.level1.selectedRecord} /> : null}
                {displaySection.level3.visible ? <CommercialInvoiceTariff commInvoiceTariffData={displaySection.level3.data} handleDisplayBack={this.handleDisplayBack} handleDisplayForward={this.handleDisplayForward}
                    l1totalRecords={displaySection.level0.totalRecords} l1selectedRecord={displaySection.level0.selectedRecord}
                    l2totalRecords={displaySection.level1.totalRecords} l2selectedRecord={displaySection.level1.selectedRecord}
                    l3totalRecords={displaySection.level2.totalRecords} l3selectedRecord={displaySection.level2.selectedRecord} /> : null}
                {displaySection.level4.visible ? <CommericalInvoicePGA pGAData={displaySection.level4.data} handleDisplayBack={this.handleDisplayBack}
                    l1totalRecords={displaySection.level0.totalRecords} l1selectedRecord={displaySection.level0.selectedRecord}
                    l2totalRecords={displaySection.level1.totalRecords} l2selectedRecord={displaySection.level1.selectedRecord}
                    l3totalRecords={displaySection.level2.totalRecords} l3selectedRecord={displaySection.level2.selectedRecord}
                    l4totalRecords={displaySection.level3.totalRecords} l4selectedRecord={displaySection.level3.selectedRecord} pgaType={selectedPgaCode} pgProgramCd={pgCd} /> : null}
            </div>
        )
    }
}

export default injectIntl(withLoading(withStyles(styles)(CommercialInvoiceSection)))
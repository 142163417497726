import { withStyles } from '@material-ui/core'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
const style = theme => ({
    heading: {
        fontSize: '24px',
        fontWeight: 700,
        textAlign: 'center'
    },
    container: {
        margin: '4%'
    },
    textDiv: {
        margin: '2% 0 0 0',
        textAlign: 'center'
    },
    backToHome: {
        color: 'blue',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
})
function Page403(props) {
    const { classes, intl, isForReset } = props
    const backToHome = () => {
        const { history } = props
        history.push("/")
    }
    const backToLogin = () => {
        const { history } = props
        history.push(`/${sessionStorage.getItem('tenant-url')}/login`)
    }
    return (
        <div className={classes.container}>
            <h1 className={classes.heading}>{intl.formatMessage({ id: 'accessDenied' })}</h1>
            { isForReset ?
                (<div className={classes.textDiv}>
                    <p>
                        {intl.formatMessage({ id: 'accessDeniedMsgResetPwd' })}
                    </p>
                    <p className={classes.backToHome} onClick={() => backToLogin()}>{intl.formatMessage({ id: 'backToLogin' })}</p>
                </div>)
                : (<div className={classes.textDiv}>
                    <p>
                        {intl.formatMessage({ id: 'accessDeniedMsg' })}
                    </p>
                    <p className={classes.backToHome} onClick={() => backToHome()}>{intl.formatMessage({ id: 'backToHome' })}</p>
                </div>)}
        </div>
    )
}

export default injectIntl(withRouter(withStyles(style)(Page403)))
import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, withStyles,FormHelperText } from '@material-ui/core'
import CpInput from './CpInput'
import CpDropDown from './CpDropDown';
import CpTypeahead from './CpTypeahead';
import FastForwardIcon from '@material-ui/icons/FastForward';
import { injectIntl } from 'react-intl';
import CPToolTip from './CPToolTip';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Button from './Button';

const style = {
    tableHeader: {
        fontWeight: 'bold !important',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        borderBottom: '4px solid #CDE3E9',
        height: '54px',
        padding: '6px 6px 6px 10px'
    },
    tabletr: {
        display: ' table !important',
        width: '100%',
        fontSize: '14px',
        tableLayout: 'fixed',
        margin: 0,
        fontFamily: "'Open Sans', Arial, sans-serif !important",
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        '&:nth-child(even)': {
            backgroundColor: '#F3F7F9'
        }
    },
    tableCell: {
        fontSize: '14px',
        border: '1px solid #DAE7EA',
        height: '48px',
        padding: '6px 6px 6px 10px',
        borderLeft: '0px'
    },
    tableCellWOB: {
        fontSize: '14px',
        border: '1px solid #DAE7EA',
        height: '48px',
        padding: '6px 6px 6px 10px',
        borderLeft: '0px',
        borderRight: '0px'
    },
    noRecords: {
        minHeight: '10vh',
        fontStyle: 'italic'
    },
    noRecordscell: {
        fontSize: '14px',
        fontFamily: 'Open Sans Italic'
    },
    addIcon: {
        fontSize: '2rem'
    }
}
function CpEditableTable(props) {
    const getinput = (header, row, onChange,errortype) => {
        const { field, type, inputType, options, fieldlabel, tooltipField, afterSelectionShow, renderOption, titleprop, placeholder,title } = header;
        let id = row.id ? `${row.id}` : `${row.itemUniqueRefNo}`
        id = id + field
        const error= errortype && ( (title === 'Type' && row.type === "") || (title === 'QTY' && row.qty === ""))
        switch (type) {
            case 'input':
                return <CpInput error ={error} id={id} name={field} label={fieldlabel} placeholder={placeholder} type={inputType} value={row[field]} onChange={(event) => onChange(row['id'] ? row['id'] : row.itemUniqueRefNo, field, event.target.value)} />
            case 'dropdown':
                return (<CPToolTip title={row[field]} arrow placement="top" enterDelay={0}>
                    <div>
                        <CpDropDown error ={error} id={id} value={row[field]} name={field} label={fieldlabel ? fieldlabel : undefined} items={options} onChange={(event) => onChange(row['id'] ? row['id'] : row.itemUniqueRefNo, field, event.target.value)} />
                    </div>
                </CPToolTip>)
            case 'typeahead':
                return <CpTypeahead isTable id={id} afterSelectionShow={afterSelectionShow ? afterSelectionShow : "value"} renderOption={renderOption ? renderOption : 'both'} widgetPopper={false} value={row[field]} name={field} label={fieldlabel ? fieldlabel : undefined} options={options} onChange={(event) => onChange(row['id'] ? row['id'] : row.itemUniqueRefNo, field, event && event.value ? event.value : '')} showTitle={true} titleprop={titleprop ? titleprop : "both"} />
            case 'label':
                return (
                    <div>
                        {fieldlabel ? <span style={{ fontWeight: 'bold', marginRight: '5px' }} >{fieldlabel}</span> : null}
                        <CPToolTip title={row[tooltipField] ? row[tooltipField] : ""}>
                            <span>{row[field]}</span>
                        </CPToolTip>
                    </div>)
            default:
                return null
        }
    }
    const { headers, data, classes, addfunc, removefunc, onChangefields, maxLength, readOnly, isPrint, printClasses, navigateIcon, handleDisplayForward, intl, tableFor,error,helperText,helperText1 } = props
    return (
        <Table size='small' style={{ overflowX: 'auto' }}>
            <TableHead>
                <TableRow className={classes.tabletr} >
                    {(readOnly === false || navigateIcon === true) ?
                        <TableCell className={classes.tableHeader} style={{ width: '4%' }} >
                        </TableCell>
                        : null}
                    {
                        headers.map((header, index) => {
                            return (
                                <TableCell key={index} className={classes.tableHeader} style={{ width: header.width }} >{header.showLabel ? header.title : ''}</TableCell>
                            )
                        })
                    }
                </TableRow>
            </TableHead>
            <TableBody >
                {data && data.length > 0 ?
                    data.map((row, index) => {

                        return (
                            row.actionCode !== 'D' ?
                                <TableRow className={classes.tabletr} key={index}>
                                    {readOnly === false ? <TableCell className={classes.tableCell} style={{ width: '4%' }} ><DeleteIcon style={{ color: '#417F90', fontSize: '20px', cursor:'pointer'}} onClick={() => removefunc(row.id, row)} /></TableCell>
                                        : navigateIcon ? <TableCell className={classes.tableCell} style={{ width: '4%' }} ><FastForwardIcon style={{ color: '#417F90', fontSize: '20px', cursor:'pointer' }} onClick={() => handleDisplayForward(row, index + 1)} /></TableCell> : null}
                                    {headers.map((header, index) => {
                                        return (
                                            <TableCell className={header.showBorder === false ? classes.tableCellWOB : classes.tableCell} style={{ width: header.width }} key={index}>
                                                {getinput(header, row, onChangefields,error)}
                                                {error && ( (header.title === 'Type' && row.type === "") || (header.title === 'QTY' && row.qty === ""))  ? <FormHelperText error={true}>{helperText}</FormHelperText>:""}
                                            </TableCell>)
                                    })}
                                </TableRow> : null)
                    }) :
                    readOnly === true && (data !== undefined || (data && data.length === 0)) ?
                        <TableRow className={isPrint ? `${classes.tabletr} ${printClasses.noRecordsForPrint}` : `${classes.tabletr} ${classes.noRecords}`}>
                            <TableCell colSpan={headers.length} className={classes.noRecordscell}> {intl.formatMessage({ id: 'noRecordsFound' })}</TableCell>
                        </TableRow>
                        :
                        null
                }
                {error && (data.length <= 0) ? <FormHelperText error={true}>{helperText1}</FormHelperText>:""}
                {readOnly === false ? maxLength !== undefined ?
                    data.length < maxLength ?
                        < TableRow className={classes.tabletr}>
                            <TableCell colSpan={headers.length + 1}>
                                <Button variant="secondary" color="primary" onClick={addfunc}>
                                    <PlaylistAddIcon className={classes.addIcon} />
                                    {tableFor ?
                                        intl.formatMessage({ id: 'add' }, { val1: tableFor })
                                        : null
                                    }
                                </Button>
                            </TableCell>
                        </TableRow> :
                        null
                    :
                    < TableRow className={classes.tabletr}>
                        <TableCell colSpan={headers.length + 1}>
                            <Button variant="secondary" color="primary" onClick={addfunc}>
                                <PlaylistAddIcon className={classes.addIcon} />
                                {tableFor ?
                                    intl.formatMessage({ id: 'add' }, { val1: tableFor })
                                    : null
                                }
                            </Button>
                        </TableCell>
                    </TableRow> : null
                }

            </TableBody>
        </Table>
    )
}

export default injectIntl(withStyles(style)(CpEditableTable))

import React from 'react';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CpInput from './common/CpInput';
import { Dialog, withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Button from './common/Button';
import { voidHandler } from '../common/utils';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: '#009CDE',
        color: 'white'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    errorDiv: {
        display: 'flex',
        justifyContent: 'center',
        color: 'red '
    },
    button: {
        marginLeft: '30px',
        fontSize: '15px',
        '&:focus': {
            backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
            outline: 'none !important'
        }
    },
    div: {
        display: 'flex',
        marginTop: '10px',
        height: '20px'
    },
    title: {
        fontSize: '10px',
        height: '20px'
    },
    header: {
        backgroundColor: '#E8F0F4',
        fontWeight: '600',
        fontSize: '14px',
        display: 'flex',
        padding: '1.5rem 2rem',
    },
    progressBarWrapper: {
        marginBottom: '1rem'
    }
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function ChangePassword(props) {

    const [values, setValues] = React.useState({
        showPassword: false,
    });
    const [curPwdValues, setCurPwdValues] = React.useState({
        showPassword: false,
    });

    const getRequiredIcon = (flag) => {
        return (
            flag === true ?
                <DoneOutlinedIcon style={{ color: '#40835F' }} className={classes.button} />
                :
                <CloseIcon color="error" className={classes.button} />
        )
    }
    const { onChange, handleOnClick, formErrorDetails, onDialogClose, currentPassword, newPassword, confirmPassword, error, intl, classes, passwordComplexityFlags, pwdProgressBar, changePwdErrorExist } = props
    const forceChangePassword = sessionStorage.getItem("forceChangePassword") === "true" ? true : false
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleShowCurrentPwd = () => {
        setCurPwdValues({ ...curPwdValues, showPassword: !curPwdValues.showPassword });
    };
    const isDisabled = () => {
        const { passwordComplexityFlags, currentPassword, newPassword, confirmPassword } = props;
        if (passwordComplexityFlags !== null) {
            if (passwordComplexityFlags.ucaseFlag && passwordComplexityFlags.lcaseFlag && passwordComplexityFlags.digitsFlag &&
                passwordComplexityFlags.splCharsFlag && passwordComplexityFlags.lengthFlag) {
                if (currentPassword !== '' && newPassword !== '' && confirmPassword !== '') {
                    return false;
                }
            }
        }
        return true;
    }
    const isCancelDisabled = () => {
        if (forceChangePassword && changePwdErrorExist === false) {
            return true;
        }
        return false;
    }
    return (
        <Dialog open={true} width='md' onClose={() => { forceChangePassword ? voidHandler() : onDialogClose() }} >
            <div className={classes.header} id="alert-dialog-title">
                {intl.formatMessage({ id: 'changePassword' })}
            </div>
            <DialogContent >
                <form autoComplete="off" onSubmit={e => { e.preventDefault(); }}>
                    <div className={classes.errorDiv} >
                        {error && error.length > 0 ? <span>{error}</span> : null}
                    </div>
                    <CpInput type={curPwdValues.showPassword ? 'text' : 'password'} isRequired={true} required={true} value={currentPassword}
                        name="currentPassword" label={intl.formatMessage({ id: 'currentPassword' })} error={formErrorDetails.currentPassword !== '' ? true : false} helperText={formErrorDetails.currentPassword} onChange={(event) => onChange(event)}
                        autoFocus={true}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleShowCurrentPwd}
                                    >
                                        {curPwdValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onPaste={(e) => { e.preventDefault(); return false; }}>
                    </CpInput>
                    <CpInput type={values.showPassword ? 'text' : 'password'} isRequired={true} maxLength={30} value={newPassword}
                        name="newPassword" label={intl.formatMessage({ id: 'newPassword' })}
                        error={formErrorDetails.newPassword !== '' ? true : false}
                        helperText={formErrorDetails.newPassword} onChange={(event) => onChange(event)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),

                        }}
                        onPaste={(e) => { e.preventDefault(); return false; }}>
                    </CpInput>
                    <div className={classes.progressBarWrapper} >
                        {pwdProgressBar}
                    </div>
                    <CpInput type="password" isRequired={true} value={confirmPassword} maxLength={30}
                        name="confirmPassword" label={intl.formatMessage({ id: 'confirmPassword' })} error={formErrorDetails.confirmPassword !== '' ? true : false}
                        helperText={formErrorDetails.confirmPassword} onChange={(event) => onChange(event)}
                        onPaste={(e) => { e.preventDefault(); return false; }}>
                    </CpInput>
                    {passwordComplexityFlags !== null ?
                        <>
                            <div className={classes.div}>
                                {getRequiredIcon(passwordComplexityFlags.ucaseFlag)}
                                <span className={classes.title}>{intl.formatMessage({ id: 'password_ucase' })} </span>
                            </div>
                            <div className={classes.div}>
                                {getRequiredIcon(passwordComplexityFlags.lcaseFlag)}
                                <span className={classes.title}>{intl.formatMessage({ id: 'password_lcase' })} </span>
                            </div>
                            <div className={classes.div}>
                                {getRequiredIcon(passwordComplexityFlags.digitsFlag)}
                                <span className={classes.title}>{intl.formatMessage({ id: 'password_digits' })} </span>
                            </div>
                            <div className={classes.div}>
                                {getRequiredIcon(passwordComplexityFlags.lengthFlag)}
                                <span className={classes.title}>{intl.formatMessage({ id: 'password_length' })} </span>
                            </div>
                            <div className={classes.div}>
                                {getRequiredIcon(passwordComplexityFlags.splCharsFlag)}
                                <span className={classes.title}>{intl.formatMessage({ id: 'password_spclChar' })} </span>
                            </div>
                        </>
                        : null
                    }
                </form>
            </DialogContent>
            <DialogActions>
                <Button variant="secondary" disabled={isCancelDisabled()} onClick={() => isCancelDisabled() ? voidHandler() : onDialogClose()} >
                    {intl.formatMessage({ id: 'cancel' })}
                </Button>
                <Button variant="primary" disabled={isDisabled()} onClick={() => { handleOnClick() }}>
                    {intl.formatMessage({ id: 'saveChanges' })}
                </Button>
            </DialogActions>
        </Dialog >
    )
}
export default withStyles(styles)(injectIntl(ChangePassword));
import React from 'react'
import { Backdrop, withStyles } from '@material-ui/core'
import { ReactComponent as LoadingIcon } from '../../images/loading.svg'

const style = ({ palette }) => ({
    backdrop: {
        zIndex: 1,
        //backgroundColor: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifycontent: 'center',
        flexDirection: 'column'
    },
    p: {
        fontSize: '18px',
        color: '#4A4E50',
        marginTop: '4rem',
        fontStyle: 'normal',
        fontWeight: '600'
    }
});
function Loading(props) {
    const { classes, open } = props
    return (
        <Backdrop className={classes.backdrop} open={open}>
            <LoadingIcon />
            <p className={classes.p} >Loading...</p>
        </Backdrop>
    )
}

export default withStyles(style)(Loading)

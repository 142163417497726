import React, { useState } from "react";
import {
    withStyles, FormHelperText, useTheme, useMediaQuery, InputLabel,
    FormControl, Select, MenuItem, Checkbox, OutlinedInput
} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const style = theme => ({
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "inline-block",
            width: "35%",
            fontWeight: "bold",
            fontSize: "14px"
        }
    },
    dropDownWrapper: {
        width: "100%",
        display: "inline-flex",
        padding: "0%",
        alignItems: 'center',
        margin: '0.5rem 2px'
    },
    dropDownWrapper1: {
        width: "100%",
        display: "inline-flex",
        alignItems: 'center'
    },
    fieldLabel: {
        display: "inline-block",
        width: "35%",
        fontWeight: "bold",
        fontSize: "14px"
    },
    fieldContent: {
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: '56%'
        }
    },
    fieldContentWOL: {
        width: "100%"
    },
    required: {
        color: "red",
        width: "2%"
    },
    paper: {
        background: 'white',
        bordeRadius: '4px',
        border: '2px solid #C8CFD2',
        boxShadow: '0 2px 4px 0 rgb(0 0 0 / 20%)',
        fontSize:'13px',
        display: 'flex',
        flexDirection: 'column',
        listStyle: 'none',
        maxHeight: '250px',
        overflowY: 'auto',
        transition: 'max-height 250ms',
        zIndex: '200',
        [theme.breakpoints.up("md")]: {
            maxWidth: "28%"
        },
        '& .MuiOutlinedInput-input':{
            padding: '0px important'
        },
    },
    listName:{
        textIndent: "5px",
        fontWeight: "500",
        fontSize: '14px',
        textShadow: "0 0 #545F66",
    },
    option: props =>({
        backgroundColor: "white",
        padding: "0.25rem",
        textIndent: "5px",
        fontWeight: "400",
        fontSize: '14px',
        textShadow: "0 0 #545F66",
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: 'pointer',
        display: 'flex',
        alignItems:'center',
        paddingLeft: '0.3rem',
        userSelect: 'none',
        '& .MuiOutlinedInput-input':{
            padding: '0px important'
        },
        "&:hover": {
            backgroundColor: "#E3ECEF"
        },
        "&:checked": {
            backgroundColor: "#FEFAF0"
        }
    }),
    selected: {
        backgroundColor: "#FEFAF0"
    },
});

const CPMultiSelectBox = props => {
    const [anchorEL, setanchorEL] = useState(null)

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const {width, id, classes, label, isRequired, onChange, name, value,status, helperText,
        error, backgroundColor,options} = props;

    return (
        <div style={{ width: width }}
            className={label ? classes.dropDownWrapper : classes.dropDownWrapper1}
        >
            {label &&
                <div className={classes.sectionDesktop}>
                    <label style={{ fontWeight: 500 }} htmlFor={name}>{label} {isRequired ? <span className={classes.required}>*</span> : ""}</label>
                </div>
            }
            <div className={label ? classes.fieldContent : classes.fieldContentWOL}>
                <FormControl style={{ width: "100%" }}>
                    {isDesktop ? null:
                        (label !== undefined ?
                            <InputLabel variant="outlined" margin='dense' id={name}>
                                {label}
                            </InputLabel>
                        : null)
                    }
                    <Select
                        style={{ backgroundColor: backgroundColor ? `${backgroundColor}` : '', padding:"none !important"}}
                        id={id ? id : name}
                        labelId={name}
                        onChange={onChange}
                        name={name}
                        variant="outlined"
                        multiple
                        input={<OutlinedInput inputProps={{style:{height:'70px',textAlign:'center',}}} />}
                        IconComponent={KeyboardArrowDownIcon}
                        onClose={() => setanchorEL(null)}
                        onOpen={(event) => setanchorEL(event.currentTarget)}
                        value={value}
                        error={error}
                        renderValue={(selected) => status !== "In Progress" ? selected : selected?.join(', ')}
                        MenuProps={{
                            anchorEl: anchorEL,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            classes: { paper: classes.paper },
                            getContentAnchorEl: null
                        }}
                    >
                        {options?.map((name) => (
                            <MenuItem key={name.label} value={name.value} className={classes.option}>
                                <Checkbox checked={value.indexOf(name.value) > -1} />
                                <div className={classes.listName}>{name.label}</div>
                            </MenuItem>
                        ))}
                    </Select>
                    {error ? (
                        <FormHelperText error={true}>{helperText}</FormHelperText>
                    ) : ""}
                </FormControl>
            </div>
        </div>
    );
};

export default withStyles(style)(CPMultiSelectBox);

import React, { Component } from 'react';
import CPButton from '../../components/common/Button'
import { getTrackingDetails, saveOrUpdateFavorites, getFavourtieList, fetchContainerDetails } from "../../sevices/TMFF/Services"
import { getShipmentHeaders } from "../../sevices/CMUS/Services"
import '../../styles/bar.css';
import CpDrawer from '../common/CpDrawer';
import DetailedShipmentTMFF from '../ShipmentDetails/TMFF/DetailedShipmentTMFF';
import DetailedShipmentCMUS from '../ShipmentDetails/CMUS/DetailedShipmentCMUS';
import ErrorPage from '../common/ErrorPage';
import Booking from '../../components/eBooking/Booking';
import { TenantConsumer } from '../../common/Tenant';
import { processDate, getSnackBar, voidHandler, fetchUser } from '../../common/utils';
import { AppBar, Toolbar, Tab, Tabs, withStyles, IconButton, Menu, MenuItem, withTheme, InputAdornment, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';
import CpDropDown from '../common/CpDropDown';
import { trackingOptions, TrackingOptionsCMUS } from '../../common/DropDownValues';
import SearchShipments from '../SearchShipments/TMFF/SearchShipments';
import { withRouter } from 'react-router-dom';
import withLoading from '../common/withLoading';
import { FormattedMessage, injectIntl } from "react-intl";
import { getContrastColor } from '../../common/utils'
import MenuIcon from '@material-ui/icons/Menu';
import withError from '../common/withError';
import withCPContext from '../common/withCPContext';
import CPConfirmBox from '../common/CPConfirmBox';
import { Quote } from '../Quotes/Quote';
import BookingTemplate from "../BookingTemplates/BookingTemplate"
import CMUSSearchShipments from '../SearchShipments/CMUS/CMUSSearchShipments';
import ContainerDetails from '../ShipmentDetails/TMFF/ContainerDetails';
import FavouritesList from '../ShipmentDetails/TMFF/FavouritesList';
import { ReactComponent as PushPinIcon } from '../../images/pushpin.svg'
import SendIcon from '@material-ui/icons/Send';
import CpInput from '../common/CpInput';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import SearchIcon from '@material-ui/icons/Search';
import { HOMEPAGE_HELP_TMFF } from '../../sevices/TMFF/serviceURLS';
import { HOMEPAGE_HELP_CMUS } from '../../sevices/CMUS/servicesURLS';
import UserContextMenuSection from '../UserContextMenuSection';
import CopyRight from '../CopyRight';

const style = ({ breakpoints, palette, spacing }) => ({
    sectionDesktop: {
        display: 'none',
        height:'40px',
        [breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    tab: {
        color: getContrastColor(palette.primary.main),
        minWidth: '0',
        minHeight: '40px',
        textTransform: 'capitalize',
        display: 'inline-block',
        fontWeight: '400',
        fontSize: '16px',
        fontStyle: 'normal',
        lineHeight: 'inherit',
        '&:hover': {
            textDecoration: 'underline'
        }

    },
    iconLabelWrapper: {
        flexDirection: "row"
    },
    menuButton: {
        marginRight: spacing(2)
    },
    cursorOnHover: {
        cursor: 'pointer'
    },
    gutters: {
        paddingRight: '0',
        paddingLeft: '6px'
    },
    listItemRoot: {
        paddingTop: '0',
        paddingBottom: '0'
    },
    iconbutton: {
        fontStyle: 'italic',
        fontWeight: 'bold',
        borderRadius: '0',
        color: '#6E787D',
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: '#ffffff'
        },
        '&:active': {
            backgroundColor: "#ffffff"
        },
    },
    menuSection: {
        backgroundColor: '#FFFFFF',
        borderRadius: '0.5rem',
        // border: '2px solid #B8C0C2',
        boxShadow: '0 2px 4px 0 rgb(0 0 0 / 20%)',
        //right: '0',
        margin: '0.25rem 0 0 0',
        padding: '0 0 1rem',
        position: 'absolute',
        top: '100%',
        'z-index': '200'
    },
    menuItem: {
        margin: '1rem 0',
        padding: '0 2rem !important',
        '&hover': {
            backgroundColor: '#E3ECEF'
        }
    },
    listItemIcon: {
        minWidth: '3rem'
    },
    listItemText: {
        minWidth: '0'
    },
    divider: {
        width: '90%',
        margin: 'auto'
    }
})
class Bar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            trackingNo: "",
            trackingOption: 'ShpNos',
            showSidebar: false,
            trackingData: "",
            statusCode: 200,
            showBookingBar: this.props.showBookingBar ? this.props.showBookingBar : false,
            showQuoteBar: this.props.showQuoteBar ? this.props.showQuoteBar : false,
            routingData: undefined,
            success: false,
            open: false,
            vertical: 'top',
            horizontal: 'center',
            showSearchBar: false,
            searchGrid: false,
            bookingsGrid: false,
            shipmentDisplayType: 'fromSearch',
            enableBookingsView: false,
            bookings: undefined,
            bookingData: this.props.bookingData ? this.props.bookingData : undefined,
            quoteData: this.props.quoteData ? this.props.quoteData : undefined,
            selectedShipmentId: this.props.selectedShipmentId ? this.props.selectedShipmentId : undefined,
            destinationCities: this.props.destinationCities ? this.props.destinationCities : undefined,
            polCities: this.props.polCities ? this.props.polCities : undefined,
            podCities: this.props.podCities ? this.props.podCities : undefined,
            originCities: this.props.originCities ? this.props.originCities : undefined,
            mobileMenuId: 'primary-search-account-menu-mobile',
            mobileMoreAnchorEl: null,
            isMobileMenuOpen: Boolean(false),
            isDesktop: window.innerWidth >= 960 ? true : false,
            showConfirm: false,
            confirmFor: "",
            showBookingTemplateBar: this.props.showBookingTemplateBar ? this.props.showBookingTemplateBar : false,
            bookingTemplateData: this.props.bookingTemplateData ? this.props.bookingTemplateData : undefined,
            shipmentFileNo: this.props.shipmentFileNo ? this.props.shipmentFileNo : '',
            cmusShipmentHeaderData: "",
            showCMUSShipmentBar: false,
            showCMUSSearchBar: false,
            recordIndex: 1,
            searchResults: [],
            trackingOptionCMUS: 'fileNo',
            shipmentRetrivalLink: '',
            shipmentLinkData: '',
            showSnackBar: false,
            snackBarMsg: '',
            snackBarType: 'success',
            snackBarId:null,
            showContainerBar: false,
            showFavouritiesBar: false,
            favouritiesData: {},
            containerData: {},
            cmusShipmentfileType: '',
            userContextMenuEl: null,
            isUserContextMenuOpen: false,
            showCopyRight: false,
        };
    }
    handleShipmentNavigation = (index) => {
        const { searchResults } = this.state;
        if (index && index > 0) {
            this.setState({
                recordIndex: index,
                trackingData: searchResults[index - 1]
            })
        }
    }
    handleChange = (e) => {
        this.setState({ trackingNo: e.target.value })
        const { CpError } = this.props;
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
    }
    updateContainerdata = (containersArray) => {
        return containersArray.map(container => {
            return {
                ...container,
                totWgt: container.hasOwnProperty("totWgt") && container.hasOwnProperty("totWgtUt") ? (container.totWgt + ' ' + container.totWgtUt) : container.hasOwnProperty("totWgt") && container.totWgtUt === undefined ? container.totWgt : '',
                totPcs: container.hasOwnProperty("totPcs") && container.hasOwnProperty("totPcsUt") ? (container.totPcs + ' ' + container.totPcsUt) : container.hasOwnProperty("totPcs") && container.totPcsUt === undefined ? container.totPcs : '',
                containerStatusList: this.updateStatusList(container.containerStatusList), expandedRow: false
            }
        })
    }

    updateStatusList = (milestones) => {
        if (milestones) {
            const updateMileStones = milestones.map(mileStone => {
                return {
                    ...mileStone,
                    estimatedDate:
                        mileStone.hasOwnProperty('eventEstDate') ?
                            `${processDate(mileStone.eventEstDate)} ${mileStone.eventEstTime ? mileStone.eventEstTime : ''}`
                            :
                            mileStone.hasOwnProperty('ctnEstEventDate') ?
                                `${processDate(mileStone.ctnEstEventDate)} ${mileStone.ctnEstEventTime ? mileStone.ctnEstEventTime : ''}`
                                : "",
                    acutalDate:
                        mileStone.hasOwnProperty('eventDate') ?
                            `${processDate(mileStone.eventDate)} ${mileStone.eventTime ? mileStone.eventTime : ''}`
                            : mileStone.hasOwnProperty('ctnEventDate') ?
                                `${processDate(mileStone.ctnEventDate)} ${mileStone.ctnEventTime ? mileStone.ctnEventTime : ''}`
                                : '',
                    location: mileStone.hasOwnProperty('location') ? mileStone.location : mileStone.ctnLocation,
                    eventCode: mileStone.hasOwnProperty('eventCode') ? mileStone.eventCode : mileStone.ctnEventCode,
                    eventName: mileStone.hasOwnProperty('eventName') ? mileStone.eventName : mileStone.ctnEventName,
                    remarks: mileStone.hasOwnProperty('remarks') ? mileStone.remarks : mileStone.ctnRemarks
                }
            })
            return updateMileStones;
        }

    }
    updateDocumentsList = (documents) => {
        if (documents) {
            const updatedDocuments = documents.map(document => {
                return {
                    ...document,
                    uploadDate: processDate(document.uploadDate, 'dateTime'),
                    action: "existing",
                }
            })
            return updatedDocuments;
        }
    }
    getRoutelocation = (route) => {
        if (route.hasOwnProperty('destCity') && route.hasOwnProperty('destCtry')) {
            return `${route.destCity} / ${route.destCtry}`
        } else if (route.hasOwnProperty('destCity')) {
            return `${route.destCity} / `
        } else if (route.hasOwnProperty('destCtry')) {
            return ` / ${route.destCtry}`
        } else {
            return ''
        }
    }
    updateRoutingData = (routes) => {

        return routes.map(route => {
            return {
                ...route,
                location: this.getRoutelocation(route),
                etd: route.etdDate ? `${processDate(route.etdDate)} ${route.etdTime ? route.etdTime : ''}` : '',
                atd: route.atdDate ? `${processDate(route.atdDate)} ${route.atdTime ? route.atdTime : ''}` : '',
                eta: route.etaDate ? `${processDate(route.etaDate)} ${route.etaTime ? route.etaTime : ''}` : '',
                ata: route.etaDate ? `${processDate(route.ataDate)} ${route.ataTime ? route.ataTime : ''}` : ''
            }
        })

    }
    componentDidMount = () => {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({ isDesktop: window.innerWidth <= 960 ? false : true });
    }
    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (this.props.showBookingBar !== prevProps.showBookingBar) {
            this.setState({
                ...this.state,
                ...this.props
            })
        }
        if (this.props.showQuoteBar !== prevProps.showQuoteBar) {
            this.setState({
                ...this.state,
                ...this.props
            })
        }
        if (this.props.selectedShipmentId !== prevProps.selectedShipmentId) {
            await this.setState({
                ...this.state,
                ...this.props
            })
            this.props.selectedShipmentId ? await this.handleSubmit(sessionStorage.getItem('tenant'), 'fromTable') : voidHandler()
        }
        if (this.props.showBookingTemplateBar !== prevProps.showBookingTemplateBar) {
            await this.setState({
                ...this.state,
                ...this.props
            })
        }
        if (this.props.shipmentFileNo !== prevProps.shipmentFileNo) {
            await this.setState({
                ...this.state,
                ...this.props
            })
            this.props.shipmentFileNo ? await this.handleCMUSShipmentSubmit(false) : voidHandler()
        }
    }
    handleCMUSShipmentSubmit = async (isFromTT, event) => {
        const { intl, CpError, loading } = this.props
        const { trackingNo } = this.state
        if (event !== undefined) {
            event.preventDefault();
        }
        if (isFromTT) {
            if (trackingNo === '' || trackingNo.trim().length === 0) {
                CpError.setTrackingError(intl.formatMessage({ id: 'requiredErrormsg' }))
                return;
            }
            await this.setState({
                shipmentFileNo: trackingNo.trim()
            })
        }
        this.setState({
            error: false,
            errorMsg: ''
        })
        loading.setLoadingmsg(true)
        const headerResponse = await getShipmentHeaders(this.state.shipmentFileNo);
        if (headerResponse && headerResponse.status === 200 && headerResponse.data && headerResponse.data.data[0]) {
            await this.setState({
                cmusShipmentHeaderData: headerResponse.data.data[0],
                showCMUSShipmentBar: true, trackingNo: '',
                cmusShipmentfileType: (headerResponse.data.data[0].header && headerResponse.data.data[0].header.fileType) ? headerResponse.data.data[0].header.fileType : 'REGULAR'
            })
        }
        else {
            if (headerResponse && headerResponse.data && headerResponse.data.fieldErrors && headerResponse.data.fieldErrors.length > 0) {
                this.setState({
                    error: true,
                    errorMsg: headerResponse.data.fieldErrors[0]
                })
            } else {
                this.setState({
                    error: true,
                    errorMsg: intl.formatMessage({ id: 'somethingWentWrong' })
                })
            }
        }
        loading.setLoadingmsg(false)
    }
    fetchShipments = async (from, data, baseSystem) => {
        if (from === 'search') {
            baseSystem === "CMUS" ? await this.handleCMUSSearch() : await this.handleSearch(data);
            await this.props.history.push({
                pathname: "/",
                state: { widgets: false }
            })
            await this.props.history.push({
                pathname: '/shipments',
                state: { data: data }
            });
            await this.setState({
                searchGrid: true,
            })
        }
    }

    handleMore(index) {
        const lineIndex = ((index) * 10) + 3
        var rows = document.querySelectorAll('#cpTable tr');
        //line is zero-based
        //line is the row number that you want to see into view after scroll    
        rows[lineIndex].scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    }
    handleBookings = async () => {
        this.setState({
            isMobileMenuOpen: false,
            mobileMoreAnchorEl: null
        })
        await sessionStorage.removeItem("bookingsHeading")
        const { CpError } = this.props;
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
        if (this.props.history.location.pathname === "/bookings") {
            await this.props.history.push("/reload")
            this.props.history.push("/bookings")
        }
        else {
            this.props.history.push("/bookings")
        }
    }
    handleQuotes = async () => {
        this.setState({
            isMobileMenuOpen: false,
            mobileMoreAnchorEl: null
        })
        await sessionStorage.removeItem("quotesHeading")
        const { CpError } = this.props;
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
        if (this.props.history.location.pathname === "/quotes") {
            await this.props.history.push("/reload")
            this.props.history.push("/quotes")
        }
        else {
            this.props.history.push("/quotes")
        }
    }
    handleMangeBookingTemplates = async () => {
        this.setState({
            isMobileMenuOpen: false,
            mobileMoreAnchorEl: null
        })
        //await sessionStorage.removeItem("templatesHeading")
        const { CpError } = this.props;
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
        if (this.props.history.location.pathname === "/templates") {
            await this.props.history.push("/reload")
            this.props.history.push("/templates")
        }
        else {
            this.props.history.push("/templates")
        }
    }
    validateInput = (input) => {
        let regexp = /^[a-zA-Z0-9 ,/-]*$/;
        if (input === '') {
            return false
        }
        else if (regexp.test(input)) {
            return true
        }
        else {
            return false
        }

    }
    getErrormsg = (input) => {
        let regexp = /^[a-zA-Z0-9 ,/-]*$/;
        const { intl } = this.props
        if (input === '') {
            return intl.formatMessage({ id: 'requiredErrormsg' })
        }
        else if (regexp.test(input)) {
            return ''
        }
        else {
            return intl.formatMessage({ id: 'regularexpErrormsg' })
        }

    }
    findOrginOrDestination = (el, isOrgin, isDestination) => {
        if (el !== null && el !== undefined && el.bizType !== null && el.bizType !== undefined) {
            if (isOrgin) {
                if (el.hasOwnProperty('porName')) {
                    return el.porName;
                }
            } else if (isDestination) {
                if (el.bizType === 'Air' || el.bizType === 'Sea') {
                    return sessionStorage.getItem('tenant') === 'EIMSKIP' ? (el.devryName ? el.devryName : '') : (el.destName ? el.destName : '');
                } else if (el.bizType === 'Inland' && el.hasOwnProperty('devryName')) {
                    return el.devryName;
                }
            }
        }
        return '';
    }
    handleSubmit = async (tenant, type, event) => {
        const { loading, CpError, intl } = this.props
        if (event !== undefined) {
            event.preventDefault();
        }
        await this.setState({ success: false, error: false })
        loading.setLoadingmsg(true)
        if (type === 'reloadFromShipment') {
            this.setState({
                success: true, successMsg: 'Successful', type: type
            })
        }
        type === 'fromTable' || type === 'reloadFromShipment' ? this.setState({ shipmentDisplayType: 'fromTable' }) : this.setState({ shipmentDisplayType: 'fromSearch' })

        const { trackingNo, trackingOption } = this.state
        const trackingId = type === 'fromTable' || type === 'reloadFromShipment' ? this.state.selectedShipmentId : trackingNo
        if (this.validateInput(trackingId) === false) {
            CpError.setTrackingError(this.getErrormsg(trackingId))
        }
        else {
            const result = await getTrackingDetails(trackingOption, trackingId.trim(), type);
            let data = {
                shipments: []
            };
            if (result) {
                if (result.status !== 200) {
                    this.setState({
                        error: true,
                        errorMsg: result.status === 204 ? intl.formatMessage({ id: 'supplyValidDataMsg' }) : intl.formatMessage({ id: 'somethingWentWrong' }),
                        recordIndex: 1,
                        searchResults: [],
                        shipmentRetrivalLink: '',
                        shipmentLinkData: ''
                    })
                }

                else if (result.status === 200) {
                    this.setState({
                        trackingNo: '',
                        showSidebar: true,
                        trackingData: result.data ? type === 'fromTable' || type === 'reloadFromShipment' ? result.data : result.data.shipments[0] : '',
                        statusCode: result.status,
                        recordIndex: 1,
                        searchResults: [],
                        shipmentRetrivalLink: '',
                        shipmentLinkData: '',
                        showFavouritiesBar: false
                    });
                    (type === 'fromTable' || type === 'reloadFromShipment') && result.data ? data.shipments.push(result.data) : data = result.data
                    if (data && data.shipments && data.shipments.length > 0) {
                        let updatedResult = data.shipments.map(el => {
                            el.origin = this.findOrginOrDestination(el, true, false);
                            el.destination = this.findOrginOrDestination(el, false, true);
                            el.vesselName = el.hasOwnProperty("vesselName") && el.hasOwnProperty("vesselVoyage") ? (el.vesselName + ' / ' + el.vesselVoyage) : el.hasOwnProperty("vesselName") && el.vesselVoyage === undefined ? el.vesselName :
                                el.vesselName === undefined && el.hasOwnProperty("vesselVoyage") ? (' / ' + el.vesselVoyage) : ''
                            el.polName = el.hasOwnProperty("polName") && el.hasOwnProperty("polEtdDate") ? (el.polName + ' / ' + processDate(el.polEtdDate)) : el.hasOwnProperty("polName") && el.polEtdDate === undefined ? el.polName :
                                el.polName === undefined && el.hasOwnProperty("polEtdDate") ? (' / ' + processDate(el.polEtdDate)) : ''
                            el.podName = el.hasOwnProperty("podName") && el.hasOwnProperty("podEtaDate") ? (el.podName + ' / ' + processDate(el.podEtaDate)) : el.hasOwnProperty("podName") && el.podEtaDate === undefined ? el.podName :
                                el.podName === undefined && el.hasOwnProperty("podEtaDate") ? (' / ' + processDate(el.podEtaDate)) : ''
                            el.containers = el.hasOwnProperty("containers") ? this.updateContainerdata(el.containers) : null
                            el.statusList = el.hasOwnProperty("statusList") ? this.updateStatusList(el.statusList) : null;
                            el.documents = el.hasOwnProperty("documents") ? this.updateDocumentsList(el.documents) : null
                            el.totPcs = el.hasOwnProperty("totPcs") && el.hasOwnProperty("totPcsUt") ? (el.totPcs + ' ' + el.totPcsUt) : el.hasOwnProperty("totPcs") && el.totPcsUt === undefined ? el.totPcs : ''
                            el.totGWgt = el.hasOwnProperty("totGWgt") && el.hasOwnProperty("totGWgtUt") ? (el.totGWgt + ' ' + el.totGWgtUt) : el.hasOwnProperty("totGWgt") && el.totGWgtUt === undefined ? el.totGWgt : ''
                            el.jobRoutes = el.jobRoutes ? this.updateRoutingData(el.jobRoutes) : null
                            return el;
                        })
                        await this.setState({
                            searchResults: (updatedResult && updatedResult.length > 0) ? updatedResult : [],
                            trackingData: updatedResult ? updatedResult[0] : '',
                            showBookingBar: false,
                            showSidebar: true,
                            selectedShipmentId: updatedResult && updatedResult[0] ? updatedResult[0].gShpId : ''
                        });
                    }
                    if (result.status === 200) {
                        let retrivalURL = ''
                        if (type === 'trachShpmt' || type === '') {
                            retrivalURL = `${window.location.protocol + '//' + window.location.host}/anonymous/#/${sessionStorage.getItem('tenant-url')}/?${trackingOption}=${trackingNo}`
                            this.setState({ shipmentRetrivalLink: retrivalURL, shipmentLinkData: trackingNo })
                        } else if (type === 'fromTable' || type === 'reloadFromShipment') {
                            let queryParam = ''
                            if (data.shipments[0].jobNo && data.shipments[0].jobNo !== '') {
                                queryParam = `?JobNo=${data.shipments[0].jobNo}`
                            } else if (data.shipments[0].bookingNo && data.shipments[0].bookingNo !== '') {
                                queryParam = `?BookingNos=${data.shipments[0].bookingNo}`
                            }
                            retrivalURL = `${window.location.protocol + '//' + window.location.host}/anonymous/#/${sessionStorage.getItem('tenant-url')}/${queryParam}`
                            this.setState({ shipmentRetrivalLink: retrivalURL, shipmentLinkData: data.shipments[0].jobNo ? data.shipments[0].jobNo : data.shipments[0].bookingNo })
                        }
                    }
                }
                else
                    this.setState({ showSidebar: false, trackingData: result.data.shipments.length > 0 ? result.data.shipments[0] : '', statusCode: result.status })
            }
        }
        loading.setLoadingmsg(false)
    }
    selectChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            trackingNo: ''
        });
        const { CpError } = this.props;
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
    }
    handleDrawer = () => {
        const { showSidebar } = this.state
        this.setState({ showSidebar: !showSidebar })
    }
    handleEbooking = (showConfirmBox) => {
        const { showBookingBar } = this.state;
        if (showConfirmBox === true) {

            this.setState({
                showConfirm: true,
                confirmFor: "booking"
            })
        }
        else {
            this.setState({
                showConfirm: false,
                isMobileMenuOpen: false,
                mobileMoreAnchorEl: null,
                showBookingBar: !showBookingBar, bookingData: undefined,
                destinationCities: undefined, originCities: undefined, polCities: undefined, podCities: undefined

            })
            const { CpError } = this.props;
            if (CpError.trackingError !== '') {
                CpError.setTrackingError('')
            }
        }

    }
    handleQuote = (showConfirmBox) => {
        const { showQuoteBar } = this.state;
        if (showConfirmBox === true) {
            this.setState({
                showConfirm: true,
                confirmFor: "quote"
            })
        }
        else {
            this.setState({
                showConfirm: false,
                isMobileMenuOpen: false,
                mobileMoreAnchorEl: null,
                showQuoteBar: !showQuoteBar, quoteData: undefined
            })
            const { CpError } = this.props;
            if (CpError.trackingError !== '') {
                CpError.setTrackingError('')
            }
        }
    }
    handleBookingTemplate = (showConfirmBox) => {
        const { showBookingTemplateBar } = this.state;
        if (showConfirmBox === true) {
            this.setState({
                showConfirm: true,
                confirmFor: "bookingTemplate"
            })
        }
        else {
            this.setState({
                showConfirm: false,
                isMobileMenuOpen: false,
                mobileMoreAnchorEl: null,
                showBookingTemplateBar: !showBookingTemplateBar, bookingTemplateData: undefined,
                destinationCities: undefined, originCities: undefined, polCities: undefined, podCities: undefined
            })
            const { CpError } = this.props;
            if (CpError.trackingError !== '') {
                CpError.setTrackingError('')
            }
        }

    }
    handleSearch = () => {
        const { showSearchBar } = this.state;
        this.setState({
            isMobileMenuOpen: false,
            mobileMoreAnchorEl: null,
            showSearchBar: !showSearchBar
        })
        const { CpError } = this.props;
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
    }
    handleSuccess = async (type, msg, from) => {
        await this.setState({ success: false })
        this.setState({ success: true, type: type, successMsg: msg, from: from });
        const { CpError } = this.props;
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
    }
    handleClose = () => {
        this.setState({ success: false })
        const { CpError } = this.props;
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
    }
    handleMobileMenuOpen = (event) => {
        this.setState({
            mobileMoreAnchorEl: event.currentTarget,
            isMobileMenuOpen: true
        })
        const { CpError } = this.props;
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
    };
    handleMobileMenuClose = () => {
        this.setState({
            isMobileMenuOpen: false,
            mobileMoreAnchorEl: null
        })
    };
    handleShipmentsCMUS = () => {
        const { showCMUSShipmentBar } = this.state
        this.setState({ showCMUSShipmentBar: !showCMUSShipmentBar })
    }
    handleAccepted = () => {
        const { confirmFor } = this.state
        switch (confirmFor) {
            case "booking":
                this.handleEbooking()
                break;
            case "quote":
                this.handleQuote()
                break;
            case "bookingTemplate":
                this.handleBookingTemplate()
                break;

            default:
                break;
        }
    }
    renderMobileMenu = (tenant) => {
        const { mobileMenuId, mobileMoreAnchorEl, isMobileMenuOpen } = this.state
        const { cpContext } = this.props;
        return (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem onClick={this.homePage} id="home">
                    <p style={{ marginBottom: '0px', fontSize: '14px' }}>{< FormattedMessage id="home" />}</p>
                </MenuItem>
                {tenant.accessLevels.accessToShipments && cpContext.accessToShipments ?
                    <MenuItem onClick={this.handleSearch} id="searchShipments">
                        <p style={{ marginBottom: '0px', fontSize: '14px' }}>{< FormattedMessage id="homepage_Menu_Search_Shipments" />}</p>
                    </MenuItem> : null}
                {tenant.accessLevels.accessToQuotes && cpContext.accessToQuotes ?
                    <MenuItem onClick={this.handleQuotes} id="manageQuotes">
                        <p style={{ marginBottom: '0px', fontSize: '14px' }}>{< FormattedMessage id="homepage_Menu_Manage_Quotes" />}</p>
                    </MenuItem> : null}
                {tenant.accessLevels.accessToBookings && cpContext.accessToBookings ?
                    <MenuItem onClick={this.handleBookings} id="manageBooking">
                        <p style={{ marginBottom: '0px', fontSize: '14px' }}>{< FormattedMessage id="homepage_Menu_Manage_Bookings" />}</p>
                    </MenuItem> : null}
                {tenant.accessLevels.accessToBookingTemplates && cpContext.accessToBookingTemplates ?
                    <MenuItem onClick={this.handleMangeBookingTemplates} id="manageTemplates">
                        <p style={{ marginBottom: '0px', fontSize: '14px' }}>{< FormattedMessage id="homepage_Menu_Manage_Templates" />}</p>
                    </MenuItem> : null}
                {tenant.accessLevels.accessToBookings && cpContext.accessToBookings ?
                    <MenuItem onClick={this.handleEbooking} id='newBooking'>
                        <p style={{ marginBottom: '0px', fontSize: '14px' }}>{< FormattedMessage id="homepage_New_Booking_Button" />} </p>
                    </MenuItem> : null}
                {tenant.accessLevels.accessToQuotes && cpContext.accessToQuotes ?
                    <MenuItem onClick={this.handleQuote} id='newQuote'>
                        <p style={{ marginBottom: '0px', fontSize: '14px' }}>{< FormattedMessage id="homepage_New_Quote_Button" />} </p>
                    </MenuItem> : null}
            </Menu>
        );
    }
    handleCMUSSearch = () => {
        const { showCMUSSearchBar } = this.state;
        this.setState({
            isMobileMenuOpen: false,
            mobileMoreAnchorEl: null,
            showSearchBar: false,
            showCMUSSearchBar: !showCMUSSearchBar
        })
        const { CpError } = this.props;
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
    }
    homePage = () => {
        this.setState({
            isMobileMenuOpen: false,
            mobileMoreAnchorEl: null
        })
        this.props.history.push("/")
    }
    handlePinOrUnPin = async (favoriteId, type, isFavorite) => {
        const { trackingData, showSidebar, showContainerBar, containerData, showFavouritiesBar } = this.state
        const { intl } = this.props
        const { containers } = trackingData
        const res = await saveOrUpdateFavorites(favoriteId, type, isFavorite)
        this.setState({ showSnackBar: false })
        if (res.status === 200 || res.status === 201) {
            if (showFavouritiesBar) {
                this.onFavouritiesIconClick();
            }
            type === "shipment" ?
                this.setState({
                    trackingData: {
                        ...trackingData,
                        favoriteShp: isFavorite
                    },
                    showSnackBar: true,
                    snackBarMsg: intl.formatMessage({ id: 'favoriteShipmentSuccessMsg' }, { addedOrRemoved: isFavorite ? "added to" : 'removed from' }),
                    snackBarType: 'success',
                    snackBarId:JSON.parse(res.config.data)?.favoriteRefId
                })
                : type === "container" ?
                    (showSidebar ? this.setState({
                        trackingData: {
                            ...trackingData,
                            containers: containers.map(container => { return container.intContainerNo === favoriteId ? { ...container, favoriteCont: isFavorite } : container })
                        },
                        showSnackBar: true,
                        snackBarMsg: intl.formatMessage({ id: 'favoriteContainerSuccessMsg' }, { addedOrRemoved: isFavorite ? "added to" : 'removed from' }),
                        snackBarType: 'success',
                        snackBarId:JSON.parse(res.config.data)?.favoriteRefId

                    })
                        : showContainerBar ?
                            this.setState({
                                containerData: {
                                    ...containerData,
                                    favoriteCont: isFavorite
                                },
                                showSnackBar: true,
                                snackBarMsg: intl.formatMessage({ id: 'favoriteContainerSuccessMsg' }, { addedOrRemoved: isFavorite ? "added to" : 'removed from' }),
                                snackBarType: 'success',
                                snackBarId:JSON.parse(res.config.data)?.favoriteRefId

                            })
                            : voidHandler())
                    : voidHandler()
        }
        else {
            this.setState({
                showSnackBar: true,
                snackBarMsg: res.data && res.data.fieldErrors && res.data.fieldErrors[0] ? res.data.fieldErrors[0] : intl.formatMessage({ id: 'somethingWentWrong' }),
                snackBarType: 'info'
            })
        }
    }
    onFavouritiesIconClick = async () => {
        const { intl } = this.props;
        let favResponse = await getFavourtieList();
        if (favResponse && (favResponse.status === 200 || favResponse.status === 204)) {
            this.setState({
                favouritiesData: favResponse.data && favResponse.data.favorites ? favResponse.data.favorites : {},
                showFavouritiesBar: true
            })
        }
        else {
            await this.setState({ showSnackBar: false })
            await this.setState({
                showSnackBar: true,
                snackBarMsg: (favResponse && favResponse.data && favResponse.data.fieldErrors && favResponse.data.fieldErrors[0]) ? favResponse.data.fieldErrors[0] : intl.formatMessage({ id: 'somethingWentWrong' }),
                snackBarType: 'info'
            })
        }
    }
    handleFavDrawer = () => {
        this.setState({
            showFavouritiesBar: false
        })
    }
    handleContainerDrawer = () => {
        this.setState({
            showContainerBar: false
        })
    }
    onFavShipmentClick = (item) => {
        this.setState({
            selectedShipmentId: item.gShpId ? item.gShpId : ''
        })
        this.handleSubmit(sessionStorage.getItem('tenant'), 'fromTable')
    }
    onFavContainerClick = async (item) => {
        const { intl, loading } = this.props
        loading.setLoadingmsg(true)
        let cntrRes = await fetchContainerDetails(item.intContainerNo ? item.intContainerNo : '')
        await this.setState({ showSnackBar: false })
        if (cntrRes) {
            if (cntrRes.status === 200) {
                let container = cntrRes.data ? cntrRes.data : null
                const updatedRes = container ? ({
                    ...container,
                    totWgt: cntrRes.data.hasOwnProperty("totWgt") && container.hasOwnProperty("totWgtUt") ? (container.totWgt + ' ' + container.totWgtUt) : container.hasOwnProperty("totWgt") && container.totWgtUt === undefined ? container.totWgt : '',
                    totPcs: cntrRes.data.hasOwnProperty("totPcs") && container.hasOwnProperty("totPcsUt") ? (container.totPcs + ' ' + container.totPcsUt) : container.hasOwnProperty("totPcs") && container.totPcsUt === undefined ? container.totPcs : '',
                    totGrossWgt: container.hasOwnProperty("totGrossWgt") && container.hasOwnProperty("totWgtUt") ? (container.totGrossWgt + ' ' + container.totWgtUt) : container.hasOwnProperty("totGrossWgt") && container.totWgtUt === undefined ? container.totGrossWgt : '',
                    containerStatusList: this.updateStatusList(container.containerStatusList)
                }) : {}
                this.setState({
                    containerData: updatedRes,
                    showContainerBar: true,
                    showFavouritiesBar: false
                })
            }
            else if (cntrRes.status === 204) {
                await this.setState({
                    showSnackBar: true,
                    snackBarMsg: intl.formatMessage({ id: 'noContainerData' }),
                    snackBarType: 'info'
                })
            }
            else {
                await this.setState({
                    showSnackBar: true,
                    snackBarMsg: cntrRes.data && cntrRes.data.fieldErrors && cntrRes.data.fieldErrors[0] ? cntrRes.data.fieldErrors[0] : intl.formatMessage({ id: 'somethingWentWrong' }),
                    snackBarType: 'info'
                })
            }
        }
        loading.setLoadingmsg(false)
    }
    handleCloseUserContextMenu = () => {
        this.setState({
            userContextMenuEl: null,
            isUserContextMenuOpen: false,
        })
    }

    handleUserContextMenuOpen = (event) => {
        const { CpError } = this.props;
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
        this.setState({
            userContextMenuEl: event.currentTarget,
            isUserContextMenuOpen: true
        })
    }

    handleCopyRightBar = () => {
        const { CpError } = this.props;
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
        this.setState({
            showCopyRight: !this.state.showCopyRight
        })
    }

    renderUserContextMenu = () => {
        const { userContextMenuEl, isUserContextMenuOpen } = this.state
        const { showChangePassword, classes, handleEnableWidgetPreferences } = this.props;
        return (
            <UserContextMenuSection classes={classes} handleCloseUserContextMenu={this.handleCloseUserContextMenu}
                userContextMenuEl={userContextMenuEl}
                isUserContextMenuOpen={isUserContextMenuOpen}
                showChangePassword={showChangePassword}
                handleEnableWidgetPreferences={handleEnableWidgetPreferences}
                handleCopyRightBar={this.handleCopyRightBar} />
        );
    }
    render() {
        const {
            trackingNo, trackingOption, trackingData,
            shipmentDisplayType,
            showSidebar, showBookingBar, showSearchBar,
            success, successMsg, recordIndex, searchResults,
            type, bookingData, destinationCities, originCities, polCities, podCities,
            mobileMenuId, isMobileMenuOpen, isDesktop, showConfirm, error, errorMsg, showBookingTemplateBar,
            showQuoteBar, quoteData, from, bookingTemplateData, showCMUSShipmentBar, cmusShipmentHeaderData, showCMUSSearchBar,
            trackingOptionCMUS, shipmentFileNo, shipmentRetrivalLink, shipmentLinkData, cmusShipmentfileType,
            showSnackBar, snackBarMsg, snackBarType, showContainerBar, showFavouritiesBar, favouritiesData, containerData,
            isUserContextMenuOpen, showCopyRight,snackBarId
        }
            = this.state
        const { classes, intl, CpError, cpContext, theme, backToHome } = this.props
        const primaryColor = theme.palette.primary.main
        return (
            <>
                {CpError.code === 200 || CpError.code === 204 ?
                    <TenantConsumer>
                        {tenant =>
                            <div>
                                {
                                    success ?
                                        type !== "reloadFromShipment" ?
                                            getSnackBar(` ${from} ${type} Request is ${successMsg ? successMsg : "SUCCESS"}`, true)
                                            : type === 'reloadFromShipment'
                                                ? getSnackBar(`Shipment Save Request is ${successMsg ? successMsg : "SUCCESS"}`, true)
                                                : null
                                        :
                                        null
                                }
                                {
                                    error ? getSnackBar(errorMsg, true, 'info') : null
                                }
                                {showSnackBar ? getSnackBar(snackBarMsg, true, snackBarType,null, snackBarId) : null}
                                {showCopyRight ?
                                    <CopyRight show={showCopyRight} handleClose={this.handleCopyRightBar} />
                                    : null}
                                <div >
                                    <AppBar style={{ backgroundColor: 'white' }} position='static'>
                                        <Toolbar variant='dense' style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                                            {/* for deployment or commit */}
                                            <img className={`img ${classes.cursorOnHover}`} src={`/secure-ui/themes/${tenant.tenant}/company.gif`}
                                                height='40px' width='180px' alt="Logo" style={{ marginTop: '2px', objectFit: 'scale-down' }}
                                                onClick={backToHome}
                                            />
                                            {/* for local devlopment */}
                                            {/* <img className="img" src={require(`../images/${sessionStorage.getItem('tenant')}/company.gif`)} height='40px' width='160px' alt="Logo" style={{ marginTop: '2px' }} /> */}
                                            <div style={{ flexGrow: 1, color: '#4A4E50', padding: '0 2rem', fontWeight: 'bold' }}>
                                                Customer Portal
                                            </div>
                                            {tenant.accessLevels.accessToShipments === true && cpContext.accessToShipments ?
                                                <form style={{ display: 'flex', flexDirection: 'row', margin: '5px', width: '50rem', alignItems: 'flex-start' }} onSubmit={(event) => tenant.baseSystem === "TMFF" ? this.handleSubmit(tenant.tenant, '', event) : this.handleCMUSShipmentSubmit(true, event)}>
                                                    <div style={{ marginRight: '1rem' }}>
                                                        {tenant.baseSystem === 'TMFF' ?
                                                            <CpDropDown name='trackingOption' items={trackingOptions} onChange={this.selectChange} value={trackingOption} backgroundColor='white' />
                                                            : <CpDropDown name='trackingOptionCMUS' items={TrackingOptionsCMUS} onChange={this.selectChange} value={trackingOptionCMUS} backgroundColor='white' />}
                                                    </div>
                                                    <CpInput error={CpError.trackingError !== '' ? true : false}
                                                        placeholder={intl.formatMessage({ id: 'homepage_input_placeHolder' })}
                                                        value={trackingNo} onChange={this.handleChange}
                                                        helperText={CpError.trackingError !== '' ? CpError.trackingError : ''}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end" >
                                                                    <SearchIcon style={{ fontSize: '24px', cursor: 'pointer', color: 'white' }} type='submit' onClick={() => tenant.baseSystem === 'TMFF' ? this.handleSubmit(tenant.tenant, 'trachShpmt') : this.handleCMUSShipmentSubmit(true)} />
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                    <button type='submit' style={{ display: 'none' }}></button>
                                                </form>
                                                : null}
                                            <div className={classes.sectionDesktop}>
                                                <a href={`${window.location.protocol + '//' + window.location.host}${tenant.baseSystem === 'TMFF' ? HOMEPAGE_HELP_TMFF : HOMEPAGE_HELP_CMUS}`} target="_blank" style={{ color: "inherit" }} rel="noopener noreferrer">
                                                    <IconButton style={{ padding: '5px' }}
                                                        aria-label="account of current user"
                                                        aria-controls="primary-search-account-menu"
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                    >
                                                        <HelpIcon style={{ fontSize: '30px', color: "#6E787D",marginLeft:'1rem' }} />
                                                    </IconButton>
                                                </a>
                                                <IconButton style={{ padding: '0px',marginLeft:'2rem' }}
                                                    aria-label="show more"
                                                    aria-controls={mobileMenuId}
                                                    aria-haspopup="true"
                                                    onClick={event => this.handleUserContextMenuOpen(event)}
                                                    classes={{ root: classes.iconbutton }}
                                                >
                                                    <List style={{ marginRight: '0px !important' }} >
                                                        <ListItem classes={{ root: classes.listItemRoot, gutters: classes.gutters }} >
                                                            <ListItemText primary={fetchUser()} secondary='' style={{ color: '#6E787D', fontWeight: 'bold' }} />
                                                            <ListItemAvatar style={{ minWidth: '30px',marginLeft:'1rem' }}>
                                                                <Avatar style={{ backgroundColor: 'white' }}>
                                                                    <AccountCircleIcon color="inherit" style={{ fontSize: '30px', color: "#6E787D" }} />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                        </ListItem>
                                                    </List>
                                                </IconButton>
                                            </div>
                                            <div className={classes.sectionMobile}>
                                                <a href={`${window.location.protocol + '//' + window.location.host}${tenant.baseSystem === 'TMFF' ? HOMEPAGE_HELP_TMFF : HOMEPAGE_HELP_CMUS}`} target="_blank" style={{ color: "inherit" }} rel="noopener noreferrer">
                                                    <IconButton style={{ padding: "12px 6px" }}
                                                        aria-label="account of current user"
                                                        aria-controls="primary-search-account-menu"
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                    >
                                                        <HelpIcon style={{ fontSize: '24px', color: "#6E787D" }} />
                                                    </IconButton>
                                                </a>
                                                <IconButton style={{ flex: 'inherit', overflowWrap: 'anywhere', padding: "12px 6px" }}
                                                    aria-label="show more"
                                                    aria-controls={mobileMenuId}
                                                    aria-haspopup="true"
                                                    onClick={(event) => this.handleUserContextMenuOpen(event)}
                                                    color="inherit"
                                                >
                                                    <AccountCircleIcon style={{ fontSize: '24px', color: "#6E787D" }} />
                                                </IconButton>
                                            </div>
                                        </Toolbar>
                                    </AppBar>
                                    {tenant.baseSystem === "TMFF" ?
                                        <AppBar position="static" id="back-to-top-anchor" variant='outlined' style={{ flexDirection: 'row',border:'0px' }} >
                                            <div className={classes.sectionDesktop}>
                                                <Tabs
                                                    value={false}
                                                    textColor="primary"
                                                    aria-label="menu tabs"
                                                    style={{ height: '40px', minHeight: '40px', maxHeight: '40px' }}
                                                >
                                                    <Tab className={classes.tab} style={{ backgroundColor: '#1a5178', minWidth: '12rem' }} id="favouritiesPinorUnpin" onClick={this.onFavouritiesIconClick}
                                                        classes={{ wrapper: classes.iconLabelWrapper, labelContainer: classes.labelContainer }}
                                                        icon={<PushPinIcon style={{ marginBottom: '0', marginRight: '1rem' }} />} label="Pinned" />
                                                    {tenant.accessLevels.accessToShipments && cpContext.accessToShipments ?
                                                        <Tab className={classes.tab} label={< FormattedMessage id="homepage_Menu_Search_Shipments" />} onClick={this.handleSearch} /> : null
                                                    }
                                                    {tenant.accessLevels.accessToQuotes && cpContext.accessToQuotes ?
                                                        <Tab className={classes.tab} label={< FormattedMessage id="homepage_Menu_Manage_Quotes" />} onClick={this.handleQuotes} /> : null
                                                    }
                                                    {tenant.accessLevels.accessToBookings && cpContext.accessToBookings ?
                                                        <Tab className={classes.tab} label={< FormattedMessage id="homepage_Menu_Manage_Bookings" />} onClick={this.handleBookings} /> : null
                                                    }
                                                    {tenant.accessLevels.accessToBookingTemplates && cpContext.accessToBookingTemplates ?
                                                        <Tab className={classes.tab} label={< FormattedMessage id="homepage_Menu_Manage_Templates" />} onClick={this.handleMangeBookingTemplates} /> : null
                                                    }
                                                </Tabs>
                                            </div>
                                            <div className={classes.sectionMobile}>
                                                <IconButton style={{ padding: '0%', marginRight: '0.5%' }} id="favouritiesPinorUnpin" onClick={this.onFavouritiesIconClick} >
                                                    <PushPinIcon style={{ fontSize: '20px', padding: '0%', color: getContrastColor(primaryColor) }} />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="show menu"
                                                    aria-controls={mobileMenuId}
                                                    aria-haspopup="true"
                                                    onClick={this.handleMobileMenuOpen}
                                                    color="inherit"
                                                >
                                                    <MenuIcon style={{ color: getContrastColor(primaryColor), fontSize: '26px' }} />
                                                </IconButton>
                                            </div>
                                            <div style={{ flexGrow: 1, color: 'white' }}></div>
                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.sectionDesktop}>
                                                    {
                                                        tenant.accessLevels.accessToQuotes && cpContext.accessToQuotes ? <CPButton variant='requestButton'  onClick={this.handleQuote} id="newquote" ><SendIcon /><span style={{ paddingLeft: '1rem' }}>{< FormattedMessage id="homepage_New_Quote_Button" />}</span> </CPButton> : null
                                                    }
                                                    {
                                                        tenant.accessLevels.accessToBookings && cpContext.accessToBookings ? <CPButton variant='requestButton'  onClick={this.handleEbooking} id='newbooking'><SendIcon /><span style={{ paddingLeft: '1rem' }}>{< FormattedMessage id="homepage_New_Booking_Button" />} </span></CPButton> : null
                                                    }
                                                </div>
                                                {/*  */}
                                            </div>
                                        </AppBar>
                                        : tenant.baseSystem === "CMUS" ?
                                            <AppBar position="static" id="back-to-top-anchor" variant='outlined' style={{ flexDirection: 'row',border:'0px' }}>
                                                <div className={classes.sectionDesktop}>
                                                    <Tabs value={false} textColor="primary" aria-label="menu tabs" style={{ height: '40px', minHeight: '40px', maxHeight: '40px' }}>
                                                        <Tab className={classes.tab} label={< FormattedMessage id="homepage_Menu_Search_Shipments" />} onClick={this.handleCMUSSearch} />
                                                    </Tabs>
                                                </div>
                                            </AppBar>
                                            : null
                                    }
                                    {isMobileMenuOpen ? this.renderMobileMenu(tenant) : null}
                                    {isUserContextMenuOpen ? this.renderUserContextMenu() : null}
                                </div>
                                <CPConfirmBox show={showConfirm} message={intl.formatMessage({ id: "changesNotSavedMsg" })} handleRejected={() => { this.setState({ showConfirm: false }) }} handleAccepted={this.handleAccepted} />
                                {showSidebar && cpContext.userloggedIn ?
                                    <CpDrawer width={isDesktop ? `160vh` : '100%'} anchor='right' show={showSidebar} handleDrawer={this.handleDrawer}>
                                        <DetailedShipmentTMFF
                                            handleDrawer={this.handleDrawer}
                                            data={trackingData}
                                            cpContext={cpContext}
                                            recordIndex={recordIndex}
                                            handleSubmit={this.handleSubmit}
                                            resultCount={searchResults.length}
                                            handleShipmentNavigation={this.handleShipmentNavigation}
                                            shipmentDisplayType={shipmentDisplayType}
                                            shipmentRetrivalLink={shipmentRetrivalLink}
                                            shipmentLinkData={shipmentLinkData}
                                            handlePinOrUnPin={this.handlePinOrUnPin} />
                                    </CpDrawer>
                                    : null}
                                {showBookingBar && cpContext.userloggedIn ?
                                    <CpDrawer width={isDesktop ? `165vh` : '100%'} anchor='right' show={showBookingBar} handleDrawer={() => this.handleEbooking(bookingData ? bookingData.status === 'In Progress' ? true : false : true)}>
                                        <Booking
                                            handleDrawer={this.handleEbooking}
                                            handleSuccess={this.handleSuccess}
                                            data={bookingData}
                                            destinationCities={destinationCities}
                                            originCities={originCities}
                                            polCities={polCities}
                                            podCities={podCities}
                                            fromDashBoard={this.props.fromDashBoard} />
                                    </CpDrawer>
                                    : null}
                                {showQuoteBar && cpContext.userloggedIn ?
                                    <CpDrawer width={isDesktop ? `165vh` : '100%'} anchor='right' show={showQuoteBar} handleDrawer={() => this.handleQuote(quoteData ? quoteData.status === 'In Progress' ? true : false : true)}>
                                        <Quote
                                            handleDrawer={this.handleQuote}
                                            handleSuccess={this.handleSuccess}
                                            data={quoteData}
                                            destinationCities={destinationCities}
                                            originCities={originCities}
                                            polCities={polCities}
                                            podCities={podCities}
                                            fromDashBoard={this.props.fromDashBoard} />
                                    </CpDrawer>
                                    : null}
                                {showSearchBar && cpContext.userloggedIn ?
                                    <CpDrawer width={isDesktop ? `70vh` : '100%'} anchor='right' show={showSearchBar} handleDrawer={this.handleSearch}>
                                        <SearchShipments handleDrawer={this.handleSearch} fetchShipments={this.fetchShipments} />
                                    </CpDrawer>
                                    : null}

                                {showBookingTemplateBar && cpContext.userloggedIn ?
                                    <CpDrawer width={isDesktop ? `165vh` : '100%'} anchor='right' show={showBookingTemplateBar} handleDrawer={() => this.handleBookingTemplate(true)}>
                                        <BookingTemplate
                                            handleDrawer={this.handleBookingTemplate}
                                            handleSuccess={this.handleSuccess}
                                            data={bookingTemplateData}
                                            destinationCities={destinationCities}
                                            originCities={originCities}
                                            polCities={polCities}
                                            podCities={podCities}
                                            fromDashBoard={this.props.fromDashBoard} />
                                    </CpDrawer>
                                    : null}

                                {showCMUSShipmentBar && cpContext.userloggedIn ?
                                    <CpDrawer width={isDesktop ? `165vh` : '100%'} anchor='right' show={true} handleDrawer={this.handleShipmentsCMUS}>
                                        <DetailedShipmentCMUS
                                            handleDrawer={this.handleShipmentsCMUS}
                                            data={cmusShipmentHeaderData}
                                            shipmentFileType={cmusShipmentfileType}
                                            shipmentFileNo={shipmentFileNo}
                                        />
                                    </CpDrawer>
                                    : null}

                                {showCMUSSearchBar && cpContext.userloggedIn ?
                                    <CpDrawer width={isDesktop ? `70vh` : '100%'} anchor='right' show={showCMUSSearchBar} handleDrawer={this.handleCMUSSearch}>
                                        <CMUSSearchShipments
                                            handleDrawer={this.handleCMUSSearch}
                                            fetchShipments={this.fetchShipments} />
                                    </CpDrawer>
                                    : null}
                                {showContainerBar ?
                                    <CpDrawer width={isDesktop ? `130vh` : '100%'} anchor='right' show={showContainerBar} handleDrawer={this.handleContainerDrawer}>
                                        <ContainerDetails
                                            handleDrawer={this.handleContainerDrawer}
                                            contianerData={containerData}
                                            handlePinOrUnPin={this.handlePinOrUnPin}
                                        />
                                    </CpDrawer>
                                    : null}
                                {showFavouritiesBar ?
                                    <CpDrawer isforFavourities={true} width={isDesktop ? `60vh` : '100%'} anchor='left' height='calc(100% - 16%)' top='12%' show={showFavouritiesBar} handleDrawer={this.handleFavDrawer}>
                                        <FavouritesList
                                            handleDrawer={this.handleFavDrawer}
                                            favourities={favouritiesData}
                                            onFavShipmentClick={(item) => this.onFavShipmentClick(item)}
                                            onFavContainerClick={(item) => this.onFavContainerClick(item)}
                                        />
                                    </CpDrawer>
                                    : null}
                            </div>
                        }

                    </TenantConsumer> :
                    <ErrorPage>

                    </ErrorPage>
                }
            </>
        );
    }
}
export default injectIntl(withRouter(withError(withCPContext(withLoading(withTheme(withStyles(style)(Bar)))))))
import React, { Component } from 'react';
import BillsOfLading from './BillsOfLading';
import BillOfLading from './BillOfLading';
import BillOfLadingContainer from './BillOfLadingContainer';
import BillOfLadingCommodity from './BillOfLadingCommodity';
import { getBillsOfLadingService, getBillOfLading, getBillOfLadingContainers, getBillOfLadingCommodity } from '../../../sevices/CMUS/Services';
import withLoading from '../../common/withLoading';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
})
class BillOfLadingSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displaySection: {
                level0: { visible: true, data: null, noOfPages: 0, selectedPage: 1, totalRecords: 0, selectedRecord: 0 },
                level1: { visible: false, data: null, totalRecords: 0, selectedRecord: 0 },
                level2: { visible: false, data: null, totalRecords: 0, selectedRecord: 0 },
                level3: { visible: false, data: null }
            }
        }
    }
    componentDidMount = async () => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        await this.getBillsOfLading(1);
        await loading.setLoadindDoc(false);
    }

    getBillsOfLading = async (pageSelected) => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        let bolData = await getBillsOfLadingService(pageSelected);
        if (bolData && bolData.status === 200 && bolData.data) {
            await this.setState({
                displaySection: {
                    ...this.state.displaySection,
                    level0: {
                        visible: true,
                        data: bolData.data.data ? bolData.data.data : [],
                        noOfPages: bolData.data.maxResults !== 0 ? Math.ceil(bolData.data.totalRecords / bolData.data.maxResults) : 1,
                        selectedPage: pageSelected ? pageSelected : 1,
                        totalRecords: (bolData.data.data && bolData.data.data.length > 0) ? bolData.data.data.length : this.state.displaySection.level0.totalRecords
                    }
                }
            })
        }
        else {
            await this.setState({ displaySection: { ...this.state.displaySection, level0: { visible: true, data: null, noOfPages: 0, selectedPage: 1 } } })
        }
        loading.setLoadindDoc(false);
    }

    handleDisplayForward = async (level, data, index) => {
        const { loading } = this.props
        switch (level) {
            case 1:
                loading.setLoadindDoc(true);
                const bolData = await getBillOfLading(data.itNo, data.conveyanceSeqNo, data.idSeqNo);
                if (bolData && bolData.data) {
                    await this.setState({
                        displaySection: {
                            level0: { ...this.state.displaySection.level0, visible: false, selectedRecord: index ? index : this.state.displaySection.level0.selectedRecord },
                            level1: { visible: true, data: (bolData.data.data && bolData.data.data.length > 0) ? bolData.data.data[0] : null, totalRecords: (bolData.data.data && bolData.data.data[0] && bolData.data.data[0].inBondContainersList) ? bolData.data.data[0].inBondContainersList.length : this.state.displaySection.level1.totalRecords },
                            level2: { ...this.state.displaySection.level2, visible: false },
                            level3: { ...this.state.displaySection.level3, visible: false }
                        }
                    });
                }
                loading.setLoadindDoc(false);
                break
            case 2:
                loading.setLoadindDoc(true);
                const bolCntData = await getBillOfLadingContainers(data.itNo, data.conveyanceSeqNo, data.idSeqNo, data.noContainer);
                if (bolCntData && bolCntData.data) {
                    await this.setState({
                        displaySection: {
                            level0: { ...this.state.displaySection.level0, visible: false },
                            level1: { ...this.state.displaySection.level1, visible: false, selectedRecord: index ? index : this.state.displaySection.level1.selectedRecord },
                            level2: { visible: true, data: (bolCntData.data.data && bolCntData.data.data.length > 0) ? bolCntData.data.data[0] : null, totalRecords: (bolCntData.data.data && bolCntData.data.data[0] && bolCntData.data.data[0].inBondTariffList) ? bolCntData.data.data[0].inBondTariffList.length : 0 },
                            level3: { visible: false, data: null }
                        },
                    });
                }
                loading.setLoadindDoc(false);
                break;
            case 3:
                loading.setLoadindDoc(true);
                const bolCmdtyData = await getBillOfLadingCommodity(data.itNo, data.conveyanceSeqNo, data.idSeqNo, data.noContainer, data.seqNo);
                if (bolCmdtyData && bolCmdtyData.data) {
                    await this.setState({
                        displaySection: {
                            level0: { ...this.state.displaySection.level0, visible: false },
                            level1: { ...this.state.displaySection.level1, visible: false },
                            level2: { ...this.state.displaySection.level2, visible: false, selectedRecord: index ? index : this.state.displaySection.level2.selectedRecord },
                            level3: { visible: true, data: (bolCmdtyData.data.data && bolCmdtyData.data.data.length > 0) ? bolCmdtyData.data.data[0] : null }
                        },
                    });
                }
                loading.setLoadindDoc(false);
                break;
            default:
                break;
        }

    }

    handlePageClick = (pageIndex) => {
        this.getBillsOfLading(pageIndex);
    }
    handleDisplayBack = (level, data) => {
        switch (level) {
            case 0:
                this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: true },
                        level1: { ...this.state.displaySection.level1, visible: false },
                        level2: { ...this.state.displaySection.level2, visible: false },
                        level3: { ...this.state.displaySection.level3, visible: false }
                    }
                });

                break;
            case 1:
                this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: false },
                        level1: { ...this.state.displaySection.level1, visible: true },
                        level2: { ...this.state.displaySection.level2, visible: false },
                        level3: { ...this.state.displaySection.level3, visible: false }
                    }
                });
                break;
            case 2:
                this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: false },
                        level1: { ...this.state.displaySection.level1, visible: false },
                        level2: { ...this.state.displaySection.level2, visible: true },
                        level3: { ...this.state.displaySection.level3, visible: false }
                    }
                });
                break;
            case 3:
                this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: false },
                        level1: { ...this.state.displaySection.level1, visible: false },
                        level2: { ...this.state.displaySection.level2, visible: false },
                        level3: { ...this.state.displaySection.level3, visible: true }
                    }
                });
                break;

            default:
                this.setState({
                    displaySection: {
                        level0: { visible: true, data: data },
                        level1: { visible: false, data: null },
                        level2: { visible: false, data: null },
                        level3: { visible: false, data: null }
                    }
                });
                break;
        }
    }

    render() {
        const { displaySection } = this.state
        return (
            <div>
                {displaySection.level0.visible ? <BillsOfLading bolData={displaySection.level0.data} handleDisplayForward={this.handleDisplayForward}
                    noOfPages={displaySection.level0.noOfPages} selectedPage={displaySection.level0.selectedPage} handlePageClick={this.handlePageClick} /> : null}
                {displaySection.level1.visible ? <BillOfLading bolData={displaySection.level1.data} handleDisplayBack={this.handleDisplayBack}
                    totalRecords={displaySection.level0.totalRecords} selectedRecord={displaySection.level0.selectedRecord}
                    handleDisplayForward={this.handleDisplayForward} /> : null}
                {displaySection.level2.visible ? <BillOfLadingContainer bolCntData={displaySection.level2.data} handleDisplayBack={this.handleDisplayBack} handleDisplayForward={this.handleDisplayForward}
                    l1totalRecords={displaySection.level0.totalRecords} l1selectedRecord={displaySection.level0.selectedRecord}
                    l2totalRecords={displaySection.level1.totalRecords} l2selectedRecord={displaySection.level1.selectedRecord} /> : null}
                {displaySection.level3.visible ? <BillOfLadingCommodity bolCmdtyData={displaySection.level3.data} handleDisplayBack={this.handleDisplayBack}
                    l1totalRecords={displaySection.level0.totalRecords} l1selectedRecord={displaySection.level0.selectedRecord}
                    l2totalRecords={displaySection.level1.totalRecords} l2selectedRecord={displaySection.level1.selectedRecord}
                    l3totalRecords={displaySection.level2.totalRecords} l3selectedRecord={displaySection.level2.selectedRecord} /> : null}
            </div>
        )
    }
}

export default injectIntl(withLoading(withStyles(styles)(BillOfLadingSection)))
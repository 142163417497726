import React from 'react'
import { Dialog, withStyles, DialogContent, DialogActions, Divider, Grid, IconButton } from '@material-ui/core'
import { injectIntl } from 'react-intl';
import CPButton from './common/Button';
import image from '../images/e2open.gif'
import CloseIcon from '@material-ui/icons/Close';
import withTenant from './common/withTenant';

const style = {
    content: {
        flex: '1 1 auto',
        overflow: 'auto',
        padding: '2rem',
        fontSize: '14px',
        color: '#4A4E50',
    },
    header: {
        backgroundColor: '#E8F0F4',
        fontWeight: '600',
        fontSize: '14px',
        padding: '1.5rem 2rem'
    },
    iconButton: {
        padding: 0,
        float: 'right'
    },
    banner: {
        display: 'flex',
        flexDirection: 'row',
        height:'15vh',
    },
    copyRight: {
        color: '#4A4E50',
        padding: '0 3rem',
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '12px',
        alignSelf: 'center'
    },
    divider: {
        margin: '2rem 0'
    },
    versionBanner: {
        display: 'flex',
        flexDirection: 'column',
        color: '#4A4E50',
        fontWeight: '500'
    },
    logoImg:{
        maxWidth : '100%',
        maxHeight:'100%',
        objectFit :'contain',
    },
    bold: {
        fontWeight: 'bold'
    }
}
function CopyRight(props) {
    const { show, handleClose, classes, intl, tenantDetails } = props
    return (
        <Dialog open={show} width='sm' onClose={handleClose} >
            <div className={classes.header} id="alert-dialog-title">
                {intl.formatMessage({ id: 'aboutThisSoftware' })}
                <IconButton className={classes.iconButton} onClick={handleClose}>
                    <CloseIcon fontSize='large' />
                </IconButton>
            </div>
            <DialogContent classes={{ root: classes.content }}>
                <div className={classes.banner}>
                    <img src={image}  className={classes.logoImg} alt="E2open"/>
                    <div className={classes.copyRight}>
                        <span>{intl.formatMessage({ id: 'copyRightMessage' })}</span>
                        <span> {intl.formatMessage({ id: 'allRightsReserved' })}</span>
                    </div>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.versionBanner}>
                    <div className={classes.bold}>
                        <p> {intl.formatMessage({ id:'customerPortal' })}</p>
                    </div>
                    <Grid flex="true" container spacing={5}>
                        <Grid item xs={6} sm={6} md={4} lg={4} >
                            {intl.formatMessage({id:'softwareVersion'})}
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={4} >
                            V{tenantDetails.version}
                        </Grid>
                    </Grid>
                    <Grid flex="true" container spacing={5}>
                        <Grid item xs={6} sm={6} md={4} lg={4} >
                            {intl.formatMessage({id:'buildVersion'})}
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={4} >
                            {tenantDetails.build}
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <CPButton variant='primary' id="close" onClick={handleClose} >{intl.formatMessage({ id: "close" })}</CPButton>
            </DialogActions>
        </Dialog >
    )
}

export default injectIntl(withTenant(withStyles(style)(CopyRight)))

import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Grid, withStyles } from '@material-ui/core';
import '../../../styles/bar.css';
import { injectIntl } from "react-intl";

const style = {
    gridText: {
        fontWeight: '700',
        fontStyle: 'normal',
        marginTop: '1%',
        height: '40px',
        color: '#000000',
        fontFamily: 'Arial Bold, Arial, sans-serif',
        display: 'flex'
    },
    buttonWarpper: {
        padding: '1% 0',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#007ad9'
    },
    ragGreenOuter: {
        background: 'blue'
    }
}

class ShipmentDetails extends Component {
    constructor(props) {
        super(props);
        const { intl } = this.props;
        this.state = {
            selectedRow: null,
            columnDefs: [
                { headerName: intl.formatMessage({ id: 'CMUS_ShipmentsSummary_Shipper' }), field: "shipperName", floatingFilter: true, width: '10%', suppressMenu: true, tooltipField: "shipperName" },
                { headerName: intl.formatMessage({ id: 'CMUS_ShipmentsSummary_ArrivalDate' }), field: "arrivalDate", floatingFilter: true, width: '10%', suppressMenu: true, tooltipField: "arrivalDate" },
                { headerName: intl.formatMessage({ id: 'CMUS_ShipmentsSummary_CountryOfExport' }), field: "countryOfExportName", floatingFilter: true, width: '10%', suppressMenu: true, tooltipField: "countryOfExportName" },
                { headerName: intl.formatMessage({ id: 'CMUS_ShipmentsSummary_IRSNO' }), field: "custIrsNo", floatingFilter: true, width: '10%', suppressMenu: true, tooltipField: "custIrsNo" },
                { headerName: intl.formatMessage({ id: 'CMUS_ShipmentsSummary_CustomerName' }), field: "custName", floatingFilter: true, width: '10%', suppressMenu: true, tooltipField: "custName" },
                { headerName: intl.formatMessage({ id: 'CMUS_ShipmentsSummary_ExportDate' }), field: "exportDate", width: '10%', suppressMenu: true, tooltipField: "exportDate" },
                { headerName: intl.formatMessage({ id: 'CMUS_ShipmentsSummary_FileNo' }), field: "fileNo", width: '10%', suppressMenu: true, tooltipField: "fileNo" },
                { headerName: intl.formatMessage({ id: 'CMUS_ShipmentsSummary_HouseBill' }), field: "houseBill", width: '10%', suppressMenu: true, tooltipField: "houseBill" },
                { headerName: intl.formatMessage({ id: 'CMUS_ShipmentsSummary_MasterBill' }), field: "masterBill", width: '10%', suppressMenu: true, tooltipField: "masterBill" },
                { headerName: intl.formatMessage({ id: 'CMUS_ShipmentsSummary_MOTDescription' }), field: "motDesc", width: '10%', suppressMenu: true, tooltipField: "motDesc" },
                { headerName: intl.formatMessage({ id: 'CMUS_ShipmentsSummary_PortOfEntry' }), field: "portOfEntryName", width: '10%', suppressMenu: true, tooltipField: "portOfEntryName" },
                { headerName: intl.formatMessage({ id: 'CMUS_ShipmentsSummary_ReleaseDate' }), field: "releaseDate", width: '10%', suppressMenu: true, tooltipField: "releaseDate" },
                { headerName: intl.formatMessage({ id: 'fdaStatus' }), field: "fdaStatus", width: '5%', suppressMenu: true, tooltipField: "fdaStatus" },
                { headerName: intl.formatMessage({ id: 'pgaStatus' }), field: "pgaStatus", width: '5%', suppressMenu: true, tooltipField: "pgaStatus" },
                { headerName: intl.formatMessage({ id: 'pgaAgency' }), field: "pga", maxWidth: 80, suppressMenu: true, tooltipField: "pga" }
            ],
            defaultColDef: {
                flex: 1,
                sortable: true,
                filter: true,
                floatingFilter: true,
            },

        }
    }
    onGridReady = params => {
        this.gridApi = params.api;
    };
    selectedRow = () => {
        const { handleRowClick } = this.props;
        const row = this.gridApi.getSelectedRows();
        if (row) {
            this.setState({
                selectedRow: row
            })
            handleRowClick(this.gridApi.getSelectedRows(), 'shipmentsCMUS');
        }
        else {
            handleRowClick(this.state.selectedRow, 'shipmentsCMUS');
        }

    }
    render() {
        const { filterchange, data } = this.props
        return (<>
            <Grid item lg={12} >

                <div
                    className="ag-theme-balham"
                    style={{ height: '410px', width: '100%', backgroundColor: 'rgba(0, 0, 0,0.04)' }} >
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={data}
                        defaultColDef={this.state.defaultColDef}
                        rowSelection={'single'}
                        onRowClicked={this.selectedRow}
                        onGridReady={this.onGridReady}
                        headerHeight='44'
                        rowHeight='30'
                        onFilterChanged={filterchange}
                        floatingFiltersHeight='40'
                        tooltipShowDelay={0}
                    >
                    </AgGridReact>
                </div>
            </Grid>
        </>)
    }
}

export default injectIntl(withStyles(style)(ShipmentDetails))

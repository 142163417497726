import React from 'react'
import { Table, TableRow, TableHead, TableCell, TableBody, withStyles } from "@material-ui/core"
import { injectIntl } from 'react-intl'

const style = {
    tableHeader: {
        fontWeight: 'bold !important',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        borderBottom: '4px solid #CDE3E9',
        height: '54px',
        padding: '6px 6px 6px 10px'
    },
    tabletr: {
        display: ' table !important',
        width: '100%',
        fontSize: '14px',
        tableLayout: 'fixed',
        margin: 0,
        fontFamily: "'Open Sans', Arial, sans-serif !important",
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        '&:nth-child(even)': {
            backgroundColor: '#F3F7F9'
        }
    },
    tableCell: {
        fontSize: '14px',
        border: '1px solid #DAE7EA',
        height: '48px',
        padding: '6px 6px 6px 10px',
        borderLeft: '0px'
    },
    noRecords: {
        minHeight: '10vh',
        fontStyle: 'italic'
    },
    noRecordscell: {
        fontSize: '14px',
        fontFamily: 'Open Sans Italic'
    }
}
function StyledTable(props) {
    const { headers, data, classes, isPrint, printClasses, intl } = props
    return (
        <Table size="small" style={{ overflowX: 'auto' }} >
            <TableHead>
                <TableRow className={classes.tabletr}>
                    {
                        headers.map((header, index) => {
                            return (
                                <TableCell key={index} className={classes.tableHeader} style={{ width: header.width ? header.width : 225 }} >{header.title}</TableCell>
                            )
                        })
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {data ?
                    data.map((row, index) => {
                        return (
                            <TableRow className={classes.tabletr} key={index}>
                                {headers.map((column, index) => {
                                    return (<TableCell className={classes.tableCell} style={{ width: column.width ? column.width : 255 }} key={index}>{row[column.field]}</TableCell>)
                                })}
                            </TableRow>

                        )
                    })
                    :
                    <TableRow className={isPrint ? `${classes.tabletr} ${printClasses.noRecordsForPrint}` : `${classes.tabletr} ${classes.noRecords}`}>
                        <TableCell className={classes.noRecordscell} colSpan={headers.length} >{intl.formatMessage({ id: 'noRecordsFound' })}</TableCell>
                    </TableRow>
                }
            </TableBody>
        </Table>
    )
}

export default injectIntl(withStyles(style)(StyledTable))
import React, { Component } from 'react'
import { processDate } from '../../../common/utils'
import { evictShipmentsCache, widgetsMetaData } from '../../../sevices/CMUS/Services'
import BarChart from '../../common/BarChart'

class ReleasedShipments extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            xaxisData: "",
            yaxisData: ""
        }
    }

    componentDidMount = async () => {
        const { location } = this.props;
        const Widgets = location.state ? location.state.widgets : true
        if (Widgets) {
            this.fetchWidgetData()
        }
    }

    fetchWidgetData = async () => {
        const shipmentData = await widgetsMetaData('dates');

        let newDateMap = {}
        if (shipmentData && shipmentData.dayWiseShipmentCount) {
            shipmentData.dayWiseShipmentCount.forEach(element => {
                newDateMap = {
                    ...newDateMap,
                    [processDate(element[1], "DD MMM")]: element[1]
                }
            });
            const yaxisData = shipmentData.dayWiseShipmentCount.map(element => {
                return element[1] !== "date" ?
                    processDate(element[1], "DD MMM") :
                    ''
            })
            const xaxisData = shipmentData.dayWiseShipmentCount.map(element => {
                return element[0]
            })
            xaxisData.shift()
            yaxisData.shift()
            await this.setState({
                shipmentsExportData: shipmentData ? (shipmentData.dayWiseShipmentCount ? shipmentData.dayWiseShipmentCount : []) : [],
                loading: false,
                xaxisData: xaxisData,
                yaxisData: yaxisData,
                dateMap: newDateMap
            })
        }
        else {
            await this.setState({
                shipmentsImportData: [],
                loading: false,
                xaxisData: [],
                yaxisData: [],
                dateMap: {}
            })
        }
    }

    handleWidgetClick = async (event) => {
        const { history, CpError } = this.props
        const { dateMap } = this.state
        let header = {}
        let data = {}
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
        header = { "Released on": event.name }
        data = { dataFor: "dates", value: dateMap[event.name] }
        history.push({
            pathname: '/shipments',
            state: {
                heading: header,
                data: data,
                fromDashBoard: true
            }
        })
    }

    handleRefresh = async () => {
        await evictShipmentsCache("dates");
        await evictShipmentsCache("dates");
        await this.setState({
            loading: true
        })
        this.fetchWidgetData()
    }

    render() {
        const { intl } = this.props
        const { loading, xaxisData, yaxisData } = this.state
        return (
            <BarChart
                heading={intl.formatMessage({ id: "releasedShipments" })}
                handleClick={this.handleWidgetClick}
                name={intl.formatMessage({ id: "releasedShipments" })}
                loading={loading}
                handleRefresh={this.handleRefresh}
                xaxisData={xaxisData}
                yaxisData={yaxisData}
                showRefreshIcon={true}
            />
        )
    }
}

export default ReleasedShipments

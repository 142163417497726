import React, { Component } from 'react'
import { evictShipmentsCache, widgetsMetaData } from '../../../sevices/CMUS/Services';
import PieChart from '../../common/PieChart';

export class ShipmentsbyEntryTypes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shipmentsByEntryTypeData: [],
            loading: true
        }
    }

    componentDidMount = () => {
        const { location } = this.props;
        const Widgets = location.state ? location.state.widgets : true
        if (Widgets) {
            this.fetchWidgetData()
        }
    }

    fetchWidgetData = async () => {
        const shipmentData = await widgetsMetaData('entryType');
        await this.setState({
            shipmentsByEntryTypeData: shipmentData && shipmentData.pieChartWidgetDetails ? shipmentData.pieChartWidgetDetails : [],
            loading: false
        })
    }

    handleWidgetClick = (event) => {
        const { history, CpError } = this.props;
        let header = {}
        let data = {}
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
        header = { "Entry Types": event.data.name }
        data = { dataFor: "entryType", value: event.data.code }
        history.push({
            pathname: '/shipments',
            state: {
                heading: header,
                data: data,
                fromDashBoard: true
            }
        })
    }

    handleRefresh = async () => {
        await evictShipmentsCache('entryType');
        await evictShipmentsCache('entryType');
        await this.setState({
            loading: true
        })
        this.fetchWidgetData();
    }

    render() {
        const { intl } = this.props;
        const { shipmentsByEntryTypeData, loading } = this.state
        return (
            <PieChart
                heading={intl.formatMessage({ id: 'shipmentsbyEntrytypes' })}
                chartType="shipmentsByEntryType" data={shipmentsByEntryTypeData}
                handleClick={this.handleWidgetClick}
                loading={loading}
                handleRefresh={this.handleRefresh} />
        )
    }
}

export default ShipmentsbyEntryTypes

import React from 'react'
import { withStyles, TextField, FormHelperText, useTheme, useMediaQuery, InputLabel } from '@material-ui/core'

const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            width: '35%',
            fontWeight: 'bold',
            fontSize: '14px'
        }
    },
    inputWrapper: {
        width: '100%',
        display: 'inline-flex',
        padding: '0',
        alignItems: 'center',
        margin: '0.5rem 2px'
    },
    fieldContent: {
        width: '45%',
        [theme.breakpoints.up('md')]: {
            width: '16%'
        }
    },
    fieldContentDiv: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '56%',
        }
    },
    required: {
        color: 'red',
        paddingTop: '2.5%',
        width: '2%'
    },
    para: {
        margin: '0',
        fontSize: '10px',
        width: '34%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '3%',
        [theme.breakpoints.up('md')]: {
            fontSize: '14px'
        }
    },
    input: {
        '&.MuiOutlinedInput-root': {
            paddingRight: '0',
            '&	.MuiOutlinedInput-input': {
                display: 'block',
                lineHeight: 'calc(4rem - 4px)',
                minHeight: '3rem',
                padding: '0 1rem',
                position: 'relative',
                verticalAlign: 'middle',
                width: '100%',
                backgroundColor: '#FFFFFF',
                color: '#4A4E50'
            },
            '&  .MuiInputAdornment-root': {
                backgroundColor: theme.palette.primary.main,
                backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.24))',
                padding: '1.7rem 1rem',
                margin: '0px',
                borderTopRightRadius: theme.shape.borderRadius + 'px',
                borderBottomRightRadius: theme.shape.borderRadius + 'px',
                borderLeft: '2px solid #C8CFD2',
                color: 'white'
            },
        }
    },
    notchedOutline: {
        border: '2px solid #C8CFD2',
        borderRadius: '4px',
        transitionDuration: '100ms',
        transitionProperty: 'border-color, background-color, color, box-shadow',
        '&:focus': {
            outlineWidth: '0',
            borderColor: theme.palette.primary.main,
            boxShadow: '0 0 0 3px #e3ecef'
        },
        '&focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px'
        }
    },
    disabled: {

    }
})

function CpInput2(props) {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const numberProps = { min: 0 }
    const {
        classes,
        label,
        onChange,
        isRequired,
        register,
        type,
        name,
        isDisabled,
        texttoRightSideInput, error, helperText } = props
    return (
        <>
            <div className={classes.inputWrapper} >
                <div className={classes.sectionDesktop}><label style={{fontWeight:500}}htmlFor={name}>{label} {isRequired ? <span className={classes.required}>*</span> : ""}</label></div>
                {isDesktop ? '' :
                    <InputLabel style={{ width: '100%' }} >
                        {label}
                    </InputLabel>}
                <div className={classes.fieldContentDiv}>
                    <TextField inputRef={register}
                        id={name}
                        error={error}
                        disabled={isDisabled}
                        className={classes.fieldContent}
                        name={name}
                        variant="outlined"
                        type={type}
                        onChange={onChange}
                        size='small'
                        InputProps={{ classes: { root: classes.input, disabled: classes.disabled, notchedOutline: classes.notchedOutline } }}
                        inputProps={type === 'number' ? numberProps : {}} />
                    <div className={classes.para}>
                        <span >
                            {texttoRightSideInput}
                        </span>
                    </div>
                </div>
            </div >
            <div style={{ marginLeft: '40%' }}>{error ? <FormHelperText error={true}>{helperText}</FormHelperText> : ''}</div>
        </>
    )
}

export default withStyles(style)(CpInput2)

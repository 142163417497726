import React, { Component } from 'react';
import Conveyances from './Conveyances';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core';
import ConveyanceDetails from './ConveyanceDetails';
import ConvBillofLading from './ConvBillofLading';
import ConvCommodity from './ConvCommodity';
import { getConveyances, getConveyanceDetails, getConvBOLDetails, getConvComodityDetails } from '../../../sevices/CMUS/Services'
import withLoading from '../../../components/common/withLoading';

const styles = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
})
class ConveyancesSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displaySection: {
                level0: { visible: true, data: null, noOfPages: 0, selectedPage: 1, totalRecords: 0, selectedRecord: 0 },
                level1: { visible: false, data: null, noOfPages: 0, selectedPage: 1, totalRecords: 0, selectedRecord: 0 },
                level2: { visible: false, data: null, totalRecords: 0, selectedRecord: 0 },
                level3: { visible: false, data: null, totalRecords: 0, selectedRecord: 0 }
            },
            selectedConveyance: null,
            selectedBol: null
        }
    }
    componentDidMount = async () => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        await this.getConveyanceItems(this.state.displaySection.level0.selectedPage);
        await loading.setLoadindDoc(false);
    }
    getConveyanceItems = async (pageSelected) => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        let conveyances = await getConveyances(pageSelected);
        if (conveyances && conveyances.status === 200 && conveyances.data) {
            await this.setState({
                displaySection: {
                    ...this.state.displaySection,
                    level0: {
                        visible: true,
                        data: conveyances.data.data ? conveyances.data.data : [],
                        noOfPages: conveyances.data.maxResults !== 0 ? Math.ceil(conveyances.data.totalRecords / conveyances.data.maxResults) : 1,
                        selectedPage: pageSelected ? pageSelected : 1,
                        totalRecords: (conveyances.data.data) ? conveyances.data.data.length : this.state.displaySection.level0.totalRecords
                    }
                }
            })
        }
        else {
            await this.setState({ displaySection: { ...this.state.displaySection, level0: { visible: true, data: [], noOfPages: 0, selectedPage: 1 } } })
        }
        loading.setLoadindDoc(false);
    }
    getConvDetails = async (data, index) => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        const convDetails = await getConveyanceDetails(data.conveyanceSeqNo);
        if (convDetails && convDetails.status === 200 && convDetails.data) {
            await this.setState({
                displaySection: {
                    level0: { ...this.state.displaySection.level0, visible: false, selectedRecord: index ? index : this.state.displaySection.level0.selectedRecord },
                    level1: {
                        visible: true, data: convDetails.data ? convDetails.data : null,
                        noOfPages: convDetails.data.maxResults !== 0 ? Math.ceil(convDetails.data.totalRecords / convDetails.data.maxResults) : 1,
                        //selectedPage: pageSelected ? pageSelected : 1,
                        totalRecords: (convDetails.data.data && convDetails.data.data[0] && convDetails.data.data[0].ftzShipmentIdList) ? convDetails.data.data[0].ftzShipmentIdList.length : this.state.displaySection.level1.totalRecords
                    },
                    level2: { visible: false, data: null },
                    level3: { visible: false, data: null }
                },
                selectedConveyance: data
            });
        }
        else {
            await this.setState({
                displaySection: {
                    level0: { ...this.state.displaySection.level0, visible: false, selectedRecord: 0 },
                    level1: { visible: true, data: null, noOfPages: 0, selectedPage: 0 },
                    level2: { visible: false, data: null },
                    level3: { visible: false, data: null }
                },
                selectedConveyance: data
            });
        }
        loading.setLoadindDoc(false);
    }
    handleDisplayBack = (level, data) => {
        switch (level) {
            case 0:
                this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: true },
                        level1: { ...this.state.displaySection.level1, visible: false },
                        level2: { ...this.state.displaySection.level2, visible: false },
                        level3: { ...this.state.displaySection.level3, visible: false }
                    }
                });

                break;
            case 1:
                this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: false },
                        level1: { ...this.state.displaySection.level1, visible: true },
                        level2: { ...this.state.displaySection.level2, visible: false },
                        level3: { ...this.state.displaySection.level3, visible: false }
                    }
                });
                break;
            case 2:
                this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: false },
                        level1: { ...this.state.displaySection.level1, visible: false },
                        level2: { ...this.state.displaySection.level2, visible: true },
                        level3: { ...this.state.displaySection.level3, visible: false }
                    }
                });
                break;

            default:
                this.setState({
                    displaySection: {
                        level0: { visible: true, data: data },
                        level1: { visible: false, data: null },
                        level2: { visible: false, data: null },
                        level3: { visible: false, data: null }
                    }
                });
                break;
        }
    }

    handleDisplayForward = async (level, data, index) => {
        const { loading } = this.props
        const { selectedConveyance, selectedBol } = this.state
        switch (level) {
            case 1:
                this.getConvDetails(data, index);
                break;
            case 2:
                loading.setLoadindDoc(true);
                const convBolDetails = await getConvBOLDetails(selectedConveyance.conveyanceSeqNo, data.idSeqNo);
                if (convBolDetails && convBolDetails.data) {
                    await this.setState({
                        displaySection: {
                            level0: { ...this.state.displaySection.level0, visible: false },
                            level1: { ...this.state.displaySection.level1, visible: false, selectedRecord: index ? index : this.state.displaySection.level1.selectedRecord },
                            level2: { visible: true, data: (convBolDetails.data.data ? convBolDetails.data.data[0] : null), totalRecords: (convBolDetails.data.data && convBolDetails.data.data[0] && convBolDetails.data.data[0].ftzLinesList) ? convBolDetails.data.data[0].ftzLinesList.length : this.state.displaySection.level2.totalRecords },
                            level3: { visible: false, data: null }
                        },
                        selectedBol: data
                    });
                }
                loading.setLoadindDoc(false);
                break;
            case 3:
                loading.setLoadindDoc(true);
                const convCommodity = await getConvComodityDetails(selectedConveyance.conveyanceSeqNo, selectedBol.idSeqNo, data.lineNo);
                if (convCommodity && convCommodity.data) {
                    await this.setState({
                        displaySection: {
                            level0: { ...this.state.displaySection.level0, visible: false },
                            level1: { ...this.state.displaySection.level1, visible: false },
                            level2: { ...this.state.displaySection.level2, visible: false, selectedRecord: index ? index : this.state.displaySection.level2.selectedRecord },
                            level3: { visible: true, data: convCommodity.data.data && convCommodity.data.data.length > 0 ? convCommodity.data.data[0] : {} }
                        },
                    });
                }
                loading.setLoadindDoc(false);
                break;
            default:
                break;
        }

    }
    handlePageClick = (pageIndex, level) => {
        if (level === 0) {
            this.getConveyanceItems(pageIndex);
        } else if (level === 1) {
            this.getConvDetails(this.state.selectedInvoice, pageIndex)
        }
    }
    render() {
        const { displaySection } = this.state
        return (
            <div>
                {displaySection.level0.visible ? <Conveyances conveyancesData={displaySection.level0.data} handleDisplayForward={this.handleDisplayForward}
                    noOfPages={displaySection.level0.noOfPages} selectedPage={displaySection.level0.selectedPage} handlePageClick={this.handlePageClick} /> : null}
                {displaySection.level1.visible ? <ConveyanceDetails convDetails={displaySection.level1.data} invoiceData={this.state.selectedInvoice}
                    handleDisplayBack={this.handleDisplayBack} handleDisplayForward={this.handleDisplayForward} handlePageClick={this.handlePageClick}
                    totalRecords={displaySection.level0.totalRecords} selectedRecord={displaySection.level0.selectedRecord} /> : null}
                {displaySection.level2.visible ? <ConvBillofLading convBolDetails={displaySection.level2.data} handleDisplayBack={this.handleDisplayBack} handleDisplayForward={this.handleDisplayForward}
                    l1totalRecords={displaySection.level0.totalRecords} l1selectedRecord={displaySection.level0.selectedRecord}
                    l2totalRecords={displaySection.level1.totalRecords} l2selectedRecord={displaySection.level1.selectedRecord} /> : null}
                {displaySection.level3.visible ? <ConvCommodity convComdityDetails={displaySection.level3.data} handleDisplayBack={this.handleDisplayBack}
                    l1totalRecords={displaySection.level0.totalRecords} l1selectedRecord={displaySection.level0.selectedRecord}
                    l2totalRecords={displaySection.level1.totalRecords} l2selectedRecord={displaySection.level1.selectedRecord}
                    l3totalRecords={displaySection.level2.totalRecords} l3selectedRecord={displaySection.level2.selectedRecord} /> : null}
            </div>
        )
    }
}

export default injectIntl(withLoading(withStyles(styles)(ConveyancesSection)))
import { withStyles } from '@material-ui/core'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
const style = theme => ({
    heading: {
        fontSize: '24px',
        fontWeight: 700,
        textAlign: 'center'
    },
    container: {
        margin: '4%'
    },
    textDiv: {
        margin: '2% 0 0 0',
        textAlign: 'center'
    },
    backToHome: {
        color: 'blue',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
})
function Page404(props) {
    const { classes, intl } = props
    const backToHome = () => {
        const { history } = props
        history.push("/")
    }
    return (
        <div className={classes.container}>
            <h1 className={classes.heading}>{intl.formatMessage({ id: 'notFound' })}</h1>
            <div className={classes.textDiv}>
                <p className={classes.backToHome} onClick={() => backToHome()}>{intl.formatMessage({ id: 'backToHome' })}</p>
            </div>
        </div>
    )
}

export default injectIntl(withRouter(withStyles(style)(Page404)))
import React from 'react';
import { injectIntl } from 'react-intl';
import Box from '../../common/Box';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';

function InBondHeader(props) {

    const { headerData, intl } = props;
    const headerCoulumnList = [{ title: "Status/Location", field: "status" },
    { title: "Job No", field: "" },
    { title: "File No", field: "fileNo" },
    { title: "In-Bond Number", field: "itNo", },
    { title: "Customer Name", field: "custNo", tooltipField: "custName" },
    { title: "Entry Type", field: "entryType", tooltipField: "entryTypeDesc" },
    { title: "Entry No.", field: "entryNo" },
    { title: "Method of Transport", field: "methodOfTransport" },
    { title: "Customer Reference", field: "custRef" },
    { title: "Description of Goods", field: "descriptionOfGoods" },
    { title: "In-Bond Carrier", field: "carrier" },
    { title: "U.S. Port of Destination", field: "distPortDestinationName", tooltipField: "distPortDestination" },
    { title: "Foreign Destination", field: "lastForeignPortName", tooltipField: "lastForeignPort" },
    { title: "In-Bond Value", field: "valueInBond" }]

    const entrySummaryList = [{ title: "Entry No.", field: "entryNo" },
    { title: "Entry Type", field: "entryType", tooltipField: "entryTypeDesc" },
    { title: "IT No.", field: "itNo" },
    { title: "Entry Date", field: "dateEntryDt" },
    { title: "Entry Port", field: "distPort" }]

    const warehouseList = [{ title: "Original Bonded Amount", field: "whsOrigBondedAmt" },
    { title: "Bonded Amount", field: "bondedAmt" },
    { title: "Withdrawal Amount", field: "withdrawalAmt" },
    { title: "Balance Warehouse", field: "balanceWhse" },
    { title: "Entry No.", field: "entryNo" }]

    return (
        <>
            <Box header={intl.formatMessage({ id: 'header' })}>
                <NonEditableDataGrid list={headerCoulumnList} data={headerData ? headerData : {}} />
            </Box>
            <Box header={intl.formatMessage({ id: 'preceding_Entry_Summary' })}>
                <NonEditableDataGrid list={entrySummaryList} data={headerData ? headerData : {}} />
            </Box>
            <Box header={intl.formatMessage({ id: 'warehouse_Withdrawal' })}>
                <NonEditableDataGrid list={warehouseList} data={headerData ? headerData : {}} />
            </Box>

        </>

    )
}

export default injectIntl(InBondHeader)


import React from 'react'
import { Grid, withStyles, IconButton, Chip } from '@material-ui/core';
import CPButton from '../common/Button';
import MenuList from '../common/MenuList';
import '../../styles/bar.css'
import Box from '../common/Box';
import ContainersTable from '../eBooking/ContainersTable';
import ItemsTable from '../eBooking/ItemsTable';
import DetailsForm from '../eBooking/DetailsForm';
import CPPopOver from '../common/CPPopOver'
import { useForm, FormContext } from 'react-hook-form';
import DocumentTable from '../common/DocumentTable';
import { injectIntl } from "react-intl";
import CPConfirmBox from '../common/CPConfirmBox';
import DocumentsTableMobile from '../common/DocumentsTableMobile';
import QuoteView from '../Quotes/QuoteView';
import HelpIcon from '@material-ui/icons/Help';
import CpInput from '../common/CpInput';
import CpTypeahead from '../common/CpTypeahead';
import withCPContext from '../common/withCPContext';
import { getSnackBar } from '../../common/utils';
import NonEditableDataGrid from '../common/NonEditableDataGrid'
import CpCheckBox from '../common/CpCheckBox';


const style = ({ breakpoints, palette }) => ({
    heading: {
        fontSize: '24px',
        fontWeight: 700
    },
    details: {
        fontSize: '18px',
        fontWeight: 600
    },
    errorDiv: {
        color: 'red',
        fontWeight: 'bold',
        marginLeft: '40%'
    },
    sectionDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'block'
        }
    },
    sectionMobile: {
        display: 'block',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    sectionButtonsDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'initial'
        }
    },
    sectionButtonsMobile: {
        display: 'flow-root',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    sectionMobileHelp: {
        display: 'initial',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    templateLoading: {
        fontWeight: "600",
        fontSize: '2rem',
        color: palette.primary.main,
        [breakpoints.down('md')]: {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center'
        },
        [breakpoints.up('md')]: {
            margin: "0 0 0 6%"
        }
    },
    quoteBox: {
        margin: "2%",
        border: "2px solid",
        borderColor: palette.secondary.main,
        backgroundColor: "rgba(240, 242, 243, 1)"
    },
    primaryColor: {
        color: palette.primary.main
    },
    hr: {
        border: `2px solid`,
        color: palette.primary.main,
        background: palette.primary.main
    },
    status: {
        fontSize: '12px',
        fontWeight: '500',
        color: 'white',
        margin: '0 1rem',
        height: '28px'
    },
    divider: {
        margin: '0.5rem 1rem',
        backgroundColor: '#979797'
    },
    header: {
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#E8F0F4'
    },
    headingBox: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '2rem'
    },
    proccessing: {
        fontWeight: "600",
        fontSize: '20px',
        color: palette.primary.main
    },
    templateContainer: {
        width: '100%',
        margin: '1% 0 0 0 ',
        backgroundColor: '#F9FBFB',
        border: '1px solid #C8CFD2',
        borderLeft: '0px',
        borderRight: '0px'
    },
})
function CPCollectiveForm(props) {
    const {details, docsUpdated} = props.data;
    const supUserBooking = (props.data.cpSupUser && props.formFor === 'booking');
    const  containerCond = (details.bizType === 'SE' || details.bizType === 'SI') && details.shipmentType === 'FCL'; 
    const vehicalsCond = details.bizType === 'IL' && details.shipmentType === 'FTL';
    const docUploadReadOnly = ((details.status  === "Rejected" && props.formFor !== "bookingTemplate") || (details.status  === "Booked" && props.formFor === "quote")) ? true : false;
    
    const {
        formFor,
        classes,
        onSubmit,
        handleDrawer,
        handleClosePopUp,
        handleOnclickPopUp,
        handleOnClickMenuItem,
        quotesDivToFocus,
        detailsDivToFocus,
        containersDivToFocus,
        itemsDivToFocus,
        documentsDivToFocus,
        aduitTrailDivToFocus,
        onChange,
        handleCountryChange,
        handleDateorTimeChange,
        handlePickupFromToTimings,
        handleSwtichChange,
        handlePopOverOpen,
        handleTypeaheadChange,
        removeContainers,
        addContainers,
        onChangeContainerfields,
        removeItems,
        addItems,
        onChangeItemfields,
        data,
        handleUploadFile,
        removeNewDocFunc,
        enableIconsForDocs,
        onChangeDocumentfields,
        handleDeleteExistingDocs,
        intl,
        orgCityRef,
        destCityRef,
        polCityRef,
        podCityRef,
        polCountryRef,
        podCountryRef,
        deleteRecord,
        handleConfirm,
        voidHandler,
        onSaveContainersorItems,
        onEditContainersorItems,
        onCancelContainersorItems,
        quoteAction,
        onChangeQuoteFields,
        handleReject,
        fileTypes,
        fileErrors,
        fileSize,
        handleBookingTemplatesChange,
        performPartyLookupSearch,
        handleTypeAheadInputChange,
        handleEnableorDisableTemplate,
        onInputChange,
        enableShipperOrConsgineeAddress,
        onChangeAddress,
        clearPickupOrDeliveryData,
        retainPickupOrDeliveryData,
        getDocDownloadErrorDetails,
        handleToggleButtonChange,
        ClearDataOnClick,
        showorHideExtraFields,
        supReadyDate
    } = props
   const getContent = (field) => {
        switch (field) {
            case "shipperAddress":
                return data.shipperAddressList;
            case "consigneeAddress":
                return data.consigneeAddressList;
            case "notifyAddress":
                return data.notifyAddressList;
            case "collectFrom":
                return data.pickupCollectAddress;
            case "pickupdeliverto":
                return data.pickupDeliveryAddress;
            case "deliverydeliverto":
                return data.deliverDeliveryAddress;
            case "deliverycollectfrom":
                return data.deliverCollectAddress;
            default:
                return [];

        }
    }
    const methods = useForm();
    const getHeading = () => {
        const { formFor } = props
        let heading = ""
        switch (formFor) {
            case "booking":
                heading = intl.formatMessage({ id: 'booking' })
                break;
            case "quote":
                heading = intl.formatMessage({ id: 'quote' })
                break;
            case "bookingTemplate":
                heading = intl.formatMessage({ id: 'bookingTemplate' })
                break;
            default:
                break;
        }
        return heading
    }
    const getColor = (data) => {
        switch (data) {
            case 'In Progress':
            case 'Awaiting Acceptance':
                return '#EFB741'
            case 'Submitted':
            case 'Accepted':
                return '#5F7298'
            case 'Booked':
                return '#038155'
            case 'Rejected':
                return '#AC3823'
            default:
                return '#EFB741';
        }

    }
    const helpUrl = formFor === 'booking' ? `${window.location.protocol + '//' + window.location.host}/webHelp/cp-new-booking.html` :
        formFor === 'quote' ? `${window.location.protocol + '//' + window.location.host}/webHelp/cp-request-a-quote.html` :
            formFor === 'bookingTemplate' ? `${window.location.protocol + '//' + window.location.host}/webHelp/cp-new-template.html` : "#"
    return (

        < FormContext {...methods} >
            <div onClick={ClearDataOnClick}>
                <form autoComplete="off">
                    <div className={classes.header}>
                        <div className={classes.headingBox}>
                            <span className={classes.heading} >{getHeading()}</span>
                            {formFor === 'bookingTemplate' ? null : <Chip label={data.details.status} className={classes.status} style={{ backgroundColor: getColor(data.details.status) }} />}

                        </div>
                        <div className={classes.sectionMobileHelp} >
                            {/* Help section */}
                            <a href={helpUrl}
                                target="_blank" style={{ color: "inherit", float: 'right' }} rel="noopener noreferrer">
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="primary-search-account-menu"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <HelpIcon style={{ fontSize: '28px', color: "#6E787D" }} />
                                </IconButton>

                            </a>
                            {/* end of Help section */}
                        </div>
                        {data.disableButtons ?
                            <span className={classes.proccessing}>{intl.formatMessage({ id: 'processing' })}</span> : null}
                        <div className={classes.sectionButtonsDesktop}>
                            {
                                formFor === "bookingTemplate" ?
                                <>
                                    <CPButton variant='primary' styles={{ float: 'right' }} disabled={data.disableButtons} onClick={(event) => { event.preventDefault(); onSubmit('Save') }}>
                                        {intl.formatMessage({ id: 'save' })}
                                    </CPButton>
                                    {data.unId ?
                                        <CPButton variant='secondary' styles={{ float: 'right' }} disabled={data.disableButtons} onClick={(event) => { event.preventDefault(); onSubmit('Save as New') }}>
                                            {intl.formatMessage({ id: 'saveAndNew' })}
                                        </CPButton>
                                        : null
                                    }
                                    {data.unId ?
                                        <CPButton variant='secondary' styles={{ float: 'right' }} disabled={data.disableButtons} onClick={() => handleEnableorDisableTemplate()}>
                                            {data.templateStatus === "A" ? intl.formatMessage({ id: 'disable' }) : intl.formatMessage({ id: 'enable' })}
                                        </CPButton>
                                        : null
                                    }
                                    <CPButton variant='containerLess' styles={{ float: 'right' }} disabled={data.disableButtons} onClick={() => handleDrawer(true)}>{intl.formatMessage({ id: 'cancel' })}</CPButton>
                                </>
                                :
                                <>
                                    {
                                        formFor === "quote" && 
                                            (data.details.status === "Awaiting Acceptance" || data.details.status === "Rejected" || data.details.status === "Booked") ?
                                                <CPButton styles={{ float: 'right', marginRight: '2rem' }} variant='primary' disabled={data.disableButtons} onClick={() => handleDrawer(data.details.status === 'In Progress' ? true : false)}>
                                                    {intl.formatMessage({ id: 'close' })}
                                                </CPButton>
                                        :
                                        <>
                                            <CPButton styles={{ float: 'right', }} variant='primary' disabled={data.details.status !== 'In Progress' ? (docsUpdated ? false : true) : data.disableButtons} onClick={(event) => { event.preventDefault(); methods.handleSubmit(onSubmit('submit')) }}>
                                                {intl.formatMessage({ id: 'saveAndSubmit' })}
                                            </CPButton>
                                            <CPButton styles={{ float: 'right', }} variant='secondary' disabled={data.details.status !== 'In Progress' ? true : data.disableButtons} onClick={(event) => { event.preventDefault(); methods.handleSubmit(onSubmit('save')) }}>
                                                {intl.formatMessage({ id: 'save' })}
                                            </CPButton>
                                            {data.unId && 
                                                ((formFor === "quote" && data.details.status === "In Progress") || 
                                                    (formFor !== "quote" && data.details.status !== "Accepted")) && 
                                                (<CPButton styles={{ float: 'right', }} variant='secondary' disabled={data.disableButtons} onClick={() => handleConfirm()}>
                                                    {intl.formatMessage({ id: 'delete' })}
                                                </CPButton>)
                                            }
                                            <CPButton styles={{ float: 'right' }} variant='containerLess' disabled={data.disableButtons} onClick={() => handleDrawer(data.details.status === 'In Progress' ? true : false)}>
                                                {intl.formatMessage({ id: 'cancel' })}
                                            </CPButton>
                                        </>
                                    }
                                </>
                            }
                            {/* Help section */}
                            <a href={helpUrl}
                                target="_blank" style={{ color: "inherit", float: 'right' }} rel="noopener noreferrer">
                                <IconButton 
                                    aria-label="account of current user"
                                    aria-controls="primary-search-account-menu"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <HelpIcon style={{ fontSize: '28px', color: "#6E787D" }} />
                                </IconButton>

                            </a>
                            {/* end of Help section */}
                        </div>
                    </div>
                    {data.snackBar.show ? getSnackBar(data.snackBar.msg, true, data.snackBar.type) : null}
                    <CPConfirmBox show={data.showConfirm} message={formFor === "quote" ? intl.formatMessage({ id: "deleteQuoteMsg" }) : intl.formatMessage({ id: "deleteBookingMsg" })} handleRejected={voidHandler} handleAccepted={() => deleteRecord(data.unId)} />
                    <CPConfirmBox show={data.showPickupConfirm === true || data.showDeliveryConfirm === true} message={data.showPickupConfirm === true ? intl.formatMessage({ id: "pickupWarningMsg" }) : intl.formatMessage({ id: "deliveryWarningMsg" })} handleRejected={() => retainPickupOrDeliveryData(data.showPickupConfirm === true ? "pickupRequired" : "deliveryRequired")} handleAccepted={() => clearPickupOrDeliveryData(data.showPickupConfirm === true ? "pickupRequired" : "deliveryRequired")} />
                    <CPPopOver
                        style={{ maxWidth: '50%', maxHeight: '50%' }}
                        anchorEl={data.anchorEl} handleClose={handleClosePopUp}
                        open={data.openpopUp}
                        content={getContent(data.popOverField)}
                        onClick={handleOnclickPopUp}
                        buttonlabel='ok' onButtonClick={handleClosePopUp} />
                    <Grid flex="true" container spacing={0}>
                        <Grid item md={2} lg={2} xl={2}  >
                            <div className={classes.sectionDesktop}>
                                <MenuList list={data.sideHeaders} handleOnClickMenuItem={handleOnClickMenuItem} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10} style={{ maxHeight: '90vh', overflow: 'auto', border: '1px solid #E5E8E8' }}>

                            <div className={classes.errorDiv}> {data.error || data.error !== '' ? data.error : ''}</div>
                            <div className={classes.sectionButtonsMobile}>
                                {data.disableButtons ?
                                    <span className={classes.proccessing}>{intl.formatMessage({ id: 'processing' })}</span> : null}
                                {formFor === "bookingTemplate" ?
                                    <>

                                        <CPButton variant="primary" styles={{ float: 'right' }} disabled={data.disableButtons} onClick={(event) => { event.preventDefault(); onSubmit('Save') }}>{intl.formatMessage({ id: 'save' })}</CPButton>
                                        {data.unId ?
                                            <CPButton variant="secondary" styles={{ float: 'right' }} disabled={data.disableButtons} onClick={(event) => { event.preventDefault(); onSubmit('Save as new') }}>{intl.formatMessage({ id: 'saveAndNew' })}</CPButton>
                                            : null}
                                        {data.unId ?
                                            <CPButton variant="secondary" styles={{ float: 'right' }} disabled={data.disableButtons} onClick={() => handleEnableorDisableTemplate()}>{data.templateStatus === "A" ? intl.formatMessage({ id: 'disable' }) : intl.formatMessage({ id: 'enable' })}</CPButton>
                                            : null}
                                        <CPButton variant="containerLess" styles={{ float: 'right' }} disabled={data.disableButtons} onClick={() => handleDrawer(true)}>{intl.formatMessage({ id: 'cancel' })}</CPButton>
                                    </> :
                                    <>
                                        <CPButton styles={{ float: 'right', }} variant='primary' disabled={data.details.status !== 'In Progress'  ? true :  data.disableButtons} onClick={(event) => { event.preventDefault(); methods.handleSubmit(onSubmit('submit')) }}>{intl.formatMessage({ id: 'saveAndSubmit' })}</CPButton>
                                        <CPButton styles={{ float: 'right', }} variant='primary' disabled={data.details.status !== 'In Progress' ? false : data.disableButtons} onClick={(event) => { event.preventDefault(); methods.handleSubmit(onSubmit('save')) }}>{intl.formatMessage({ id: 'save' })}</CPButton>
                                        {data.unId && ((formFor ==="quote" && data.details.status === "In Progress" ) || (formFor !== "quote" && data.unId && data.details.status !== "Accepted")) && (
                                            <CPButton styles={{ float: 'right', }} variant='secondary' disabled={data.disableButtons} onClick={() => handleConfirm()}>{intl.formatMessage({ id: 'delete' })}</CPButton>
                                        )}
                                        <CPButton styles={{ float: 'right', }} variant='containerLess' disabled={data.disableButtons} onClick={() => handleDrawer(true)}>{intl.formatMessage({ id: 'cancel' })}</CPButton>
                                    </>
                                }
                            </div>
                            <div name="Quote" ref={quotesDivToFocus}>
                                {formFor === "quote" && (data.details.status === "Awaiting Acceptance" || data.details.status === "Accepted" || data.details.status === "Rejected" || data.details.status === "Booked") ?
                                    <QuoteView quoteData={data} data={data.revenueList} status={data.details.status} quoteNo={data.quoteNo} validFromTo={data.validFromTo} quoteAction={quoteAction} rejectReason={data.rejectReason} onChange={onChangeQuoteFields} showRejectBox={data.showRejectBox} handleReject={handleReject} disableButtons={data.disableButtons} />
                                    :
                                    null}
                            </div>
                            <div name="TemplateDetails" style={{margin: '1rem 2rem'}}>
                                {formFor === "bookingTemplate" ?
                                    <Box>
                                        <Grid flex="true" container spacing={1} className={classes.templateContainer}>
                                            <Grid item xs={12} sm={12} md={6} lg={6} style={{ marginTop: '1%' }} >
                                                <CpInput isDisabled={data.readOnly} label={intl.formatMessage({ id: 'templateName' })} maxLength={100} name="templateName" isRequired={true} type="text" value={data.templateName} onChange={(event) => onChange(event)} error={data.errordetails.templateName !== '' ? true : false} helperText={data.errordetails.templateName} />
                                            </Grid>
                                            {
                                                data.unId !== null ?
                                                    <>
                                                        <Grid item xs={12} sm={12} md={6} lg={6} ></Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6} >
                                                            <CpInput isDisabled={true} label={intl.formatMessage({ id: 'templateNo' })} name="templateNo" isRequired={false} type="text" value={data.templateNo} />
                                                        </Grid>
                                                    </>
                                                    : null
                                            }
                                            <Grid item xs={12} sm={12} md={6} lg={6} ></Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                <CpCheckBox isForm={true} isDisabled={data.readOnly} label={intl.formatMessage({ id: 'availablePublicly' })} name='availablePublicly' checked={data.availablePublicly} onChange={(event) => handleSwtichChange(event)} />
                                            </Grid>
                                        </Grid>
                                    </Box> :
                                    null}
                                {formFor === "booking" ?
                                    <Grid flex="true" container spacing={0} style={{ paddingTop: '1%' }}>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <CpTypeahead isDisabled={data.templateLoading ? true : data.unId === null ? false : true} isRequired={false} label={intl.formatMessage({ id: 'applyTemplate' })} name='applyTemplate' afterSelectionShow="description" options={data.bookingTemplates} value={data.templateNo} showTitle={true} onChange={(selected) => handleBookingTemplatesChange(selected, 'templateName')} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ alignSelf: 'center' }}>
                                            {data.templateLoading ?
                                                <p className={classes.templateLoading} >Loading...</p>
                                                : null}
                                            {data.templateLoading === false && data.templateNo !== '' ?
                                                <div>
                                                    <span>
                                                        {`${intl.formatMessage({ id: 'templateNo' })} ${data.templateNo} `}
                                                    </span>
                                                </div>
                                                : null
                                            }
                                        </Grid>
                                    </Grid>
                                    : null}
                            </div>
                            <div name="Details" ref={detailsDivToFocus}>
                                <Box header={intl.formatMessage({ id: 'details' })}>
                                    <DetailsForm
                                        formFor={formFor}
                                        unId={data.unId}
                                        onChange={onChange}
                                        handleCountryChange={handleCountryChange}
                                        handleDateorTimeChange={handleDateorTimeChange}
                                        handlePickupFromToTimings={handlePickupFromToTimings}
                                        handleSwtichChange={handleSwtichChange}
                                        handlePopOverOpen={handlePopOverOpen}
                                        handleTypeaheadChange={handleTypeaheadChange}
                                        data={data.details}
                                        supReadyDate={supReadyDate}
                                        errordetails={data.errordetails}
                                        destCityRef={destCityRef}
                                        orgCityRef={orgCityRef}
                                        polCityRef={polCityRef}
                                        podCityRef={podCityRef}
                                        polCountryRef={polCountryRef}
                                        podCountryRef={podCountryRef}
                                        readOnly={data.readOnly}
                                        noOptionMsg={data.noOptionMsg}
                                        performPartyLookupSearch={performPartyLookupSearch}
                                        handleTypeAheadInputChange={handleTypeAheadInputChange}
                                        onInputChange={onInputChange}
                                        enableShipperOrConsgineeAddress={enableShipperOrConsgineeAddress}
                                        onChangeAddress={onChangeAddress}
                                        handleToggleButtonChange={handleToggleButtonChange}
                                        pickupCollectAddress={data.pickupCollectAddress}
                                        pickupDeliveryAddress={data.pickupDeliveryAddress}
                                        deliverCollectAddress={data.deliverCollectAddress}
                                        deliverDeliveryAddress={data.deliverDeliveryAddress}
                                        autoFocusCustomer={data.autoFocusCustomer}
                                        autoFocusShipper={data.autoFocusShipper}
                                        autoFocusConsignee={data.autoFocusConsignee}
                                        readOnlyHeaderList={data.readOnlyHeaderList}
                                        showorHideExtraFields={showorHideExtraFields}
                                        supUser={data.cpSupUser}
                                    />
                                </Box>
                                {data.readOnly ?
                                    <>
                                        <Box header={intl.formatMessage({ id: 'pickupAtOrigin' })}>
                                            <NonEditableDataGrid list={data.cpSupUser ? data.supPickupList :data.pickupList} heading='' data={data.details} />
                                        </Box>
                                        <Box header={intl.formatMessage({ id: 'deliveryatDest' })}>
                                            <NonEditableDataGrid list={data.cpSupUser ? data.supDeliveryList :data.deliveryList} heading='' data={data.details} />
                                        </Box>
                                    </>
                                    : null}
                            </div>
                            {containerCond || (data.details.bizType === 'IL' && data.details.shipmentType === 'FTL') ?
                                < div ref={containersDivToFocus} >
                                    <Box header={ containerCond ? intl.formatMessage({ id: 'containers' }) : intl.formatMessage({ id: 'loadUnit' })}  isRequired={ supUserBooking && containerCond  ? true:false}>
                                        <ContainersTable readOnly={data.readOnly} data={ vehicalsCond ? data.vehiclesContainersList :data.containerslist} removefunc={removeContainers} addfunc={addContainers} onChangefields={onChangeContainerfields} 
                                            selectedContainer={data.selectedContainer} onSaveContainersorItems={onSaveContainersorItems} onEditContainersorItems={onEditContainersorItems}  
                                            formFor={formFor} supUserBooking={supUserBooking} containerCond={containerCond} errordetails={data.errordetails} onCancelContainersorItems={onCancelContainersorItems} />
                                    </Box>
                                </div> : null}
                            <div ref={itemsDivToFocus}>
                                <Box header={intl.formatMessage({ id: 'items' })}>
                                    <ItemsTable readOnly={data.readOnly} data={data.itemslist} removefunc={removeItems} addfunc={addItems} onChangefields={onChangeItemfields} selectedItem={data.selectedItem} onSaveContainersorItems={onSaveContainersorItems} onEditContainersorItems={onEditContainersorItems} onCancelContainersorItems={onCancelContainersorItems} />
                                </Box>
                            </div>
                            {formFor === "bookingTemplate" ? null
                                : <>
                                    <div className={classes.sectionDesktop} ref={documentsDivToFocus}>
                                        <Box header={intl.formatMessage({ id: 'documents' })} showloading={true}>
                                            <DocumentTable data={data.documents}
                                                readOnly={docUploadReadOnly}
                                                onUpload={handleUploadFile}
                                                uploadRequired={true}
                                                removeNewDocFunc={removeNewDocFunc}
                                                enableIcons={data.enableIcons}
                                                enableIconsForDocs={enableIconsForDocs}
                                                onChangeDocfields={onChangeDocumentfields}
                                                handleDeleteExistingDocs={handleDeleteExistingDocs}
                                                docTypes={data.docTypes}
                                                fileErrors={fileErrors}
                                                fileTypes={fileTypes}
                                                fileSize={fileSize}
                                                docErrorHandle={getDocDownloadErrorDetails} />
                                        </Box>
                                    </div>
                                    <div className={classes.sectionMobile} >
                                        <Box style={{ 'width': '100%' }} header={intl.formatMessage({ id: 'documents' })} showloading={true}>
                                            <DocumentsTableMobile data={data.documents}
                                                readOnly={data.readOnly}
                                                onUpload={handleUploadFile}
                                                uploadRequired={true}
                                                removeNewDocFunc={removeNewDocFunc}
                                                enableIcons={data.enableIcons}
                                                enableIconsForDocs={enableIconsForDocs}
                                                onChangeDocfields={onChangeDocumentfields}
                                                handleDeleteExistingDocs={handleDeleteExistingDocs}
                                                docTypes={data.docTypes}
                                                fileErrors={fileErrors}
                                                fileTypes={fileTypes}
                                                fileSize={fileSize}
                                                docErrorHandle={getDocDownloadErrorDetails} />
                                        </Box>
                                    </div>
                                    <div ref={aduitTrailDivToFocus}>
                                        <Box header={intl.formatMessage({ id: 'auditTrails' })}>
                                            <NonEditableDataGrid list={data.auditTrailList} heading='' data={data.auditTrail}  />
                                        </Box>
                                    </div>
                                </>}
                        </Grid>
                    </Grid>
                </form>
            </div >
        </FormContext >
    )
}

export default injectIntl(withCPContext(withStyles(style)(CPCollectiveForm)))
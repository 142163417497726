import React from 'react'
import { CPContextConsumer } from '../../common/CPcontext';

const withCPContext = (Component) => {
    return (props) => (
        <CPContextConsumer>
            {(cpContext) =>
                <Component {...props} cpContext={cpContext} />
            }
        </CPContextConsumer>
    )
}

export default withCPContext;
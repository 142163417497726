import React from 'react'
import { LoadingConsumer } from '../../common/loading'
const withLoading = (Component) => {
    return (props) => (
        <LoadingConsumer>
            {(loading) =>
                <Component {...props} loading={loading} />
            }
        </LoadingConsumer>
    )
}

export default withLoading;
import React, { Component } from 'react';
import CPCollectiveContainer from '../universal/CPCollectiveContainer';

class Booking extends Component {
    render() {
        
        return <CPCollectiveContainer {...this.props} formFor="booking" />
    }

}

export default Booking
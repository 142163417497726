import React, { Component } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import withTenant from '../../common/withTenant';
import withError from '../../common/withError';
import ShipmentsByMOT from "./ShipmentsByMOT"
import ShipmentsByStatus from './ShipmentsbyStatus';
import ShipmentsbyEntryTypes from './ShipmentsbyEntryTypes';
import ReleasedShipments from './ReleasedShipments';

const style = theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16)
        }
    }
});
class CMUSDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isDesktop: window.innerWidth >= 960 ? true : false
        }
    }
    componentDidMount = () => {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({ isDesktop: window.innerWidth <= 960 ? false : true });
    }
    render() {
        const { tenantDetails } = this.props;
        return (
            <Grid container spacing={2} flex='true' direction="row" style={{ width: '100%', margin: '1% 0 0 0 ' }}>
                <Grid item xl={6} lg={6}>
                    <Grid container spacing={2} flex='true' direction="row" justify="flex-start" alignItems="center">
                        {tenantDetails.accessLevels.accessToShipments === true ?
                            <>
                                <Grid item lg={6} xs={12} sm={12} md={6} xl={6}>
                                    <Paper variant="outlined" >
                                        <ShipmentsByMOT {...this.props} />
                                    </Paper>
                                </Grid>
                                <Grid item lg={6} xs={12} sm={12} md={6} xl={6}>
                                    <Paper variant="outlined" >
                                        <ShipmentsbyEntryTypes {...this.props} />
                                    </Paper>
                                </Grid>
                                <Grid item lg={6} xs={12} sm={12} md={6} xl={6} >
                                    <Paper variant="outlined" >
                                        <ReleasedShipments {...this.props} />
                                    </Paper>
                                </Grid>
                                <Grid item lg={6} xs={12} sm={12} md={6} xl={6}>
                                    <Paper variant="outlined" >
                                        <ShipmentsByStatus {...this.props} />
                                    </Paper>
                                </Grid>
                            </>
                            : null}

                    </Grid>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Paper variant="outlined" style={{ height: '100%', maxHeight: '74vh' }} >
                        <a href={`${tenantDetails.redirectUrl}`} target="_blank" rel="noopener noreferrer">
                            <img className="img" src={`/secure-ui/themes/${tenantDetails.tenant}/brand.gif`} style={{ height: '100%', width: '100%', position: 'relative', left: '0' }} alt="Brand space" />
                        </a>
                    </Paper>
                </Grid>
            </Grid >
        );

    }
}

export default injectIntl(withTenant(withError(withRouter(withStyles(style)(CMUSDashboard)))));
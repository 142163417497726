import React, { Component } from 'react'
import CPCollectiveContainer from '../universal/CPCollectiveContainer'

export class Quote extends Component {
    render() {
        return <CPCollectiveContainer {...this.props} formFor="quote" />
    }
}

export default Quote

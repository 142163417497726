import { Link, Typography } from "@material-ui/core";
import { injectIntl } from "react-intl";
import Box from "../../common/Box";
import CpEditableTable from "../../common/CpEditableTable";
import NonEditableDataGrid from "../../common/NonEditableDataGrid";
import CpBreadcrumb from "../../common/CpBreadcrumb";

function BillOfLadingContainer(props) {
    const mainSectionHeader = [
        { title: "Container No.", field: "noContainer" },
        { title: "Type", field: "contEquipDescCode" },
        { title: "Seal 1", field: "containerSeal1" },
        { title: "Seal 2", field: "containerSeal2" },
        { title: "Marks and Numbers", field: "marks" }
    ]

    const CommodityHeaders = [
        { 'width': '15%', 'title': 'Line No', 'field': 'seqNo', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Assembler', 'field': 'assembler', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Value US$', 'field': 'valueInBond', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Exchange Rate', 'field': 'exchangeRateAmt', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Pieces', 'field': 'piecesUnitOfMeasure', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Description', 'field': 'cargoDesc', 'showLabel': true, 'type': 'label' }

    ]

    const { intl, handleDisplayBack, l1totalRecords, l1selectedRecord, l2totalRecords, l2selectedRecord, handleDisplayForward, bolCntData } = props

    return (
        <div>
            <div style={{ display: 'flex', margin: '2rem 0 0 2rem' }}>
                <CpBreadcrumb >
                    <Link color='inherit' onClick={() => handleDisplayBack(1)}> {intl.formatMessage({ id: 'bol' }, { val1: l1selectedRecord, val2: l1totalRecords })} </Link>
                    <Typography color='textPrimary'>{intl.formatMessage({ id: 'containerValues' }, { val1: l2selectedRecord, val2: l2totalRecords })}</Typography>
                </CpBreadcrumb>
            </div>
            <Box showloading={true} header={intl.formatMessage({ id: 'containerValues' }, { val1: l2selectedRecord, val2: l2totalRecords })} >
                <NonEditableDataGrid list={mainSectionHeader} data={bolCntData ? bolCntData : {}} />
            </Box>
            <Box header={intl.formatMessage({ id: 'commodities' })}>
                <CpEditableTable headers={CommodityHeaders} readOnly={true} data={(bolCntData && bolCntData.inBondTariffList) ? bolCntData.inBondTariffList : []} navigateIcon={true} handleDisplayForward={(row, index) => handleDisplayForward(3, row, index)} />
            </Box>
        </div>
    );
}

export default injectIntl(BillOfLadingContainer)
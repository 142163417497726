import React from 'react'
import { TenantConsumer } from '../../common/Tenant';

const withTenant = (Component) => {
    return (props) => (
        <TenantConsumer>
            {(tenantDetails) =>
                <Component {...props} tenantDetails={tenantDetails} />
            }
        </TenantConsumer>
    )
}

export default withTenant;
const CMUS_GATEWAY_URL = '/api-gateway/cmus/1';
const CMUS_WIDGETS_URL = '/api-gateway/cmus/1/shipments-widget-metadata?countBy=';
const SHIPMENT_URL = '/shipment';
const SHIPMENTS_URL = '/shipments';
const CATCH_EVITCITON_SHIPMENTS = "/api-gateway/cmus/1/clear-shipments-widget-cache?cacheAttribute="
const GET_INVOICE_ITEMS = '/CiHeader'
const GET_CI_LINES = '/CiLines'
const GET_CI_TARIFF = '/CiTariffClass'
const GET_SHIPMENT_HEADERS = '/ShipmentHeader';
const GET_SHIPMENT_DOCUMENTS = '/documents/?value='
const DOCUMENT_DOWNLOAD = '/documents/'
const GET_SHIPMENT_REMARKS = '/ShipmentNotes';
const GET_DELIVERY_ORDERS = '/DoHeader';
const GET_SHIPMENT_MILESTONES = '/ShipmentDates';
const GET_SHIPMENT_BILLING = '/InvoiceHeader';
const GET_BILLING_INVOICE_DETAILS = '/InvoiceLines';
const CUSTOMER_LOOKUP = "/api-gateway/1/lookups/um-parties?custNo=";
const PORTS_LOOKUP = "/api-gateway/cmus/1/lookups/"
const HOMEPAGE_HELP_CMUS = '/CustomsAuthenticatePortal-Help/Landing_Page.html'
const SEARCH_SHIPMENT_HELP = '/CustomsAuthenticatePortal-Help/Search_Shipments.html';

const GET_PGADETAILS = '/PgEs'
const FETCH_FILE_EXTENSIONS = '/file-extensions'
const FETCH_DOC_TYPES = '/lookups/DmDocTypesView'
const GET_FTZ_CONCUR_PPT = '/FtzPttConcur'
const GET_CONVEYANCES = '/FtzConveyance'
const GET_CONVEYANCE_BOL = '/FtzShipmentId'
const GET_CONVEYANCE_CONTAINER = '/FtzContainers'
const GET_CONVEYANCE_COMMODITY = '/FtzLines'
const INBOND_CONVEYANCE = '/InBondConveyance'
const BILL_OF_LADING = "/InBondShipmentId"
const BILL_OF_LADING_CONTAINER = "/InBondContainers"
const BILL_OF_LADING_COMMODITY = "/InBondTariff"

export {
    CMUS_GATEWAY_URL,
    CMUS_WIDGETS_URL,
    SHIPMENT_URL,
    CATCH_EVITCITON_SHIPMENTS,
    GET_INVOICE_ITEMS,
    GET_CI_LINES,
    GET_CI_TARIFF,
    GET_SHIPMENT_HEADERS,
    GET_SHIPMENT_DOCUMENTS,
    DOCUMENT_DOWNLOAD,
    GET_SHIPMENT_REMARKS,
    GET_DELIVERY_ORDERS,
    GET_SHIPMENT_MILESTONES,
    GET_SHIPMENT_BILLING,
    SHIPMENTS_URL,
    CUSTOMER_LOOKUP,
    PORTS_LOOKUP,
    HOMEPAGE_HELP_CMUS,
    SEARCH_SHIPMENT_HELP,
    GET_BILLING_INVOICE_DETAILS,
    GET_PGADETAILS,
    FETCH_FILE_EXTENSIONS,
    FETCH_DOC_TYPES,
    GET_FTZ_CONCUR_PPT,
    GET_CONVEYANCES,
    GET_CONVEYANCE_BOL,
    GET_CONVEYANCE_CONTAINER,
    GET_CONVEYANCE_COMMODITY,
    INBOND_CONVEYANCE,
    BILL_OF_LADING,
    BILL_OF_LADING_CONTAINER,
    BILL_OF_LADING_COMMODITY
}
import React from 'react'
import ReactEcharts from 'echarts-for-react';
import { useTheme, withStyles } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import { ReactComponent as LoadingIcon } from '../../../src/images/loading.svg'
import RefreshIcon from '@material-ui/icons/Refresh';
import { injectIntl } from 'react-intl';
import { generateColorGradiants } from '../../common/utils';

const style = {
    topDiv: {
        margin: '10px',
        display: 'flex'
    },
    noDataDiv: {
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Open Sans Italic',
        fontStyle: 'italic'
    },
    loadingDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '25rem',
        flexDirection: 'column'
    }
}

const FunnelChart = (props) => {
    const { classes, data, heading, handleClick, loading, handleRefresh, intl, noRefresh, showIcon } = props;
    const theme = useTheme()
    const primaryColor = theme.palette.primary.main
    const onEvents = {
        click: (event) => handleClick ? handleClick(event) : null
    };
    const seriesData = data
    const option = {
        tooltip: {
            trigger: 'item',
            axisPointer: { type: 'cross', crossStyle: { type: 'solid' } },
            formatter: '{b} : {c} ',
            fontSize: '8px',
            borderColor: 'black',
            borderWidth: '0.1',
            backgroundColor: '#fafcfe',
            borderRadius: '0px',
            textStyle: {
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '6px'
            }
        },
        toolbox: {
            show: true,
            orient: 'vertical',
            top: 'center',

        },

        series: [

            {
                name: 'Funnel',
                color: generateColorGradiants(primaryColor),
                type: 'funnel',
                sort: 'ascending',
                funnelAlign: 'left',
                data: seriesData,
                height: '65%',
                label: {
                    show: true,
                    fontSize: ' 8 px',
                    color: "rgba(10, 0, 0, 1)",
                    padding: [0, 0, 0, 0],
                    ellipsis: '...',
                    overflow: 'truncate'
                }
            },

        ]
    };
    return (
        <div>
            {
                loading ?
                    <div className={classes.loadingDiv} >
                        <LoadingIcon />
                        <span>Loading...</span>
                    </div> :
                    <>
                        <div className={classes.topDiv} >
                            {
                                showIcon === false ? null : <LocalShippingIcon id={'widgetName'} color='disabled' fontSize="large" />
                            }
                            <label id={'widgetLabel'} className={classes.shipmentLabel}>{heading}</label>

                            {noRefresh ? null : <RefreshIcon onClick={handleRefresh} fontSize="large" style={{ cursor: 'pointer', marginLeft: 'auto', color: '#417F90' }} />}
                        </div>
                        {
                            seriesData.length === 0 ?
                                <div className={classes.noDataDiv}>
                                    {intl.formatMessage({ id: 'no_data' })}
                                </div> :
                                <div>
                                    <ReactEcharts option={option} onEvents={onEvents} style={{ height: "200px" }} />
                                </div>
                        }
                    </>
            }
        </div>
    )
}

export default injectIntl(withStyles(style)(FunnelChart))

import React from 'react'
import { Card, CardContent, withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';


const style = theme => ({
    line: {
        margin: 0,
        display: 'flex'
    },
    title: {
        fontWeight: 'bold',
        width: '20%',
        display: 'flex',
        padding: '1%'
    },
    titleNormal: {
        fontWeight: 'Normal',
        width: '20%',
        display: 'flex',
        padding: '1%'
    },
    value: {
        width: '80%',
        display: 'inline-block',
        padding: '1%',
        fontWeight: 400
    },
    root: {
        minWidth: 275,
        width: '98%',
        margin: '1%'
    },
    cardRoot: {
        padding: '0 0 0 0 !important',
        marginBottom: '10px !important'
    },
    odd: {
        backgroundColor: 'rgba(237, 239, 239, 1)'
    },
    even: {
        backgroundColor: 'white'
    },
    noRecordsFound: {
        padding: '2% !important',
        textAlign: 'center'
    }
})
const MileStoneMobileView = (props) => {
    const { data, classes, intl } = props;
    const getOddorEven = (index) => {
        return index % 2 === 0 ? "even" : "odd"
    }
    return (
        <Card className={classes.root} variant="outlined" >
            {data ?
                data.map((el, index) => {
                    return (
                        <CardContent key={index} className={`${classes.cardRoot} ${classes[getOddorEven(index)]}`}>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.title}> <span >{el.eventCode}</span></div>
                                <div className={classes.value}> <span >{el.eventName}</span></div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.titleNormal}> <span >Est.</span></div>
                                <div className={classes.value}> <span >{el.estimatedDate}</span></div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.titleNormal}> <span >Act.</span></div>
                                <div className={classes.value}> <span >{el.acutalDate}</span></div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'location' })}</span></div>
                                <div className={classes.value}> <span >{el.location}</span></div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'notes' })}</span></div>
                                <div className={classes.value}> <span >{el.remarks}</span></div>
                            </div>
                        </CardContent>

                    )
                })
                :
                <CardContent className={classes.noRecordsFound}>{intl.formatMessage({ id: 'noRecordsFound' })}</CardContent>
            }
        </Card>
    )
}

export default injectIntl(withStyles(style)(MileStoneMobileView))

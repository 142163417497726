import React from 'react'
import { Route } from 'react-router-dom'
import Page403 from '../ErrorPages/Page403'

function PrivateRoute({ children, ...rest }) {
    const { enableRoute } = rest
    return (

        <Route
            {...rest}
            render={({ location }) =>
                sessionStorage.getItem("Auth-Token") !== null && enableRoute === true ?
                    <>
                        {children}
                    </>
                    :
                    enableRoute !== true ?
                        <Page403 isForReset={false}/>
                        :
                        null
            }
        />
    )
}

export default PrivateRoute
import React from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Component } from 'react';
import { withStyles, Fab, IconButton, Link, Typography } from '@material-ui/core';
import CPButton from '../../components/common/Button'
import '../../styles/bar.css'
import { getContrastColor } from '../../common/utils';
import CPCSVDownloader from '../common/CPCSVDownloader'
import withLoading from '../common/withLoading';
import withTenant from '../common/withTenant';
import { injectIntl } from "react-intl";
import CardsTable from '../mobileComponents/CardsTable';
import ScrollTop from '../mobileComponents/ScrollTop';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import peopleIcon from '../../images/people.svg';
import personIcon from '../../images/person.svg'
import enabledIcon from '../../images/checkCircle.svg';
import disabledIcon from '../../images/cancel.svg';
import HelpIcon from '@material-ui/icons/Help';
import { fetchBookingTemplates } from '../../sevices/TMFF/Services';
import CpBreadcrumb from '../common/CpBreadcrumb';
import CpCheckBox from '../common/CpCheckBox';

const style = ({ breakpoints, palette }) => ({
    containerDiv: {
        background: 'white',
        padding: '0 2%',
        overflow: 'auto'
    },
    heading: {
        background: 'white',
        height: '4rem',
        padding: '0.75%',
        display: 'flex'
    },
    underline: {
        '&:hover': {
            textDecoration: "underline !important",
            color: '#277AB5'
        }
    },
    gridTextDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            fontWeight: '700',
            fontStyle: 'normal',
            marginTop: '2%',
            marginBottom: '2%',
            height: '4rem',
            display: 'flex'
        }
    },
    gridTextMobile: {
        fontWeight: '700',
        fontStyle: 'normal',
        marginTop: '2%',
        marginBottom: '2%',
        height: '4rem',
        display: 'flex',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    gridButtonMobile: {
        marginTop: '1%',
        height: '40px',
        display: 'flow-grow',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    buttonWarpper: {
        padding: '1% 0',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#007ad9'
    },
    tableWrapper: {
        border: '1px solid #E8F0F4 !important'
    },
    sectionDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'initial',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    fabIcon: {
        backgroundColor: palette.primary.main,
        color: getContrastColor(palette.primary.main)
    },
    detailText: {
        paddingLeft: '0.75%',
        fontSize: '24px'
    },
    recordsText: {
        flexGrow: '1',
        paddingLeft: '0.75%',
        paddingTop: '0.65%',
        fontSize: '14px'
    }
})

const mobileorDownloadHeaders = [
    { label: "Status", key: "templateStatus" },
    { label: "Availability", key: "avilablity" },
    { label: "Template Name", key: "templateName" },
    { label: "Template No.", key: "templateNo" },
    { label: "Shipper", key: "shipper" },
    { label: "Commodity", key: "commodity" },
    { label: "Departure", key: "departure" },
    { label: "Destination", key: "destination" }
]
const mobileorDownloadHeadersCPAdmin = [
    { label: "Status", key: "templateStatus" },
    { label: "Availability", key: "avilablity" },
    { label: "Template Name", key: "templateName" },
    { label: "Template No.", key: "templateNo" },
    { label: "Customer", key: "customer" },
    { label: "Shipper", key: "shipper" },
    { label: "Commodity", key: "commodity" },
    { label: "Departure", key: "departure" },
    { label: "Destination", key: "destination" }
]
class BookingTemplates extends Component {
    constructor(props) {
        super(props);
        const { intl } = props;
        this.state = {
            selectedRow: null,
            fromDashBoard: false,
            columnDefs: [
                { headerName: '', maxWidth: 80, floatingFilter: false, suppressMenu: true, cellRenderer: this.StatusRender },
                { headerName: intl.formatMessage({ id: 'booking_templates_search_template_name' }), field: "templateName", floatingFilter: true, minWidth: 400, suppressMenu: true, tooltipField: "templateName" },
                { headerName: intl.formatMessage({ id: 'templateNo' }), field: "templateNo", floatingFilter: true, minWidth: 120, suppressMenu: true, tooltipField: "templateNo" },
                { headerName: intl.formatMessage({ id: 'booking_templates_search_shipper' }), field: "shipper", floatingFilter: true, minWidth: 120, suppressMenu: true, tooltipField: "shipper" },
                { headerName: intl.formatMessage({ id: 'booking_templates_search_commodity' }), field: "commodity", floatingFilter: true, minWidth: 90, suppressMenu: true, tooltipField: "commodity" },
                { headerName: intl.formatMessage({ id: 'booking_templates_search_departure' }), field: "departure", floatingFilter: true, minWidth: 120, suppressMenu: true, tooltipField: "departure" },
                { headerName: intl.formatMessage({ id: 'booking_templates_search_destination' }), field: "destination", floatingFilter: true, minWidth: 150, suppressMenu: true, tooltipField: "destination" },
            ],
            columnDefsCPAdmin: [
                { headerName: '', maxWidth: 80, floatingFilter: false, suppressMenu: true, cellRenderer: this.StatusRender },
                { headerName: intl.formatMessage({ id: 'booking_templates_search_template_name' }), field: "templateName", floatingFilter: true, minWidth: 400, suppressMenu: true, tooltipField: "templateName" },
                { headerName: intl.formatMessage({ id: 'templateNo' }), field: "templateNo", floatingFilter: true, minWidth: 120, suppressMenu: true, tooltipField: "templateNo" },
                { headerName: intl.formatMessage({ id: 'booking_templates_search_customer' }), field: "customer", floatingFilter: true, minWidth: 120, suppressMenu: true, tooltipField: "customer" },
                { headerName: intl.formatMessage({ id: 'booking_templates_search_shipper' }), field: "shipper", floatingFilter: true, minWidth: 120, suppressMenu: true, tooltipField: "shipper" },
                { headerName: intl.formatMessage({ id: 'booking_templates_search_commodity' }), field: "commodity", floatingFilter: true, minWidth: 90, suppressMenu: true, tooltipField: "commodity" },
                { headerName: intl.formatMessage({ id: 'booking_templates_search_departure' }), field: "departure", floatingFilter: true, minWidth: 120, suppressMenu: true, tooltipField: "departure" },
                { headerName: intl.formatMessage({ id: 'booking_templates_search_destination' }), field: "destination", floatingFilter: true, minWidth: 150, suppressMenu: true, tooltipField: "destination" },
            ],
            gridOptions: {
                components: {
                    statusRenderer: this.StatusRender
                }
            },
            mobileData: null,
            rowData: null,
            defaultColDef: {
                flex: 1,
                sortable: true,
                filter: true,
                floatingFilter: true,
            },
            icons: {
                filter: '<i class="fa ArrowDropDownIcon"/>'
            },
            nextPageIndex: 0,
            filteredData: [],
            isCpAdmin: sessionStorage.getItem("isCPAdmin") === "true" ? true : false,
            previousRowData: null,
            activeRowData: null,
            isSwitchBoolean: false
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
    };

    selectedRow = async () => {
        const { handleRowClick } = this.props;
        let row = this.gridApi.getSelectedRows();
        if (row) {
            this.setState({
                selectedRow: row
            })
            handleRowClick(this.gridApi.getSelectedRows(), 'templates', this.state.fromDashBoard);
        }
        else {
            handleRowClick(this.state.selectedRow, 'templates', this.state.fromDashBoard);
        }
    }

    filterchange = async (event) => {
        const filteredData = event.api.rowModel.rowsToDisplay.map(row => {
            return row.data
        })
        this.setState({
            filteredData: filteredData
        })
    }
    onCardClick = async (id) => {
        const { handleRowClick } = this.props;
        handleRowClick([this.state.mobileData[id]], 'templates', this.state.fromDashBoard);
    }

    componentDidMount = async () => {
        const fetchedBookingTemplates = await fetchBookingTemplates();
        if (fetchedBookingTemplates && fetchedBookingTemplates.bookings) {
            const activeData = fetchedBookingTemplates.bookings.length > 0 ? fetchedBookingTemplates.bookings.filter((status) => { return status.templateStatus === "Active" }) : []
            await this.setState({
                rowData: fetchedBookingTemplates.bookings,
                activeRowData: activeData,
                previousRowData: activeData,
                filteredData: activeData,
                mobileData: this.processBookingTemplateData(activeData)
            })
        }
        else {
            await this.setState({
                rowData: [],
                filteredData: [],
                mobileData: [],
                activeRowData: [],
                previousRowData: []
            })
        }

    }

    StatusRender = (params) => {
        let element = document.createElement("span");
        let imageElement1 = ''
        let imageElement2 = ''
        if (params.data.templateStatus === 'Active') {
            imageElement1 = '<img width="16px" src="' + enabledIcon + '" title="Enabled"/>';
        } else {
            imageElement1 = '<img width="16px" src="' + disabledIcon + '" title="Disabled"/>';
        }
        if (params.data.isPrivate === 0) {
            imageElement2 = '<img width="16px" src="' + peopleIcon + '" title="Public"/>';
        }
        else {
            imageElement2 = '<img width="16px" src="' + personIcon + '" title="Private" />';
        }
        element.innerHTML = imageElement1 + '&emsp;' + imageElement2
        return element;
    }
    processBookingTemplateData = (data) => {
        const updatedData = data ?
            data.map(row => {
                return {
                    ...row,
                    avilablity: row.isPrivate === 1 ? "Private" : "Public"
                }
            }) :
            []
        return updatedData
    }
    handleSwtichChange = (data) => {
        const { rowData, previousRowData } = this.state
        if (data.target.checked) {
            this.setState({
                activeRowData: rowData,
                mobileData: this.processBookingTemplateData(rowData),
                filteredData: rowData,
                isSwitchBoolean: true
            })
        }
        else {
            this.setState({
                activeRowData: previousRowData,
                mobileData: this.processBookingTemplateData(previousRowData),
                filteredData: previousRowData,
                isSwitchBoolean: false
            })
        }
    }
    render() {
        const { classes, backToHome, intl, tenantDetails, handleNewTemplate } = this.props
        const { columnDefs, defaultColDef, rowClassRules, mobileData, filteredData, isCpAdmin, columnDefsCPAdmin, activeRowData, isSwitchBoolean } = this.state
        return (
            <div className={classes.containerDiv}>
                <div className={classes.heading}>
                    <CpBreadcrumb>
                        <Link color='inherit' classes={{ underlineHover: classes.underline }} href="#" onClick={() => backToHome('templates')}>{intl.formatMessage({ id: 'home' })}</Link>
                        <Typography color='textPrimary' >{intl.formatMessage({ id: 'templates_header' })}</Typography>
                    </CpBreadcrumb>
                    <a href={`${window.location.protocol + '//' + window.location.host}/webHelp/cp-booking-templates.html`} target="_blank" style={{ color: "inherit", flexGrow: '1' }} rel="noopener noreferrer">
                        <IconButton style={{ float: "right", padding: '0px' }}
                            aria-label="account of current user"
                            aria-controls="primary-search-account-menu"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <HelpIcon style={{ fontSize: '28px', color: "#6E787D" }} />
                        </IconButton>
                    </a>
                </div>
                <div className={classes.gridTextDesktop}>
                    <span className={classes.detailText}> {intl.formatMessage({ id: 'templates_header' })}</span> <span className={classes.recordsText}>{activeRowData ? activeRowData.length : '0'} {intl.formatMessage({ id: 'records' })}</span>
                    {filteredData ? filteredData.length > 0 ? <CPCSVDownloader data={this.processBookingTemplateData(filteredData)} headers={isCpAdmin ? mobileorDownloadHeadersCPAdmin : mobileorDownloadHeaders} filename="BookingTemplates" style={{ float: 'right' }} /> : null : null}
                    {
                        tenantDetails.accessLevels.accessToBookingTemplates === true ? <CPButton variant='primary' onClick={() => handleNewTemplate()} id='newtemplate'>{intl.formatMessage({ id: 'newTemplate' })} </CPButton> : null
                    }
                </div>
                <div className={classes.gridButtonMobile}>
                    {
                        tenantDetails.accessLevels.accessToBookingTemplates === true ? <CPButton variant='primary' styles={{ float: 'right' }} onClick={() => handleNewTemplate()} id='newtemplate'>{intl.formatMessage({ id: 'newTemplate' })} </CPButton> : null
                    }
                    {filteredData ? filteredData.length > 0 ? <CPCSVDownloader data={filteredData} headers={isCpAdmin ? mobileorDownloadHeadersCPAdmin : mobileorDownloadHeaders} filename="BookingTemplates" style={{ float: 'right' }} /> : null : null}
                </div>
                <div className={classes.gridTextMobile} >
                    <span style={{ paddingTop: '1px', paddingLeft: '2px' }}> {intl.formatMessage({ id: 'templates_grid_selection' })}</span>
                </div>
                <div className={classes.tableWrapper}>
                    <CpCheckBox isForm={false} checked={isSwitchBoolean} onChange={(event) => this.handleSwtichChange(event)} label="Include Disabled Templates" />
                    <div className={classes.sectionDesktop}>
                        <div
                            className="ag-theme-balham"
                            style={{ height: '410px', width: '100%', backgroundColor: 'rgba(0, 0, 0,0.04)' }} >
                            <AgGridReact
                                columnDefs={isCpAdmin ? columnDefsCPAdmin : columnDefs}
                                rowData={activeRowData}
                                defaultColDef={defaultColDef}
                                rowSelection={'single'}
                                onRowClicked={this.selectedRow}
                                rowClassRules={rowClassRules}
                                onGridReady={this.onGridReady}
                                headerHeight='42'
                                rowHeight='30'
                                floatingFiltersHeight='40'
                                onFilterChanged={this.filterchange}
                                gridOptions={this.state.gridOptions}
                                tooltipShowDelay={0}
                            />
                        </div>
                    </div>
                    <div className={classes.sectionMobile}>
                        <CardsTable data={mobileData} headers={isCpAdmin ? mobileorDownloadHeadersCPAdmin : mobileorDownloadHeaders} onCardClick={this.onCardClick} />
                        <ScrollTop >
                            <Fab className={classes.fabIcon} size="medium" aria-label="scroll back to top">
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(withTenant(withStyles(style)(withLoading(BookingTemplates))))

import React from 'react'
import { withStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import RateCard from './RateCard'
import CPButton from '../common/Button';
import CpTextarea from '../common/CpTextarea'
import CPConfirmBox from "../common/CPConfirmBox"
import NonEditableDataGrid from '../common/NonEditableDataGrid'
import Box from '../common/Box';
const style = ({ breakpoints, palette }) => ({
    rateHeading: {
        margin: '2% 0 0 0',
        fontWeight: 900,
        fontSize: '18px'
    },
    buttons: {
        padding: '2rem 0',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #C8CFD2',

    }
})
function QuoteView(props) {
    const { intl, data, classes, status, quoteAction, rejectReason, onChange, showRejectBox, handleReject, disableButtons, quoteData } = props
    const disableBook = status === "Accepted" ? false : true
    const disableAccept = status === "Awaiting Acceptance" ? false : true
    const disableAccept_Book = status === "Awaiting Acceptance" ? false : true
    const disableReject = status === "Rejected" || status === "Accepted" || status === "Booked" ? true : false
    const showReason = status === "Rejected" ? true : false
    return (
        <Box>
            <NonEditableDataGrid quoteView={true} list={quoteData.quotelist} heading='' data={quoteData.quoteDetails} />
            <div className={classes.rateHeading}>
                <span>{intl.formatMessage({ id: 'rate' })}</span>
            </div>
            <RateCard data={data}></RateCard>
            <div className={classes.buttons}>
                <CPButton variant="primary" onClick={() => quoteAction("Book")} disabled={disableAccept_Book || disableButtons} >{intl.formatMessage({ id: 'accept_book' })}</CPButton>
                <CPButton variant="secondary" onClick={() => quoteAction("Accept")} disabled={disableAccept || disableButtons}>{intl.formatMessage({ id: 'accept' })}</CPButton>
                <CPButton variant="secondary" onClick={() => quoteAction("Book")} disabled={disableBook || disableButtons}>{intl.formatMessage({ id: 'book' })}</CPButton>
                {disableReject ? null : <CPButton variant='containerLess' disabled={disableReject} onClick={() => showRejectBox ? quoteAction("Reject") : handleReject()}>{intl.formatMessage({ id: 'reject' })} </CPButton>}
                {showRejectBox ?
                    <>
                        <CPConfirmBox
                            show={showRejectBox} handleRejected={() => handleReject()} handleAccepted={() => quoteAction("Reject")} inputName="rejectReason" handleChange={onChange} inputValue={rejectReason} width="50%" placeHolder="Reject reason" rowsMin={2} rowsMax={2} />
                    </>
                    : null}
                {showReason ?
                    <CpTextarea name='rejectReason' value={rejectReason} isDisabled={true} rowsMin={2} rowsMax={2} width="50%" placeHolder="Reject reason" /> :
                    null}
            </div>
        </Box >
    )
}

export default injectIntl(withStyles(style)(QuoteView))

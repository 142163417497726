import React from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { withStyles, withTheme } from '@material-ui/core';
import { getContrastColor } from '../../common/utils';
import CpInput from '../common/CpInput';

const style = ({ palette }) => ({
    refreshCaptch: {
        color: getContrastColor(palette.secondary.main),
        background: palette.secondary.main,
        borderRadius: '7px',
        cursor: 'pointer',
        fontSize: '20px'
    },
    captchaMasterDiv: {
        width: '100%',
        display: 'flex'
    },
    captchRefreshSpan: {
        float: 'left',
        marginLeft: '5%',
        marginRight: '5%',
        marginTop: '1.5%'
    },
    captchaTextSpan: {
        display: 'inline-block'
    },
    canvas: {
        pointerEvents: 'none',
        border: '1px solid gray',
        backgroundColor: palette.secondary.main,
        fontFamily: 'Jazz LET',
        letterSpacing: '2px !important'
    }
})

class Captcha extends React.Component {

    charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    constructor(props) {
        super(props);
        this.state = {
            genratedcaptcha: '',
            inputcaptcha: '',
            message: ''
        };
    }
    componentDidMount() {
        this.prepareCaptchaString();
    }
    prepareCaptchaString = () => {
        const { theme } = this.props;
        const secondaryColor = theme.palette.secondary.main
        this.setState({ message: '', inputcaptcha: '' })
        let captcha = [];
        for (var i = 0; i < 5; i++) {
            captcha[i] = this.charsArray[Math.floor(Math.random() * this.charsArray.length)];
        }
        var canvas = document.getElementById("canvasCaptcha")
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.font = "italic bold 16px cursive";
        ctx.fillStyle = getContrastColor(secondaryColor)
        ctx.textAlign = "center";
        ctx.fillText(captcha.join(""), canvas.width / 2, canvas.height / 1.5);
        this.setState({ genratedcaptcha: captcha.join("") })
    }
    updateInputCaptcha = (event) => {
        if (event !== null) {
            this.setState({ inputcaptcha: event.target.value, message: '' });
        }
    }
    validateCaptcha() {
        const { genratedcaptcha, inputcaptcha } = this.state;
        const { intl } = this.props
        if (genratedcaptcha === inputcaptcha) {
            this.setState({ message: '' })
            return true;
        }
        else {
            this.prepareCaptchaString();
            this.setState({ message: intl.formatMessage({ id: "wrongCaptcha" }) })
            return false;
        }
    }

    render() {
        const { message, inputcaptcha } = this.state
        const { classes } = this.props
        return (
            <>
                {message !== '' ? <span style={{ color: 'red ' }} >{message}</span> : null}
                <div className={classes.captchaMasterDiv}>
                    <span className={classes.captchaTextSpan}>
                        <canvas id="canvasCaptcha" className={classes.canvas} width="120%" height="30px" />
                    </span>
                    <span className={classes.captchRefreshSpan}>
                        <AutorenewIcon id="refreshCaptcha" color='primary' className={classes.refreshCaptch} onClick={this.prepareCaptchaString} />
                    </span>
                    <span>
                        <CpInput name="inputCaptcha" type="text" placeholder="Enter captcha" variant='outlined' value={inputcaptcha} maxLength={5} onChange={(event) => this.updateInputCaptcha(event)} />
                    </span>
                </div>
            </>
        );
    }
}
export default withTheme(withStyles(style)(Captcha));
import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import '../../styles/bar.css'
import { withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
const style = theme => ({
  outlinedSuccess: {
    background: '#EFFAF1 !important',
    borderColor: '#366E4C !important',
    color: '#366E4C !important',
    '& .MuiAlert-icon': {
      color: '#366E4C !important',
    }
  },
  outlinedInfo: {
    background: '#F3F7F9 !important',
    borderColor: '#206595 !important',
    color: '#206595 !important',
    '& .MuiAlert-icon': {
      color: '#206595 !important',
    }

  },
  outlinedWarning: {
    background: '#FEFAF0 !important',
    borderColor: '#9C6E0D !important',
    color: '#9C6E0D !important',
    '& .MuiAlert-icon': {
      color: '#9C6E0D !important',
    }
  },
  outlinedError: {
    background: '#F9EFED !important',
    borderColor: '#AC3823 !important',
    color: '#AC3823 !important',
    '& .MuiAlert-icon': {
      color: '#AC3823 !important',
    }
  },
  root: {
    maxWidth: '90rem',
    borderRadius: '0.5rem',
    margin: '0 auto',
    display: "flex",
    alignItems: 'stretch',
    background: '#FFFFFF',
    border: '0.2rem solid',
    fontSize: '14px'
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '30px',
    marginRight: '0',
    opacity: '1'
  },
  message: {
    flex: '1 1 auto',
    padding: '1.5rem',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px'
  },
  action: {

  }
})
const CPSnackBar = (props) => {
  const [snack, setSnack] = useState({
    open: props.open,
    vertical: 'top',
    horizontal: 'center',
  });

  useEffect(() => {
    setSnack((prev) => ({ ...prev, open: props.open }))
  }, [props.snackBarId,props.message, props.open])

  const handleClose = () => {
    setSnack({ ...snack, open: false });
  };
  const { vertical, horizontal, open } = snack
  const { classes } = props

  return (

    <Snackbar
      open={open}
      autoHideDuration={(props.errorList && props.errorList.length > 0) ? 60000 : 5000}
      anchorOrigin={{ vertical, horizontal }}
      key={`${vertical},${horizontal}`}
      onClose={handleClose}
    >

      {/* Expected severities : {'info', 'success', 'error', 'warning'} */}
      <Alert
        onClose={handleClose}
        variant='outlined'
        classes={{ root: classes.root, outlinedSuccess: classes.outlinedSuccess, outlinedInfo: classes.outlinedInfo, outlinedWarning: classes.outlinedWarning, outlinedError: classes.outlinedError, message: classes.message, icon: classes.icon }}
        iconMapping={{
          success: <CheckCircleIcon fontSize='inherit' />,
          error: <ErrorIcon fontSize='inherit' />,
          info: <InfoIcon fontSize='inherit' />,
          warning: <WarningIcon fontSize='inherit' />
        }}
        severity={(!props.severity || props.severity === null) ? 'success' : props.severity}>
        {props.message}
        {props.errorList && props.errorList.length > 0 ?
          props.errorList.map(item => {
            return (
              <div>
                <span>{item}</span>
              </div>
            )
          })
          : null}
      </Alert>
    </Snackbar>
  )
}

export default withStyles(style)(CPSnackBar)
import { Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import withTenant from './common/withTenant';
import { logout } from '../sevices/common/CpServices';
import SettingsIcon from '@material-ui/icons/Settings';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

function UserContextMenuSection(props) {
    const { tenantDetails, userContextMenuEl,
        isUserContextMenuOpen,
        showChangePassword, history, handleEnableWidgetPreferences, handleCloseUserContextMenu, handleCopyRightBar } = props

    const umRedirect = () => {
        window.open(`${window.location.protocol + '//' + window.location.host}/usermanagement/#/`);
    }

    return (

        <Menu
            PopoverClasses={{ paper: props.classes.menuSection }}
            anchorEl={userContextMenuEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id='primary-search-account-menu-mobile'
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isUserContextMenuOpen}
            onClose={() => handleCloseUserContextMenu()}
        >
            {sessionStorage.getItem('userEmail') === '' ? null
                : <>
                    <MenuItem className={props.classes.menuItem}>
                        <ListItemText className={props.classes.listItemText} style={{ fontSize: '12px' }} primary={sessionStorage.getItem('userEmail')} secondary="User" />
                    </MenuItem>
                    <Divider className={props.classes.divider} style={{ width: '90%' }} />
                </>}

            {sessionStorage.getItem('isCPAdmin') === 'false' ?
                <>
                    <MenuItem className={props.classes.menuItem} onClick={() => { showChangePassword(); handleCloseUserContextMenu() }}>
                        <ListItemIcon className={props.classes.listItemIcon}>
                            <VpnKeyIcon style={{ fontSize: '16px' }} />
                        </ListItemIcon>
                        <ListItemText className={props.classes.listItemText}>Change Password</ListItemText>
                    </MenuItem>
                    {/* <Divider className={props.classes.divider} /> */}
                </>
                : null}
            <MenuItem className={props.classes.menuItem} onClick={() => { handleCopyRightBar(); handleCloseUserContextMenu() }}>
                <ListItemIcon className={props.classes.listItemIcon}>
                    <InfoOutlinedIcon style={{ fontSize: '16px' }} />
                </ListItemIcon>
                <ListItemText className={props.classes.listItemText}>About this software</ListItemText>
            </MenuItem>

            <Divider className={props.classes.divider} />
            {
                (sessionStorage.getItem('isCPAdmin') === 'true' && tenantDetails.baseSystem === "TMFF") ?
                    <>
                        <MenuItem className={props.classes.menuItem} onClick={() => { handleEnableWidgetPreferences(); handleCloseUserContextMenu() }}>
                            <ListItemIcon className={props.classes.listItemIcon}>
                                <SettingsIcon style={{ fontSize: '16px' }} />
                            </ListItemIcon>
                            <ListItemText className={props.classes.listItemText} >Set widget Preferences</ListItemText>
                        </MenuItem>
                        <Divider className={props.classes.divider} />
                    </>
                    : null
            }
            {
                sessionStorage.getItem('allowUM') === 'true' ?
                    <>
                        <MenuItem className={props.classes.menuItem} onClick={() => umRedirect()} >
                            <ListItemIcon className={props.classes.listItemIcon} aria-label="cpusermanagement" color="inherit">
                                <OpenInNewIcon style={{ fontSize: '16px' }} />
                            </ListItemIcon>
                            <ListItemText className={props.classes.listItemText} >UserManagement</ListItemText>
                        </MenuItem>
                        <Divider className={props.classes.divider} />
                    </>
                    : null
            }
            <MenuItem className={props.classes.menuItem} onClick={() => logout(history, undefined, undefined, tenantDetails.baseSystem)} >
                <ListItemIcon className={props.classes.listItemIcon} aria-label="logout" color="inherit">
                    <ExitToAppIcon style={{ fontSize: '16px' }} />
                </ListItemIcon>
                <ListItemText className={props.classes.listItemText}>Logout</ListItemText>
            </MenuItem>
        </Menu >
    )
}

export default withRouter(withTenant(UserContextMenuSection));
import { getConfig, getAuthorizationAndTenantAsHeaders, getHeadersWithUser, logError, getHeaders } from "../common/CpServices";
import axios from 'axios';

import {
    CATCH_EVITCITON_SHIPMENTS, CMUS_GATEWAY_URL, CMUS_WIDGETS_URL, SHIPMENT_URL, GET_INVOICE_ITEMS, GET_CI_LINES, GET_CI_TARIFF,
    GET_SHIPMENT_HEADERS, GET_SHIPMENT_DOCUMENTS, DOCUMENT_DOWNLOAD, GET_SHIPMENT_REMARKS, GET_DELIVERY_ORDERS, GET_SHIPMENT_MILESTONES,
    GET_SHIPMENT_BILLING, SHIPMENTS_URL, CUSTOMER_LOOKUP, PORTS_LOOKUP, GET_BILLING_INVOICE_DETAILS, GET_PGADETAILS,
    FETCH_FILE_EXTENSIONS, FETCH_DOC_TYPES, GET_FTZ_CONCUR_PPT, GET_CONVEYANCES, GET_CONVEYANCE_BOL, GET_CONVEYANCE_COMMODITY, INBOND_CONVEYANCE, BILL_OF_LADING,
    BILL_OF_LADING_CONTAINER, BILL_OF_LADING_COMMODITY
} from "./servicesURLS";


const pgaEntities = require('../../common/pgaEntities.json')
let shipmentNoSelected = null;
export const widgetsMetaData = (countBy) => {
    const { ipAddress, gatewayport } = getConfig()
    let url = `${ipAddress}:${gatewayport}${CMUS_WIDGETS_URL}${countBy}`;
    return axios.get(url, { 'headers': getHeadersWithUser() })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, url);
        });
}

export const fetchShipmentDetails = (dataFor, value, pageIndex) => {
    const { ipAddress, gatewayport } = getConfig();

    let quesrString = `?value=${value}`
    if (pageIndex) {
        quesrString = quesrString + (quesrString.length > 0 ? '&' : "") + "pageIndex=" + pageIndex
    }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}/${dataFor}${SHIPMENTS_URL}/${quesrString}`;
    return axios.get(URL, { 'headers': getAuthorizationAndTenantAsHeaders() })
        .then(res => {
            return res
        }).catch(res => {
            logError(res, URL);
        });
}

// 1st level invoice items
export const getShipmentInvoiceItems = async (pageIndex) => {
    const shipmentNumber = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = { "sort": "ciNo", "pageIndex": pageIndex, "maxResults": 15 }

    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNumber}${GET_INVOICE_ITEMS}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
// 2nd level commercial invoice items
export const getShipmentInvoiceItemDetails = async (invoiceItemNo, pageIndex) => {
    const shipmentNumber = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = {
        "additionalFilters": [{ "name": "ciNo", "value": invoiceItemNo }],
        "sort": "ciLineNo", "pageIndex": pageIndex, "maxResults": 15
    }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNumber}${GET_CI_LINES}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })

}
// Shipment Number Hardocoded -- Need to remove once all shipmet detail available
export const getCIInvoiceLines = (invoiceItemNo, ciLineNo) => {
    const shipmentNumber = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = {
        "additionalFilters": [{ "name": "ciNo", "value": invoiceItemNo }, { "name": "ciLineNo", "value": ciLineNo }],
        "includeEntities": ["CiLineFees", "CiPenalty", "CiTariffClass", "PgEs"],
        "sort": "ciLineNo"
    }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNumber}${GET_CI_LINES}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })

}
export const getTariffDetails = (invoiceNo, ciLineNo, tariffLineNo) => {
    const shipmentNumber = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = {
        "additionalFilters": [{ "name": "ciNo", "value": invoiceNo },
        { "name": "ciLineNo", "value": ciLineNo },
        { "name": "tariffLineNo", "value": tariffLineNo }],
        "includeEntities": ["PgEs"], "sort": "tariffLineNo"
    }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNumber}${GET_CI_TARIFF}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        let pgaList = res;
        if (pgaList.data && pgaList.data.data[0] && pgaList.data.data[0].pgEsList && pgaList.data.data[0].pgEsList.length > 0) {
            pgaList.data.data[0].pgEsList = pgaList.data.data[0].pgEsList.map(item => { return ({ ...item, "agencyName": getAgencyName(item) }) })
        }
        return pgaList
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}

function getAgencyName(item) {
    let agencyName = ''
    if (item.pgAgencyCd === 'EPA') {
        if (item.pgCd === 'EP1' || item.pgCd === 'EP2' || item.pgCd === 'EP7' || item.pgCd === 'EP8') {
            agencyName = 'EPA TSCA'
        } else if (item.pgCd === 'EP5' || item.pgCd === 'EP6') {
            agencyName = 'EPA Pesticides'
        } else if (item.pgCd === 'EP3' || item.pgCd === 'EP4') {
            agencyName = 'EPA Vehicles and Engines'
        }
    }
    else if (item.pgAgencyCd === 'APH') {
        if (item.pgProgramCd === 'AAC' || item.pgProgramCd === 'ABS' || item.pgProgramCd === 'APQ' || item.pgProgramCd === 'AVS') {
            agencyName = 'APHIS Core'
        } else if (item.pgProgramCd === 'APL') {
            agencyName = 'APHIS Lacey'
        }
    }
    else if (item.pgAgencyCd === 'NHT') {
        agencyName = 'NHTSA'
    }
    else if (item.pgAgencyCd === 'FSI') {
        agencyName = 'FSIS'
    }
    else if (item.pgAgencyCd === 'DTC') {
        agencyName = 'DDTC'
    }
    else if (item.pgAgencyCd === 'NMF') {
        agencyName = 'NMFS'
    } else {
        agencyName = item.pgAgencyCd
    }
    return agencyName;
}

export const getShipmentHeaders = (shipmentNo) => {
    shipmentNoSelected = shipmentNo
    const { ipAddress, gatewayport } = getConfig()

    const reqBody = {
        "includeEntities": ["ShipmentHeaderAux", "UltimateConsignee", "ShipmentId", "Container", "ContainerDates", "ShipmentIdContainers", "IsfHeader", "AdmissionNoControl", "InBondHeader", "CustRefXRef", "InBondConveyance", "ShipmentWarehouse", "BrokerIt"],
        "sort": "fileNo",
        "excludedEntryTypes": sessionStorage.getItem('excludedEntryTypes')
    }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${GET_SHIPMENT_HEADERS}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })

}


export const evictShipmentsCache = (widgetFor) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CATCH_EVITCITON_SHIPMENTS}${widgetFor}`
    axios.get(URL, { headers: getHeadersWithUser() })
}
export const CMUSWidgetEvictCache = () => {
    evictShipmentsCache("all")
    evictShipmentsCache("all")
}

export const getDocumentsList = async (shipmentFileNo) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${GET_SHIPMENT_DOCUMENTS}${shipmentFileNo}`;
    return axios.get(URL, { 'headers': getHeaders() })
        .then(res => {
            return res
        }).catch(error => {
            logError(error, URL);
            return error.response
        })
}

export const downloadCMUSDocument = (row) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${DOCUMENT_DOWNLOAD}${row.documentId}`;
    const headers = getHeaders()
    return axios.get(URL, { headers: headers }).then(res => {
        if (res.status === 200) {
            var pdf = 'data:application/octet-stream;base64,' + res.data.document.documentData
            const link = document.createElement('a');
            link.href = pdf
            link.download = (row.fileName ? row.fileName : "Download") + "." + res.data.document.documentType;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            return res;
        }
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
export const getShipmentRemarks = async (shipmentNo, pageIndex) => {
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = { "additionalFilters": [{ "name": "confidential", "value": "Y", "operation": "<>" }], "sort": "-dateUpdated", "pageIndex": pageIndex, "maxResults": 15 }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${GET_SHIPMENT_REMARKS}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
export const getShipmentDeliveryOrders = async (shipmentNo, pageIndex) => {
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = { "sort": "suffix", "pageIndex": pageIndex, "maxResults": 15 }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${GET_DELIVERY_ORDERS}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
export const getShipmentDeliveryOrderDetails = async (shipmentNo, deliveryNo) => {
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = {
        "includeEntities": ["DoDates", "DoShipIdXRef", "DoContainers", "DoMarks", "DoDeliverTo"],
        "additionalFilters": [{ "name": "doNo", "value": deliveryNo }], "sort": "suffix"
    }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${GET_DELIVERY_ORDERS}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
export const getMilestones = async (shipmentNo, pageIndex) => {
    const { ipAddress, gatewayport } = getConfig()
    const reqBody =
    {
        "referenceExclusionList": [
            {
                "field": "tracingDateNo",
                "referenceEntityName": "TracingDatesMaster",
                "referenceField": "tracingDateNo",
                "exclusionField": "webEnable",
                "exclusionValue": "Y"
            }
        ],
        "sort": "-dateUpdated",
        "pageIndex": pageIndex,
        "maxResults": 15
    }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${GET_SHIPMENT_MILESTONES}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
export const getBillingInvoiceDetails = async (suffix) => {
    const shipmentNo = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = { "additionalFilters": [{ "name": "printY", "value": "Y", "operation": "=" }, { "name": "suffix", "value": suffix }] }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${GET_BILLING_INVOICE_DETAILS}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
export const getBillings = async (pageIndex) => {
    const shipmentNo = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = { "pageIndex": pageIndex, "maxResults": 15 }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${GET_SHIPMENT_BILLING}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
export const searchShipmentDetails = async (data, pageIndex) => {
    const { ipAddress, gatewayport } = getConfig()
    let shipmentSearchFilterList = [    
        {
            "entityName": "ShipmentDates",
            "fieldName": "dateTracingShipment",
            "fromValue": sessionStorage.getItem('shipmentAgeLimitNoOfDays'),
            "toValue": "0",
            "tracingDateNo": 4
          },
        data.fileNo.trim().length > 0 ? {
            "entityName": "ShipmentHeader",
            "fieldName": "fileNo",
            "fromValue": data.fileNo.trim(),
            "toValue": data.fileNo.trim()
        } : null,
        data.entryNo.trim().length > 0 ? {
            "entityName": "ShipmentHeader",
            "fieldName": "entryNo",
            "fromValue": data.entryNo.trim(),
            "toValue": data.entryNo.trim()
        } : null,
        data.hblNo.trim().length > 0 ? {
            "entityName": "ShipmentId",
            "fieldName": "houseBill",
            "fromValue": data.hblNo.trim(),
            "toValue": data.hblNo.trim()
        } : null,
        data.mblNo.trim().length > 0 ? {
            "entityName": "ShipmentId",
            "fieldName": "masterBill",
            "fromValue": data.mblNo.trim(),
            "toValue": data.mblNo.trim()
        } : null,
        data.exportDateFrom !== "" || data.exportDateTo !== "" ? {
            "entityName": "ShipmentDates",
            "fieldName": "dateTracingShipment",
            "fromValue": data.exportDateFrom !== "" ? data.exportDateFrom : '0',
            "toValue": data.exportDateTo !== "" ? data.exportDateTo : '0',
            "tracingDateNo": 1
        } : null,
        data.arrivalDateFrom !== "" || data.arrivalDateTo !== "" ? {
            "entityName": "ShipmentDates",
            "fieldName": "dateTracingShipment",
            "fromValue": data.arrivalDateFrom !== '' ? data.arrivalDateFrom : '0',
            "toValue": data.arrivalDateTo !== '' ? data.arrivalDateTo : '0',
            "tracingDateNo": 12
        } : null,
        data.releaseDateFrom !== "" || data.releaseDateTo !== "" ? {
            "entityName": "ShipmentDates",
            "fieldName": "dateTracingShipment",
            "fromValue": data.releaseDateFrom !== '' ? data.releaseDateFrom : '0',
            "toValue": data.releaseDateTo !== '' ? data.releaseDateTo : '0',
            "tracingDateNo": 19
        } : null,
        data.custRefNo.trim().length > 0 ? {
            "entityName": "CustRefXRef",
            "fieldName": "custRef",
            "fromValue": data.custRefNo.trim(),
            "toValue": data.custRefNo.trim()
        } : null,
       
        data.custIRSNo.trim().length > 0 ? {
            "entityName": "ShipmentHeader",
            "fieldName": "custNo",
            "fromValue": data.custIRSNo.trim(),
            "toValue": data.custIRSNo.trim(),
            "referenceEntityName": "Customer",
            "referenceFieldName": "custNo",
            "referenceSearchFieldName": "irsNo"
        } : null,
        data.containerNo.trim().length > 0 ? {
            "entityName": "Container",
            "fieldName": "noContainer",
            "fromValue": data.containerNo.trim(),
            "toValue": data.containerNo.trim()
        } : null,
        data.ciNo.trim().length > 0 ? {
            "entityName": "CiHeader",
            "fieldName": "ciNo",
            "fromValue": data.ciNo.trim(),
            "toValue": data.ciNo.trim()
        } : null,
        
        data.pol !== '' ? {
            "entityName": "ShipmentHeader",
            "fieldName": "portLading",
            "fromValue": data.pol,
            "toValue": data.pol
        } : null,
        data.pod !== '' ? {
            "entityName": "ShipmentHeader",
            "fieldName": "distPort",
            "fromValue": data.pod,
            "toValue": data.pod
        } : null,
        data.poe !== '' ? {
            "entityName": "ShipmentHeader",
            "fieldName": "distPortEntry",
            "fromValue": data.poe,
            "toValue": data.poe
        } : null,
        (data.shipper !== '' || data.shipperInput !== '') ? {
            "entityName": "ShipmentHeader",
            "fieldName": "shipper",
            "fromValue": data.shipper !== '' ? data.shipper : data.shipperInput !== '' ? data.shipperInput : '',
            "toValue": data.shipper !== '' ? data.shipper : data.shipperInput !== '' ? data.shipperInput : ''
        } : null,
        (data.consignee !== '' || data.consigneeInput !== '') ? {
            "entityName": "UltimateConsignee",
            "fieldName": "custNo",
            "fromValue": data.consignee !== '' ? data.consignee : data.consigneeInput !== '' ? data.consigneeInput : '',
            "toValue": data.consignee !== '' ? data.consignee : data.consigneeInput !== '' ? data.consigneeInput : ''
        } : null
    ]
    shipmentSearchFilterList = shipmentSearchFilterList.filter(filter => filter !== null)
    const reqBody = {
        "shipmentSearchFilterList": shipmentSearchFilterList,
        "sort": "fileNo",
        "pageIndex": pageIndex,
        "maxResults": 100,
        "excludedEntryTypes": sessionStorage.getItem('excludedEntryTypes')
        
    }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENTS_URL}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return null
    })
}
export const fetchCustomers = async (custNo) => {
    const { ipAddress, gatewayport } = getConfig()
    const quereParam = '&pageIndex=-1';
    const URL = `${ipAddress}:${gatewayport}${CUSTOMER_LOOKUP}${custNo}${quereParam}`;
    return axios.get(`${URL}`, { headers: getHeaders() }).then(res => {
        return res.data
    }).catch(error => {
        logError(error, URL);
        return null
    })
}
export const fetchPorts = async (value, portfor) => {
    const { ipAddress, gatewayport } = getConfig()
    const reqbody =
        portfor === "pol" ? {
            "lookupField": "portLadingName",
            "lookupValue": value,
            "pageIndex": -1,
            "sort": "portLadingName"
        } : {
            "lookupField": "name",
            "lookupValue": value,
            "pageIndex": -1,
            "sort": "name"
        };
    const URL = `${ipAddress}:${gatewayport}${PORTS_LOOKUP}${portfor === "pol" ? "Port" : "DistrictPort"}`;
    return axios.post(`${URL} `, reqbody, { headers: getHeaders() }).then(res => {
        return res.data
    }).catch(error => {
        logError(error, URL);
        return null
    })
}

export const getPGADetails = async (invoiceNo, ciLineNo, tariffLineNo, data) => {
    const shipmentNo = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${GET_PGADETAILS}`;
    const reqBody = {
        "includeRequest": false,
        "additionalFilters": [{ "name": "ciNo", "value": invoiceNo },
        { "name": "ciLineNo", "value": ciLineNo },
        { "name": "tariffLineNo", "value": tariffLineNo },
        { "name": "pgCd", "value": data.pgCd },
        { "name": "pgSeqNbr", "value": data.pgSeqNbr }],
        "includeEntities": pgaEntities[data.pgAgencyCd]
    }
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res.data
    }).catch(error => {
        logError(error, URL);
        return null
    })
}
export const fetchFileFilters = async () => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${FETCH_FILE_EXTENSIONS}`
    return axios.get(URL, { 'headers': getHeaders() }).then(res => {
        return res.data
    }).catch(error => {
        logError(error, URL);
        return null;
    })
}
export const fetchDocTypes = async () => {
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = {
        "pageIndex": -1,
        "sort": "code"
    }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${FETCH_DOC_TYPES}`
    return axios.post(URL, reqBody, { 'headers': getHeaders() }).then(res => {
        return res.data
    }).catch(error => {
        logError(error, URL);
        return null;
    })
}
export const updateShipmentDoc = (reqBody) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${DOCUMENT_DOWNLOAD}`
    return axios.post(URL, reqBody, { 'headers': getHeadersWithUser() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    });
}
export const getConcurPPTArrivals = async (pageIndex) => {
    const shipmentNo = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = { "pageIndex": pageIndex, "maxResults": 15 }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${GET_FTZ_CONCUR_PPT}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
export const getConcurPPTArrivalDetails = async (data) => {
    const shipmentNo = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = {
        "includeEntities": [
            "FtzPttContainers",
            "FtzPttRemarks"
        ],
        "additionalFilters": [{ "name": "actionQualifier", "value": data.actionQualifier },
        { "name": "idNo", "value": data.idNo }, { "name": "actionCd", "value": data.actionCd }, { "name": "seqNo", "value": data.seqNo }]
    }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${GET_FTZ_CONCUR_PPT}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
export const getConveyances = async (pageIndex) => {
    const shipmentNumber = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = { "pageIndex": pageIndex, "maxResults": 15 }

    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNumber}${GET_CONVEYANCES}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
export const getConveyanceDetails = async (convSeqNo) => {
    const shipmentNumber = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = { "includeEntities": ["FtzShipmentId", "FtzContainers"], "additionalFilters": [{ "name": "conveyanceSeqNo", "value": convSeqNo }] }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNumber}${GET_CONVEYANCES}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
export const getConvBOLDetails = async (convSeqNo, idSeqNo) => {
    const shipmentNumber = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = {
        "includeEntities": ["FtzContainers", "FtzLines", "FtzCommodityDescriptions"],
        "additionalFilters": [{ "name": "conveyanceSeqNo", "value": convSeqNo }, { "name": "idSeqNo", "value": idSeqNo }]
    }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNumber}${GET_CONVEYANCE_BOL}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
export const getConvComodityDetails = async (convSeqNo, idSeqNo, lineNo) => {
    const shipmentNumber = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = {
        "includeEntities": ["FtzCommodityDescriptions"],
        "additionalFilters": [{ "name": "conveyanceSeqNo", "value": convSeqNo }, { "name": "idSeqNo", "value": idSeqNo }, { "name": "lineNo", "value": lineNo }]
    }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNumber}${GET_CONVEYANCE_COMMODITY}`;
    return axios.post(`${URL}`, reqBody, { headers: getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    })
}
//In bond conveyance
export const getInBondCoveyanceService = async (itNo) => {
    const shipmentNo = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${INBOND_CONVEYANCE}`;
    const reqBody = {
        "additionalFilters": [{ "name": "itNo", "value": itNo }]
    }
    return axios.post(URL, reqBody, { 'headers': getHeadersWithUser() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    });
}
//Bill of Landing
export const getBillsOfLadingService = async (pageIndex) => {
    const shipmentNo = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const reqBody = { "pageIndex": pageIndex, "maxResults": 15 }
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${BILL_OF_LADING}`;
    return axios.post(URL, reqBody, { 'headers': getHeadersWithUser() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    });
}

export const getBillOfLading = async (itNo, convSeqNo, idSeqNo) => {
    const shipmentNo = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${BILL_OF_LADING}`;
    const reqBody = {
        "includeEntities": ["InBondContainers", "InBondMarks"],
        "additionalFilters": [{ "name": "itNo", "value": itNo }, { "name": "conveyanceSeqNo", "value": convSeqNo },
        { "name": "idSeqNo", "value": idSeqNo }]
    }
    return axios.post(URL, reqBody, { 'headers': getHeadersWithUser() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    });
}

export const getBillOfLadingContainers = async (itNo, convSeqNo, idSeqNo, noContainer) => {
    const shipmentNo = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${BILL_OF_LADING_CONTAINER}`;
    const reqBody = {
        "includeEntities": ["InBondTariff", "InBondMarks", "InBondDesc"],
        "additionalFilters": [{ "name": "itNo", "value": itNo }, { "name": "conveyanceSeqNo", "value": convSeqNo },
        { "name": "idSeqNo", "value": idSeqNo }, { "name": "noContainer", "value": noContainer }]
    }
    return axios.post(URL, reqBody, { 'headers': getHeadersWithUser() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    });
}

export const getBillOfLadingCommodity = async (itNo, convSeqNo, idSeqNo, noContainer, seqNo) => {
    const shipmentNo = shipmentNoSelected
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CMUS_GATEWAY_URL}${SHIPMENT_URL}/${shipmentNo}${BILL_OF_LADING_COMMODITY}`;
    const reqBody = {
        "includeEntities": ["InBondDesc", "PgFdaIb", "PgFdaIbParties", "PgFdaIbCompliance"],
        "additionalFilters": [{ "name": "itNo", "value": itNo }, { "name": "conveyanceSeqNo", "value": convSeqNo },
        { "name": "idSeqNo", "value": idSeqNo }, { "name": "noContainer", "value": noContainer },
        { "name": "seqNo", "value": seqNo }]
    }
    return axios.post(URL, reqBody, { 'headers': getHeadersWithUser() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    });
}

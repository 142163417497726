import React from 'react';
import '../../styles/bar.css'
import { withStyles, FormHelperText, TextField, useTheme, useMediaQuery, Popper, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { voidHandler } from '../../common/utils';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CPToolTip from './CPToolTip';

const style = theme => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      width: '35%',
      fontWeight: 'bold',
      fontSize: '14px'
    }
  },
  typeaheadWrapper: {
    width: '100%',
    display: 'inline-flex',
    padding: '0%',
    alignItems: 'center',
    margin: '0.5rem 2px'
  },
  typeaheadWrapper1: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center'
  },
  divTypeahead: props => ({
    width: props.showAddressess ? '82%' : '90%',
    [theme.breakpoints.up('md')]: {
      width: '56%'
    }
  }),
  required: {
    color: 'red',
    width: '2%'
  },
  option: {
    backgroundColor: "white",
    padding: "0.25rem 1.5rem",
    textIndent: "5px",
    fontWeight: "400",
    fontSize: '14px',
    textShadow: "0 0 #545F66",
    overflow: "hidden",
    textOverflow: "ellipsis",

    cursor: 'pointer',
    display: 'block',
    lineHeight: '2rem',
    minHeight: '3rem',
    paddingLeft: '1.25rem',
    userSelect: 'none',
    "&:hover": {
      backgroundColor: "#E3ECEF"
    },
    "&:checked": {
      backgroundColor: "#FEFAF0"
    },
    "&:selected": {
      backgroundColor: "#FEFAF0"
    },
    '&[aria-selected="true"]': {
      backgroundColor: '#FEFAF0'
    }
  },
  paper: {
    borderRadius: '0px',
    fontFamily: 'Source Sans Pro, sans-serif',
    color: '#545F66',
  },
  input: {
    '&.MuiOutlinedInput-root': {
      paddingRight: '0',
      '&	.MuiOutlinedInput-input': {
        paddingRight: '0',
        display: 'block',
        lineHeight: 'calc(4rem - 4px)',
        minHeight: '3rem',
        padding: '0 1rem',
        position: 'relative',
        verticalAlign: 'middle',
        width: '50%',
        backgroundColor: '#FFFFFF',
        color: '#4A4E50',
      },
      '&  .MuiAutocomplete-endAdornment': {
        backgroundColor: '',
        backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08))',
        padding: '0.5rem',
        margin: '0px',
        height: '2.75rem',
        width: '5rem',
        borderTopRightRadius: theme.shape.borderRadius + 'px',
        borderBottomRightRadius: theme.shape.borderRadius + 'px',
        borderLeft: '2px solid #C8CFD2',
        color: 'white',
        right: '0'
      }
    }
  },
  notchedOutline: {
    border: '2px solid #C8CFD2',
    borderRadius: '4px',
    transitionDuration: '100ms',
    transitionProperty: 'border-color, background-color, color, box-shadow',
    '&:focus': {
      outlineWidth: '0',
      borderColor: theme.palette.primary.main,
      boxShadow: '0 0 0 3px #e3ecef'
    },
    '&focus-visible': {
      outline: '-webkit-focus-ring-color auto 1px'
    }
  },
  disabled: {

  }
})


function CpSearchableFreeTextTypeahead(props) {
  const isPopper = props.widgetPopper
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { classes, isRequired, label, name, options, onChange, error, value, helperText, Ref, isDisabled, noOptionsMsg, multiple, onInputChange, inputValue, searchWith, showTitle, titleprop, maxLength, autoFocus } = props
  let selection = [];
  const PopperComponent = function (props) {
    return <Popper {...props} style={{ width: isPopper ? '10rem' : '28rem', textIndent: "5px" }} placement="bottom-start" />;
  };
  if (options) {
    if (options.length !== 0) {
      selection = options.filter(option => {
        return option["value"] === value
      })
    }
  }
  return (
    <div className={label ? classes.typeaheadWrapper : classes.typeaheadWrapper1} >
      {label ?
        <>
          <div className={classes.sectionDesktop}><label style={{fontWeight:500}}htmlFor={name} >{label} {isRequired ? <span className={classes.required}>*</span> : ""}</label></div>
        </>
        : null}
      <div className={classes.divTypeahead}>
        <form autoComplete="off" onSubmit={e => { e.preventDefault(); }}>
          <Autocomplete
            multiple={multiple ? multiple : false}
            limitTags={2}
            options={options}
            onChange={(event, newValue) => onChange(newValue)}
            onInputChange={(event, value) => onInputChange ? onInputChange(value, event) : voidHandler()}
            inputValue={(value && inputValue) ? (inputValue + " (" + value + ")") : inputValue ? inputValue : value ? value : ''}
            getOptionLabel={(option) => searchWith === "label" ? option.label ? option.label : '' : searchWith === "both" ? option.label + "(" + option.value + ")" : option.value ? option.value : ''}
            value={selection[0]}
            clearOnBlur={false}
            disabled={isDisabled}
            open={autoFocus}
            noOptionsText={noOptionsMsg ? noOptionsMsg : 'No Options'}
            ref={Ref ? Ref : null}
            classes={{ option: classes.option, paper: classes.paper, inputRoot: classes.outbox }}
            PopperComponent={PopperComponent}
            popupIcon={<KeyboardArrowDownIcon />}
            renderInput={(params) =>
              <CPToolTip title={showTitle ? selection[0] ? titleprop === 'both' ? selection[0].label + "(" + selection[0].value + ")" : selection[0].label : '' : ''} >
                <TextField
                  label={isDesktop ? '' : label}
                  error={error}
                  variant="outlined"
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    maxLength: maxLength ? maxLength : null
                  }}
                  InputProps={{
                    ...params.InputProps,
                    classes: { root: classes.input, disabled: classes.disabled, notchedOutline: classes.notchedOutline },
                    endAdornment: (
                      <Button style={{ minWidth: '5rem' }}>
                        {params.InputProps.endAdornment}
                      </Button>
                    )
                  }}
                  InputLabelProps={{ ...params.InputLabelProps, margin: 'dense' }}
                  name={name}
                  id={name}
                  disabled={isDisabled} />
              </CPToolTip>}
          />
        </form>
        {error ? <FormHelperText error={true}>{helperText}</FormHelperText> : ''}
      </div>
      {
        !isDisabled ?
          <IconButton id={'search' + name + 's'} style={{ padding: '8px' }} onClick={() => props.performPartyLookupSearch()} >
            <SearchIcon style={{ fontSize: '20px', color: '#417F90' }} />
          </IconButton>
          : null
      }
    </div >
  )
}

export default withStyles(style)(CpSearchableFreeTextTypeahead)

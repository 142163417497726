import React from 'react';
import { injectIntl } from "react-intl";
import { Grid, withStyles } from '@material-ui/core';
import RouteTableMobileView from './RouteTableMobileView';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    root: {
        minWidth: 275,
        width: '98%',
        margin: '1%'
    },
    cardRoot: {
        padding: '0 0 0 0 !important',
        marginBottom: '10px !important'
    },
    line: {
        margin: 0,
        display: 'flex'
    },
    titleNormal: {
        fontWeight: 'Normal',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important'
    },
    title: {
        fontWeight: 'Bold',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important'
    },
    value: {
        width: '60%',
        display: 'inline-block',
        fontSize: '12px !important'
    },
    bodyContainer: {
        padding: '0.5rem',
        borderBottom: '1px solid #DDE3E3'
    },
    odd: {
        backgroundColor: '#FFFFFF'
    },
    even: {
        backgroundColor: '#F9FBFB'
    },
    tableHeader: {
        fontWeight: 'bold !important',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        borderBottom: '4px solid #CDE3E9',
        height: '54px',
        padding: '16px 6px 6px 10px',
    },
    noRecordsFound: {
        minHeight: '10vh',
        fontStyle: 'italic',
        padding: '6px 24px 6px 16px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        fontFamily: 'Open Sans Italic'
    },
    arrowGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icons: {
        fontSize: '24px'
    },
    arrowIcon: {
        color: '#979797',
        fontSize: '40px'
    },
    container: {
        margin: '1rem 0'
    },
    heading: {
        fontSize: '18px',
        fontWeight: '600'
    },
    label: {
        fontStyle: 'italic',
        textAlign: 'end',
        marginRight: '3rem'
    },
    bold: {
        fontWeight: '600'
    },
    countryGrid: {
        alignItems: 'center',
        display: 'flex'
    }
})

function RouteDetails(props) {

    const { data, intl, classes, isPrint, printClasses } = props;
    const getOddOrEven = (num) => {
        if (num % 2 === 0)
            return 'even'
        return 'odd'
    }
    // const getIconForBizType = (bizType) => {
    //     if (bizType === "AIR") {
    //         return (<FlightIcon className={classes.icons} />);
    //     } else if (bizType === "SEA") {
    //         return (<SeaIcon />)
    //     } else if (bizType === "ROAD") {
    //         return (<LocalShippingIcon className={classes.icons} />);
    //     }
    // }
    return (
        <>
            <div className={isPrint ? printClasses.sectionDesktopForPrint : classes.sectionDesktop}>
                <Grid container spacing={0} flex='true' direction="column" >
                    <Grid container className={classes.container} spacing={0} flex='true' direction="row" >
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                            <div className={classes.tableHeader}>{intl.formatMessage({ id: 'depature' })}</div>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className={classes.arrowGrid}>
                            <ArrowRightAltIcon className={classes.arrowIcon} />
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                            <div className={classes.tableHeader}>{intl.formatMessage({ id: 'arrival' })}</div>
                        </Grid>
                    </Grid>
                    {data && data.length > 0 ?
                        data.map((item, index) => {
                            return (
                                <Grid key={index} className={`${classes.bodyContainer} ${classes[getOddOrEven(index + 1)]}`} container spacing={0} flex='true' direction="row" >
                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className={classes.countryGrid}>
                                        <div className={classes.heading}>
                                            {/* {getIconForBizType(item.tptMode)} */}
                                            <span style={{ marginLeft: '1rem' }}>{item.location}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                        <Grid container spacing={1} flex='true' direction="column" >
                                            <Grid className={classes.label} item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                Estimated
                                            </Grid>
                                            <Grid className={classes.label} item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                Actual
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                        <Grid container spacing={1} flex='true' direction="column" >
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                {item.etd}
                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.bold}>
                                                {item.atd}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                    </Grid>
                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                        <Grid container spacing={1} flex='true' direction="column" >
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                                {item.eta}
                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.bold}>
                                                {item.ata}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                        :
                        <div className={classes.noRecordsFound}>
                            <span>{intl.formatMessage({ id: 'noRecordsFound' })}</span>
                        </div>


                    }

                </Grid >
            </div >
            <div className={isPrint ? printClasses.sectionMobileForPrint : classes.sectionMobile}>
                <RouteTableMobileView data={data} />
            </div>
        </>
    )
}

export default injectIntl(withStyles(style)(RouteDetails))

import messages_ja_JP from '../translations/messages_ja_JP'
import messages_en from '../translations/messages_en'
export const messages = {
    'en-US': messages_en,
    'en': messages_en,
    'en-GB': messages_en,
    'en-AU': messages_en,
    'en-CA': messages_en,
    'en-IN': messages_en,
    'en-NZ': messages_en,
    'en-ZA': messages_en,
    'ja-JP': messages_ja_JP,
    'ja': messages_ja_JP
}
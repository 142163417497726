const MONITOR_SERCICE_URL = '/api-gateway/1/log';
const GET_THEME_DETAILS_URL = '/secure-ui/themes/';
const GET_THEMES_GATEWAY_DETAILS_URL = '/api-gateway/themes/';
const LOGIN_URL = '/api-gateway/secure/1/login';
const NEW_LOGIN_URL = '/api-gateway/1/new-login';
const CHANGE_PASSWORD = '/api-gateway/1/users/change-password'
const FORGOT_PASSWORD = '/api-gateway/1/users/forgot-password'
const RESET_PASSWORD = '/api-gateway/1/users/reset-password'
const LOGOUT_URL = '/api-gateway/1/logout';
const METRIC_URL='/api-gateway/1/metrics/'

export {
        MONITOR_SERCICE_URL,
        GET_THEME_DETAILS_URL,
        LOGIN_URL,
        NEW_LOGIN_URL,
        GET_THEMES_GATEWAY_DETAILS_URL,
        CHANGE_PASSWORD,
        FORGOT_PASSWORD,
        RESET_PASSWORD,
        LOGOUT_URL,
        METRIC_URL
}
import React, { Component } from 'react';
import CPButton from '../../common/Button'
import { Grid, withStyles, IconButton } from '@material-ui/core';
import CpInput from '../../common/CpInput';
import CpInput1 from '../../common/CpInput1';
import CpInput2 from '../../common/CpInput2';
import CpTypeahead from '../../common/CpTypeahead';
import { getCities } from '../../../sevices/TMFF/Services';
import withCPContext from '../../common/withCPContext';
import { FormattedMessage, injectIntl } from "react-intl";
import RefreshComponent from '../../refreshfunctionality/RefreshComponent';
import HelpIcon from '@material-ui/icons/Help';


const style = ({ breakpoints, palette }) => ({
    heading: {
        fontSize: '24px',
        fontWeight: 700
    },
    header: {
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#E8F0F4'
    },
    headingBox: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '2rem'
    },
    sectionButtonsDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'initial'
        }
    },
    sectionButtonsMobile: {
        display: 'flow-root',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    hr: {
        border: `2px solid`,
        color: palette.primary.main,
        background: palette.primary.main
    }
})


class SearchShipments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            polCities: [],
            podCities: [],
            data: {
                shipper: "",
                consignee: "",
                jobNo: "",
                hblNo: "",
                mblNo: "",
                ctnNo: "",
                etaFrom: "7",
                etaTo: "7",
                etdFrom: "7",
                etdTo: "7",
                createDateNoOfDaysAgo: "",
                pol: "",
                polCity: "",
                pod: "",
                podCity: ""
            },
            disableGo: false,
        }
    }

    handleCountryChange = async (event, field) => {
        const citiesKey = field + "Cities"
        if (event !== null) {
            await this.setState({
                [citiesKey]: [],
                data: {
                    ...this.state.data,
                    [field]: event.value
                }
            })
            const cities = await getCities(event.value)
            await this.setState({
                [citiesKey]: cities
            })
        }
        else {
            await this.setState({
                [citiesKey]: [],
                data: {
                    ...this.state.data,
                    [field]: ''
                }
            })
        }
        this.validateGobutton()
    }

    handleTypeaheadChange = async (event, field) => {
        if (event !== null) {
            await this.setState({
                data: {
                    ...this.state.data,
                    [field]: event.value
                }
            })
        }
        else {
            await this.setState({
                data: {
                    ...this.state.data,
                    [field]: ''
                }
            })
        }
        this.validateGobutton()
    }

    onChange = async (event) => {
        await this.setState({
            data: {
                ...this.state.data,
                [event.target.name]: event.target.value
            }
        })
        this.validateGobutton()
    }

    validateGobutton = () => {
        const { data } = this.state
        let valueArray = Object.values(data)
        valueArray = valueArray.filter(el => el.trim().length > 0)
        this.setState({
            disableGo: valueArray.length <= 0 ? true : false
        })
    }

    render() {
        const { handleDrawer, classes, cpContext, intl } = this.props
        const { data, podCities, polCities, disableGo } = this.state
        return (
            <div>
                <div className={classes.header}>
                    <div className={classes.headingBox}>
                        <span className={classes.heading} ><FormattedMessage id="search_shipments_search_header" /></span>
                    </div>
                    <div className={classes.sectionButtonsDesktop}>
                        {/* help section */}
                        <a href={`${window.location.protocol + '//' + window.location.host}/webHelp/cp-search-shipments.html`} target="_blank" style={{ color: "inherit" }} rel="noopener noreferrer">
                            <IconButton 
                                aria-label="account of current user"
                                aria-controls="primary-search-account-menu"
                                aria-haspopup="true"
                                color="inherit">
                                <HelpIcon style={{ fontSize: '28px', color: "#6E787D" }} />
                            </IconButton>
                        </a>
                        {/* end of help section */}
                        <CPButton variant='containerLess' onClick={() => handleDrawer()}>{intl.formatMessage({ id: 'search_shipments_search_cancel' })}</CPButton>
                        <CPButton variant='primary' disabled={disableGo} onClick={() => this.props.fetchShipments('search', data)}>{intl.formatMessage({ id: 'search_shipments_search' })}</CPButton>
                        <div className={classes.sectionButtonsMobile} >
                            <a href={`${window.location.protocol + '//' + window.location.host}/webHelp/cp-search-shipments.html`} target="_blank" style={{ color: "inherit" }} rel="noopener noreferrer">
                                <IconButton 
                                    aria-label="account of current user"
                                    aria-controls="primary-search-account-menu"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <HelpIcon style={{ fontSize: '28px', color: "#6E787D" }} />
                                </IconButton>
                            </a>
                        </div>
                    </div>
                </div>
                <Grid flex="true" container spacing={0} style={{ width: '99%' }}>
                    <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '1.25rem 0 1.25rem 1.25rem'}}>
                        <div className={classes.sectionButtonsMobile} >
                            <CPButton styles={{ float: 'right' }} variant='primary' disabled={disableGo} onClick={() => this.props.fetchShipments('search', data)}>{intl.formatMessage({ id: 'search_shipments_search' })}</CPButton>
                            <CPButton styles={{ float: 'right' }} variant='secondary' onClick={() => handleDrawer()}>{intl.formatMessage({ id: 'search_shipments_search_cancel' })}</CPButton>
                        </div>
                        <RefreshComponent isRequired={false} label={intl.formatMessage({ id: 'search_shipments_search_shipper' })} name='shipper' value={data.shipper} options={cpContext.shippers} onChange={(event) => this.handleTypeaheadChange(event, 'shipper')} isRefresh={true} isForSearchShipments={true} />
                        <RefreshComponent isRequired={false} label={intl.formatMessage({ id: 'search_shipments_search_consignee' })} name='consignee' value={data.consignee} options={cpContext.consignees} onChange={(event) => this.handleTypeaheadChange(event, 'consignee')} isRefresh={true} isForSearchShipments={true} />
                        <CpInput label={intl.formatMessage({ id: 'search_shipments_search__jobNo' })} name="jobNo" isRequired={false} type="text" onChange={this.onChange} />
                        <CpInput label={intl.formatMessage({ id: 'search_shipments_search_hblNo' })} name="hblNo" isRequired={false} type="text" onChange={this.onChange} />
                        <CpInput label={intl.formatMessage({ id: 'search_shipments_search_mbNo' })} name="mblNo" isRequired={false} type="text" onChange={this.onChange} />
                        <CpInput label={intl.formatMessage({ id: 'search_shipments_search_containerNo' })} name="ctnNo" isRequired={false} type="text" onChange={this.onChange} />
                        <CpInput label={intl.formatMessage({ id: 'search_shipments_search_refNo' })} name="refNo" isRequired={false} type="text" onChange={this.onChange} />
                        <CpInput1
                            label={intl.formatMessage({ id: 'search_shipments_search_ETD' })}
                            onChange={this.onChange}
                            name1="etdFrom" name2="etdTo"
                            isRequired={false}
                            type1="number" type2="number"
                            value1={data.etdFrom} value2={data.etdTo}
                            texttoRightSideInput1={intl.formatMessage({ id: 'search_shipments_search_daysAgo_to' })} texttoRightSideInput2={intl.formatMessage({ id: 'search_shipments_search_daysAhead' })} />
                        <CpInput1
                            onChange={this.onChange}
                            label={intl.formatMessage({ id: 'search_shipments_search_ETA' })}
                            name1="etaFrom" name2="etaTo"
                            isRequired={false}
                            type1="number" type2="number"
                            value1={data.etaFrom} value2={data.etaTo}
                            texttoRightSideInput1={intl.formatMessage({ id: 'search_shipments_search_daysAgo_to' })} texttoRightSideInput2={intl.formatMessage({ id: 'search_shipments_search_daysAhead' })} />
                        <CpInput2 label={intl.formatMessage({ id: 'search_shipments_search_createdDays' })} name="createDateNoOfDaysAgo" isRequired={false} type="number" onChange={this.onChange}
                            texttoRightSideInput={intl.formatMessage({ id: 'search_shipments_search_daysAgo' })} />
                        <CpTypeahead isRequired={false} name='pol' label={intl.formatMessage({ id: 'search_shipments_search_loadingCountry' })} options={cpContext.countries} value={data.pol} onChange={(event) => this.handleCountryChange(event, "pol")} />
                        <CpTypeahead isRequired={false} name='polCity' label={intl.formatMessage({ id: 'search_shipments_search_portOfLoading' })} options={polCities} value={data.polCity} onChange={(event) => this.handleTypeaheadChange(event, "polCity")} />
                        <CpTypeahead isRequired={false} label={intl.formatMessage({ id: 'search_shipments_search_dischargeCountry' })} name='pod' options={cpContext.countries} value={data.pod} onChange={(event) => this.handleCountryChange(event, "pod")} />
                        <CpTypeahead isRequired={false} label={intl.formatMessage({ id: 'search_shipments_search_portOfDischarge' })} name='podCity' options={podCities} value={data.podCity} onChange={(event) => this.handleTypeaheadChange(event, "podCity")} />
                    </Grid>
                </Grid>
            </div>
        )
    }
}


export default injectIntl(withStyles(style)(withCPContext(SearchShipments)))
import React, { useState } from 'react';
import CPButton from '../common/Button'
import CpInput from '../common/CpInput'
import { useForm } from "react-hook-form";
import { useIntl } from 'react-intl';
import { voidHandler } from '../../common/utils';
import { forgotPassword } from '../../sevices/common/CpServices';
import Captcha from './Captcha';

function ForgotPassword(props) {
  const intl = useIntl();
  const handleForgotPassword = (data) => {
    if (captchRef.current) {
      if (captchRef.current.validateCaptcha()) {
        invokeForgotPassword(data);
      } else {
        setError('');
      }
    }
  }
  const invokeForgotPassword = async (data) => {
    if (location && location.state && location.state.isTokenExpiryMsg !== '') {
      location.state.isTokenExpiryMsg = ''
    }
    const reqBody = {
      "email": data.email,
      "passwordResetUrl": `${window.location.protocol + '//' + window.location.host + window.location.pathname + '#'}/${sessionStorage.getItem('tenant-url')}/reset`
    }
    const res = await forgotPassword(reqBody);
    if (res !== null && res !== undefined) {
      if (res.status === 200) {
        isForgotSuccess();
      }
      else {
        if (res.data && res.data.fieldErrors && res.data.fieldErrors.length > 0) {
          setError(res.data.fieldErrors[0])
        }
        else if (res.data.code === '11003') {
          setError(intl.formatMessage({ id: 'routingErrorMsg' }));
        }
        else {
          const message = (res.data && res.data.code === '22001') ? res.data.message : intl.formatMessage({ id: 'somethingWentWrong' });
          setError(message)
        }
        captchRef.current.prepareCaptchaString();
      }
    }
  }
  const { register, handleSubmit, errors } = useForm();
  const { classes, handleBack, isForgotSuccess, location } = props;
  const [error, setError] = useState('');
  const captchRef = React.useRef(null);
  return (
    <div>
      <form key={1} onSubmit={handleSubmit(handleForgotPassword)}>
        <div className={classes.inputs1}>
          <CpInput
            register={register({
              required: intl.formatMessage({ id: 'reset_ErrorMessage_EmailAddress_Required' }),
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: intl.formatMessage({ id: 'reset_ErrorMessage_ValidEmail' }),
              },
            })}
            placeholder={intl.formatMessage({ id: 'Reset_Submit_Email' })}
            name="email"
            error={errors.email}
            autoFocus={true}
            helperText={errors.email ? errors.email.message : ""}
            onChange={() => { error !== '' ? setError('') : voidHandler() }}
          />
          {error !== '' || error !== undefined ? <span style={{ display: 'flex', color: 'red ' }} >{error}</span> : null}
          {location && location.state && location.state.isTokenExpiryMsg ? <span style={{ color: 'red ' }} >{location.state.isTokenExpiryMsg}</span> : null}
        </div>
        <Captcha ref={captchRef} intl={intl} />
        <div className={classes.buttonGroup2}>
          <CPButton id="back" variant="secondary" onClick={() => handleBack()} >{intl.formatMessage({ id: 'Reset_Back' })}</CPButton>
          <CPButton type="submit" id="submit" styles={{ marginRight: '0' }} variant="primary">{intl.formatMessage({ id: 'Reset_submit' })}</CPButton>
        </div>
      </form>
    </div>

  );
}
export default ForgotPassword;
import { withStyles } from '@material-ui/core';
import React from 'react';
import { injectIntl } from 'react-intl';
import Box from '../../common/Box';
import CpEditableTable from '../../common/CpEditableTable';
import PageNumbers from '../../common/Pagination/PageNumbers';

const styles = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    recordCount: {
        marginLeft: '10px',
        fontSize: '14px',
        fontFamily: 'sans-serif',
        fontWeight: 400,
        fontStyle: 'normal',
        color: '#545F66'
    }
})

function Billings(props) {

    const { classes, handleDisplayForward, billingData, noOfPages, selectedPage, handlePageClick, intl } = props

    const billingHeaders = [
        { 'width': '30%', 'title': intl.formatMessage({ id: 'invoiceDate' }), 'field': 'dateInvoice', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'invoiceNo' }), 'field': 'suffix', 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': intl.formatMessage({ id: 'invoiceTotalAmount' }), 'field': 'totalAmount', 'showLabel': true, 'type': 'label' }
    ]

    return (
        <Box showloading={true} header={intl.formatMessage({ id: 'billing' })}>
            <div style={{ height: '30px', display: 'flex', alignItems: "center" }}> <span className={classes.recordCount}>{intl.formatMessage({ id: 'shipments_noOfRecords' })} : {billingData ? billingData.length : "0"}</span></div>
            <div>
                <CpEditableTable headers={billingHeaders} readOnly={true} data={billingData} navigateIcon={true} handleDisplayForward={(row, index) => handleDisplayForward(1, row, index)} />
            </div>
            <PageNumbers noOfPages={noOfPages} selected={selectedPage} onClick={(index) => handlePageClick(index, 0)} />
        </Box>
    );
}

export default injectIntl(withStyles(styles)(Billings))

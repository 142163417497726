import React, { Component } from 'react'
import { evictShipmentsCache, widgetsMetaData } from '../../../sevices/CMUS/Services'
import DonutWidget from '../../common/DonutWidget'


export class ShipmentsByMOT extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shipmentsbyMotData: [],
            loading: true
        }
    }
    componentDidMount = () => {
        const { location } = this.props;
        const Widgets = location.state ? location.state.widgets : true

        if (Widgets) {
            this.fetchWidgetData()
        }

    }
    fetchWidgetData = async () => {
        const shipments = await widgetsMetaData("mot")
        await this.setState({
            shipmentsbyMotData: shipments && shipments.pieChartWidgetDetails ? shipments.pieChartWidgetDetails : [],
            loading: false,
        })
    }
    handleWidgetClick = async (event) => {
        const { history, CpError } = this.props
        let header = {}
        let data = {}
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
        header = { "MOT": event.data.name }
        data = { dataFor: "mot", value: event.data.code }
        history.push({
            pathname: '/shipments',
            state: {
                heading: header,
                data: data,
                fromDashBoard: true
            }
        })
    }
    handleRefresh = async () => {
        await evictShipmentsCache("mot");
        await evictShipmentsCache("mot");
        await this.setState({
            loading: true
        })
        this.fetchWidgetData()
    }
    render() {
        const { intl } = this.props;
        const { shipmentsbyMotData, loading } = this.state
        return (
            <DonutWidget
                heading={intl.formatMessage({ id: 'shipmentsbyMOT' })}
                data={shipmentsbyMotData}
                name={intl.formatMessage({ id: 'shipmentsbyMOT' })}
                loading={loading}
                handleClick={this.handleWidgetClick}
                handleRefresh={this.handleRefresh} />
        )
    }
}

export default ShipmentsByMOT

import React from 'react'
import { Dialog, withStyles, DialogContent, DialogActions } from '@material-ui/core'
import { injectIntl } from 'react-intl';
import CpTextarea from './CpTextarea';
import CPButton from './Button';

const style = {
    content: {
        flex: '1 1 auto',
        overflow: 'auto',
        padding: '2rem',
        fontSize: '14px',
        color: '#4A4E50'
    },
    header: {
        backgroundColor: '#E8F0F4',
        fontWeight: '500',
        fontSize: '14px',
        display: 'flex',
        padding: '1.5rem 2rem'
    }
}
function CPConfirmBox(props) {
    const { show, handleRejected, handleAccepted, message, classes, intl, inputName, inputId, handleChange, inputValue, rowsMin, rowsMax, placeHolder, type } = props
    return (
        <Dialog open={show} width='md' onClose={handleRejected} style={{ width: "100%" }}>
            <div className={classes.header} id="alert-dialog-title">
                {type === 'alert' ? intl.formatMessage({ id: 'alert' }) : intl.formatMessage({ id: "information" })}
            </div>
            <DialogContent classes={{ root: classes.content }}>
                <div >
                    {type === 'alert' ?
                        <span > {message}</span>
                        : inputName ?
                            <CpTextarea id={inputId ? inputId : inputName} placeHolder={placeHolder} isDisabled={false} name={inputName} onChange={(event) => handleChange(event)} value={inputValue} rowsMin={rowsMin} rowsMax={rowsMax} />
                            :
                            <span > {message}</span>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                {type === 'alert' ?
                    <CPButton variant='primary' id="confirmOk" onClick={handleAccepted} >{intl.formatMessage({ id: "ok" })}</CPButton>
                    :
                    <>
                        <CPButton variant='secondary' id="condirmCancel" onClick={handleRejected}  >{intl.formatMessage({ id: "no" })}</CPButton>
                        <CPButton variant='primary' id="confirmOk" onClick={handleAccepted} >{intl.formatMessage({ id: "yes" })}</CPButton>
                    </>
                }

            </DialogActions>
        </Dialog >
    )
}

export default injectIntl(withStyles(style)(CPConfirmBox))

import React, { Component } from 'react';
import { Link, Typography, withStyles } from '@material-ui/core';
import { injectIntl } from "react-intl";
import withTenant from '../../common/withTenant';
import CpEditableTable from '../../common/CpEditableTable';
import CpTypeahead from '../../common/CpTypeahead';
import withCPContext from '../../common/withCPContext';
import withError from '../../common/withError';
import { withRouter } from 'react-router-dom';
import CPButton from '../../common/Button'
import { savePreferences, getPreferences } from '../../../sevices/TMFF/Services';
import { getSnackBar } from '../../../common/utils'
import { sourceOptions, dataRangeOptions, widgetLayoutHeaders, filterOptionsDefault, groupByOptionsDefault, chartOptionsDefault } from './WidgetConstants'
import CpBreadcrumb from '../../common/CpBreadcrumb';
import Box from '../../common/Box';

const style = theme => ({
    containerDiv: {
        background: 'white',
        padding: '0 2%',
        overflow: 'auto',
        maxHeight: '80vh'
    },
    heading: {
        background: 'white',
        height: '4rem',
        padding: '0.75%',
        display: 'flex'
    },
    widgetDetailsTable: {
        width: '100%',
        padding: '1% 0'
    },
    widgetDetailsTableHeader: {
        width: '30%'
    },
    dataSource: {
        display: 'inline-flex',
        width: '40%',
        margin: '1rem'
    },
    errorDiv: {
        color: 'red',
        width: '90%',
        fontSize: '14px',
        textAlign: 'center',
        fontWeight: 'bold',
        position: 'absolute'
    },
    button: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    widgetInfoWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    widgetInfo: {
        width: '84%'
    },
    widgetInfoLabel: {
        width: '16%',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'bold'
    }
})

const loginWidgetHeaders = [
    { 'width': '20%', 'title': 'Filter Column', 'field': 'filterBy', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'titleprop': 'label', 'showBorder': true, 'options': filterOptionsDefault, 'renderOption': 'label' },
    { 'width': '30%', 'title': 'Filter Value', 'field': 'filterByVal', 'showLabel': true, 'type': 'input', 'inputType': 'text' },
    { 'width': '20%', 'title': 'Group By', 'field': 'groupBy', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'titleprop': 'label', 'showBorder': true, 'options': groupByOptionsDefault, 'renderOption': 'label' },
    { 'width': '20%', 'title': 'Chart Type', 'field': 'widgetType', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'titleprop': 'label', 'showBorder': true, 'options': chartOptionsDefault, 'renderOption': 'label' },
    { 'width': '20%', 'title': 'Duration', 'field': 'dateFilter', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'titleprop': 'label', 'showBorder': true, 'options': dataRangeOptions, 'renderOption': 'label' },
    { 'width': '20%', 'title': 'Widget Name', 'field': 'widgetName', 'showLabel': true, 'type': 'input', 'inputType': 'text' }
]

const bookingWidgetHeaders = [
    { 'width': '20%', 'title': 'Filter Column', 'field': 'filterBy', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'renderOption': 'label', 'showBorder': true, 'options': filterOptionsDefault, 'titleprop': 'label' },
    { 'width': '30%', 'title': 'Filter By', 'field': 'filterByVal', 'showLabel': true, 'type': 'input', 'inputType': 'text' },
    { 'width': '20%', 'title': 'Group By', 'field': 'groupBy', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'renderOption': 'label', 'showBorder': true, 'options': groupByOptionsDefault, 'titleprop': 'label' },
    { 'width': '20%', 'title': 'Chart Type', 'field': 'widgetType', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'renderOption': 'label', 'showBorder': true, 'options': chartOptionsDefault, 'titleprop': 'label' },
    { 'width': '20%', 'title': 'Duration', 'field': 'dateFilter', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'renderOption': 'label', 'titleprop': 'label', 'showBorder': true, 'options': dataRangeOptions },
    { 'width': '20%', 'title': 'Widget Name', 'field': 'widgetName', 'showLabel': true, 'type': 'input', 'inputType': 'text' },
]

const quoteWidgetHeaders = [
    { 'width': '20%', 'title': 'Filter Column', 'field': 'filterBy', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'renderOption': 'label', 'titleprop': 'label', 'showBorder': true, 'options': filterOptionsDefault },
    { 'width': '30%', 'title': 'Filter', 'field': 'filterByVal', 'showLabel': true, 'type': 'input', 'inputType': 'text' },
    { 'width': '20%', 'title': 'Group By', 'field': 'groupBy', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'renderOption': 'label', 'titleprop': 'label', 'showBorder': true, 'options': groupByOptionsDefault },
    { 'width': '20%', 'title': 'Chart Type', 'field': 'widgetType', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'renderOption': 'label', 'titleprop': 'label', 'showBorder': true, 'options': chartOptionsDefault },
    { 'width': '20%', 'title': 'Duration', 'field': 'dateFilter', 'showLabel': true, 'type': 'typeahead', 'afterSelectionShow': 'label', 'renderOption': 'label', 'titleprop': 'label', 'showBorder': true, 'options': dataRangeOptions },
    { 'width': '20%', 'title': 'Widget Name', 'field': 'widgetName', 'showLabel': true, 'type': 'input', 'inputType': 'text' }
]


class ConfigureWidgets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            widgetDetails: [],
            layoutDetails: [{ 'rows': '0', 'colomns': '0' }],
            showSnackBar: false,
            snackBarMsg: '',
            snackBarType: 'info',
            widgetError: false,
            updatedLoginHeaders: loginWidgetHeaders,
            updatedBookingHeaders: bookingWidgetHeaders,
            updatedQuoteHEaders: quoteWidgetHeaders
        }
    }

    componentDidMount = async () => {
        const savedPreferences = await getPreferences();
        await this.setState({
            layoutDetails: [{
                'rows': (savedPreferences && savedPreferences.data) ? JSON.stringify(savedPreferences.data.noOfRows) : 0,
                'colomns': (savedPreferences && savedPreferences.data) ? JSON.stringify(savedPreferences.data.noOfColumns) : 0
            }],
            widgetDetails: (savedPreferences && savedPreferences.data) ? savedPreferences.data.widgetDetails : []
        })
    }

    generateHeaders(module, value) {
        const { updatedLoginHeaders, updatedBookingHeaders, updatedQuoteHEaders } = this.state
        if (module === 'login') {
            let updatedHeaders = updatedLoginHeaders.map(header => {
                if (header.field === 'groupBy') {
                    return { ...header, 'options': groupByOptionsDefault.filter(option => option.value !== value) }
                }
                if (header.field === 'filterBy') {
                    return { ...header, 'options': filterOptionsDefault }
                }
                else {
                    return header
                }
            })
            return updatedHeaders
        }
        else if (module === 'quote') {
            let updatedHeaders = updatedQuoteHEaders.map(header => {
                if (header.field === 'groupBy') {
                    return { ...header, 'options': groupByOptionsDefault.filter(option => option.value !== value) }
                }
                if (header.field === 'filterBy') {
                    return { ...header, 'options': filterOptionsDefault }
                }
                else {
                    return header
                }
            })
            return updatedHeaders
        }
        else if (module === 'booking') {
            let updatedHeaders = updatedBookingHeaders.map(header => {
                if (header.field === 'groupBy') {
                    return { ...header, 'options': groupByOptionsDefault.filter(option => option.value !== value) }
                }
                if (header.field === 'filterBy') {
                    return { ...header, 'options': filterOptionsDefault }
                }
                else {
                    return header
                }
            })
            return updatedHeaders
        }
    }

    validateConfigurations = async () => {
        const { widgetDetails } = this.state;
        let error = false
        widgetDetails.forEach(widget => {
            const { dateFilter, widgetType, groupBy, widgetName } = widget.layouts[0]
            if (!error) {
                if ((dateFilter === undefined || dateFilter === '') || (widgetType === undefined || widgetType === '') ||
                    (groupBy === undefined || groupBy === '') || (widgetName === undefined || widgetName === '')) {
                    error = true;
                    this.setState({ widgetError: error });
                    return error;
                }
            }
        })
        this.setState({ widgetError: error });
        return error;
    }

    handleWidgetDataCreation = async (rows, cols) => {
        let details = [];
        for (var i = 0; i < rows; i++) {
            for (var j = 0; j < cols; j++) {
                await details.push(
                    {
                        dataSource: '',
                        layouts: [{
                            filterBy: '',
                            filterByVal: '',
                            groupBy: '',
                            widgetId: `${i + 1}${j + 1}`,
                            id: `${i + 1}${j + 1}`,
                            widgetType: '',
                            widgetName: '',
                            dateFilter: '',
                            userId: sessionStorage.getItem('userName'),
                        }]
                    });
            }
        }
        await this.setState({
            widgetDetails: details,
            updatedLoginHeaders: loginWidgetHeaders,
            updatedBookingHeaders: bookingWidgetHeaders,
            updatedQuoteHEaders: quoteWidgetHeaders
        })
    }

    handleChangeFields = async (id, field, value) => {
        const { widgetDetails } = this.state;
        if (field === 'rows' || field === 'colomns') {
            const updatedLayout = this.state.layoutDetails
            updatedLayout[0][field] = value;
            this.setState({
                layoutDetails: updatedLayout
            })
            this.handleWidgetDataCreation(updatedLayout[0].rows, updatedLayout[0].colomns)
        }
        else {
            if (field === 'filterBy') {
                let widgetId = null;
                const updatedWidgetDetails = widgetDetails.map(widget => {
                    if (widget.layouts[0].id === id) {
                        widgetId = id;
                        this.updateHeaders('filterBy', value, widget.dataSource);
                        return {
                            ...widget,
                            layouts: [{
                                ...widget.layouts[0],
                                [field]: value,
                                filterByVal: '',
                                groupBy: value === widget.layouts[0].groupBy ? '' : widget.layouts[0].groupBy
                            }]
                        }
                    }
                    else {
                        return widget;
                    }
                })
                await this.setState({ widgetDetails: updatedWidgetDetails })
                this.generateWidgetName(widgetId);
            }
            else if (field === 'groupBy') {
                let widgetId = null;
                const updatedWidgetDetails = widgetDetails.map(widget => {
                    if (widget.layouts[0].id === id) {
                        widgetId = id;
                        this.updateHeaders('groupBy', value, widget.dataSource);
                        return {
                            ...widget,
                            layouts: [{
                                ...widget.layouts[0],
                                [field]: value === '' ? '' : value,
                                filterByVal: value === '' ? '' : widget.layouts[0].filterByVal,
                                filterBy: value === '' ? '' : widget.layouts[0].filterBy
                            }]
                        }
                    }
                    else {
                        return widget;
                    }
                })
                await this.setState({ widgetDetails: updatedWidgetDetails })
                this.generateWidgetName(widgetId);
            }
            else if (field === 'dateFilter') {
                let widgetId = null;
                const updatedWidgetDetails = widgetDetails.map(widget => {
                    if (widget.layouts[0].id === id) {
                        widgetId = id;
                        return {
                            ...widget,
                            layouts: [{
                                ...widget.layouts[0],
                                [field]: value === '' ? '' : value
                            }]
                        }
                    }
                    else {
                        return widget;
                    }
                })
                await this.setState({ widgetDetails: updatedWidgetDetails })
                this.generateWidgetName(widgetId);
            }
            else if (field === 'filterByVal') {
                let widgetId = null;
                const updatedWidgetDetails = widgetDetails.map(widget => {
                    if (widget.layouts[0].id === id) {
                        widgetId = id;
                        this.updateHeaders('filterByVal', value, widget.dataSource);
                        return {
                            ...widget,
                            layouts: [{
                                ...widget.layouts[0],
                                [field]: value === '' ? '' : value
                            }]
                        }
                    }
                    else {
                        return widget;
                    }
                })
                await this.setState({ widgetDetails: updatedWidgetDetails })
                this.generateWidgetName(widgetId);
            }
            else {
                const updatedWidgetDetails = widgetDetails.map(widget => {
                    if (widget.layouts[0].id === id) {
                        return {
                            ...widget,
                            layouts: [{
                                ...widget.layouts[0],
                                [field]: value === '' ? '' : value
                            }]
                        }
                    }
                    else {
                        return widget;
                    }
                })
                await this.setState({ widgetDetails: updatedWidgetDetails })
            }
        }
    }

    generateWidgetName = (id) => {
        const { widgetDetails } = this.state
        if (id !== null) {
            let updatedWidgetDetails = widgetDetails.map(widget => {
                if (widget.layouts[0].id === id) {
                    const { filterByVal, groupBy, dateFilter } = widget.layouts[0];
                    return {
                        ...widget,
                        layouts: [{
                            ...widget.layouts[0],
                            widgetName: ((dateFilter && dateFilter !== '') ? dataRangeOptions.filter(date => date.value === dateFilter)[0].label : '') +
                                " " + ((widget.dataSource && widget.dataSource !== '') ? widget.dataSource : '') + " " + ((filterByVal && filterByVal !== '') ? filterByVal + ' ' : '') + 'count ' +
                                ((groupBy && groupBy !== '') ? "group by " + groupByOptionsDefault.filter(group => group.value === groupBy)[0].label : '')
                        }]
                    }
                } else {
                    return widget;
                }
            })
            this.setState({ widgetDetails: updatedWidgetDetails })
        }
    }

    handleChangeSource = async (id, value) => {
        let updatedWidgetDetails = await this.state.widgetDetails.map(widget => {
            return widget.layouts[0].id === id ? {
                ...widget,
                'dataSource': value,
                layouts: [{
                    ...widget.layouts[0],
                    filterBy: value === '' ? '' : widget.layouts[0].filterBy,
                    filterByVal: value === '' ? '' : widget.layouts[0].filterByVal,
                    groupBy: value === '' ? '' : widget.layouts[0].groupBy,
                    widgetType: value === '' ? '' : widget.layouts[0].widgetType,
                    widgetName: value === '' ? '' : widget.layouts[0].widgetName,
                    dateFilter: value === '' ? '' : widget.layouts[0].dateFilter
                }]
            } : widget
        });
        await this.setState({ widgetDetails: updatedWidgetDetails })
        this.generateWidgetName(id);
    }

    updateHeaders = async (field, value, source) => {
        const { updatedLoginHeaders, updatedQuoteHEaders, updatedBookingHeaders } = this.state;
        const headers = source === 'login' ? updatedLoginHeaders : source === 'booking' ? updatedBookingHeaders : updatedQuoteHEaders;

        if (field === 'filterBy') {
            let updatedGroupOptions = groupByOptionsDefault.filter(option => option.value !== value);
            let updatedHeaders = headers.map(header => {
                if (header.field === 'groupBy') {
                    return { ...header, 'options': updatedGroupOptions }
                }
                else if (header.field === 'filterByVal') {
                    return { ...header, 'placeholder': value === 'status' ? 'SUCCESS or FAILED' : '' }
                }
                else {
                    return header
                }
            })
            await this.setState({
                [source === 'login' ? 'updatedLoginHeaders' : source === 'booking' ? 'updatedBookingHeaders' : 'updatedQuoteHEaders']: updatedHeaders
            })
        }
        else if (field === 'groupBy') {
            let updatedFilterOptions = filterOptionsDefault.filter(option => option.value !== value)
            let updatedHeaders = headers.map(header => {
                if (header.field === 'filterBy') {
                    return { ...header, 'options': updatedFilterOptions }
                }
                else {
                    return header
                }
            })
            await this.setState({
                [source === 'login' ? 'updatedLoginHeaders' : source === 'booking' ? 'updatedBookingHeaders' : 'updatedQuoteHEaders']: updatedHeaders
            })
        }
        else if (field === 'filterByVal') {
            let updatedHeaders = headers.map(header => {
                if (header.field === 'filterByVal' && value !== '') {
                    return { ...header, 'placeholder': "" }
                }
                else {
                    return header
                }
            })
            await this.setState({
                [source === 'login' ? 'updatedLoginHeaders' : source === 'booking' ? 'updatedBookingHeaders' : 'updatedQuoteHEaders']: updatedHeaders
            })
        }
    }
    handleWidgetCreation = async () => {
        const { layoutDetails, widgetDetails } = this.state;
        this.setState({
            showSnackBar: false,
            snackBarMsg: '',
            snackBarType: 'info'
        })
        const reqBody = {
            tenant: sessionStorage.getItem('tenant'),
            userId: sessionStorage.getItem('userName'),
            noOfRows: layoutDetails[0].rows,
            noOfColumns: layoutDetails[0].colomns,
            widgetDetails: widgetDetails
        }
        const widgetValidation = await this.validateConfigurations();
        if (widgetValidation === false) {
            savePreferences(reqBody).then(res => {
                if (res.status === 201) {
                    this.setState({
                        showSnackBar: true,
                        snackBarMsg: ' Preferences saved successfully',
                        snackBarType: 'success'
                    })
                }
                else {
                    this.setState({
                        showSnackBar: true,
                        snackBarMsg: ' Preferences saved failed',
                        snackBarType: 'error'
                    })
                }
            });
        }
    }

    render() {
        const { widgetDetails, layoutDetails, showSnackBar, snackBarMsg, snackBarType, widgetError } = this.state;
        const { classes, intl, backToHome } = this.props;
        return (
            <div className={classes.containerDiv}>
                {showSnackBar ? getSnackBar(snackBarMsg, true, snackBarType) : null}
                <div className={classes.heading}>
                    <CpBreadcrumb>
                        <Link color='inherit' classes={{ underlineHover: classes.underline }} href="#" onClick={() => backToHome()}>{intl.formatMessage({ id: 'home' })}</Link>
                        <Typography color='textPrimary' >{intl.formatMessage({ id: 'widgetConfigurations' })}</Typography>
                    </CpBreadcrumb>
                </div>
                {widgetError === true ? <span className={classes.errorDiv}>Please fill in all required fields</span> : null}
                <div>
                    <div className={classes.button}>
                        <CPButton styles={{ float: 'right' }} variant='primary' id="save" onClick={() => this.handleWidgetCreation()} >{intl.formatMessage({ id: 'save' })} </CPButton>
                    </div>
                    <Box header={intl.formatMessage({ id: 'widgetLayout' })}>
                        <div className={classes.widgetDetailsTableHeader}>
                            <CpEditableTable headers={widgetLayoutHeaders} data={layoutDetails} onChangefields={this.handleChangeFields} />
                        </div>
                        {widgetDetails && widgetDetails.length > 0 ?
                            <Box header={intl.formatMessage({ id: 'widgetDetails' })}>
                                <div className={classes.widgetDetailsTable}>
                                    {
                                        widgetDetails.map(widget => {
                                            return (
                                                <>
                                                    <div className={classes.dataSource}>
                                                        <CpTypeahead options={sourceOptions} label={intl.formatMessage({ id: 'widgetDataSource' })} afterSelectionShow={'label'} value={widget && widget.dataSource ? widget.dataSource : ''} onChange={(event) => this.handleChangeSource(widget.layouts[0].id, event && event.value ? event.value : '')} />
                                                    </div>
                                                    <div >
                                                        {widget && widget.dataSource === "" ? null :
                                                            <div className={classes.widgetInfoWrapper}>
                                                                <div className={classes.widgetInfoLabel}> {intl.formatMessage({ id: 'widgetConfiguration' })}</div>
                                                                <div className={classes.widgetInfo}>
                                                                    <CpEditableTable headers={this.generateHeaders(widget.dataSource, widget.layouts[0].filterBy)} data={widget.layouts} onChangefields={this.handleChangeFields} />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </>)
                                        })
                                    }
                                </div>
                            </Box >
                            : null}
                    </Box>

                </div>
            </div >
        );
    }
}


export default injectIntl(withTenant(withCPContext(withError(withRouter(withStyles(style)(ConfigureWidgets))))));
import React from 'react';
import "./index.css"
import ReactDOM from 'react-dom/client';
import App from "./App"
import 'bootstrap/dist/css/bootstrap.css';
import { TenantProvider, defaultTenant } from "./common/Tenant";
import { CPContextProvider, defaultCPContext } from './common/CPcontext';
import { IntlProvider } from 'react-intl';
import { messages } from "./translations/messages"
import Favicon from 'react-favicon';

let paths = window.location.hash.split("/");
let tenant = ''
tenant = (paths[2] === "login" && paths.length === 3) ? paths[1].toUpperCase() : defaultTenant.tenant;
if (sessionStorage.getItem('tenant') !== null && sessionStorage.getItem('tenant') !== '') {
  tenant = sessionStorage.getItem('tenant');
}
else {
  sessionStorage.setItem('tenant', paths[1] ? paths[1].toUpperCase() : '')
  sessionStorage.setItem('tenant-url', paths[1] ? paths[1] : '')

  tenant = sessionStorage.getItem('tenant');
}
let tenantDetails = {
  primaryColor: '',
  secondaryColor: '',
  tenant: tenant,
  redirectUrl: ''
}
const language = navigator.language;
const root= ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <IntlProvider locale={language} messages={messages[language] ? messages[language] : messages['en']}>
    <TenantProvider value={tenantDetails}>
      <CPContextProvider value={defaultCPContext}>
      <Favicon url={`/secure-ui/themes/${tenantDetails.tenant}/favicon.gif`} />
        {App()}
      </CPContextProvider>
    </TenantProvider>
  </IntlProvider>,
);
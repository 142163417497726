import React from 'react'
import ReactEcharts from 'echarts-for-react';
import { withStyles, useTheme } from '@material-ui/core';
import { ReactComponent as LoadingIcon } from '../../../src/images/loading.svg'
import RefreshIcon from '@material-ui/icons/Refresh';
import { injectIntl } from 'react-intl';
import { generateColorGradiants } from '../../common/utils';
const style = {
    topDiv: {
        display: 'flex'
    },
    noDataDiv: {
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Open Sans Italic',
        fontStyle: 'italic'
    },
    header: {
        marginTop: '10px',
        marginLeft: '50px',
        fontWeight: '600'
    },
    refreshIcon: {
        marginTop: '10px',
        marginLeft: 'auto',
        cursor: 'pointer',
        marginRight: '8px',
        color: '#417F90'
    },
    loadingDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '25rem',
        flexDirection: 'column'
    }
}


const PieChart = (props) => {
    const { classes, data, heading, handleClick, loading, handleRefresh, intl, noRefresh, noLegend } = props;
    const theme = useTheme()
    const primaryColor = theme.palette.primary.main
    const legendData = data ? data.map((name) => name.name) : []
    const onEvents = {
        click: (event) => handleClick ? handleClick(event) : null
    };
    const seriesData = data
    const option = {
        animation: false,
        title: {
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            appendToBody: true,
            formatter: '{b} : {c} ',
            borderColor: '#2C697A',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            padding: 15,
            textStyle: {
                color: '#4A4E50',
                fontFamily: 'Open Sans',
                fontSize: 14
            }
        },
        legend: {
            show: sessionStorage.getItem('preferredWidgetsEnabled') === 'true' ? false : true,
            type: 'scroll',
            orient: 'vertical',
            right: 10,
            top: 20,
            bottom: 20,
            data: noLegend ? {} : legendData
        },
        series: [
            {
                type: 'pie',
                radius: '60%',
                center: ['40%', '50%'],
                data: seriesData ? seriesData : [],
                color: generateColorGradiants(primaryColor),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 2,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                label: {
                    show: true,
                    fontSize: '12 px',
                    color: "rgba(10, 0, 0, 1)",
                    padding: [0, 0, 0, 0],
                    ellipsis: '...',
                    overflow: 'truncate'
                }
            }
        ]
    };
    return (
        <div>
            {
                loading ?
                    <div className={classes.loadingDiv} >
                        <LoadingIcon />
                        <span>Loading...</span>
                    </div> :
                    <>
                        <div className={classes.topDiv} >
                            <span className={classes.header}>{heading}</span>
                            {noRefresh ? null : <RefreshIcon onClick={handleRefresh} fontSize="large" className={classes.refreshIcon} />}
                        </div>
                        {
                            seriesData.length === 0 ?
                                <div className={classes.noDataDiv}>
                                    {intl.formatMessage({ id: 'no_data' })}
                                </div> :
                                <div>
                                    <ReactEcharts option={option} onEvents={onEvents} style={{ height: "200px" }} />
                                </div>
                        }

                    </>
            }
        </div>
    )
}

export default injectIntl(withStyles(style)(PieChart))

import React, { useState } from 'react';
import { TenantConsumer } from '../../common/Tenant';
import { withRouter } from 'react-router-dom';
import CPButton from '../common/Button'
import CpInput from '../common/CpInput'
import { Card, CardHeader, CardContent, Box, withStyles, Grid, Typography, AppBar } from '@material-ui/core';
import { useForm } from "react-hook-form";
import { loginAPI, logError, logout, logMetrics } from '../../sevices/common/CpServices';
import { useIntl } from 'react-intl';
import withCPContext from '../common/withCPContext';
import { vadlidateEmail, voidHandler } from '../../common/utils';
import { Link } from 'react-router-dom';
import ForgotPassword from './ForgotPassword'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CPConfirmBox from '../common/CPConfirmBox';
import withTenant from '../common/withTenant';
import Captcha from './Captcha';

let paths = window.location.hash.split("/");
let tenant = null
if (paths && paths[2] === 'login') {
  sessionStorage.setItem('tenant', paths[1] ? paths[1].toUpperCase() : '')
  sessionStorage.setItem('tenant-url', paths[1] ? paths[1] : '')
  sessionStorage.removeItem('Auth-Token')
  tenant = sessionStorage.getItem('tenant');
} else {
  tenant = sessionStorage.getItem('tenant');
}
const style = theme => ({
  layout: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '5%'
  },
  logocontainer: {
    width: '20%',
    display: 'flex',
    justifyContent: 'start',
    margin: '10px'
  },
  maincontainer: {
    height: '94vh',
    margin: '0px',
    [theme.breakpoints.up('md')]: {
      height: '96vh',
    }
  },
  inputs: {
    margin: '1rem 0'
  },
  cardlayout: {
    width: '100%',
    margin: '2%',
    marginRight: '7%'
  },
  inputs1: {
    height: '5em',
  },
  linkText: {
    float: 'left',
    padding: '3.5% 0%'
  },
  checkCircle: {
    float: 'left',
    witdth: '20%',
    marginRight: '3%'
  },
  copyRightBar: {
    position: 'fixed',
    left: '0px',
    padding: '5px',
    width: '100%',
    bottom: '0',
    top: '94%',
    [theme.breakpoints.up('md')]: {
      top: '96%',
    }
  },
  buttonGroup2: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonGroup1: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center'
  }
})
function Signin(props) {
  const { tenantDetails } = props
  const captchaCount = tenantDetails.captchaCount
  const [open, setOpen] = React.useState(false);
  const [un, setUn] = useState('');
  const [pw, setPw] = useState('');
  const [captchaCounter, setcaptchaCounter] = useState(1);
  const [enableCaptcha, setEnableCaptcha] = useState(captchaCount === captchaCounter ? true : false);
  const captchRef = React.useRef(null);
  const intl = useIntl();

  const handleSignin = (data, forceLogoff) => {
    if (forceLogoff === true) {
      processSigninData(data);
    }
    else {
      setcaptchaCounter(captchaCounter + 1)
      if (captchRef.current) {
        if (captchRef.current.validateCaptcha()) {
          setcaptchaCounter(1);
          processSigninData(data);
        } else {
          setErrors('');
        }
      }
      else {
        if (captchaCounter % captchaCount === 0) {
          setEnableCaptcha(true);
          setErrors('');
        }
        else {
          processSigninData(data);
        }
      }
    }
  }
  const processSigninData = async (data) => {
    const emailorUserId = data.email.trim()
    const isEmail = emailorUserId.includes("@") || emailorUserId.includes(".")
    if (isEmail && vadlidateEmail(emailorUserId)) {
      const requestBody = {
        "email": emailorUserId,
        "password": data.password,
        "forceLogoff": data.forceLogoff ? data.forceLogoff : undefined
      }
      login(requestBody)
    }
    else if (emailorUserId.length > 0 && isEmail === false) {
      const requestBody = {
        "userId": emailorUserId,
        "password": data.password,
        "forceLogoff": data.forceLogoff ? data.forceLogoff : undefined
      }
      login(requestBody)
    }
    else {
      setValue("email", "")
      setError("email", {
        type: "manual",
        message: intl.formatMessage({ id: 'login_ErrorMessage_ValidEmail' })
      });
    }
  }
  const login = (data) => {

    const { tenantDetails } = props
    setErrors('');
    clearChangePwdmsg();
    if (tenant === '' || tenant === null) {
      tenant = paths[1] ? paths[1].toUpperCase() : '';
      sessionStorage.setItem('tenant', tenant)
      sessionStorage.setItem('tenant-url', paths[1])
    }

    if (sessionStorage.getItem('tenant') === '' || sessionStorage.getItem('tenant') === null) {
      sessionStorage.setItem('tenant', tenant)
      sessionStorage.setItem('tenant-url', paths[1])
    }

    loginAPI(data, props.history).then(res => {
      console.log("res status code",res)
      const metricsReqBody = {
        "tenant": sessionStorage.getItem('tenant'),
        "userId": data.email ? data.email : data.userId,
        "service": "login",
        "operation": "generateToken",
        "status": "SUCCESS"
      }
      logMetrics(metricsReqBody);
      if (sessionStorage.getItem('allowPortal') === 'false') {
        setErrors(intl.formatMessage({ id: 'unauthorizedAccessMsg' }));
        logError(error, "/Login");
        logout(props.history, undefined, undefined, tenantDetails.baseSystem);
      }
      else {
        props.history.push('/')
        setErrors('')
      }
    }).catch(error => {
      const metricsReqBody = {
        "tenant": sessionStorage.getItem('tenant'),
        "userId": data.email ? data.email : data.userId,
        "service": "login",
        "operation": "generateToken",
        "status": "FAILED"
      }
      logMetrics(metricsReqBody);
      if (error.response && error.response.data) {
        let code = error.response.data.code;
        let msg = error.response.data.message
        enableCaptcha ? captchRef.current.prepareCaptchaString() : voidHandler();
        if (code === '22007') {
          setUn(data.email ? data.email : data.userId);
          setPw(data.password);
          setErrors(intl.formatMessage({ id: 'userLoggedIn' }));
          setOpen(true);
          logError(error, "/Login");
        }
        else if (code === "22001") {
          setErrors(msg);
          logError(error, "/Login");
        }
        else if (code === '11003') {
          setErrors(intl.formatMessage({ id: 'routingErrorMsg' }));
          logError(error, "/Login");
        }
        else {
          if (error.response.data.fieldErrors && error.response.data.fieldErrors.length > 0) {
            setErrors(error.response.data.fieldErrors[0]);
            logError(error, "/Login");
          } else {
            setErrors(intl.formatMessage({ id: 'contactAdminMsg' }));
            logError(error, "/Login");
          }
        }
      }
      else {
        enableCaptcha ? captchRef.current.prepareCaptchaString() : voidHandler();
        setErrors(intl.formatMessage({ id: 'contactAdminMsg' }));
        logError(error, "/Login");
      }

    })
  }
  const handleLink = () => {
    setErrors('');
    setIsLinkClicked(!isLinkClicked);
    clearChangePwdmsg();
  }
  const handleBack = () => {
    setErrors('');
    if (isLinkClicked) {
      setIsLinkClicked(!isLinkClicked);
    }
    if (isPwdReset) {
      setIsPwdReset(!isPwdReset);
    }
    if (location && location.state && location && location.state.isForgotPassword) {
      location.state.isForgotPassword = false;
      location.state.isTokenExpiryMsg = '';
      props.history.push(`/${sessionStorage.getItem('tenant-url')}/login`);

    }
  }
  const isForgotSuccess = () => {
    setErrors('');
    if (isLinkClicked) {
      setIsLinkClicked(!isLinkClicked);
    }
    if (location && location.state && location && location.state.isForgotPassword) {
      location.state.isForgotPassword = false;
      location.state.isTokenExpiryMsg = '';
    }
    setIsPwdReset(true);
  }
  const clearChangePwdmsg = () => {
    const { location } = props;
    if (location && location.state) {
      location.state.msg = '';
    }
  }
  const { register, handleSubmit, errors, setError, setValue } = useForm();
  const { classes, location } = props;
  const [error, setErrors] = useState('')
  const [isLinkClicked, setIsLinkClicked] = useState(false)
  const [isPwdReset, setIsPwdReset] = useState(false);

  const handleOverrideSession = () => {
    let data = {
      'email': un,
      'password': pw,
      'forceLogoff': true
    }
    setOpen(false);
    handleSignin(data, true);
  };
  const handleClose = () => {
    window.$signInError = ''
    setOpen(false);
  };

  return (
    <TenantConsumer>
      {value =>
        <div className={`container-fluid ${classes.maincontainer}`}
          style={{ backgroundImage: "url(/secure-ui/themes/" + value.tenant + "/background.gif)", backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', backgroundPosition: 'center' }}>
          <div className={`${classes.logocontainer}`}>
            {/* for deployment or commit */}
            <img src={`/secure-ui/themes/${value.tenant}/company.gif`} alt="product" style={{ width: '100%', objectFit: 'scale-down' }} />
            {/* for local devlopment */}
            {/*<img src={require(`../../images/${value.tenant}/company.gif`)} alt="product" />*/}
          </div>
          <Box className={`${classes.layout}`}>
            <Grid container flex='true' justify="flex-end">
              <Grid item lg={3} xl={3} md={4} sm={6} xs={12}>
                <Card className={classes.cardlayout} >
                  <CardHeader title={isLinkClicked || (location && location.state && location && location.state.isForgotPassword) ?
                    intl.formatMessage({ id: 'Forgot_Password_Label' }) : isPwdReset ?
                      (<div >
                        <CheckCircleIcon style={{ fontSize: '20px', color: '#4caf50' }} />
                        Thank you
                      </div>) : intl.formatMessage({ id: 'login_label_login' })} />
                  <CardContent style={{ padding: '16px' }}>
                    {isLinkClicked || (location && location.state && location && location.state.isForgotPassword) ?
                      <ForgotPassword {...props} handleBack={handleBack} isForgotSuccess={isForgotSuccess} />
                      : isPwdReset ?
                        (<div >
                          <p><span> {intl.formatMessage({ id: 'Forgot_Password_Confirmation' })} </span></p>
                          <div className={classes.buttonGroup2}>
                            <CPButton styles={{ marginRight: '0' }} id="back" variant='secondary' onClick={() => handleBack()} >{intl.formatMessage({ id: 'Reset_Back' })}</CPButton>
                          </div>
                        </div>) :
                        <div>
                          <form onSubmit={handleSubmit(handleSignin)}>
                            <div className={classes.inputs}>
                              <CpInput
                                register={register({
                                  required: intl.formatMessage({ id: 'login_ErrorMessage_Email_Required' }),
                                })}
                                autoFocus={true}
                                placeholder={intl.formatMessage({ id: 'login_Email' })}
                                name="email"
                                error={errors.email}
                                helperText={errors.email ? errors.email.types ? errors.email.types.message : errors.email.message : ""}
                                onChange={(event) => {
                                  setValue("email", event.target.value.toUpperCase())
                                  error !== '' ? setErrors('') : voidHandler()
                                }}
                              />
                            </div>

                            <div className={classes.inputs}>
                              <CpInput
                                type="password"
                                register={register({ required: intl.formatMessage({ id: 'login_ErrorMessage_Password_Required' }) })}
                                placeholder={intl.formatMessage({ id: 'login_Password' })}
                                name="password"
                                error={errors.password}
                                helperText={errors.password ? errors.password.message : ""}
                                onChange={() => { error !== '' ? setErrors('') : voidHandler() }}
                              />
                            </div>
                            {location && location.state && location.state.msg ? <span style={{ color: 'green ' }} >{location.state.msg}</span> : null}
                            {error !== '' || error !== undefined ? <span style={{ color: 'red ' }} >{error}</span> : null}
                            {enableCaptcha ? <Captcha ref={captchRef} intl={intl} /> : null}
                            <div className={classes.buttonGroup1}>
                              <Link to='#' onClick={handleLink}><span className={classes.linkText}>Forgot Password?</span></Link>
                              <CPButton styles={{ float: 'right', marginRight: '0' }} variant='primary' type="submit" id="login" >{intl.formatMessage({ id: 'login_Button' })}</CPButton>
                            </div>
                          </form>
                        </div>}
                    <CPConfirmBox show={open} message={intl.formatMessage({ id: "login_User_Already_LoggedIn" })} handleRejected={handleClose} handleAccepted={handleOverrideSession} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <AppBar className={classes.copyRightBar} >
            <Typography variant='h6' style={{ color: 'white', fontSize: '1rem' }} color="textSecondary">
              {`Version: ${value && value.version ? value.version : ''} Build ${value && value.build ? value.build : ''} ${intl.formatMessage({ id: 'copyRightMessage' })} ${intl.formatMessage({ id: 'allRightsReserved' })}`}
            </Typography>
          </AppBar>
        </div >
      }

    </TenantConsumer >

  );

}
export default withTenant(withRouter(withCPContext(withStyles(style)(Signin))));
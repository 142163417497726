import React from 'react'
import CPButton from '../../common/Button'
import MenuList from '../../common/MenuList'
import { Grid, withStyles, IconButton, Chip } from '@material-ui/core'
import Box from '../../common/Box'
import DetailedMileStoneData from '../TMFF/DetailedMileStoneData'
import DocumentTable from '../../common/DocumentTable';
import { injectIntl } from "react-intl";
import ReactToPrint from "react-to-print";
import withTenant from '../../common/withTenant';
import HelpIcon from '@material-ui/icons/Help';
import CommercialInvoiceSection from './CommercialInvoiceSection'
import DeliveryOrdersSection from './DeliveryOrdersSection'
import Header from './Header'
import {
    getDocumentsList, getMilestones, getShipmentRemarks, getBillings, getShipmentInvoiceItems, getShipmentDeliveryOrders,
    fetchFileFilters, updateShipmentDoc, fetchDocTypes, getConveyances, getConcurPPTArrivals, getInBondCoveyanceService, getBillsOfLadingService
} from '../../../sevices/CMUS/Services';
import RemarksCMUS from './RemarksCMUS';
import BillingSection from './BillingSection';
import PrintShipmentCMUS from './PrintShipmentCMUS';
import { SEARCH_SHIPMENT_HELP } from '../../../sevices/CMUS/servicesURLS';
import BillOfLadingSection from './BillOfLadingSection'
import InBondConveyance from './InBondConveyance'
import InBondHeader from './InBondHeader'
import { generateDocSaveRequest, getIsValidFileTypeandSize, getBase64, getSnackBar } from '../../../common/utils'
import CPConfirmBox from '../../common/CPConfirmBox'
import Admissions from './Admissions'
import ConcurPPTArrivalSection from './ConcurPPTArrivalSection'
import ConveyancesSection from './ConveyancesSection'
import PrintIcon from '@material-ui/icons/Print';

const style = ({ breakpoints, palette }) => ({
    heading: {
        fontSize: '24px',
        fontWeight: 700,
    },
    heading1: {
        fontSize: '12px',
        fontWeight: 700,
        color: '#2C697A',
        width: '-webkit-fill-available',
        marginTop: '1%'
    },
    buttonWarpper: {
        padding: '1%',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#007ad9'
    },
    noRecordsDiv: {
        display: 'flex',
        justifyContent: 'center',
        height: '165vh',
        alignItems: 'center',
        fontSize: '28px'
    },
    sectionDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'block',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    sectionButtonsMobile: {
        display: 'flow-root',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    sectionButtonsDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'center'
        }
    },
    errorDiv: {
        color: 'red',
        fontWeight: 'bold',
        marginLeft: '40%',
        textAlign: 'left'
    },
    sectionDesktopForPrint: {
        display: 'flex'
    },
    sectionMobileForPrint: {
        display: 'none'
    },
    noRecordsForPrint: {
        display: ' flex !important',
        justifyContent: 'center',
        alignItems: 'center'
    },
    myClass: {
        fontSize: '20px',
        color: '#2C697A !important'
    },
    button: {
        padding: '0 8%'
    },
    status: {
        fontSize: '12px',
        fontWeight: '500',
        color: 'white',
        margin: '0 1rem',
        backgroundColor: '#277AB5',
        height: '28px'
    },
    header: {
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#E8F0F4'
    },
    headingBox: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '2rem'
    },
    pushIcon: {
        marginBottom: '0',
        marginRight: '1rem'
    },
    divider: {
        margin: '0.5rem 1rem',
        backgroundColor: '#979797'
    },
    proccessing: {
        margin: "0 25% 0 0",
        fontWeight: "600",
        fontSize: '20px',
        color: palette.primary.main
    },
    iconButton: {
        marginRight: '8px',
        width:'40px'
    },
    icon: {
        fontSize: '24px',
        color: '#2C697A'
    },
    body: {
        minHeight: '40vh',
        maxHeight: '90vh',
        overflow: 'auto',
        border: '1px solid #E5E8E8'
    }
})
class DetailedShipment extends React.Component {
    constructor(props) {
        super(props);
        const { intl, shipmentFileType, data } = this.props
        this.state = {
            documentList: [],
            milestones: [],
            milestonesResponse: {},
            remarksResponse: {},
            isDesktop: window.innerWidth >= 1028 ? true : false,
            shipmentHeaderData: data ? data : {},
            showHeader: true,
            showInBondConveyance: false,
            showBillOfLading: false,
            showDocuments: false,
            showBilling: false,
            showDelivery: false,
            showMileStone: false,
            showRemarks: false,
            showCommercial: false,
            showConveyance: false,
            showConcurPPTArvl: false,
            remarksData: [],
            billingData: [],
            list: shipmentFileType === 'REGULAR' ? [{ 'name': intl.formatMessage({ id: 'header' }), 'selected': 'true', 'isDisabled': false },
            { 'name': intl.formatMessage({ id: 'milestones' }), 'selected': 'false', 'isDisabled': false },
            { 'name': intl.formatMessage({ id: 'remarkHeader' }), 'selected': 'false', 'isDisabled': false },
            { 'name': intl.formatMessage({ id: 'documents' }), 'selected': 'false', 'isDisabled': false },
            { 'name': intl.formatMessage({ id: 'commercialInvoice' }), 'selected': 'false', 'isDisabled': false },
            { 'name': intl.formatMessage({ id: 'billing' }), 'selected': 'false', 'isDisabled': this.getAccessStatus('billingAccess') },
            { 'name': intl.formatMessage({ id: 'delivery' }), 'selected': 'false', 'isDisabled': this.getAccessStatus('doAccess') }]
                : shipmentFileType === 'FTZ' ?
                    [{ 'name': intl.formatMessage({ id: 'admissions' }), 'selected': 'true', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'conveyances' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'concur/ppt/arrival' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'milestones' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'remarkHeader' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'documents' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'billing' }), 'selected': 'false', 'isDisabled': this.getAccessStatus('billingAccess') },
                    { 'name': intl.formatMessage({ id: 'delivery' }), 'selected': 'false', 'isDisabled':  this.getAccessStatus('doAccess')  }]
                    : shipmentFileType === 'INBOND' ?
                        [{ 'name': intl.formatMessage({ id: 'header' }), 'selected': 'true', 'isDisabled': false },
                        { 'name': intl.formatMessage({ id: 'inBond_Conveyance' }), 'selected': 'false', 'isDisabled': false },
                        { 'name': intl.formatMessage({ id: 'bills_Of_Lading' }), 'selected': 'false', 'isDisabled': false },
                        { 'name': intl.formatMessage({ id: 'documents' }), 'selected': 'false', 'isDisabled': false },
                        { 'name': intl.formatMessage({ id: 'billing' }), 'selected': 'false', 'isDisabled': this.getAccessStatus('billingAccess') },
                        { 'name': intl.formatMessage({ id: 'delivery' }), 'selected': 'false', 'isDisabled':  this.getAccessStatus('doAccess')  },
                        { 'name': intl.formatMessage({ id: 'milestones' }), 'selected': 'false', 'isDisabled': false },
                        { 'name': intl.formatMessage({ id: 'remarkHeader' }), 'selected': 'false', 'isDisabled': false }]
                        : [],
            shipmentData: {
                header: this.props.data ? this.props.data : '',
                remarks: [],
                milestones: [],
                documents: [],
                commInvItems: [],
                billing: [],
                deliveryOrders: [],
                conveyances: [],
                concurPPT: [],
                inbondConveyance: [],
                inbondBol: []
            },
            enablePrint: false,
            disableButton: false,
            fileTypes: '',
            fileSize: '10M',
            fileErrors: {
                invalidType: false,
                limitExceded: false
            },
            uploadRequired: true,
            totalUpdatedDocs: 0,
            uploadError: '',
            docTypes: [],
            newFileAdded: false,
            showSnackBar: false,
            snackBarMsg: '',
            snackBarType: '',
            docErrorList: [],
            inBondConveyanceData: null
        }
    }
    componentDidMount = async () => {
        this.applyDocumentFileFilters();
        const res = await fetchDocTypes()
        const documentTypes = res && res.data ? res.data : []
        this.setState({ docTypes: documentTypes })
    }

    getAccessStatus  = (accessibilityModule) =>{
        console.log('Module',accessibilityModule)
        switch (accessibilityModule) {
            case 'billingAccess' : {
                if(sessionStorage.getItem('billingAccess') === 'N'){
                     return true
                 }else{
                    return false
                 }
            }
            case 'doAccess' : {
                if(sessionStorage.getItem('doAccess') === 'N' ){
                     return true
                 }else{
                     return false
                 }
            }
            case 'documentUploadAccess' : {
                if(sessionStorage.getItem('documentUpload') === 'N' ){
                     return true
                 }else{
                     return false
                 }
            }
            default : 
                return false
        }
       
       
    }
    getContentRequiredForPrint = async () => {
        this.setState({ enablePrint: false, disableButton: true })
        const { shipmentFileNo, shipmentFileType } = this.props
        let promise = []
        if (shipmentFileType === 'REGULAR') {
            const mileStoneRes = getMilestones(shipmentFileNo, 1)
            const remarksRes = getShipmentRemarks(shipmentFileNo, 1)
            const docsRes = getDocumentsList(shipmentFileNo);
            const commInvItemsRes = getShipmentInvoiceItems(1)
            const billingRes = getBillings(1)
            const delvryOrdersRes = getShipmentDeliveryOrders(shipmentFileNo, 1)
            promise = await Promise.all([mileStoneRes, remarksRes, docsRes, commInvItemsRes, billingRes, delvryOrdersRes]);
            await this.setState({
                shipmentData: {
                    ...this.state.shipmentData,
                    milestones: promise[0] && promise[0].data && promise[0].data.data ? promise[0].data.data : [],
                    remarks: promise[1] && promise[1].data && promise[1].data.data ? promise[1].data.data : [],
                    documents: promise[2] && promise[2].documentList ? promise[2].documentList : [],
                    commInvItems: promise[3] && promise[3].data && promise[3].data.data ? promise[3].data.data : [],
                    billing: promise[4] && promise[4].data && promise[4].data.data ? promise[4].data.data : [],
                    deliveryOrders: promise[5] && promise[5].data && promise[5].data.data ? promise[5].data.data : []
                },
                enablePrint: true
            })
        } else if (shipmentFileType === 'FTZ') {
            const mileStoneRes = getMilestones(shipmentFileNo, 1)
            const remarksRes = getShipmentRemarks(shipmentFileNo, 1)
            const docsRes = getDocumentsList(shipmentFileNo);
            const conveyances = getConveyances(1)
            const concurPptArrvl = getConcurPPTArrivals(1)
            const billingRes = getBillings(1)
            const delvryOrdersRes = getShipmentDeliveryOrders(shipmentFileNo, 1)
            promise = await Promise.all([mileStoneRes, remarksRes, docsRes, conveyances, concurPptArrvl, billingRes, delvryOrdersRes]);
            await this.setState({
                shipmentData: {
                    ...this.state.shipmentData,
                    milestones: promise[0] && promise[0].data && promise[0].data.data ? promise[0].data.data : [],
                    remarks: promise[1] && promise[1].data && promise[1].data.data ? promise[1].data.data : [],
                    documents: promise[2] && promise[2].documentList ? promise[2].documentList : [],
                    conveyances: promise[3] && promise[3].data && promise[3].data.data ? promise[3].data.data : [],
                    concurPPT: promise[4] && promise[4].data && promise[4].data.data ? promise[4].data.data : [],
                    billing: promise[5] && promise[5].data && promise[5].data.data ? promise[5].data.data : [],
                    deliveryOrders: promise[6] && promise[6].data && promise[6].data.data ? promise[6].data.data : []
                },
                enablePrint: true
            })
        } else if (shipmentFileType === 'INBOND') {
            const mileStoneRes = getMilestones(shipmentFileNo, 1)
            const remarksRes = getShipmentRemarks(shipmentFileNo, 1)
            const docsRes = getDocumentsList(shipmentFileNo);
            const inbondConveyancesRes = getInBondCoveyanceService(shipmentFileNo)
            const billOfLadingRes = getBillsOfLadingService(1)
            const billingRes = getBillings(1)
            const delvryOrdersRes = getShipmentDeliveryOrders(shipmentFileNo, 1)
            promise = await Promise.all([mileStoneRes, remarksRes, docsRes, inbondConveyancesRes, billOfLadingRes, billingRes, delvryOrdersRes]);
            await this.setState({
                shipmentData: {
                    ...this.state.shipmentData,
                    milestones: promise[0] && promise[0].data && promise[0].data.data ? promise[0].data.data : [],
                    remarks: promise[1] && promise[1].data && promise[1].data.data ? promise[1].data.data : [],
                    documents: promise[2] && promise[2].documentList ? promise[2].documentList : [],
                    inbondConveyance: promise[3] && promise[3].data && promise[3].data.data ? promise[3].data.data[0] : [],
                    inbondBol: promise[4] ? promise[4] : [],
                    billing: promise[5] && promise[5].data && promise[5].data.data ? promise[5].data.data : [],
                    deliveryOrders: promise[6] && promise[6].data && promise[6].data.data ? promise[6].data.data : []
                },
                enablePrint: true
            })
        }
    }
    getShipmentRemarksInfo = async (pageIndex) => {
        const { shipmentFileNo } = this.props
        const res = await getShipmentRemarks(shipmentFileNo, pageIndex)
        if (res && res.status === 200 && res.data) {
            await this.setState({
                remarksResponse: {
                    totalRecords: res.data.totalRecords,
                    maxResults: res.data.maxResults,
                    selectedPage: pageIndex,
                    noOfPages: res.data.maxResults !== 0 ? Math.ceil(res.data.totalRecords / res.data.maxResults) : 1
                },
                remarksData: res.data.data
            })
        } else {
            this.setState({
                remarksResponse: {
                    totalRecords: 0,
                    maxResults: 0,
                    selectedPage: 1,
                    noOfPages: 0
                },
                remarksData: []
            })
        }
    }
    getInBondCoveyance = async () => {
        const { data } = this.props
        const inBondData = await getInBondCoveyanceService((data && data.header && data.header.itNo) ? data.header.itNo : '');
        this.setState({
            inBondConveyanceData: inBondData && inBondData.data && inBondData.data.data ? inBondData.data.data[0] : {}
        })
    }

    handleOnClickMenuItem = async (event) => {
        const mentList = this.state.list;
        await mentList.map((listItem) => {
            return event === listItem.name ? listItem.selected = 'true' : listItem.selected = 'false'
        })
        await this.setState({
            list: mentList
        })

        switch (event) {

            case "Header":
            case "Admissions":
                this.setState({
                    showHeader: true,
                    showInBondConveyance: false,
                    showBillOfLading: false,
                    showDocuments: false,
                    showBilling: false,
                    showDelivery: false,
                    showMileStone: false,
                    showRemarks: false,
                    showCommercial: false,
                    showConveyance: false,
                    showConcurPPTArvl: false
                })
                break;
            case "Milestones":
                await this.getMilestonesData(1);
                this.setState({
                    showHeader: false,
                    showInBondConveyance: false,
                    showBillOfLading: false,
                    showDocuments: false,
                    showBilling: false,
                    showDelivery: false,
                    showMileStone: true,
                    showRemarks: false,
                    showCommercial: false,
                    showConveyance: false,
                    showConcurPPTArvl: false
                })
                break;
            case "Concur/PTT/Arrival":
                await this.setState({
                    showConcurPPTArvl: false
                })
                this.setState({
                    showHeader: false,
                    showInBondConveyance: false,
                    showBillOfLading: false,
                    showDocuments: false,
                    showBilling: false,
                    showDelivery: false,
                    showMileStone: false,
                    showRemarks: false,
                    showCommercial: false,
                    showConveyance: false,
                    showConcurPPTArvl: true
                })
                break;
            case "Remarks":
                await this.getShipmentRemarksInfo(1)
                this.setState({
                    showHeader: false,
                    showInBondConveyance: false,
                    showBillOfLading: false,
                    showDocuments: false,
                    showBilling: false,
                    showDelivery: false,
                    showMileStone: false,
                    showRemarks: true,
                    showCommercial: false,
                    showConveyance: false,
                    showConcurPPTArvl: false
                })
                break;
            case "Conveyances":
                await this.setState({
                    showConveyance: false
                })
                this.setState({
                    showHeader: false,
                    showInBondConveyance: false,
                    showBillOfLading: false,
                    showDocuments: false,
                    showBilling: false,
                    showDelivery: false,
                    showMileStone: false,
                    showRemarks: false,
                    showCommercial: false,
                    showConveyance: true,
                    showConcurPPTArvl: false
                })
                break;
            case "Documents":
                this.setState({
                    showHeader: false,
                    showInBondConveyance: false,
                    showBillOfLading: false,
                    showDocuments: true,
                    showBilling: false,
                    showDelivery: false,
                    showMileStone: false,
                    showRemarks: false,
                    showCommercial: false,
                    showConveyance: false,
                    showConcurPPTArvl: false,
                    documentList: await this.getDocumentsData()
                })
                break;
            case "In-Bond Conveyance":
                await this.getInBondCoveyance()
                this.setState({
                    showHeader: false,
                    showInBondConveyance: true,
                    showBillOfLading: false,
                    showDocuments: false,
                    showBilling: false,
                    showDelivery: false,
                    showMileStone: false,
                    showRemarks: false,
                    showCommercial: false,
                    showConveyance: false,
                    showConcurPPTArvl: false
                })
                break;
            case "Bills of Lading":
                await this.setState({
                    showBillOfLading: false
                })
                this.setState({
                    showHeader: false,
                    showInBondConveyance: false,
                    showBillOfLading: true,
                    showDocuments: false,
                    showBilling: false,
                    showDelivery: false,
                    showMileStone: false,
                    showRemarks: false,
                    showCommercial: false,
                    showConveyance: false,
                    showConcurPPTArvl: false
                })
                break;
            case "Billing":
                await this.setState({
                    showBilling: false
                })
                this.setState({
                    showHeader: false,
                    showInBondConveyance: false,
                    showBillOfLading: false,
                    showDocuments: false,
                    showBilling: true,
                    showDelivery: false,
                    showMileStone: false,
                    showRemarks: false,
                    showCommercial: false,
                    showConveyance: false,
                    showConcurPPTArvl: false
                })
                break;
            case "Delivery Orders":
                await this.setState({
                    showDelivery: false
                })
                this.setState({
                    showHeader: false,
                    showInBondConveyance: false,
                    showBillOfLading: false,
                    showDocuments: false,
                    showBilling: false,
                    showDelivery: true,
                    showMileStone: false,
                    showRemarks: false,
                    showCommercial: false,
                    showConveyance: false,
                    showConcurPPTArvl: false
                })
                break;
            case "Commercial Invoice":
                await this.setState({
                    showCommercial: false
                })
                this.setState({
                    showHeader: false,
                    showInBondConveyance: false,
                    showBillOfLading: false,
                    showDocuments: false,
                    showBilling: false,
                    showDelivery: false,
                    showMileStone: false,
                    showRemarks: false,
                    showCommercial: true,
                    showConveyance: false,
                    showConcurPPTArvl: false
                })
                break;
            default:
                break;
        }
    }

    getDocumentsData = async () => {
        const { shipmentFileNo } = this.props
        const respo = await getDocumentsList(shipmentFileNo);
        if (respo && respo.status === 200 && respo.data.documentList) {
            return respo.data.documentList;
        }
        else {
            return [];
        }

    }
    getMilestonesData = async (pageIndex) => {
        const { shipmentFileNo } = this.props
        const res = await getMilestones(shipmentFileNo, pageIndex)
        if (res && res.status === 200 && res.data) {
            this.setState({
                milestonesResponse: {
                    totalRecords: res.data.totalRecords,
                    maxResults: res.data.maxResults,
                    selectedPage: pageIndex,
                    noOfPages: res.data.maxResults !== 0 ? Math.ceil(res.data.totalRecords / res.data.maxResults) : 1
                },
                milestones: res.data.data
            })
        } else {
            this.setState({
                milestonesResponse: {
                    totalRecords: 0,
                    maxResults: 0,
                    selectedPage: 1,
                    noOfPages: 0
                },
                milestones: []
            })
        }
    }
    applyDocumentFileFilters = async () => {
        const fileFilters = await fetchFileFilters();
        if (fileFilters && fileFilters.extensionList && fileFilters.extensionList.length > 0) {
            let updatedList = fileFilters.extensionList.map(item => { return '.' + item })
            this.setState({ fileTypes: updatedList.join(',') })
        } else {
            const defaultTypes = '.pdf,.txt,.doc,.docx,.xls,.xlsx,.xlsm,.ppt,.csv,.odt,.eml,.emlx,.msg,.zip,.jpg,.jpeg,.png,.gif,.tif,.bmp,.ico';
            this.setState({ fileTypes: defaultTypes })
        }
    }
    handleUploadFile = async (event, from) => {
        const file = event && event.target ? event.target.files[0] : event[0];
        let fileName = ''
        let type = '';
        if (file && file.name) {
            fileName = file.name.slice(0, file.name.lastIndexOf('.'))
            type = file.name.slice(file.name.lastIndexOf('.') + 1)
        }
        const { documentList } = this.state
        let updateddocuments = []
        if (this.state.totalUpdatedDocs >= 5) {
            this.setState({
                uploadRequired: false
            })
        }
        else {
            this.setState({
                fileErrors: {
                    invalidType: false,
                    limitExceded: false
                }
            })
            const res = await getIsValidFileTypeandSize(file, this.state.fileSize, this.state.fileTypes)
            this.setState({
                fileErrors: {
                    invalidType: res.invType,
                    limitExceded: res.sizeExceded
                }
            })
            if (res.isValid) {
                this.setState({ newFileAdded: true, totalUpdatedDocs: this.state.totalUpdatedDocs + 1 });
                getBase64(file).then(data => {
                    if (from === 'add') {
                        let docSNo = documentList[0] ? documentList[0].docSNo ? documentList[0].docSNo + 1 : 1 : 1
                        updateddocuments = [{
                            docSNo: docSNo,
                            action: 'new',
                            description: '',
                            fileName: fileName,
                            enablefields: true,
                            documentType: type,
                            fileData: data
                        },
                        ...documentList]
                    }
                    this.setState({
                        documentList: updateddocuments
                    })
                })
            }
        }
    }
    onChangeDocumentfields = (docSNo, value, event) => {
        const { documentList } = this.state
        let updatedDocuments = documentList.map(document => {
            return document.docSNo === docSNo ? { ...document, description: value } : document
        })
        this.setState({
            documentList: updatedDocuments
        })
    }
    handleremoveNewDocFunc = (docSNo) => {
        const { documentList, totalUpdatedDocs } = this.state
        let updatedDocuments = documentList.filter((document) => {
            return document.docSNo !== docSNo;
        });
        let newtotalupdatedDocs = totalUpdatedDocs - 1
        this.setState({
            totalUpdatedDocs: newtotalupdatedDocs,
            documentList: updatedDocuments,
            newFileAdded: newtotalupdatedDocs > 0 ? true : false
        })
    }
    handleSave = async () => {
        const { intl, shipmentFileNo } = this.props
        await this.setState({
            disableButton: true,
            showSnackBar: false,
            docErrorList: []
        })
        const shipmentReqBody = generateDocSaveRequest(this.state, shipmentFileNo);
        const res = await updateShipmentDoc(shipmentReqBody)
        if (res) {
            if (res.status === 201) {
                this.setState({ disableButton: false, newFileAdded: false, showSnackBar: true, snackBarMsg: intl.formatMessage({ id: 'docUploadSuccess' }), snackBarType: 'success' })
            } else if (res.status === 200) {
                const errorList = (res.data && res.data.documentList) ? res.data.documentList.map(item => {
                    let msg = `File name: ${item.fileName ? item.fileName : ''} ; Error: ${item.statusMessage ? item.statusMessage : ''}`
                    return msg
                }) : []
                this.setState({ disableButton: false, newFileAdded: false, showSnackBar: true, snackBarMsg: (res.data && res.data.statusMessage) ? res.data.statusMessage : '', snackBarType: 'error', docErrorList: errorList })
            }
            else {
                this.setState({ disableButton: false, newFileAdded: false, showSnackBar: true, snackBarMsg: (res.data && res.data.fieldErrors ? res.data.fieldErrors[0] : intl.formatMessage({ id: 'somethingWentWrong' })), snackBarType: 'error' })
            }
            this.setState({ documentList: await this.getDocumentsData() })
        }
    }
    handleConfirmBox = () => {
        this.setState({
            uploadRequired: false
        })
    }
    handleConfirmClick = () => {
        this.setState({
            uploadRequired: true
        })
    }
    getDocDownloadErrorDetails = async (msg, status) => {
        await this.setState({ showSnackBar: false })
        await this.setState({ showSnackBar: true, snackBarMsg: msg, snackBarType: status === 204 ? 'info' : 'error' })
    }
    getColourforStatus = (status) => {
        switch (status ? status.toUpperCase() : '') {
            case 'PICKUP':
            case 'PICKED UP':
            case 'ARRIVED':
            case 'DEPARTED':
                return '#277AB5'
            case "DELIVERED":
            case 'RELEASED':
                return "green"
            case 'DELAYED':
                return 'red'
            default:
                return '#277AB5';
        }
    }
    render() {
        const { handleDrawer, classes, tenantDetails, shipmentFileNo, intl, shipmentFileType } = this.props
        const { list, shipmentHeaderData, showHeader, showDocuments, showBilling, showDelivery, showMileStone, showRemarks, showCommercial, milestonesResponse, milestones, remarksData, shipmentData, disableButton, enablePrint,
            docTypes, fileErrors, fileTypes, fileSize, totalUpdatedDocs, showSnackBar, snackBarMsg, snackBarType, docErrorList, showConcurPPTArvl, showConveyance, showInBondConveyance, showBillOfLading, inBondConveyanceData } = this.state;
        return (
            < div >
                <div className={classes.header}>
                    <div className={classes.headingBox}>
                        <span className={classes.heading} > {shipmentFileType === 'FTZ' ? intl.formatMessage({ id: 'ftzshipment' }) : intl.formatMessage({ id: 'shipment' })} </span>
                        {shipmentHeaderData && shipmentHeaderData.header && shipmentHeaderData.header.status ? <Chip style={{ backgroundColor: this.getColourforStatus(shipmentHeaderData.header.status) }} className={classes.status} label={shipmentHeaderData.header.status} /> : null}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className={classes.heading} style={{ fontSize: '14px' }}> {intl.formatMessage({ id: 'CMUS_ShipmentsSummary_FileNo' })} {shipmentFileNo} </span>
                        {disableButton ?
                            <span className={classes.heading} style={{ fontSize: '14px' }}> {intl.formatMessage({ id: 'processing' })} </span> : null}
                    </div>
                    <div className={classes.sectionButtonsDesktop}>
                        {/* help section */}
                        <a href={`${window.location.protocol + '//' + window.location.host}${SEARCH_SHIPMENT_HELP}`} target="_blank" rel="noopener noreferrer" style={{ color: "inherit", float: "right" }}>
                            <IconButton className={classes.iconButton}
                                aria-label="account of current user"
                                aria-controls="primary-search-account-menu"
                                aria-haspopup="true"
                                color="inherit"
                            >
                                <HelpIcon className={classes.icon} style={{ color: "#6E787D" }} />
                            </IconButton>
                        </a>
                        {/* end of help section */}
                        <IconButton className={classes.iconButton} disabled={this.state.disableButton ? true : false}>
                            <ReactToPrint
                                trigger={() => <PrintIcon className={classes.icon} />}
                                content={() => this.componentRef}
                                onBeforeGetContent={() => this.getContentRequiredForPrint()}
                                onAfterPrint={() => this.setState({ disableButton: false })}
                            />
                        </IconButton>
                        <CPButton disabled={disableButton ? true : false} variant='primary' styles={{ float: 'right' }} onClick={() => handleDrawer()}>{intl.formatMessage({ id: 'close' })}</CPButton>
                        {this.state.newFileAdded ?
                            <CPButton disabled={this.state.disableButton ? true : false} variant='primary' styles={{ float: 'right' }} onClick={() => this.handleSave()}>SAVE</CPButton> : null}

                    </div>
                </div>
                {showSnackBar ? getSnackBar(snackBarMsg, true, snackBarType, docErrorList) : null}
                <Grid flex='true' container spacing={0}>
                    {
                        shipmentHeaderData === '' || shipmentHeaderData === undefined || shipmentHeaderData === null ? "" :
                            <Grid item  md={2} lg={2} xl={2}  >
                                <div className={classes.sectionDesktop}>
                                    <MenuList list={list} handleOnClickMenuItem={this.handleOnClickMenuItem} />
                                </div>
                            </Grid>
                    }
                    <div style={{ display: 'none' }}>
                        {enablePrint === true ?
                            <PrintShipmentCMUS ref={el => (this.componentRef = el)} printClasses={classes} shipmentData={shipmentData} fileType={shipmentFileType} tenant={tenantDetails} />
                            : null}
                    </div>
                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10} className={classes.body} id="scrollGrid">
                        <CPConfirmBox show={!this.state.uploadRequired} message={intl.formatMessage({ id: 'saveDocs' })} handleRejected={() => { this.setState({ showConfirm: false }) }} handleAccepted={() => this.handleConfirmClick()} type="alert" />
                        <div style={{ textAlign: 'left' }} className={classes.errorDiv}> {this.state.uploadError || this.state.uploadError !== '' ? this.state.uploadError : ''}</div>
                        <div className={classes.sectionButtonsMobile}>
                            {/* <CPButton variant='primary' styles={{ float: 'right'}} disabled={this.state.disableButton ? true : false}>
                                            <ReactToPrint
                                                trigger={() => <span >{intl.formatMessage({ id: 'print' })}</span>}
                                                content={() => this.componentRef}
                                            />
                                        </CPButton> */}
                            {/* help section */}
                            <a href={`${window.location.protocol + '//' + window.location.host}${SEARCH_SHIPMENT_HELP}`} target="_blank" rel="noopener noreferrer" style={{ color: "inherit", float: "right" }}>
                                <IconButton style={{ padding: '5px' }}
                                    aria-label="account of current user"
                                    aria-controls="primary-search-account-menu"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <HelpIcon style={{ fontSize: '28px', color: "#6E787D" }} />
                                </IconButton>
                            </a>
                            {/* end of help section */}
                            {this.state.newFileAdded ?
                                <CPButton disabled={this.state.disableButton ? true : false} variant='primary' styles={{ float: 'right' }} onClick={() => this.handleSave()}>{intl.formatMessage({ id: 'save' })}</CPButton> : null
                            }
                            <CPButton disabled={disableButton ? true : false} variant='primary' styles={{ float: 'right',marginRight:'25px' }} onClick={() => handleDrawer()}>{intl.formatMessage({ id: 'close' })}</CPButton>
                        </div>
                        {
                            shipmentHeaderData === '' || shipmentHeaderData === undefined || shipmentHeaderData === null ?
                                <div className={classes.noRecordsDiv}>
                                    <span>{intl.formatMessage({ id: 'no_data' })}</span>
                                </div>
                                :
                                <>
                                    {showHeader ? <div>

                                        {shipmentFileType === 'REGULAR' ?
                                            <Header headerData={shipmentHeaderData} />
                                            : shipmentFileType === 'FTZ' ?
                                                <Admissions headerData={shipmentHeaderData} />
                                                : (shipmentFileType === 'INBOND') ?
                                                    <InBondHeader
                                                        headerData={shipmentHeaderData.header}
                                                        printClasses={classes}
                                                        isPrint={false}></InBondHeader>
                                                    : null
                                        }
                                    </div> : null}
                                    {showDocuments ? <div >
                                        <Box header={intl.formatMessage({ id: 'documents' })} showloading={true}>
                                            <DocumentTable isCMUS={true} data={this.state.documentList
                                                ? this.state.documentList : null} uploadRequired={true} readOnly={false} fromShipment={true}
                                                docTypes={docTypes} fileErrors={fileErrors} fileTypes={fileTypes} fileSize={fileSize}
                                                onUpload={this.handleUploadFile} removeNewDocFunc={this.handleremoveNewDocFunc}
                                                onChangeDocfields={this.onChangeDocumentfields} unId={shipmentFileNo} updatedDocs={totalUpdatedDocs} handleConfirmBox={this.handleConfirmBox}
                                                docErrorHandle={this.getDocDownloadErrorDetails} />
                                        </Box>
                                    </div> : null}
                                    {showBilling ? <BillingSection /> : null}
                                    {showDelivery ? <DeliveryOrdersSection shipmentFileNo={shipmentFileNo} /> : null}
                                    {showMileStone ? <div >
                                        <Box header={intl.formatMessage({ id: 'milestones' })}>
                                            <DetailedMileStoneData data={milestones} milestonesResponse={milestonesResponse} type="milestones" isCMUS={true} handlePageClick={this.getMilestonesData} />
                                        </Box>
                                    </div> : null}
                                    {showInBondConveyance ? <div >
                                        <Box header={intl.formatMessage({ id: 'inBond_Conveyance' })}>
                                            <InBondConveyance data={inBondConveyanceData} />
                                        </Box>
                                    </div> : null}
                                    {showBillOfLading ?
                                        <BillOfLadingSection /> : null}
                                    {showRemarks ? <RemarksCMUS remarksData={remarksData} remarksResponse={this.state.remarksResponse} handlePageClick={this.getShipmentRemarksInfo} /> : null}
                                    {showCommercial ? <CommercialInvoiceSection /> : null}
                                    {showConcurPPTArvl ? <ConcurPPTArrivalSection /> : null}
                                    {showConveyance ? <ConveyancesSection /> : null}
                                </>
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default injectIntl(withStyles(style)(withTenant(DetailedShipment)))

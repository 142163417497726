import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Grid, withStyles } from '@material-ui/core';
import '../../../styles/bar.css';
import { injectIntl } from "react-intl";
import { getStatusClass } from '../../../common/utils';

const style = {
    gridText: {
        fontWeight: '700',
        fontStyle: 'normal',
        marginTop: '1%',
        height: '40px',
        color: '#000000',
        fontFamily: 'Arial Bold, Arial, sans-serif',
        display: 'flex'
    },
    buttonWarpper: {
        padding: '1% 0',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#007ad9'
    },
    ragGreenOuter: {
        background: 'blue'
    }
}

class ShipmentDetails extends Component {
    constructor(props) {
        super(props);
        const { intl } = this.props;
        const statusRenderer =(param)=> {
            const isStatus = param.colDef.field === "currentStatus";
            const currentStatus = isStatus ? param.value ? ` <span class="progressStatus ${getStatusClass(param.value) ?? ""} "></span> ${param.value} `: "" : param.value;
            return currentStatus
         }
        this.state = {
            selectedRow: null,
            columnDefs: [
                { headerName: intl.formatMessage({ id: 'search_shipments_search_shipper' }), field: "shprName", floatingFilter: true, width: '20%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "shprName" },
                { headerName: intl.formatMessage({ id: 'search_shipments_search_consignee' }), field: "csgnName", floatingFilter: true, width: '20%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "csgnName" },
                { headerName: intl.formatMessage({ id: 'search_shipments_search__jobNo' }), field: "jobNo", floatingFilter: true, width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "jobNo" },
                { headerName: intl.formatMessage({ id: 'search_shipment_search_status' }), field: "currentStatus", floatingFilter: true, width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "currentStatus" , cellRenderer:statusRenderer},
                { headerName: intl.formatMessage({ id: 'search_shipments_search_hblNo' }), field: "shpNo", floatingFilter: true, width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "shpNo" },
                { headerName: intl.formatMessage({ id: 'search_shipments_search_mbNo' }), field: "masterNo", width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "masterNo" },
                { headerName: intl.formatMessage({ id: 'search_shipments_search_refNo' }), field: "refNo", width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "refNo" },
                { headerName: intl.formatMessage({ id: 'search_shipments_search_ETD' }), field: "polEtdDate", width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "polEtdDate" },
                { headerName: intl.formatMessage({ id: 'search_shipments_search_ETA' }), field: "podEtaDate", width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "podEtaDate" },
                { headerName: intl.formatMessage({ id: 'shipments_bizType' }), field: "bizType", width: '10%', suppressMenu: true }
            ],
            // Added if shipments are being routed from Inland widget
            IlcolumnDefs: [
                { headerName: intl.formatMessage({ id: 'search_shipments_search_shipper' }), field: "shprName", floatingFilter: true, width: '20%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "shprName" },
                { headerName: intl.formatMessage({ id: 'search_shipments_search_consignee' }), field: "csgnName", floatingFilter: true, width: '20%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "csgnName" },
                { headerName: intl.formatMessage({ id: 'search_shipment_search_status' }), field: "currentStatus", floatingFilter: true, width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "currentStatus" , cellRenderer:statusRenderer},
                { headerName: intl.formatMessage({ id: 'search_shipments_search_shpNo' }), field: "shpNo", floatingFilter: true, width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "shpNo" },
                { headerName: intl.formatMessage({ id: 'search_shipments_search_origin' }), field: "porName", floatingFilter: true, width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "porName" },
                { headerName: intl.formatMessage({ id: 'search_shipments_search_destination' }), field: "devryName", floatingFilter: true, width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "devryName" },
                { headerName: intl.formatMessage({ id: 'search_shipments_search_refNo' }), field: "refNo", width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "refNo" },
                { headerName: intl.formatMessage({ id: 'search_shipments_search_ETD' }), field: "polEtdDate", width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "polEtdDate" },
                { headerName: intl.formatMessage({ id: 'search_shipments_search_ETA' }), field: "podEtaDate", width: '10%', suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "podEtaDate" },
               
            ],
            defaultColDef: {
                flex: 1,
                sortable: true,
                filter: true,
                floatingFilter: true,
            },

        }
    }
    onGridReady = params => {
        this.gridApi = params.api;
    };
    selectedRow = () => {
        const { handleRowClick } = this.props;
        const selectedRows = this.gridApi.getSelectedRows();

        this.setState({
            selectedRow: selectedRows.length > 0 ? selectedRows : null
        },()=>{
            handleRowClick(this.state.selectedRow, 'shipments');
            this.gridApi.deselectAll();
        });
    }
    render() {
        const { filterchange, data,isFromILWidget } = this.props
        const { columnDefs, defaultColDef,IlcolumnDefs } = this.state
        return (
            <Grid item sm={12} lg={12} xl={12} >
                <div
                    className="ag-theme-balham"
                    style={{ height: '410px', width: '100%', backgroundColor: 'rgba(0, 0, 0,0.04)' }} >
                    <AgGridReact
                        columnDefs={isFromILWidget ? IlcolumnDefs :columnDefs}
                        rowData={data}
                        defaultColDef={defaultColDef}
                        rowSelection={'single'}
                        onRowClicked={this.selectedRow}
                        onGridReady={this.onGridReady}
                        headerHeight='40'
                        rowHeight='30'
                        onFilterChanged={filterchange}
                        floatingFiltersHeight='40'
                        tooltipShowDelay={0}
                    >
                    </AgGridReact>
                </div>
            </Grid>)
    }
}

export default injectIntl(withStyles(style)(ShipmentDetails))


import axios from 'axios';
import { getConfig, getHeaders, getHeadersWithUser, logError } from '../common/CpServices';

import {
    CREATE_BOOKING_URL,
    GET_DESTINATIONS_URL,
    GET_TRACKING_DETIALS_URL,GET_TRACKING_STATUS_URL,
    FETCH_SHIPMENTS, FETCH_DOCUMENT,
    GET_SYSTEM_CODES, GET_COUNTRIES,
    GET_PARTIES, GET_CITIES, GET_CONTAINERTYPES, FETCH_BOOKINGS,
    FETCH_BOOKINGS_WIDGETS, FETCH_SHIPMENTS_WIDGETS_EXPORT_DUE, FETCH_SHIPMENTS_WIDGETS_IMPORT_DUE,
    FETCH_SHIPMENTS_WIDGETS_BY_COUNTRY, FETCH_QUOTES_WIDGETS_EXPIRING,
    CATCH_EVITICTION_BOOKINGS, CATCH_EVITCITON_SHIPMENTS, FETCH_QUOTES, CREATE_QUOTE_URL, EVICT_SHIP_CONSIGNEE_CACHE,
    FETCH_QUOTES_WIDGETS, CATCH_EVITCITON_QUOTES, FETCH_FILE_FILTERS, EDOC_UPLOAD_TT, CREATE_TEMPLATE_URL, FETCH_BOOKING_TEMPLATES,
    GET_BOOKING_TEMPLATE_DATA,
    GET_CUSTOMERS, FAVORITE_SHIPMENT_CONTAINERS, GET_CONTAINER_DETAILS,PREFERENCES_URL,CONCURRENT_USERCOUNT_URL,FETCH_SHIPMENTS_WIDGETS_BY_STATUS
} from './serviceURLS'

export const getTrackingDetails = (variable, value, type) => {
    const { ipAddress, gatewayport } = getConfig()
    let URL = ';'
    const fields = '&Fields=*'
    if (type === 'fromTable' || type === 'reloadFromShipment') {
        URL = `${ipAddress}:${gatewayport}${GET_TRACKING_DETIALS_URL}/${value}`

    } else {
        let pageIndex = ''
        if (type === 'trachShpmt' && variable === 'RefNos') {
            pageIndex = '&PageIndex=-1'
        }
        URL = `${ipAddress}:${gatewayport}${FETCH_SHIPMENTS}${variable}=${value}${pageIndex}${fields}`;
    }
    return axios.get(URL, { 'headers': getHeaders() })
        .then(res => {
            return res
        }).catch(error => {
            logError(error, URL);
            return error.response;
        });
}

export const fetchShipmentDetails = (data, index) => {
    const { ipAddress, gatewayport } = getConfig()
    let quesrString = data && data.shpNos ? `ShpNos=${data.shpNos}&PageIndex=${index}&MaxResults=100` : 'MaxResults=100&PageIndex=' + index;
    if (data && data.shipper) {
        quesrString = quesrString + "&shipper=" + data.shipper
    }
    if (data && data.consignee) {
        quesrString = quesrString + "&consignee=" + data.consignee
    }
    if (data && data.notify) {
        quesrString = quesrString + "&notify=" + data.notify
    }
    if (data && data.jobNo && data.jobNo.trim().length > 0) {
        quesrString = quesrString + "&jobNo=" + data.jobNo
    }
    if (data && data.hblNo && data.hblNo.trim().length > 0) {
        quesrString = quesrString + "&shpNo=" + data.hblNo
    }
    if (data && data.mblNo && data.mblNo.trim().length > 0) {
        quesrString = quesrString + "&masterNo=" + data.mblNo
    }
    if (data && data.ctnNo && data.ctnNo.trim().length > 0) {
        quesrString = quesrString + "&containerNo=" + data.ctnNo
    }
    if (data && data.refNo && data.refNo.trim().length > 0) {
        quesrString = quesrString + "&refNos=" + data.refNo
    }
    if (data && (data.etdFrom || data.etdTo)) {
        quesrString = quesrString + "&etdNoOfDaysAgo=" + (data.etdFrom ? data.etdFrom : 0) + '&etdNoOfDaysAhead=' + (data.etdTo ? data.etdTo : 0)
    }
    if (data && (data.etaFrom || data.etaTo)) {
        quesrString = quesrString + "&etaNoOfDaysAgo=" + (data.etaFrom ? data.etaFrom : 0) + '&etaNoOfDaysAhead=' + (data.etaTo ? data.etaTo : 0)
    }
    if (data && data.createDateNoOfDaysAgo) {
        quesrString = quesrString + "&createDateNoOfDaysAgo=" + data.createDateNoOfDaysAgo
    }
    if (data && data.pol) {
        quesrString = quesrString + "&polCtry=" + data.pol
    }
    if (data && data.polCity) {
        quesrString = quesrString + "&polCity=" + data.polCity
    }
    if (data && data.pod) {
        quesrString = quesrString + "&podCtry=" + data.pod
    }
    if (data && data.podCity) {
        quesrString = quesrString + "&podCity=" + data.podCity
    }

    if (data && data.polEtdDate) {
        quesrString = quesrString + "&polEtdDate=" + data.polEtdDate + "&createDateNoOfDaysAgo=90"
    }
    if (data && data.podEtaDate) {
        quesrString = quesrString + "&podEtaDate=" + data.podEtaDate + "&createDateNoOfDaysAgo=90"
    }
    if (data && data.podCtry) {
        quesrString = quesrString + "&podCtry=" + data.podCtry + "&createDateNoOfDaysAgo=90"
    }
    if (data && data.bizType) {
        quesrString = quesrString + "&bizType=" + data.bizType
    }
 
    const URL = `${ipAddress}:${gatewayport}${data && data.shpNos ? GET_TRACKING_STATUS_URL : GET_TRACKING_DETIALS_URL}?${quesrString}`;
    return axios.get(URL, { 'headers': getHeaders() })
        .then(res => {
            return res
        }).catch(res => {
            logError(res, URL);
        });
}

export const fetchBookings = (days, params) => {
    const { ipAddress, gatewayport } = getConfig()
    // let url = ''
    let quesrString = '';
    if (days) {
        quesrString = quesrString + (quesrString.length > 0 ? '&' : "") + "CreateDateNoOfDaysAgo=" + days;
    }
    if (params) {
        quesrString = quesrString + (quesrString.length > 0 ? '&' : "") + "BookStatus=" + params
    }
    const URL = `${ipAddress}:${gatewayport}${FETCH_BOOKINGS}?${quesrString}`;
    return axios.get(URL, { 'headers': getHeaders() })
        .then(res => {
            return res.data.bookings
        }).catch(res => {
            logError(res, URL);
        });

}
export const fetchQuotes = (days, params, data) => {
    const { ipAddress, gatewayport } = getConfig()
    let quesrString = '';
    if (days) {
        quesrString = quesrString + (quesrString.length > 0 ? '&' : "") + "CreateDateNoOfDaysAgo=" + days;
    }
    if (params) {
        quesrString = quesrString + (quesrString.length > 0 ? '&' : "") + "QuoteStatus=" + params
    }
    if (data) {
        quesrString = quesrString + (quesrString.length > 0 ? '&' : "") + "ValidTo=" + data + '&CreateDateNoOfDaysAgo=90'
    }
    const URL = `${ipAddress}:${gatewayport}${FETCH_QUOTES}?${quesrString}`;
    return axios.get(URL, { 'headers': getHeaders() })
        .then(res => {
            return res.data.quotes
        }).catch(res => {
            logError(res, URL);
        });

}

export const allBookingsWidgetdata = () => {
    const { ipAddress, gatewayport } = getConfig()
    let url = `${ipAddress}:${gatewayport}${FETCH_BOOKINGS_WIDGETS}`;
    return axios.get(url, { 'headers': getHeadersWithUser() })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, url);
        });
}

export const shipmentWidgetImportDueRecords = (noOfDays) => {
    const { ipAddress, gatewayport } = getConfig()
    let url = `${ipAddress}:${gatewayport}${FETCH_SHIPMENTS_WIDGETS_IMPORT_DUE}${noOfDays}`;
    return axios.get(url, { 'headers': getHeadersWithUser() })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, url);
        });
}

export const shipmentsWidgetExportDueRecords = (noOfDays) => {
    const { ipAddress, gatewayport } = getConfig()
    let url = `${ipAddress}:${gatewayport}${FETCH_SHIPMENTS_WIDGETS_EXPORT_DUE}${noOfDays}`;
    return axios.get(url, { 'headers': getHeadersWithUser() })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, url);
        });
}

export const ShipmentsWidgetByCountry = () => {
    const { ipAddress, gatewayport } = getConfig()
    let url = `${ipAddress}:${gatewayport}${FETCH_SHIPMENTS_WIDGETS_BY_COUNTRY}`;
    return axios.get(url, { 'headers': getHeadersWithUser() })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, url);
        });
}

export const ShipmentsWidgetByStatus = (bizType) => {
    const { ipAddress, gatewayport } = getConfig()
    let url = `${ipAddress}:${gatewayport}${FETCH_SHIPMENTS_WIDGETS_BY_STATUS}${bizType}`;
    return axios.get(url, { 'headers': getHeadersWithUser() })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, url);
        });
}

export const getBookingById = (unid, userRole) => {
    const { ipAddress, gatewayport } = getConfig()
    //let url = `${ipAddress}:${gatewayport}${FETCH_BOOKINGS}${unid}?BizType=${bizType}`;
    let url = `${ipAddress}:${gatewayport}${FETCH_BOOKINGS}${unid}?userRole=${userRole}`;

    return axios.get(url, { 'headers': getHeaders() })
        .then(res => {
            if (res.status !== 200) {
                return null;
            }
            else {
                return res.data
            }
        }).catch(res => {
            logError(res, url);
        });

}

export const getDocumentDownloaded = (row, action) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${FETCH_DOCUMENT}${row.externalID ? row.externalID : row.externalId}?action=${action}`;
    const headers = getHeaders()
    return axios.get(URL, { responseType: 'blob', 'headers': { ...headers, "Accept": 'application/json, application/octet-stream' } })
        .then(res => {
            if (res.status === 200) {
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement('a');
                const contentDisposition = res.headers['content-disposition'];
                const filenameRegex = /(?<=").*(?=")/;
                const match = contentDisposition.match(filenameRegex);
                let filename =  match ?? row.filename;
                
                link.href = url;
                link.setAttribute('download',filename);
                document.body.appendChild(link);
                link.click();
            } else {
                return res;
            }
        }).catch(error => {
            if (error.response.data instanceof Blob) {
                new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.onload = () => {
                        error.response.data = JSON.parse(reader.result);
                        resolve(Promise.reject(error));
                    };
                    reader.onerror = () => {
                        reject(error);
                    };
                    reader.readAsText(error.response.data);
                })
                return Promise.resolve(error.response);
            }
            logError(error, URL);
            return null
        })
}

export const updateShipmentDoc = (reqBody) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${EDOC_UPLOAD_TT}`
    return axios.post(URL, reqBody, { 'headers': getHeadersWithUser() }).then(res => {
        return res
    }).catch(res => {
        logError(res, URL);
        return res
    });
}

export const createEbooking = (reqBody, type) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CREATE_BOOKING_URL}S`
    return axios.post(URL, reqBody, { 'headers': getHeaders() }).then(res => {
        return res
    }).catch(res => {
        logError(res, URL);
        return res
    });
}

export const deleteBooking = (reqBody) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CREATE_BOOKING_URL}D`
    return axios.post(URL, reqBody, { 'headers': getHeaders() }).then(res => {
        return res
    }).catch(res => {
        logError(res, URL);
        return res
    })
}
export const getDestinations = async (country) => {
    const { ipAddress, gatewayport } = getConfig()
    const country1 = country;
    const URL = await `${ipAddress}:${gatewayport}${GET_DESTINATIONS_URL}${country1}`
    return await axios.get(URL, { 'headers': getHeaders() })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, URL);
        })
}
export const fetchSystemCodes = async (type) => {
    const { ipAddress, gatewayport } = getConfig()
    let queryparam = '&PageIndex=-1';
    const URL = `${ipAddress}:${gatewayport}${GET_SYSTEM_CODES}${type}${queryparam}`
    return axios.get(URL, { 'headers': getHeaders() }).then(res => {
        return res.data
    }).catch(res => {
        logError(res, URL);
    })
}
export const fetchCountries = async () => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${GET_COUNTRIES}`
    return axios.get(URL, { 'headers': getHeaders() }).then(res => {
        return res.data
    }).catch(res => {
        logError(res, URL);
        return []
    })
}
export const getCities = async (country, isRoad, isSea, isAir) => {
    const { ipAddress, gatewayport } = getConfig()
    let URL = `${ipAddress}:${gatewayport}${GET_CITIES}?CtryCode=${country}`
    if (isRoad === true) {
        URL = URL + '&IsRoadAccess=true'
    }
    if (isSea !== undefined && isSea) {
        URL = URL + '&IsSeaPort=true'
    }
    if (isAir !== undefined && isAir) {
        URL = URL + '&IsIATACity=true'
    }
    return axios.get(URL, { headers: getHeaders() }).then(res => {
        return res.data.lookupItems
    }).catch(res => {
        logError(res, URL);
        return []
    })

}
export const evictBookingsCache = (widgetFor) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CATCH_EVITICTION_BOOKINGS}${widgetFor}`
    axios.get(URL, { headers: getHeadersWithUser() })
}
export const evictShipmentsCache = (widgetFor) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CATCH_EVITCITON_SHIPMENTS}${widgetFor}`
    axios.get(URL, { headers: getHeadersWithUser() })
}

export const fetchParties = async (party) => {
    const { ipAddress, gatewayport } = getConfig()
    let queryparam = '&PageIndex=-1';
    let anotherparm = '&NeedAddresses=true'
    queryparam = queryparam + anotherparm
    const URL = `${ipAddress}:${gatewayport}${GET_PARTIES}${party}${queryparam}`
    return axios.get(URL, { headers: getHeaders() })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, URL);
        })
}
export const fetchContainerTypes = async () => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${GET_CONTAINERTYPES}`
    return axios.get(URL, { headers: getHeaders() })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, URL);
        })
}

export const evictShipConDataCache = (type) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${EVICT_SHIP_CONSIGNEE_CACHE}?serviceName=parties&cacheAttribute=${type}`
    axios.get(URL, { headers: getHeadersWithUser() })
}


export const quoteActions = async (reqBody) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CREATE_QUOTE_URL}`
    return axios.post(URL, reqBody, { 'headers': getHeaders() }).then(res => {
        return res
    }).catch(res => {
        logError(res, URL);
        return res
    });

}
export const getQuoteById = async (unid, bizType) => {
    const { ipAddress, gatewayport } = getConfig()
    let url = `${ipAddress}:${gatewayport}${FETCH_QUOTES}${unid}?BizType=${bizType}`;
    return axios.get(url, { 'headers': getHeaders() })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, url);
        });
}

export const fetchQuoteWidgetMetadata = () => {
    const { ipAddress, gatewayport } = getConfig()
    let url = `${ipAddress}:${gatewayport}${FETCH_QUOTES_WIDGETS}`;
    return axios.get(url, { 'headers': getHeadersWithUser() })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, url);
        });
}

export const fetchQuoteExpringWidgetMetadata = (noOfDays) => {
    const { ipAddress, gatewayport } = getConfig()
    let url = `${ipAddress}:${gatewayport}${FETCH_QUOTES_WIDGETS_EXPIRING}${noOfDays}`;
    return axios.get(url, { 'headers': getHeadersWithUser() })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, url);
        });
}

export const evictQuotesCache = (widgetFor) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CATCH_EVITCITON_QUOTES}${widgetFor}`
    axios.get(URL, { headers: getHeadersWithUser() })
}

export const fetchFileFilters = async (ownerId) => {
    const { ipAddress, gatewayport } = getConfig()
    let queryparam = '';
    if (ownerId !== null && ownerId !== '' && ownerId !== undefined) {
        queryparam = queryparam + 'OwnerID=' + ownerId + '&';
    }
    queryparam = queryparam + 'RuleCode_Sno=CMCHECKFILESIZE_1,CMCHKFILEEXTENSION_1';
    const URL = `${ipAddress}:${gatewayport}${FETCH_FILE_FILTERS}${queryparam}`
    return axios.get(URL, { 'headers': getHeaders() }).then(res => {
        return res.data
    }).catch(res => {
        logError(res, URL);
    })
}
export const bookingTemplateActions = (reqBody, type) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${CREATE_TEMPLATE_URL}S`
    return axios.post(URL, reqBody, { 'headers': getHeaders() }).then(res => {
        return res
    }).catch(res => {
        logError(res, URL);
        return res
    });
}
export const fetchBookingTemplates = async (days, isLookup) => {
    let quesrString = '';
    if (days) {
        quesrString = quesrString + (quesrString.length > 0 ? '&' : "") + "CreateDateNoOfDaysAgo=" + days;
    }
    if (isLookup) {
        quesrString = quesrString + (quesrString.length > 0 ? '&' : "") + "TemplateStatus=A"
    }
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${FETCH_BOOKING_TEMPLATES}?${quesrString}`
    return axios.get(URL, { headers: getHeaders() }).then(res => {
        return res.data
    }).catch(res => {
        logError(res, URL);
    })
}
export const getBookingTemplateDataById = async (unId) => {
    const { ipAddress, gatewayport } = getConfig()
    let url = `${ipAddress}:${gatewayport}${GET_BOOKING_TEMPLATE_DATA}${unId}`;
    return axios.get(url, { 'headers': getHeaders() }).then(res => {
        return res.data
    }).catch(res => {
        logError(res, url);
    });
}

export const TMFFWidgetEvictCache = () => {
    evictBookingsCache("all");
    evictShipmentsCache("all");
    evictQuotesCache("all");

    evictBookingsCache("all");
    evictShipmentsCache("all");
    evictQuotesCache("all");
}

export const fetchCustomers = async (party, role, getAddressFlag) => {
    const { ipAddress, gatewayport } = getConfig()
    let queryparam = ''
    queryparam = 'PartyID=' + party + '&Name=' + party + '&Role=' + role + (getAddressFlag ? '&NeedAddresses=true' : '') + '&PageIndex=-1'
    const URL = `${ipAddress}:${gatewayport}${GET_CUSTOMERS}?${queryparam}`
    return axios.get(URL, { headers: getHeaders() })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, URL);
        })
}

export const saveOrUpdateFavorites = async (favoriteId, favoriteType, isFavorite) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${FAVORITE_SHIPMENT_CONTAINERS}`
    const reqBody = {
        "favoriteRefId": favoriteId,
        "favoriteType": favoriteType,
        "isFavorite": isFavorite
    }
    return axios.post(URL, reqBody, { 'headers': getHeaders() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    });
}

export const fetchContainerDetails = (intContainerNo) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${GET_CONTAINER_DETAILS}${intContainerNo}`;
    return axios.get(URL, { 'headers': getHeaders() })
        .then(res => {
            return res
        }).catch(error => {
            logError(error, URL);
            return error.response;
        });
}

export const getFavourtieList = () => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${FAVORITE_SHIPMENT_CONTAINERS}`;
    return axios.get(URL, { 'headers': getHeaders() })
        .then(res => {
            return res
        }).catch(error => {
            logError(error, URL);
            return error.response;
        });
}

export const savePreferences = async (reqBody) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${PREFERENCES_URL}`
    return axios.post(URL, reqBody, { 'headers': getHeadersWithUser() }).then(res => {
        return res
    }).catch(res => {
        logError(res, URL);
        return res
    });
}



export const getPreferences = async () => {
    const { ipAddress, gatewayport } = getConfig()
    let url = `${ipAddress}:${gatewayport}${PREFERENCES_URL}${sessionStorage.getItem('userName')}`;
    return axios.get(url, { 'headers': getHeaders() }).then(res => {
        return res
    }).catch(res => {
        logError(res, url);
    });
}

export const getConcurrentUserCount = async () =>{
    const { ipAddress, gatewayport } = getConfig()
    let url = `${ipAddress}:${gatewayport}${CONCURRENT_USERCOUNT_URL}`;
    return axios.get(url, { 'headers': getHeaders() }).then(res => {
        return res
    }).catch(res => {
        logError(res, url);
    });

}

export const getRefCodeFromBizrule = async () => {
    const { ipAddress, gatewayport } = getConfig()
    const queryparam = 'RuleCode_Sno=CPREFCODE_1';
    const URL = `${ipAddress}:${gatewayport}${FETCH_FILE_FILTERS}${queryparam}`
    return axios.get(URL, { 'headers': getHeaders() }).then(res => {
        return res.data
    }).catch(res => {        
        logError(res, URL);
        return null;
    })
}
import { withStyles } from '@material-ui/core';
import React from 'react';
import { injectIntl } from 'react-intl';
import Box from '../../common/Box';
import CpEditableTable from '../../common/CpEditableTable';
import PageNumbers from '../../common/Pagination/PageNumbers';

const styles = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    recordCount: {
        marginLeft: '10px',
        fontSize: '14px',
        fontFamily: 'sans-serif',
        fontWeight: 400,
        fontStyle: 'normal',
        color: '#545F66'
    }
})

function ConcurPPTArrivalList(props) {

    const { handleDisplayForward, concurPPTArvlData, noOfPages, selectedPage, handlePageClick, intl } = props

    const concurPPTArrivalHeaders = [
        { 'width': '10%', 'title': intl.formatMessage({ id: 'seqNo' }), 'field': 'seqNo', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': intl.formatMessage({ id: 'actionQualifier' }), 'field': 'actionQualifier', 'showLabel': true, 'type': 'label', tooltipField: 'actionQualifierDesc' },
        { 'width': '10%', 'title': intl.formatMessage({ id: 'actionCode' }), 'field': 'actionCd', 'showLabel': true, 'type': 'label', tooltipField: 'actionCdDesc' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'idNo' }), 'field': 'idNo', 'showLabel': true, 'type': 'label' },
        { 'width': '18%', 'title': intl.formatMessage({ id: 'pttUniqueId' }), 'field': 'ftzPttUniqueIdentifier', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': intl.formatMessage({ id: 'receivedQuantity' }), 'field': 'receivedQuantity', 'showLabel': true, 'type': 'label' }
    ]

    return (
        <Box showloading={true} header={intl.formatMessage({ id: 'concur/ppt/arrival' })}>
            <div>
                <CpEditableTable headers={concurPPTArrivalHeaders} readOnly={true} data={concurPPTArvlData ? concurPPTArvlData : []} navigateIcon={true} handleDisplayForward={(row, index) => handleDisplayForward(1, row, index)} />
            </div>
            <PageNumbers noOfPages={noOfPages} selected={selectedPage} onClick={(index) => handlePageClick(index, 0)} />
        </Box>
    );
}

export default injectIntl(withStyles(styles)(ConcurPPTArrivalList))

import React, { useRef, useState } from 'react'
import { withStyles, Checkbox, IconButton, CardContent, Card } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CpDropDown from './CpDropDown';
import { getDocumentDownloaded } from '../../sevices/TMFF/Services';
import withLoading from './withLoading';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { injectIntl } from "react-intl";
import CPConfirmBox from './CPConfirmBox';
import withTenant from './withTenant';
import CPToolTip from './CPToolTip';

const style = theme => ({
    linkbutton: {
        color: '#009CDE',
        paddingLeft: '1%',
        cursor: 'pointer'
    },
    noRecords: {
        minHeight: '10vh',
        display: ' flex !important',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonWarpper: {
        padding: '1%',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#007ad9'
    },
    errorWarpper: {
        padding: '0.5%',
        fontWeight: 'bold',
        color: '#007ad9',
        overflowWrap: 'anywhere'
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    checkBox: {
        root: {
            "&checked": {
                color: '#007ad9'
            }
        },
        checked: {
            color: '#007ad9'
        }
    },
    iconButton: {
        marginLeft: '8px',
        cursor: 'pointer',
        padding: '0px',
        '&:disabled': {
            color: '#545F66',
            cursor: 'not-allowed'
        }
    },
    Download: {
        color: '#007ad9'
    },
    Delete: {
        color: 'red'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    root: {
        minWidth: 275,
        width: '98%',
        margin: '1%'
    },
    cardRoot: {
        padding: '0 0 0 0 !important',
        marginBottom: '10px !important'
    },
    line: {
        margin: 0,
        display: 'flex'
    },
    titleNormal: {
        fontWeight: 'Normal',
        width: '20%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important',
        justifyContent: 'center',
        alignItems: 'center'
    },
    value: {
        width: '80%',
        display: 'inline-block',
        fontSize: '12px !important'
    },
    odd: {
        backgroundColor: 'rgba(237, 239, 239, 1)'
    },
    even: {
        backgroundColor: 'white'
    },
    noRecordsFound: {
        padding: '2% !important',
        textAlign: 'center'
    }
})
const DocumentsTableMobile = (props) => {
    const { data, classes, onUpload, removeNewDocFunc, enableIcons, enableIconsForDocs, onChangeDocfields, handleDeleteExistingDocs, uploadRequired, intl, readOnly, fromShipment, unId, updatedDocs, handleConfirmBox, fileTypes, fileSize, fileErrors, docErrorHandle } = props

    const inputFile = useRef(null);
    const [openDialog, setopenDialog] = useState(false)
    const handleClick = () => {
        inputFile.current.click();
    }
    //const inputFileedit = useRef(null)
    // const handleClickedit = () => {
    //     inputFileedit.current.click();
    // }
    const getDescription = (row) => {
        const { docTypes } = props;

        const docDesc = docTypes.length > 0 ?
            docTypes.filter((item) => {
                return item.value === row.type
            }) : []
        return docDesc.length > 0 ? docDesc[0].label : '';
    }
    const download = async (event, from, row) => {
        if (from === 'icons') {
            let selectedrow = data.map(document => {
                return (document.selected === true ? document : null)
            })
            selectedrow = selectedrow.filter(row => {
                return row !== null
            })
            row = selectedrow[0]
        }
        const { loading, intl } = props
        event.stopPropagation();
        loading.setCustomMsg(intl.formatMessage({ id: 'downloading' }));
        const res = await getDocumentDownloaded(row, 'download');
        loading.setCustomMsg(null);
        if (res) {
            if (res.status === 204) {
                docErrorHandle(intl.formatMessage({ id: 'docDownloadNotAvailable' }), res.status)
            } else if (res.status !== 200) {
                docErrorHandle(res.data && res.data.fieldErrors ? res.data.fieldErrors[0] : intl.formatMessage({ id: 'somethingWentWrong' }), res.status)
            }
        }
    }
    const getIcon = (row) => {
        if (readOnly === true) {
            return (
                <AttachFileIcon fontSize='large' style={{ color: '#007ad9', fontSize: '25px', marginLeft: '8px', cursor: 'pointer' }} onClick={(event) => { event.preventDefault(); download(event, 'row', row) }} />
            );
        }
        else {
            switch (row.action) {
                case "new":
                    return (<RemoveCircleOutlineIcon style={{ color: 'red', fontSize: '25px', marginLeft: '8px' }} onClick={() => removeNewDocFunc(row.docSNo)} />)
                case "existing":
                    return (
                        <Checkbox checked={row.selected}
                            color='default'
                            classes={{ root: classes.checkBox.root, checked: classes.checkBox.checked }}
                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '25px' }} />}
                            checkedIcon={<CheckBoxIcon style={{ fontSize: '25px' }} />}
                            onChange={(event) => enableIconsForDocs(event, row.docSNo)} />
                    )
                default:
                    //Uncomment below piece in order to make use of delete and download file with checkbox
                    //
                    // if (props.fromShipment) {
                    //     return (
                    //         <Checkbox checked={row.selected}
                    //             color='default'
                    //             classes={{ root: classes.checkBox.root, checked: classes.checkBox.checked }}
                    //             icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '25px' }} />}
                    //             checkedIcon={<CheckBoxIcon style={{ fontSize: '25px' }} />}
                    //             onChange={(event) => enableIconsForDocs(event, row.externalID)} />
                    //     )
                    // } else {
                    return (
                        <AttachFileIcon fontSize='large' style={{ color: '#007ad9', fontSize: '25px', marginLeft: '8px', cursor: 'pointer' }} onClick={(event) => { event.preventDefault(); download(event, 'row', row) }} />
                    );
            }
        }


    }
    const getinput = (row) => {
        return <CpDropDown label={intl.formatMessage({ id: 'docDescription' })} id={'description' + row.docSNo} name="description" value={row["description"]} items={props.docTypes} onChange={(event) => onChangeDocfields(row['docSNo'], event.target.value)} />
    }
    const onDeleteDoc = () => {
        setopenDialog(false)
        handleDeleteExistingDocs()
    }
    const voidHandler = () => {
        setopenDialog(false)
    }
    return (
        <>

            <CPConfirmBox show={openDialog} message={intl.formatMessage({ id: "deleteDocMsg" })} handleRejected={voidHandler} handleAccepted={onDeleteDoc} />
            {uploadRequired && readOnly !== true ?
                <div className={classes.mainDiv}>
                    {fromShipment ?
                        unId !== undefined && unId !== null && unId !== '' ?
                            <div className={classes.buttonWarpper}>
                                <input type='file' id='addfile' ref={inputFile} accept={`${fileTypes}`} style={{ display: 'none' }} onChange={(event) => onUpload(event, "add")} />
                                <CPToolTip title={`Allowed file size is up to ${fileSize}B and types are "${fileTypes.replace(/\./g, '')}"`}>
                                    <span style={{ cursor: 'pointer' }} onClick={() => { updatedDocs < 5 ? handleClick() : handleConfirmBox() }}>{intl.formatMessage({ id: "uploadDocument" })}</span>
                                </CPToolTip>
                            </div>
                            : null
                        : <div className={classes.buttonWarpper}>
                            <input type='file' id='addfile' ref={inputFile} accept={`${fileTypes}`} style={{ display: 'none' }} onChange={(event) => onUpload(event, "add")} />
                            <CPToolTip title={`Allowed file size is up to ${fileSize}B and types are "${fileTypes.replace(/\./g, '')}"`} >
                                <span style={{ cursor: 'pointer' }} onClick={handleClick}>{intl.formatMessage({ id: "uploadDocument" })}</span>
                            </CPToolTip>
                        </div>
                    }
                    {fromShipment ?
                        null :
                        <div style={{ padding: '0.5%' }}>
                            {/* <input type='file' id='file' ref={inputFileedit} style={{ display: 'none' }} onChange={(event) => onUpload(event, "edit")} />
                            <EditIcon style={{ color: '#007ad9', fontSize: '20px', marginLeft: '8px', cursor: 'pointer' }} onClick={handleClickedit} /> */}
                            <IconButton id="downloadDoc" disabled={enableIcons ? false : true} className={`${classes.iconButton} ${classes.Download}`} onClick={(event) => { event.preventDefault(); download(event, 'icons') }} >
                                <GetAppIcon color={enableIcons ? 'secondary' : 'disabled'} style={{ fontSize: '20px', paddingTop: '2px' }} />
                            </IconButton>
                            <IconButton id="DeleteDoc" disabled={enableIcons ? false : true} className={`${classes.iconButton} ${classes.Delete}`} onClick={() => setopenDialog(true)}>
                                {/* onClick={onDeleteDoc} */}
                                <DeleteIcon color={enableIcons ? 'secondary' : 'disabled'} style={{ fontSize: '20px' }} />
                            </IconButton>
                        </div>

                    }
                    <div className={classes.errorWarpper}>
                        {fileErrors.invalidType === true ? (<span style={{ color: 'red ' }}>{intl.formatMessage({ id: "invalidFileType" })}{fileTypes.replace(/\./g, '')}{intl.formatMessage({ id: "types" })}</span>) :
                            fileErrors.limitExceded === true ? (<span style={{ color: 'red ' }}>{intl.formatMessage({ id: "fileSizeExceded" })}{fileSize}B</span>) : null}
                    </div>
                </div>
                : null
            }

            <Card >
                {data ?
                    data.map((row, index) => {
                        return (
                            row.actionCode !== 'D' ?
                                <CardContent key={index} className={`${classes.cardRoot}`}>
                                    <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                        <div className={classes.titleNormal}> <span >{getIcon(row)}</span></div>
                                        <div className={classes.value}>
                                            <div>{row.enablefields ? getinput(row) : getDescription(row)}</div>
                                            <div style={{ overflowWrap: 'anywhere' }}>{row.fileName}
                                                <div>{row.hasOwnProperty("uploadDate") ? row.uploadDate : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                                : null)
                    })
                    :
                    readOnly === true ?
                        <CardContent className={classes.noRecordsFound}>{intl.formatMessage({ id: 'noRecordsFound' })}</CardContent>
                        :
                        null
                }
            </Card>
        </>
    )
}

export default injectIntl(withTenant(withLoading(withStyles(style)(DocumentsTableMobile))))
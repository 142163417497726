import React, { Component } from 'react'
import { evictShipmentsCache, ShipmentsWidgetByCountry } from '../../../sevices/TMFF/Services';
import PieChart from '../../common/PieChart';

export class ShipmentCountryWidget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shipmentsByCountryMetaData: [],
            loading: true
        }
    }
    componentDidMount = async () => {
        const { location } = this.props;
        const Widgets = location.state ? location.state.widgets : true
        if (Widgets) {
            const shipmentData = await ShipmentsWidgetByCountry();
            await this.setState({
                shipmentsByCountryMetaData: shipmentData && shipmentData.pieChartWidgetDetails ? shipmentData.pieChartWidgetDetails : [],
                loading: false
            })
        }
    }
    handleShipments = (event) => {
        const { history, CpError, intl } = this.props;
        const country = event.data.name
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
        let header = { [intl.formatMessage({ id: 'shipments_by_country' })]: country }
        history.push({
            pathname: '/shipments',
            state: {
                data: {
                    podCtry: country
                },
                heading: header
            }
        })
    }
    handleRefresh = async () => {
        //Need to remove one evict method for PROD
        //Using two evict for Pre prod
        evictShipmentsCache("podctry");
        evictShipmentsCache("podctry");
        this.setState({
            loading: true
        })
        const shippingMetaData = await ShipmentsWidgetByCountry()
        this.setState({
            shipmentsByCountryMetaData: shippingMetaData && shippingMetaData.pieChartWidgetDetails ? shippingMetaData.pieChartWidgetDetails : [],
            loading: false
        })
    }

    render() {
        const { intl } = this.props;
        const { shipmentsByCountryMetaData, loading } = this.state
        return (
            // this vertical bar chart can be used once widget api is enhanced
            // <BarChartVertical heading={intl.formatMessage({ id: 'shipments_by_country' })}
            //     chartType="shipmentsByCountry" data={shipmentsByCountryMetaData}
            //     handleClick={this.handleShipments}
            //     loading={loading}
            //     handleRefresh={this.handleRefresh} />
            <PieChart heading={intl.formatMessage({ id: 'shipments_by_country' })}
                chartType="shipmentsByCountry" data={shipmentsByCountryMetaData}
                handleClick={this.handleShipments}
                loading={loading}
                handleRefresh={this.handleRefresh} />
        )
    }
}

export default ShipmentCountryWidget

import React from 'react'
import { ErrorConsumer } from '../../common/error';
const withError = (Component) => {
    return (props) => (
        <ErrorConsumer>
            {(error) =>
                <Component {...props} CpError={error} />
            }
        </ErrorConsumer>
    )
}

export default withError;
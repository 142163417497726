import { Link, Typography } from "@material-ui/core";
import { injectIntl } from "react-intl";
import Box from "../../common/Box";
import CpEditableTable from "../../common/CpEditableTable";
import NonEditableDataGrid from "../../common/NonEditableDataGrid";
import CpBreadcrumb from "../../common/CpBreadcrumb";

function BillOfLadingCommodity(props) {
    const commodityHeader = [
        { title: "Line No.", field: "seqNo" },
        { title: "Part No.", field: "styleNo" },
        { title: "Assembler", field: "assembler" },
        { title: "Tariff No.", field: "tariffNo" },
        { title: "Value US$", field: "valueInBond" },
        { title: "Value Foreign/currency", field: "valueForeignCurrency" },
        { title: "Exchange Rate", field: "exchangeRateAmt" },
        { title: "Pieces", field: "piecesUnitOfMeasure" },
        { title: "Weight/UOM", field: "weightUom" },
        { title: "Commodity Description", field: "cargoDesc" }
    ]

    const priorNoticeHeader = [
        { title: "Product Code", field: "productCode" },
        { title: "Product Description", field: "productDescription" },
        { title: "Affirmation of Compliance", field: "affirmationOfCompliance" },
        { title: "Compliance Qualifier", field: "complianceQualifier" },
        { title: "Prior Notice Confirm. No.", field: "priorNoticeConfirmNo" }
    ]

    const pgaPartiesHeader = [
        { 'width': '10%', 'title': 'Party Role', 'field': 'partyRole', 'showLabel': true, 'type': 'label', tooltipField: "partyRoleDesc" },
        { 'width': '25%', 'title': 'Party Name', 'field': 'partyName', 'showLabel': true, 'type': 'label' },
        { 'width': '65%', 'title': 'Party Address', 'field': 'address', 'showLabel': true, 'type': 'label', tooltipField: "address" },
    ]

    const { intl, handleDisplayBack, l1totalRecords, l1selectedRecord, l2totalRecords, l2selectedRecord, l3totalRecords, l3selectedRecord, bolCmdtyData } = props

    return (
        <div>
            <div style={{ display: 'flex', margin: '2rem 0 0 2rem' }}>
                <CpBreadcrumb>
                    <Link color='inherit' onClick={() => handleDisplayBack(1)}> {intl.formatMessage({ id: 'bol' }, { val1: l1selectedRecord, val2: l1totalRecords })} </Link>
                    <Link color='inherit' onClick={() => handleDisplayBack(2)}> {intl.formatMessage({ id: 'containerValues' }, { val1: l2selectedRecord, val2: l2totalRecords })} </Link>
                    <Typography color='textPrimary'>{intl.formatMessage({ id: 'commodityValues' }, { val1: l3selectedRecord, val2: l3totalRecords })}</Typography>
                </CpBreadcrumb>
            </div>
            <Box showloading={true} header={intl.formatMessage({ id: 'commodityValues' }, { val1: l3selectedRecord, val2: l3totalRecords })} >
                <NonEditableDataGrid list={commodityHeader} data={bolCmdtyData ? bolCmdtyData : {}} />
            </Box>
            <Box header={intl.formatMessage({ id: 'priorNotice' })}>
                <NonEditableDataGrid list={priorNoticeHeader} data={bolCmdtyData && bolCmdtyData.priorNotice ? bolCmdtyData.priorNotice : {}} />
            </Box>
            <Box header={intl.formatMessage({ id: 'pgaParties' })}>
                <CpEditableTable headers={pgaPartiesHeader} readOnly={true} data={(bolCmdtyData && bolCmdtyData.pgFdaIbList && bolCmdtyData.pgFdaIbList[0] && bolCmdtyData.pgFdaIbList[0].pgFdaIbPartiesList) ? bolCmdtyData.pgFdaIbList[0].pgFdaIbPartiesList : []} />
            </Box>
        </div>
    );
}

export default injectIntl(BillOfLadingCommodity)
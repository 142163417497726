import { Badge, IconButton, Step, StepConnector, StepLabel, Stepper, styled, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { evictBookingsCache, allBookingsWidgetdata } from '../../../sevices/TMFF/Services'
import { ReactComponent as LoadingIcon } from '../../../../src/images/loading.svg'
import RefreshIcon from '@material-ui/icons/Refresh';
import Send from '@material-ui/icons/Send';
import Edit from '@material-ui/icons/Edit';
import { voidHandler } from '../../../common/utils';

const style = () => ({
    topDiv: {
        display: 'flex'
    },
    noDataDiv: {
        height: '24rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Open Sans Italic',
        fontStyle: 'italic'
    },
    stepLabel: {
        fontSize: '14px !important',
        cursor: 'pointer',
        "&.MuiStepLabel-label.MuiStepLabel-completed": {
            textTransform: 'uppercase'
        }
    },
    step: {
        width: 'fit-content'
    },
    stepIconImg: {
        fontSize: '2.5rem',
        color: 'white'
    },
    stepper: {
        marginTop: '40px',
        padding: '20px'
    },
    header: {
        marginTop: '10px',
        marginLeft: 'auto',
        fontWeight: '600'
    },
    refreshIcon: {
        marginTop: '10px',
        marginLeft: 'auto',
        cursor: 'pointer',
        marginRight: '8px'
    },
    loadingDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '25rem',
        flexDirection: 'column'
    }
});

const StyledBadge = styled(Badge)(() => ({
    "& .MuiBadge-badge": {
        left: 12,
        top: -35,
        fontWeight: "bold !important",
        fontSize: '20px !important',
        color: 'default',
    }
}));
const Styledconnector = styled(StepConnector)(() => ({
    marginTop: '10px',
    padding: '0px',
    "& .MuiStepConnector-lineVertical": {
        minHeight: '45px',
    }
}));
export class BookingsWidget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bookingStatusData: [],
            loading: true
        }
    }
    componentDidMount = async () => {
        const { location } = this.props;
        const Widgets = location.state ? location.state.widgets : true
        if (Widgets) {
            this.prepareWidgetData()
        }
    }
    prepareWidgetData = async () => {
        let bookingData = await allBookingsWidgetdata();
        this.setState({
            bookingStatusData: bookingData ? bookingData : [],
            loading: false
        })
    }
    handleBookingClick = async (status) => {
        const { history, CpError } = this.props
        let header = {}
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
        header = { "status": status }
        history.push({
            pathname: '/bookings',
            state: {
                heading: header,
                fromDashBoard: true
            }
        })
    }
    handleRefresh = async () => {
        //Need to remove one evict method for PROD
        //Using two evict for Pre prod
        await this.setState({ loading: true })
        await evictBookingsCache("bookstatus");
        await evictBookingsCache("bookstatus");
        this.prepareWidgetData();
    }
    render() {
        const { intl, classes } = this.props;
        const { bookingStatusData, loading } = this.state
        const { inProgress, submitted } = this.state.bookingStatusData
        return (
            <div>
                {loading ?
                    <div className={classes.loadingDiv} >
                        <LoadingIcon />
                        <span>Loading...</span>
                    </div> :
                    <>
                        <div className={classes.topDiv} >
                            <span className={classes.header}>{intl.formatMessage({ id: 'all_Bookings' })}</span>
                            <RefreshIcon className={classes.refreshIcon} onClick={this.handleRefresh} fontSize="large" color="primary" />
                        </div>
                        {
                            bookingStatusData ?
                                <>
                                    <Stepper activeStep={2}
                                        alternativeLabel
                                        connector={<Styledconnector />}
                                        className={classes.stepper}
                                    >
                                        <Step key="inProgress" className={classes.step} onClick={() => { inProgress ? this.handleBookingClick("In Progress") : voidHandler() }}>
                                            <StepLabel classes={{ label: classes.stepLabel }}
                                                icon={
                                                    <IconButton
                                                        style={{ backgroundColor: "#EFB741" }}
                                                        aria-label="cart" >
                                                        <StyledBadge anchorOrigin={{ vertical: "top", horizontal: "left" }} badgeContent={inProgress ? inProgress : '0'}>
                                                            <Edit className={classes.stepIconImg} />
                                                        </StyledBadge>
                                                    </IconButton>
                                                }
                                            >
                                                {intl.formatMessage({ id: "inProgress" })}
                                            </StepLabel>
                                        </Step>
                                        <Step key="submitted" className={classes.step} onClick={() => { submitted ? this.handleBookingClick("Submitted") : voidHandler() }}>
                                            <StepLabel classes={{ label: classes.stepLabel }}
                                                icon={
                                                    <IconButton
                                                        style={{ backgroundColor: "#5F7298" }}
                                                        aria-label="cart" >
                                                        <StyledBadge anchorOrigin={{ vertical: "top", horizontal: "left" }} badgeContent={submitted ? submitted : '0'}>
                                                            <Send className={classes.stepIconImg} />
                                                        </StyledBadge>
                                                    </IconButton>
                                                }
                                            >
                                                {intl.formatMessage({ id: "submitted" })}
                                            </StepLabel>
                                        </Step>
                                    </Stepper>
                                </>
                                :
                                <div className={classes.noDataDiv}>{intl.formatMessage({ id: 'no_data' })}</div>
                        }
                    </>
                }
            </div>
        )
    }
}

export default injectIntl(withStyles(style)(BookingsWidget))

import React from 'react'
import CpEditableTable from '../../common/CpEditableTable';
import Box from '../../common/Box'
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import PageNumbers from '../../common/Pagination/PageNumbers';

const styles = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    recordCount: {
        marginLeft: '10px',
        fontSize: '14px',
        fontFamily: 'sans-serif',
        fontWeight: 400,
        fontStyle: 'normal',
        color: '#545F66'
    }
})

function RemarksCMUS(props) {

    const { remarksData, intl, classes, remarksResponse, handlePageClick } = props;

    const remarksHeaders = [
        { 'width': '65%', 'title': intl.formatMessage({ id: 'Note' }), 'field': 'note', 'showLabel': true, 'type': 'label' },
        { 'width': '35%', 'title': intl.formatMessage({ id: 'updated_date_time' }), 'field': 'updatedDateTime', 'showLabel': true, 'type': 'label' }
    ]
    return (
        <Box header={intl.formatMessage({ id: 'remarksCMUS' })}>
            <div style={{ height: '30px', display: 'flex', alignItems: "center" }}> <span className={classes.recordCount}>{intl.formatMessage({ id: 'shipments_noOfRecords' })} : {remarksData ? remarksData.length : "0"}</span></div>
            <CpEditableTable headers={remarksHeaders} readOnly={true} data={remarksData ? remarksData : []} />
            <div >
                <PageNumbers noOfPages={remarksResponse.noOfPages} selected={remarksResponse.selectedPage} onClick={handlePageClick} />
            </div>
        </Box>
    )
}

export default injectIntl(withStyles(styles)(RemarksCMUS))
import React from 'react';
import { Card, CardContent, withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
const style = theme => ({
    root: {
        width: '31%',
        margin: '1.5%',
        paddingBottom: '2%',
        marginLeft: '7px'
    },
    heading: {
        fontWeight: 'bold',
        paddingBottom: '10px'
    },
    wrapper: {
        display: "flex",
        flexWrap: "wrap",
        paddingTop: '8px'
    },
    nodata: {
        paddingLeft: '400px',
        paddingTop: '5px',
        border: "1px solid lightgrey",
        width: '99%',
        marginLeft: '5px',
        marginTop: '5px'
    },
    title: {
        maxHeight: '40px',
        maxWidth: '100%',
        fontSize: '18px',
        fontWeight: 'bold   ',
        color: '#4A4E50',
        padding: '5px',
        borderBottom: '1px solid #C8CFD2',
        textTransform: 'capitalize',
        display: 'flex',
        width: '100%'
    },
    name: {
        paddingBottom: '0',
        fontWeight: 'bold'
    },
    noRecordsFound: {
        minHeight: '10vh',
        fontStyle: 'italic',
        padding: '6px 24px 6px 16px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        fontFamily: 'Open Sans Italic',
        width: '100%'
    }
});

function Cards(props) {
    const { classes, intl, isInbond } = props
    const pgaData = props.data
    const isCardContentNotEmpty = (pgaData, index) => {
        if ((pgaData[index].partyName && pgaData[index].partyName !== '') || (pgaData[index].partyRoleDesc && pgaData[index].partyRoleDesc !== '') || (pgaData[index].partyAddressLine1 && pgaData[index].partyAddressLine1 !== '') || (pgaData[index].partyAddressLine2 && pgaData[index].partyAddressLine2 !== '') ||
            (pgaData[index].partyCity && pgaData[index].partyCity !== '') || (pgaData[index].partyCountry && pgaData[index].partyCountry !== '') || (pgaData[index].partyStateProvince && pgaData[index].partyStateProvince !== '') ||
            (pgaData[index].partyPostalCd && pgaData[index].partyPostalCd !== '') || (pgaData[index].name && pgaData[index].name !== '') || (pgaData[index].address1 && pgaData[index].address1 !== '') ||
            (pgaData[index].address2 && pgaData[index].address2 !== '') || (pgaData[index].address3 && pgaData[index].address3 !== '') || (pgaData[index].address4 && pgaData[index].address4 !== '')) {
            return true;
        }
        return false;
    }
    return (
        <div className={classes.wrapper}>
            {pgaData && pgaData.length ?
                pgaData.map((el, index) => {
                    return (
                        isCardContentNotEmpty(pgaData, index) === true ?
                            <Card key={index} className={classes.root}>
                                <span>
                                    {isInbond ?
                                        <CardContent>
                                            <div className={classes.heading}>{pgaData[index].partyName}
                                            </div>
                                            <div>{pgaData[index].name}
                                            </div>
                                            <div>{pgaData[index].address1}
                                            </div>
                                            <div>{pgaData[index].address2}
                                            </div>
                                            <div>{pgaData[index].address3}
                                            </div>
                                            <div>{pgaData[index].address4}
                                            </div>
                                        </CardContent> :
                                        <CardContent>
                                            <div className={classes.heading}>{pgaData[index].partyRoleDesc} ({pgaData[index].partyRole})
                                            </div>
                                            <div className={classes.name}>{pgaData[index].partyName}
                                            </div>
                                            <div>{pgaData[index].partyAddressLine1}
                                            </div>
                                            <div>{pgaData[index].partyAddressLine2}
                                            </div>
                                            <div>{[pgaData[index].partyCity, pgaData[index].partyStateProvince].filter(Boolean).join(', ')}
                                            </div>
                                            <div>{[pgaData[index].partyCountry, pgaData[index].partyPostalCd].filter(Boolean).join(', ')}
                                            </div>
                                        </CardContent>}
                                </span>
                            </Card>
                            :
                            <>
                                <div className={classes.title}>{intl.formatMessage({ id: 'partyList' })}</div>
                                <div className={classes.noRecordsFound}>
                                    {pgaData === null ?
                                        <span>{intl.formatMessage({ id: 'data_Loading' })}</span> : <span>{intl.formatMessage({ id: 'noContentFound' })} </span>}
                                </div>
                            </>
                    )
                })
                :
                <>
                    <div className={classes.title}>{intl.formatMessage({ id: 'partyList' })}</div>
                    <div className={classes.noRecordsFound}>
                        {pgaData === null ?
                            <span>{intl.formatMessage({ id: 'data_Loading' })}</span> : <span>{intl.formatMessage({ id: 'noContentFound' })} </span>}
                    </div>
                </>
            }
        </div>
    )
}

export default injectIntl(withStyles(style)(Cards))
import React from 'react'
import Box from '../../common/Box';
import { injectIntl } from "react-intl";
import DetailedMileStoneData from './DetailedMileStoneData'
import { Table, TableHead, TableRow, TableCell, withStyles, TableBody, IconButton, Collapse } from '@material-ui/core';
import ContainersViewMobile from './ContainersViewMobile';
import { ReactComponent as PushPinRoundedIcon } from '../../../images/pushpin.svg'
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';

const style = theme => ({
    tableHeader: {
        fontWeight: 'bold !important',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        borderBottom: '4px solid #CDE3E9',
        height: '54px',
        padding: '6px 6px 6px 10px'
    },
    tabletr: {
        display: ' table !important',
        width: '100%',
        fontSize: '14px',
        tableLayout: 'fixed',
        margin: 0,
        fontFamily: "'Open Sans', Arial, sans-serif !important",
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        '&:nth-child(even)': {
            backgroundColor: '#F3F7F9'
        }
    },
    tableCell: {
        fontSize: '14px',
        border: '1px solid #DAE7EA',
        height: '48px',
        padding: '6px 6px 6px 10px',
        borderLeft: '0px'
    },
    noRecords: {
        minHeight: '10vh',
        fontStyle: 'italic',
       
    },
    noRecordscell: {
        fontSize: '14px',
        fontFamily: 'Open Sans Italic'
    },
    buttonWarpper: {
        padding: '1%',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#007ad9'
    },
    sectionDesktop: {
        display: 'none',
        margin: '1% 0.5%',
        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    iconButton: {
        padding: '8%'
    },
    icons: {
        fontSize: '20px',
        width: '20px',
        height: '20px',
        color: '#417F90'
    }
})
function ContainersTable(props) {
    const { data, handleExpandCollapseContainer, mileStoneHeaders, intl, classes, isPrint, printClasses, handlePinOrUnPin } = props;
    const containerHeaders = [
        { title: intl.formatMessage({ id: 'containerno' }), field: "containerNo", width: '20%' },
        { title: intl.formatMessage({ id: 'type' }), field: "containerType", width: '20%' },
        { title: intl.formatMessage({ id: 'seal' }), field: "containerSealNo1", width: '20%' },
        { title: intl.formatMessage({ id: 'pcs' }), field: "totPcs", width: '20%' },
        { title: intl.formatMessage({ id: 'weight' }), field: "totWgt", width: '20%' }
        // totWgtUt
    ]
    return (
        <>
            <div className={isPrint ? printClasses.sectionDesktopForPrint : classes.sectionDesktop}>
                <Table size='small' style={{ overflowX: 'auto' }}>
                    <TableHead>
                        <TableRow className={classes.tabletr} >
                            <TableCell className={classes.tableHeader} style={{ width: '12%' }} > </TableCell>
                            {
                                containerHeaders.map((header, index) => {
                                    return (
                                        <TableCell key={index} style={{ width: header.width ? header.width : 225 }} className={classes.tableHeader}>{header.title}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {data ?
                            data.map((row, index) => {
                                return (
                                    <>
                                        <TableRow className={classes.tabletr} key={index} >
                                            <TableCell className={classes.tableCell} style={{ width: '12%' }} >
                                                <IconButton title='Expand' id={`ctnExpand${index}`} className={classes.iconButton} onClick={() => handleExpandCollapseContainer(index)}>
                                                    {row.expandedRow ? <UnfoldLessIcon className={classes.icons} /> : <UnfoldMoreIcon className={classes.icons} />}
                                                </IconButton>
                                                {row.intContainerNo ?
                                                    <IconButton id={`ctnPinUnpin${index}`} onClick={() => handlePinOrUnPin(row.intContainerNo, 'container', !row.favoriteCont)}>
                                                        <PushPinRoundedIcon className={classes.icons} style={{ color: row.favoriteCont === true ? '' : '#4A4E50' }} />
                                                    </IconButton> : null}
                                            </TableCell>
                                            {containerHeaders.map((header, i) => {
                                                return (<TableCell className={classes.tableCell} style={{ width: header.width }} key={i}>{row[header.field]}</TableCell>)
                                            })}
                                        </TableRow>
                                        <Collapse in={row.expandedRow} timeout="auto" unmountOnExit>
                                            <Box header={intl.formatMessage({ id: 'milestones' })}>
                                                <DetailedMileStoneData mileStoneHeaders={mileStoneHeaders} data={row.containerStatusList} milistone={true} />
                                            </Box>
                                        </Collapse>
                                    </>
                                )
                            })
                            :
                            <TableRow className={isPrint ? `${classes.tabletr} ${printClasses.noRecordsForPrint}` : `${classes.tabletr} ${classes.noRecords}`}>
                                <TableCell className={classes.noRecordscell} colSpan={containerHeaders.length} > {intl.formatMessage({ id: 'noRecordsFound' })}</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </div >
            <div className={isPrint ? printClasses.sectionMobileForPrint : classes.sectionMobile}>
                <ContainersViewMobile data={data} mileStoneHeaders={mileStoneHeaders} handleExpandCollapseContainer={handleExpandCollapseContainer} handlePinOrUnPin={handlePinOrUnPin} />
            </div>
        </>
    )
}

export default injectIntl(withStyles(style)(ContainersTable))

import React, { Component } from 'react'
import { processDate } from '../../../common/utils';
import { shipmentWidgetImportDueRecords } from '../../../sevices/TMFF/Services';
import BarChart from '../../common/BarChart';

export class ShipmentImportWidget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shipmentsImportData: [],
            loading: true,
            dateMap: {},
            xaxisData: [],
            yaxisData: [],
            noOfDays: '7',
            selectedPage: 1,
            noOfPages: 0,
            xaxisSlicedData: [],
            yaxisSlicedData: []
        }
    }

    getWidgetData = async () => {
        const { noOfDays } = this.state
        const shipmentData = await shipmentWidgetImportDueRecords(noOfDays);
        let newDateMap = {}
        if (shipmentData && shipmentData.dayWiseShipmentCount) {
            shipmentData.dayWiseShipmentCount.forEach(element => {
                newDateMap = {
                    ...newDateMap,
                    [processDate(element[1], "DD MMM")]: element[1]
                }
            });
            const noOfPages = Math.ceil((shipmentData.dayWiseShipmentCount.length - 1) / 7);
            const yaxisData = shipmentData.dayWiseShipmentCount.map(element => {
                return element[1] !== "date" ?
                    processDate(element[1], "DD MMM") : ''
            })
            const xaxisData = shipmentData.dayWiseShipmentCount.map(element => {
                return element[0]
            })
            xaxisData.shift()
            yaxisData.shift()
            await this.setState({
                shipmentsImportData: shipmentData ? (shipmentData.dayWiseShipmentCount ? shipmentData.dayWiseShipmentCount : []) : [],
                loading: false,
                xaxisData: xaxisData,
                yaxisData: yaxisData,
                xaxisSlicedData: xaxisData.slice(0, 7),
                yaxisSlicedData: yaxisData.slice(0, 7),
                dateMap: newDateMap,
                selectedPage: 1,
                noOfPages: noOfPages
            })
        }
        else {
            await this.setState({
                shipmentsImportData: [],
                loading: false,
                xaxisData: [],
                yaxisData: [],
                dateMap: {},
                xaxisSlicedData: [],
                yaxisSlicedData: []
            })
        }

    }
    componentDidMount = async () => {
        const { location } = this.props;
        const Widgets = location.state ? location.state.widgets : true
        if (Widgets) {
            this.getWidgetData();
        }

    }
    handleShipments = (event) => {
        const { dateMap } = this.state
        const { history, CpError, intl } = this.props;
        const param = dateMap[event.name]
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
        let header = { [intl.formatMessage({ id: 'shipments_arriving' })]: event.name }
        history.push({
            pathname: '/shipments',
            state: {
                data: {
                    podEtaDate: param,
                    bizType: "SI,AI"
                },
                heading: header
            }
        })
    }

    handleInputChange = async (event) => {
        if (event !== null) {
            await this.setState({
                noOfDays: event.target.value,
                loading: true
            })
        } else {
            await this.setState({
                noOfDays: 0,
                loading: true
            })
        }
        this.getWidgetData()
    }

    handlePageClick = (pageNo) => {
        const pageNumber = pageNo - 1;

        const start = pageNumber === 0 ? 0 : (pageNumber * 7) + pageNumber - 1;
        const end = start + 7;
        const { xaxisData, yaxisData } = this.state
        this.setState({ xaxisSlicedData: xaxisData.slice(start, end), yaxisSlicedData: yaxisData.slice(start, end), selectedPage: pageNo })
    }
    render() {
        const { intl } = this.props;
        const { loading, xaxisSlicedData, yaxisSlicedData, noOfDays, selectedPage, noOfPages } = this.state
        return (
            <BarChart
                heading={intl.formatMessage({ id: 'shipments_arriving' })}
                name='Import Shipments'
                loading={loading}
                handleClick={this.handleShipments}
                xaxisData={xaxisSlicedData}
                yaxisData={yaxisSlicedData}
                showTypeahead={true}
                handleInputChange={this.handleInputChange}
                noOfDays={noOfDays}
                selectedPage={selectedPage}
                noOfPages={noOfPages}
                handlePageClick={this.handlePageClick}
                showRefreshIcon={false}
            />
        )
    }
}

export default ShipmentImportWidget

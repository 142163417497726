import React, { Component } from 'react';
import { createEbooking, getCities, fetchParties, fetchSystemCodes, fetchFileFilters, deleteBooking, evictBookingsCache, quoteActions, evictQuotesCache, bookingTemplateActions, getBookingTemplateDataById, fetchCustomers, fetchBookingTemplates } from '../../sevices/TMFF/Services'
import CPCollectiveForm from './CPCollectiveForm'
import { generateBookingRequest, getBase64, generateBookingDelete, generateQuoteRequest, generateQuoteDelete, generateQuoteReject, generateQuoteAccept, generateQuoteBook, getFileFiltersData, getIsValidFileTypeandSize, generateBookingTemplateRequest, generateTemplateEnableorDisableRequest, processDate } from '../../common/utils';
import moment from 'moment';
import { getBookingData, getBookingTemplateData, getQuoteData, getBookingDataOfTemplate } from '../../common/FormUtils';
import { withRouter } from 'react-router-dom';
import withCPContext from '../common/withCPContext';
import { injectIntl } from 'react-intl';
import { logMetrics } from '../../sevices/common/CpServices'


class CPCollectiveContainer extends Component {
    constructor(props) {
        super(props);
        const { formFor, data, cpContext } = this.props
        const formdata = this.getData(formFor)
        const jobServiceReqs = data?.serviceRequest ?? ""
        const serReqList = data?.bizType ? cpContext.serviceRequests[data?.bizType]:[]
        const cond =  (data?.status && data?.status === "In Progress") || formFor === "bookingTemplate";
        const finSerReqsLabels = !cond ? jobServiceReqs?.split(",").map(value=>{
                const foundItem  = serReqList?.find(item => item.value === value);
                return foundItem ? foundItem.label: ''
            }).filter(label =>label !== '').join(', ') || '' : (jobServiceReqs?.split(",") || []);

        this.state = {
            ...formdata,
            cpSupUser : JSON.parse(sessionStorage.getItem('isCpSupUser')),
            autoFocusCustomer: false,
            autoFocusShipper: false,
            autoFocusConsignee: false,
            details: {
                ...formdata.details,
                destinations: props.destinationCities ? props.destinationCities : [],
                origins: props.originCities ? props.originCities : [],
                polCities: props.polCities ? props.polCities : [],
                podCities: props.podCities ? props.podCities : [],
                systemSerReqs: serReqList,
                serviceRequest: finSerReqsLabels ?? [],
                serList: jobServiceReqs?.split(",") ?? []                
            },
            fileTypes: '',
            templateNameGenflag : true,
            fileSize: '10M',
            docsUpdated: false,
            fileErrors: {
                invalidType: false,
                limitExceded: false
            },
            noOptionMsg: {orgCity: '',destCity: '',podCity: '',polCity: ''},
            bookingTemplates: props.bookingTemplates ? props.bookingTemplates : [],
            templateNamegen: { mot: '', ship: '',cons: '',orig: '',dest: '',comm: '',remrk: ''},
            snackBar: { show: false, msg: '',type: 'info'},
            pickupCollectAddress: [],
            pickupDeliveryAddress: [],
            deliverCollectAddress: [],
            deliverDeliveryAddress: [],
            quotelist: [
                { title: "Quote No.", field: "quoteNo" },
                { title: "Valid From/To", field: "validFromTo" }
            ],
            showAddressfield: false,
            pickupList: [
                { title: "Collect From", field: "pickupAddress" },
                { title: "Delivery To", field: "deliveryAddress" },
                { title: "Collection Date", field: "readyDate" },
                { title: "From/To", field: "pickupTime" }],
            deliveryList: [
                { title: "Collect From", field: "deliveryCollectAddress" },
                { title: "Delivery To", field: "deliveryDeliveryAddress" },
                { title: "Collection Date", field: "readyDate" },
                { title: "From/To", field: "deliveryTime" }],
            supPickupList: [
                { title: "Collect From", field: "pickupAddress" },
                { title: "Delivery To", field: "deliveryAddress" },],
            supDeliveryList: [
                { title: "Collect From", field: "deliveryCollectAddress" },
                { title: "Delivery To", field: "deliveryDeliveryAddress" },],
            supReadyDate :[ 
                { title: "Cargo Ready Date", field: "readyDate" },
                { title: "From/To", field: "pickupTime" },
                { title: "Need Date at Destination", field: "deliveryreadyDate" },
                { title: "From/To", field: "deliveryTime" }],
            
            readOnlyHeaderList: this.getHeaderList(formdata.details.bizType, formFor)
        }

        this.quotesDivToFocus = React.createRef()
        this.detailsDivToFocus = React.createRef()
        this.containersDivToFocus = React.createRef()
        this.itemsDivToFocus = React.createRef()
        this.aduitTrailDivToFocus = React.createRef()
        this.documentsDivToFocus = React.createRef();
        this.destCityRef = React.createRef();
        this.orgCityRef = React.createRef();
        this.polCityRef = React.createRef();
        this.podCityRef = React.createRef();
        this.podCountryRef = React.createRef();
        this.polCountryRef = React.createRef();
    }
    getHeaderList = (bizType, formFor) => {
        switch (bizType) {
            case "SI":
                if (formFor === 'booking') {
                    return [{ title: "Shipper", field: "shipperName", extraFieldName: "Address", extraField: "readOnlyShipperAddress", showExtraField: false },
                    { title: "Total Volume", field: "volume" },
                    { title: "Origin", field: "orgCity" },
                    { title: "Final Destination", field: "destCity" },
                    { title: "Consignee", field: "consignee", extraFieldName: "Address", extraField: "readOnlyConsigneeAddress", showExtraField: false },
                    { title: "Notify", field: "notify", extraFieldName: "Address", extraField: "readOnlyNotifyAddress", showExtraField: false },
                    { title: "HBL/HAWB No.", field: "hblAwbNo" },
                    { title: "Commodity", field: "commodity" },
                    { title: "Discharge Country", field: "podCountry" },
                    { title: "Delivery Terms", field: "deliveryTerms" },
                    { title: "Mode of Transport", field: "bizType" },
                    { title: "Reference No.", field: "referenceNumber" },
                    { title: "Loading Country", field: "polCountry" },
                    { title: "Special Requirements/Remarks", field: "specialRqs" },
                    { title: "Total Pieces", field: "pieces" },
                    { title: "Preffered Carrier", field: "carrier" },
                    { title: "Port of Loading", field: "polCity" },
                    { title: "Total Weight", field: "weight" },
                    { title: "Service Request", field: "serviceRequest" },
                    { title: "Origin Country", field: "orgCountry" },
                    { title: "Final Destination Country", field: "destCountry" },
                    { title: "Shipment Type", field: "shipmentType" },
                    { title: "Move Type", field: "moveType" },
                    { title: "Port of Discharge", field: "podCity" }
                    ]
                }
                else {
                    return [{ title: "Shipper", field: "shipperName", extraFieldName: "Address", extraField: "readOnlyShipperAddress", showExtraField: false },
                    { title: "Total Volume", field: "volume" },
                    { title: "Origin", field: "orgCity" },
                    { title: "Final Destination", field: "destCity" },
                    { title: "Consignee", field: "consignee", extraFieldName: "Address", extraField: "readOnlyConsigneeAddress", showExtraField: false },
                    { title: "Commodity", field: "commodity" },
                    { title: "Notify", field: "notify", extraFieldName: "Address", extraField: "readOnlyNotifyAddress", showExtraField: false },
                    { title: "Discharge Country", field: "podCountry" },
                    { title: "Delivery Terms", field: "deliveryTerms" },
                    { title: "Mode of Transport", field: "bizType" },
                    { title: "Reference No.", field: "referenceNumber" },
                    { title: "Loading Country", field: "polCountry" },
                    { title: "Special Requirements/Remarks", field: "specialRqs" },
                    { title: "Total Pieces", field: "pieces" },
                    { title: "Preffered Carrier", field: "carrier" },
                    { title: "Port of Loading", field: "polCity" },
                    { title: "Total Weight", field: "weight" },
                    { title: "Service Request", field: "serviceRequest" },
                    { title: "Origin Country", field: "orgCountry" },
                    { title: "Final Destination Country", field: "destCountry" },
                    { title: "Shipment Type", field: "shipmentType" },
                    { title: "Move Type", field: "moveType" },
                    { title: "Port of Discharge", field: "podCity" }
                    ]
                }
            case "AI":
                if (formFor === 'booking') {
                    return [{ title: "Shipper", field: "shipperName", extraFieldName: "Address", extraField: "readOnlyShipperAddress", showExtraField: false },
                    { title: "Total Volume", field: "volume" },
                    { title: "Origin", field: "orgCity" },
                    { title: "Final Destination", field: "destCity" },
                    { title: "Consignee", field: "consignee", extraFieldName: "Address", extraField: "readOnlyConsigneeAddress", showExtraField: false },
                    { title: "Notify", field: "notify", extraFieldName: "Address", extraField: "readOnlyNotifyAddress", showExtraField: false },
                    { title: "Commodity", field: "commodity" },
                    { title: "HBL/HAWB No.", field: "hblAwbNo" },
                    { title: "Arrival Country", field: "podCountry" },
                    { title: "Delivery Terms", field: "deliveryTerms" },
                    { title: "Mode of Transport", field: "bizType" },
                    { title: "Reference No.", field: "referenceNumber" },
                    { title: "Departure Country", field: "polCountry" },
                    { title: "Special Requirements/Remarks", field: "specialRqs" },
                    { title: "Total Pieces", field: "pieces" },
                    { title: "Preffered Carrier", field: "carrier" },
                    { title: "Airport Departure", field: "polCity" },
                    { title: "Total Weight", field: "weight" },
                    { title: "Service Request", field: "serviceRequest" },
                    { title: "Origin Country", field: "orgCountry" },
                    { title: "Final Destination Country", field: "destCountry" },
                    { title: "Airport Arrival", field: "podCity" }
                    ]
                } else {
                    return [
                        { title: "Shipper", field: "shipperName", extraFieldName: "Address", extraField: "readOnlyShipperAddress", showExtraField: false },
                        { title: "Total Volume", field: "volume" },
                        { title: "Origin", field: "orgCity" },
                        { title: "Final Destination", field: "destCity" },
                        { title: "Consignee", field: "consignee", extraFieldName: "Address", extraField: "readOnlyConsigneeAddress", showExtraField: false },
                        { title: "Notify", field: "notify", extraFieldName: "Address", extraField: "readOnlyNotifyAddress", showExtraField: false },
                        { title: "Commodity", field: "commodity" },
                        { title: "Arrival Country", field: "podCountry" },
                        { title: "Delivery Terms", field: "deliveryTerms" },
                        { title: "Mode of Transport", field: "bizType" },
                        { title: "Reference No.", field: "referenceNumber" },
                        { title: "Departure Country", field: "polCountry" },
                        { title: "Special Requirements/Remarks", field: "specialRqs" },
                        { title: "Total Pieces", field: "pieces" },
                        { title: "Preffered Carrier", field: "carrier" },
                        { title: "Airport Departure", field: "polCity" },
                        { title: "Total Weight", field: "weight" },
                        { title: "Service Request", field: "serviceRequest" },
                        { title: "Origin Country", field: "orgCountry" },
                        { title: "Final Destination Country", field: "destCountry" },
                        { title: "Airport Arrival", field: "podCity" }
                    ]
                }

            case "AE":
                return [
                    { title: "Shipper", field: "shipperName", extraFieldName: "Address", extraField: "readOnlyShipperAddress", showExtraField: false },
                    { title: "Total Volume", field: "volume" },
                    { title: "Origin", field: "orgCity" },
                    { title: "Final Destination", field: "destCity" },
                    { title: "Consignee", field: "consignee", extraFieldName: "Address", extraField: "readOnlyConsigneeAddress", showExtraField: false },
                    { title: "Notify", field: "notify", extraFieldName: "Address", extraField: "readOnlyNotifyAddress", showExtraField: false },
                    { title: "Commodity", field: "commodity" },
                    { title: "Arrival Country", field: "podCountry" },
                    { title: "Delivery Terms", field: "deliveryTerms" },
                    { title: "Mode of Transport", field: "bizType" },
                    { title: "Reference No.", field: "referenceNumber" },
                    { title: "Departure Country", field: "polCountry" },
                    { title: "Special Requirements/Remarks", field: "specialRqs" },
                    { title: "Total Pieces", field: "pieces" },
                    { title: "Preffered Carrier", field: "carrier" },
                    { title: "Airport Departure", field: "polCity" },
                    { title: "Total Weight", field: "weight" },
                    { title: "Service Request", field: "serviceRequest" },
                    { title: "Origin Country", field: "orgCountry" },
                    { title: "Final Destination Country", field: "destCountry" },
                    { title: "Airport Arrival", field: "podCity" }
                ]
            case "IL":
                return [
                    { title: "Shipper", field: "shipperName", extraFieldName: "Address", extraField: "readOnlyShipperAddress", showExtraField: false },
                    { title: "Total Volume", field: "volume" },
                    { title: "Origin", field: "orgCity" },
                    { title: "Final Destination", field: "destCity" },
                    { title: "Consignee", field: "consignee", extraFieldName: "Address", extraField: "readOnlyConsigneeAddress", showExtraField: false },
                    { title: "Notify", field: "notify", extraFieldName: "Address", extraField: "readOnlyNotifyAddress", showExtraField: false },
                    { title: "Commodity", field: "commodity" },
                    { title: "Delivery Terms", field: "deliveryTerms" },
                    { title: "Mode of Transport", field: "bizType" },
                    { title: "Reference No.", field: "referenceNumber" },
                    { title: "Special Requirements/Remarks", field: "specialRqs" },
                    { title: "Total Pieces", field: "pieces" },
                    { title: "Preffered Carrier", field: "carrier" },
                    { title: "Total Weight", field: "weight" },
                    { title: "Service Request", field: "serviceRequest" },
                    { title: "Origin Country", field: "orgCountry" },
                    { title: "Final Destination Country", field: "destCountry" },
                    { title: "Shipment Type", field: "shipmentType" },
                    { title: "Move Type", field: "moveType" }]
            case "SE":
            default:
                return [
                    { title: "Shipper", field: "shipperName", extraFieldName: "Address", extraField: "readOnlyShipperAddress", showExtraField: false },
                    { title: "Total Volume", field: "volume" },
                    { title: "Origin", field: "orgCity" },
                    { title: "Final Destination", field: "destCity" },
                    { title: "Consignee", field: "consignee", extraFieldName: "Address", extraField: "readOnlyConsigneeAddress", showExtraField: false },
                    { title: "Notify", field: "notify", extraFieldName: "Address", extraField: "readOnlyNotifyAddress", showExtraField: false },
                    { title: "Commodity", field: "commodity" },
                    { title: "Discharge Country", field: "podCountry" },
                    { title: "Delivery Terms", field: "deliveryTerms" },
                    { title: "Mode of Transport", field: "bizType" },
                    { title: "Reference No.", field: "referenceNumber" },
                    { title: "Loading Country", field: "polCountry" },
                    { title: "Special Requirements/Remarks", field: "specialRqs" },
                    { title: "Total Pieces", field: "pieces" },
                    { title: "Preffered Carrier", field: "carrier" },
                    { title: "Port of Loading", field: "polCity" },
                    { title: "Total Weight", field: "weight" },
                    { title: "Service Request", field: "serviceRequest" },
                    { title: "Origin Country", field: "orgCountry" },
                    { title: "Final Destination Country", field: "destCountry" },
                    { title: "Shipment Type", field: "shipmentType" },
                    { title: "Move Type", field: "moveType" },
                    { title: "Port of Discharge", field: "podCity" }
                ]
        }
    }
    getData = (formFor) => {
        const { data, cpContext } = this.props
        let formData = null
        switch (formFor) {
            case "booking":
                formData = getBookingData(data, cpContext)
                break;
            case "quote":
                formData = getQuoteData(data, cpContext)
                break;
            case "bookingTemplate":
                formData = getBookingTemplateData(data, cpContext)
                break;
            default:
                break;
        }
        return formData
    }
    processResponseData = async (data) => {
        const validFromTo = (data.validFrom ? processDate(data.validFrom, "dateformat") : '') + ' - ' + (data.validTo ? processDate(data.validTo, "dateformat") : '')
        const updatedDocuments = data.documents ?
            data.documents.map(document => {
                return {
                    ...document,
                    uploadDate: processDate(document.uploadDate, 'dateTime'),
                    action: "existing",
                    selected: false
                }
            })
            :
            []
        let updatedData = {
            ...data,
            documents: updatedDocuments,
            validFromTo: validFromTo
        }
        return updatedData
    }
    componentDidMount = async () => {
        let data = []
        if (this.props.formFor === 'booking') {
            data = await fetchBookingTemplates(false, true);
        }
        let fileFilters = await fetchFileFilters();
        const res = await getFileFiltersData(fileFilters);
        this.setState({
            fileSize: res.fileSize,
            fileTypes: res.fileTypes,
            bookingTemplates: data ? data.bookingTemplates ? data.bookingTemplates : [] : []
        })
        const docTypes = await fetchSystemCodes("JOBDOCTYPE")
        let updateddocType = docTypes ? docTypes.lookupItems : []
        this.setState({
            docTypes: updateddocType,
            pickupCollectAddress: await this.getaddresses("collectfrom"),
            pickupDeliveryAddress: await this.getaddresses("pickupdeliverto"),
            deliverCollectAddress: await this.getaddresses("deliverycollectfrom"),
            deliverDeliveryAddress: await this.getaddresses("deliverydeliverto")
        })
    }
    handleCountryChange = async (event, field, type) => {
        const { intl, formFor } = this.props
        let data = []
        if (event !== null) {
            if (field === 'orgCountry') {
                this.orgCityRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
                await this.setState({
                    details: {
                        ...this.state.details,
                        origins: [],
                        [field]: event.value,
                        orgCity: '',
                        orgCityName: ''
                    },
                    errordetails: {
                        ...this.state.errordetails,
                        orgCountry: ''
                    },
                    noOptionMsg: {
                        ...this.state.noOptionMsg,
                        orgCity: intl.formatMessage({ id: 'data_Loading' })
                    }
                })
                const isRoad = this.state.details.bizType === 'IL' ? true : false
                data = await getCities(event.value, isRoad)
                await this.setState({
                    details: {
                        ...this.state.details,
                        origins: data ? data : [],
                    },
                    noOptionMsg: {
                        ...this.state.noOptionMsg,
                        orgCity: ''
                    }
                })

            }
            else if (field === 'polCountry') {
                this.polCityRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
                await this.setState({
                    details: {
                        ...this.state.details,
                        polCities: [],
                        [field]: event.value,
                        polCity: '',
                        polCityName: ''
                    },
                    errordetails: {
                        ...this.state.errordetails,
                        polCountry: ''
                    },
                    noOptionMsg: {
                        ...this.state.noOptionMsg,
                        polCity: intl.formatMessage({ id: 'data_Loading' })
                    }
                })
                const isRoad = this.state.details.bizType === 'IL' ? true : false
                const isSea = (this.state.details.bizType === 'SE' || this.state.details.bizType === 'SI') ? true : false
                const isAir = (this.state.details.bizType === 'AE' || this.state.details.bizType === 'AI') ? true : false
                data = await getCities(event.value, isRoad, isSea, isAir)
                await this.setState({
                    details: {
                        ...this.state.details,
                        polCities: data ? data : [],
                    },
                    noOptionMsg: {
                        ...this.state.noOptionMsg,
                        polCity: ''
                    }
                })

            }
            else if (field === 'podCountry') {
                this.podCityRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
                await this.setState({
                    details: {
                        ...this.state.details,
                        podCities: [],
                        [field]: event.value,
                        podCity: '',
                        podCityName: ''
                    },
                    errordetails: {
                        ...this.state.errordetails,
                        podCountry: ''
                    },
                    noOptionMsg: {
                        ...this.state.noOptionMsg,
                        podCity: intl.formatMessage({ id: 'data_Loading' })
                    }
                })
                const isRoad = this.state.details.bizType === 'IL' ? true : false
                const isSea = (this.state.details.bizType === 'SE' || this.state.details.bizType === 'SI') ? true : false
                const isAir = (this.state.details.bizType === 'AE' || this.state.details.bizType === 'AI') ? true : false
                data = await getCities(event.value, isRoad, isSea, isAir)
                await this.setState({
                    details: {
                        ...this.state.details,
                        podCities: data ? data : [],
                    },
                    noOptionMsg: {
                        ...this.state.noOptionMsg,
                        podCity: ''
                    }
                })

            }
            else {
                this.destCityRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
                await this.setState({
                    details: {
                        ...this.state.details,
                        destinations: [],
                        [field]: event.value,
                        destCity: '',
                        destCityName: ''
                    },
                    errordetails: {
                        ...this.state.errordetails,
                        destCountry: ''
                    },
                    noOptionMsg: {
                        ...this.state.noOptionMsg,
                        destCity: intl.formatMessage({ id: 'data_Loading' })
                    }
                })
                const isRoad = this.state.details.bizType === 'IL' ? true : false
                data = await getCities(event.value, isRoad)
                await this.setState({
                    details: {
                        ...this.state.details,
                        destinations: data ? data : []
                    },
                    noOptionMsg: {
                        ...this.state.noOptionMsg,
                        destCity: ''
                    }
                })

            }
        }
        else {
            if (field === 'orgCountry') {
                await this.setState({
                    details: {
                        ...this.state.details,
                        origins: [],
                        [field]: '',
                        orgCity: '',
                        orgCityName: ''
                    }
                })
                this.orgCityRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
            }
            else if (field === 'polCountry') {
                await this.setState({
                    details: {
                        ...this.state.details,
                        polCities: [],
                        [field]: '',
                        polCity: '',
                        polCityName: ''
                    }
                })
                this.polCityRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
            }
            else if (field === 'podCountry') {
                await this.setState({
                    details: {
                        ...this.state.details,
                        podCities: [],
                        [field]: '',
                        podCity: '',
                        podCityName: ''
                    },
                    errordetails: {
                        ...this.state.errordetails,
                        podCountry: ''
                    }
                })
                this.podCityRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
            }
            else {
                await this.setState({
                    details: {
                        ...this.state.details,
                        destinations: [],
                        [field]: '',
                        destCity: '',
                        destCityName: ''
                    }
                })
                this.destCityRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
            }
        }
        if (formFor === "bookingTemplate" && (field === "orgCountry" || field === "destCountry")) {
            this.generateTemplateName()
        }
    }
    handleUploadFile = async (event, from) => {
        const file = event && event.target ? event.target.files[0] : event[0];
        const { documents } = this.state
        let updateddocuments = []
        if (file !== undefined) {
            this.setState({
                fileErrors: {
                    invalidType: false,
                    limitExceded: false
                }
            })
            const res = await getIsValidFileTypeandSize(file, this.state.fileSize, this.state.fileTypes)
            this.setState({
                fileErrors: {
                    invalidType: res.invType,
                    limitExceded: res.sizeExceded
                }
            })
            if (res.isValid) {
                getBase64(file).then(data => {
                    if (from === 'add') {
                        let docSNo = documents[documents.length - 1] ? documents[documents.length - 1].docSNo + 1 : 1
                        updateddocuments = [...documents, {
                            docSNo: docSNo, description: '', fileName: file.name, type: '', uploadDate: '', downloadableURL: '', action: 'new', selected: false, actionCode: 'A', enablefields: true,
                            source: 'CP',
                            "fileData": {
                                "text":
                                    [data]
                            }
                        }]
                    }
                    if (from === 'edit') {
                        updateddocuments = documents.map(document => {
                            return (document.selected === true ? {
                                ...document, description: '', fileName: file.name, type: '', uploadDate: '', selected: true, actionCode: 'U', enablefields: true,
                                "fileData": {
                                    "text":
                                        [data]
                                }
                            } : document)
                        })
                    }
                    this.setState({
                        documents: updateddocuments,
                        docsUpdated: true
                    })
                })
            }
        }
    }
    handleremoveNewDocFunc = (docSNo) => {
        const { documents } = this.state
        let updatedDocuments = documents.filter((document) => {
            return document.docSNo !== docSNo;
        });
        const newDoc = updatedDocuments?.filter(x=>x.action === "new") ?? []
        this.setState({
            documents: updatedDocuments,
            docsUpdated: newDoc.length >= 1 ? true : false
        })
    }
    handleEnableIconsForDocs = (event, docSNo) => {
        const { documents } = this.state

        let updatedDocuments = documents.map(document => {
            return (document.docSNo === docSNo ? { ...document, selected: event.target.checked } : { ...document, selected: false })
        })
        this.setState({
            enableIcons: event.target.checked,
            documents: updatedDocuments
        })

    }
    handleDeleteExistingDocs = (id) => {
        const { documents } = this.state
        let updatedDocuments = documents.map(document => {
            return (document.docSNo === id ? { ...document, actionCode: 'D', selected: false } : document)
        })
        this.setState({
            enableIcons: false,
            documents: updatedDocuments,
            docsUpdated: updatedDocuments.length >= 1 ? true : false
        })
    }
    onChangeDocumentfields = (docSNo, value) => {
        const { documents } = this.state
        let updatedDocuments = documents.map(document => {
            return document.docSNo === docSNo ? { ...document, description: value, type: value } : document
        })
        this.setState({
            documents: updatedDocuments,
            docsUpdated: updatedDocuments.length >= 1 ? true : false
        })

    }
    handleOnclickPopUp = (position, anchorEl) => {
        const { popOverField, shipperAddressList, consigneeAddressList,notifyAddressList } = this.state
        switch (popOverField) {
            case "shipperAddress":
                let updatedAddresses = shipperAddressList.map((el, index) => {
                    return position === index ? { ...el, selected: true } : { ...el, selected: false }
                })

                this.setState({
                    shipperAddressList: updatedAddresses,
                    details: { ...this.state.details, shipperAddress: shipperAddressList[position] }

                })
                break;
            case "consigneeAddress":
                let updatedAddresses1 = consigneeAddressList.map((el, index) => {
                    return position === index ? { ...el, selected: true } : { ...el, selected: false }
                })

                this.setState({
                    consigneeAddressList: updatedAddresses1,
                    details: { ...this.state.details, consigneeAddress: consigneeAddressList[position] }
                })
                break;
            case "notifyAddress":
                let updatedAddresses2 = notifyAddressList.map((el, index) => {
                    return position === index ? { ...el, selected: true } : { ...el, selected: false }
                })

                this.setState({
                    notifyAddressList: updatedAddresses2,
                    details: { ...this.state.details, notifyAddress: notifyAddressList[position] }
                })
                break;
            default:
                break;
        }
        this.handlePopOverOpen(null, popOverField, anchorEl)
    }
    handleBookingOrQuoteSubmit = async (type) => {
        const { shipperName, bizType, totalPcs, totalPcsUt, totalWgt, totalWgtUt, destCity, destCountry,orgCountry,orgCity,polCountry,polCity, readyDate, podCity, podCountry,deliveryreadyDate,shipmentType} = this.state.details
        const {containerslist} =this.state;
        const { handleDrawer, handleSuccess, formFor, fromDashBoard, intl } = this.props;
        const boolReadyDate = readyDate ? (moment(readyDate).isAfter(moment(new Date())) || moment(readyDate).isSame(moment(new Date()), "day")) : true;
        const {cpSupUser} =this.state;
        const supuserFields =  (totalPcs && totalPcsUt && totalWgt && totalWgtUt);
        const supdatatime =(readyDate && deliveryreadyDate);
        const isSupUserBooking = (cpSupUser && formFor === 'booking');
        const containerCond = (bizType === 'SE' || bizType === 'SI') && shipmentType === 'FCL';
		
        const hasEmptyTypeOrQty =(container) =>{
            return container.some((item)=> item.type === "" || item.qty === "")
        }

        const isSupContainersBookingEmpty = isSupUserBooking && containerCond ? (!hasEmptyTypeOrQty(containerslist) && !!containerslist.length)  :true;

        if ((shipperName && bizType  && ( !cpSupUser ? supuserFields :((formFor === "booking")? supdatatime: true)) && ((destCity && destCountry) || (podCountry && podCity)) && ((orgCountry&& orgCity) ||(polCity && polCountry)) && boolReadyDate && isSupContainersBookingEmpty )) {
            this.setState({ error: " ", disableButtons: true })
            if (formFor === "booking") {
                const bookingReqBody = generateBookingRequest(this.state, type);
                const res = await createEbooking(bookingReqBody, type);
                if (res.status === 201 || res.status === 200) {
                    evictBookingsCache("bookstatus");
                    evictBookingsCache("bookstatus");
                    handleSuccess(type, res.data, "Booking");
                    const metricsReqBody = {
                        "tenant": sessionStorage.getItem('tenant'),
                        "userId": sessionStorage.getItem('userName'),
                        "service": "booking",
                        "operation": "saveBooking",
                        "status": "SUCCESS"
                    }
                    logMetrics(metricsReqBody);
                    handleDrawer();
                    if (this.props.history.location.pathname === "/") {
                        this.props.history.push("/reload")
                        this.props.history.push({
                            pathname: "/",
                            state: { widgets: true }
                        })
                    }
                    if (this.props.history.location.pathname === "/bookings") {
                        await this.props.history.push({
                            pathname: "/",
                            state: { widgets: false }
                        })
                        if (fromDashBoard !== undefined && fromDashBoard !== null && fromDashBoard) {
                            this.props.history.push("/reload")
                            this.props.history.push({
                                pathname: "/",
                                state: { widgets: true }
                            })
                        }
                        else {
                            this.props.history.push("/bookings")
                        }
                    }
                }
                else {
                    this.setState({ error: intl.formatMessage({ id: 'somethingWentWrong' }), disableButtons: false })
                    const metricsReqBody = {
                        "tenant": sessionStorage.getItem('tenant'),
                        "userId": sessionStorage.getItem('userName'),
                        "service": "booking",
                        "operation": "saveBooking",
                        "status": "FAILED"
                    }
                    logMetrics(metricsReqBody);
                }
            }
            else if (formFor === "quote") {
                const quoteReqBody = generateQuoteRequest(this.state, type);
                const res = await quoteActions(quoteReqBody, type);
                if (res.status === 201 || res.status === 200) {
                    evictQuotesCache("quotestatus");
                    evictQuotesCache("quotestatus");
                    handleSuccess(type, res.data.status, "Quote");
                    const metricsReqBody = {
                        "tenant": sessionStorage.getItem('tenant'),
                        "userId": sessionStorage.getItem('userName'),
                        "service": "quote",
                        "operation": "saveQuote",
                        "status": "SUCCESS"
                    }
                    logMetrics(metricsReqBody);
                    handleDrawer();
                    if (this.props.history.location.pathname === "/") {
                        this.props.history.push("/reload")
                        this.props.history.push({
                            pathname: "/",
                            state: { widgets: true }
                        })
                    }
                    if (this.props.history.location.pathname === "/quotes") {
                        await this.props.history.push({
                            pathname: "/",
                            state: { widgets: false }
                        })
                        if (fromDashBoard !== undefined && fromDashBoard !== null && fromDashBoard) {
                            this.props.history.push("/reload")
                            this.props.history.push({
                                pathname: "/",
                                state: { widgets: true }
                            })
                        }
                        else {
                            this.props.history.push("/quotes")
                        }
                    }
                }
                else {
                    this.setState({ error: intl.formatMessage({ id: 'somethingWentWrong' }), disableButtons: false })
                    const metricsReqBody = {
                        "tenant": sessionStorage.getItem('tenant'),
                        "userId": sessionStorage.getItem('userName'),
                        "service": "quote",
                        "operation": "saveQuote",
                        "status": "FAILED"
                    }
                    logMetrics(metricsReqBody);
                }
            }


        } else {
            let errors = {}
            if (shipperName === '' || shipperName === undefined) {
                errors.shipper = intl.formatMessage({ id: 'requiredDropdownMsg' })
            }

            if (bizType === '' || bizType === undefined) {
                errors.bizType = intl.formatMessage({ id: 'requiredDropdownMsg' })
            }
            if (destCountry !== '' && destCity === '') {
                if (!(podCountry !== '' && podCity !== '')) {
                    errors.destCity = intl.formatMessage({ id: 'requiredDropdownMsg' })
                }
            }
            if (podCountry !== '' && podCity === '') {
                if (!(destCountry !== '' && destCity !== '')) {
                    errors.podCity = intl.formatMessage({ id: 'requiredDropdownMsg' })
                }
            }
            if ((podCountry === '' && destCountry === '')) {
                if (bizType === "AE" || bizType === "SE" || bizType === "AI" || bizType === "SI") {
                    errors.destCity = (bizType === "AE" ||bizType === "AI") ? intl.formatMessage({ id: 'selectAirportOrDestination' }) : intl.formatMessage({ id: 'selectPortofDischargeOrDestination' })
                    errors.destCountry = destCountry === '' ? " " : '';
                    errors.podCity = (bizType === "AE" ||bizType === "AI") ? intl.formatMessage({ id: 'selectAirportOrDestination' }) : intl.formatMessage({ id: 'selectPortofDischargeOrDestination' });
                    errors.podCountry = podCountry === '' ?  " " : '';
                }
                else {
                    errors.destCity = intl.formatMessage({ id: 'requiredDropdownMsg' })
                    errors.destCountry = intl.formatMessage({ id: 'requiredDropdownMsg' })
                }
            }
            if (orgCountry !== '' && orgCity === '') {
                if (!(polCountry !== '' && polCity !== '')) {
                    errors.orgCity = intl.formatMessage({ id: 'requiredDropdownMsg' })
                }
            }
            if (polCountry !== '' && polCity === '') {
                if (!(orgCountry !== '' && orgCity !== '')) {
                    errors.polCity = intl.formatMessage({ id: 'requiredDropdownMsg' })
                }
            }
            if ((orgCountry === '' && polCountry === '')) {
                if (bizType === "AE" || bizType === "SE" || bizType === "AI" || bizType === "SI") {
                    errors.polCity = (bizType === "AE" || bizType === "AI") ? intl.formatMessage({ id:"selectOriginOrDeparture"}):intl.formatMessage({id:"selectOriginOrPortOfLoading"});
                    errors.polCountry = polCountry === '' ? " " : '';
                    errors.orgCity = (bizType === "AE" || bizType === "AI") ? intl.formatMessage({ id:"selectOriginOrDeparture"}):intl.formatMessage({id:"selectOriginOrPortOfLoading"});
                    errors.orgCountry = orgCountry === '' ?  " " : '';
                }
                else {
                    errors.orgCity = intl.formatMessage({ id: 'requiredDropdownMsg' })
                    errors.orgCountry = intl.formatMessage({ id: 'requiredDropdownMsg' })
                }
            }
            if(cpSupUser){
                if(!readyDate){
                    errors.readyDate = intl.formatMessage({id:'requiredDropdownMsg'})
                }
                if(!deliveryreadyDate){
                    errors.deliveryreadyDate = intl.formatMessage({id:'requiredDropdownMsg'})
                }
                if(containerslist){
                    errors.containerlist = intl.formatMessage({id:'requiredDropdownMsg'})
                    errors.container = intl.formatMessage({id:'requiredContainers'})
                }
            }else{
                if (!parseInt(totalPcs)) {
                    errors.totalPcs = intl.formatMessage({ id: 'requiredErrormsg' })
                }
                if (!parseInt(totalWgt)) {
                    errors.totalWgt = intl.formatMessage({ id: 'requiredErrormsg' })
                }
                if (totalPcsUt === '' || totalPcsUt === undefined) {
                    errors.totalPcsUt = intl.formatMessage({ id: 'requiredDropdownMsg' })
                }
                if (totalWgtUt === '' || totalWgtUt === undefined) {
                    errors.totalWgtUt = intl.formatMessage({ id: 'requiredDropdownMsg' })
                }
        }

            // if (this.state.itemslist.length <= 0) {
            //     errors.items = "Atleast one item should be entered"
            // }

            await this.setState({
                errordetails: {
                    ...this.state.errordetails,
                    ...errors
                }
            })
        }
    }
    handleTemplateSubmit = async (type) => {
        const { templateName, details } = this.state
        const { handleDrawer, handleSuccess, intl } = this.props;
        if (templateName && templateName.trim().length > 0 && details.bizType) {
            await this.setState({ disableButtons: true })
            const templateReqBody = generateBookingTemplateRequest(this.state, type);
            const res = await bookingTemplateActions(templateReqBody, type);
            if (res.status === 201 || res.status === 200) {
                handleSuccess(type, res.data, "Template");
                handleDrawer();
                if (this.props.history.location.pathname === "/templates") {
                    this.props.history.push("/reload")
                    this.props.history.push({
                        pathname: "/templates",
                    })
                }
            }
            else {
                this.setState({ error: intl.formatMessage({ id: 'somethingWentWrong' }), disableButtons: false })
            }
        }
        else {
            let errors = {}
            if (details.bizType === '' || details.bizType === undefined) {
                errors.bizType = intl.formatMessage({ id: 'requiredDropdownMsg' })
            }
            if (templateName === undefined || templateName === '' || templateName.trim().length === 0) {
                errors.templateName = intl.formatMessage({ id: 'requiredErrormsg' })
            }
            await this.setState({
                errordetails: {
                    ...this.state.errordetails,
                    ...errors
                }
            })
        }
    }
    onSubmit = async (type) => {
        const { formFor } = this.props;
        if (formFor === "bookingTemplate") {
            this.handleTemplateSubmit(type)
        }
        else {
            this.handleBookingOrQuoteSubmit(type)
        }

    }
    enableorDisableLoadUnit = (status) => {
        const { sideHeaders } = this.state
        let updatedSideHeaders = sideHeaders.map(header => {
            return (header.name === 'Load Unit' ? { ...header, isDisabled: status === 'disable' ? true : false } : header)
        })
        this.setState({ sideHeaders: updatedSideHeaders })
    }
    enableorDisableContainers = (status) => {
        const { sideHeaders } = this.state
        let updatedSideHeaders = sideHeaders.map(header => {
            return (header.name === 'Containers' ? { ...header, isDisabled: status === 'disable' ? true : false } : header)
        })
        this.setState({ sideHeaders: updatedSideHeaders })
    }
    handleTypeaheadChange = async (event, parent, field) => {
        const { formFor } = this.props
        if (event !== null) {
            if (field === "destCity") {
                let errordetails = {
                    ...this.state.errordetails,
                    [field]: ''
                }
                if (event.value !== '' && this.state.details.destCountry !== '') {
                    errordetails = {
                        ...errordetails,
                        podCity: '',
                        podCountry: ''
                    }
                }
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: event.value,
                        destCityName: event.description
                    },
                    errordetails: errordetails
                })
            }
            else if (field === "orgCity") {
                let errordetails = {
                    ...this.state.errordetails,
                    [field]: ''
                }
                if (event.value !== '' && this.state.details.orgCountry !== '') {
                    errordetails = {
                        ...errordetails,
                        polCity: '',
                        polCountry: ''
                    }
                }
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: event.value,
                        orgCityName: event.description
                    },
                    errordetails: errordetails
                })
            }
            else if (field === "podCity") {
                let errordetails = {
                    ...this.state.errordetails,
                    [field]: ''
                }
                if (event.value !== '' && this.state.details.podCountry !== '') {
                    errordetails = {
                        ...errordetails,
                        destCountry: '',
                        destCity: ''
                    }
                }
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: event.value,
                        podCityName: event.description
                    },
                    errordetails: errordetails
                })
            }
            else if (field === "polCity") {
                let errordetails = {
                    ...this.state.errordetails,
                    [field]: ''
                }
                if (event.value !== '' && this.state.details.polCountry !== '') {
                    errordetails = {
                        ...errordetails,
                        orgCity: '',
                        orgCountry: ''
                    }
                }
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: event.value,
                        polCityName: event.description
                    },
                    errordetails: errordetails
                })
            }
            else if (field === "shipper") {
                const defaultAddress = event.addresses ? event.addresses.find(a => a.addrType === 'C') : {};
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: event.value,
                        shipperName: event.description ? event.description : event.label,
                        shipperDefaultAddress: defaultAddress ? defaultAddress : {},
                        shipperAddress: {"addr1":event.addresses[0].addr1 ,"addr2":event.addresses[0].addr2, "addr3":event.addresses[0].addr3 , "addr4" : event.addresses[0].addr4}

                    },
                    shipperAddressList: event.addresses ? event.addresses.map(a => { return { ...a, "label": a.address, "value": a.addrType, "addressType": a.addrTypeDesc } }) : [],
                    errordetails: {
                        ...this.state.errordetails,
                        [field]: ''
                    },
                    autoFocusCustomer: false,
                    autoFocusShipper: false,
                    autoFocusConsignee: false
                })
            }
            else if (field === "consignee") {
                const defaultAddress = event.addresses ? event.addresses.find(a => a.addrType === 'C') : {};
                await this.setState({
                    consigneeAddressList: event.addresses ? event.addresses.map(a => { return { ...a, "label": a.address, "value": a.addrType, "addressType": a.addrTypeDesc } }) : [],
                    [parent]: {
                        ...this.state.details,
                        [field]: event.value,
                        consigneeName: event.description ? event.description : event.label,
                        consigneeDefaultAddress: defaultAddress ? defaultAddress : {},
                        consigneeAddress:  {"addr1":event.addresses[0].addr1 ,"addr2":event.addresses[0].addr2, "addr3":event.addresses[0].addr3 , "addr4" : event.addresses[0].addr4}
                    },
                    autoFocusCustomer: false,
                    autoFocusShipper: false,
                    autoFocusConsignee: false,
                })
            }else if (field === "notify") {
                const defaultAddress = event.addresses ? event.addresses.find(a => a.addrType === 'C') : {};
                await this.setState({
                    notifyAddressList: event.addresses ? event.addresses.map(a => { return { ...a, "label": a.address, "value": a.addrType, "addressType": a.addrTypeDesc } }) : [],
                    [parent]: {
                        ...this.state.details,
                        [field]: event.value,
                        notifyName: event.description ? event.description : event.label,
                        notifyDefaultAddress: defaultAddress ? defaultAddress : {},
                        notifyAddress:  {"addr1":event.addresses[0].addr1 ,"addr2":event.addresses[0].addr2, "addr3":event.addresses[0].addr3 , "addr4" : event.addresses[0].addr4}
                    },
                    autoFocusCustomer: false,
                    autoFocusShipper: false,
                    autoFocusConsignee: false,
                })
            }
            else if (field === "customer") {
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: event.value,
                        customerName: event.description ? event.description : event.label
                    },
                    autoFocusCustomer: false,
                    autoFocusShipper: false,
                    autoFocusConsignee: false
                })
            }
            else if( field === "commodityCode"){
                await this.setState({
                    [parent]:{
                        ...this.state.details,
                        [field]:event.value,
                        commodity:event.label ?? ""
                    }
                })
            }
            else {
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: event.value
                    },
                    errordetails: {
                        ...this.state.errordetails,
                        [field]: ''
                    }
                })
            }
        }
        else {
            if (field === "destCity") {
                let errordetails = {
                    ...this.state.errordetails,
                    [field]: ''
                }
                if (event !== null && event.value !== '' && this.state.details.destCountry !== '') {
                    errordetails = {
                        ...errordetails,
                        podCountry: '',
                        podCity: ''
                    }
                }
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: '',
                        destCityName: ''
                    },
                    errordetails: errordetails
                })
            }
            else if (field === "orgCity") {
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: '',
                        orgCityName: ''
                    },
                    errordetails: {
                        ...this.state.errordetails,
                        [field]: ''
                    }
                })
            }
            else if (field === "podCity") {
                let errordetails = {
                    ...this.state.errordetails,
                    [field]: ''
                }
                if (event !== null && event.value !== '' && this.state.details.podCountry !== '') {
                    errordetails = {
                        ...errordetails,
                        destCountry: '',
                        destCity: ''
                    }
                }
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: '',
                        podCityName: ''
                    },
                    errordetails: errordetails
                })
            }
            else if (field === "polCity") {
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: '',
                        polCityName: ''
                    },
                    errordetails: {
                        ...this.state.errordetails,
                        [field]: ''
                    }
                })
            }
            else if (field === "shipper") {
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: '',
                        shipperName: '',
                        enableShipperAddress: false,
                        shipperAddress: {},
                        shipperDefaultAddress: {}
                    },
                    autoFocusCustomer: false,
                    autoFocusShipper: false,
                    autoFocusConsignee: false,
                    errordetails: {
                        ...this.state.errordetails,
                        [field]: ''
                    }
                })
            }
            else if (field === "consignee") {
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: '',
                        consigneeName: '',
                        consigneeAddress: {},
                        enableConsigneeAddress: false,
                        consigneeDefaultAddress: {}
                    },
                    autoFocusCustomer: false,
                    autoFocusShipper: false,
                    autoFocusConsignee: false,
                })
            }else if (field === "notify") {
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: '',
                        notifyName: '',
                        notifyAddress: {},
                        enableNotifyAddress: false,
                        notifyDefaultAddress: {}
                    },
                    autoFocusCustomer: false,
                    autoFocusShipper: false,
                    autoFocusConsignee: false,
                })
            }
            else if (field === "customer") {
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: '',
                        customerName: ''
                    },
                    autoFocusCustomer: false,
                    autoFocusShipper: false,
                    autoFocusConsignee: false
                })
            }
            else {
                await this.setState({
                    [parent]: {
                        ...this.state.details,
                        [field]: ''
                    },
                    autoFocusCustomer: false,
                    autoFocusShipper: false,
                    autoFocusConsignee: false,
                    errordetails: {
                        ...this.state.errordetails,
                        [field]: ''
                    }
                })
            }
        }
        if (formFor === "bookingTemplate" && (field === "consignee" || field === "shipper" || field === "orgCity" || field === "destCity")) {
            this.generateTemplateName()
        }
    }
    onChangeAddress = (event, parent) => {
        let field = event.target.name
        let value = event.target.value
        if (parent === 'shipperAddress') {
            this.setState({
                details: {
                    ...this.state.details,
                    shipperAddress: {
                        ...this.state.details.shipperAddress,
                        [field]: value
                    }
                }

            })
        }
        else if(parent === 'consigneeAddress'){
            this.setState({
                details: {
                    ...this.state.details,
                    consigneeAddress: {
                        ...this.state.details.consigneeAddress,
                        [field]: value
                    }
                }

            })
        }else if(parent === 'notifyAddress') {
            this.setState({
                details: {
                    ...this.state.details,
                    notifyAddress: {
                        ...this.state.details.notifyAddress,
                        [field]: value
                    }
                }
            })
        }
    }
    onChange = async (event, parent) => {
        const { formFor } = this.props
        const { pickupCollectAddress, pickupDeliveryAddress, deliverCollectAddress,deliverDeliveryAddress } = this.state
        const { collectFromObj, pickupdelivertoObj, deliverycollectfromObj, deliverydelivertoObj } = this.state.details;
        const {airDefaults, seaDefaults, roadDefaults ,airOriginCityList,seaPolCityList, serviceRequests} = this.props.cpContext;

        let field = event.target.name;
        let string = event.target.value;
        let position = event.currentTarget.dataset.index;
        if (parent) {
            switch (parent) {
                case "collectFrom":
                    let pcFrom = {
                        ...this.state.details.collectFromObj,
                        [field === "collectFrom" ? "fullName" : field]: string,
                        collectSNo: collectFromObj.collectSNo ? collectFromObj.collectSNo : '1',
                        partyType: 'CL',
                        fullName: ''
                    }
                    this.setState({
                        details: {
                            ...this.state.details,
                            collectFromObj: pcFrom
                        }
                    })
                    break;

                case "pickupdeliverto":
                    let pdTo = {
                        ...this.state.details.pickupdelivertoObj,
                        [field === "pickupdeliverto" ? "fullName" : field]: string,
                        collectSNo: pickupdelivertoObj.collectSNo ? pickupdelivertoObj.collectSNo : '2',
                        partyType: 'PDT',
                        fullName: ''
                    }
                    this.setState({
                        details: {
                            ...this.state.details,
                            pickupdelivertoObj: pdTo
                        }
                    })

                    break;
                case "deliverycollectfrom":
                    let dcFrom = {
                        ...this.state.details.deliverycollectfromObj,
                        [field === "deliverycollectfrom" ? "fullName" : field]: string,
                        collectSNo: deliverycollectfromObj.collectSNo ? deliverycollectfromObj.collectSNo : '3',
                        partyType: 'DCF',
                        fullName: ''
                    }
                    this.setState({
                        details: {
                            ...this.state.details,
                            deliverycollectfromObj: dcFrom
                        }
                    })

                    break;
                case "deliverydeliverto":
                    let ddTo = {
                        ...this.state.details.deliverydelivertoObj,
                        [field === "deliverydeliverto" ? "fullName" : field]: string,
                        collectSNo: deliverydelivertoObj.collectSNo ? deliverydelivertoObj.collectSNo : '4',
                        partyType: 'DDT',
                        fullName: ''
                    }
                    this.setState({
                        details: {
                            ...this.state.details,
                            deliverydelivertoObj: ddTo
                        }
                    })
                    break;
                case "serviceRequest":
                    let val = typeof string === 'string' ? string.split(',') : string
                    this.setState({
                        details: {
                            ...this.state.details,
                            serviceRequest: val
                        }
                    })
                    break;

                default:
                    const  regex = /^(\d{1,10}(\.\d{0,4})?)?$/;
                    if((field === "totalPcs" && string.length > 9) || ((field === "totalWgt" ||field === "totalVol") && (string.length > 15 || !regex.test(string)))){
                        break;
                    }else {
                        await this.setState({
                            [parent]: {
                                ...this.state.details,
                                [field]: string
                            },
                            errordetails: {
                                ...this.state.errordetails,
                                [field]: ''
                            }
                        });
                        break;
                    }

            }
        }
        else {
            switch (field) {
                case "collectFrom":
                    let updatedAddresses1 = pickupCollectAddress.map((el, index) => {
                        return position === index ? { ...el, selected: true } : { ...el, selected: false }
                    })
                    if (position) {
                        this.setState({
                            pickupCollectAddress: updatedAddresses1,
                            details: {
                                ...this.state.details,
                                collectFromObj: {
                                    ...pickupCollectAddress[position],
                                    collectSNo: collectFromObj.collectSNo ? collectFromObj.collectSNo : '1',
                                    partyType: 'CL',
                                    partyId: pickupCollectAddress[position].value,
                                    fullName: pickupCollectAddress[position].label,
                                    addr1: pickupCollectAddress[position].addr1 ? pickupCollectAddress[position].addr1 : '',
                                    addr2: pickupCollectAddress[position].addr2 ? pickupCollectAddress[position].addr2 : '',
                                    addr3: pickupCollectAddress[position].addr3 ? pickupCollectAddress[position].addr3 : '',
                                    addr4: pickupCollectAddress[position].addr4 ? pickupCollectAddress[position].addr4 : '',
                                },
                                pickupdeliverto: pickupCollectAddress[position].label

                            }
                        })
                    }
                    else {
                        this.setState({
                            details: {
                                ...this.state.details,
                                collectFromObj: {
                                    fullName: '',
                                    addr1: '',
                                    addr2: '',
                                    addr3: '',
                                    addr4: '',
                                    partyType: 'CL',
                                    collectSNo: 1
                                }
                            }
                        })
                    }
                    break;

                case "pickupdeliverto":
                    let updatedAddresses2 = pickupDeliveryAddress.map((el, index) => {
                        return position === index ? { ...el, selected: true } : { ...el, selected: false }
                    })
                    if (position) {
                        this.setState({
                            pickupDeliveryAddress: updatedAddresses2,
                            details: {
                                ...this.state.details,
                                pickupdelivertoObj: {
                                    ...pickupDeliveryAddress[position],
                                    collectSNo: pickupdelivertoObj.collectSNo ? pickupdelivertoObj.collectSNo : '2',
                                    partyType: 'PDT',
                                    partyId: pickupDeliveryAddress[position].value,
                                    fullName: pickupDeliveryAddress[position].label,
                                    addr1: pickupDeliveryAddress[position].addr1 ? pickupDeliveryAddress[position].addr1 : '',
                                    addr2: pickupDeliveryAddress[position].addr2 ? pickupDeliveryAddress[position].addr2 : '',
                                    addr3: pickupDeliveryAddress[position].addr3 ? pickupDeliveryAddress[position].addr3 : '',
                                    addr4: pickupDeliveryAddress[position].addr4 ? pickupDeliveryAddress[position].addr4 : '',
                                },
                                pickupdeliverto: pickupDeliveryAddress[position].label

                            }
                        })
                    }
                    else {
                        this.setState({
                            details: {
                                ...this.state.details,
                                pickupdelivertoObj: {
                                    fullName: '',
                                    addr1: '',
                                    addr2: '',
                                    addr3: '',
                                    addr4: '',
                                    partyType: 'PDT',
                                    collectSNo: 2
                                }
                            }
                        })
                    }
                    break;
                case "deliverycollectfrom":
                    let updatedAddresses3 = deliverCollectAddress.map((el, index) => {
                        return position === index ? { ...el, selected: true } : { ...el, selected: false }
                    })
                    if (position) {
                        this.setState({
                            deliverCollectAddress: updatedAddresses3,
                            details: {
                                ...this.state.details,
                                deliverycollectfromObj: {
                                    ...deliverCollectAddress[position],
                                    collectSNo: deliverycollectfromObj.collectSNo ? deliverycollectfromObj.collectSNo : '3',
                                    partyType: 'DCF',
                                    partyId: deliverCollectAddress[position].value,
                                    fullName: deliverCollectAddress[position].label,
                                    addr1: deliverCollectAddress[position].addr1 ? deliverCollectAddress[position].addr1 : '',
                                    addr2: deliverCollectAddress[position].addr2 ? deliverCollectAddress[position].addr2 : '',
                                    addr3: deliverCollectAddress[position].addr3 ? deliverCollectAddress[position].addr3 : '',
                                    addr4: deliverCollectAddress[position].addr4 ? deliverCollectAddress[position].addr4 : '',
                                },
                                pickupdeliverto: deliverCollectAddress[position].label

                            }
                        })
                    }
                    else {
                        this.setState({
                            details: {
                                ...this.state.details,
                                deliverycollectfromObj: {
                                    fullName: '',
                                    addr1: '',
                                    addr2: '',
                                    addr3: '',
                                    addr4: '',
                                    partyType: 'DCF',
                                    collectSNo: 3
                                }
                            }
                        })
                    }
                    break;
                case "deliverydeliverto":
                    let updatedAddresses4 = deliverDeliveryAddress.map((el, index) => {
                        return position === index ? { ...el, selected: true } : { ...el, selected: false }
                    })
                    if (position) {
                        this.setState({
                            deliverDeliveryAddress: updatedAddresses4,
                            details: {
                                ...this.state.details,
                                deliverydelivertoObj: {
                                    ...deliverDeliveryAddress[position],
                                    collectSNo: deliverydelivertoObj.collectSNo ? deliverydelivertoObj.collectSNo : '4',
                                    partyType: 'DDT',
                                    partyId: deliverDeliveryAddress[position].value,
                                    fullName: deliverDeliveryAddress[position].label,
                                    addr1: deliverDeliveryAddress[position].addr1 ? deliverDeliveryAddress[position].addr1 : '',
                                    addr2: deliverDeliveryAddress[position].addr2 ? deliverDeliveryAddress[position].addr2 : '',
                                    addr3: deliverDeliveryAddress[position].addr3 ? deliverDeliveryAddress[position].addr3 : '',
                                    addr4: deliverDeliveryAddress[position].addr4 ? deliverDeliveryAddress[position].addr4 : '',
                                },
                                pickupdeliverto: deliverDeliveryAddress[position].label

                            }
                        })
                    }
                    else {
                        this.setState({
                            details: {
                                ...this.state.details,
                                deliverydelivertoObj: {
                                    fullName: '',
                                    addr1: '',
                                    addr2: '',
                                    addr3: '',
                                    addr4: '',
                                    partyType: 'DDT',
                                    collectSNo: 4
                                }
                            }
                        })
                    }
                    break;
                default:
                    await this.setState({
                        [field]: string
                    })
                    break;
            }

        }

        if (field === 'bizType' || field === 'shipmentType') {
            if ((this.state.details.bizType === 'SE' || this.state.details.bizType === 'SI') && this.state.details.shipmentType === 'FCL') {
                this.setState({
                    containerslist :[],
                    vehiclesContainersList:[],
                })
                await this.enableorDisableContainers('enable')
            }
            else if (this.state.details.bizType === 'IL' && this.state.details.shipmentType === 'FTL') {
                await this.enableorDisableLoadUnit('enable')
                this.setState({
                    vehiclesContainersList:[],
                    containerslist :[]
                })
            }
            else {
                await this.enableorDisableLoadUnit('disable')
                await this.enableorDisableContainers('disable')
            }

            if (field === "bizType") {  
                const clearAutoComplete = (ref) => {
                    if(ref && ref.current !== null){
                        const clearIndicator = ref.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                        if(clearIndicator){
                            clearIndicator.click();
                        }
                    }
                }
                clearAutoComplete(this.podCityRef)
                clearAutoComplete(this.podCountryRef)
                clearAutoComplete(this.polCityRef)
                clearAutoComplete(this.polCountryRef)
                
                if(!!string){
                    let obj = serviceRequests[string] ?? []
                    await this.setState({
                        details:{
                            ...this.state.details,
                            systemSerReqs :obj,
                            serviceRequest:[]
                        }
                    })
                }
                const updateDefaults = async(newdetails,defaultValues)=>{
                    await this.setState({
                        details:{
                            ...newdetails,
                            ...defaultValues
                        }
                    })
                }
                const cityName = (cityList,city)=>{
                    const res = cityList.find(x =>x.value === city)
                    return res?.description ?? ""
                }
    
                const defaults ={
                    AE:{
                        totalPcsUt : airDefaults.airPcsUnit ?? "",
                        totalWgtUt : airDefaults.airWgtUnit ?? "",
                        totalVolUt : airDefaults.airVolUnit ?? "",
                        orgCountry : airDefaults.airOriginCtry ?? "",
                        orgCity    : airDefaults.airOriginCity ?? "",
                        orgCityName:  await cityName(airOriginCityList, airDefaults.airOriginCity),
                        origins    : airOriginCityList ?? [],
                        polCountry : "",
                        polCity    : "",
                    },
                    SE:{
                        totalPcsUt: seaDefaults.seaPcsUnit ?? "",
                        totalWgtUt: seaDefaults.seaWgtUnit ?? "",
                        totalVolUt: seaDefaults.seaVolUnit ?? "",
                        polCountry: seaDefaults.seaPolCtry ?? "",
                        polCity   : seaDefaults.seaPolCity ?? "",
                        polCityName : await cityName(seaPolCityList, seaDefaults.seaPolCity),
                        polCities   : seaPolCityList ?? [],
                        orgCountry : "",
                        orgCity    : "",
                    },
                    IL:{
                        totalPcsUt: roadDefaults.roadPcsUnit ?? "",
                        totalWgtUt: roadDefaults.roadWgtUnit ?? "",
                        totalVolUt: roadDefaults.roadVolUnit ?? "",
                        orgCountry:"",
                        orgCity:"",
                        polCountry:"",
                        polCity:"" ,
                        orgCityName:"",
                        polCityName:"",
                    },
                    default:{
                        totalPcsUt:"",
                        totalWgtUt:"",
                        totalVolUt:"",
                        polCountry:"",
                        polCity:"" ,
                        orgCountry:"",
                        orgCity:"",
                        orgCityName:"",
                        polCityName:"",
                        origins: this.state.details?.origins ?? [],
                        polCities: this.state.details?.polCities ?? []
                    }
                }
                const {bizType} = this.state.details;

                if( string === "AE" || string === "SE" || string ==="IL"){
                    const defaultValues = defaults[string] ||defaults.default; 
                    await updateDefaults(this.state.details, defaultValues)
                }else{
                    const defaultValues = defaults.default;
                    await updateDefaults(this.state.details, defaultValues)
                }
                const resetDetails ={
                    details:{
                        ...this.state.details,
                        shipmentType: '',
                        podCity: '',
                        podCityName: '',
                        podCountry: '',
                        destCity: '',
                        destCountry: '',
                        destName: ''
                    },
                    errordetails: {
                        ...this.state.errordetails,
                        destCity: '',
                        destCountry: '',
                        podCity: '',
                        podCountry: '',
                        polCountry:'',
                        polCity:'',
                        orgCity:'',
                        orgCountry:''
                    }
                }
                
                if (bizType === 'AE' || bizType === 'AI') {
                    this.setState({
                        ...resetDetails,
                        moveType:''
                    })
                } else {
                    this.setState(resetDetails)
                }
            }
        }

        if (field === "templateName"){
            this.setState({
                templateNameGenflag:false
            })
            if(string.length !== 0) {
                this.setState({
                    errordetails: {
                        ...this.state.errordetails,
                        templateName: ''
                    }
                })
           }
        }
        if (formFor === "bookingTemplate" && (field === "bizType" || field === "commodity" || field === "specialRqs")) {
            this.generateTemplateName()
        }
    }
    enableShipperOrConsgineeAddress = (name) => {
        if (name === 'shipper') {
            this.setState({
                details: {
                    ...this.state.details,
                    enableShipperAddress: !this.state.details.enableShipperAddress
                }
            })
        } else if (name === 'consignee') {
            this.setState({
                details: {
                    ...this.state.details,
                    enableConsigneeAddress: !this.state.details.enableConsigneeAddress
                }
            })
        }else if (name === 'notify') {
            this.setState({
                details: {
                    ...this.state.details,
                    enableNotifyAddress: !this.state.details.enableNotifyAddress
                }
            })
        }
    }
    handleDateorTimeChange = (field, value) => {
        const { intl } = this.props
        let currentDate = new Date().toLocaleDateString() ?? "";
        let selectedDate = value?.toDate().toLocaleDateString();
        let currentDateFormat = currentDate.split('/').map(part => part.padStart(2, '0')).reverse().join('-');
        let selectDateFormat = selectedDate.split('/').map(part => part.padStart(2, '0')).reverse().join('-');
        let currentYear = new Date().getFullYear();
        let selectedYear = value ? new Date(value).getFullYear() : null;
        let isValidDate = selectDateFormat ?  (selectedYear >= currentYear && (selectDateFormat >= currentDateFormat) ): true;    

        const  updateState ={
            details: {
                ...this.state.details,
                [field]: value
            },
            errordetails:{
                ...this.state.errordetails,
            }
        };

        if (field === "readyDate") {
            updateState.errordetails.readyDate = !isValidDate ? intl.formatMessage({ id: 'dateError' }) : "";
        }else if(field === 'deliveryreadyDate'){
            updateState.errordetails.deliveryreadyDate= !isValidDate ? intl.formatMessage({ id: 'dateError' }) : '';
        }
        this.setState(updateState);
    }

    handlePickupFromToTimings = (variable, time1, time2) => {
        const { intl } = this.props
        if (time2 === null || time1 === null) {
            this.setState(prevState => ({
              details: {
                ...prevState.details,
                [variable]: variable === "fromTime" || variable === "deliveryfromTime" ? time1 : time2
              },
              errordetails: {
                ...prevState.errordetails,
                pickup_timeValidationErrorMessage: variable === "fromTime" || variable === "toTime" ? '' : prevState.errordetails.pickup_timeValidationErrorMessage,
                delivery_timeValidationErrorMessage: variable === "deliveryfromTime" || variable === "deliverytoTime" ? '' : prevState.errordetails.delivery_timeValidationErrorMessage
              }
            }));
        }
        else if (time1 !== null && time1.isValid() && time2 !== null && time2.isValid()) {
            if (time1.isSameOrBefore(time2)) {
              this.setState(prevState => ({
                details: {
                  ...prevState.details,
                  [variable]: variable === "fromTime" || variable === "deliveryfromTime" ? time1 : time2
                },
                errordetails: {
                  ...prevState.errordetails,
                  pickup_timeValidationErrorMessage: variable === "fromTime" || variable === "toTime" ? '' : prevState.errordetails.pickup_timeValidationErrorMessage,
                  delivery_timeValidationErrorMessage: variable === "deliveryfromTime" || variable === "deliverytoTime" ? '' : prevState.errordetails.delivery_timeValidationErrorMessage
                }
              }));
            } else {
              this.setState(prevState => ({
                details: {
                    ...prevState.details,
                    [variable]: variable === "toTime" || variable === "deliverytoTime" ? time2 : time1
                  },
                errordetails: {
                  ...prevState.errordetails,
                  pickup_timeValidationErrorMessage: variable === "fromTime" || variable === "toTime" ? intl.formatMessage({ id: 'timeError' }) : prevState.errordetails.pickup_timeValidationErrorMessage,
                  delivery_timeValidationErrorMessage: variable === "deliveryfromTime" || variable === "deliverytoTime" ? intl.formatMessage({ id: 'timeError' }) : prevState.errordetails.delivery_timeValidationErrorMessage
                }
              }));
            }
        }
    }

    getaddresses = async (deliverfield) => {
        let pickup = deliverfield
        let collectFromfecth = await Promise.all([fetchParties(pickup)])
        let collectFrom = collectFromfecth ? (collectFromfecth[0] === undefined ? [] : (collectFromfecth[0].lookupItems ? collectFromfecth[0].lookupItems : [])) : []
        let update = []
        for (let i = 0; i < collectFrom.length; i++) {
            if (collectFrom[i].addresses) {
                for (let j = 0; j < collectFrom[i].addresses.length; j++) {
                    update.push({
                        ...collectFrom[i],
                        ...collectFrom[i].addresses[j],
                        selected: false,
                        address: collectFrom[i].label + ',' + collectFrom[i].addresses[j].address,
                        addressType: collectFrom[i].addresses[j].addrTypeDesc,
                        addresses: undefined,
                        uniqueAddrCode:collectFrom[i].addresses[j].addrTypeDesc+collectFrom[i].label + ',' + collectFrom[i].addresses[j].address
                    })
                }
            }
            else {
                update.push({
                    ...collectFrom[i],
                    addr1: '',
                    addr2: '',
                    addr3: '',
                    addr4: '',
                    selected: false,
                    address: collectFrom[i].label
                })
            }
        }
        return update
    }
    handleToggleButtonChange = async (event, parent, name) => {
        const field = name
        const value = event[0]
        if (parent) {
            await this.setState({
                [parent]: {
                    ...this.state.details,
                    [field]: value
                },
                errordetails: {
                    ...this.state.errordetails,
                    [field]: ''
                }
            });
        }
        else {
            this.setState({
                [field]: value
            })

        }
        if (field === "pickupRequired" && value === false) {
            if (this.checkIsPickuporDeliveryDataExist("pickupRequired")) {
                this.setState({
                    showPickupConfirm: true
                })
            }
        }
        if (field === "deliveryRequired" && value === false) {
            if (this.checkIsPickuporDeliveryDataExist("deliveryRequired")) {
                this.setState({
                    showDeliveryConfirm: true
                })
            }
        }
    }

    handleSwtichChange = async (event, parent) => {
        const field = event.target.name
        const value = event.target.checked
        if (parent) {
            await this.setState({
                [parent]: {
                    ...this.state.details,
                    [field]: value
                },
                errordetails: {
                    ...this.state.errordetails,
                    [field]: ''
                }
            });
        }
        else {
            if (field === 'enableshipperAddress') {
                await this.setState({
                    details: {
                        ...this.state.details,
                        enableShipperAddress: value
                    }
                });
            }
            if (field === 'enableconsigneeAddress') {
                await this.setState({
                    details: {
                        ...this.state.details,
                        enableConsigneeAddress: value
                    }
                });
            }
            if (field === 'enablenotifyAddress') {
                await this.setState({
                    details: {
                        ...this.state.details,
                        enableNotifyAddress: value
                    }
                });
            }
            else {
                this.setState({
                    [field]: value
                })
            }
        }
        if (field === "pickupRequired" && value === false) {
            if (this.checkIsPickuporDeliveryDataExist("pickupRequired")) {
                this.setState({
                    showPickupConfirm: true
                })
            }
        }
        if (field === "deliveryRequired" && value === false) {
            if (this.checkIsPickuporDeliveryDataExist("deliveryRequired")) {
                this.setState({
                    showDeliveryConfirm: true
                })
            }
        }
    };
    checkIsPickuporDeliveryDataExist = (field) => {
        if (field === "pickupRequired") {
            const { readyDate, fromTime, toTime, pickupRemarks, collectFromObj, pickupdelivertoObj } = this.state.details
            if (readyDate !== null || fromTime !== null || toTime !== null || pickupRemarks !== '') {
                return true;
            } else if ((collectFromObj || pickupdelivertoObj)) {
                if ((collectFromObj.collectFrom && collectFromObj.collectFrom !== '') || (collectFromObj.addr1 && collectFromObj.addr1 !== '') || (collectFromObj.addr2 && collectFromObj.addr2 !== '') || (collectFromObj.addr3 && collectFromObj.addr3 !== '') || (collectFromObj.addr4 && collectFromObj.addr4 !== '') ||
                    (collectFromObj.pickupdeliverto && collectFromObj.pickupdeliverto !== '') || (pickupdelivertoObj.addr1 && pickupdelivertoObj.addr1 !== '') || (pickupdelivertoObj.addr2 && pickupdelivertoObj.addr2 !== '') || (pickupdelivertoObj.addr3 && pickupdelivertoObj.addr3 !== '') || (pickupdelivertoObj.addr4 && pickupdelivertoObj.addr4 !== '')) {
                    return true;
                }
            }
        } else {
            const { deliverycollectfrom, deliverydeliverto, deliveryreadyDate, deliveryfromTime, deliverytoTime, deliveryRemarks, deliverycollectfromObj, deliverydelivertoObj } = this.state.details
            if (deliverycollectfrom !== '' || deliverydeliverto !== '' || deliveryreadyDate !== null || deliveryfromTime !== null || deliverytoTime !== null || deliveryRemarks !== '') {
                return true;
            } else if ((deliverycollectfromObj || deliverydelivertoObj)) {
                if ((deliverycollectfromObj.addr1 && deliverycollectfromObj.addr1 !== '') || (deliverycollectfromObj.addr2 && deliverycollectfromObj.addr2 !== '') || (deliverycollectfromObj.addr3 && deliverycollectfromObj.addr3 !== '') || (deliverycollectfromObj.addr4 && deliverycollectfromObj.addr4 !== '') ||
                    (deliverydelivertoObj.addr1 && deliverydelivertoObj.addr1 !== '') || (deliverydelivertoObj.addr2 && deliverydelivertoObj.addr2 !== '') || (deliverydelivertoObj.addr3 && deliverydelivertoObj.addr3 !== '') || (deliverydelivertoObj.addr4 && deliverydelivertoObj.addr4 !== '')) {
                    return true;
                }
            }
        }
        return false;
    }
    clearPickupOrDeliveryData = (field) => {
        if (field === "pickupRequired") {
            this.setState({
                details: {
                    ...this.state.details,
                    collectFrom: "",
                    collectFromObj: {
                        ...this.state.details.collectFromObj,
                        addr1: "",
                        addr2: "",
                        addr3: "",
                        addr4: "",
                        fullName: "",
                        collectSNo: this.state.details.collectFromObj.collectSNo ? this.state.details.collectFromObj.collectSNo : '1',
                        partyType: 'CL',
                    },
                    pickupdeliverto: "",
                    pickupdelivertoObj: {
                        ...this.state.details.pickupdelivertoObj,
                        addr1: "",
                        addr2: "",
                        addr3: "",
                        addr4: "",
                        fullName: "",
                        collectSNo: this.state.details.pickupdelivertoObj.collectSNo ? this.state.details.pickupdelivertoObj.collectSNo : '2',
                        partyType: 'PDT',
                    },
                    readyDate: null,
                    fromTime: null,
                    toTime: null,
                    pickupRequired: false,
                    pickupRemarks: ''
                },
                showPickupConfirm: false
            })
        } else {
            this.setState({
                details: {
                    ...this.state.details,
                    deliverycollectfrom: "",
                    deliverycollectfromObj: {
                        ...this.state.details.deliverycollectfromObj,
                        addr1: "",
                        addr2: "",
                        addr3: "",
                        addr4: "",
                        fullName: "",
                        collectSNo: this.state.details.deliverycollectfromObj.collectSNo ? this.state.details.deliverycollectfromObj.collectSNo : '3',
                        partyType: 'DCF',
                    },
                    deliverydeliverto: "",
                    deliverydelivertoObj: {
                        ...this.state.details.deliverydelivertoObj,
                        addr1: "",
                        addr2: "",
                        addr3: "",
                        addr4: "",
                        fullName: "",
                        collectSNo: this.state.details.deliverydelivertoObj.collectSNo ? this.state.details.deliverydelivertoObj.collectSNo : '4',
                        partyType: 'DDT',
                    },
                    deliveryreadyDate: null,
                    deliveryfromTime: null,
                    deliverytoTime: null,
                    deliveryRequired: false,
                    deliveryRemarks: ''
                },
                showDeliveryConfirm: false
            })
        }
    }
    retainPickupOrDeliveryData = (field) => {
        if (field === 'pickupRequired') {
            this.setState({
                showPickupConfirm: false,
                details: {
                    ...this.state.details,
                    pickupRequired: true,
                }
            })
        } else {
            this.setState({
                showDeliveryConfirm: false,
                details: {
                    ...this.state.details,
                    deliveryRequired: true,
                }
            })
        }
    }
    handleOnClickMenuItem = async (event) => {

        let mentList = this.state.sideHeaders;
        mentList = mentList.map((listItem) => {
            return event === listItem.name ? listItem.selected = 'true' : listItem.selected = 'false'
        })
        await this.setState({
            list: mentList
        })
        switch (event) {
            case "Quote": this.quotesDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            case "Details": this.detailsDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            case "Containers": this.containersDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            case "Load Unit": this.containersDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            case "Items": this.itemsDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            case "Documents": this.documentsDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            case "Audit Trail": this.aduitTrailDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            default:
                break;
        }
    }

    removeContainers = (id) => {
        const { containerslist,vehiclesContainersList,details } = this.state;
        let vehicalslist = details.bizType === 'IL' && details.shipmentType === 'FTL';

        let updatedcontainerslist = containerslist.filter((item) => {
            return item.id !== id;
        });
        let updatedvehicleContainerlist = vehiclesContainersList.filter((item) => { return item.id !== id;});
        this.setState({
            vehiclesContainersList: vehicalslist? updatedvehicleContainerlist: vehiclesContainersList,
            selectedContainer: null,
            containerslist: vehicalslist? containerslist: updatedcontainerslist
        })
    }
    addContainers = () => {
        const { containerslist,vehiclesContainersList,details } = this.state
        let id = containerslist[containerslist.length - 1] ? containerslist[containerslist.length - 1].id ? containerslist[containerslist.length - 1].id + 1 : containerslist.length + 1 : 1
        let vid = vehiclesContainersList && vehiclesContainersList[vehiclesContainersList.length - 1] ? vehiclesContainersList[vehiclesContainersList.length - 1].id ? vehiclesContainersList[vehiclesContainersList.length - 1].id + 1 : vehiclesContainersList.length + 1 : 1
        let updatedcontainerslist = [...containerslist, { id: id, type: '', qty: '', }]
        let updatedvehicleContainerlist = [...vehiclesContainersList, { id: vid, type: '', qty: '', }]
        let vehicalslist = details.bizType === 'IL' && details.shipmentType === 'FTL';

        vehicalslist ?
        this.setState({
            vehiclesContainersList : updatedvehicleContainerlist,
        }):
        this.setState({
            selectedContainer: id,
            selectedcontainerData: null,
            containerslist: updatedcontainerslist
        })
    }
    onChangeContainerfields = async (id, field, value) => {
        const { containerslist,vehiclesContainersList,details } = this.state
        let updatedcontainerslist = containerslist.map(container => {
            // For qty type , we should not allow decimals.
            // Fixed as part of https://jira.blujaysolutions.com/browse/CPOR-199
            let val1 = field === 'qty' ? value === '' ? '' : Math.floor(value) : value;
            return container.id === id ? { ...container, [field]: val1 } : container
        })
        let updatedvehicleContainerlist = vehiclesContainersList.map(cont =>{
            let val =field === 'qty' ? value ==='' ? '' : Math.floor(value) : value;
            return cont.id ===id ? {...cont,[field]:val}:cont;
        })

        let vehicalslist = details.bizType === 'IL' && details.shipmentType === 'FTL';
        if (field === 'qty' ) {
            if(((value < 10000 && value > 0) || value === '' )){
                this.setState({
                    vehiclesContainersList: vehicalslist? updatedvehicleContainerlist : vehiclesContainersList,
                    containerslist: vehicalslist? containerslist: updatedcontainerslist
                })
            }
        }
        else {
            this.setState({
                vehiclesContainersList: vehicalslist ? updatedvehicleContainerlist : vehiclesContainersList,
                containerslist: vehicalslist ? containerslist : updatedcontainerslist
            })
        }
    }
    removeItems = (id, row) => {
        const { itemslist } = this.state
        let updateditemslist = itemslist.map((item) => {
            return item.itemUniqueRefNo !== undefined ?
                (item.itemUniqueRefNo === row.itemUniqueRefNo ? { ...item, actionCode: 'D', itemActionCode: 'D' } : item)
                : (item.id !== id ? item : null)
        })
        updateditemslist = updateditemslist.filter(item => {
            return item !== null
        })
        // let itemsError = updateditemslist.length > 0 ? '' : 'Atleast one item should be entered'
        this.setState({
            selectedItem: null,
            itemslist: updateditemslist,
            errordetails: {
                ...this.state.errordetails,
                //items: itemsError
            }
        })
    }
    onSaveContainersorItems = (id, type) => {
        type === 'ITEM' ?
            this.setState({
                selectedItem: null
            })
            :
            this.setState({
                selectedContainer: null
            })
    }
    onCancelContainersorItems = (id, type) => {
        const { itemslist, selectedItemData, containerslist, selectedcontainerData } = this.state
        if (type === 'ITEM') {
            let updatedItemslist = itemslist.map(item => {
                return item.itemUniqueRefNo !== undefined ?
                    (item.itemUniqueRefNo === id ? { ...selectedItemData } : item) :
                    (item.id === id ? (selectedItemData !== null ? { ...selectedItemData } : null) : item)
            })
            updatedItemslist = updatedItemslist.filter(item => {
                return item !== null
            })
            this.setState({
                itemslist: updatedItemslist,
                selectedItem: null,
                selectedItemData: null
            })
        }
        else if (type === "CONTAINER") {
            let updatedcontainerslist = containerslist.map(container => {
                return container.id === id ? (selectedcontainerData ? { ...selectedcontainerData } : null) : container
            })
            updatedcontainerslist = updatedcontainerslist.filter(container => {
                return container !== null
            })
            this.setState({
                containerslist: updatedcontainerslist,
                selectedContainer: null,
                selectedcontainerData: null
            })
        }
    }
    onEditContainersorItems = (id, type, row) => {
        type === 'ITEM' ?
            this.setState({
                selectedItem: id,
                selectedItemData: row
            })
            :
            this.setState({
                selectedContainer: id,
                selectedcontainerData: row
            })
    }
    addItems = () => {
        const { itemslist } = this.state
        let id = itemslist[itemslist.length - 1] ? itemslist[itemslist.length - 1].id ? itemslist[itemslist.length - 1].id + 1 : itemslist.length + 1 : 1
        let updateditemslist = [...itemslist,
        { id: id, description: '', itemPcs: '', itemPcsUt: '', itemWgt: '', itemWgtUt: '', itemDimLength: '', itemDimWidth: '', itemDimHeight: '', itemDimUnit: '', itemVol: '', itemVolUt: '', itemActionCode: 'A', actionCode: 'new' },
        ]

        this.setState({
            itemslist: updateditemslist,
            selectedItem: id,
            selectedItemData: null,
            errordetails: {
                ...this.state.errordetails,
                items: ''

            }
        })
    }

    // To return the number of decimal places in the given number.
    getNumberOfDecimals = (val) => {
        let numDecimals = (val.split('.')[1] || []).length;
        return numDecimals;
    }

    onChangeItemfields = async (id, field, value) => {
        const { itemslist } = this.state
        let updateditemslist = itemslist.map(item => {
            // Item's pieces should not allow decimal values.
            // Fixed by Ananth as part of https://jira.blujaysolutions.com/browse/CPOR-199
            let val1 = value === '' ? '' : field === 'itemPcs' ? Math.floor(value) :
                (('itemWgt' || 'itemVol') && this.getNumberOfDecimals(value) > 4) ?
                    (Number(value).toFixed(4)) : value;
            return item.itemUniqueRefNo !== undefined ?
                (item.itemUniqueRefNo === id ? { ...item, [field]: val1, itemActionCode: 'U' } : item)
                : (item.id === id ? { ...item, [field]: val1 } : item)
        })
        // max pieces should be less than 999999999
        if (field === 'itemPcs') {
            let val1 = value === '' ? '' : Math.floor(value);
            if (val1 > 0 && val1 < 999999999) {
                this.setState({
                    itemslist: updateditemslist
                })
            }
            else if (val1 === '') {
                this.setState({
                    itemslist: updateditemslist
                })
            }
        }

        // max item weight should be less than 9999999999.9999 
        else if (field === 'itemWgt') {
            if (value > 0 && value < 9999999999.9999) {
                this.setState({
                    itemslist: updateditemslist
                })
            }
            else if (value === '') {
                this.setState({
                    itemslist: updateditemslist
                })
            }
        }

        //  max item volume should be less than 9999999999.9999
        else if (field === 'itemVol') {
            if (value > 0 && value < 9999999999.9999) {
                this.setState({
                    itemslist: updateditemslist
                })
            }
            else if (value === '') {
                this.setState({
                    itemslist: updateditemslist
                })
            }
        }

        else {
            this.setState({
                itemslist: updateditemslist
            })
        }
    }

    getShipperOrConsigneeAddress = (addressList, value) => {
        if (addressList && addressList.length > 0) {
            return addressList.filter(item => {
                return item.value === value
            })
        }
    }
    handlePopOverOpen = async (event, field, el, addressList, value) => {
        this.setState({
            openpopUp: true,
            anchorEl: event ? event.currentTarget : el
        })
        let selectedAddressList = []
        if (field === "shipperAddress" || field === "consigneeAddress" || field === "notifyAddress" ) {
            selectedAddressList = this.getShipperOrConsigneeAddress(addressList, value);
        }

        switch (field) {
            case 'collectFrom': this.setState({ popOverField: field });
                break;
            case 'deliverTo': this.setState({ popOverField: field });
                break;
            case 'shipperAddress': await this.setState({ popOverField: field, shipperAddressList: selectedAddressList && selectedAddressList.length > 0 ? selectedAddressList[0].addresses : this.state.shipperAddressList });
                break;
            case 'consigneeAddress': await this.setState({ popOverField: field, consigneeAddressList: selectedAddressList && selectedAddressList.length > 0 ? selectedAddressList[0].addresses : this.state.consigneeAddressList })
                break;
            case 'notifyAddress' : await this.setState({popOverField: field, notifyAddressList: selectedAddressList && selectedAddressList.length > 0 ? selectedAddressList[0].addresses: this.state.notifyAddressList })
                break;
            case 'pickupdeliverto': this.setState({ popOverField: field });
                break;
            case 'deliverycollectfrom': this.setState({ popOverField: field });
                break;
            case 'deliverydeliverto': this.setState({ popOverField: field });
                break;
            default:
                break;
        }
    }
    handleClosePopUp = () => {
        this.setState({ anchorEl: null, openpopUp: false })

    };
    deleteRecord = async (unId) => {
        const { handleSuccess, handleDrawer, fromDashBoard, intl, formFor } = this.props
        this.setState({ error: " ", disableButtons: true, showConfirm: false })
        if (formFor === "booking") {
            const bookingdeleteBody = generateBookingDelete(unId);
            const res = await deleteBooking(bookingdeleteBody);
            if (res.status === 201 || res.status === 200) {
                handleSuccess("Delete", res.data, "Booking");
                handleDrawer();
                evictBookingsCache("bookstatus");
                evictBookingsCache("bookstatus");
                if (this.props.history.location.pathname === "/bookings") {
                    await this.props.history.push({
                        pathname: "/",
                        state: { widgets: false }
                    })
                    if (fromDashBoard !== undefined && fromDashBoard !== null && fromDashBoard) {
                        this.props.history.push("/reload")
                        this.props.history.push({
                            pathname: "/",
                            state: { widgets: true }
                        })
                    }
                    else {
                        this.props.history.push("/bookings")
                    }
                }
            }
            else {
                this.setState({ error: intl.formatMessage({ id: 'somethingWentWrong' }), disableButtons: false })
            }
        }
        else if (formFor === "quote") {
            const quoteDeleteBody = generateQuoteDelete(unId);
            const res = await quoteActions(quoteDeleteBody);
            if (res.status === 201 || res.status === 200) {
                handleSuccess("Delete", res.data.status, "Quote");
                handleDrawer();
                evictQuotesCache("quotestatus");
                evictQuotesCache("quotestatus");
                if (this.props.history.location.pathname === "/quotes") {
                    await this.props.history.push({
                        pathname: "/",
                        state: { widgets: false }
                    })
                    if (fromDashBoard !== undefined && fromDashBoard !== null && fromDashBoard) {
                        this.props.history.push("/reload")
                        this.props.history.push({
                            pathname: "/",
                            state: { widgets: true }
                        })
                    }
                    else {
                        this.props.history.push("/quotes")
                    }
                }
            }
            else {
                this.setState({ error: intl.formatMessage({ id: 'somethingWentWrong' }), disableButtons: false })
            }
        }
    }
    handleConfirm = () => {
        this.setState({
            showConfirm: true
        })
    }
    voidHandler = () => {
        this.setState({
            showConfirm: false
        })
    }
    voidAddressHandler = () => {
        this.setState({
            showPickupWarning: false
        })
    }

    onChangeQuoteFields = async (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    handleReject = () => {
        this.setState({
            showRejectBox: !this.state.showRejectBox
        })
    }
    quoteAction = async (type) => {
        const { handleSuccess, handleDrawer, intl } = this.props
        const { unId, rejectReason } = this.state
        let reqbody = undefined
        this.setState({ error: " ", disableButtons: true })
        switch (type) {
            case "Reject":
                reqbody = generateQuoteReject(unId, rejectReason)
                break;
            case "Accept":
                reqbody = generateQuoteAccept(unId)
                break;
            case "Book":
                reqbody = generateQuoteBook(unId)
                break;
            default:
                break;
        }
        const res = await quoteActions(reqbody);
        if (res.status === 201 || res.status === 200) {
            await evictQuotesCache("validto")
            await evictQuotesCache("validto")
            handleSuccess(type === "Book" ? "booking" : type, res.data.status, "Quote");
            handleDrawer();
            if (this.props.history.location.pathname === "/quotes") {
                await this.props.history.push({
                    pathname: "/",
                    state: { widgets: false }
                })
                this.props.history.push("/quotes")
            }
        }
        else {
            this.setState({ error: intl.formatMessage({ id: 'somethingWentWrong' }), disableButtons: false })
        }
    }
    handleBookingTemplatesChange = async (event, field) => {
        if (event != null) {
            const { intl, cpContext } = this.props
            const { docTypes, addrresses, bookingTemplates } = this.state
            let promise = []
            const formdata = getBookingData(undefined)
            await this.setState({
                ...formdata,
                details: {
                    ...formdata.details,
                    destinations: [],
                    origins: [],
                    polCities: [],
                    podCities: [],
                    customers: [],
                    systemSerReqs:[]
                },
                templateNo: event.value,
                templateLoading: true,
                readOnly: true,
                error: '',
                docTypes: docTypes,
                addresses: addrresses,
                bookingTemplates: bookingTemplates
            })
            const bookingTemplatesdata = await getBookingTemplateDataById(event.templateUnId);
            if (bookingTemplatesdata) {
                let updatedData = await this.processResponseData(bookingTemplatesdata)
                const data = getBookingDataOfTemplate(updatedData, docTypes, addrresses, cpContext);
                const isRoad = data.details.bizType === 'IL' ? true : false
                const isSea = (data.details.bizType === 'SE' || data.details.bizType === 'SI') ? true : false
                const isAir = (data.details.bizType === 'AE' || data.details.bizType === 'AI') ? true : false
                const fetchDestinations = data.details.destCountry ? getCities(data.details.destCountry, isRoad) : [];
                const fetchOrigins = data.details.orgCountry ? getCities(data.details.orgCountry, isRoad) : [];
                const fetchPOL = data.details.polCountry ? getCities(data.details.polCountry, isRoad, isSea, isAir) : [];
                const fetchPOD = data.details.podCountry ? getCities(data.details.podCountry, isRoad, isSea, isAir) : [];
                promise = await Promise.all([fetchDestinations, fetchOrigins, fetchPOL, fetchPOD]);
                await this.setState({
                    ...data,
                    details: {
                        ...data.details,
                        destinations: promise[0],
                        origins: promise[1],
                        polCities: promise[2],
                        podCities: promise[3],
                        systemSerReqs: data?.details?.bizType ? cpContext.serviceRequests[data?.details.bizType]:[]
                    },
                    templateLoading: false,
                })
            }
            else {
                const formdata = getBookingData()
                await this.setState({
                    ...formdata,
                    details: {
                        ...formdata.details,
                        destinations: [],
                        origins: [],
                        polCities: [],
                        podCities: [],
                        customers: []
                    },
                    templateLoading: false,
                    error: intl.formatMessage({ id: 'somethingWentWrong' })
                })
            }
        }
        else {
            const formdata = getBookingData()
            await this.setState({
                ...formdata,
                details: {
                    ...formdata.details,
                    destinations: [],
                    origins: [],
                    polCities: [],
                    podCities: [],
                    customers: []
                },
                error: ''
            })
        }
    }
    performPartyLookupSearch = async (value, field, getAddressFlag) => {
        const { intl } = this.props
        const { details } = this.state
        const listKey = field + 's';
        const inputKey = field + 'Name';
        const fieldValue = details[field]
        this.setState({
            errordetails: {
                ...this.state.errordetails,
                [field]: ''
            },
            details: {
                ...this.state.details,
                noOptionMsg: intl.formatMessage({ id: 'data_Loading' }),
                [listKey]: []
            }

        })
        if (value !== null && value !== '' && value !== undefined && value.trim().length >= 1) {
            const partyLookup = await Promise.all([fetchCustomers(fieldValue && fieldValue !== "" ? fieldValue : value.trim(), field === "shipper" ? "1" : field === "consignee" ? "2" : "17", getAddressFlag)]);
            let lookupData = partyLookup ? partyLookup[0] ? partyLookup[0].lookupItems : [] : []
            this.setState({
                details: {
                    ...this.state.details,
                    [listKey]: lookupData,
                    [inputKey]: value.trim(),
                    noOptionMsg: ''
                },
                autoFocusCustomer: field === 'customer' ? true : false,
                autoFocusShipper: field === 'shipper' ? true : false,
                autoFocusConsignee: field === 'consignee' ? true : false
            })
            if (partyLookup && partyLookup[0] && partyLookup[0].totalRecords === 0) {
                this.setState({
                    errordetails: {
                        ...this.state.errordetails,
                        [field]: intl.formatMessage({ id: 'noRecords_partyId_lookup' })
                    }
                })
            }
        } else {
            this.setState({
                errordetails: {
                    ...this.state.errordetails,
                    [field]: intl.formatMessage({ id: 'select_partyId_value' })
                },
                details: {
                    ...this.state.details,
                    noOptionMsg: ''
                }
            })
        }
    }
    handleTypeAheadInputChange = (value, field, event) => {
        const { details } = this.state
        const fieldIdValue = details[field]
        const inputKey = field + "Name"
        const addressListKey = field + "AddressList"
        if (event) {
            this.setState({
                details: {
                    ...this.state.details,
                    [inputKey]: value.toUpperCase(),
                    [field]: ""
                },
                [addressListKey]: fieldIdValue === "" ? [] : this.state[addressListKey],
                errordetails: {
                    ...this.state.errordetails,
                    [field]: ''
                }
            })
        }
    }
    handleEnableorDisableTemplate = async () => {
        const { templateStatus } = this.state
        const { handleSuccess, handleDrawer, intl } = this.props
        await this.setState({ disableButtons: true })
        const templateReqBody = generateTemplateEnableorDisableRequest(this.state);
        const res = await bookingTemplateActions(templateReqBody);
        if (res.status === 201 || res.status === 200) {
            handleSuccess(templateStatus === 'A' ? 'Disable' : 'Enable', res.data, "Template");
            handleDrawer();
            if (this.props.history.location.pathname === "/templates") {
                this.props.history.push("/reload")
                this.props.history.push({
                    pathname: "/templates",
                })
            }
        }
        else {
            this.setState({ error: intl.formatMessage({ id: 'somethingWentWrong' }), disableButtons: false })
        }
    }
    generateTemplateName = async () => {
        const { details, unId, templateNamegen, templateName, templateNameGenflag } = this.state         
        if (unId === null && templateNameGenflag) {
            let findManualEntry = templateName;
            if (templateName !== '' && templateName !== undefined) {
                const values = Object.values(templateNamegen)
                await values.forEach((value) => {
                    if (value !== '') {
                        findManualEntry = findManualEntry.replace(value + ' ', '')
                    }
                });
            }
            const { bizType, shipper, consignee, orgCountry, orgCity, destCountry, destCity, commodity, specialRqs } = details
            let origin = orgCity !== '' ? orgCountry + orgCity : ''
            let destination = destCity !== '' ? destCountry + destCity : ''

            const updateTemplateNamegen = {
                mot: bizType,
                ship: shipper,
                cons: consignee,
                orig: origin,
                dest: destination,
                comm: commodity,
                remrk: specialRqs
            }
            await this.setState({
                templateNamegen: updateTemplateNamegen,
                autoFocusCustomer: false,
                autoFocusShipper: false,
                autoFocusConsignee: false
            })
            let array = [bizType, shipper, consignee, origin, destination, commodity, specialRqs]
            array = array.filter(a => a.length > 0)
            let string = array.join(" ")
            if (findManualEntry.trim() !== '') {
                string = string + ' ' + findManualEntry.trim();
            }

            await this.setState({
                templateName: string + ' ',
            })
        }
    }
    handleInputChange = (value, field) => {
        if (field === "shipper") {
            this.setState({
                details: {
                    ...this.state.details,
                    shipperAddress: {},
                    shipperDefaultAddress: {}
                },
                shipperAddressList: []
            })
        }
        else if (field === "consignee") {
            this.setState({
                details: {
                    ...this.state.details,
                    consigneeAddress: {},
                    consigneeDefaultAddress: {}
                },
                consigneeAddressList: []
            })
        }else if (field === "notify") {
            this.setState({
                details: {
                    ...this.state.details,
                    notifyAddress: {},
                    notifyDefaultAddress: {}
                },
                notifyAddressList: []
            })
        }

        this.setState({

            details: {
                ...this.state.details,
                [field + "Name"]: value
            },
            errordetails: {
                ...this.state.errordetails,
                [field]: ''
            }
        })
    }
    getDocDownloadErrorDetails = async (msg, status) => {
        await this.setState({ snackBar: { show: false, msg: '', status: 'info' } })
        await this.setState({ snackBar: { show: true, msg: msg, type: status === 204 ? 'info' : 'error' } })
    }
    ClearDataOnClick = () => {
        this.setState({
            autoFocusCustomer: false,
            autoFocusShipper: false,
            autoFocusConsignee: false
        })
    }
    showorHideExtraFields = (field) => {
        const { readOnlyHeaderList } = this.state
        let updatedreadOnlyHeaderList = readOnlyHeaderList.map(item => item.title === field ? { ...item, showExtraField: !item.showExtraField } : item)
        this.setState({
            readOnlyHeaderList: updatedreadOnlyHeaderList
        })
    }
    render() {
        const { handleDrawer, formFor } = this.props
        const { fileTypes, fileSize, fileErrors,supReadyDate} = this.state
        return (
            <CPCollectiveForm
                formFor={formFor}
                onSubmit={this.onSubmit}
                handleDrawer={handleDrawer}
                handleClosePopUp={this.handleClosePopUp}
                handleOnclickPopUp={this.handleOnclickPopUp}
                handleOnClickMenuItem={this.handleOnClickMenuItem}
                quotesDivToFocus={this.quotesDivToFocus}
                detailsDivToFocus={this.detailsDivToFocus}
                containersDivToFocus={this.containersDivToFocus}
                itemsDivToFocus={this.itemsDivToFocus}
                documentsDivToFocus={this.documentsDivToFocus}
                aduitTrailDivToFocus={this.aduitTrailDivToFocus}
                onChange={this.onChange}
                handleCountryChange={this.handleCountryChange}
                handleDateorTimeChange={this.handleDateorTimeChange}
                handlePickupFromToTimings={this.handlePickupFromToTimings}
                handleSwtichChange={this.handleSwtichChange}
                handlePopOverOpen={this.handlePopOverOpen}
                handleTypeaheadChange={this.handleTypeaheadChange}
                removeContainers={this.removeContainers}
                addContainers={this.addContainers}
                onChangeContainerfields={this.onChangeContainerfields}
                removeItems={this.removeItems}
                addItems={this.addItems}
                onChangeItemfields={this.onChangeItemfields}
                data={this.state}
                supReadyDate={supReadyDate}
                handleUploadFile={this.handleUploadFile}
                removeNewDocFunc={this.handleremoveNewDocFunc}
                enableIconsForDocs={this.handleEnableIconsForDocs}
                onChangeDocumentfields={this.onChangeDocumentfields}
                handleDeleteExistingDocs={this.handleDeleteExistingDocs}
                orgCityRef={this.orgCityRef}
                destCityRef={this.destCityRef}
                polCityRef={this.polCityRef}
                podCityRef={this.podCityRef}
                podCountryRef={this.podCountryRef}
                polCountryRef={this.polCountryRef}
                deleteRecord={this.deleteRecord}
                voidHandler={this.voidHandler}
                handleConfirm={this.handleConfirm}
                onSaveContainersorItems={this.onSaveContainersorItems}
                onEditContainersorItems={this.onEditContainersorItems}
                onCancelContainersorItems={this.onCancelContainersorItems}
                quoteAction={this.quoteAction}
                onChangeQuoteFields={this.onChangeQuoteFields}
                handleReject={this.handleReject}
                fileTypes={fileTypes}
                fileErrors={fileErrors}
                fileSize={fileSize}
                handleBookingTemplatesChange={this.handleBookingTemplatesChange}
                performPartyLookupSearch={this.performPartyLookupSearch}
                handleTypeAheadInputChange={this.handleTypeAheadInputChange}
                handleEnableorDisableTemplate={this.handleEnableorDisableTemplate}
                onInputChange={this.handleInputChange}
                enableShipperOrConsgineeAddress={this.enableShipperOrConsgineeAddress}
                onChangeAddress={this.onChangeAddress}
                clearPickupOrDeliveryData={this.clearPickupOrDeliveryData}
                retainPickupOrDeliveryData={this.retainPickupOrDeliveryData}
                getDocDownloadErrorDetails={this.getDocDownloadErrorDetails}
                handleToggleButtonChange={this.handleToggleButtonChange}
                ClearDataOnClick={this.ClearDataOnClick}
                showorHideExtraFields={this.showorHideExtraFields}
            />
        );
    }

}

export default injectIntl(withCPContext(withRouter(CPCollectiveContainer)))

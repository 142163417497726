import NonEditableDataGrid from "../../common/NonEditableDataGrid";

function InBondConveyance(props) {
    const inBondHeader = [
        { title: "Import Method of Trans.", field: "mot", tooltipField: "motDesc" },
        { title: "Importing Carrier", field: "carrier", tooltipField: "conveyanceName" },
        { title: "Voyage/Flight", field: "voyageFlight" },
        { title: "Flag Country", field: "country" },
        { title: "US Port of Arrival", field: "distPortUnlading", tooltipField: "distPortUnladingName" },
        { title: "Arrival Date", field: "dateArrivalDt" },
        { title: "FTZ/Whse Firms Code", field: "firmsCode" }
    ]

    const { data} = props
    return (
        <div>
            <NonEditableDataGrid list={inBondHeader} data={data ? data : {}} />
        </div>
    );
}

export default InBondConveyance
import React from 'react';
import { Breadcrumbs, withStyles } from "@material-ui/core"
import { ReactComponent as BreadcrumbSeparator } from '../../images/BreadcrumbSeparator.svg'

const style = () => ({
    header: {
        fontSize: '14px !important',
        fontWeight: '500 !important',
        display: 'flex !important',
        color: '#277AB5',
        cursor:'pointer'
    }
});
function CpBreadcrumb(props) {
    const { children, classes } = props
    return (
        <Breadcrumbs separator={<BreadcrumbSeparator />} className={classes.header} >
            {children}
        </Breadcrumbs >
    )
}
export default withStyles(style)(CpBreadcrumb)
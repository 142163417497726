import { Tooltip, withStyles } from '@material-ui/core'
import React from 'react'
const style = () => ({
    paper: {
        display: 'block',
        fontWeight: 400,
        backgroundColor: '#FFFFFF',
        borderRadius: '0.5rem',
        border: '2px solid #417F90',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.32)',
        maxWidth: '60vh',
        opacity: 1,
        whiteSpace: 'wrap',
        zIndex: 200,
        padding: '1.35rem',
        fontSize: '14px',
        color: '#4A4E50'
    },
    arrow: {
        color: '#417F90'
    }

})
function CPToolTip(props) {
    const { title, classes, children } = props
    return (
        <Tooltip classes={{ tooltipArrow: classes.paper, arrow: classes.arrow }} title={title} arrow>
            {children}
        </Tooltip>
    )
}

export default withStyles(style)(CPToolTip)
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import React from 'react'
const style = theme => ({
    root: {
        height: '3rem',
        width: '3rem',
        padding: '0'
    },
    notChecked: {
        color: '#C8CFD2',
        height: '3rem',
        width: '3rem'
    },
    checked: {
        color: '#277AB5'
    },
    disabled: {
        color: '#E5E8EB'
    },
    indeterminate: {

    },
    label: {
        marginLeft: '0',
        marginRight: '0'
    },
    inputWrapper: {
        width: '94%',
        display: 'inline-flex',
        padding: '0'
    },
    inputWrapper1: {
        width: '100%',
        display: 'inline-flex'
    },
    required: {
        color: 'red',
        paddingTop: '2.5%',
        width: '2%'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            width: '35%',
            fontWeight: 'bold',
            fontSize: '14px'
        }
    }

})
function CpCheckBox(props) {
    const { classes, checked, onChange, id, name, label, isDisabled, isRequired, isForm } = props
    return (
        <div className={isForm ? classes.inputWrapper : classes.inputWrapper1} >
            {isForm ?
                <>
                    <div className={classes.required}>{isRequired ? <span>*</span> : ""}</div>
                    <div className={classes.sectionDesktop}></div>
                </>
                : null}
            <FormControlLabel
                value={checked}
                id={id ? id : name}
                name={name}
                classes={{ root: classes.label }}
                control={<Checkbox
                    classes={{ root: classes.root }}
                    checked={checked}
                    icon={<CheckBoxOutlineBlankIcon className={classes.notChecked} />}
                    checkedIcon={<CheckBoxIcon className={classes.root} />}
                    indeterminateIcon={<IndeterminateCheckBoxIcon className={classes.root} />}
                    disabled={isDisabled}
                />}
                label={label}
                labelPlacement="end"
                onChange={onChange}

            />
        </div>
    )
}

export default withStyles(style)(CpCheckBox)
import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '../Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { voidHandler } from '../../../common/utils';

const style = theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignItems: 'center'
    }
})
function PageNumbers(props) {
    const { selected, onClick, noOfPages, classes } = props
    let numbers = noOfPages === 0 ? [0] : [...Array(noOfPages).keys()]
    let showingNumbers = numbers
    numbers = numbers.map(number => number + 1)
    const startIndex = selected <= 4 ? 0 : selected > noOfPages - 3 ? numbers.length - 7 : selected - 4;
    const endIndex = startIndex + 5;
    numbers.shift()
    numbers.pop()
    showingNumbers = numbers.length > 5 ? numbers.slice(startIndex, endIndex) : numbers
    return (
        <div className={classes.container}>
            {noOfPages && noOfPages > 0 ?
                <>
                    <Button id="previous" variant="secondary" styles={{ minWidth: '0', position: 'static', width: '0px' }} disabled={selected === 1 ? true : false} onClick={() => onClick(selected - 1)} >
                        <ChevronLeftIcon style={{ fontSize: '20px' }} />
                    </Button>
                    <Button id={1} styles={{ minWidth: '0' }} variant={selected === 1 ? 'selected' : 'paginationButton'} onClick={() => selected === 1 ? voidHandler() : onClick(1)}>{1}</Button>
                    {numbers.length > 5 && selected >= 5 ? '...' : null}
                    {showingNumbers.map(number => {
                        return <Button id={number} styles={{ minWidth: '0' }} variant={number === selected ? 'selected' : 'paginationButton'} onClick={() => selected === number ? voidHandler() : onClick(number)}>{number}</Button>
                    })}
                    {numbers.length > 5 && selected < noOfPages - 3 ? '...' : null}
                    {noOfPages !== 1 ? <Button id={noOfPages} styles={{ minWidth: '0' }} variant={noOfPages === selected ? 'selected' : 'paginationButton'} onClick={() => selected === noOfPages ? voidHandler() : onClick(noOfPages)}>{noOfPages}</Button> : null}
                    <Button id="next" variant='secondary' styles={{ minWidth: '0', position: 'static', width: '0px' }} disabled={selected === noOfPages ? true : false} onClick={() => onClick(selected + 1)} >
                        <ChevronRightIcon style={{ fontSize: '20px' }} />
                    </Button>
                </> :
                null
            }
        </div >
    )
}

export default withStyles(style)(PageNumbers)

import React from 'react'
import { List, ListItem, ListItemText, withStyles } from "@material-ui/core"
const style = ({ palette }) => ({
    menu: {
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: 'Open Sans',
        color:palette.primary.main,
        height:'38px'
        
    },
    menuSelected:{
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: 'Open Sans',
        height:'38px',
        color: '#4A4E50'
    },
    selected: {
        borderLeft: '4px solid',
        borderColor: palette.primary.main,
        paddingLeft: '5px',
        color: '#4A4E50',
        height:'38px'
        
    },
    NotSelected: {
        paddingLeft: '10px',
        borderLeft: '1px solid #C8CFD2',
        color: palette.primary.main,
        height:'38px'
    },
    gutters: {
        paddingRight: '0',
        paddingLeft: '14px'
    },
    listItemRoot: {
        paddingTop: '5px',
        paddingBottom: '0'
    },
});
function MenuList(props) {
    const { list, classes, handleOnClickMenuItem } = props
    return (
        <div style={{ margin: '8% 0 0 8%', width: '92%' }}>
            <List component="nav">
                {list.map((item, index) => {
                    return (
                        item.isDisabled === false ?
                            <ListItem classes={{ root: classes.listItemRoot, gutters: classes.gutters }} button disabled={item.isDisabled ? true : false} key={index} className={item.selected === "true" ? classes.selected : classes.NotSelected} onClick={() => handleOnClickMenuItem(item.name)}>
                                <ListItemText >
                                    <span className={item.selected === "true" ? classes.menuSelected : classes.menu}> {item.name}</span>
                                </ListItemText>
                            </ListItem>
                            :
                            null)
                })
                }

            </List>
        </div>
    )
}

export default withStyles(style)(MenuList)

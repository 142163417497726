import React, { Component } from 'react'
import withCPContext from '../common/withCPContext'
import CpEditableTable from '../common/CpEditableTable'
import { fetchContainerTypes } from "../../sevices/TMFF/Services"
import { injectIntl } from "react-intl"
import { withStyles } from '@material-ui/core'
import CPEditableCardTable from '../mobileComponents/CPEditableCardTable'
const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
})
class ContainersTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            containerTypes: []
        }
    }
    componentDidMount = async () => {
        const { containerTypes } = this.state
        if (containerTypes === [] || containerTypes.length === 0) {
            let newcontainerTypes = await fetchContainerTypes()
            this.setState({
                containerTypes: newcontainerTypes.lookupItems
            })
        }
    }
    render() {
        const { data, addfunc, removefunc, onChangefields, readOnly, intl, classes, selectedContainer, onSaveContainersorItems, onEditContainersorItems,
             onCancelContainersorItems ,errordetails,supUserBooking,containerCond} = this.props
        const { containerTypes } = this.state
        const containersheaders = [
            { 'width': '40%', 'title': intl.formatMessage({ id: 'type' }), 'field': 'type', 'showLabel': true, 'type': 'dropdown', 'options': containerTypes },
            { 'width': '30%', 'title': intl.formatMessage({ id: 'qty' }), 'field': 'qty', 'showLabel': true, 'type': 'input', 'inputType': 'number' }
        ]
        const readOnlycontainersheaders = [
            { 'width': '40%', 'title': intl.formatMessage({ id: 'type' }), 'field': 'type', 'showLabel': true, 'type': 'label' },
            { 'width': '30%', 'title': intl.formatMessage({ id: 'qty' }), 'field': 'qty', 'showLabel': true, 'type': 'label' }
        ]
        return (
            <>
                <div className={classes.sectionDesktop}>
                    <CpEditableTable headers={readOnly === true ? readOnlycontainersheaders : containersheaders} readOnly={readOnly} data={data} error={(errordetails.container !== '' && (supUserBooking && containerCond)) ? true:false} helperText={(supUserBooking && containerCond) ? errordetails.containerlist:""} helperText1={ (supUserBooking && containerCond) ? errordetails.container:""} removefunc={removefunc} addfunc={addfunc} onChangefields={onChangefields} maxLength={4} tableFor="Container" />
                </div>
                <div className={classes.sectionMobile}>
                    <CPEditableCardTable selected={selectedContainer} headers={readOnly === true ? readOnlycontainersheaders : containersheaders} readOnly={readOnly} data={data} removefunc={removefunc} addfunc={addfunc} onChangefields={onChangefields} maxLength={4} tableFor="CONTAINER" containerTypes={containerTypes} onSave={onSaveContainersorItems} onEdit={onEditContainersorItems} onCancel={onCancelContainersorItems} />
                </div>
            </>
        )
    }
}

export default injectIntl(withStyles(style)(withCPContext(ContainersTable)))
import React from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withStyles } from '@material-ui/core'


const style = theme => ({
    buttonEnabled: {
        width: '6rem',
        height: '3.4rem',
        backgroundColor: '#277AB5',
        fontWeight: 'bold',
        color: '#FBFCFC',
        fontSize: '1.3rem',
        '&:disabled': {
            backgroundColor: '#277AB5',
            color: '#FBFCFC',
            cursor: 'not-allowed'
        },
        '&:hover': {
            backgroundColor: '#277AB5',
            color: '#FBFCFC'
        }
    },
    buttonDisabled: {
        width: '6rem',
        height: '3.4rem',
        backgroundColor: '#FFFFFF',
        fontWeight: 'bold',
        color: '#417F90',
        fontSize: '1.3rem',
        border: 'solid',
        borderColor: '#CCCCCC',
        '&:disabled': {
            backgroundColor: '#FFFFFF',
            color: '#417F90',
            cursor: 'not-allowed'
        },
        '&:hover': {
            backgroundColor: '#FFFFFF',
            color: '#417F90'
        }
    },
    labelDiv: {
        fontWeight: '500',
        margin: '0.5rem 0',
    },
    toggleButtonWrapper: {
        padding: '0 0 0 1%',
    },
    toggleButtonGroup: {
        margin: '0.5rem 0',
    }
})

function CpButtonGroup(props) {
    const { classes, value, onChange, name, label, disabled } = props

    return (
        <div className={classes.toggleButtonWrapper}>
            <div className={classes.labelDiv}>{label}</div>
            <ToggleButtonGroup className={classes.toggleButtonGroup} size='small' name={name} checked={value} onChange={onChange}>
                <ToggleButton disabled={disabled} className={value ? classes.buttonEnabled : classes.buttonDisabled} value={true}>Yes</ToggleButton>
                <ToggleButton disabled={disabled} className={!value ? classes.buttonEnabled : classes.buttonDisabled} value={false} >No</ToggleButton>
            </ToggleButtonGroup>
        </div>


    )
}

export default withStyles(style)(CpButtonGroup)
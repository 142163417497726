import React from 'react'
import CpEditableTable from '../../common/CpEditableTable';
import { Link, Typography } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Box from '../../common/Box';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';
import CpBreadcrumb from '../../common/CpBreadcrumb';

function ConcurPPTArrivalDetails(props) {

    const { concurPPTArvlDetails, intl, handleDisplayBack, totalRecords, selectedRecord } = props;
    const mainHeaderList = [
        { title: "Sequence No", field: "seqNo" },
        { title: "Action Qualifier", field: "actionQualifier", tooltipField: 'actionQualifierDesc' },
        { title: "Action Code", field: "actionCd", tooltipField: 'actionCdDesc' },
        { title: "Identification No.", field: "idNo" },
        { title: "PTT Unique Identifier", field: "ftzPttUniqueIdentifier" },
        { title: "Received Quantity", field: "receivedQuantity" },
        { title: "Carrier Code", field: "carrierCd" },
        { title: "Carrier IRS No.", field: "irsNo" },
        { title: "Delivery Code", field: "deliveryCd" },
        { title: "Contact Name", field: "unconcurContactName" },
        { title: "Phone", field: "unconcurContactPhone" },
        { title: "FIRMS Code", field: "pttFirms" },
        { title: "Airport Code", field: "airportCd" },
        { title: "Correction Req. Reason", field: "unconcurReasonCd" },
        { title: "Replacement Details", field: "unconcurReplacementId" }
    ]
    const containerColumns = [
        { 'width': '20%', 'title': intl.formatMessage({ id: 'container' }), 'field': 'noContainer', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'airCarrier' }), 'field': 'carrierCd', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'flight' }), 'field': 'ftzVoyageFlight', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'CMUS_ShipmentsSummary_ArrivalDate' }), 'field': 'dateArrival', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'manifestQuantity' }), 'field': 'containerManifestedQuantity', 'showLabel': true, 'type': 'label' }
    ]
    const remarkColumns = [
        { 'width': '50%', 'title': intl.formatMessage({ id: 'seqNo' }), 'field': 'remarkSeqNo', 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': intl.formatMessage({ id: 'remark' }), 'field': 'concurRemark', 'showLabel': true, 'type': 'label' }
    ]
    return (
        <div>
            <div style={{ display: 'flex', margin: '2rem 0 0 2rem' }}>
                <CpBreadcrumb>
                    <Link color='inherit' onClick={() => handleDisplayBack(0)}>{intl.formatMessage({ id: 'concur/ppt/arrival' })}</Link>
                    <Typography color='textPrimary'>{intl.formatMessage({ id: 'concurPptArrival' }, { val1: selectedRecord, val2: totalRecords })}</Typography>
                </CpBreadcrumb>
            </div>
            <Box showloading={true} header={intl.formatMessage({ id: 'concurPptArrival' }, { val1: selectedRecord, val2: totalRecords })}>
                <NonEditableDataGrid list={mainHeaderList} data={concurPPTArvlDetails ? concurPPTArvlDetails : {}} />
            </Box>
            <Box header={intl.formatMessage({ id: 'containers' })}>
                <CpEditableTable readOnly={true} headers={containerColumns} data={(concurPPTArvlDetails && concurPPTArvlDetails.ftzPttContainersList) ? concurPPTArvlDetails.ftzPttContainersList : []} />
            </Box>
            <Box header={intl.formatMessage({ id: 'remarkHeader' })}>
                <CpEditableTable readOnly={true} headers={remarkColumns} data={(concurPPTArvlDetails && concurPPTArvlDetails.ftzPttRemarksList) ? concurPPTArvlDetails.ftzPttRemarksList : []} />
            </Box>
        </div>
    )
}

export default injectIntl(ConcurPPTArrivalDetails)
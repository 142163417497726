import React from 'react'
import { injectIntl } from 'react-intl';
import CpEditableTable from '../../common/CpEditableTable';
import { withStyles } from '@material-ui/core';
import PageNumbers from '../../common/Pagination/PageNumbers';
import withLoading from '../../../components/common/withLoading';
import Box from '../../common/Box';

const styles = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
})
function CommercialInvoiceItems(props) {
    const invoiceheaders = [
        { 'width': '15%', 'title': 'Invoice', 'field': 'ciNo', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Value', 'field': 'valueForeignAmt', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'C/O', 'field': 'countryOrigin', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Weight', 'field': 'weightGross', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': 'Charges', 'field': 'charges', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Cust.Ref.', 'field': 'custRef', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Inv.Lines', 'field': 'ciNoLines', 'showLabel': true, 'type': 'label' },

    ]

    const { handleDisplayForward, invoiceItemsData, noOfPages, selectedPage, handlePageClick, intl } = props
    return (
        <Box showloading={true} header={intl.formatMessage({ id: 'commercial_invoice_items_caps' })}>
            <div>
                <CpEditableTable headers={invoiceheaders} readOnly={true} data={invoiceItemsData} navigateIcon={true} handleDisplayForward={(row, index) => handleDisplayForward(1, row, index)} />
            </div>
            <PageNumbers noOfPages={noOfPages} selected={selectedPage} onClick={(index) => handlePageClick(index, 0)} />
        </Box>
    )
}

export default injectIntl(withLoading(withStyles(styles)(CommercialInvoiceItems)))
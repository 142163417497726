import React from 'react'
import { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import DeliveryOrders from './DeliveryOrders';
import DeliveryDetails from './DeliveryDetails';
import { getShipmentDeliveryOrders, getShipmentDeliveryOrderDetails } from '../../../sevices/CMUS/Services'
import withLoading from '../../common/withLoading';

const styles = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
})
class DeliveryOrdersSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displaySection: {
                level0: { visible: true, data: undefined, noOfPages: 0, selectedPage: 1, totalRecords: 0, selectedRecord: 0 },
                level1: { visible: false, data: undefined },
            }
        }
    }
    componentDidMount() {
        this.getDeliveryOrders(this.state.displaySection.level0.selectedPage);
    }

    getDeliveryOrders = async (pageSelected) => {
        const { loading } = this.props
        loading.setLoadindDoc(true)
        const response = await getShipmentDeliveryOrders(this.props.shipmentFileNo, pageSelected)
        if (response && response.status === 200 && response.data) {
            this.setState({
                displaySection: {
                    ...this.state.displaySection,
                    level0: {
                        visible: true,
                        data: response.data.data ? response.data.data : [],
                        noOfPages: response.maxResults !== 0 ? Math.ceil(response.data.totalRecords / response.data.maxResults) : 1,
                        selectedPage: pageSelected ? pageSelected : 1,
                        totalRecords: response.data.totalRecords ? response.data.totalRecords : 0
                    }
                }
            })
        } else {
            this.setState({
                displaySection: {
                    ...this.state.displaySection,
                    level0: {
                        visible: true,
                        data: [],
                        noOfPages: 0,
                        selectedPage: 1
                    }
                }
            })
        }
        loading.setLoadindDoc(false);
    }
    getDeliveryOrderDetails = async (data, index) => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        if (data !== undefined && data !== '') {
            const response = await getShipmentDeliveryOrderDetails(this.props.shipmentFileNo, data.doNo)
            if (response && response.status === 200 && response.data) {
                await this.setState({
                    displaySection: {
                        level1: { visible: true, data: response.data.data ? response.data.data[0] : [] },
                        level0: { ...this.state.displaySection.level0, visible: false, selectedRecord: index ? index : 0 }
                    }
                })
            } else {
                await this.setState({
                    displaySection: {
                        level1: { visible: true, data: [] },
                        level0: { ...this.state.displaySection.level0, visible: false }
                    }
                })
            }
        }
        loading.setLoadindDoc(false);
    }
    handleDisplayBack = async (level, data) => {
        switch (level) {
            case 0:
                await this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: true },
                        level1: { ...this.state.displaySection.level1, visible: false }
                    }
                });
                break;
            default:
                this.setState({
                    displaySection: {
                        level0: { visible: false, data: undefined, noOfPages: 0, selectedPage: 1 },
                        level1: { visible: false, data: undefined }
                    }
                });
                break;
        }
    }

    handleDisplayForward = async (level, data, index) => {
        switch (level) {
            case 1:
                await this.getDeliveryOrderDetails(data, index)
                break;
            default:
                this.setState({
                    displaySection: {
                        level0: { visible: false, data: undefined, noOfPages: 0, selectedPage: 1 },
                        level1: { visible: false, data: undefined }
                    }
                });
                break;
        }
    }
    handlePageClick = (pageIndex, level) => {
        if (level === 0) {
            this.getDeliveryOrders(pageIndex);
        }
    }
    render() {
        const { displaySection } = this.state
        return (
            <div>
                {displaySection.level0.visible ? <DeliveryOrders delvieryOrdersData={displaySection.level0.data}
                    handleDisplayForward={this.handleDisplayForward} noOfPages={displaySection.level0.noOfPages}
                    selectedPage={displaySection.level0.selectedPage} handlePageClick={this.handlePageClick} /> : null}
                {displaySection.level1.visible ? <DeliveryDetails handleDisplayBack={this.handleDisplayBack} deliveryData={displaySection.level1.data}
                    totalRecords={displaySection.level0.totalRecords} selectedRecord={displaySection.level0.selectedRecord} /> : null}
            </div>
        )
    }

}

export default injectIntl(withLoading(withStyles(styles)(DeliveryOrdersSection)))
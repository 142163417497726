import React from 'react'
import { withStyles, TextField, FormHelperText, useTheme, useMediaQuery, InputLabel } from '@material-ui/core'

const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            width: '35%',
            fontWeight: 'bold',
            fontSize: '14px'
        }
    },
    inputWrapper: {
        width: '100%',
        display: 'inline-flex',
        padding: '0',
        alignItems: 'center',
        margin: '0.5rem 2px'
    },
    fieldContent: {
        width: '45%',
        [theme.breakpoints.up('md')]: {
            width: '16%'
        }
    },

    required: {
        color: 'red',
        width: '2%'
    },
    para: {
        margin: '0',
        fontSize: '10px',
        width: '34%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            fontSize: '14px'
        }
    },
    input: {
        '&.MuiOutlinedInput-root': {
            paddingRight: '0',
            '&	.MuiOutlinedInput-input': {
                display: 'block',
                lineHeight: 'calc(4rem - 4px)',
                minHeight: '3rem',
                padding: '0 1rem',
                position: 'relative',
                verticalAlign: 'middle',
                width: '100%',
                backgroundColor: '#FFFFFF',
                color: '#4A4E50'
            },
            '&  .MuiInputAdornment-root': {
                backgroundColor: theme.palette.primary.main,
                backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.24))',
                padding: '1.7rem 1rem',
                margin: '0px',
                borderTopRightRadius: theme.shape.borderRadius + 'px',
                borderBottomRightRadius: theme.shape.borderRadius + 'px',
                borderLeft: '2px solid #C8CFD2',
                color: 'white'
            },
        }
    },
    notchedOutline: {
        border: '2px solid #C8CFD2',
        borderRadius: '4px',
        transitionDuration: '100ms',
        transitionProperty: 'border-color, background-color, color, box-shadow',
        '&:focus': {
            outlineWidth: '0',
            borderColor: theme.palette.primary.main,
            boxShadow: '0 0 0 3px #e3ecef'
        },
        '&focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px'
        }
    },
    disabled: {

    }
})

function CpInput1(props) {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const numberProps = { min: 0 }
    const {
        classes,
        label,
        onChange,
        //onBlur,
        isRequired,
        register1,
        register2,
        type1,
        type2,
        name1,
        name2,
        value1,
        value2,
        isDisabled1,
        isDisabled2,
        placeholder1,
        placeholder2,
        texttoRightSideInput2,
        texttoRightSideInput1, error, helperText, maxLength1, maxLength2 } = props
    return (
        <>
            <div className={classes.inputWrapper} >
                <div className={classes.sectionDesktop}><label style={{fontWeight:500}}htmlFor={name1}>{label} {isRequired ? <span className={classes.required}>*</span> : ""}</label></div>
                {isDesktop ? '' :
                    <InputLabel style={{ width: '100%' }} >
                        {label}
                    </InputLabel>}
                <div style={{ display: 'flex', width: '56%' }}>
                    <TextField inputRef={register1}
                        id={name1}
                        error={error}
                        placeholder={placeholder1}
                        disabled={isDisabled1}
                        className={classes.fieldContent}
                        name={name1}
                        variant="outlined"
                        value={value1}
                        type={type1}
                        onChange={onChange}
                        onInput={(e) => {
                            if (maxLength1 && e.target.value.length > maxLength1) {
                                e.target.value = e.target.value.slice(0, maxLength1)
                            }
                        }}
                        InputProps={{ classes: { root: classes.input, disabled: classes.disabled, notchedOutline: classes.notchedOutline } }}
                        size='small'
                        inputProps={type1 === 'number' ? numberProps : {}} />
                    <div className={classes.para} >
                        <span >
                            {texttoRightSideInput1}
                        </span>
                    </div>
                    <TextField inputRef={register2}
                        id={name2}
                        error={error}
                        placeholder={placeholder2}
                        disabled={isDisabled2}
                        className={classes.fieldContent}
                        name={name2}
                        variant="outlined"
                        InputProps={{ classes: { root: classes.input, disabled: classes.disabled, notchedOutline: classes.notchedOutline } }}
                        size='small'
                        value={value2}
                        type={type2}
                        //onBlur={onBlur}
                        onChange={onChange}
                        onInput={(e) => {
                            if (maxLength2 && e.target.value.length > maxLength2) {
                                e.target.value = e.target.value.slice(0, maxLength2)
                            }
                        }}
                        inputProps={type2 === 'number' ? numberProps : {}} />
                    <div className={classes.para}>
                        <span >
                            {texttoRightSideInput2}
                        </span>
                    </div>
                </div>
            </div >
            <div style={{ marginLeft: '40%' }}>{error ? <FormHelperText error={true}>{helperText}</FormHelperText> : ''}</div>
        </>
    )
}

export default withStyles(style)(CpInput1)

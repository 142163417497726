import React from 'react'
import CpEditableTable from '../../common/CpEditableTable';
import { Link, Typography } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Box from '../../common/Box';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';
import CpBreadcrumb from '../../common/CpBreadcrumb';

function BillingDetails(props) {

    const { billingInvoiceData, selectedBilling, intl, handleDisplayBack, totalRecords, selectedRecord } = props;
    const billingDataHeaders = [
        { title: "Invoice Date", field: "dateInvoice" },
        { title: "Invoice No.", field: "suffix" },
        { title: "Invoice Total Amount", field: "totalAmount" }
    ]
    const billingHeaders = [
        { 'width': '50%', 'title': intl.formatMessage({ id: 'description' }), 'field': 'chargeDesc', 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': intl.formatMessage({ id: 'amount' }), 'field': 'amount', 'showLabel': true, 'type': 'label' }
    ]
    return (
        <div>
            <div style={{ display: 'flex', margin: '2rem 0 0 2rem' }}>
                <CpBreadcrumb >
                    <Link color='inherit' onClick={() => handleDisplayBack(0)}>{intl.formatMessage({ id: 'billing' })}</Link>
                    <Typography color='textPrimary'>{intl.formatMessage({ id: 'invoice' }, { val1: selectedRecord, val2: totalRecords })}</Typography>
                </CpBreadcrumb>
            </div>
            <Box showloading={true} header={intl.formatMessage({ id: 'invoice' }, { val1: selectedRecord, val2: totalRecords })}>
                <NonEditableDataGrid list={billingDataHeaders} data={selectedBilling ? selectedBilling : []} />
                <CpEditableTable headers={billingHeaders} readOnly={true} data={billingInvoiceData ? billingInvoiceData : []} />
            </Box>
        </div>
    )
}

export default injectIntl(BillingDetails)
import React, { Component } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import withTenant from '../../common/withTenant';
import withError from '../../common/withError';
import ShipmentImportWidget from './ShipmentImportWidget';
import ShipmentExportWidget from './ShipmentExportWidget';
import BookingsWidget from './BookingsWidget';
import ShipmentCountryWidget from './ShipmentCountryWidget';
import InlandShipmentWidget from './InlandShipmentWidget'
import QuotesWidgets from './QuotesWidgets';
import { ExpiringQuotesWidget } from './ExpiringQuotesWidget';
import withCPContext from '../../common/withCPContext';
import CpSwitch from '../../common/CpSwitch';
import CustomizedDashboard from './CustomizedDashboard';

const style = theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16)
        }
    },
    cpSwitch: {
        width: "80%",
        float: "right",
        display: 'flex',
        marginTop: '0.2%',
        [theme.breakpoints.up('md')]: {
            width: "18%"
        }
    }
});



class TMFFDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadPreferredWidgets: sessionStorage.getItem('preferredWidgetsEnabled') ? (sessionStorage.getItem('preferredWidgetsEnabled') === 'true' ? true : false) : false,
            isDesktop: window.innerWidth >= 960 ? true : false,
        }
    }
    handleSwtichChange = (event) => {
        sessionStorage.setItem('preferredWidgetsEnabled', event.target.checked)
        this.setState({ loadPreferredWidgets: event.target.checked })
    }

    render() {
        const { tenantDetails, cpContext, classes } = this.props;
        const { loadPreferredWidgets } = this.state
        return (
            <>
                {sessionStorage.getItem('isCPAdmin') === 'true' ?
                    <div className={classes.cpSwitch} >
                        <CpSwitch isDisabled={false} label={'Preferred Widgets'} name='preferredWidgets' value={loadPreferredWidgets} isRequired={false} onChange={(event) => this.handleSwtichChange(event)} isLabelRequired={true} />
                    </div> : null}

                {loadPreferredWidgets ?
                    <CustomizedDashboard />
                    : <Grid container spacing={2} flex='true' direction="row" style={{ width: '100%', margin: '1% 0 0 0 ' }}>
                        {tenantDetails.accessLevels.accessToQuotes === true && cpContext.accessToQuotes ?
                            <Grid item xl={3} lg={3} sm={12} xs={12}>
                                <Grid container spacing={2} flex='true' direction="row" justifyContent="flex-start" alignItems="center">
                                    <Grid item xl={12} lg={12} sm={12} xs={12}>
                                        <Paper elevation={3} style={{ height: '51.5rem' }}>
                                            <QuotesWidgets {...this.props} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid >
                            : null}
                        <Grid item xl={9} lg={9}>
                            <Grid container spacing={2} flex='true' direction="row" justifyContent="flex-start" alignItems="center">
                                {tenantDetails.accessLevels.accessToQuotes === true && cpContext.accessToQuotes ?
                                    <>
                                        <Grid item lg={4} xs={12} sm={12} md={4} xl={4}>
                                            <Paper elevation={3} style={{ height: '25rem' }} >
                                                <ExpiringQuotesWidget {...this.props} />
                                            </Paper>
                                        </Grid>
                                    </>
                                    : null}
                                {tenantDetails.accessLevels.accessToBookings === true && cpContext.accessToBookings ?
                                    <Grid item lg={4} xs={12} sm={12} md={4} xl={4}>
                                        <Paper elevation={3} style={{ height: '25rem' }}>
                                            <BookingsWidget {...this.props} />
                                        </Paper>
                                    </Grid>
                                    : null}
                                {tenantDetails.accessLevels.accessToILWidget === true && cpContext.accessToILWidget ?
                                    <Grid item lg={4} xs={12} sm={12} md={4} xl={4}>
                                        <Paper elevation={3} style={{ height: '25rem' }} >
                                            <InlandShipmentWidget {...this.props} />
                                        </Paper>
                                    </Grid>
                                    :
                                    null}
                                {tenantDetails.accessLevels.accessToShipments === true && cpContext.accessToShipments ?
                                    <>
                                        <Grid item lg={4} xs={12} sm={12} md={4} xl={4}>
                                            <Paper elevation={3} style={{ height: '25rem' }}>
                                                <ShipmentCountryWidget {...this.props} />
                                            </Paper>
                                        </Grid>
                                        <Grid item lg={4} xs={12} sm={12} md={4} xl={4}>
                                            <Paper elevation={3} style={{ height: '25rem' }} >
                                                <ShipmentImportWidget {...this.props} />
                                            </Paper>
                                        </Grid>
                                        <Grid item lg={4} xs={12} sm={12} md={4} xl={4}>
                                            <Paper elevation={3} style={{ height: '25rem' }} >
                                                <ShipmentExportWidget {...this.props} />
                                            </Paper>
                                        </Grid>
                                    </>
                                    : null}
                                {tenantDetails.accessLevels.accessToShipments === false || 
                                tenantDetails.accessLevels.accessToQuotes === false ||
                                tenantDetails.accessLevels.accessToBookings === false ||
                                tenantDetails.accessLevels.accessToILWidget === false  ||
                                cpContext.accessToShipments === false ||
                                cpContext.accessToBookings === false ||
                                cpContext.accessToILWidget === false ||
                                cpContext.accessToQuotes === false? 
                                    <Grid item lg={4} xs={12} sm={12} md={4} xl={4}>
                                    <Paper elevation={3} style={{ height: '25rem' }} >
                                        <a href={`${tenantDetails.redirectUrl}`} target="_blank" rel="noopener noreferrer">
                                            <img className="img" src={`/secure-ui/themes/${tenantDetails.tenant}/brand.gif`} style={{ height: '100%', width: '100%', position: 'relative', left: '0',objectFit:'contain' }} alt="Brand space" />
                                        </a>
                                    </Paper>
                                </Grid> : null}


                            </Grid>
                        </Grid>
                    </Grid>}

            </>
        );

    }
}

export default injectIntl(withTenant(withCPContext(withError(withRouter(withStyles(style)(TMFFDashboard))))));
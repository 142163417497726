const shipmentTypesSea = [
    { label: 'FCL', value: 'FCL' },
    { label: 'LCL', value: 'LCL' }];

const shipmentTypesInland = [
    { label: 'FTL', value: 'FTL' },
    { label: 'LTL', value: 'LTL' }];

const motTypes = [
    { label: 'AIR EXPORT', value: 'AE' },
    { label: 'AIR IMPORT', value: 'AI' },
    { label: 'SEA EXPORT', value: 'SE' },
    { label: 'SEA IMPORT', value: 'SI' },
    { label: 'INLAND', value: 'IL' }];

const deliveryTerms = [
    { label: 'Collect', value: 'C' },
    { label: 'Prepaid', value: 'P' }];

const trackingOptions = [
    { value: 'BookingNos', label: 'Booking No.' },
    { value: 'ContainerNo', label: 'Container No.' },
    { value: 'ShpNos', label: 'HBL/HAWB No.' },
    { value: 'JobNo', label: 'Job No.' },
    { value: 'MasterNo', label: 'MBL/MAWB No.' },
    { value: 'RefNos', label: 'Ref No.' }];

const BookingstatusMap = [
    { label: 'In Progress', value: 'D' },
    { label: 'Submitted', value: 'P' }]

const QuoteStatusMap = [
    { label: 'In Progress', value: 'N' },
    { label: 'Submitted', value: 'D' },
    { label: 'Awaiting Acceptance', value: 'T' },
    { label: 'Accepted', value: 'Q' },
    { label: 'Rejected', value: 'R' },
    { label: 'Booked', value: 'B' }]

const TrackingOptionsCMUS = [
    { value: 'fileNo', label: 'File No.' }];

export { shipmentTypesSea, shipmentTypesInland, motTypes, deliveryTerms, trackingOptions, BookingstatusMap, QuoteStatusMap, TrackingOptionsCMUS }
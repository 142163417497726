import React from 'react';
import Popover from '@material-ui/core/Popover';
import { Box, Button, withStyles } from '@material-ui/core';
import '../../styles/bar.css'

const style = ({ spacing, palette }) => ({
  typography: {
    padding: spacing(2)
  },
  popover: {
    border: '1px solid',
    borderColor: palette.primary.main
  },
  root: {
    border: '1px solid',
    borderColor: palette.primary.main,
    paper: {
      backgroundColor: '#f5f5f5'
    }
  },
  box: {
    cursor: 'pointer',
    margin: 12,
    padding: 10,
    display: 'block',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#E0F3FB'
    }
  },
  selected: {
    backgroundColor: '#C2E7F7'
  },
  button: {
    color: palette.primary.main,
    fontSize: '1.75rem',
    outlineStyle: 'none',
    borderRadius: 2
  }
});

function CPPopOver(props) {

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };
  const { open, anchorEl, handleClose, content, onClick, buttonlabel, onButtonClick, classes } = props
  return (
    <div>
      <Popover
        classes={{ root: classes.root }}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {
          content ?
            <>
              {content.length > 0 ?
                content.map((el, index) => {

                  return (
                    <Box className={`${classes.box} ${el.selected ? classes.selected : ''}`} key={index} onClick={() => onClick(index, anchorEl)} onDoubleClick={onButtonClick}>
                      <span style={{ fontWeight: 'bold' }}>{el.addrTypeDesc ? el.addrTypeDesc + ': ' : ""}</span>
                      {el.address}
                    </Box>
                  )
                })
                : <Box className={`${classes.box}`}>
                  No addresses are available. Please add a new one from text area.</Box>}
              {buttonlabel ?
                <Box display='flex' style={{ justifyContent: 'flex-end' }} m={1}>
                  <Button id="popOverOK" variant="primary" className={classes.button} onClick={onButtonClick}>{buttonlabel}</Button>
                </Box>
                :
                null}

            </>
            :
            null
        }
        {/* <Typography className={classes.typography}>{message ? message : 'No content'}</Typography> */}
      </Popover>
    </div >
  );
}

export default withStyles(style)(CPPopOver);

import React, { Component } from 'react';
import CPButton from '../../common/Button'
import { Grid, withStyles, IconButton } from '@material-ui/core';
import CpInput from '../../common/CpInput';
import CpInput1 from '../../common/CpInput1';
import { FormattedMessage, injectIntl } from "react-intl";
import HelpIcon from '@material-ui/icons/Help';
import CpSearchableTypeahead from '../../common/CpSearchableTypeahead';
import { fetchCustomers, fetchPorts } from '../../../sevices/CMUS/Services';
import { SEARCH_SHIPMENT_HELP } from '../../../sevices/CMUS/servicesURLS';
import CpSearchableFreeTextTypeahead from '../../common/CpSearchableFreeTextTypeahead';
import CpInputMask from '../../common/CpInputMask';

const style = ({ breakpoints, palette }) => ({
    heading: {
        fontSize: '24px',
        fontWeight: 700
    },
    sectionButtonsDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'center'
        }
    },
    sectionButtonsMobile: {
        display: 'flow-root',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    header: {
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#E8F0F4'
    },
    headingBox: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '2rem'
    }
})

class CMUSSearchShipments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            polCities: [],
            podCities: [],
            poeCities: [],
            shippers: [],
            consignees: [],
            data: {
                shipper: '',
                shipperInput: '',
                consignee: '',
                consigneeInput: '',
                fileNo: '',
                entryNo: '',
                hblNo: '',
                mblNo: '',
                exportDateFrom: '',
                exportDateTo: '',
                arrivalDateFrom: '',
                arrivalDateTo: '',
                releaseDateFrom: '',
                releaseDateTo: '',
                pol: '',
                polInput: '',
                pod: '',
                podInput: '',
                poe: '',
                poeInput: '',
                custRefNo: '',
                custIRSNo:'',
                containerNo:'',
                ciNo:''
            },
            noOptionMsg: '',
            disableGo: true,
            error: '',
            errors: {
                pol: '',
                pod: '',
                poe: '',
                shipper: '',
                consignee: ''
            }
        }
    }

    onChange = async (event) => {
        await this.setState({
            data: {
                ...this.state.data,
                [event.target.name]: event.target.value
            }
        })
        this.validateGobutton()
    }

    getCustomerLookup = async (value, field) => {
        const { intl } = this.props
        const customerKey = field + 's'
        this.setState({
            errors: {
                ...this.state.errors,
                [field]: ''
            },
            [customerKey]: [],
            noOptionMsg: intl.formatMessage({ id: 'data_Loading' })
        })
        if (value !== null && value !== '' && value !== undefined && value.trim().length >= 1) {
            const customerLookup = await fetchCustomers(value.trim());
            let customerData = customerLookup ? customerLookup.lookupItems : []
            this.setState({
                noOptionMsg: '',
                [customerKey]: customerData,
            })
            if (customerLookup && customerLookup.totalRecords === 0) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        [field]: intl.formatMessage({ id: 'noRecords_lookup' })
                    }
                })
            }
        } else {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [field]: intl.formatMessage({ id: 'search_value' })
                },
                noOptionMsg: ''
            })
        }

    }

    handleTypeAheadInputChange = async (value, field) => {
        if (field === 'shipper' || field === 'consignee') {
            let updatedField = field + "Input"
            await this.setState({
                data: {
                    ...this.state.data,
                    [updatedField]: value.toUpperCase(),
                    [field]: ''
                },
                errors: {
                    ...this.state.errors,
                    [field]: ''
                }
            })
            this.validateGobutton();
        } else {
            this.setState({
                data: {
                    ...this.state.data,
                    [field]: value.toUpperCase()
                },
                errors: {
                    ...this.state.errors,
                    [field]: ''
                }
            })
        }
    }

    getPorts = async (value, field) => {
        const { intl } = this.props
        const citiesKey = field + "Cities"
        this.setState({
            errors: {
                ...this.state.errors,
                [field]: ''
            },
            [citiesKey]: [],
            noOptionMsg: intl.formatMessage({ id: 'data_Loading' })
        })
        if (value !== null && value !== '' && value !== undefined && value.trim().length >= 1) {
            const citiesLookup = await fetchPorts(value.trim(), field);
            let citiesData = citiesLookup ? citiesLookup.data ? citiesLookup.data : [] : []
            this.setState({
                noOptionMsg: '',
                [citiesKey]: citiesData
            })
            if (citiesLookup && citiesLookup.totalRecords === 0) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        [field]: intl.formatMessage({ id: 'noRecords_lookup' })
                    }
                })
            }
        } else {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [field]: intl.formatMessage({ id: 'search_value' })
                },
                noOptionMsg: ''
            })
        }
    }

    handleTypeaheadChange = async (event, field) => {
        if (event !== null) {
            if (field === 'shipper' || field === 'consignee') {
                let updatedField = field + "Input"
                await this.setState({
                    data: {
                        ...this.state.data,
                        [field]: event.value,
                        [updatedField]: event.description
                    },
                    errors: {
                        ...this.state.errors,
                        [field]: ''
                    }
                })
            } else {
                await this.setState({
                    data: {
                        ...this.state.data,
                        [field]: event.value,
                    },
                    errors: {
                        ...this.state.errors,
                        [field]: ''
                    }
                })
            }
        }
        else {
            await this.setState({
                data: {
                    ...this.state.data,
                    [field]: '',
                },
                errors: {
                    ...this.state.errors,
                    [field]: ''
                }
            })
        }
        this.validateGobutton()
    }

    validateGobutton = () => {
        const { data } = this.state
        const { shipperInput, consigneeInput } = this.state.data
        const goEnable = ((shipperInput && shipperInput.trim().length > 2) || (consigneeInput && consigneeInput.trim().length > 2)) ? true : false;
        const checkdata = {
            ...data,
            consigneeInput: '',
            shipperInput: '',
            podInput: '',
            poeInput: '',
            polInput: ''
        }
        let valueArray = Object.values(checkdata)
        valueArray = valueArray.filter(el => el.trim().length > 0)
        this.setState({
            disableGo: goEnable ? false : (valueArray.length <= 0 ? true : false)
        })
    }

    render() {
        const { handleDrawer, classes, intl, fetchShipments } = this.props
        const { data, podCities, polCities, shippers, poeCities, consignees, noOptionMsg, errors, disableGo } = this.state
        return (
            <div >
                <div className={classes.header}>
                    <div className={classes.headingBox}>
                        <span className={classes.heading} ><FormattedMessage id="search_shipments_search_header" /></span>
                    </div>
                    <div className={classes.sectionButtonsDesktop}>
                        {/* help section */}
                        <a href={`${window.location.protocol + '//' + window.location.host}${SEARCH_SHIPMENT_HELP}`} target="_blank" style={{ color: "inherit", float: "right" }} rel="noopener noreferrer">
                            <IconButton style={{ padding: '5px' }}
                                aria-label="account of current user"
                                aria-controls="primary-search-account-menu"
                                aria-haspopup="true"
                                color="inherit"
                            >
                                <HelpIcon style={{ fontSize: '30px', color: "#6E787D" }} />
                            </IconButton>
                        </a>
                        {/* end of help section */}
                        <CPButton variant="containerLess" onClick={() => handleDrawer()}>{intl.formatMessage({ id: 'search_shipments_search_cancel' })}</CPButton>
                        <CPButton variant="primary" disabled={disableGo} onClick={() => fetchShipments("search", data, "CMUS")}>{intl.formatMessage({ id: 'search_shipments_search' })}</CPButton>
                    </div>
                    <div className={classes.sectionButtonsMobile} >
                        <a href={`${window.location.protocol + '//' + window.location.host}${SEARCH_SHIPMENT_HELP}`} target="_blank" style={{ color: "inherit", float: "right" }} rel="noopener noreferrer">
                            <IconButton style={{ padding: '5px' }}
                                aria-label="account of current user"
                                aria-controls="primary-search-account-menu"
                                aria-haspopup="true"
                                color="inherit"
                            >
                                <HelpIcon style={{ fontSize: '30px', color: "#6E787D" }} />
                            </IconButton>
                        </a>
                    </div>
                </div>
                <Grid flex="true" container spacing={0} style={{ width: '99%' }}>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{margin: '1.25rem 0 1.25rem 1.25rem'}}>
                        <div className={classes.sectionButtonsMobile} >
                            <CPButton styles={{ float: 'right' }} variant="primary" disabled={disableGo} onClick={() => fetchShipments("search", data, "CMUS")}>{intl.formatMessage({ id: 'search_shipments_search' })}</CPButton>
                            <CPButton styles={{ float: 'right' }} variant='containerLess' onClick={() => handleDrawer()}>{intl.formatMessage({ id: 'search_shipments_search_cancel' })}</CPButton>
                        </div>
                        <CpSearchableFreeTextTypeahead isDisabled={false} isRequired={false} searchWith="label" showTitle={true} maxLength='50'
                            label={intl.formatMessage({ id: 'search_shipments_search_shipper' })}
                            name='shipper' options={shippers} value={data.shipper}
                            onChange={(event) => this.handleTypeaheadChange(event, 'shipper')}
                            onInputChange={(value) => this.handleTypeAheadInputChange(value, 'shipper')}
                            inputValue={data.shipperInput}
                            noOptionsMsg={noOptionMsg}
                            error={errors.shipper !== '' ? true : false} helperText={errors.shipper}
                            performPartyLookupSearch={() => this.getCustomerLookup(data.shipperInput, 'shipper')} />
                        <CpSearchableFreeTextTypeahead isDisabled={false} isRequired={false} showTitle={true} searchWith="label" maxLength='50'
                            label={intl.formatMessage({ id: 'search_shipments_search_consignee' })}
                            name='consignee' options={consignees} value={data.consignee}
                            onChange={(event) => this.handleTypeaheadChange(event, 'consignee')}
                            onInputChange={(value) => this.handleTypeAheadInputChange(value, 'consignee')}
                            inputValue={data.consigneeInput}
                            noOptionsMsg={noOptionMsg}
                            error={errors.consignee !== '' ? true : false} helperText={errors.consignee}
                            performPartyLookupSearch={() => this.getCustomerLookup(data.consigneeInput, 'consignee')} />
                        <CpInput maxLength="50" label={intl.formatMessage({ id: 'CMUS_ShipmentsSummary_FileNo' })} name="fileNo" isRequired={false} type="text" onChange={this.onChange} />
                        <CpInput maxLength="50" label={intl.formatMessage({ id: 'EntryNo' })} name="entryNo" isRequired={false} type="text" onChange={this.onChange} />
                        <CpInput maxLength="50" label={intl.formatMessage({ id: 'CMUS_ShipmentsSummary_HouseBill' })} name="hblNo" isRequired={false} type="text" onChange={this.onChange} />
                        <CpInput maxLength="50" label={intl.formatMessage({ id: 'CMUS_ShipmentsSummary_MasterBill' })} name="mblNo" isRequired={false} type="text" onChange={this.onChange} />
                        <CpInput1
                            label={intl.formatMessage({ id: 'CMUS_ShipmentsSummary_ExportDate' })}
                            onChange={this.onChange}
                            name1="exportDateFrom" name2="exportDateTo"
                            isRequired={false}
                            type1="number" type2="number"
                            maxLength1={3} maxLength2={3}
                            value1={data.exportDateFrom} value2={data.exportDateTo}
                            texttoRightSideInput1={intl.formatMessage({ id: 'search_shipments_search_daysAgo_to' })} texttoRightSideInput2={intl.formatMessage({ id: 'search_shipments_search_daysAhead' })} />
                        <CpInput1
                            onChange={this.onChange}
                            label={intl.formatMessage({ id: 'CMUS_ShipmentsSummary_ArrivalDate' })}
                            name1="arrivalDateFrom" name2="arrivalDateTo"
                            isRequired={false}
                            type1="number" type2="number"
                            maxLength1={3} maxLength2={3}
                            value1={data.arrivalDateFrom} value2={data.arrivalDateTo}
                            texttoRightSideInput1={intl.formatMessage({ id: 'search_shipments_search_daysAgo_to' })} texttoRightSideInput2={intl.formatMessage({ id: 'search_shipments_search_daysAhead' })} />
                        <CpInput1
                            onChange={this.onChange}
                            label={intl.formatMessage({ id: 'CMUS_ShipmentsSummary_ReleaseDate' })}
                            name1="releaseDateFrom" name2="releaseDateTo"
                            isRequired={false}
                            type1="number" type2="number"
                            value1={data.releaseDateFrom} value2={data.releaseDateTo}
                            texttoRightSideInput1={intl.formatMessage({ id: 'search_shipments_search_daysAgo_to' })} texttoRightSideInput2={intl.formatMessage({ id: 'search_shipments_search_daysAhead' })} />
                        <CpSearchableTypeahead isDisabled={false} isRequired={false} searchWith="label" showTitle={true}
                            label={intl.formatMessage({ id: 'search_shipments_search_portOfLoading' })}
                            name='pol' options={polCities} value={data.pol}
                            onChange={(event) => this.handleTypeaheadChange(event, 'pol')}
                            onInputChange={(value) => this.handleTypeAheadInputChange(value, 'polInput')}
                            inputValue={data.polInput}
                            noOptionsMsg={noOptionMsg}
                            performPartyLookupSearch={() => this.getPorts(data.polInput, 'pol')}
                            error={errors.pol !== '' ? true : false} helperText={errors.pol} />
                        <CpSearchableTypeahead isDisabled={false} isRequired={false} searchWith="label" showTitle={true}
                            label={intl.formatMessage({ id: 'PortOfUnloading' })}
                            name='pod' options={podCities} value={data.pod}
                            onChange={(event) => this.handleTypeaheadChange(event, 'pod')}
                            onInputChange={(value) => this.handleTypeAheadInputChange(value, 'podInput')}
                            inputValue={data.podInput}
                            noOptionsMsg={noOptionMsg}
                            performPartyLookupSearch={() => this.getPorts(data.podInput, 'pod')}
                            error={errors.pod !== '' ? true : false} helperText={errors.pod} />
                        <CpSearchableTypeahead isDisabled={false} isRequired={false} searchWith="label" showTitle={true}
                            label={intl.formatMessage({ id: 'CMUS_ShipmentsSummary_PortOfEntry' })}
                            name='poe' options={poeCities} value={data.poe}
                            onChange={(event) => this.handleTypeaheadChange(event, 'poe')}
                            onInputChange={(value) => this.handleTypeAheadInputChange(value, 'poeInput')}
                            inputValue={data.poeInput}
                            noOptionsMsg={noOptionMsg}
                            performPartyLookupSearch={() => this.getPorts(data.poeInput, 'poe')}
                            error={errors.poe !== '' ? true : false} helperText={errors.poe} />
                        <CpInput maxLength="50" label={intl.formatMessage({ id: 'CustomerReferenceNo' })} name="custRefNo" isRequired={false} type="text" onChange={this.onChange} />
                        <CpInputMask maxLength="50" label={intl.formatMessage({ id: 'customerIRSNumber' })} name="custIRSNo" isRequired={false} type="text" onChange={this.onChange} maskingFormat="99-999999999" placeHolder='99-999999999'/>
                        <CpInput maxLength="50" label={intl.formatMessage({ id: 'ContainerNo' })} name="containerNo" isRequired={false} type="text" onChange={this.onChange} maskingFormat="99-999999999" />
                        <CpInput maxLength="50" label={intl.formatMessage({ id: 'ciNo' })} name="ciNo" isRequired={false} type="text" onChange={this.onChange} maskingFormat="99-999999999" />
                        <div style={{ marginLeft: '5%' }}>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}


export default injectIntl(withStyles(style)(CMUSSearchShipments))
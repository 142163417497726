import React from 'react'
import Box from '../../common/Box'
import DetailedMileStoneData from '../TMFF/DetailedMileStoneData'
import DocumentTable from '../../common/DocumentTable';
import RemarksCMUS from './RemarksCMUS';
import Billings from './Billings';
import Header from './Header'
import CommercialInvoiceItems from './CommercialInvoiceItems'
import DeliveryOrders from './DeliveryOrders'
import Conveyances from './Conveyances'
import ConcurPPTArrivalList from './ConcurPPTArrivalList'
import Admissions from './Admissions';
import InBondHeader from './InBondHeader';
import InBondConveyance from './InBondConveyance';
import BillsOfLading from './BillsOfLading';

class PrintShipmentCMUS extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { shipmentData, tenant, printClasses, fileType } = this.props
        const paginationResponse = {
            selectedPage: 0,
            noOfPages: 0
        }
        return (
            <div>
                {tenant.printLogo !== 'false' ?
                    <div style={{ paddingBottom: '30px' }}>
                        <img className="img" src={`/secure-ui/themes/${tenant.tenant}/company.gif`} height='40px' width='160px' alt="Logo" style={{ marginTop: '2px', objectFit: 'scale-down' }} />
                    </div>
                    :
                    null}
                {
                    shipmentData.header === '' || shipmentData.header === undefined || shipmentData.header === null ?
                        <div className={printClasses.noRecordsDiv}>
                            <span>"No Data Found"</span>
                        </div>
                        :
                        <>
                            {fileType === 'REGULAR' ?
                                <>
                                    <div>
                                        <Box header="header">
                                            <Header isPrint={true} printClasses={printClasses} headerData={shipmentData ? shipmentData.header : []} />
                                        </Box>
                                    </div>
                                    <div>
                                        <Box header="milestones">
                                            <DetailedMileStoneData isPrint={true} printClasses={printClasses} isCMUS={true} handlePageClick={() => { }} data={shipmentData ? shipmentData.milestones : []} milestonesResponse={paginationResponse} type="milestones" />
                                        </Box>
                                    </div>
                                    <div>
                                        <RemarksCMUS remarksData={shipmentData ? shipmentData.remarks : []} remarksResponse={paginationResponse} />
                                    </div>
                                    <div>
                                        <Box header="documents">
                                            <DocumentTable isCMUS={true} data={shipmentData ? shipmentData.documents : null} uploadRequired={false} readOnly={true} fromShipment={true} isPrint={true} printClasses={printClasses} />
                                        </Box>
                                    </div>
                                    <div>
                                        <CommercialInvoiceItems invoiceItemsData={shipmentData ? shipmentData.commInvItems : []} noOfPages={0} selectedPage={1} handleDisplayForward={() => { }} handlePageClick={() => { }} />
                                    </div>
                                    <div>
                                        <Billings billingData={shipmentData ? shipmentData.billing : []} noOfPages={0} selectedPage={1} />
                                    </div>
                                    <div>
                                        <DeliveryOrders delvieryOrdersData={shipmentData ? shipmentData.deliveryOrders : []} noOfPages={0} selectedPage={1} />
                                    </div>
                                </>
                                : fileType === 'FTZ' ?
                                    <>
                                        <div>
                                            <Box header="Admissions">
                                                <Admissions isPrint={true} printClasses={printClasses} headerData={shipmentData ? shipmentData.header : []} />
                                            </Box>
                                        </div>
                                        <div>
                                            <Conveyances conveyancesData={shipmentData.conveyances ? shipmentData.conveyances : []} noOfPages={0} selectedPage={1} />
                                        </div>
                                        <div>
                                            <ConcurPPTArrivalList concurPPTArvlData={shipmentData.concurPPT ? shipmentData.concurPPT : []} noOfPages={0} selectedPage={1} />
                                        </div>
                                        <div>
                                            <Box header="milestones">
                                                <DetailedMileStoneData isPrint={true} printClasses={printClasses} isCMUS={true} handlePageClick={() => { }} data={shipmentData ? shipmentData.milestones : []} milestonesResponse={paginationResponse} type="milestones" />
                                            </Box>
                                        </div>
                                        <div>
                                            <RemarksCMUS remarksData={shipmentData ? shipmentData.remarks : []} remarksResponse={paginationResponse} />
                                        </div>
                                        <div>
                                            <Box header="documents">
                                                <DocumentTable isCMUS={true} data={shipmentData ? shipmentData.documents : null} uploadRequired={false} readOnly={true} fromShipment={true} isPrint={true} printClasses={printClasses} />
                                            </Box>
                                        </div>
                                        <div>
                                            <Billings billingData={shipmentData ? shipmentData.billing : []} noOfPages={0} selectedPage={1} />
                                        </div>
                                        <div>
                                            <DeliveryOrders delvieryOrdersData={shipmentData ? shipmentData.deliveryOrders : []} noOfPages={0} selectedPage={1} />
                                        </div>
                                    </>
                                    : fileType === 'INBOND' ?
                                        <>
                                            <div>
                                                <Box header="Header">
                                                    <InBondHeader
                                                        headerData={shipmentData.header.header}
                                                        printClasses={printClasses}
                                                        isPrint={true}></InBondHeader>
                                                </Box>
                                            </div>
                                            <div>
                                                <Box header="In-Bond Conveyance">
                                                    <InBondConveyance data={shipmentData.inbondConveyance} printClasses={printClasses}
                                                        isPrint={true} />
                                                </Box>
                                            </div>
                                            <div>
                                                <BillsOfLading bolData={shipmentData.inbondBol} noOfPages={0} selectedPage={1} />

                                            </div>
                                            <div>
                                                <Box header="documents">
                                                    <DocumentTable isCMUS={true} data={shipmentData ? shipmentData.documents : null} uploadRequired={false} readOnly={true} fromShipment={true} isPrint={true} printClasses={printClasses} />
                                                </Box>
                                            </div>
                                            <div>
                                                <Billings billingData={shipmentData ? shipmentData.billing : []} noOfPages={0} selectedPage={1} />
                                            </div>
                                            <div>
                                                <DeliveryOrders delvieryOrdersData={shipmentData ? shipmentData.deliveryOrders : []} noOfPages={0} selectedPage={1} />
                                            </div>
                                            <div>
                                                <Box header="milestones">
                                                    <DetailedMileStoneData isPrint={true} printClasses={printClasses} isCMUS={true} handlePageClick={() => { }} data={shipmentData ? shipmentData.milestones : []} milestonesResponse={paginationResponse} type="milestones" />
                                                </Box>
                                            </div>
                                            <div>
                                                <RemarksCMUS remarksData={shipmentData ? shipmentData.remarks : []} remarksResponse={paginationResponse} />
                                            </div>


                                        </>
                                        : null
                            }
                        </>
                }
            </div>
        )
    }
}

export default PrintShipmentCMUS

const GET_TRACKING_DETIALS_URL = '/api-gateway/secure/1/shipments/';
const GET_TRACKING_STATUS_URL = '/api-gateway/secure/1/tracking-status';
const CREATE_BOOKING_URL = '/api-gateway/secure/1/bookings/?action=';
const GET_DESTINATIONS_URL = '/api-gateway/secure/1/shipment-destinations?countryCode=';
const GET_ROUTE_DETAILS_URL = '/route-data-service/1/routes';
const FETCH_SHIPMENTS = '/api-gateway/secure/1/tracking-status?';
const FETCH_BOOKINGS = '/api-gateway/secure/1/bookings/';
const FETCH_DOCUMENT = '/api-gateway/secure/1/docs/';
const GET_SYSTEM_CODES = '/api-gateway/1/system-codes?Type=';
const GET_COUNTRIES = '/api-gateway/1/countries';
const GET_CITIES = '/api-gateway/1/cities';
const GET_PARTIES = '/api-gateway/1/parties?LookupType=';
const GET_CONTAINERTYPES = '/api-gateway/1/container-types';
const FETCH_BOOKINGS_WIDGETS = '/api-gateway/1/booking-status-widget-metadata?countBy=bookstatus';
const FETCH_SHIPMENTS_WIDGETS_EXPORT_DUE = '/api-gateway/1/shipments-widget-metadata?countBy=poletddate&etdNoOfDaysAhead='
const FETCH_SHIPMENTS_WIDGETS_IMPORT_DUE = '/api-gateway/1/shipments-widget-metadata?countBy=podetadate&etaNoOfDaysAhead='
const FETCH_SHIPMENTS_WIDGETS_BY_COUNTRY = '/api-gateway/1/shipments-widget-metadata?countBy=podctry'
const FETCH_SHIPMENTS_WIDGETS_BY_STATUS = '/api-gateway/1/shipments-widget-metadata?countBy=status&bizType='
const CATCH_EVITICTION_BOOKINGS = '/api-gateway/secure/1/clear-bookings-widget-cache?cacheAttribute='
const CATCH_EVITCITON_SHIPMENTS = '/api-gateway/secure/1/clear-shipments-widget-cache?cacheAttribute='
const EVICT_SHIP_CONSIGNEE_CACHE = '/api-gateway/1/clear-lookup-cache'
const UPLOAD_DOCUMENT_SEARCH_TT = '/api-gateway/1/shipments-data'
const FETCH_QUOTES = "/api-gateway/1/quotes/"
const CREATE_QUOTE_URL = "/api-gateway/1/quotes/"
const FETCH_QUOTES_WIDGETS = "/api-gateway/1/quotes-widget-metadata?countBy=quotestatus"
const FETCH_QUOTES_WIDGETS_EXPIRING = "/api-gateway/1/quotes-widget-metadata?countBy=validto&validToNoOfDaysAhead="
const CATCH_EVITCITON_QUOTES = "/api-gateway/1/clear-quotes-widget-cache?cacheAttribute="
const FETCH_FILE_FILTERS = '/api-gateway/1/bizrules?'
const EDOC_UPLOAD_TT = '/api-gateway/secure/1/docs/'
const CREATE_TEMPLATE_URL = "/api-gateway/1/booking-templates/?action="
const FETCH_BOOKING_TEMPLATES = '/api-gateway/1/booking-templates/'
const GET_BOOKING_TEMPLATE_DATA = '/api-gateway/1/booking-templates/'
const GET_CUSTOMERS = "/api-gateway/1/lookups/um-parties/"
const HOMEPAGE_HELP_TMFF = '/webHelp/cp-landing-page.html'
const FAVORITE_SHIPMENT_CONTAINERS = '/api-gateway/1/favorite-shipment-containers'
const GET_CONTAINER_DETAILS = '/api-gateway/1/favorite-containers/'
const PREFERENCES_URL='/api-gateway/1/preferences/'
const CONCURRENT_USERCOUNT_URL='/api-gateway/1/concurrent-user-count'

export {
    GET_TRACKING_DETIALS_URL,
    GET_TRACKING_STATUS_URL,
    GET_ROUTE_DETAILS_URL,
    GET_DESTINATIONS_URL,
    CREATE_BOOKING_URL,
    FETCH_SHIPMENTS,
    FETCH_BOOKINGS,
    FETCH_DOCUMENT,
    GET_SYSTEM_CODES,
    GET_COUNTRIES,
    GET_CITIES,
    GET_PARTIES,
    GET_CONTAINERTYPES,
    FETCH_BOOKINGS_WIDGETS,
    FETCH_SHIPMENTS_WIDGETS_EXPORT_DUE,
    FETCH_SHIPMENTS_WIDGETS_IMPORT_DUE,
    FETCH_SHIPMENTS_WIDGETS_BY_COUNTRY,
    FETCH_SHIPMENTS_WIDGETS_BY_STATUS,
    CATCH_EVITCITON_SHIPMENTS,
    CATCH_EVITICTION_BOOKINGS,
    EVICT_SHIP_CONSIGNEE_CACHE,
    UPLOAD_DOCUMENT_SEARCH_TT,
    FETCH_QUOTES,
    CREATE_QUOTE_URL,
    FETCH_QUOTES_WIDGETS,
    FETCH_QUOTES_WIDGETS_EXPIRING,
    CATCH_EVITCITON_QUOTES,
    FETCH_FILE_FILTERS,
    EDOC_UPLOAD_TT,
    CREATE_TEMPLATE_URL,
    FETCH_BOOKING_TEMPLATES,
    GET_BOOKING_TEMPLATE_DATA,
    GET_CUSTOMERS,
    HOMEPAGE_HELP_TMFF,
    FAVORITE_SHIPMENT_CONTAINERS,
    GET_CONTAINER_DETAILS,
    PREFERENCES_URL,
    CONCURRENT_USERCOUNT_URL
}
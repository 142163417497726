import React from 'react'
import { Switch, withStyles, FormControlLabel } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import CPToolTip from './CPToolTip'


const style = {
    switchBase: {
        "&$checked": {
            color: green[500]
        },
        "&$checked + $track": {
            backgroundColor: green[500]
        }
    },
    checked: {},
    track: {},
    swtichWrapper: {
        width: '100%',
        display: 'inline-flex',
        padding: '0% 0 0 5%'
    },
    fieldLabel: {
        display: 'inline-block',
        width: '50%',
        fontWeight: '500',
        paddingTop: '2.5%',
        fontSize: '14px'
    },
    required: {
        color: 'red',
        paddingTop: '2.5%',
        width: '2%'
    },
    fieldLabel2: {
        display: 'inline-block',
        width: '124px',
        fontWeight: 'bold',
        paddingTop: '4.5%',
        fontSize: '14px'
    }
}
function CpSwitch(props) {
    const { classes, label, onChange, isRequired, name, value, isDisabled, isLabelRequired, isForm, toolTipValue } = props
    return (
        <div className={classes.swtichWrapper}  >
            {isLabelRequired ?
                <>
                    <div className={classes.required}>{isRequired ? <span>*</span> : ""}</div>
                    <div className={isForm === false ? classes.fieldLabel2 : classes.fieldLabel}><label style={{fontWeight:500}} >{label}</label></div>
                </>
                : null
            }
            <FormControlLabel
                control={
                    <CPToolTip title={toolTipValue ? toolTipValue : ""} >
                        <Switch disabled={isDisabled} classes={{ switchBase: classes.switchBase, checked: classes.checked, track: classes.track }} checked={value} onChange={onChange} name={name} />
                    </CPToolTip>
                }
                label={isLabelRequired ? (value ? "YES" : "NO") : null}
            />

        </div>
    )
}

export default withStyles(style)(CpSwitch)

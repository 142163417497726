import React, { Component } from 'react'
import { withStyles, Fab, Typography, Link } from '@material-ui/core';
import ShipmentDetails from './ShipmentDetails'
import CPCSVDownloader from '../../common/CPCSVDownloader';
import { fetchShipmentDetails, searchShipmentDetails } from '../../../sevices/CMUS/Services';
import { getContrastColor } from '../../../common/utils';
import withLoading from '../../common/withLoading';
import { injectIntl } from "react-intl";
import CardsTable from '../../mobileComponents/CardsTable';
import ScrollTop from '../../mobileComponents/ScrollTop';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PageNumbers from "../../common/Pagination/PageNumbers"
import CpBreadcrumb from '../../common/CpBreadcrumb';

const style = ({ breakpoints, palette }) => ({
    containerDiv: {
        background: 'white',
        padding: '0 2%',
        overflow: 'auto'
    },
    heading: {
        background: 'white',
        height: '4rem',
        padding: '0.75%',
        display: 'flex'
    },
    underline: {
        '&:hover': {
            textDecoration: "underline !important",
            color: '#277AB5'
        }
    },
    shipmentsHeading: {
        width: '90%',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    gridText: {
        fontWeight: '700',
        fontStyle: 'normal',
        marginTop: '2%',
        marginBottom: '2%',
        height: '4rem',
        display: 'flex'
    },
    buttonWarpper: {
        padding: '1% 0',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#007ad9'
    },
    recordCount: {
        marginLeft: '10px',
        //color: 'dimgrey',
        fontSize: '14px',
        fontFamily: 'sans-serif',
        fontWeight: 400,
        fontStyle: 'normal',
        color: '#545F66'
    },
    tableWrapper: {
        border: '1px solid #E8F0F4 !important'
    },
    sectionDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        justifyContent: 'center',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    fabIcon: {
        backgroundColor: palette.primary.main,
        color: getContrastColor(palette.primary.main)
    },
    detailText: {
        paddingLeft: '0.75%',
        fontSize: '24px'
    },
    recordsText: {
        flexGrow: '1',
        paddingLeft: '0.75%',
        paddingTop: '0.65%',
        fontSize: '14px'
    }
})

const downloadheaders = [
    { label: "Shipper", key: "shipperName" },
    { label: "Arrival Date", key: "arrivalDate" },
    { label: "Country of Export", key: "countryOfExportName" },
    { label: "Customer Name", key: "custName" },
    { label: "Export Date", key: "exportDate" },
    { label: "File No.", key: "fileNo" },
    { label: "House Bill No.", key: "houseBill" },
    { label: "Master Bill No.", key: "masterBill" },
    { label: "MOT", key: "motDesc" },
    { label: "Port of Entry", key: "portOfEntryName" },
    { label: "Release Date", key: "releaseDate" },
    { label: "FDA Status", key: "fdaStatus" },
    { label: "PGA Status", key: "pgaStatus" },
    { label: "PGA Agency", key: "pga" }

]
const mobileHeaders = [
    { label: "Shipper", key: "shipperName" },
    { label: "Arrival Date", key: "arrivalDate" },
    { label: "Country of Export", key: "countryOfExportName" },
    { label: "Customer Name", key: "custName" },
    { label: "Export Date", key: "exportDate" },
    { label: "File No.", key: "fileNo" },
    { label: "House Bill", key: "houseBill" },
    { label: "Master Bill", key: "masterBill" },
    { label: "MOT", key: "motDesc" },
    { label: "Port of Entry", key: "portOfEntryName" },
    { label: "Release Date", key: "releaseDate" },
    { label: "FDA Status", key: "fdaStatus" },
    { label: "PGA Status", key: "pgaStatus" },
    { label: "PGA Agency", key: "pga" }
]
class CMUSShipments extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: null,
            nextPageIndex: 0,
            fetchedShipments: null,
            mobileData: null,
            filteredData: [],
            heading: '',
            noOfPages: 0,
            selectedPage: 1
        }
    }

    componentDidMount = async () => {
        const { location } = this.props
        if (location.state) {
            const { data, fromDashBoard, heading } = location.state
            sessionStorage.setItem("fromDashBoard", fromDashBoard ? JSON.stringify(true) : JSON.stringify(false))
            sessionStorage.setItem("shipmentsHeading", heading ? JSON.stringify(heading) : "")
            sessionStorage.setItem("shipmentData", JSON.stringify(data))
            this.fetchShipments(false);
        }
        else if (sessionStorage.getItem("shipmentData")) {
            this.fetchShipments(false)
        }
    }

    fetchShipments = async (sendpageNumber) => {
        const { loading } = this.props
        const { selectedPage } = this.state
        loading.setLoadingmsg(true)
        const heading = sessionStorage.getItem("shipmentsHeading") ? JSON.parse(sessionStorage.getItem("shipmentsHeading")) : ''
        const data = JSON.parse(sessionStorage.getItem("shipmentData"))
        const shipments = data.dataFor ?
            await fetchShipmentDetails(data.dataFor, data.value, sendpageNumber ? selectedPage : undefined) :
            await searchShipmentDetails(data, selectedPage);
        if (shipments && shipments.data) {
            await this.setState({
                data: shipments.data.shipments,
                filteredData: shipments.data.shipments,
                mobileData: shipments.data.shipments,
                noOfPages: shipments.data.maxResults !== 0 ? Math.ceil(shipments.data.totalRecords / shipments.data.maxResults) : 1,
                heading: heading,
                totalRecords:shipments.data.totalRecords
            })
        } else {
            this.setState({ data: [], filteredData: [], mobileData: [] })
        }
        loading.setLoadingmsg(false)
    }
    onCardClick = async (id) => {
        const { handleRowClick } = this.props;
        handleRowClick([this.state.mobileData[id]], 'shipments');
    }
    filterchange = async (event) => {
        const filteredData = event.api.rowModel.rowsToDisplay.map(row => {
            return row.data
        })
        this.setState({
            filteredData: filteredData
        })
    }
    handlePageClick = async (pageIndex) => {
        await this.setState({
            selectedPage: pageIndex,
        })
        this.fetchShipments(true)
    }
    getBackHome = () => {
        const { backToHome } = this.props;
        sessionStorage.removeItem("shipmentsHeading")
        sessionStorage.removeItem("shipmentData")
        backToHome('shipments')
    }
    render() {
        const { classes, handleRowClick, intl } = this.props;
        const { data, mobileData, filteredData, heading, noOfPages, selectedPage,totalRecords } = this.state
        return (
            <div className={classes.containerDiv}>
                <div className={classes.heading}>
                    <CpBreadcrumb>
                        <Link color='inherit' classes={{ underlineHover: classes.underline }} href="#" onClick={() => this.getBackHome()}>{intl.formatMessage({ id: 'home' })}</Link>
                        <Typography color='textPrimary' >{intl.formatMessage({ id: 'shipments' })}</Typography>
                    </CpBreadcrumb>
                </div>
                <div className={classes.gridText}>
                    <span className={classes.detailText}> {heading !== '' ? `${Object.keys(heading)[0]} | ${heading[Object.keys(heading)[0]]}` : intl.formatMessage({ id: 'shipments_grid_selection' })}</span>
                    <span className={classes.recordsText}>{totalRecords ? totalRecords : '0'} {intl.formatMessage({ id: 'records' })}</span>
                    {filteredData.length > 0 ? <CPCSVDownloader data={filteredData} headers={downloadheaders} filename="shipments" style={{ float: 'right' }} /> : null}
                </div>
                <div className={classes.tableWrapper}>
                    <div className={classes.sectionDesktop}>
                        {data ? <ShipmentDetails data={data} totalRecords={totalRecords}handleRowClick={handleRowClick} filterchange={this.filterchange} /> : null}
                    </div>
                    <div className={classes.sectionMobile}>
                        <CardsTable data={mobileData} headers={mobileHeaders} onCardClick={this.onCardClick} />
                        <ScrollTop >
                            <Fab className={classes.fabIcon} size="medium" aria-label="scroll back to top">
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </div>
                    <PageNumbers noOfPages={noOfPages} selected={selectedPage} onClick={this.handlePageClick} />
                </div>

            </div>
        )
    }
}

export default injectIntl(withLoading(withStyles(style)(CMUSShipments)))
import React from 'react'
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers'
import { FormHelperText } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { withStyles, useTheme, useMediaQuery } from '@material-ui/core';

const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            width: '35%',
            fontWeight: 'bold',
            fontSize: '14px'
        }
    },
    dateTimeWrapper: {
        width: '100%',
        display: 'inline-flex',
        padding: '0',
        margin: '0.5rem 2px',
        alignItems: 'center'
    },
    fieldContent: {
        width: '56%'
    },
    fieldContent1: {
        width: '44%',
        marginRight: '2%',
        [theme.breakpoints.up('md')]: {
            width: '25%',
            marginRight: '2%'
        }
    },
    fieldContent2: {
        width: '44%',
        [theme.breakpoints.up('md')]: {
            width: '29%'
        }
    },
    required: {
        color: 'red',
        width: '2%'
    },
    button: {
        padding: '6px'
    },
    errorMessage: {
        padding: '0% 0 0 40%'
    },
    input: {
        '&.MuiOutlinedInput-root': {
            '&	.MuiOutlinedInput-input': {
                display: 'block',
                lineHeight: 'calc(4rem - 4px)',
                minHeight: '3rem',
                padding: '0 1rem',
                position: 'relative',
                verticalAlign: 'middle',
                width: '100%',
                backgroundColor: '#FFFFFF',
                color: '#4A4E50'
            }
        }
    },
    notchedOutline: {
        borderRadius: '4px',
        border: '2px solid #C8CFD2',
        transitionDuration: '100ms',
        transitionProperty: 'border-color, background-color, color, box-shadow',
        '&:focus': {
            outlineWidth: '0',
            borderColor: theme.palette.primary.main,
            boxShadow: '0 0 0 3px #e3ecef'
        },
        '&focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px'
        }
    },
    disabled: {

    }
})
function CpTime(props) {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const { classes, isDisabled, isRequired, label, label1, label2, timeValue1, timeValue2, timeName1, timeName2, onTimeChange1, onTimeChange2, helperText } = props
    return (
        <React.Fragment>
            <div className={classes.dateTimeWrapper} >
                {label ?
                    <>
                        <div className={classes.sectionDesktop}><label style={{fontWeight:500}}htmlFor={timeName1}>{label} {isRequired ? <span className={classes.required}>*</span> : ""}</label></div>
                    </>
                    : null}
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    < KeyboardTimePicker className={classes.fieldContent1}
                        name={timeName1}
                        ampm={false}
                        label={isDesktop ? '' : label1}
                        disabled={isDisabled}
                        placeholder='HH:mm'
                        inputVariant="outlined"
                        InputProps={{ classes: { root: classes.input, disabled: classes.disabled, notchedOutline: classes.notchedOutline } }}
                        InputLabelProps={{ margin: 'dense' }}
                        error={helperText === '' ? false : true}
                        invalidDateMessage={isDisabled ? '' : "Invalid time format"}
                        value={isDisabled || (timeValue1 && timeValue1.isValid()) ? moment(timeValue1, 'HH:mm') : timeValue1}
                        onChange={(value) => onTimeChange1(value, timeValue2)}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                            className: classes.button
                        }}
                        keyboardIcon={<ScheduleIcon color={isDisabled ? 'disabled' : 'secondary'} style={{ color: isDisabled ? 'disabled' : '#4A4E50' }} />}
                    />
                    < KeyboardTimePicker className={classes.fieldContent2}
                        name={timeName2}
                        ampm={false}
                        label={isDesktop ? '' : label2}
                        disabled={isDisabled}
                        placeholder='HH:mm'
                        inputVariant="outlined"
                        InputProps={{ classes: { root: classes.input, disabled: classes.disabled, notchedOutline: classes.notchedOutline } }}
                        InputLabelProps={{ margin: 'dense' }}
                        error={helperText === '' ? false : true}
                        invalidDateMessage={isDisabled ? '' : "Invalid time format"}
                        value={isDisabled || (timeValue2 && timeValue2.isValid()) ? moment(timeValue2, 'HH:mm') : timeValue2}
                        onChange={(value) => onTimeChange2(timeValue1, value)}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                            className: classes.button
                        }}
                        keyboardIcon={<ScheduleIcon color={isDisabled ? 'disabled' : 'secondary'} style={{ color: isDisabled ? 'disabled' : '#4A4E50' }} />}
                    />
                </MuiPickersUtilsProvider>
            </div>
            <div className={classes.errorMessage}>
                <FormHelperText error={true}>{helperText}</FormHelperText>
            </div>
        </React.Fragment>
    )
}

export default withStyles(style)(CpTime)

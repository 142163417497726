import React from 'react'
import { Card, CardContent, Grid, IconButton, withStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FlightIcon from '@material-ui/icons/Flight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { ReactComponent as SeaIcon } from '../../../images/marine.svg';
import CloseIcon from '@material-ui/icons/Close';

const style = theme => ({
    container: {
        margin: '2%'
    },
    headBox: {
        backgroundColor: '#FFFFFF',
        maxWidth: '100%',
        fontSize: '14px',
        padding: '1rem',
        borderBottom: '1px solid #417F90',
        color: '#4A4E50',
        fontWeight: '600',
        display: 'flex',
        alignItems: 'center'
    },
    headerIcon: {
        color: '#6E787D',
        fontSize: '24px'
    },
    heading: {
        marginLeft: '2%'
    },
    body: {
        height: '40vh',
        overflowY: 'auto',
        overflowX: 'auto'
    },
    card: {
        minHeight: '1.75rem',
        display: 'flex',
        width: '96%',
        margin: '2%',
        cursor: 'pointer'
    },
    cardContent: {
        padding: '2%'
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    value: {
        width: '100%',
        display: 'inline-block'
    },
    button: {
        padding: '0.5rem',
        margin: '0.5rem 1rem 0.5rem 0'
    }
});

function FavouritiesList(props) {

    const { classes, onFavShipmentClick, onFavContainerClick, intl, favourities, handleDrawer } = props
    const hyphen = '----'

    const getLocation = (item, isContainer) => {
        if (item) {
            if (item.bizType === 'Air' || item.bizType === 'Sea' || isContainer) {
                if (sessionStorage.getItem('tenant') === 'EIMSKIP') {
                    if (item.hasOwnProperty('porName') || item.hasOwnProperty('devryName')) {
                        return (<div><span>{item.porName ? item.porName : ''}</span><ArrowRightAltIcon fontSize="medium" /><span>{item.devryName ? item.devryName : ''}</span></div>)
                    }
                    else if (item.hasOwnProperty('polName') || item.hasOwnProperty('podName')) {
                        return (<div><span>{item.polName ? item.polName : ''}</span><ArrowRightAltIcon fontSize="medium" /><span>{item.podName ? item.podName : ''}</span></div>)
                    }
                }
                else {
                    if (item.hasOwnProperty('porName') || item.hasOwnProperty('destName')) {
                        return (<div><span>{item.porName ? item.porName : ''}</span><ArrowRightAltIcon fontSize="medium" /><span>{item.destName ? item.destName : ''}</span></div>)
                    }
                    else if (item.hasOwnProperty('polName') || item.hasOwnProperty('podName')) {
                        return (<div><span>{item.polName ? item.polName : ''}</span><ArrowRightAltIcon fontSize="medium" /><span>{item.podName ? item.podName : ''}</span></div>)
                    }
                }
            }
            else if (item.bizType === 'Inland') {
                if (item.hasOwnProperty('porName') || item.hasOwnProperty('devryName')) {
                    return (<div><span>{item.porName ? item.porName : ''}</span><ArrowRightAltIcon fontSize="medium" /><span>{item.devryName ? item.devryName : ''}</span></div>)
                }
            }
        }
        return '';
    }
    const getIconForBizType = (bizType) => {
        if (bizType === "Air") {
            return (<FlightIcon className={classes.headerIcon} />);
        } else if (bizType === "Sea") {
            return (<SeaIcon alt="shipIcon" className={classes.headerIcon} />)
        } else if (bizType === "Inland") {
            return (<LocalShippingIcon className={classes.headerIcon} />);
        }
    }
    return (
        <div className={classes.container}>
            <Grid flex="true" container spacing={0}>
                <Grid item xs={11} sm={11} md={11} lg={11} xl={11} >
                    <div className={classes.headBox}>
                        <LocalShippingIcon className={classes.headerIcon} />
                        <span className={classes.heading}>{intl.formatMessage({ id: 'pinned_shipments' })}</span>
                    </div>
                    <div className={classes.body}>
                        {favourities && favourities.shipments && favourities.shipments.length > 0 ?
                            favourities.shipments.map((item, index) => {
                                return (
                                    <Card key={index} className={classes.card} elevation={2} onClick={() => onFavShipmentClick(item)}>
                                        <CardContent className={classes.cardContent}>
                                            <div className={classes.cardHeader}>
                                                <div>{getIconForBizType(item.bizType)}</div>
                                                <div className={classes.value}><span style={{ fontWeight: 'bold' }}>{item.masterNo ? item.masterNo : (item.shpNo ? item.shpNo : hyphen)}</span></div>
                                            </div>
                                            <div className={classes.value}><span>{item.shprName ? item.shprName : hyphen}</span></div>
                                            <div className={classes.value}><span>{getLocation(item, false)}</span></div>
                                        </CardContent>
                                    </Card>
                                )
                            })
                            :
                            null
                        }
                    </div>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                    <IconButton id="confirmCancel" onClick={handleDrawer} className={classes.button}>
                        <CloseIcon style={{ fontSize: '20px' }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid flex="true" container spacing={0}>
                <Grid item xs={11} sm={11} md={11} lg={11} xl={11} >
                    <div className={classes.headBox}>
                        <ListAltIcon className={classes.headerIcon} />
                        <span className={classes.heading}>{intl.formatMessage({ id: 'containers' })}</span>
                    </div>
                    <div className={classes.body}>
                        {favourities && favourities.containers && favourities.containers.length > 0 ?
                            favourities.containers.map((item, index) => {
                                return (
                                    <Card key={index} className={classes.card} elevation={2} onClick={() => onFavContainerClick(item)}>
                                        <CardContent className={classes.cardContent}>
                                            <div className={classes.value}><span style={{ fontWeight: 'bold' }}>{item.containerNo ? item.containerNo : hyphen}</span></div>
                                            <div className={classes.value}><span>{item.masterNo ? item.masterNo : hyphen}</span></div>
                                            <div className={classes.value}><span>{getLocation(item, true)}</span></div>
                                        </CardContent>
                                    </Card>
                                )
                            })
                            :
                            null
                        }
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}

export default injectIntl(withStyles(style)(FavouritiesList))

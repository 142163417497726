import React from 'react'
import { Route } from 'react-router-dom'
import Page403 from '../ErrorPages/Page403'

let paths = window.location.hash.split("/");
const getQueryParam = (param) => {
    var rx = new RegExp("[?]" + param + "=([^&]+).*$");
    var returnVal = paths[2].match(rx);
    return returnVal === null ? "" : returnVal[1];
}
if (paths && paths[2] !== undefined && paths[2].includes('reset')) {
    const result = getQueryParam('token');
    sessionStorage.setItem('reset-token', result)
    sessionStorage.setItem('tenant', paths[1] ? paths[1].toUpperCase() : '')
    sessionStorage.setItem('tenant-url', paths[1] ? paths[1] : '')
}
function ResetPasswordRoute({ children }) {
    return (
        <Route
            render={({ location }) =>
                sessionStorage.getItem("reset-token") !== null && sessionStorage.getItem("reset-token") !== '' ?
                    <>
                        {children}
                    </>
                    : <Page403 isForReset={true} />
            }
        />
    )
}

export default ResetPasswordRoute
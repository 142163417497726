import React from 'react'
import { injectIntl } from 'react-intl';
import CpEditableTable from '../../common/CpEditableTable';
import { Link, Typography } from '@material-ui/core';
import Box from '../../common/Box';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';
import CpBreadcrumb from '../../common/CpBreadcrumb';



function CommercialInvoiceTariff(props) {

    const commInvoiceLineHeaders = [
        { title: "CI Line No.", field: "ciLineNo" },
        { title: "Tariff Line No.", field: "tariffLineNo" },
        { title: "Tariff No.", field: "tariffNo" },
        { title: "Value US$", field: "valueUs" },
        { title: "Entered Value ", field: "valueEntered" },
        { title: "Weight", field: "weight" },
        { title: "SPI Primary", field: "spiPrimary" },
        { title: "Secondary SPI", field: "spiSecondary" },
        { title: "PGA Indicator", field: "pgaIndicator" },
        { title: "Duty", field: "duty" },
    ]

    const pgaHeaders = [
        { 'width': '15%', 'title': 'Agency', 'field': 'agencyName', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': 'Disclaim', 'field': 'isDisclaimer', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': 'Declaration Code', 'field': 'disclaimerTypeCd', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': 'PG Status', 'field': 'narrativeMessage', 'showLabel': true, 'type': 'label' }
    ]

    const { intl, handleDisplayBack, commInvoiceTariffData, handleDisplayForward, l1totalRecords, l1selectedRecord, l2totalRecords, l2selectedRecord, l3totalRecords, l3selectedRecord } = props

    const qtyHeader = [
        { 'width': '100%', 'title': intl.formatMessage({ id: 'quantity' }), 'field': 'quantity', 'showLabel': true, 'type': 'label' }
    ]

    return (
        <div>
            <div style={{ display: 'flex', margin: '2rem 0 0 2rem' }}>
                <CpBreadcrumb >
                    <Link color='inherit' onClick={() => handleDisplayBack(1)}> {intl.formatMessage({ id: 'commercial_invoice_item_caps' }, { val1: l1selectedRecord, val2: l1totalRecords })} </Link>
                    <Link color='inherit' onClick={() => handleDisplayBack(2)}> {intl.formatMessage({ id: 'commercial_invoice_line' }, { val1: l2selectedRecord, val2: l2totalRecords })} </Link>
                    <Typography color='textPrimary'>{intl.formatMessage({ id: 'tarrif_caps' }, { val1: l3selectedRecord, val2: l3totalRecords })}</Typography>
                </CpBreadcrumb>
            </div>
            <Box showloading={true} header={intl.formatMessage({ id: 'tarrif_caps' }, { val1: l3selectedRecord, val2: l3totalRecords })}>
                <NonEditableDataGrid list={commInvoiceLineHeaders} data={commInvoiceTariffData ? commInvoiceTariffData : {}} />
                <CpEditableTable headers={qtyHeader} readOnly={true} data={commInvoiceTariffData && commInvoiceTariffData.quantityList ? commInvoiceTariffData.quantityList : []} />
            </Box>
            <Box header='PGA'>
                <CpEditableTable headers={pgaHeaders} readOnly={true} data={commInvoiceTariffData && commInvoiceTariffData.pgEsList ? commInvoiceTariffData.pgEsList : []} navigateIcon={true} handleDisplayForward={(row, index) => handleDisplayForward(4, row, index)} />
            </Box>
        </div>
    );
}

export default injectIntl(CommercialInvoiceTariff)
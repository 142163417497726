import React, { useState } from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, withStyles, IconButton } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import CpDropDown from './CpDropDown';
import { getDocumentDownloaded } from '../../sevices/TMFF/Services';
import { downloadCMUSDocument } from '../../sevices/CMUS/Services';
import withLoading from './withLoading';
import { injectIntl } from "react-intl";
import CPConfirmBox from './CPConfirmBox';
import CPToolTip from './CPToolTip';
import { DropzoneArea } from 'material-ui-dropzone';

const style = ({ breakpoints, palette }) => ({
    tableHeader: {
        fontWeight: 'bold !important',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        borderBottom: '4px solid #CDE3E9',
        height: '54px',
        padding: '6px 6px 6px 10px'
    },
    tabletr: {
        display: ' table !important',
        width: '100%',
        fontSize: '14px',
        tableLayout: 'fixed',
        margin: 0,
        fontFamily: "'Open Sans', Arial, sans-serif !important",
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        '&:nth-child(even)': {
            backgroundColor: '#F3F7F9'
        }
    },
    tableCell: {
        fontSize: '14px',
        border: '1px solid #DAE7EA',
        height: '48px',
        padding: '6px 6px 6px 10px',
        borderLeft: '0px'
    },
    noRecords: {
        minHeight: '10vh',
        fontStyle: 'italic'
    },
    noRecordscell: {
        fontSize: '14px',
        fontFamily: 'Open Sans italic'
    },
    buttonWarpper: {
        padding: '1%',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#007ad9',
        width: '100%',

    },
    errorWarpper: {
        padding: '0.5%',
        fontWeight: 'bold',
        color: '#007ad9',
        overflowWrap: 'anywhere'
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    checkBox: {
        root: {
            "&checked": {
                color: '#007ad9'
            }
        },
        checked: {
            color: '#007ad9'
        }
    },
    iconButton: {
        marginLeft: '8px',
        cursor: 'pointer',
        padding: '0px',
        '&:disabled': {
            color: '#545F66',
            cursor: 'not-allowed'
        }
    },
    Download: {
        color: '#007ad9'
    },
    Delete: {
        color: 'red'
    },
    sectionDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    textButton: {
        cursor: 'pointer',
        color: palette.secondary.main
    }
})
function DocumentTable(props) {
    const { data, classes, onUpload, removeNewDocFunc, onChangeDocfields, handleDeleteExistingDocs, uploadRequired, intl, 
        readOnly, isPrint, printClasses, conCloseBy, finCloseBy, fileTypes, fileSize, fileErrors, isCMUS, docErrorHandle } = props
    const headers = [
        { title: intl.formatMessage({ id: 'docDescription' }), field: "description", width: '20%' },
        { title: intl.formatMessage({ id: 'fileName' }), field: "fileName", width: '20%' },
        { title: intl.formatMessage({ id: 'type' }), field: "type", width: '20%' },
        { title: isCMUS ? intl.formatMessage({ id: 'dateTime' }) : intl.formatMessage({ id: 'date' }), field: "uploadDate", width: '20%' },
        { title: intl.formatMessage({ id: 'uploadBy' }), field: "uploadByName", width: '20%' }
    ]
    const [openDialog, setopenDialog] = useState(false)
    const [documentId, setdocumentId] = useState(0)
    const getDescription = (row) => {
        const { docTypes } = props;

        const docDesc = docTypes.length > 0 ?
            docTypes.filter((item) => {
                return item.value === row.type
            }) : []
        return docDesc.length > 0 ? docDesc[0].label : '';
    }
    const download = async (event, from, row) => {
        const { loading, intl } = props
        loading.setCustomMsg(intl.formatMessage({ id: 'downloading' }));
        if (from === 'icons') {
            let selectedrow = data.map(document => {
                return (document.selected === true ? document : null)
            })
            selectedrow = selectedrow.filter(row => {
                return row !== null
            })
            row = selectedrow[0]
        }

        event.stopPropagation();

        if (isCMUS) {
            downloadCMUSDocument(row, 'download').then(res => {
                loading.setCustomMsg(null);
                if (res) {
                    if (res.status === 204) {
                        docErrorHandle(intl.formatMessage({ id: 'docDownloadNotAvailable' }), res.status)
                    } else if (res.status !== 200) {
                        docErrorHandle(res.data && res.data.fieldErrors ? res.data.fieldErrors[0] : intl.formatMessage({ id: 'somethingWentWrong' }), res.status)
                    }
                }
            })
        }
        else {
            const res = await getDocumentDownloaded(row, 'download');
            loading.setCustomMsg(null);
            if (res) {
                if (res.status === 204) {
                    docErrorHandle(intl.formatMessage({ id: 'docDownloadNotAvailable' }), res.status)
                } else if (res.status !== 200) {
                    docErrorHandle(res.data && res.data.fieldErrors ? res.data.fieldErrors[0] : intl.formatMessage({ id: 'somethingWentWrong' }), res.status)
                }
            }
        }
    }
    const getIcon = (row) => {
        if (readOnly === true) {
            return (
                <TableCell className={classes.tableCell} style={{ width: '10%' }} >
                    <IconButton  disabled={true}>
                        <DeleteIcon fontSize='large' style={{ color: 'silver', cursor: 'pointer' }} />
                    </IconButton>
                    <GetAppIcon fontSize='large' style={{ color: '#417F90', cursor: 'pointer' }} onClick={(event) => { event.preventDefault(); download(event, 'row', row) }} />
                </TableCell>
                );
        }
        else {
            switch (row.action) {
                case "new":
                    return (
                        <TableCell className={classes.tableCell} style={{ width: '10%' }} >
                            <DeleteIcon fontSize='large' style={{ color: '#417F90', cursor: 'pointer' }} onClick={() => removeNewDocFunc(row.docSNo)} />
                        </TableCell>)
                case "existing":
                    const isDeleteDisabled = row.source !== 'CP' || (finCloseBy ?? false) || (conCloseBy ?? false);
                    return (
                        <TableCell className={classes.tableCell} style={{ width: '10%' }} >
                            <IconButton onClick={() => { setopenDialog(true); setdocumentId(row.docSNo) }} disabled={isDeleteDisabled}>
                                <DeleteIcon fontSize='large' style={{ color: isDeleteDisabled ? 'silver' : '#417F90', cursor: 'pointer' }} />
                            </IconButton>
                            <GetAppIcon style={{ fontSize: '20px', paddingTop: '1px', color: '#417F90', marginLeft: '1rem', cursor: 'pointer' }} onClick={(event) => { event.preventDefault(); download(event, 'row', row) }} />
                        </TableCell>)
                default:
                    // Uncomment in future to use download icon and delete
                    //
                    // if (props.fromShipment) {
                    //     return (<TableCell style={{ width: '5%' }} >
                    //         <Checkbox checked={row.selected}
                    //             color='default'
                    //             classes={{ root: classes.checkBox.root, checked: classes.checkBox.checked }}
                    //             icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '25px' }} />}
                    //             checkedIcon={<CheckBoxIcon style={{ fontSize: '25px' }} />}
                    //             onChange={(event) => enableIconsForDocs(event, row.externalID)} />
                    //     </TableCell>)
                    // } else {
                    return (
                        <TableCell className={classes.tableCell} style={{ width: '10%' }} >
                            <GetAppIcon style={{ fontSize: '20px', paddingTop: '1px', color: '#417F90', cursor: 'pointer' }} onClick={(event) => { event.preventDefault(); download(event, 'row', row) }} />
                        </TableCell>);
            }
        }


    }
    const getinput = (row) => {
        return <CpDropDown id={'description' + row.docSNo} name="description" sendLabel={isCMUS ? true : false} value={row["description"]} items={props.docTypes} onChange={(event) => onChangeDocfields(row['docSNo'], event.target.value)} />
    }
    const onDeleteDoc = () => {
        setopenDialog(false)
        handleDeleteExistingDocs(documentId)
    }
    const voidHandler = () => {
        setopenDialog(false)
    }
    const handleChange = (files) => {
        //const {onUpload} = this.props
        if (files && files.length > 0) {
            onUpload(files, "add")
        }
    }

    return (
        <>
            <div >
                <CPConfirmBox show={openDialog} message={intl.formatMessage({ id: "deleteDocMsg" })} handleRejected={voidHandler} handleAccepted={onDeleteDoc} />
                {uploadRequired && readOnly !== true && !(finCloseBy || conCloseBy) ?
                    <div className={classes.mainDiv}>
                        {
                            <div className={classes.buttonWarpper} >
                                {
                                    <CPToolTip title={`Allowed file size is up to ${fileSize}B and types are "${fileTypes.replace(/\./g, '')}"`} placement="top" className={classes.toolTip} arrow>
                                        <div>
                                            <DropzoneArea
                                                showFileNames={false}
                                                dropzoneText="Drag and drop a file here, or click to select a file"
                                                showAlerts={false}
                                                onChange={(event) => handleChange(event)}
                                                showFileNamesInPreview={false}
                                                maxFileSize={55899301}
                                                useChipsForPreview={false}
                                                showPreviews={false}
                                                showPreviewsInDropzone={false}
                                                filesLimit={1}
                                            />
                                        </div>
                                    </CPToolTip>
                                }
                            </div>
                        }
                        <div className={classes.errorWarpper}>
                            {fileErrors && fileErrors.invalidType === true ? (<span style={{ color: 'red ' }}>{intl.formatMessage({ id: "invalidFileType" })}{fileTypes.replace(/\./g, '')}{intl.formatMessage({ id: "types" })}</span>) :
                                fileErrors && fileErrors.limitExceded === true ? (<span style={{ color: 'red ' }}>{intl.formatMessage({ id: "fileSizeExceded" })}{fileSize}B</span>) : null}
                        </div>
                    </div>
                    : null
                }
                <Table size='small' style={{ overflowX: 'auto' }}>
                    <TableHead>
                        <TableRow className={classes.tabletr} >
                            <TableCell className={classes.tableHeader} style={{ width: '10%' }} > </TableCell>
                            {
                                headers.map((header, index) => {
                                    return (
                                        <TableCell key={index} style={{ width: header.width }} className={classes.tableHeader}>{header.title}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(data && data.length > 0) ?
                            data.map((row, index) => {
                                return (
                                    row.actionCode !== 'D' ?
                                        <CPToolTip key={index} title={row && row.suffix ? row.suffix : ''} className={classes.toolTip} arrow>
                                            <TableRow className={classes.tabletr} >
                                                {getIcon(row)}
                                                <TableCell className={classes.tableCell} style={{ width: '20%' }}>{row.enablefields ? getinput(row) : isCMUS ? row.description : getDescription(row)}</TableCell>
                                                <TableCell className={classes.tableCell} style={{ width: '20%', overflowWrap: 'anywhere' }}>{row.fileName}</TableCell>
                                                <TableCell className={classes.tableCell} style={{ width: '20%' }}>{isCMUS ? row.documentType : row.type}</TableCell>
                                                <TableCell className={classes.tableCell} style={{ width: '20%' }}>{isCMUS ? row.docDate : row.uploadDate}</TableCell>
                                                <TableCell className={classes.tableCell} style={{ width: '20%' }}>{row.uploadByName}</TableCell>
                                            </TableRow>
                                        </CPToolTip>
                                        : null)
                            })
                            :
                            readOnly === true ?
                                <TableRow className={isPrint ? `${classes.tabletr} ${printClasses.noRecordsForPrint}` : `${classes.tabletr} ${classes.noRecords}`}>
                                    <TableCell colSpan={headers.length} className={classes.noRecordscell} >{intl.formatMessage({ id: 'noRecordsFound' })}</TableCell>
                                </TableRow>
                                :
                                null
                        }
                    </TableBody>
                </Table >
            </div >
        </>
    )
}

export default injectIntl(withLoading(withStyles(style)(DocumentTable)))
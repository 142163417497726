import React from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Component } from 'react';
import { withStyles, Fab, IconButton, Link, Typography } from '@material-ui/core';
import '../../styles/bar.css'
import CPCSVDownloader from '../common/CPCSVDownloader'
import { getContrastColor, voidHandler } from '../../common/utils';
import { fetchBookings } from '../../sevices/TMFF/Services'
import withLoading from '../common/withLoading';
import { injectIntl } from "react-intl";
import CardsTable from '../mobileComponents/CardsTable';
import ScrollTop from '../mobileComponents/ScrollTop';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import greyStopIcon from '../../images/greyStopIcon.png';
import yellowStopIcon from '../../images/yellowStopIcon.png';
import redStopIcon from '../../images/redStopIcon.png';
import { BookingstatusMap } from '../../common/DropDownValues';
import HelpIcon from '@material-ui/icons/Help';
import CpBreadcrumb from '../common/CpBreadcrumb'

const style = ({ breakpoints, palette }) => ({
    containerDiv: {
        background: 'white',
        padding: '0 2%',
        overflow: 'auto'
    },
    heading: {
        background: 'white',
        height: '4rem',
        padding: '0.75%',
        display: 'flex'
    },
    underline: {
        '&:hover': {
            textDecoration: "underline !important",
            color: '#277AB5'
        }
    },
    gridText: {
        fontWeight: '700',
        fontStyle: 'normal',
        marginTop: '2%',
        marginBottom: '2%',
        height: '4rem',
        display: 'flex'
    },
    buttonWarpper: {
        padding: '1% 0',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#007ad9'
    },
    recordCount: {
        marginLeft: '10px',
        fontSize: '14px',
        fontWeight: 400,
        fontStyle: 'normal',
        color: '#545F66'
    },
    tableWrapper: {
        border: '1px solid #E8F0F4 !important'
    },
    sectionDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        justifyContent: 'center',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    textColor: {
        color: '#4A4E50'
    },
    fabIcon: {
        backgroundColor: palette.primary.main,
        color: getContrastColor(palette.primary.main)
    },
    detailText: {
        paddingLeft: '0.75%',
        fontSize: '24px'
    },
    recordsText: {
        flexGrow: '1',
        paddingLeft: '0.75%',
        paddingTop: '0.65%',
        fontSize: '14px'
    }
})
const downloadHeaders = [
    { label: "Shipper", key: "shipper" },
    { label: "MOT", key: "bizType" },
    { label: "Origin", key: "location" },
    { label: "Total Peices", key: "bkPcs" },
    { label: " ", key: "bkPcsUt" },
    { label: "Total Weight", key: "bkgWgt" },
    { label: " ", key: "bkgWgtUt" },
    { label: "Destination", key: "destination" },
    { label: "Booking No.", key: "bookingNo" },
    { label: "Status", key: "status" },
    { label: "Ref No.", key: "refNo" },
    { label: "Last Updated", key: "lastUpdated" }
]
const mobileHeaders = [
    { label: "Shipper", key: "shipper" },
    { label: "MOT", key: "bizType" },
    { label: "Origin", key: "location" },
    { label: "Total Peices", key: "bkPcs" },
    { label: "Total Weight", key: "bkgWgt" },
    { label: "Destination", key: "destination" },
    { label: "Booking No.", key: "bookingNo" },
    { label: "Status", key: "status" },
    { label: "Ref No.", key: "refNo" },
    { label: "Last Updated", key: "lastUpdated" }
]
class Bookings extends Component {
    constructor(props) {
        super(props);
        const { intl } = props;
        this.state = {
            selectedRow: null,
            heading: '',
            fromDashBoard: false,
            columnDefs: [{ headerName: intl.formatMessage({ id: 'search_shipments_search_shipper' }), field: "shipper", floatingFilter: true, minWidth: 150, suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "shipper" },
            { headerName: intl.formatMessage({ id: 'shipments_bizType' }), field: "bizType", floatingFilter: true, maxWidth: 120, suppressMenu: true },
            { headerName: intl.formatMessage({ id: 'origin' }), field: "location", floatingFilter: true, suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "location" },
            {
                headerName: intl.formatMessage({ id: 'total_pc' }), sortable: false, floatingFilter: false, maxWidth: 120, suppressMenu: true, tooltipValueGetter: (params) => (params.data.bkPcs !== undefined ? params.data.bkPcs + " " + params.data.bkPcsUt : ""), valueGetter: function (params) {
                    return (params.data.bkPcs !== undefined ? params.data.bkPcs + " " + params.data.bkPcsUt : "");
                },
            },
            {
                headerName: intl.formatMessage({ id: 'total_wt' }), sortable: false, field: "bkgWgt", floatingFilter: false, maxWidth: 120, suppressMenu: true, tooltipValueGetter: (params) => (params.data.bkgWgt !== undefined ? params.data.bkgWgt + " " + params.data.bkgWgtUt : ""), valueGetter: function (params) {
                    return (params.data.bkgWgt !== undefined ? params.data.bkgWgt + " " + params.data.bkgWgtUt : "");
                },
            },
            { headerName: intl.formatMessage({ id: 'destination' }), field: "destination", floatingFilter: true, suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "destination" },
            { headerName: intl.formatMessage({ id: 'booking_no' }), field: "bookingNo", floatingFilter: true, minWidth: 120, suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "bookingNo" },
            { headerName: intl.formatMessage({ id: 'status' }), maxWidth: 150, field: "status", floatingFilter: true, suppressMenu: true, cellRenderer: this.StatusRender, tooltipValueGetter: (params) => params.value, tooltipField: "status" },
            { headerName: intl.formatMessage({ id: 'refNo' }), field: "refNo", floatingFilter: true, maxWidth: 150, suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "refNo" },
            { headerName: intl.formatMessage({ id: 'last_updated' }), maxWidth: 170, field: "lastUpdated", floatingFilter: false, suppressMenu: true, tooltipValueGetter: (params) => params.value, tooltipField: "lastUpdated" }
            ],
            gridOptions: {
                components: {
                    statusRenderer: this.StatusRender
                }
            },
            mobileData: null,
            rowData: null,
            defaultColDef: {
                flex: 1,
                sortable: true,
                filter: true,
                floatingFilter: true,

            },
            icons: {
                filter: '<i class="fa ArrowDropDownIcon"/>'
            },
            nextPageIndex: 0,
            priorDays: 60,
            filteredData: []
        }
    }



    onGridReady = params => {
        this.gridApi = params.api;
    };
    selectedRow = async () => {
        const { handleRowClick } = this.props;
        let row = this.gridApi.getSelectedRows();
        if (row) {
            this.setState({
                selectedRow: row
            })
            handleRowClick(this.gridApi.getSelectedRows(), 'bookings', this.state.fromDashBoard);
        }
        else {
            handleRowClick(this.state.selectedRow, 'bookings', this.state.fromDashBoard);
        }
    }
    filterchange = async (event) => {
        const filteredData = event.api.rowModel.rowsToDisplay.map(row => {
            return row.data
        })
        this.setState({
            filteredData: filteredData
        })
    }
    onCardClick = async (id) => {
        const { handleRowClick } = this.props;
        handleRowClick([this.state.mobileData[id]], 'bookings', this.state.fromDashBoard);
    }
    processBookingData = (data) => {
        const updatedData = data ?
            data.map(row => {
                return {
                    ...row,
                    totalPcs: row.hasOwnProperty("bkPcs") && row.hasOwnProperty("bkPcsUt") ? (row.bkPcs + ' ' + row.bkPcsUt) : row.hasOwnProperty("bkPcs") && row.bkPcsUt === undefined ? row.bkPcs : '',
                    totalWgt: row.hasOwnProperty("bkgWgt") && row.hasOwnProperty("bkgWgtUt") ? (row.bkgWgt + ' ' + row.bkgWgtUt) : row.hasOwnProperty("bkgWgt") && row.bkgWgtUt === undefined ? row.bkgWgt : ''
                }
            }) :
            []

        return updatedData
    }
    componentDidMount = async () => {
        const { location } = this.props
        if (location.state) {
            let status = "";
            BookingstatusMap.forEach(el => {
                el.label === location.state.heading.status ? status = el.value : voidHandler()
            })
            const fetchedBookings = await fetchBookings('', status);
            sessionStorage.setItem("fromDashBoard", JSON.stringify(location.state.fromDashBoard))
            sessionStorage.setItem("bookingsHeading", JSON.stringify(location.state.heading))
            await this.setState({
                rowData: fetchedBookings,
                filteredData: fetchedBookings,
                heading: location.state.heading,
                mobileData: this.processBookingData(fetchedBookings),
                fromDashBoard: location.state.fromDashBoard
            })
        }
        else if (sessionStorage.getItem("bookingsHeading")) {
            const heading = JSON.parse(sessionStorage.getItem("bookingsHeading"))
            let status = "";
            BookingstatusMap.forEach(el => {
                el.label === heading.status ? status = el.value : voidHandler()
            })
            const fetchedBookings = await fetchBookings('', status);
            await this.setState({
                rowData: fetchedBookings,
                filteredData: fetchedBookings,
                heading: JSON.parse(sessionStorage.getItem("bookingsHeading")),
                mobileData: this.processBookingData(fetchedBookings),
                fromDashBoard: JSON.parse(sessionStorage.getItem("fromDashBoard"))
            })
        }
        else {
            const fetchedBookings = await fetchBookings();
            await this.setState({
                rowData: fetchedBookings,
                filteredData: fetchedBookings,
                mobileData: this.processBookingData(fetchedBookings)
            })
        }
    }
    handleClickMore = async () => {
        const { priorDays } = this.state
        const { loading } = this.props
        loading.setLoadingmsg(true)
        const fetchedBookings = await fetchBookings(priorDays);
        await this.setState({
            rowData: fetchedBookings,
            filteredData: fetchedBookings,
            mobileData: this.processBookingData(fetchedBookings),
            priorDays: priorDays + 30
        })
        loading.setLoadingmsg(false)
    }
    StatusRender = (params) => {
        let element = document.createElement("span");
        let imageElement = ''
        if (params.value === 'In Progress') {
            imageElement = '<img width="16px" src="' + yellowStopIcon + '" />';
            element.innerHTML = imageElement + ' ' + params.value
        } else if (params.value === 'Rejected') {
            imageElement = '<img width="16px" src="' + redStopIcon + '" />';
            element.innerHTML = imageElement + ' ' + params.value
        }
        else if (params.value === "Accepted" || params.value === "Submitted") {
            imageElement = '<img width="16px" src="' + greyStopIcon + '" />';
            element.innerHTML = imageElement + ' ' + params.value
        }
        else {
            element.innerHTML = params.value
        }
        return element;
    }

    render() {
        const { classes, backToHome, intl } = this.props
        const { columnDefs, rowData, defaultColDef, rowClassRules, priorDays, heading, mobileData, filteredData } = this.state
        return (
            <div className={classes.containerDiv}>
                <div className={classes.heading}>
                    <CpBreadcrumb>
                        <Link color='inherit' classes={{ underlineHover: classes.underline }} href="#" onClick={() => backToHome('bookings')}>{intl.formatMessage({ id: 'home' })}</Link>
                        <Typography color='textPrimary' >{intl.formatMessage({ id: 'bookings_header' })}</Typography>
                    </CpBreadcrumb>
                    <a href={`${window.location.protocol + '//' + window.location.host}/webHelp/cp-manage-bookings.html`} target="_blank" style={{ color: "inherit", flexGrow: '1' }} rel="noopener noreferrer">
                        <IconButton style={{ float: 'right', padding: '0px' }}
                            aria-label="account of current user"
                            aria-controls="primary-search-account-menu"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <HelpIcon style={{ fontSize: '28px', color: "#6E787D" }} />
                        </IconButton>
                    </a>
                </div>
                <div className={classes.gridText}>
                    <span className={classes.detailText}> {heading !== '' ? `${intl.formatMessage({ id: Object.keys(heading)[0] })} | ${heading[Object.keys(heading)[0]]}` : intl.formatMessage({ id: 'bookings_header' })}</span> <span className={classes.recordsText}>{rowData ? rowData.length : '0'} {intl.formatMessage({ id: 'records' })}</span>
                    {filteredData ? filteredData.length > 0 ? <CPCSVDownloader data={filteredData} headers={downloadHeaders} filename="bookings" style={{ float: 'right' }} /> : null : null}
                </div>
                <div className={classes.tableWrapper}>
                    {/* <div style={{ height: '30px', display: 'flex', alignItems: "center" }}> <span className={classes.recordCount}>{intl.formatMessage({ id: 'shipments_noOfRecords' })} : {rowData ? rowData.length : '0'}</span></div> */}
                    <div className={classes.sectionDesktop}>
                        <div
                            className="ag-theme-balham"
                            style={{ height: '410px', width: '100%', backgroundColor: 'rgba(0, 0, 0,0.04)' }} >
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={rowData}
                                defaultColDef={defaultColDef}
                                rowSelection={'single'}
                                onRowClicked={this.selectedRow}
                                rowClassRules={rowClassRules}
                                onGridReady={this.onGridReady}
                                headerHeight='42'
                                rowHeight='30'
                                floatingFiltersHeight='40'
                                onFilterChanged={this.filterchange}
                                gridOptions={this.state.gridOptions}
                                tooltipShowDelay={0}
                            />
                        </div>
                    </div>
                    <div className={classes.sectionMobile}>
                        <CardsTable data={mobileData} headers={mobileHeaders} onCardClick={this.onCardClick} />
                        <ScrollTop >
                            <Fab className={classes.fabIcon} size="medium" aria-label="scroll back to top">
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </div>
                    <div style={{ height: '30px', display: 'flex', alignItems: "center" }}>
                        {heading === '' ?
                            <span className={`${classes.recordCount} ${classes.textColor}`} style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={this.handleClickMore}>{intl.formatMessage({ id: 'clickToFetch' })} {priorDays} {intl.formatMessage({ id: 'daysData' })}</span>
                            : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(withStyles(style)(withLoading(Bookings)))

import React, { Component } from 'react';
import Bar from './universal/Bar';
import { Route, Switch, withRouter } from 'react-router-dom';
import Bookings from './ManageBooking/Bookings';
import { fetchSystemCodes, fetchCountries, fetchParties, getBookingById, getCities, getQuoteById, fetchFileFilters, getBookingTemplateDataById, getRefCodeFromBizrule } from "../sevices/TMFF/Services"
import TMFFShipments from './SearchShipments/TMFF/TMFFShipments';
import PrivateRoute from './auth/PrivateRoute';
import { processDate } from '../common/utils';
import Loading from './common/Loading';
import withLoading from './common/withLoading';
import withCPContext from './common/withCPContext';
import withTenant from './common/withTenant';
import withError from './common/withError';
import { getSnackBar, validatePasswordComplexity, getProgressBar } from '../common/utils';
import { injectIntl } from 'react-intl';
import Page404 from './ErrorPages/Page404';
import Quotes from './ManageQuotes/Quotes';
import ChangePassword from '../components/ChangePassword'
import { changePassword, logout } from '../sevices/common/CpServices'
import BookingTemplates from './ManageTemplates/BookingTemplates';
import CPIdleWarning from './common/CPIdleWarning';
import TMFFDashboard from './Dashboard/TMFF/TMFFDashboard';
import CMUSDashboard from './Dashboard/CMUS/CMUSDashboard';
import CMUSShipments from './SearchShipments/CMUS/CMUSShipments';
import { withTheme } from '@material-ui/core';
import { fetchCustomers } from "../sevices/CMUS/Services"
import ConfigureWidgets from './Dashboard/TMFF/ConfigureWidgets';

class HomePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showBookingBar: false,
      showQuoteBar: false,
      showBookingTemplateBar: false,
      bookingData: "",
      quoteData: "",
      bookingTemplateData: "",
      destinationCities: [],
      originCities: [],
      podCities: [],
      polCities: [],
      systemSerReqs:[],
      selectedShipmentId: undefined,
      showErrorSnackBar: undefined,
      changePassword: sessionStorage.getItem("forceChangePassword") === "true" ? true : false,
      userRole : sessionStorage.getItem("isCpSupUser") === "true" ? "isCpSupUser" : "CPUser",
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      formErrorDetails: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      confirmPasswordError: '',
      showIdleWarning: false,
      passwordComplexityFlags: null,
      pwdProgressBar: null,
      shipmentFileNo: undefined,
      changePwdErrorExist: false,
      enableWidgetPreferences: false,

    }
    this.warningTimer = null;
    this.timeoutTimer = null;
    this.findIdleTimeAfterLogin();
    this.deriveUserAccessLevel();
  }

  deriveUserAccessLevel() {
    const { cpContext } = this.props
    if (sessionStorage.getItem('quotesAccess') && sessionStorage.getItem('quotesAccess') !== '') {
      cpContext.accessToQuotes = sessionStorage.getItem('quotesAccess') === 'true' ? true : false
    }
    if (sessionStorage.getItem('bookingAccess') && sessionStorage.getItem('bookingAccess') !== '') {
      cpContext.accessToBookings = sessionStorage.getItem('bookingAccess') === 'true' ? true : false
    }
    if (sessionStorage.getItem('tracktraceAccess') && sessionStorage.getItem('tracktraceAccess') !== '') {
      cpContext.accessToShipments = sessionStorage.getItem('tracktraceAccess') === 'true' ? true : false
    }
    if (sessionStorage.getItem('templatesAccess') && sessionStorage.getItem('templatesAccess') !== '') {
      cpContext.accessToBookingTemplates = sessionStorage.getItem('templatesAccess') === 'true' ? true : false
    }
    if (sessionStorage.getItem('inlandAccess') && sessionStorage.getItem('inlandAccess') !== '') {
      cpContext.accessToILWidget = sessionStorage.getItem('inlandAccess') === 'true' ? true : false
    }
  }
  findIdleTimeAfterLogin = () => {
    window.addEventListener("click", this.resetTimers);
    this.warningTimer = setTimeout(this.idleWarning, 1000 * 60 * 25);
    this.timeoutTimer = setTimeout(this.performApplicationLogout, 1000 * 60 * 30);
  }
  idleWarning = () => {
    this.setState({ showIdleWarning: true });
    window.removeEventListener("click", this.resetTimers);
  }
  resetTimers = () => {
    clearTimeout(this.warningTimer);
    clearTimeout(this.timeoutTimer);
    if (sessionStorage.getItem('Auth-Token') && sessionStorage.getItem('Auth-Token') !== '' && sessionStorage.getItem('Auth-Token') !== null) {
      this.findIdleTimeAfterLogin();
    }
  }
  performApplicationLogout = () => {
    const { tenantDetails } = this.props
    this.setState({ showIdleWarning: false })
    clearTimeout(this.warningTimer);
    clearTimeout(this.timeoutTimer);
    if (sessionStorage.getItem('Auth-Token') !== '' && sessionStorage.getItem('Auth-Token') !== null) {
      logout(this.props.history, undefined, undefined, tenantDetails.baseSystem)
    }
  }
  handleTokenReset = async () => {
    const { tenantDetails } = this.props
    this.setState({ showIdleWarning: false })
    if (tenantDetails.baseSystem === 'TMFF') {
      const res = await fetchFileFilters();
      if (res && res !== null && res.totalRecords && res.totalRecords >= 1) {
        this.resetTimers()
      }
    } else if (tenantDetails.baseSystem === 'CMUS') {
      const resp = await fetchCustomers("PORTAL");
      if (resp && resp !== null) {
        this.resetTimers();
      }
    }
  }
  componentWillUnmount = () => {
    clearTimeout(this.warningTimer);
    clearTimeout(this.timeoutTimer);
    window.removeEventListener("click", this.resetTimers);
  }

  showChangePassword = () => {
    this.setState({
      changePassword: true
    })
  }
  backToHome = () => {
    const { CpError } = this.props
    if (CpError.trackingError !== '') {
      CpError.setTrackingError('')
    }
    this.props.history.push("/")
  }
  processResponseData = async (data) => {

    const validFromTo = (data.validFrom ? processDate(data.validFrom, "dateformat") : '') + ' - ' + (data.validTo ? processDate(data.validTo, "dateformat") : '')
    const updatedDocuments = data.documents ?
      data.documents.map(document => {
        return {
          ...document,
          uploadDate: processDate(document.uploadDate, 'dateTime'),
          action: "existing",
          selected: false
        }
      })
      :
      []
    let updatedData = {
      ...data,
      documents: updatedDocuments,
      validFromTo: validFromTo
    }
    return updatedData
  }
  handleOnClick = async () => {
    const { history, intl, tenantDetails } = this.props
    const { currentPassword, newPassword, confirmPassword } = this.state
    if (currentPassword === '' || newPassword === '' || confirmPassword === '') {
      if (currentPassword === '') {
        await this.setState({
          formErrorDetails: {
            ...this.state.formErrorDetails,
            currentPassword: intl.formatMessage({ id: 'requiredErrormsg' })
          }
        })
      }
      if (newPassword === '') {
        await this.setState({
          formErrorDetails: {
            ...this.state.formErrorDetails,
            newPassword: intl.formatMessage({ id: 'requiredErrormsg' })
          }
        })
      }
      if (confirmPassword === '') {
        await this.setState({
          formErrorDetails: {
            ...this.state.formErrorDetails,
            confirmPassword: intl.formatMessage({ id: 'requiredErrormsg' })
          }
        })
      }
      await this.setState({
        confirmPasswordError: ''
      })
    }
    else {
      await this.setState({
        confirmPasswordError: ''
      })
      if (newPassword !== confirmPassword) {
        this.setState({
          formErrorDetails: {
            ...this.state.formErrorDetails,
            confirmPassword: intl.formatMessage({ id: 'passwordMismatch' })
          },
        })
      }
      else {
        let isPassowrdComplextityMet = false;
        const { passwordComplexityFlags } = this.state
        if (passwordComplexityFlags !== null) {
          if (passwordComplexityFlags.ucaseFlag && passwordComplexityFlags.lcaseFlag && passwordComplexityFlags.digitsFlag &&
            passwordComplexityFlags.splCharsFlag && passwordComplexityFlags.lengthFlag) {
            isPassowrdComplextityMet = true;
          }
        }
        if (isPassowrdComplextityMet) {
          const res = await changePassword(currentPassword, newPassword)
          if (res.status === 200) {
            this.setState({
              changePassword: false,
              formErrorDetails: {
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
              },
              passwordComplexityFlags: null,
              pwdProgressBar: null
            })
            logout(history, "changePassword", intl.formatMessage({ id: 'passwordChangedmsg' }), tenantDetails.baseSystem)
          }
          else if (res.data && res.data.fieldErrors && res.data.fieldErrors[0] === "Old Password error.") {
            if (sessionStorage.getItem("forceChangePassword") === "true") {
              this.setState({ changePwdErrorExist: true })
            }
            this.setState({
              currentPassword: '',
              newPassword: '',
              confirmPassword: '',
              formErrorDetails: {
                currentPassword: intl.formatMessage({ id: 'wrongPassword' }),
                newPassword: '',
                confirmPassword: ''
              },
              passwordComplexityFlags: null,
              pwdProgressBar: null
            })
          }
          else {
            if (sessionStorage.getItem("forceChangePassword") === "true") {
              this.setState({ changePwdErrorExist: true })
            }
            const msg = await (res.data && res.data.fieldErrors && res.data.fieldErrors[0]) ? res.data.fieldErrors[0] : intl.formatMessage({ id: 'somethingWentWrong' })
            this.setState({
              currentPassword: '',
              newPassword: '',
              confirmPassword: '',
              formErrorDetails: {
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
              },
              passwordComplexityFlags: null,
              pwdProgressBar: null,
              confirmPasswordError: msg
            })
          }
        } else {
          this.setState({
            formErrorDetails: {
              ...this.state.formErrorDetails,
              confirmPassword: intl.formatMessage({ id: 'passwordCriteriaNotMet' })
            },
          })
        }
      }
    }
  }

  handleEnableWidgetPreferences = async () => {
    this.props.history.push('/customWidgets')
  }

  onChange = (event) => {
    const value = event.target.value
    switch (event.target.name) {
      case "currentPassword":
        this.setState({
          currentPassword: value,
          formErrorDetails: {
            ...this.state.formErrorDetails,
            currentPassword: ''
          },
          confirmPasswordError: ''
        })
        break;
      case "newPassword":
        const validationResultFlags = validatePasswordComplexity(value)

        this.setState({
          newPassword: value,
          pwdProgressBar: getProgressBar(validationResultFlags.pwdStrength, '40%', 20, [10, 20, 27, 37]),
          passwordComplexityFlags: validationResultFlags,
          formErrorDetails: {
            ...this.state.formErrorDetails,
            newPassword: ''
          },
          confirmPasswordError: ''
        })
        break;
      case "confirmPassword":
        this.setState({
          confirmPassword: value,
          formErrorDetails: {
            ...this.state.formErrorDetails,
            confirmPassword: ''
          },
          confirmPasswordError: ''
        })
        break;
      default:
        break;
    }
  }

  onDialogClose = () => {
    const { history, tenantDetails } = this.props
    if (this.state.changePwdErrorExist) {
      logout(history, "changePassword", '', tenantDetails.baseSystem)
    }
    this.setState({
      confirmPasswordError: '',
      changePassword: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      formErrorDetails: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      passwordComplexityFlags: null,
      pwdProgressBar: null
    })
  }

  handleRowClick = async (row, from, fromDashBoard) => {
    const { loading, CpError } = this.props
    if (CpError.trackingError !== '') {
      CpError.setTrackingError('')
    }
    if (from === "bookings") {
      loading.setLoadingmsg(true)
      await this.setState({
        showBookingBar: false,
        showErrorSnackBar: false,
        showQuoteBar: false,
        showBookingTemplateBar: false
      })
      const bookingData = await getBookingById(row[0].unId, this.state.userRole) ;
      if (!bookingData || bookingData === null) {
        this.props.history.push("/reload");
        this.props.history.push("/bookings");
        this.setState({
          showErrorSnackBar: true
        })
      }
      let promise = []
      if (bookingData) {
        const isRoad = bookingData.bizType === 'IL' ? true : false
        const isSea = (bookingData.bizType === 'SE' || bookingData.bizType === 'SI') ? true : false
        const isAir = (bookingData.bizType === 'AE' || bookingData.bizType === 'AI') ? true : false
        const fetchDestinations = bookingData.destCountry ? getCities(bookingData.destCountry, isRoad) : [];
        const fetchOrigins = bookingData.orgCountry ? getCities(bookingData.orgCountry, isRoad) : [];
        const fetchPOL = bookingData.polCtry ? getCities(bookingData.polCtry, isRoad, isSea, isAir) : [];
        const fetchPOD = bookingData.podCtry ? getCities(bookingData.podCtry, isRoad, isSea, isAir) : [];
        promise = await Promise.all([fetchDestinations, fetchOrigins, fetchPOL, fetchPOD]);
        await this.setState({
          destinationCities: promise[0],
          originCities: promise[1],
          polCities: promise[2],
          podCities: promise[3],
          showBookingBar: true,
          bookingData: await this.processResponseData(bookingData),
          fromDashBoard: fromDashBoard
        })
      }
      loading.setLoadingmsg(false)
    }
    else if (from === "shipments") {
      await this.setState({
        selectedShipmentId: undefined
      })
      await this.setState({
        selectedShipmentId: row[0].gShpId,
        showBookingBar: false,
        showQuoteBar: false,
        showBookingTemplateBar: false
      })
    }
    else if (from === "quotes") {
      loading.setLoadingmsg(true)
      await this.setState({
        showBookingBar: false,
        showQuoteBar: false,
        showErrorSnackBar: false,
        showBookingTemplateBar: false
      })
      const quoteData = await getQuoteById(row[0].unId, row[0].bizType);
      if (!quoteData || quoteData === null) {
        this.props.history.push("/reload");
        this.props.history.push("/quotes");
        this.setState({
          showErrorSnackBar: true
        })
      }
      let promise = []
      if (quoteData) {
        const isRoad = quoteData.bizType === 'IL' ? true : false
        const isSea = (quoteData.bizType === 'SE' || quoteData.bizType === 'SI') ? true : false
        const isAir = (quoteData.bizType === 'AE' || quoteData.bizType === 'AI') ? true : false
        const fetchDestinations = quoteData.destCtry ? getCities(quoteData.destCtry, isRoad) : [];
        const fetchOrigins = quoteData.porCtry ? getCities(quoteData.porCtry, isRoad) : [];
        const fetchPOL = quoteData.polCtry ? getCities(quoteData.polCtry, isRoad, isSea, isAir) : [];
        const fetchPOD = quoteData.podCtry ? getCities(quoteData.podCtry, isRoad, isSea, isAir) : [];
        promise = await Promise.all([fetchDestinations, fetchOrigins, fetchPOL, fetchPOD]);
        await this.setState({
          destinationCities: promise[0],
          originCities: promise[1],
          polCities: promise[2],
          podCities: promise[3],
          showQuoteBar: true,
          quoteData: await this.processResponseData(quoteData),
          fromDashBoard: fromDashBoard
        })
      }
      loading.setLoadingmsg(false)
    } else if (from === "shipmentsCMUS") {
      await this.setState({ shipmentFileNo: undefined })
      this.setState({
        shipmentFileNo: row[0].fileNo,
        showBookingBar: false,
        showQuoteBar: false,
        showBookingTemplateBar: false
      })
    }
    else if ("templates") {
      loading.setLoadingmsg(true)
      await this.setState({
        showBookingBar: false,
        showErrorSnackBar: false,
        showQuoteBar: false,
        showBookingTemplateBar: false
      })
      const bookingTemplateData = await getBookingTemplateDataById(row[0].unId);
      if (!bookingTemplateData || bookingTemplateData === null) {
        this.props.history.push("/reload");
        this.props.history.push("/templates");
        this.setState({
          showErrorSnackBar: true
        })
      }
      let promise = []
      if (bookingTemplateData) {
        const isRoad = bookingTemplateData.bizType === 'IL' ? true : false
        const isSea = (bookingTemplateData.bizType === 'SE' || bookingTemplateData.bizType === 'SI') ? true : false
        const isAir = (bookingTemplateData.bizType === 'AE' || bookingTemplateData.bizType === 'AI') ? true : false
        const fetchDestinations = bookingTemplateData.destCountry ? getCities(bookingTemplateData.destCountry, isRoad) : [];
        const fetchOrigins = bookingTemplateData.orgCountry ? getCities(bookingTemplateData.orgCountry, isRoad) : [];
        const fetchPOL = bookingTemplateData.polCtry ? getCities(bookingTemplateData.polCtry, isRoad, isSea, isAir) : [];
        const fetchPOD = bookingTemplateData.podCtry ? getCities(bookingTemplateData.podCtry, isRoad, isSea, isAir) : [];
        promise = await Promise.all([fetchDestinations, fetchOrigins, fetchPOL, fetchPOD]);
        await this.setState({
          destinationCities: promise[0],
          originCities: promise[1],
          polCities: promise[2],
          podCities: promise[3],
          showBookingTemplateBar: true,
          bookingTemplateData: await this.processResponseData(bookingTemplateData),
          fromDashBoard: fromDashBoard
        })
      }
      loading.setLoadingmsg(false)
    }
  }
  handleNewTemplate = async () => {
    await this.setState({
      showBookingTemplateBar: false,
      showBookingBar: false,
      showQuoteBar: false,
      bookingTemplateData: ""
    })
    this.setState({ showBookingTemplateBar: true })
  }
  componentDidMount = async () => {
    const { cpContext, tenantDetails } = this.props
    const userDefaultsData =JSON.parse(sessionStorage.getItem("userDefaults")) ?? {};
    const {airDefaults,seaDefaults} =userDefaultsData 

    if (tenantDetails.baseSystem === 'TMFF') {
      const countries = await fetchCountries();
      cpContext.countries = countries ? countries.lookupItems ? countries.lookupItems : [] : [];
      cpContext.airOriginCityList =  airDefaults && airDefaults.airOriginCtry && airDefaults.airOriginCity ? await getCities(airDefaults.airOriginCtry,false,false,true) :[];
      cpContext.seaPolCityList = seaDefaults && seaDefaults.seaPolCtry && seaDefaults.seaPolCity ? await getCities(seaDefaults.seaPolCtry,false,true,false): [];

      const systemcodesPromises = [
        fetchSystemCodes("WT"),
        fetchSystemCodes("UT"),
        fetchSystemCodes("VT"),
        fetchSystemCodes("CARGODIMUT"),
        fetchSystemCodes("MOVETYPEDESC"),
        fetchSystemCodes("CO"),
        getRefCodeFromBizrule(),
      ];
      const serviceRequestsPromises = [
        fetchSystemCodes("AESRVREQ"),
        fetchSystemCodes("AISRVREQ"),
        fetchSystemCodes("SESRVREQ"),
        fetchSystemCodes("SISRVREQ"),
        fetchSystemCodes("RFSRVREQ"),
      ];
      const [systemCodes, serviceRequests] = await Promise.all([
        Promise.allSettled(systemcodesPromises),
        Promise.allSettled(serviceRequestsPromises),
      ]);

      const [ weightUOM,piecesUOM,volumeUOM,dimensionsUOM,
        moveTypes,commodityUOM,bizType,
      ] = systemCodes.map((code) =>
        code.status === "fulfilled" ? code.value?.lookupItems : []
      );
      const [AE, AI, SE, SI, IL] = serviceRequests.map((request) =>
        request.status === "fulfilled" ? request.value?.lookupItems : []
      );

      cpContext.weightUOM = weightUOM ?? [];
      cpContext.piecesUOM = piecesUOM ?? [];
      cpContext.volumeUOM = volumeUOM ?? [];
      cpContext.dimensionsUOM = dimensionsUOM ?? [];
      cpContext.moveTypes = moveTypes ?? [];
      cpContext.commodityUOM = commodityUOM ?? [];
      const bizrules = bizType ?? [];
      cpContext.serviceRequests = {
        AE: AE ?? [],
        AI: AI ?? [],
        SE: SE ?? [],
        SI: SI ?? [],
        IL: IL ?? [],
      };

      if (bizrules && bizrules.length > 0) {
        let values = bizrules[0].value ? bizrules[0].value.split('|') : [];
        cpContext.referenceCode = (values && values.length > 0) ? values[0] : ''
      }
      const parties = await Promise.all(
        [
          fetchParties("shipper"),
          fetchParties("consignee"),
          fetchParties("notify")
        ]);
      cpContext.shippers = parties ? parties[0] ? parties[0].lookupItems : [] : []
      cpContext.consignees = parties ? parties[1] ? parties[1].lookupItems : [] : []
      cpContext.notifies = parties ? parties[2] ? parties[2].lookupItems :[] :[]
      cpContext.airDefaults = userDefaultsData.airDefaults ?? {};
      cpContext.seaDefaults = userDefaultsData.seaDefaults ?? {};
      cpContext.roadDefaults = userDefaultsData.roadDefaults ?? {};
    }
  }
  render() {
    const { loading, tenantDetails, intl, cpContext } = this.props
    const { currentPassword, changePassword, newPassword, confirmPassword, showErrorSnackBar, formErrorDetails, showIdleWarning, passwordComplexityFlags, pwdProgressBar, confirmPasswordError, changePwdErrorExist, enableWidgetPreferences } = this.state
    return (
      <>
        <Loading open={loading.loadingmsg} />
        <div>
          {
            showErrorSnackBar && showErrorSnackBar === true ?
              getSnackBar(intl.formatMessage({ id: 'somethingWentWrong' }), true, 'info') : null
          }
          {showIdleWarning ?
            <CPIdleWarning show={showIdleWarning} message={intl.formatMessage({ id: "sessionLogoutMsg" })} handleRejected={this.performApplicationLogout} handleAccepted={this.handleTokenReset} />
            : null}
          <Bar {...this.state}
            backToHome={this.backToHome} showChangePassword={this.showChangePassword}
            changePassword={changePassword} handleEnableWidgetPreferences={this.handleEnableWidgetPreferences} />
          {changePassword ?
            <ChangePassword handleOnClick={this.handleOnClick}
              currentPassword={currentPassword} newPassword={newPassword}
              confirmPassword={confirmPassword} formErrorDetails={formErrorDetails}
              onChange={this.onChange} onDialogClose={this.onDialogClose} error={confirmPasswordError}
              passwordComplexityFlags={passwordComplexityFlags}
              pwdProgressBar={pwdProgressBar} changePwdErrorExist={changePwdErrorExist} />
            :
            <Switch>
              <PrivateRoute value="/" strict exact path="/" enableRoute={true}>
                <div style={{ overflow: 'auto', height: '82vh' }}>
                  {tenantDetails.baseSystem === "CMUS" ? <CMUSDashboard />
                    : tenantDetails.baseSystem === "TMFF" ? < TMFFDashboard enableWidgetPreferences={enableWidgetPreferences} /> : null}
                </div>
              </PrivateRoute>
              {tenantDetails.baseSystem === "TMFF" ?
                <>
                  <PrivateRoute value="/bookings" path="/bookings" enableRoute={tenantDetails.accessLevels.accessToBookings && cpContext.accessToBookings}>
                    <Bookings {...this.props} backToHome={this.backToHome} handleRowClick={this.handleRowClick} />
                  </PrivateRoute>
                  <PrivateRoute value="/quotes" path="/quotes" enableRoute={tenantDetails.accessLevels.accessToQuotes && cpContext.accessToQuotes}>
                    <Quotes {...this.props} backToHome={this.backToHome} handleRowClick={this.handleRowClick} />
                  </PrivateRoute>
                  <PrivateRoute value="/shipments" path="/shipments" enableRoute={tenantDetails.accessLevels.accessToShipments && cpContext.accessToShipments}>
                    <TMFFShipments {...this.props} backToHome={this.backToHome} handleRowClick={this.handleRowClick} />
                  </PrivateRoute>
                  <PrivateRoute value="/templates" path="/templates" enableRoute={tenantDetails.accessLevels.accessToBookingTemplates && cpContext.accessToBookingTemplates}>
                    <BookingTemplates {...this.props} handleNewTemplate={this.handleNewTemplate} backToHome={this.backToHome} handleRowClick={this.handleRowClick} />
                  </PrivateRoute>
                  <PrivateRoute value="/customWidgets" path="/customWidgets" enableRoute={true}>
                    <ConfigureWidgets backToHome={this.backToHome} />
                  </PrivateRoute>

                </>
                :
                tenantDetails.baseSystem === "CMUS" ?
                  <PrivateRoute value="/shipments" path="/shipments" enableRoute={tenantDetails.accessLevels.accessToShipments}>
                    <CMUSShipments {...this.props} backToHome={this.backToHome} handleRowClick={this.handleRowClick} />
                  </PrivateRoute>
                  : null
              }
              <Route component={Page404} />
            </Switch>
          }
        </div >
      </>
    );
  }
}

export default injectIntl(withRouter(withTenant(withError(withCPContext(withLoading(withTheme(HomePage)))))));
import React from 'react'
import { CSVLink } from "react-csv";
import { withStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
const styles = ({ palette }) => ({
    button: props => ({
        //color: props.showIcon ? props.color : 'white',
        height: '32px',
        padding: '0.2rem 1.75rem',
        minWidth: '65px',
        fontWeight: '500',
        borderRadius: '4px',
        textAlign: 'center',
        fontSize: '14px',
        outlineStyle: 'none',
        margin: '5px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
        transitionDuration: '100ms',
        transitionProperty: 'background-color, background-image, box-shadow, border-color, color',
        color: palette.primary.main,
        border: '2px solid',
        borderColor: palette.primary.main,
        backgroundColor: '#ffffff',
        backgroundImage: 'initial',
        fontFamily: 'Open sans',
        '&:hover': {
            color: '#123E4A',
            backgroundColor: '#ffffff'
        },
        '&:active': {
            color: '#1A5178',
            border: '2px solid #2C697A',
            borderImage: 'initial',
            backgroundColor: "#D5E5EA",
        },
        '&:focus': {
            backgroundColor: '#E3ECEF',
            borderColor: '#23E4A',
            outlineStyle: 'none',
            textDecoration: 'none'
        },
        '&:disabled': {
            color: '#838D90',
            backgroundColor: '#C8CFD2'
        }
    })
});

function CPCSVDownloader(props) {
    const { data, filename, headers, classes, style, showIcon } = props
    return (
        <CSVLink data={data ? data : []} headers={headers}
            filename={`${filename}.csv`} style={style} className={classes.button}>{showIcon ? <GetAppIcon /> : 'Export'}</CSVLink>
    )
}

export default withStyles(styles)(CPCSVDownloader)

import React from 'react'
import CpEditableTable from '../common/CpEditableTable'
import withCPContext from '../common/withCPContext'
import { injectIntl } from "react-intl";
import { withStyles, } from '@material-ui/core';
import CPEditableCardTable from '../mobileComponents/CPEditableCardTable'
const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    root: {
        minWidth: 275,
        width: '98%',
        margin: '1%'
    },
    cardRoot: {
        padding: '0 0 0 0 !important',
        marginBottom: '10px !important'
    },
    line: {
        margin: 0,
        display: 'flex'
    },
    titleNormal: {
        fontWeight: 'Normal',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important'
    },
    title: {
        fontWeight: 'Bold',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important'
    },
    value: {
        width: '60%',
        display: 'inline-block',
        fontSize: '12px !important'
    },
    odd: {
        backgroundColor: 'rgba(237, 239, 239, 1)'
    },
    even: {
        backgroundColor: 'white'
    }
})
function ItemsTable(props) {
    const { data, addfunc, removefunc, onChangefields, cpContext, intl, readOnly, classes, selectedItem, onSaveContainersorItems, onEditContainersorItems, onCancelContainersorItems, isPrint, printClasses } = props
    const itemsheaders = [
        { 'width': '16%', 'title': intl.formatMessage({ id: 'goodsDescription' }), 'field': 'description', 'showLabel': true, 'type': 'input', 'inputType': 'text' },
        { 'width': '5%', 'title': intl.formatMessage({ id: 'pcs' }), 'field': 'itemPcs', 'showLabel': true, 'type': 'input', 'inputType': 'number', 'showBorder': false },
        { 'width': '5%', 'title': 'UOM', 'field': 'itemPcsUt', 'showLabel': false, 'type': 'typeahead', 'options': cpContext.piecesUOM },
        { 'width': '5%', 'title': intl.formatMessage({ id: 'weight' }), 'field': 'itemWgt', 'showLabel': true, 'type': 'input', 'inputType': 'number', 'showBorder': false },
        { 'width': '5%', 'title': 'Weightmesure', 'field': 'itemWgtUt', 'showLabel': false, 'type': 'typeahead', 'showBorder': true, 'options': cpContext.weightUOM },
        { 'width': '5%', 'title': intl.formatMessage({ id: 'dimensions' }), 'field': 'itemDimLength', 'showLabel': true, 'type': 'input', 'inputType': 'number', 'showBorder': false, 'fieldlabel': 'L' },
        { 'width': '5%', 'title': 'Dimensionswidth', 'field': 'itemDimWidth', 'showLabel': false, 'type': 'input', 'inputType': 'number', 'showBorder': false, 'fieldlabel': 'W' },
        { 'width': '5%', 'title': 'Dimensionsheight', 'field': 'itemDimHeight', 'showLabel': false, 'type': 'input', 'inputType': 'number', 'showBorder': false, 'fieldlabel': 'H' },
        { 'width': '5%', 'title': 'Dimensionsmesure', 'field': 'itemDimUnit', 'showLabel': false, 'type': 'typeahead', 'showBorder': true, 'options': cpContext.dimensionsUOM },
        { 'width': '5%', 'title': intl.formatMessage({ id: 'volume' }), 'field': 'itemVol', 'showLabel': true, 'type': 'input', 'inputType': 'number', 'showBorder': false },
        { 'width': '5%', 'title': 'Volumemesure', 'field': 'itemVolUt', 'showLabel': false, 'type': 'typeahead', 'showBorder': true, 'options': cpContext.volumeUOM },
    ]

    const shipmentItemheaders = [
        { 'width': '16%', 'title': intl.formatMessage({ id: 'goodsDescription' }), 'field': 'description', 'showLabel': true, 'type': 'label', tooltipField: 'description' },
        { 'width': '5%', 'title': intl.formatMessage({ id: 'pcs' }), 'field': 'itemPcs', 'showLabel': true, 'type': 'label', 'showBorder': false },
        { 'width': '5%', 'title': 'UOM', 'field': 'itemPcsUt', 'showLabel': false, 'type': 'label' },
        { 'width': '5%', 'title': intl.formatMessage({ id: 'weight' }), 'field': 'itemWgt', 'showLabel': true, 'type': 'label', 'showBorder': false },
        { 'width': '5%', 'title': 'Weightmesure', 'field': 'itemWgtUt', 'showLabel': false, 'type': 'label', 'showBorder': true },
        { 'width': '5%', 'title': intl.formatMessage({ id: 'dimensions' }), 'field': 'itemDimLength', 'showLabel': true, 'type': 'label', 'showBorder': false, 'fieldlabel': 'L' },
        { 'width': '5%', 'title': 'Dimensionswidth', 'field': 'itemDimWidth', 'showLabel': false, 'type': 'label', 'showBorder': false, 'fieldlabel': 'W' },
        { 'width': '5%', 'title': 'Dimensionsheight', 'field': 'itemDimHeight', 'showLabel': false, 'type': 'label', 'showBorder': false, 'fieldlabel': 'H' },
        { 'width': '5%', 'title': 'Dimensionsmesure', 'field': 'itemDimUnit', 'showLabel': false, 'type': 'label', 'showBorder': true },
        { 'width': '5%', 'title': intl.formatMessage({ id: 'volume' }), 'field': 'itemVol', 'showLabel': true, 'type': 'label', 'showBorder': false },
        { 'width': '5%', 'title': 'Volumemesure', 'field': 'itemVolUt', 'showLabel': false, 'type': 'label', 'showBorder': true },
    ]
    return (
        <>
            <div className={isPrint ? printClasses.sectionDesktopForPrint : classes.sectionDesktop}>
                <CpEditableTable headers={readOnly === true ? shipmentItemheaders : itemsheaders} readOnly={readOnly} data={data} removefunc={removefunc} addfunc={addfunc} onChangefields={onChangefields} isPrint={isPrint} tableFor='Item' printClasses={printClasses} />
            </div>
            <div className={isPrint ? printClasses.sectionMobileForPrint : classes.sectionMobile}>
                <CPEditableCardTable selected={selectedItem ? selectedItem : null} headers={readOnly === true ? shipmentItemheaders : itemsheaders} readOnly={readOnly} data={data} removefunc={removefunc} addfunc={addfunc} onChangefields={onChangefields} tableFor='ITEM' onSave={onSaveContainersorItems} onEdit={onEditContainersorItems} onCancel={onCancelContainersorItems} />
            </div>
        </>
    )
}

export default injectIntl(withCPContext(withStyles(style)(ItemsTable)))

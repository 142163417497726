import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'


class JsPdfWrapper {
    constructor(options = null) {
        this.OPTIONS = {
            'jsPDF': {
                'orientation': 'p',
                'format': 'a4'
            },
            'html2canvas': {
                'allowTaint': false,
                'backgroundColor': '#ffffff',
                'canvas': null,

            },
        }
        if (options != null) {
            if (options.jsPDF != null) {
                this.OPTIONS.jsPDF = options.jsPDF;
            }
            if (options.html2canvas != null) {
                this.OPTIONS.html2canvas = options.html2canvas;
            }
        }
    }

    DownloadFromHTML(targetElm, fileName = null) {
        const pdf = new jsPDF(this.OPTIONS.jsPDF.orientation, this.OPTIONS.jsPDF.unit, this.OPTIONS.jsPDF.format);
        pdf.html()
        window.scrollTo(0, 0);
        html2canvas(targetElm,
            {
                onclone: function (clonedDoc) {
                    //clonedDoc.getElementById('divToPrint1').style.display = 'block';imageBlockwidgetName export imageId
                    clonedDoc.getElementById('displayDiv').style.visibility = 'visible';

                }
            },
            this.OPTIONS.html2canvas).then((canvas) => {
                var imgWidth = 210;
                var pageHeight = 290;
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var heightLeft = imgHeight;


                var doc = new jsPDF('p', 'mm');
                var position = 0;
                var pageData = canvas.toDataURL('image/jpeg', 1.0);
                var imgData = encodeURIComponent(pageData);
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                doc.setLineWidth(5);
                doc.setDrawColor(255, 255, 255);
                doc.rect(0, 0, 210, 295);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    doc.setLineWidth(5);
                    doc.setDrawColor(255, 255, 255);
                    doc.rect(0, 0, 210, 295);
                    heightLeft -= pageHeight;
                }
                doc.save(fileName);

            });
        window.scrollTo(0, document.body.scrollHeight - 30 || document.documentElement.scrollHeight);
    }



}


export default  JsPdfWrapper
import React from 'react'
import { fetchParties, evictShipConDataCache } from '../../sevices/TMFF/Services'
import withCPContext from '../common/withCPContext'
import { Typography } from '@material-ui/core';
import CpTypeahead from '../common/CpTypeahead';

class RefreshComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            shippers: this.props.options,
            nameAddrList:[],
        }
    }
    createPartyNameAddrList =(data) =>{
        const {value,label,localName,cityCode,cityName,addresses} =data;
        if(addresses && Array.isArray(addresses)){
            const nameAddr =  addresses?.map((address)=>({
                value,
                label,
                localName,
                cityCode,
                cityName,
                fullAddress: <Typography><span style={{fontWeight:'bold'}}>{address.addrTypeDesc} :</span> {`${localName ?? label},  ${address.address}`}</Typography>,
                addresses:[address]
            })); 
            return nameAddr 
        }else{
            return [];
        }
    }

    componentDidMount=()=>{
        const {options} =this.props;
        const {createPartyNameAddrList} =this;
        const combinedObjects= [].concat(...options?.map((x)=>createPartyNameAddrList(x))) ?? []
        this.setState({
            nameAddrList : combinedObjects
        })
    }

    handleConsShipRefresh = async () => {
        const { cpContext, name } = this.props
        evictShipConDataCache(this.props.name);

        const parties = await Promise.all(
            [
                fetchParties(name)
            ]);

        this.setState({
            shippers: parties ? parties[0] ? parties[0].lookupItems : [] : []
        })
        if (name === 'shipper')
            cpContext.shippers = parties ? parties[0] ? parties[0].lookupItems : [] : []
        if (name === 'consignee')
            cpContext.consignees = parties ? parties[0] ? parties[0].lookupItems : [] : []
        if(name === 'notify')
            cpContext.notifies = parties ? parties[0] ? parties[0].lookupItems : [] : []
    }
    render() {
        const { isDisabled, isRequired, label, name, isAddr1, value, onChange, error, helperText, isForSearchShipments,isRefresh ,renderOption,afterSelectionShow} = this.props
        const {nameAddrList} =this.state;
        return (
            <>
                {isForSearchShipments ?
                    <div style={{ display: 'flex', alignItems: 'end', width: '100%' }}>
                            <CpTypeahead isDisabled={isDisabled} isRequired={isRequired} label={label} name={name} value={value} options={this.state.shippers} onChange={onChange}
                                isRefresh={true} handleConsShipRefresh={this.handleConsShipRefresh} error={error} helperText={helperText} />
                    </div> :
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                        <div style={{ width: '100%' }}>
                            <CpTypeahead isDisabled={isDisabled} isRequired={isRequired} label={label} name={name} 
                                value={value} options={nameAddrList} onChange={onChange} afterSelectionShow={afterSelectionShow}
                                isRefresh={isRefresh} renderOption={renderOption} handleConsShipRefresh={this.handleConsShipRefresh} 
                                error={error} helperText={helperText} isAddr1={isAddr1}
                                />
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default withCPContext(RefreshComponent)
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import withTenant from '../../common/withTenant';
import withError from '../../common/withError';
import withCPContext from '../../common/withCPContext';
import { Grid, Paper, withStyles } from '@material-ui/core';
import PieChart from '../../common/PieChart';
import DonutWidget from '../../common/DonutWidget';
import { getPreferences, getConcurrentUserCount } from '../../../sevices/TMFF/Services';
import { fetchMetrics, getConfig } from '../../../sevices/common/CpServices'
import Loading from '../../common/Loading';
import companyGif from '../../../images/company.gif'
import DataToPdf from './DataToPdf'
import FunnelChart from '../../common/FunnelChart';
import JsPdfWrapper from '../../../common/JsPdfWrapper'
import CPButton from '../../common/Button'
const customConfig = getConfig()

const style = theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16)
        }
    },
    labelConfig: {
        fontSize: '18px',
        float: 'right',
        margin: '12px',
        cursor: 'pointer'

    },
    noDataDiv: {
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bolder'

    },
    buttonGroup: {
        display: 'flex',
        width: '96%',
        justifyContent: "end",
        [theme.breakpoints.up("md")]: {
            width: '82%'
        }
    }
});
class CustomizedDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            widgetDetails: [],
            loading: false,
            noData: false,
            userCount: 0,
            noOfRows: 0,
            noOfColumns: 0

        }
    }

    getWidgetData = async (widget) => {
        const widgetData = await fetchMetrics(
            widget.layouts[0].groupBy,
            widget.layouts[0].filterBy ? widget.layouts[0].filterBy : '',
            widget.layouts[0].filterByVal ? widget.layouts[0].filterByVal : '',
            widget.layouts[0].dateFilter,
            widget.layouts[0].widgetType,
            widget.dataSource)
        return widgetData && widgetData.data ? widgetData.data : {};
    }

    componentDidMount = async () => {
        this.setState({ loading: true })
        let widgets = await getPreferences();
        let userCount = await getConcurrentUserCount();
        let updatedWidgets = []
        if (widgets && widgets.data) {
            this.setState({
                noData: false,
                noOfRows: widgets.data.noOfRows,
                noOfColumns: widgets.data.noOfColumns
            })

            for (let i = 0; i < widgets.data.widgetDetails.length; i++) {
                updatedWidgets.push({
                    ...widgets.data.widgetDetails[i],
                    layouts: [{ ...widgets.data.widgetDetails[i].layouts[0], widgetData: await this.getWidgetData(widgets.data.widgetDetails[i]) }]
                })
            }
        } else {
            this.setState({
                noData: true

            })
        }
        this.setState({ widgetDetails: updatedWidgets, loading: false, userCount: userCount && userCount.data ? userCount.data : 1 })
    }

    fetchConcurrentUserCount = async () => {
        let userCount = await getConcurrentUserCount();
        this.setState({
            userCount: userCount && userCount.data ? userCount.data : 1
        })
    }

    getChart = (chartType, element, data) => {
        const { loading } = this.state;
        switch (chartType) {
            case 'pie':
                return (
                    <Grid item lg={6} xs={12} sm={12} md={6} xl={6}>
                        <Paper variant="outlined" >
                            <PieChart heading={element && element.layouts ? element.layouts[0].widgetName : null}
                                data={data.pieChartList ? data.pieChartList : []}
                                loading={loading}
                                noRefresh={true}
                                showIcon={false} />
                        </Paper>
                    </Grid>
                )
            case 'donut':
                return (
                    data ?
                        <Grid item lg={6} xs={12} sm={12} md={6} xl={6}>
                            <Paper variant="outlined" >
                                <DonutWidget
                                    heading={element.layouts[0].widgetName}
                                    data={data && data.pieChartList ? data.pieChartList : []}
                                    loading={loading}
                                    noRefresh={true}
                                    showIcon={false}
                                />
                            </Paper>
                        </Grid> : null
                )
            case 'funnel':
                return (
                    <Grid item lg={6} xs={12} sm={12} md={6} xl={6}>
                        <Paper variant="outlined" >
                            <FunnelChart heading={element && element.layouts ? element.layouts[0].widgetName : null}
                                data={data.pieChartList ? data.pieChartList : []}
                                loading={loading}
                                noRefresh={true}
                                showIcon={false} />

                        </Paper>
                    </Grid>
                )
            default:
                break

        }
    }

    printAll = () => {
        const jsPdfWrapper = new JsPdfWrapper();
        const targetElement = document.getElementById('toPrint');
        const pdfFileName = 'Metrics-Data';
        jsPdfWrapper.DownloadFromHTML(targetElement, pdfFileName);
    }

    render() {
        const { widgetDetails, loading, noData, noOfColumns, noOfRows } = this.state
        const { tenantDetails, classes } = this.props
        return (
            <>
                <div className={classes.buttonGroup}>
                    {noData ?
                        null :
                        <CPButton id='exportAll' onClick={() => this.printAll()} variant="primary" styles={{ float: 'right', margin: '0.50%', marginRight: '0%', border: 'none' }}>Export To PDF</CPButton>
                    }
                    <CPButton id='Councurentusercount' title='Concurrent User count' variant="secondary" onClick={this.fetchConcurrentUserCount} styles={{ float: 'right', margin: '0.50%', marginRight: '0%' }}>{`${this.state.userCount}`}</CPButton>
                </div>
                <Loading open={loading} />
                {noData ?
                    <>
                        <Grid container spacing={2} flex='true' direction="row" style={{ width: '100%', margin: '1% 0 0 0 ' }}>
                            <Grid item xl={8} lg={8}>
                                <Grid container spacing={2} flex='true' direction="row" justify="flex-start" alignItems="center">
                                    <div className={classes.noDataDiv}>No Data Found</div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid container spacing={4} flex='true' direction="row" style={{ width: '100%', margin: '1% 0 0 0 ' }}>
                            <Grid item xl={8} lg={8}>
                                <Grid container spacing={2} flex='true' direction={noOfRows === 2 && noOfColumns === 1 ? "column" : "row"} justify="flex-start" alignItems={noOfRows === 2 && noOfColumns === 1 ? "" : "center"} id="chartDiv">
                                    {
                                        widgetDetails.map((element, index) => {
                                            return (element.layouts[0].widgetId === '11' ? <>
                                                {element && element.layouts ? this.getChart(element.layouts[0].widgetType, element, element.layouts[0].widgetData) : null}

                                            </> : null)
                                        })
                                    }

                                    {
                                        widgetDetails.map((element, index) => {
                                            return (element.layouts[0].widgetId === '12' ? <>
                                                {element && element.layouts ? this.getChart(element.layouts[0].widgetType, element, element.layouts[0].widgetData) : null}

                                            </> : null)
                                        })
                                    }
                                    {
                                        widgetDetails.map((element, index) => {
                                            return (element.layouts[0].widgetId === '21' ? <>
                                                {element && element.layouts ? this.getChart(element.layouts[0].widgetType, element, element.layouts[0].widgetData) : null}

                                            </> : null)
                                        })
                                    }

                                    {
                                        widgetDetails.map((element, index) => {
                                            return (element.layouts[0].widgetId === '22' ? <>
                                                {element && element.layouts ? this.getChart(element.layouts[0].widgetType, element, element.layouts[0].widgetData) : null}

                                            </> : null)
                                        })
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                <Paper variant="outlined" style={{ height: '100%' }} >
                                    <a href={`${tenantDetails.redirectUrl}`} target="_blank" rel="noopener noreferrer">
                                        <img className="img" src={`/secure-ui/themes/${tenantDetails.tenant}/brand.gif`} style={{ height: '100%', width: '100%', position: 'relative', left: '0' }} alt="Brand space" />
                                    </a>
                                </Paper>
                            </Grid>
                        </Grid>
                        <div id="toPrint">
                            {customConfig.enableToPdf ? <div>
                                <img className="img" id='imageId' src={companyGif} height='40px' width='160px' alt="Logo" style={{ marginTop: '3%', marginLeft: '3%', display: 'none' }} />
                            </div> : null}
                            <div id='displayDiv' style={{ visibility: 'hidden', margin: '3%' }}>
                                <div>
                                    <img className="img" id='imageId' src={companyGif} height='40px' width='160px' alt="Logo" style={{ marginTop: '3%', marginBottom: '5%', marginLeft: '3%' }} />
                                </div>
                                <DataToPdf widgetDetails={widgetDetails} loading={this.state.loading} noData={this.state.noData} />
                            </div>
                        </div>
                    </>}
            </>
        );
    }
}



export default injectIntl(withTenant(withCPContext(withError(withRouter(withStyles(style)(CustomizedDashboard))))));
import React, { Component } from 'react';
import { Dialog, withStyles, DialogContent, DialogActions } from '@material-ui/core'
import withTenant from './withTenant';
import { injectIntl } from 'react-intl';
import CPButton from './Button';

const style = () => ({
    content: {
        flex: '1 1 auto',
        overflow: 'auto',
        padding: '2rem',
        fontSize: '14px',
        color: '#4A4E50'
    },
    header: {
        backgroundColor: '#E8F0F4',
        fontWeight: '500',
        fontSize: '14px',
        display: 'flex',
        padding: '1.5rem 2rem',
    }
});

class CPIdleWarning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countDown: '',
        }
        this.startingMinutes = 5;
        this.time = this.startingMinutes * 60;
        this.timer = null;
    }
    componentDidMount() {
        this.setState({ countDown: ` 5:00 minutes!` })
        this.timer = setInterval(this.updateCountdown, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }
    updateCountdown = () => {
        const minutes = Math.floor(this.time / 60);
        let seconds = this.time % 60;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        this.setState({ countDown: ` ${minutes}:${seconds} minutes!` })
        this.time--;
        if (this.time === 0) {
            clearInterval(this.timer);
        }
    }

    render() {
        const { show, handleRejected, handleAccepted, message, classes, intl } = this.props;
        return (
            <Dialog open={show} width='md' onClose={() => { void (0) }} style={{ width: "100%" }}>
                <div className={classes.header} id="alert-dialog-title">
                    {intl.formatMessage({ id: "information" })}
                </div>
                <DialogContent classes={{ root: classes.content }}>
                    <span> {`${message}${this.state.countDown}`}</span>
                </DialogContent>
                <DialogActions>
                    <CPButton variant='secondary' id="condirmCancel" onClick={handleRejected}  >{intl.formatMessage({ id: "logout" })}</CPButton>
                    <CPButton variant='primary' id="confirmOk" onClick={handleAccepted} >{intl.formatMessage({ id: "stay" })}</CPButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default injectIntl(withTenant(withStyles(style)(CPIdleWarning)))
import React from 'react';
import { Drawer, withStyles } from "@material-ui/core"

const style = {
    drawer: props => ({
        width: props.width,
       // borderRadius: props.anchor === "right" ? '10px 0 0 10px' : '0 0px 0px 0'
    })
}
function CpDrawer(props) {
    const { handleDrawer, show, children, classes, anchor } = props
    return (
        <Drawer classes={{ paper: classes.drawer }} open={show} anchor={anchor} onClose={() => handleDrawer()}>
            {children}
        </Drawer >
    )
}
export default withStyles(style)(CpDrawer)
import React from 'react'
import { Card, withStyles, CardContent, IconButton } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Box from '../../common/Box';
import DetailedMileStoneData from './DetailedMileStoneData';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { ReactComponent as PushPinIcon } from '../../../images/pushpin.svg'

const style = theme => ({
    root: {
        minWidth: 275,
        width: '98%',
        margin: '1%'
    },
    cardRoot: {
        padding: '0 0 0 0 !important',
        marginBottom: '10px !important'
    },
    line: {
        margin: 0,
        display: 'flex'
    },
    titleNormal: {
        fontWeight: 'Normal',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important'
    },
    title: {
        fontWeight: 'Bold',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important'
    },
    value: {
        width: '60%',
        display: 'inline-block',
        padding: '1%',
        fontSize: '12px !important'
    },
    odd: {
        backgroundColor: 'rgba(237, 239, 239, 1)'
    },
    even: {
        backgroundColor: 'white'
    },
    noRecordsFound: {
        padding: '2% !important',
        textAlign: 'center'
    },
    iconButton: {
        padding: '4px'
    }
})

const ContainersViewMobile = (props) => {
    const { data, classes, intl, mileStoneHeaders, handleExpandCollapseContainer, handlePinOrUnPin } = props;
    const getOddorEven = (index) => {
        return index % 2 === 0 ? "even" : "odd"
    }
    return (
        <Card className={classes.root} variant="outlined" >
            {
                data ? data.map((container, index) => {
                    return (
                        <CardContent key={index} className={`${classes.cardRoot} ${classes[getOddorEven(index)]}`} >
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.title}> <span >{intl.formatMessage({ id: 'search_shipments_search_containerNo' })}</span></div>
                                <div className={classes.title}> <span >{container.containerNo}</span></div>
                                <div>
                                    <IconButton title='Expand' id={`ctnExpandMobile${index}`} className={classes.iconButton} onClick={() => handleExpandCollapseContainer(index)}>
                                        {container.expandedRow ? <ArrowDropUpIcon style={{ fontSize: '20px', color: '#417F90' }} /> : <ArrowDropDownIcon style={{ fontSize: '20px', color: '#2C697A' }} />}
                                    </IconButton>
                                    {container.intContainerNo ?
                                        <IconButton id={`ctnPinUnpinMobile${index}`} onClick={() => handlePinOrUnPin(container.intContainerNo, 'container', !container.favoriteCont)}>
                                            <PushPinIcon color={container.favoriteCont ? 'secondary' : ''} style={{ fontSize: '20px' }} />
                                        </IconButton>
                                        : null}
                                </div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'type' })}</span></div>
                                <div className={classes.value}> <span >{container.containerType}</span></div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'seal' })}</span></div>
                                <div className={classes.value}> <span >{container.containerSealNo1}</span></div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'pcs' })}</span></div>
                                <div className={classes.value}> <span >{`${container.totPcs} `}</span></div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'weight' })}</span></div>
                                <div className={classes.value}> <span >{`${container.totWgt} `}</span></div>
                            </div>
                            {container.expandedRow ?
                                <Box header={intl.formatMessage({ id: 'milestones' })}>
                                    <DetailedMileStoneData mileStoneHeaders={mileStoneHeaders} data={container.containerStatusList} milistone={true} />
                                </Box>
                                :
                                null
                            }
                        </CardContent>
                    )
                }) : <CardContent className={classes.noRecordsFound}>{intl.formatMessage({ id: 'noRecordsFound' })}</CardContent>
            }

        </Card>
    )
}

export default injectIntl(withStyles(style)(ContainersViewMobile))

import React from 'react'
import { withStyles, CircularProgress } from '@material-ui/core'
import withLoading from './withLoading'
const style = {
    box: {
        margin: '1rem 2rem'
    },
    headBox: {
        maxHeight: '40px',
        maxWidth: '100%',
        fontSize: '18px',
        fontWeight: 'bold   ',
        color: '#4A4E50',
        padding: '5px',
        borderBottom: '1px solid #C8CFD2',
        textTransform: 'capitalize',
        display: 'flex'

    },
    bodyBox: {
        maxWidth: '100%',
        fontSize: '14px',
        borderColor: '#edefef',
        borderTop: 0,
        height: 'fit-content'
    },
    progress: {
        color: '#417F90',
        marginRight: '10px'
    },
    required: {
        color: "red",
        width: "2%"
      },
}
function Box(props) {

    const { header, children, classes, showloading, loading,isRequired } = props
    return (
        <div className={classes.box}>
            {header ?
                <div className={classes.headBox}>
                    <span style={{ marginRight: '1%',textTransform:'none' }}>{header}</span>
                    <label style={{ fontWeight: 500 }} htmlFor={header}> {isRequired ? <span className={classes.required}>*</span> : ""}</label>
                    {showloading ? (loading.loadingDoc ?
                        <CircularProgress size='20px' classes={{ root: classes.progress, marginLeft: '2%' }} /> :
                        loading.customMsg ? <> <span style={{ textTransform: 'capitalize', }}>{loading.customMsg}</span> <CircularProgress size='20px' classes={{ root: classes.progress, marginLeft: '2%' }} /> </> : null) : null}
                </div>
                : null}
            <div className={classes.bodyBox}>
                {children}
            </div>

        </div >
    )
}
export default withLoading(withStyles(style)(Box))

import React from 'react'
import { withStyles, Paper, Card, CardContent } from '@material-ui/core'
import EditableItem from './EditableItem'
import EditableContainer from './EditableContainer'
import ItemsMobileView from './ItemsMobileView'
import BookingContainerView from './BookingContainerView'
import { injectIntl } from 'react-intl'

const style = {
    tableHeader: {
        fontWeight: 'bold !important',
        backgroundColor: 'rgba(237, 239, 239, 1)',
        borderRight: '2px solid white',
        height: '40px'
    },
    tableHeaderWOB: {
        fontWeight: 'bold !important',
        backgroundColor: 'rgba(237, 239, 239, 1)',
        height: '40px'
    },
    tablebody: {
        // display: ' block !important',
        width: '100%',
        tableLayout: 'fixed',
        fontSize: '12px !important'
    },
    tabletr: {
        display: ' table !important',
        width: '100%',
        tableLayout: 'fixed'
    },
    lastrow: {
        borderBottom: '0px!important'
    },
    linkbutton: {
        cursor: 'pointer',
        width: '100%',
        padding: '2%',
        fontWeight: '700',
        color: '#007ad9',
        textTransform: 'uppercase'
    },
    noRecords: {
        minHeight: '10vh',
        display: ' flex !important',
        justifyContent: 'center',
        alignItems: 'center'
    },
    paper: {
        width: '98%',
        margin: '1%',
        minWidth: '275px'
    }
}
function CPEditableCardTable(props) {
    const { data, classes, addfunc, removefunc, onChangefields, maxLength, readOnly, tableFor, selected, onSave, containerTypes, onEdit, onCancel, intl } = props
    const selectedRow = selected !== null ? data?.filter(el => {
        const id = el.id ? el.id : el.itemUniqueRefNo
        return id === selected
    }) : null
    return (
        <Paper className={classes.paper}>
            {readOnly === false ? maxLength !== undefined ?
                data.length < maxLength ?
                    <div className={classes.linkbutton}>
                        <span onClick={addfunc}>ADD {tableFor}</span>
                    </div>
                    :
                    null
                :
                <div className={classes.linkbutton}>
                    <span onClick={addfunc}>ADD {tableFor}</span>
                </div>
                : null
            }
            {data ?
                selected !== null ?
                    tableFor === 'ITEM' ?
                        <EditableItem data={selectedRow[0]} onChange={onChangefields} onSave={onSave} onCancel={onCancel} />
                        :
                        <EditableContainer data={selectedRow[0]} onChange={onChangefields} onSave={onSave} onCancel={onCancel} containerTypes={containerTypes} />
                    :
                    (tableFor === 'ITEM' ?
                        <ItemsMobileView data={data} readOnly={readOnly} onDelete={removefunc} onEdit={onEdit} />
                        :
                        <BookingContainerView data={data} readOnly={readOnly} onDelete={removefunc} onEdit={onEdit} />)
                :
                readOnly === true ?
                    <Card className={`${classes.tabletr} ${classes.noRecords}`}>
                        <CardContent > {intl.formatMessage({ id: 'noRecordsFound' })}</CardContent>
                    </Card>
                    :
                    null
            }
        </Paper >
    )
}

export default injectIntl(withStyles(style)(CPEditableCardTable))

import React from 'react'
import { injectIntl } from 'react-intl';
import CpEditableTable from '../../common/CpEditableTable';
import { Link, Typography } from '@material-ui/core';
import Box from '../../common/Box';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';
import CpBreadcrumb from '../../common/CpBreadcrumb';

function CommercialInvoiceLine(props) {
    const commInvoiceHeaders = [
        { title: "Comm.Invoice No.", field: "ciNo" },
        { title: "CBP Line Number", field: "ciLineNo" },
        { title: "Country of Origin", field: "countryOrigin" },
        { title: "Country of Export", field: "countryExport" },
        { title: "Part No. ", field: "partNo" },
        { title: "Tariff No.", field: "tariffNo" },
        { title: "Value US$", field: "valueUsAmt" },
        { title: "Value Foreign/Currency", field: "valueForeign" },
        { title: "Exchange Rate", field: "exchangRate" },
        { title: "Charges/Currency", field: "charges" },
        { title: "Gross Weight", field: "weight" },
        { title: "Trade Agreement", field: "spiPrimary" },
        { title: "Manufacturer ID", field: "manufacturerId" },
        { title: "AD/CVD Case No.", field: "caseNo" },
        { title: "PGA Indicator", field: "pgaIndicator" },
        { title: "Duty Total", field: "dutyTotAmt" }
    ]

    const { intl, handleDisplayForward, handleDisplayBack, commInvoiceData, data, l1totalRecords, l1selectedRecord, l2totalRecords, l2selectedRecord } = props

    const feeHeaders = [
        { 'width': '15%', 'title': intl.formatMessage({ id: 'fee_code' }), 'field': 'customsFeeCode', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': intl.formatMessage({ id: 'fee_desc' }), 'field': 'customsFeeCodeDesc', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': intl.formatMessage({ id: 'fee_amount' }), 'field': 'amtFeeAmt', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': intl.formatMessage({ id: 'fee_rated_amount' }), 'field': 'amtFeeProratedAmt', 'showLabel': true, 'type': 'label' }
    ]
    const tariffHeaders = [
        { 'width': '70%', 'title': intl.formatMessage({ id: 'tarrif' }), 'field': 'tariffNo', 'showLabel': true, 'type': 'label' }
    ]

    return (
        <div>
            <div style={{ display: 'flex', margin: '2rem 0 0 2rem' }}>
                <CpBreadcrumb>
                    <Link color='inherit' onClick={() => handleDisplayBack(1, commInvoiceData)}> {intl.formatMessage({ id: 'commercial_invoice_item_caps' }, { val1: l1selectedRecord, val2: l1totalRecords })} </Link>
                    <Typography color='textPrimary'>{intl.formatMessage({ id: 'commercial_invoice_line' }, { val1: l2selectedRecord, val2: l2totalRecords })}</Typography>
                </CpBreadcrumb>
            </div>
            <Box showloading={true} header={intl.formatMessage({ id: 'commercial_invoice_line' }, { val1: l2selectedRecord, val2: l2totalRecords })}>
                <NonEditableDataGrid list={commInvoiceHeaders} data={data && data.data ? data.data[0] : {}} />
                <CpEditableTable headers={feeHeaders} readOnly={true} data={data && data.data && data.data[0].ciLineFeesList ? data.data[0].ciLineFeesList : []} />
            </Box>
            <Box header={intl.formatMessage({ id: 'tarrif' })}>
                <CpEditableTable headers={tariffHeaders} readOnly={true} data={data && data.data && data.data[0].ciTariffClassList ? data.data[0].ciTariffClassList : []} navigateIcon={true} handleDisplayForward={(row, index) => handleDisplayForward(3, row, index)} />
            </Box>
        </div>
    );
}

export default injectIntl(CommercialInvoiceLine)
import React from 'react'
import { injectIntl } from 'react-intl';
import CpEditableTable from '../../common/CpEditableTable';
import { Link, Typography } from '@material-ui/core';
import Box from '../../common/Box';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';
import CpBreadcrumb from '../../common/CpBreadcrumb';

function DeliveryDetails(props) {

    const { handleDisplayBack, deliveryData, intl, totalRecords, selectedRecord } = props

    const deliveryHeaders = [
        { title: "Delivery Order No.", field: "doNo" },
        { title: "Location of Goods", field: "location" },
        { title: "Deliver to Name", field: "deliverToParty" },
        { title: "Deliver to Address", field: "deliveryToAddress" },
        { title: "Notify Party", field: "notifyParty" },
        { title: "Notify Party Address", field: "notifyPartyAddress" },
        { title: "# of Packages", field: "noOfPackages" },
        { title: "Gross Weight", field: "grossWeight" },
        { title: "Trucker", field: "trucker" },
        { title: "Trucker Contact", field: "truckerContact" },
        { title: "Prepaid/Collect/COD/ Bank Release", field: "prepaidCollect" },
        { title: "Instructions", field: "instructions" },
        { title: "Route Instructions", field: "routeInstr" },
        { title: "Messages", field: "messages" },
        { title: "Final Delivery", field: "finalDelivery" },
        { title: "POD Signature", field: "authorizeSignature" }
    ]

    const dateListHeaders = [
        { 'width': '50%', 'title': intl.formatMessage({ id: 'description' }), 'field': 'tracingDateNoDesc', 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': intl.formatMessage({ id: 'date' }), 'field': 'dateTracing', 'showLabel': true, 'type': 'label' }
    ]
    const hblmblHeaders = [
        { 'width': '50%', 'title': intl.formatMessage({ id: 'search_shipments_search_mbNo' }), 'field': 'masterBill', 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': intl.formatMessage({ id: 'search_shipments_search_hblNo' }), 'field': 'houseBill', 'showLabel': true, 'type': 'label' }
    ]
    const containerHeaders = [
        { 'width': '100%', 'title': intl.formatMessage({ id: 'containerno' }), 'field': 'noContainer', 'showLabel': true, 'type': 'label' }
    ]
    const markListHeaders = [
        { 'width': '50%', 'title': intl.formatMessage({ id: 'Marks_LineNumber' }), 'field': 'marksLineNo', 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': intl.formatMessage({ id: 'Marks_Number' }), 'field': 'marksNumbers', 'showLabel': true, 'type': 'label' }
    ]

    return (
        <div>
            <div style={{ display: 'flex', margin: '2rem 0 0 2rem' }}>
                <CpBreadcrumb >
                    <Link color='inherit' onClick={() => handleDisplayBack(0)}>{intl.formatMessage({ id: 'delivery_orders' })}</Link>
                    <Typography color='textPrimary'>{intl.formatMessage({ id: 'delivery_caps' }, { val1: selectedRecord, val2: totalRecords })}</Typography>
                </CpBreadcrumb>
            </div>
            <Box showloading={true} header={intl.formatMessage({ id: 'delivery_caps' }, { val1: selectedRecord, val2: totalRecords })}>
                <NonEditableDataGrid list={deliveryHeaders} data={deliveryData ? deliveryData : {}} />
                <CpEditableTable headers={dateListHeaders} readOnly={true} data={deliveryData && deliveryData.doDatesList ? deliveryData.doDatesList : []} />
                <CpEditableTable headers={hblmblHeaders} readOnly={true} data={deliveryData && deliveryData.doShipIdXRefList ? deliveryData.doShipIdXRefList : []} />
                <CpEditableTable headers={containerHeaders} readOnly={true} data={deliveryData && deliveryData.doContainersList ? deliveryData.doContainersList : []} />
                <CpEditableTable headers={markListHeaders} readOnly={true} data={deliveryData && deliveryData.doMarksList ? deliveryData.doMarksList : []} />
            </Box>
        </div>
    )
}

export default injectIntl(DeliveryDetails)
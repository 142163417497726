import React from 'react'
import { injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core'
import CpEditableTable from '../common/CpEditableTable'
import RateCardMobileView from '../mobileComponents/RateCardMobileView'
const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    }
})
function RateCard(props) {
    const { intl, data, classes } = props
    const quoteHeaders = [
        { 'width': '20%', 'title': intl.formatMessage({ id: 'docDescription' }), 'field': 'revChrgDesc', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'rate' }), 'field': 'revRate', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': intl.formatMessage({ id: 'qty' }), 'field': 'revQuantity', 'showLabel': true, 'type': 'label'},
        { 'width': '10%', 'title': 'UOM', 'field': 'revQuantityUnit', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'amount' }), 'field': 'revAMTFC', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': intl.formatMessage({ id: 'currency' }), 'field': 'revCurrCode', 'showLabel': true, 'type': 'label', 'showBorder': false },
    ]
    return (
        <div style={{ padding: "0% 1%" }}>
            <div className={classes.sectionDesktop}>
                <CpEditableTable headers={quoteHeaders} readOnly={true} data={data} />
            </div>
            <div className={classes.sectionMobile}>
                <RateCardMobileView data={data} />
            </div>
        </div>
    )
}

export default injectIntl(withStyles(style)(RateCard))

import React from 'react'
import { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Billings from './Billings';
import BillingDetails from './BillingDetails';
import { getBillings, getBillingInvoiceDetails } from '../../../sevices/CMUS/Services'
import withLoading from '../../common/withLoading';

const styles = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
})
class BillingSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displaySection: {
                level0: { visible: true, data: undefined, noOfPages: 0, selectedPage: 1, totalRecords: 0, selectedRecord: 0 },
                level1: { visible: false, data: undefined },
            },
            selectedBilling: []
        }
    }
    componentDidMount = async () => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        await this.getBillingsInfo(this.state.displaySection.level0.selectedPage);
        await loading.setLoadindDoc(false);
    }
    getBillingsInfo = async (pageSelected) => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        let billings = await getBillings(pageSelected);
        if (billings && billings.status === 200 && billings.data) {
            await this.setState({
                displaySection: {
                    ...this.state.displaySection,
                    level0: {
                        visible: true,
                        data: billings.data.data ? billings.data.data : [],
                        noOfPages: billings.data.maxResults !== 0 ? Math.ceil(billings.data.totalRecords / billings.data.maxResults) : 1,
                        selectedPage: pageSelected ? pageSelected : 1,
                        totalRecords: billings.data.totalRecords ? billings.data.totalRecords : 0
                    }
                }
            })
        }
        else {
            await this.setState({
                displaySection: {
                    ...this.state.displaySection,
                    level0: {
                        visible: true,
                        data: [],
                        noOfPages: 0,
                        selectedPage: 0
                    }
                }
            })

        }
        loading.setLoadindDoc(false);
    }
    getInvoiceDetails = async (data, index) => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        const invoiceDetails = await getBillingInvoiceDetails(data.suffix)
        if (invoiceDetails && invoiceDetails.status === 200 && invoiceDetails.data) {
            await this.setState({
                displaySection: {
                    level0: { ...this.state.displaySection.level0, visible: false, selectedRecord: index ? index : 0 },
                    level1: {
                        visible: true, data: invoiceDetails.data.data ? invoiceDetails.data.data : null
                    },
                },
                selectedBilling: data
            });
        }
        else {
            await this.setState({
                displaySection: {
                    level0: { ...this.state.displaySection.level0, visible: false },
                    level1: {
                        visible: true,
                        data: null
                    }
                },
                selectedBilling: data
            });
        }
        loading.setLoadindDoc(false);
    }
    handleDisplayBack = async (level) => {
        switch (level) {
            case 0:
                await this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: true },
                        level1: { ...this.state.displaySection.level1, visible: false }
                    }
                });
                break;
            default:
                this.setState({
                    displaySection: {
                        level0: { visible: false, data: undefined, noOfPages: 0, selectedPage: 1 },
                        level1: { visible: false, data: undefined }
                    }
                });
                break;
        }
    }

    handleDisplayForward = async (level, data, index) => {
        switch (level) {
            case 1:
                await this.getInvoiceDetails(data, index)
                break;
            default:
                this.setState({
                    displaySection: {
                        level0: { visible: false, data: undefined, noOfPages: 0, selectedPage: 1 },
                        level1: { visible: false, data: undefined }
                    }
                });
                break;
        }
    }
    handlePageClick = (pageIndex, level) => {
        if (level === 0) {
            this.getBillingsInfo(pageIndex);
        }
    }
    render() {
        const { displaySection, selectedBilling } = this.state
        return (
            <div>
                {displaySection.level0.visible ? <Billings billingData={displaySection.level0.data}
                    handleDisplayForward={this.handleDisplayForward} noOfPages={displaySection.level0.noOfPages}
                    selectedPage={displaySection.level0.selectedPage} handlePageClick={this.handlePageClick} /> : null}
                {displaySection.level1.visible ? <BillingDetails handleDisplayBack={this.handleDisplayBack} billingInvoiceData={displaySection.level1.data} selectedBilling={selectedBilling}
                    totalRecords={displaySection.level0.totalRecords} selectedRecord={displaySection.level0.selectedRecord} /> : null}
            </div>
        )
    }

}

export default injectIntl(withLoading(withStyles(styles)(BillingSection)))
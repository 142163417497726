import React, { useEffect, useState } from 'react';
import CpDropDown from '../common/CpDropDown';
import CpTypeahead from '../common/CpTypeahead';
import CpInput from '../common/CpInput';
import CpDateTime from '../common/CpDateTime';
import CpTextarea from '../common/CpTextarea';
import { shipmentTypesInland, shipmentTypesSea, motTypes, deliveryTerms } from '../../common/DropDownValues';
import { Grid, withStyles } from '@material-ui/core';
import '../../styles/bar.css';
import { ConnectForm } from '../common/ConnectForm';
import { CPContextConsumer } from '../../common/CPcontext';
import CPTime from '../common/CPTime';
import { injectIntl } from "react-intl";
import RefreshComponent from '../refreshfunctionality/RefreshComponent'
import CpSearchableFreeTextTypeahead from '../common/CpSearchableFreeTextTypeahead';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CpButtonGroup from '../common/CpButtonGroup';
import CPButton from '../common/Button';
import SearchIcon from '@material-ui/icons/Search';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import NonEditableDataGrid from '../common/NonEditableDataGrid';
import CPMultiSelectBox from '../common/CPMultiSelectBox';

const style = theme => ({
    citedDivNoBg: {
        margin: '2rem 0'
    },
    showIcon: {
        color: '#277AB5',
        marginLeft: '2%',
        [theme.breakpoints.up('md')]: {
            marginLeft: '36%',
        }
    },

    pickupDeliveryGrid: {
        width: '100%',
        backgroundColor: '#F9FBFB',
        borderBottom: '1px solid #C8CFD2',
        borderTop: '1px solid #C8CFD2',
        margin: '0.5rem 0'
    },
    boldHelperText: {
        color: "black",
        fontWeight: 600,
        fontSize: '11px',
    },

})

function DetailsForm(props) {
    const [supDateField, setSUPDateField] = useState(null);
    const { addressTitle, status } = props.data;
    let cpSupUser = JSON.parse(sessionStorage.getItem('isCpSupUser'));


    useEffect(() => {
        const { readOnlyHeaderList, supReadyDate } = props;
        if (cpSupUser) {
            setSUPDateField([...readOnlyHeaderList, ...supReadyDate])
        } else {
            setSUPDateField(readOnlyHeaderList)
        }
    }, [props.readOnlyHeaderList])

    const getAddressTitle = (obj, status, intl) => {
        return addressTitle?.[obj] && status === "In Progress" ? addressTitle[obj] : intl.formatMessage({ id: 'existingAddress' });
    }

    const { classes, readOnly, onChange, handleCountryChange, handleDateorTimeChange,handlePickupFromToTimings,handlePopOverOpen, handleTypeaheadChange,
        data, errordetails, intl, destCityRef, orgCityRef, podCityRef,polCityRef, podCountryRef, polCountryRef, unId, formFor, noOptionMsg, performPartyLookupSearch,
        handleTypeAheadInputChange, onInputChange, onChangeAddress, handleToggleButtonChange, autoFocusCustomer, autoFocusShipper, autoFocusConsignee, pickupCollectAddress,
        pickupDeliveryAddress, deliverCollectAddress,deliverDeliveryAddress,showorHideExtraFields } = props;
    return (
        <CPContextConsumer>
            {({ weightUOM, volumeUOM, moveTypes, piecesUOM, commodityUOM, countries, shippers, consignees, notifies }) =>
                <ConnectForm>
                    {({ register }) =>
                        <Grid flex="true" container spacing={0}>
                            {readOnly ?
                                <>
                                    <NonEditableDataGrid showorHideExtraFields={showorHideExtraFields} list={supDateField} heading='' data={data} />
                                </>
                                :
                                <>
                                    <Grid item lg={12}>
                                        <div className={classes.citedDivNoBg}>
                                            <Grid flex="true" container spacing={0}>
                                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                                    {formFor === "bookingTemplate" && sessionStorage.getItem("isCPAdmin") === "true" ?
                                                        <>
                                                            <CpSearchableFreeTextTypeahead isDisabled={false} isRequired={false} label={intl.formatMessage({ id: 'booking_templates_search_customer' })}
                                                                name='customer' options={data.customers} value={data.customer}
                                                                searchWith="label" showTitle={true}
                                                                onChange={(event) => handleTypeaheadChange(event, 'details', 'customer')}
                                                                error={errordetails.customer !== '' ? true : false} helperText={errordetails.customer}
                                                                performPartyLookupSearch={() => performPartyLookupSearch(data.customerName, "customer")}
                                                                onInputChange={(value, event) => handleTypeAheadInputChange(value, "customer", event)}
                                                                inputValue={data.customerName}
                                                                noOptionsMsg={data.noOptionMsg}
                                                                autoFocus={autoFocusCustomer} />
                                                            <CpSearchableFreeTextTypeahead isDisabled={false} isRequired={false} label={intl.formatMessage({ id: 'search_shipments_search_shipper' })}
                                                                name='shipper' options={data.shippers} value={data.shipper}
                                                                searchWith="label" showTitle={true}
                                                                onChange={(event) => handleTypeaheadChange(event, 'details', 'shipper')}
                                                                error={errordetails.shipper !== '' ? true : false} helperText={errordetails.shipper}
                                                                performPartyLookupSearch={() => performPartyLookupSearch(data.shipperName, "shipper", true)}
                                                                onInputChange={(value, event) => handleTypeAheadInputChange(value, "shipper", event)}
                                                                inputValue={data.shipperName}
                                                                noOptionsMsg={data.noOptionMsg}
                                                                autoFocus={autoFocusShipper} />

                                                        </>
                                                        :
                                                        <RefreshComponent isDisabled={readOnly} isRequired={formFor === "bookingTemplate" ? false : true} label={intl.formatMessage({ id: 'search_shipments_search_shipper' })}
                                                            name='shipper' value={data.shipper} isAddr1={ data.shipperAddress.addr1 ?? ""} onInputChange={onInputChange} options={shippers} onChange={(selected) => handleTypeaheadChange(selected, 'details', 'shipper')} error={errordetails.shipper !== '' ? true : false}
                                                            helperText={errordetails.shipper} isRefresh={true} afterSelectionShow="fullName" renderOption="labelAddr"  />

                                                    }
                                                    <div className={classes.showIcon}>
                                                        <CPButton variant='containerLess' disabled={!data.shipperName} styles={{ margin: '0px' }} onClick={() => props.enableShipperOrConsgineeAddress('shipper')}>
                                                            {data.enableShipperAddress ?
                                                                <><RemoveCircleOutlineIcon />{intl.formatMessage({ id: 'hideAddress' })} </>
                                                                : <><AddCircleOutlineOutlinedIcon />{intl.formatMessage({ id: 'showAddress' })}</>}
                                                        </CPButton>
                                                    </div>

                                                    {data.enableShipperAddress ? <>
                                                        <div className={classes.showIcon}>
                                                            <CPButton variant='secondary' disabled={readOnly} onClick={(event) => handlePopOverOpen(event, 'shipperAddress', "", shippers, data.shipper)} ><SearchIcon></SearchIcon>{intl.formatMessage({ id: 'searchAddress' })}</CPButton>
                                                        </div>
                                                        <CpInput isDisabled={readOnly} register={register()} placeholder={intl.formatMessage({ id: 'address1' })} label={' '} maxLength={45} value={data.shipperAddress.addr1 ? data.shipperAddress.addr1 : null} id="shpaddr1" name="addr1" isRequired={false} type="text" onChange={(event) => onChangeAddress(event, 'shipperAddress')} />
                                                        <CpInput isDisabled={readOnly} register={register()} placeholder={intl.formatMessage({ id: 'address2' })} label={' '} maxLength={45} id="shpaddr2" name="addr2" isRequired={false} type="text" value={data.shipperAddress.addr2 ? data.shipperAddress.addr2 : null} onChange={(event) => onChangeAddress(event, 'shipperAddress')} />
                                                        <CpInput isDisabled={readOnly} register={register()} placeholder={intl.formatMessage({ id: 'address3' })} label={' '} maxLength={45} id="shpaddr3" name="addr3" isRequired={false} type="text" value={data.shipperAddress.addr3 ? data.shipperAddress.addr3 : null} onChange={(event) => onChangeAddress(event, 'shipperAddress')} />
                                                        <CpInput isDisabled={readOnly} register={register()} placeholder={intl.formatMessage({ id: 'address4' })} label={' '} maxLength={45} id="shpaddr4" name="addr4" isRequired={false} type="text" value={data.shipperAddress.addr4 ? data.shipperAddress.addr4 : null} onChange={(event) => onChangeAddress(event, 'shipperAddress')} />

                                                    </> : null
                                                    }

                                                    {formFor === "bookingTemplate" && sessionStorage.getItem("isCPAdmin") === "true" ?
                                                        <CpSearchableFreeTextTypeahead isDisabled={false} isRequired={false} label={intl.formatMessage({ id: 'search_shipments_search_consignee' })}
                                                            name='consignee' options={data.consignees} value={data.consignee}
                                                            searchWith="label" showTitle={true}
                                                            onChange={(event) => handleTypeaheadChange(event, 'details', 'consignee')}
                                                            error={errordetails.consignee !== '' ? true : false} helperText={errordetails.consignee}
                                                            performPartyLookupSearch={() => performPartyLookupSearch(data.consigneeName, "consignee", true)}
                                                            onInputChange={(value, event) => handleTypeAheadInputChange(value, "consignee", event)}
                                                            inputValue={data.consigneeName}
                                                            noOptionsMsg={data.noOptionMsg}
                                                            autoFocus={autoFocusConsignee} />
                                                        :
                                                        <RefreshComponent isDisabled={readOnly} isRequired={false} label={intl.formatMessage({ id: 'search_shipments_search_consignee' })}
                                                         name='consignee' value={data.consignee} isAddr1={data.consigneeAddress.addr1 ?? ""} isRefresh={true}  onInputChange={onInputChange} afterSelectionShow="fullName" 
                                                         renderOption="labelAddr" options={consignees} onChange={(selected) => handleTypeaheadChange(selected, 'details', 'consignee')}  />

                                                    }
                                                    <div className={classes.showIcon}>
                                                        <CPButton styles={{ margin: '0px' }} variant='containerLess' disabled={!data.consigneeName} onClick={() => props.enableShipperOrConsgineeAddress('consignee')}>
                                                            {data.enableConsigneeAddress ?
                                                                <><RemoveCircleOutlineIcon />{intl.formatMessage({ id: 'hideAddress' })} </>
                                                                : <><AddCircleOutlineOutlinedIcon />{intl.formatMessage({ id: 'showAddress' })}</>}
                                                        </CPButton>
                                                    </div>
                                                    {data.enableConsigneeAddress ? <>
                                                        <div className={classes.showIcon}>
                                                            <CPButton variant='secondary' disabled={readOnly} onClick={(event) => handlePopOverOpen(event, 'consigneeAddress', "", consignees, data.consignee)} ><SearchIcon></SearchIcon>{intl.formatMessage({ id: 'searchAddress' })}</CPButton>
                                                        </div>
                                                        <CpInput isDisabled={readOnly} label={" "} placeholder={intl.formatMessage({ id: 'address1' })} maxLength={45} value={data.consigneeAddress.addr1 ? data.consigneeAddress.addr1 : null} id="csnaddr1" name="addr1" isRequired={false} type="text" onChange={(event) => onChangeAddress(event, 'consigneeAddress')} />
                                                        <CpInput isDisabled={readOnly} register={register()} placeholder={intl.formatMessage({ id: 'address2' })} label={' '} id="csnaddr2" name="addr2" isRequired={false} type="text" maxLength={45} value={data.consigneeAddress.addr2 ? data.consigneeAddress.addr2 : null} onChange={(event) => onChangeAddress(event, 'consigneeAddress')} />
                                                        <CpInput isDisabled={readOnly} register={register()} placeholder={intl.formatMessage({ id: 'address3' })} label={' '} id="csnaddr3" name="addr3" isRequired={false} type="text" maxLength={45} value={data.consigneeAddress.addr3 ? data.consigneeAddress.addr3 : null} onChange={(event) => onChangeAddress(event, 'consigneeAddress')} />
                                                        <CpInput isDisabled={readOnly} register={register()} placeholder={intl.formatMessage({ id: 'address4' })} label={' '} id="csnaddr4" name="addr4" isRequired={false} type="text" maxLength={45} value={data.consigneeAddress.addr4 ? data.consigneeAddress.addr4 : null} onChange={(event) => onChangeAddress(event, 'consigneeAddress')} />

                                                    </> : null
                                                    }

                                                    <RefreshComponent isDisabled={readOnly} isRequired={false} label={intl.formatMessage({ id: 'search_shipments_search_notify' })} name='notify' 
                                                        value={data.notify} isAddr1={data.notifyAddress.addr1 ?? ""} isRefresh={true} onInputChange={onInputChange} options={notifies} onChange={(selected) => handleTypeaheadChange(selected, 'details', 'notify')}
                                                        afterSelectionShow="fullName" renderOption="labelAddr"/>
                                                    <div className={classes.showIcon}>
                                                        <CPButton styles={{ margin: '0px' }} variant='containerLess' disabled={!data.notifyName} onClick={() => props.enableShipperOrConsgineeAddress('notify')}>
                                                            {data.enableNotifyAddress ?
                                                                <><RemoveCircleOutlineIcon />{intl.formatMessage({ id: 'hideAddress' })} </>
                                                                : <><AddCircleOutlineOutlinedIcon />{intl.formatMessage({ id: 'showAddress' })}</>}
                                                        </CPButton>
                                                    </div>
                                                    {data.enableNotifyAddress ? <>
                                                        <div className={classes.showIcon}>
                                                            <CPButton variant='secondary' disabled={readOnly} onClick={(event) => handlePopOverOpen(event, 'notifyAddress', "", notifies, data.notify)} ><SearchIcon></SearchIcon>{intl.formatMessage({ id: 'searchAddress' })}</CPButton>
                                                        </div>
                                                        <CpInput isDisabled={readOnly} placeholder={intl.formatMessage({ id: 'address1' })} label={" "} id="ntfyaddr1"  name="addr1" isRequired={false} type="text" maxLength={45} value={data.notifyAddress.addr1 ? data.notifyAddress.addr1 : null} onChange={(event) => onChangeAddress(event, 'notifyAddress')} />
                                                        <CpInput isDisabled={readOnly} register={register()} placeholder={intl.formatMessage({ id: 'address2' })} label={' '} id="ntfyaddr2" name="addr2" isRequired={false} type="text" maxLength={45} value={data.notifyAddress.addr2 ? data.notifyAddress.addr2 : null} onChange={(event) => onChangeAddress(event, 'notifyAddress')} />
                                                        <CpInput isDisabled={readOnly} register={register()} placeholder={intl.formatMessage({ id: 'address3' })} label={' '} id="ntfyaddr3" name="addr3" isRequired={false} type="text" maxLength={45} value={data.notifyAddress.addr3 ? data.notifyAddress.addr3 : null} onChange={(event) => onChangeAddress(event, 'notifyAddress')} />
                                                        <CpInput isDisabled={readOnly} register={register()} placeholder={intl.formatMessage({ id: 'address4' })} label={' '} id="ntfyaddr4" name="addr4" isRequired={false} type="text" maxLength={45} value={data.notifyAddress.addr4 ? data.notifyAddress.addr4 : null} onChange={(event) => onChangeAddress(event, 'notifyAddress')} />

                                                    </> : null
                                                    }


                                                    <CpDropDown isDisabled={readOnly ? true : unId ? true : false} width="100%" label={intl.formatMessage({ id: 'modeOfTransport' })} name="bizType" onChange={(event) => onChange(event, 'details')} value={data.bizType} isRequired={true} items={motTypes} error={errordetails.bizType !== '' ? true : false} helperText={errordetails.bizType} />
                                                    {(data.bizType === "AE" || data.bizType === "AI") ? null : <CpDropDown canBlank={data.shipmentType && data.shipmentType.trim() !== '' ? true : null} isDisabled={readOnly} label='Shipment Type' name="shipmentType" items={data.bizType === "IL" ? shipmentTypesInland : shipmentTypesSea} value={data.shipmentType} onChange={(event) => onChange(event, 'details')} />}
                                                    {(data.bizType === "AE" || data.bizType === "AI") ? null : <CpDropDown canBlank={data.moveType && data.moveType.trim() !== '' ? true : null} isDisabled={readOnly} label='Move Type' name="moveType" items={moveTypes} onChange={(event) => onChange(event, 'details')} value={data.moveType} />}
                                                    {(formFor === "booking" && (data.bizType === "AI" || data.bizType === "SI")) ? <CpInput isDisabled={readOnly} register={register()} label={intl.formatMessage({ id: 'HBL/HAWB No.' })} name="hblAwbNo" value={data.hblAwbNo} isRequired={false} type="text" onChange={(event) => onChange(event, 'details')} /> : null}

                                                    {cpSupUser === false ?
                                                        <>
                                                            <CpInput isDisabled={readOnly} type="number" register={register({ required: 'enter this value' })} label={intl.formatMessage({ id: 'total_pc' })} isRequired={formFor === "bookingTemplate" ? false : true} name="totalPcs" value={data.totalPcs} onChange={(event) => onChange(event, 'details')} error={errordetails.totalPcs !== '' ? true : false} helperText={errordetails.totalPcs}>
                                                                <CpTypeahead renderOption="both" afterSelectionShow="label" isDisabled={readOnly} label='' name="totalPcsUt" options={piecesUOM} onChange={(event) => handleTypeaheadChange(event, 'details', 'totalPcsUt')} value={data.totalPcsUt} error={errordetails.totalPcsUt !== '' ? true : false} helperText={errordetails.totalPcsUt} showTitle={true} titleprop="both" />
                                                            </CpInput>
                                                            <CpInput isDisabled={readOnly} type="number"
                                                                register={register({ required: 'enter this value' })} label={intl.formatMessage({ id: 'total_wt' })} name="totalWgt"
                                                                isRequired={formFor === "bookingTemplate" ? false : true} value={data.totalWgt} onChange={(event) => onChange(event, 'details')}
                                                                error={errordetails.totalWgt !== '' ? true : false}
                                                                helperText={errordetails.totalWgt} >
                                                                <CpTypeahead renderOption="both" afterSelectionShow="label" isDisabled={readOnly} label='' name="totalWgtUt" options={weightUOM} value={data.totalWgtUt} onChange={(event) => handleTypeaheadChange(event, 'details', 'totalWgtUt')}
                                                                    error={errordetails.totalWgtUt !== '' ? true : false}
                                                                    helperText={errordetails.totalWgtUt} showTitle={true} titleprop="both" />
                                                            </CpInput>
                                                        </>

                                                        :
                                                        <>
                                                            <CpInput isDisabled={readOnly} type="number"
                                                                register={register()} label={intl.formatMessage({ id: 'total_pc' })} name="totalPcs"
                                                                maxLength={9} isRequired={false} value={data.totalPcs} onChange={(event) => onChange(event, 'details')}>
                                                                <CpTypeahead renderOption="both" afterSelectionShow="label" isDisabled={readOnly} label='' name="totalPcsUt" options={piecesUOM} onChange={(event) => handleTypeaheadChange(event, 'details', 'totalPcsUt')} value={data.totalPcsUt} showTitle={true} titleprop="both" />
                                                            </CpInput>
                                                            <CpInput isDisabled={readOnly} type="number" register={register()} label={intl.formatMessage({ id: 'total_wt' })} name="totalWgt" isRequired={false} value={data.totalWgt} onChange={(event) => onChange(event, 'details')}>
                                                                <CpTypeahead renderOption="both" afterSelectionShow="label" isDisabled={readOnly} label='' name="totalWgtUt" options={weightUOM} value={data.totalWgtUt} onChange={(event) => handleTypeaheadChange(event, 'details', 'totalWgtUt')} showTitle={true} titleprop="both" />
                                                            </CpInput>
                                                        </>
                                                    }

                                                    <CpInput isDisabled={readOnly} type="number" register={register()} label={intl.formatMessage({ id: 'totalVoume' })} name="totalVol" isRequired={false} value={data.totalVol} onChange={(event) => onChange(event, 'details')} >
                                                        <CpTypeahead renderOption="both" afterSelectionShow="label" isDisabled={readOnly} label='' name="totalVolUt" value={data.totalVolUt} options={volumeUOM} onChange={(event) => handleTypeaheadChange(event, 'details', 'totalVolUt')} showTitle={true} titleprop="both" />
                                                    </CpInput>
                                                    <CpTypeahead renderOption="both" afterSelectionShow="value" isDisabled={readOnly} label={intl.formatMessage({ id: 'commodity' })} name="commodityCode" maxLength={20} value={data.commodityCode} options={commodityUOM} onChange={(event) => handleTypeaheadChange(event, 'details', 'commodityCode')} showTitle={true} titleprop="both" >
                                                        <CpInput isDisabled={readOnly} register={register()} label="" name="commodity" isRequired={false} type="text" value={data.commodity} maxLength={70} onChange={(event) => onChange(event, 'details')} />
                                                    </CpTypeahead>    
                                                    <CpInput isDisabled={readOnly} register={register()} label={intl.formatMessage({ id: 'refNo' })} name="referenceNumber" isRequired={false} type="text"
                                                        helperText={(data.referenceNumber.length === 50) && <span className={classes.boldHelperText}>{intl.formatMessage({ id: 'referenceNumberMsg' })} </span>}
                                                        maxLength={50} value={data.referenceNumber} onChange={(event) => onChange(event, 'details')} />
                                                    <CpInput isDisabled={readOnly} register={register()} label={intl.formatMessage({ id: 'prefCarrier' })} name="carrier" value={data.carrier}
                                                        maxLength={2000} isRequired={false} type="text" onChange={(event) => onChange(event, 'details')} />
                                                    <CPMultiSelectBox isDisabled={readOnly} label={intl.formatMessage({ id: 'serviceRequest' })} name="serviceRequest" value={data.serviceRequest ?? []} options ={data.systemSerReqs ?? []} maxLength={500} status={data.status} onChange={(event)=> onChange(event, 'serviceRequest')}/>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                                    <CpTypeahead isDisabled={readOnly} isRequired={formFor === "bookingTemplate" ? false : !(data.bizType === "SE" || data.bizType === "AE" || data.bizType === "SI" || data.bizType === "AI")} label={intl.formatMessage({ id: 'orgCountry' })} name='orgCountry' options={countries} value={data.orgCountry} onChange={(event) => handleCountryChange(event, "orgCountry")} error={errordetails.orgCountry !== '' ? true : false} helperText={errordetails.orgCountry} />
                                                    <CpTypeahead isDisabled={readOnly} isRequired={formFor === "bookingTemplate" ? false : !(data.bizType === "SE" || data.bizType === "AE" || data.bizType === "SI" || data.bizType === "AI")} label={intl.formatMessage({ id: 'origin' })} name='orgCity' Ref={orgCityRef} options={data.origins} value={data.orgCity} onChange={(event) => handleTypeaheadChange(event, 'details', 'orgCity')} noOptionsMsg={data.orgCountry ? noOptionMsg.orgCity !== '' ? noOptionMsg.orgCity : '' : "Please select country"} showTitle={true} error={errordetails.orgCity !== '' ? true : false} helperText={errordetails.orgCity} />
                                                    {data.bizType === 'SE' || data.bizType === 'AE' || data.bizType === 'SI' || data.bizType === 'AI' ?
                                                        <>
                                                            <CpTypeahead isDisabled={readOnly} isRequired={false} label={data.bizType === 'SE' || data.bizType === 'SI' ? intl.formatMessage({ id: 'polCountrySea' }) : intl.formatMessage({ id: 'polCountryAir' })} name='polCountry' options={countries} Ref={polCountryRef} value={data.polCountry} onChange={(event) => handleCountryChange(event, "polCountry")} error={errordetails.polCountry !== '' ? true : false} helperText={errordetails.polCountry} />
                                                            <CpTypeahead isDisabled={readOnly} isRequired={false} label={data.bizType === 'SE' || data.bizType === 'SI' ? intl.formatMessage({ id: 'polCitySea' }) : intl.formatMessage({ id: 'polCityAir' })} name='polCity' Ref={polCityRef} options={data.polCities} value={data.polCity} onChange={(event) => handleTypeaheadChange(event, 'details', 'polCity')} noOptionsMsg={data.polCountry ? noOptionMsg.polCity !== '' ? noOptionMsg.polCity : '' : "Please select country"} showTitle={true} error={errordetails.polCity !== '' ? true : false} helperText={errordetails.polCity} />
                                                            <CpTypeahead isDisabled={readOnly} isRequired={false} label={data.bizType === 'SE' || data.bizType === 'SI' ? intl.formatMessage({ id: 'podCountrySea' }) : intl.formatMessage({ id: 'podCountryAir' })} name='podCountry' Ref={podCountryRef} value={data.podCountry} options={countries} onChange={(event) => handleCountryChange(event, "podCountry")} error={errordetails.podCountry !== '' ? true : false} helperText={errordetails.podCountry} />
                                                            <CpTypeahead isDisabled={readOnly} isRequired={false} label={data.bizType === 'SE' || data.bizType === 'SI' ? intl.formatMessage({ id: 'podCitySea' }) : intl.formatMessage({ id: 'podCityAir' })} name='podCity' Ref={podCityRef} value={data.podCity} options={data.podCities} onChange={(selected) => handleTypeaheadChange(selected, 'details', 'podCity')} noOptionsMsg={data.podCountry ? noOptionMsg.podCity !== '' ? noOptionMsg.podCity : '' : "Please select country"} error={errordetails.podCity !== '' ? true : false} helperText={errordetails.podCity} showTitle={true} />
                                                        </> : null
                                                    }
                                                    <CpTypeahead isDisabled={readOnly} isRequired={formFor === "bookingTemplate" ? false : (data.bizType === "SE" || data.bizType === "AE" || data.bizType === "SI" || data.bizType === "AI" ? false : true)} label={intl.formatMessage({ id: 'destCountry' })} name='destCountry' value={data.destCountry} options={countries} onChange={(event) => handleCountryChange(event, "destCountry")} error={errordetails.destCountry !== '' ? true : false} helperText={errordetails.destCountry} />
                                                    <CpTypeahead isDisabled={readOnly} isRequired={formFor === "bookingTemplate" ? false : (data.bizType === "SE" || data.bizType === "AE" || data.bizType === "SI" || data.bizType === "AI" ? false : true)} label={intl.formatMessage({ id: 'destinatoin' })} name='destCity' Ref={destCityRef} value={data.destCity} options={data.destinations} onChange={(selected) => handleTypeaheadChange(selected, 'details', 'destCity')} error={errordetails.destCity !== '' ? true : false} helperText={errordetails.destCity} noOptionsMsg={data.destCountry ? noOptionMsg.destCity !== '' ? noOptionMsg.destCity : '' : "Please select country"} showTitle={true} />
                                                    <CpDropDown canBlank={data.deliveryTerms && data.deliveryTerms.trim() !== '' ? true : null} isDisabled={readOnly} label={intl.formatMessage({ id: 'deliveryTerms' })} name="deliveryTerms" isRequired={false} value={data.deliveryTerms} items={deliveryTerms} onChange={(event) => onChange(event, 'details')} />
                                                    <CpTextarea isDisabled={readOnly} label={intl.formatMessage({ id: 'remarks' })} name='specialRqs' value={data.specialRqs} rowsMin={data.bizType === "AE" ? 3 : 3} rowsMax={data.bizType === "AE" ? 3 : 3}
                                                        maxLength={2000} onChange={(event) => onChange(event, 'details')} />
                                                    {cpSupUser === true ?
                                                        <>
                                                            <CpDateTime isDisabled={formFor === 'bookingTemplate' || readOnly} isRequired={formFor === "booking" ? true : false} label={intl.formatMessage({ id: 'CargoReadyDate' })} showTime={false}
                                                                dateName='readyDate' dateValue={data.readyDate} onDateChange={(value) => handleDateorTimeChange('readyDate', value)} error={errordetails.readyDate !== '' && cpSupUser && (formFor === "booking") ? true : false} helperText={errordetails.readyDate}
                                                            />
                                                            <CPTime isDisabled={formFor === 'bookingTemplate' || readOnly} isRequired={false} label={intl.formatMessage({ id: 'fromTo' })} helperText={errordetails.pickup_timeValidationErrorMessage}
                                                                timeName1='fromTime' timeValue1={data.fromTime} onTimeChange1={(fromValue, toValue) => handlePickupFromToTimings('fromTime', fromValue, toValue)} label1={intl.formatMessage({ id: 'from' })}
                                                                timeName2='toTime' timeValue2={data.toTime} onTimeChange2={(fromValue, toValue) => handlePickupFromToTimings('toTime', fromValue, toValue)} label2={intl.formatMessage({ id: 'to' })}
                                                            />

                                                            <CpDateTime isDisabled={formFor === 'bookingTemplate' || readOnly} isRequired={formFor === "booking" ? true : false} label={intl.formatMessage({ id: 'DestinationDate' })} showTime={false}
                                                                dateName='deliveryreadyDate' dateValue={data.deliveryreadyDate} onDateChange={(value) => handleDateorTimeChange('deliveryreadyDate', value)} error={errordetails.deliveryreadyDate !== '' && cpSupUser && (formFor === "booking") ? true : false} helperText={errordetails.deliveryreadyDate}

                                                            />
                                                            <CPTime isDisabled={formFor === 'bookingTemplate' || readOnly} isRequired={false} label={intl.formatMessage({ id: 'fromTo' })} helperText={errordetails.delivery_timeValidationErrorMessage}
                                                                timeName1='deliveryfromTime' timeValue1={data.deliveryfromTime} onTimeChange1={(fromValue, toValue) => handlePickupFromToTimings('deliveryfromTime', fromValue, toValue)} label1={intl.formatMessage({ id: 'from' })}
                                                                timeName2='deliverytoTime' timeValue2={data.deliverytoTime} onTimeChange2={(fromValue, toValue) => handlePickupFromToTimings('deliverytoTime', fromValue, toValue)} label2={intl.formatMessage({ id: 'to' })}
                                                            />
                                                        </>
                                                        : null
                                                    }

                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <div className={classes.pickupDeliveryGrid}>
                                        <CpButtonGroup label={intl.formatMessage({ id: 'pickupRequired' })} disabled={readOnly} name='pickupRequired' value={data.pickupRequired} onChange={(event, value) => handleToggleButtonChange(value, 'details', 'pickupRequired')} />
                                        {data.pickupRequired ?
                                            <>
                                                <Grid flex="true" container >
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <CpDropDown emptyMessage={getAddressTitle("collectFromObj", status, intl)} address={true} canBlank={true} isDisabled={readOnly} width="100%" label={intl.formatMessage({ id: 'collectFrom' })} name="collectFrom" onChange={(event) => onChange(event)} value={data.collectFromObj.uniqueAddrCode ? data.collectFromObj.uniqueAddrCode : 'none'} isRequired={false} items={pickupCollectAddress} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address1' })} maxLength={45} value={data.collectFromObj.addr1 ? data.collectFromObj.addr1 : ''} name="addr1" onChange={(event) => onChange(event, 'collectFrom')} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address2' })} maxLength={45} value={data.collectFromObj.addr2 ? data.collectFromObj.addr2 : ''} name="addr2" onChange={(event) => onChange(event, 'collectFrom')} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address3' })} maxLength={45} value={data.collectFromObj.addr3 ? data.collectFromObj.addr3 : ''} name="addr3" onChange={(event) => onChange(event, 'collectFrom')} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address4' })} maxLength={45} value={data.collectFromObj.addr4 ? data.collectFromObj.addr4 : ''} name="addr4" onChange={(event) => onChange(event, 'collectFrom')} />
                                                        <CpDropDown emptyMessage={getAddressTitle("pickupdelivertoObj", status, intl)} address={true} canBlank={true} isDisabled={readOnly} width="100%" label={intl.formatMessage({ id: 'delivery_to' })} name="pickupdeliverto" onChange={(event) => onChange(event)} value={data.pickupdelivertoObj.uniqueAddrCode ? data.pickupdelivertoObj.uniqueAddrCode : 'none'} isRequired={false} items={pickupDeliveryAddress} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address1' })} maxLength={45} value={data.pickupdelivertoObj.addr1 ? data.pickupdelivertoObj.addr1 : ''} name="addr1" onChange={(event) => onChange(event, 'pickupdeliverto')} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address2' })} maxLength={45} value={data.pickupdelivertoObj.addr2 ? data.pickupdelivertoObj.addr2 : ''} name="addr2" onChange={(event) => onChange(event, 'pickupdeliverto')} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address3' })} maxLength={45} value={data.pickupdelivertoObj.addr3 ? data.pickupdelivertoObj.addr3 : ''} name="addr3" onChange={(event) => onChange(event, 'pickupdeliverto')} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address4' })} maxLength={45} value={data.pickupdelivertoObj.addr4 ? data.pickupdelivertoObj.addr4 : ''} name="addr4" onChange={(event) => onChange(event, 'pickupdeliverto')} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        {cpSupUser === false ?
                                                            <>
                                                                <CpDateTime isDisabled={formFor === 'bookingTemplate' || readOnly} isRequired={false} label={intl.formatMessage({ id: 'collection_date' })} showTime={false}
                                                                    dateName='readyDate' dateValue={data.readyDate} onDateChange={(value) => handleDateorTimeChange('readyDate', value)} error={errordetails.readyDate !== ""? true : false} helperText={errordetails.readyDate}

                                                                />
                                                                <CPTime isDisabled={formFor === 'bookingTemplate' || readOnly} isRequired={false} label={intl.formatMessage({ id: 'fromTo' })} helperText={errordetails.pickup_timeValidationErrorMessage}
                                                                    timeName1='fromTime' timeValue1={data.fromTime} onTimeChange1={(fromValue, toValue) => handlePickupFromToTimings('fromTime', fromValue, toValue)} label1={intl.formatMessage({ id: 'from' })}
                                                                    timeName2='toTime' timeValue2={data.toTime} onTimeChange2={(fromValue, toValue) => handlePickupFromToTimings('toTime', fromValue, toValue)} label2={intl.formatMessage({ id: 'to' })}
                                                                />
                                                            </>
                                                            : null
                                                        }

                                                        <CpInput label={intl.formatMessage({ id: 'remarkHeader' })} name='pickupRemarks' maxLength={2000} value={data.pickupRemarks} onChange={(event) => onChange(event, 'details')} />
                                                    </Grid>
                                                </Grid>

                                            </>
                                            :
                                            null}
                                    </div>

                                    <div className={classes.pickupDeliveryGrid}>
                                        <CpButtonGroup label={intl.formatMessage({ id: 'deliveryRequired' })} disabled={readOnly} name='deliveryRequired' value={data.deliveryRequired} onChange={(event, value) => handleToggleButtonChange(value, 'details', 'deliveryRequired')} />
                                        {data.deliveryRequired ?
                                            <>
                                                <Grid flex="true" container >
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <CpDropDown emptyMessage={getAddressTitle("deliverycollectfromObj", status, intl)} address={true} canBlank={true} isDisabled={readOnly} width="100%" label={intl.formatMessage({ id: 'collectFrom' })} name="deliverycollectfrom" onChange={(event) => onChange(event)} value={data.deliverycollectfromObj.uniqueAddrCode ? data.deliverycollectfromObj.uniqueAddrCode : 'none'} isRequired={false} items={deliverCollectAddress} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address1' })} maxLength={45} value={data.deliverycollectfromObj.addr1 ? data.deliverycollectfromObj.addr1 : ''} name="addr1" onChange={(event) => onChange(event, 'deliverycollectfrom')} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address2' })} maxLength={45} value={data.deliverycollectfromObj.addr2 ? data.deliverycollectfromObj.addr2 : ''} name="addr2" onChange={(event) => onChange(event, 'deliverycollectfrom')} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address3' })} maxLength={45} value={data.deliverycollectfromObj.addr3 ? data.deliverycollectfromObj.addr3 : ''} name="addr3" onChange={(event) => onChange(event, 'deliverycollectfrom')} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address4' })} maxLength={45} value={data.deliverycollectfromObj.addr4 ? data.deliverycollectfromObj.addr4 : ''} name="addr4" onChange={(event) => onChange(event, 'deliverycollectfrom')} />
                                                        <CpDropDown emptyMessage={getAddressTitle("deliverydelivertoObj", status, intl)} address={true} canBlank={true} isDisabled={readOnly} width="100%" label={intl.formatMessage({ id: 'delivery_to' })} name="deliverydeliverto" onChange={(event) => onChange(event)} value={data.deliverydelivertoObj.uniqueAddrCode ? data.deliverydelivertoObj.uniqueAddrCode : 'none'} isRequired={false} items={deliverDeliveryAddress} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address1' })} maxLength={45} value={data.deliverydelivertoObj.addr1 ? data.deliverydelivertoObj.addr1 : ''} name="addr1" onChange={(event) => onChange(event, 'deliverydeliverto')} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address2' })} maxLength={45} value={data.deliverydelivertoObj.addr2 ? data.deliverydelivertoObj.addr2 : ''} name="addr2" onChange={(event) => onChange(event, 'deliverydeliverto')} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address3' })} maxLength={45} value={data.deliverydelivertoObj.addr3 ? data.deliverydelivertoObj.addr3 : ''} name="addr3" onChange={(event) => onChange(event, 'deliverydeliverto')} />
                                                        <CpInput label={' '} placeholder={intl.formatMessage({ id: 'address4' })} maxLength={45} value={data.deliverydelivertoObj.addr4 ? data.deliverydelivertoObj.addr4 : ''} name="addr4" onChange={(event) => onChange(event, 'deliverydeliverto')} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        {cpSupUser === false ?
                                                            <>
                                                                <CpDateTime isDisabled={formFor === 'bookingTemplate' || readOnly} isRequired={false} label={intl.formatMessage({ id: 'delivery_date' })} showTime={false}
                                                                    dateName='deliveryreadyDate' dateValue={data.deliveryreadyDate} onDateChange={(value) => handleDateorTimeChange('deliveryreadyDate', value)} error={errordetails.deliveryreadyDate !== ""? true : false} helperText={errordetails.deliveryreadyDate}

                                                                />
                                                                <CPTime isDisabled={formFor === 'bookingTemplate' || readOnly} isRequired={false} label={intl.formatMessage({ id: 'fromTo' })} helperText={errordetails.delivery_timeValidationErrorMessage}
                                                                    timeName1='deliveryfromTime' timeValue1={data.deliveryfromTime} onTimeChange1={(fromValue, toValue) => handlePickupFromToTimings('deliveryfromTime', fromValue, toValue)} label1={intl.formatMessage({ id: 'from' })}
                                                                    timeName2='deliverytoTime' timeValue2={data.deliverytoTime} onTimeChange2={(fromValue, toValue) => handlePickupFromToTimings('deliverytoTime', fromValue, toValue)} label2={intl.formatMessage({ id: 'to' })}
                                                                />
                                                            </>
                                                            : null
                                                        }
                                                        <CpInput label={intl.formatMessage({ id: 'remarkHeader' })} name='deliveryRemarks' maxLength={2000} value={data.deliveryRemarks} onChange={(event) => onChange(event, 'details')} />
                                                    </Grid>
                                                </Grid>
                                            </>
                                            :
                                            null}
                                    </div>
                                </>
                            }
                        </Grid >

                    }

                </ConnectForm >
            }
        </CPContextConsumer >)
}
export default injectIntl(withStyles(style)(DetailsForm))
import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import withError from '../../common/withError';
import withCPContext from '../../common/withCPContext';
import { Grid, Paper, withStyles } from '@material-ui/core';
import PieChart from '../../common/PieChart';
import DonutWidget from '../../common/DonutWidget';
import Loading from '../../common/Loading';
import CpEditableTable from '../../common/CpEditableTable';
import FunnelChart from '../../common/FunnelChart';

const ref = React.createRef();




const style = theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16)
        }
    },
    labelConfig: {
        fontSize: '18px',
        float: 'right',
        margin: '12px',
        cursor: 'pointer'

    },
    noDataDiv: {
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bolder'

    }
});





function DataToPdf(props) {

    const generatePieHeaders = (filter, groupBy) => {
        const exportWidgetHeadersPie = [
            { 'width': '30%', 'title': `${groupBy}`, 'field': 'name', 'showLabel': true, 'type': 'label', 'showBorder': true },
            { 'width': '40%', 'title': `${filter ? filter : ''} Count`, 'field': 'value', 'showLabel': true, 'type': 'label', 'showBorder': true },
        ]

        return exportWidgetHeadersPie
    }
    const getChart = (chartType, element, data, index) => {

        const { loading } = props
        switch (chartType) {
            case 'pie':
                return (
                    <>
                        <span style={{ fontSize: '14px', margin: '2%', fontWeight: '600' }} >{index + 1} .  {element.layouts[0].widgetName}</span>
                        <div >
                            <Grid item lg={8} xs={8} sm={8} md={8} xl={8} ref={ref} style={{ padding: '2%' }} >
                                <Paper variant="outlined" >

                                    <PieChart
                                        data={data.pieChartList ? data.pieChartList : []}
                                        loading={loading}
                                        noRefresh={true}
                                        noLegend={true} />
                                </Paper>

                                <CpEditableTable headers={generatePieHeaders(element.layouts[0].filterByVal, element.layouts[0].groupBy)} data={data && data.pieChartList ? data.pieChartList : []} />
                            </Grid>
                        </div>
                    </>
                )
            case 'donut':
                return (
                    data ?
                        <>
                            <span style={{ fontSize: '14px', margin: '2%', fontWeight: '600' }} >{index + 1} .  {element.layouts[0].widgetName}</span>
                            <div>
                                <Grid item lg={8} xs={8} sm={8} md={8} xl={8} ref={ref} style={{ padding: '2%' }} >

                                    <Paper variant="outlined" >
                                        <DonutWidget
                                            data={data && data.pieChartList ? data.pieChartList : []}
                                            loading={loading}
                                            noRefresh={true}
                                        />
                                    </Paper>

                                    <CpEditableTable headers={generatePieHeaders(element.layouts[0].filterByVal, element.layouts[0].groupBy)} data={data && data.pieChartList ? data.pieChartList : []} />
                                </Grid>
                            </div>



                        </> : null
                )
            case 'funnel':
                return (
                    data ?
                        <>
                            <span style={{ fontSize: '14px', margin: '2%', fontWeight: '600' }} >{index + 1} .  {element.layouts[0].widgetName}</span>
                            <div >
                                <Grid item lg={8} xs={8} sm={8} md={8} xl={8} ref={ref} style={{ padding: '2%' }} >

                                    <Paper variant="outlined" >
                                        <FunnelChart
                                            data={data.pieChartList ? data.pieChartList : []}
                                            loading={loading}
                                            noRefresh={true}
                                            showLabel={false} />
                                    </Paper>

                                    <CpEditableTable headers={generatePieHeaders(element.layouts[0].filterByVal, element.layouts[0].groupBy)} data={data && data.pieChartList ? data.pieChartList : []} />
                                </Grid>
                            </div>
                        </> : null
                )
            default:
                break;

        }
    }

    const { widgetDetails, loading, classes, noData } = props
    return (
        <>
            {noData ?
                <>
                    <Loading open={loading} />
                    <Grid container spacing={2} flex='true' direction="row" style={{ width: '100%', margin: '1% 0 0 0 ' }}>
                        <Grid item xl={8} lg={8}>
                            <Grid container spacing={2} flex='true' direction="row" alignItems="center">
                                <div className={classes.noDataDiv}>No Data Found</div>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                : <>
                    <Loading open={loading} />
                    <div>

                        {
                            widgetDetails.map((element, index) => {
                                return (element.layouts[0].widgetId === '11' ? <>{getChart(element.layouts[0].widgetType, element, element.layouts[0].widgetData, index)}</> : null)
                            })
                        }

                        {
                            widgetDetails.map((element, index) => {
                                return (element.layouts[0].widgetId === '12' ? <>{getChart(element.layouts[0].widgetType, element, element.layouts[0].widgetData, index)}</> : null)
                            })
                        }
                        {
                            widgetDetails.map((element, index) => {
                                return (element.layouts[0].widgetId === '21' ? <>{getChart(element.layouts[0].widgetType, element, element.layouts[0].widgetData, index)}</> : null)
                            })
                        }

                        {
                            widgetDetails.map((element, index) => {
                                return (element.layouts[0].widgetId === '22' ? <>{getChart(element.layouts[0].widgetType, element, element.layouts[0].widgetData, index)}</> : null)
                            })
                        }


                    </div>

                </>}

        </>)
}

export default injectIntl(withCPContext(withError(withRouter(withStyles(style)(DataToPdf)))));
import React from 'react'
import Table from '../../common/Table'
import { injectIntl } from "react-intl";
import { withStyles } from '@material-ui/core';
import MileStoneMobileView from './MileStoneMobileView';
import PageNumbers from '../../common/Pagination/PageNumbers';

const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    line: {
        margin: 0,
        display: 'flex'
    },
    title: {
        fontWeight: 'bold',
        width: '20%',
        display: 'flex',
        padding: '1%'
    },
    titleNormal: {
        fontWeight: 'Normal',
        width: '20%',
        display: 'flex',
        padding: '1%'
    },
    value: {
        width: '80%',
        display: 'inline-block',
        padding: '1%',
        fontWeight: 400
    },
    root: {
        minWidth: 275,
        width: '98%',
        margin: '1%'
    },
    cardRoot: {
        padding: '0 0 0 0 !important',
        marginBottom: '10px !important'
    },
    odd: {
        backgroundColor: 'rgba(237, 239, 239, 1)'
    },
    even: {
        backgroundColor: 'white'
    },
    recordCount: {
        marginLeft: '10px',
        //color: 'dimgrey',
        fontSize: '14px',
        fontFamily: 'sans-serif',
        fontWeight: 400,
        fontStyle: 'normal',
        color: '#545F66'
    }
})
function DetailedMileStoneData(props) {
    const { data, intl, classes, isPrint, printClasses, isCMUS, milestonesResponse, handlePageClick } = props;
    const milstoneColoumns = [
        { title: intl.formatMessage({ id: 'stage' }), field: "eventCode", width: '12%' },
        { title: intl.formatMessage({ id: 'description' }), field: "eventName", width: '17%' },
        { title: intl.formatMessage({ id: 'expectedDateTime' }), field: "estimatedDate", width: "20%" },
        { title: intl.formatMessage({ id: 'actualDateTime' }), field: "acutalDate", width: '20%' },
        { title: intl.formatMessage({ id: 'location' }), field: "location", width: '20%' },
        { title: intl.formatMessage({ id: 'notes' }), field: "remarks", width: '20%' }
    ]
    const milstoneCMUSColoumns = [
        { title: intl.formatMessage({ id: 'description' }), field: "tracingDateNoDesc", width: '50%' },
        { title: intl.formatMessage({ id: 'actualDateTime' }), field: "actualDateTime", width: "25%" },
        { title: intl.formatMessage({ id: 'updated_date_time' }), field: "updatedDateTime", width: '25%' }
    ]

    return (
        <>
            {isCMUS ?
                <div style={{ height: '30px', display: 'flex', alignItems: "center" }}> <span className={classes.recordCount}>{intl.formatMessage({ id: 'shipments_noOfRecords' })} : {data ? data.length : "0"}</span></div> : null
            }
            <div className={isPrint ? printClasses.sectionDesktopForPrint : classes.sectionDesktop}>
                <Table headers={isCMUS ? milstoneCMUSColoumns : milstoneColoumns} data={data} isPrint={isPrint} printClasses={printClasses} />
            </div>
            {isCMUS ? <div>
                <PageNumbers noOfPages={milestonesResponse.noOfPages} selected={milestonesResponse.selectedPage} onClick={handlePageClick} />
            </div> : null}
            <div className={isPrint ? printClasses.sectionMobileForPrint : classes.sectionMobile}>
                <MileStoneMobileView data={data} />
            </div>
        </>
    )
}

export default injectIntl(withStyles(style)(DetailedMileStoneData))

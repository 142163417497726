import React from 'react'
import { withStyles, TextField, useTheme, useMediaQuery } from '@material-ui/core'
import CPToolTip from './CPToolTip';

const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            width: '35%',
            fontWeight: 'bold',
            paddingTop: '2.5%',
            fontSize: '14px'
        }
    },
    inputWrapper: {
        width: '100%',
        display: 'inline-flex',
        padding: '0% 0 0 5%'
    },
    inputWrapper1: {
        width: '100%',
        display: 'inline-flex'
    },
    fieldLabel: {
        display: 'inline-block',
        width: '35%',
        fontWeight: 'bold',
        paddingTop: '2.5%',
        fontSize: '14px'
    },
    fieldContent: {
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: '60%'
        }
    },
    fieldContent1: {
        width: '33%',
        marginRight: '3%',
        [theme.breakpoints.up('md')]: {
            width: '23%',
            marginRight: '3%',
            marginTop: '<0.5%>'
        }
    },
    fieldContent2: {
        width: '55%',
        [theme.breakpoints.up('md')]: {
            width: '35%',
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '4.25%'
        }
    },
    fieldContentWOL: {
        width: '100%'
    },
    fieldContent1WOL: {
        width: '60%',
        marginRight: '3%'
    },
    fieldContent2WOL: {
        width: '37%'
    },
    required: {
        color: 'red',
        paddingTop: '2.5%',
        width: '2%'
    },
    Tooltip: {
        color: 'black'
    },
    input: {
        '&.MuiOutlinedInput-root': {
            '&	.MuiOutlinedInput-input': {
                display: 'block',
                lineHeight: 'calc(4rem - 4px)',
                minHeight: '2rem',
                padding: '0 1rem',
                position: 'relative',
                verticalAlign: 'middle',
                width: '100%',
                backgroundColor: '#FFFFFF',
                border: '2px solid #C8CFD2',
                borderRadius: '4px',
                color: '#4A4E50',
                transitionDuration: '100ms',
                transitionProperty: 'border-color, background-color, color, box-shadow',
                '&:focus': {
                    outlineWidth: '0',
                    borderColor: theme.palette.primary.main,
                    boxShadow: '0 0 0 3px #e3ecef'
                },
                '&focus-visible': {
                    outline: '-webkit-focus-ring-color auto 1px'
                }
            }
        }
    },
    notchedOutline: {

    },
    disabled: {

    }
})

function CpInput(props) {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const { width, register, id, classes, error, helperText, label, type, isRequired, children, name, InputProps, value, isDisabled, placeholder, showTitle, onPaste, onCopy } = props
    return (
        <div style={{ width: width }} className={label ? classes.inputWrapper : classes.inputWrapper1} >
            {label ?
                <>
                    <div className={classes.sectionDesktop}><label style={{fontWeight:500}}htmlFor={name}>{label}</label></div>
                    <div className={classes.required}>{isRequired ? <span>*</span> : ""}</div>
                </>
                : null}
            <CPToolTip title={showTitle === true ? value : ''} >
                <TextField inputRef={register}
                    id={id ? id : name}
                    label={isDesktop ? '' : label}
                    error={error}
                    helperText={helperText}
                    placeholder={placeholder}
                    variant="outlined"
                    disabled={isDisabled}
                    className={children ? (label ? classes.fieldContent1 : classes.fieldContent1WOL) : (label ? classes.fieldContent : classes.fieldContentWOL)}
                    name={name}
                    defaultValue={value !== null ? value : ''}
                    type={type}
                    InputProps={{ ...InputProps, classes: { root: classes.input, disabled: classes.disabled, notchedOutline: classes.notchedOutline } }}
                    onCopy={onCopy}
                    onPaste={onPaste}
                    onInput={(e) => {
                        if (e.target.value.length > 2) {
                            e.target.value = e.target.value.slice(0, 2)
                        }
                        if (e.target.value > 90) {
                            e.target.value = 90
                        }
                        if (e.target.value < 0) {
                            e.target.value = 0
                        }
                    }}
                    onKeyDown={e => {
                        if (e.keyCode === 13 && e.target.value) {
                            props.onChange(e);
                        }
                    }}
                    inputProps={{ maxLength: "2", min: 0, max: 90 }} />
            </CPToolTip>
            {children ?
                <div className={label ? classes.fieldContent2 : classes.fieldContent2WOL}>
                    {children}
                </div> : null}
        </div>
    )
}

export default withStyles(style)(CpInput)

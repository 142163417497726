import { Link, Typography } from "@material-ui/core";
import { injectIntl } from "react-intl";
import Box from "../../common/Box";
import Cards from "./Cards";
import CpEditableTable from "../../common/CpEditableTable";
import NonEditableDataGrid from "../../common/NonEditableDataGrid";
import CpBreadcrumb from "../../common/CpBreadcrumb";

function BillOfLading(props) {
    const mainSectionHeader = [
        { title: "MBL/MAWB No.", field: "mblMawbNo" },
        { title: "HBL/HAWB No.", field: "hblHawbNo" },
        { title: "Sub House Bill", field: "subHouseBill" },
        { title: "Previous IT No.", field: "prevItNo" },
        { title: "Quantity/UOM", field: "quantityUom" },
        { title: "Weight/UOM", field: "weightUom" }
    ]

    const ContainerHeaders = [
        { 'width': '15%', 'title': 'Container No.', 'field': 'noContainer', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Type', 'field': 'contEquipDescCode', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Seal 1', 'field': 'containerSeal1', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Seal 2', 'field': 'containerSeal2', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Marks and Numbers', 'field': 'marks', 'showLabel': true, 'type': 'label' }

    ]

    const { intl, handleDisplayBack, totalRecords, selectedRecord, handleDisplayForward, bolData } = props
    return (
        <div>
            <div style={{ display: 'flex', margin: '2rem 0 0 2rem' }}>
                <CpBreadcrumb >
                    <Link color='inherit' onClick={() => handleDisplayBack(0)}> {intl.formatMessage({ id: 'bills_Of_Lading' })} </Link>
                    <Typography color='textPrimary'>{intl.formatMessage({ id: 'bol' }, { val1: selectedRecord, val2: totalRecords })}</Typography>
                </CpBreadcrumb>
            </div>
            <Box showloading={true} header={intl.formatMessage({ id: 'bol' }, { val1: selectedRecord, val2: totalRecords })} >
                <NonEditableDataGrid list={mainSectionHeader} data={bolData ? bolData : {}} />
                <Cards isInbond={true} data={bolData && bolData.partyDetails ? bolData.partyDetails : []} />
            </Box>
            <Box header={intl.formatMessage({ id: 'containers' })}>
                <CpEditableTable headers={ContainerHeaders} readOnly={true} data={bolData && bolData.inBondContainersList ? bolData.inBondContainersList : []} navigateIcon={true} handleDisplayForward={(row, index) => handleDisplayForward(2, row, index)} />
            </Box>


        </div>
    );
}

export default injectIntl(BillOfLading)
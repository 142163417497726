import React, { useState } from 'react';
import { TenantConsumer } from '../../common/Tenant';
import { withRouter } from 'react-router-dom';
import CPButton from '../common/Button'
import CpInput from '../common/CpInput'
import { Card, CardHeader, CardContent, Box, withStyles, Grid, IconButton } from '@material-ui/core';
import { useForm } from "react-hook-form";
import { resetPassword } from '../../sevices/common/CpServices';
import { useIntl } from 'react-intl';
import withCPContext from '../common/withCPContext';
import { voidHandler, validatePasswordComplexity, getProgressBar } from '../../common/utils';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Captcha from './Captcha';

const style = {
  layout: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '5%'
  },
  logocontainer: {
    width: '20%',
    display: 'flex',
    justifyContent: 'start',
    margin: '10px'
  },
  maincontainer: {
    height: '100vh',
    margin: '0px'
  },
  inputs: {
    height: '3.1875em'
  },
  cardlayout: {
    width: '100%',
    margin: '2%',
    marginRight: '7%'
  },
  button: {
    fontSize: '15px',
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
      outline: 'none !important'
    }
  },
  div: {
    display: 'flex',
    marginTop: '10px',
    height: '20px'
  },
  title: {
    fontSize: '10px',
    height: '20px'
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}

function ResetPassword(props) {
  const [values, setValues] = React.useState({
    showPassword: false,
    progressBar: null
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const intl = useIntl();
  const redirectToLoginPage = () => {
    sessionStorage.removeItem('reset-token');
    props.history.push({
      pathname: `/${sessionStorage.getItem('tenant-url')}/login`,
      state: {
        msg: intl.formatMessage({ id: "passwordChangedmsg" }),
      },
    })
  };
  const onChange = (event) => {
    const validationResultFlags = validatePasswordComplexity(event.target.value)
    setValues({ ...values, progressBar: getProgressBar(validationResultFlags.pwdStrength, 0, 30, [10, 30, 50, 70]) });
    setPasswordComplexityFlags(validationResultFlags);
    error !== '' ? setError('') : voidHandler()
  }
  const handleResetPassword = (data) => {
    let isPassowrdComplextityMet = false;
    if (passwordComplexityFlags !== null) {
      if (passwordComplexityFlags.ucaseFlag && passwordComplexityFlags.lcaseFlag && passwordComplexityFlags.digitsFlag &&
        passwordComplexityFlags.splCharsFlag && passwordComplexityFlags.lengthFlag) {
        isPassowrdComplextityMet = true;
      }
    }
    if (isPassowrdComplextityMet) {
      if (data.confirmPassword === data.newPassword) {
        if (captchRef.current) {
          if (captchRef.current.validateCaptcha()) {
            invokeResetPassword(data);
          }
          else {
            setError('');
          }
        }
      }
      else {
        setError(intl.formatMessage({ id: 'passwordMismatch' }));
      }
    } else {
      setError(intl.formatMessage({ id: 'passwordCriteriaNotMet' }));
    }

  }
  const invokeResetPassword = async (data) => {
    const reqBody = {
      "resetPasswordToken": sessionStorage.getItem('reset-token'),
      "newPassword": data.newPassword
    }
    const res = await resetPassword(reqBody);
    if (res !== null && res !== undefined) {
      if (res.status === 200) {
        redirectToLoginPage();
      }
      else {
        if (res.data && res.data.fieldErrors && res.data.fieldErrors.length > 0) {
          if (res.data.fieldErrors[0].trim().includes("Reset Password Token is Expired")) {
            props.history.push({
              pathname: `/${sessionStorage.getItem('tenant-url')}/login`,
              state: {
                isForgotPassword: true,
                isTokenExpiryMsg: intl.formatMessage({ id: 'reset_token_expiry' })
              }
            })
          } else {
            setError(res.data.fieldErrors[0]);
          }
        }
        else if (res.data.code === '11003') {
          setError(intl.formatMessage({ id: 'routingErrorMsg' }));
        }
        else {
          const message = (res.data && res.data.code === '22001') ? res.data.message : intl.formatMessage({ id: 'somethingWentWrong' });
          setError(message)
        }
        captchRef.current.prepareCaptchaString();
      }
    }
  }
  const getRequiredIcon = (flag) => {
    return (
      flag === true ?
        <DoneOutlinedIcon style={{ color: '#40835F' }} className={classes.button} />
        :
        <CloseIcon color="error" className={classes.button} />
    )
  }

  const { register, handleSubmit, errors } = useForm();
  const { classes } = props;
  const [error, setError] = useState('')
  const [passwordComplexityFlags, setPasswordComplexityFlags] = useState(null);
  const captchRef = React.useRef(null);
  return (
    <TenantConsumer>
      {value =>
        <div className={`container-fluid ${classes.maincontainer}`}
          style={{ backgroundImage: "url(/secure-ui/themes/" + value.tenant + "/background.gif)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <div className={`${classes.logocontainer}`}>
            {<img src={`/secure-ui/themes/${value.tenant}/company.gif`} alt="product" style={{ width: '100%', objectFit: 'scale-down' }} />}
          </div>
          <Box className={`${classes.layout}`}>
            <Grid container flex='true' justify="flex-end">
              <Grid item lg={3} xl={3} md={4} sm={6} xs={12}>
                <Card className={classes.cardlayout} >
                  <CardHeader title={intl.formatMessage({ id: 'Reset_Password_Label' })} />
                  <CardContent>
                    <form key={1} onSubmit={handleSubmit(handleResetPassword)}>
                      <div className={classes.inputs}>
                        <CpInput
                          type={values.showPassword ? 'text' : 'password'}
                          register={register({
                            required: intl.formatMessage({ id: 'ErrorMessage_NewPwd_Required' }),
                          })}
                          placeholder={intl.formatMessage({ id: 'NewPassword' })}
                          name="newPassword"
                          error={errors.newPassword}
                          autoFocus={true}
                          maxLength={30}
                          helperText={errors.newPassword ? errors.newPassword.message : ""}
                          onChange={(event) => onChange(event)}
                          onPaste={(e) => { e.preventDefault(); return false; }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  outline='none'
                                  onClick={handleClickShowPassword}
                                >
                                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),

                          }}
                        />
                      </div>
                      <div style={{ marginBottom: '1rem' }}>
                        {values.progressBar}
                      </div>
                      <div className={classes.inputs}>
                        <CpInput
                          type="password"
                          register={register({ required: intl.formatMessage({ id: 'ErrorMessage_ConfirmPwd_Required' }) })}
                          placeholder={intl.formatMessage({ id: 'ConfirmPassword' })}
                          name="confirmPassword"
                          error={errors.confirmPassword}
                          helperText={errors.confirmPassword ? errors.confirmPassword.message : ""}
                          onPaste={(e) => { e.preventDefault(); return false; }}
                          onChange={() => { error !== '' ? setError('') : voidHandler() }}
                          maxLength={30}
                        />
                      </div>
                      {error !== '' || error !== undefined ? <span style={{ display: 'flex', color: 'red ' }} >{error}</span> : null}
                      <Captcha ref={captchRef} intl={intl} />
                      {passwordComplexityFlags !== null ?
                        <>
                          <div className={classes.div}>
                            {getRequiredIcon(passwordComplexityFlags.ucaseFlag)}
                            <span className={classes.title}>{intl.formatMessage({ id: 'password_ucase' })} </span>
                          </div>
                          <div className={classes.div}>
                            {getRequiredIcon(passwordComplexityFlags.lcaseFlag)}
                            <span className={classes.title}>{intl.formatMessage({ id: 'password_lcase' })} </span>
                          </div>
                          <div className={classes.div}>
                            {getRequiredIcon(passwordComplexityFlags.digitsFlag)}
                            <span className={classes.title}>{intl.formatMessage({ id: 'password_digits' })} </span>
                          </div>
                          <div className={classes.div}>
                            {getRequiredIcon(passwordComplexityFlags.lengthFlag)}
                            <span className={classes.title}>{intl.formatMessage({ id: 'password_length' })} </span>
                          </div>
                          <div className={classes.div}>
                            {getRequiredIcon(passwordComplexityFlags.splCharsFlag)}
                            <span className={classes.title}>{intl.formatMessage({ id: 'password_spclChar' })} </span>
                          </div>
                        </>
                        : null
                      }
                      <div className={classes.buttonGroup}>
                        <CPButton styles={{ marginRight: '0' }} variant="primary" type="submit" id="submit" >{intl.formatMessage({ id: 'Reset_submit' })}</CPButton>
                      </div>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </div>
      }
    </TenantConsumer>
  );
}
export default withRouter(withCPContext(withStyles(style)(ResetPassword)));
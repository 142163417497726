import React from 'react'
import CpEditableTable from '../../common/CpEditableTable';
import { Link, Typography } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Box from '../../common/Box';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';
import CpBreadcrumb from '../../common/CpBreadcrumb';

function ConveyanceDetails(props) {

    const { convDetails, intl, handleDisplayBack, handleDisplayForward, totalRecords, selectedRecord } = props;

    const convHeaderList = [
        { title: "Admission Type", field: "admissionTypeDesc", tooltipField: 'admissionType' },
        { title: "Shipper", field: "conveyanceShipper" },
        { title: "Method of Transport", field: "mot" },
        { title: "Importing Carrier", field: "carrier" },
        { title: "Vessel Flag", field: "country" },
        { title: "Export Date", field: "exportDate" },
        { title: "Import Date", field: "dateImport" },
        { title: "Arrival Date", field: "dateArrival" },
        { title: "Split Shipment", field: "splitConveyance" },
        { title: "Port of Unlading", field: "portUnlading" },
        { title: "Conveyance Name", field: "conveyanceName" },
        { title: "Voyage/Flight", field: "ftzVoyageFlight" }
    ]
    const bolColumns = [
        { 'width': '20%', 'title': intl.formatMessage({ id: 'bol/awb' }), 'field': 'idSeqNo', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'houseBill' }), 'field': 'ftzHouseBill', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': intl.formatMessage({ id: 'quantity/uom' }), 'field': 'quantityUom', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': intl.formatMessage({ id: 'exportCountry' }), 'field': 'countryExport', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'containers' }), 'field': 'commaSeperatedContainers', 'showLabel': true, 'type': 'label', tooltipField: 'commaSeperatedContainers' }
    ]
    return (
        <div>
            <div style={{ display: 'flex', margin: '2rem 0 0 2rem' }}>
                <CpBreadcrumb >
                    <Link color='inherit' onClick={() => handleDisplayBack(0)}>{intl.formatMessage({ id: 'conveyances' })}</Link>
                    <Typography color='textPrimary'>{intl.formatMessage({ id: 'conveyanceSelection' }, { val1: selectedRecord, val2: totalRecords })}</Typography>
                </CpBreadcrumb>
            </div>
            <Box showloading={true} header={intl.formatMessage({ id: 'conveyanceSelection' }, { val1: selectedRecord, val2: totalRecords })}>
                <NonEditableDataGrid list={convHeaderList} data={convDetails.data ? convDetails.data[0] : {}} />
            </Box>
            <Box header={intl.formatMessage({ id: 'billsofLading' })}>
                <CpEditableTable headers={bolColumns} readOnly={true} data={convDetails.data[0].ftzShipmentIdList ? convDetails.data[0].ftzShipmentIdList : []} navigateIcon={true} handleDisplayForward={(row, index) => handleDisplayForward(2, row, index)} />
            </Box>


        </div>
    )
}

export default injectIntl(ConveyanceDetails)
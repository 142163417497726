import React from 'react'
import { withStyles, TextareaAutosize, InputLabel, useTheme, useMediaQuery } from '@material-ui/core'

const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            width: '35%',
            fontWeight: 'bold',
            fontSize: '14px'
        }
    },
    textAreaWrapper: {
        width: '100%',
        display: 'block',
        padding: '0',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',
            margin: '0.5rem 2px'
        }
    },
    textAreaWrapper1: {
        width: '100%',
        display: 'block',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            width: '100%',
            display: 'inline-flex',
            padding: '0% 0 0 5%',
            alignItems: 'center',
            margin: '2px'
        }
    },
    fieldContent: {
        display: 'block',
        lineHeight: 'calc(4rem - 4px)',
        minHeight: '3rem',
        padding: '0 1rem',
        position: 'relative',
        verticalAlign: 'middle',
        backgroundColor: '#FFFFFF',
        border: '2px solid #C8CFD2',
        borderRadius: '4px',
        color: '#4A4E50',
        transitionDuration: '100ms',
        transitionProperty: 'border-color, background-color, color, box-shadow',
        '&:focus': {
            outlineWidth: '0',
            borderColor: theme.palette.primary.main,
            boxShadow: '0 0 0 3px #e3ecef'
        },
        '&focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px'
        },
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: '56%'
        }
    },
    fieldContentWOL: {
        display: 'block',
        lineHeight: 'calc(4rem - 4px)',
        minHeight: '3rem',
        padding: '0 1rem',
        position: 'relative',
        verticalAlign: 'middle',
        backgroundColor: '#FFFFFF',
        border: '2px solid #C8CFD2',
        borderRadius: '4px',
        color: '#4A4E50',
        transitionDuration: '100ms',
        transitionProperty: 'border-color, background-color, color, box-shadow',
        '&:focus': {
            outlineWidth: '0',
            borderColor: theme.palette.primary.main,
            boxShadow: '0 0 0 3px #e3ecef'
        },
        '&focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px'
        },
        width: '100%'
    },
    required: {
        color: 'red',
        width: '2%'
    }
})
function CpTextarea(props) {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const { classes, isRequired, label, value, name, onChange, rowsMax, rowsMin, isDisabled, width, placeHolder, id, maxLength } = props
    return (
        <div style={{ width: width }} className={label ? classes.textAreaWrapper : classes.textAreaWrapper1}>
            {label ?
                <>
                    <div className={classes.sectionDesktop}><label style={{fontWeight:500}}htmlFor={name}>{label} {isRequired ? <span className={classes.required}>*</span> : ""}</label></div>
                </>
                : null}
            {isDesktop ? "" :
                <InputLabel style={{ width: '100%' }} variant="standard" id={name}>
                    {label}
                </InputLabel>}
            <TextareaAutosize placeholder={placeHolder ? placeHolder : ""} id={id ? id : name} disabled={isDisabled} className={label ? classes.fieldContent : classes.fieldContentWOL} variant="outlined" value={value} 
            minRows={rowsMin} maxRows={rowsMax} maxLength={maxLength}  name={name} onChange={(event) => onChange(event)} />
        </div>
    )
}

export default withStyles(style)(CpTextarea)

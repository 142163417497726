import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { IconButton, useTheme, withStyles } from '@material-ui/core';
import { ReactComponent as LoadingIcon } from '../../../src/images/loading.svg'
import RefreshIcon from '@material-ui/icons/Refresh';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CpInput3 from './CpInput3';
import { injectIntl, useIntl } from 'react-intl';

const style = {
    topDiv: {
        display: 'flex'
    },
    noDataDiv: {
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Open Sans Italic',
        fontStyle: 'italic'
    },
    pageNoButton: {
        width: '24px',
        height: '24px',
        fontSize: '12px !important',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        margin: '0 0.25%'
    },
    header: {
        marginTop: '10px',
        marginLeft: '50px',
        fontWeight: 'bold'
    },
    header1: {
        marginTop: '10px',
        fontWeight: '700'
    },
    refreshIcon: {
        marginTop: '10px',
        marginLeft: 'auto',
        cursor: 'pointer',
        marginRight: '8px',
        color: '#417F90'
    },
    loadingDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '25rem',
        flexDirection: 'column'
    }
}
function BarChart(props) {
    const { classes, heading, handleClick, name, loading, handleRefresh, xaxisData, yaxisData, showTypeahead, handleInputChange, noOfDays, selectedPage, noOfPages, handlePageClick, showRefreshIcon } = props;
    const intl = useIntl();
    const theme = useTheme()
    const primaryColor = theme.palette.primary.main
    const option = {
        animation: false,
        color: primaryColor,
        grid: {
            containLabel: false,
            left: '15%',
            top: '5%',
            bottom: '10%'
        },
        xAxis: {
            name: '',
            minInterval: '1',
            data: undefined
        },
        yAxis: {
            type: 'category',
            axisLabel: {
                inside: false,
                rotate: 30,
                margin: 2,
                fontSize: '10px',
                showMinLabel: true
            },
            data: yaxisData
        },
        tooltip: {
            show: true,
            trigger: 'item',
            appendToBody: true,
            formatter: '{b} : {c} ',
            borderColor: '#2C697A',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            padding: 15,
            textStyle: {
                color: '#4A4E50',
                fontFamily: 'Open Sans',
                fontSize: 14
            }
        },
        series: [
            {
                name: name,
                type: 'bar',
                data: xaxisData
            },
        ]
    };
    const onEvents = {
        click: (event) => handleClick(event),
    };
    return (
        <div>
            {loading ?
                <div className={classes.loadingDiv} >
                    <LoadingIcon />
                    <span>Loading...</span>
                </div> :
                <>
                    <div className={classes.topDiv} >
                        <span className={classes.header}>{heading}</span>
                        {showTypeahead ?
                            <>
                                <div style={{ marginLeft: '2%', marginRight: '2%', marginTop: '2%', width: '14%' }}>
                                    <CpInput3 name='noOfDays' value={noOfDays} onChange={(event) => handleInputChange(event)} type='number' />
                                </div>
                                <span className={classes.header1}>{intl.formatMessage({ id: 'search_shipments_search_daysAgo' })}</span>
                            </>
                            : null}
                        {showRefreshIcon ?
                            <RefreshIcon onClick={handleRefresh} fontSize="large" className={classes.refreshIcon} />
                            : null}
                    </div>
                    {
                        xaxisData && xaxisData.length > 0 ?
                            <div>
                                <ReactEcharts option={option} onEvents={onEvents} style={{ height: showTypeahead ? "176px" : "200px" }} />
                                {showTypeahead ?
                                    <div style={{
                                        display: 'flex', alignItems: 'center',
                                        justifyContent: 'center', height: '20px'
                                    }}>
                                        <IconButton id="next" className={classes.pageNoButton} disabled={selectedPage === 1 ? true : false} style={{ color: selectedPage === 1 ? '' : primaryColor, fontSize: '14px' }} onClick={() => handlePageClick ? handlePageClick(selectedPage - 1) : null} > <ChevronLeftIcon style={{ fontSize: '20px !important' }} /></IconButton>
                                        <IconButton id="previous" className={classes.pageNoButton} disabled={selectedPage === noOfPages ? true : false} style={{ color: selectedPage === noOfPages ? '' : primaryColor, fontSize: '14px' }} onClick={() => handlePageClick(selectedPage + 1)} > <ChevronRightIcon style={{ fontSize: '20px !important' }} /></IconButton>
                                    </div> : null
                                }
                            </div>
                            :
                            <div className={classes.noDataDiv}>
                                {intl.formatMessage({ id: 'no_data' })}
                            </div>
                    }
                </>
            }
        </div>
    )
}

export default injectIntl(withStyles(style)(BarChart))

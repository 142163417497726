import React from 'react'
import { injectIntl } from 'react-intl';
import withCPContext from '../common/withCPContext';
import { withStyles, Card, CardContent } from '@material-ui/core';
const style = theme => ({
    root: {
        minWidth: 275,
        width: '98%',
        margin: '1%',
    },
    cardRoot: {
        padding: '0 0 0 0 !important',
    },
    line: {
        margin: 0,
        display: 'flex'
    },
    titleNormal: {
        fontWeight: 'Normal',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important',

    },
    title: {
        fontWeight: 'Bold',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important',

    },
    value: {
        width: '50%',
        padding: '1%',
        display: 'inline-block',
        fontSize: '12px !important',
    },
    odd: {
        backgroundColor: 'rgba(237, 239, 239, 1)'
    },
    even: {
        backgroundColor: 'white'
    }
})
function RateCardMobileView(props) {
    const { data, classes, intl } = props;
    const getOddorEven = (index) => {
        return index % 2 === 0 ? "even" : "odd"
    }
    return (
        <Card className={classes.root} variant="outlined">
            {
                data && data.length > 0 ?
                    data.map((quote, index) => {
                        return (
                            <div key={index} style={{ display: 'flex' }}>
                                <CardContent style={{ width: '100%' }} className={`${classes.cardRoot} ${classes[getOddorEven(index)]}`}>
                                    <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                        <div className={classes.title}> <span >{quote.revChrgDesc}</span></div>
                                    </div>
                                    <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                        <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'rate' })}</span></div>
                                        <div className={classes.value}> <span >{`${quote.revRate}  ${quote.revCurrCode}`}</span></div>
                                    </div>
                                    <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                        <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'qty' })}</span></div>
                                        <div className={classes.value}> <span >{`${quote.revQuantity}  ${quote.revQuantityUnit}`}</span></div>
                                    </div>
                                    <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                        <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'amount' })}</span></div>
                                        <div className={classes.value}> <span >{`${quote.revAMTFC}  ${quote.revCurrCode}`}</span></div>
                                    </div>
                                </CardContent>
                            </div>)
                    }) :
                    <CardContent style={{ width: '100%', display: 'flex', justifyContent: 'center' }} >
                        <span>
                            {intl.formatMessage({ id: 'noRecordsFound' })}
                        </span>
                    </CardContent>
            }
        </Card >)
}

export default injectIntl(withCPContext(withStyles(style)(RateCardMobileView)))

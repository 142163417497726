import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl';
import CPToolTip from './CPToolTip';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CPButton from '../common/Button';



const style = theme => ({
    title: {
        fontWeight: '500'
    },
    value: {
        fontWeight: 'bold',
        fontSize: '13px',
        wordBreak: 'break-word'
    },
    commonValue: {
        overflowY: 'auto',
        maxHeight: '120px',
        border: '1px solid rgba(0,0,0,0.5)',
        padding: '2px',
    },
    container: {
        width: '100%',
        margin: '1% 0 0 0 ',
        backgroundColor: '#F9FBFB',
        border: '1px solid #C8CFD2',
        borderLeft: '0px',
        borderRight: '0px'
    },
    noRecordsFound: {
        minHeight: '8vh',
        fontStyle: 'italic',
        padding: '6px 24px 6px 16px',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Open Sans Italic'
    },
    heading: {
        fontSize: '18px',
        fontWeight: 'bold'
    },
    quoteContainer: {
        width: '100%',
        margin: '1% 0 0 0',
        borderLeft: '0px',
        borderRight: '0px',
        backgroundColor: '#F9FBFB',
        borderTop: '1px solid #C8CFD2'
    }
})
function NonEditableDataGrid(props) {
    const { list, data, intl, classes, heading, quoteView, showorHideExtraFields, detailedShipment, printShipment,isPrint } = props;
    return (
        <>
            {!detailedShipment ? (
                <Grid container spacing={2} flex={true} direction="row" className={quoteView ? classes.quoteContainer : classes.container}>
                    {heading && (
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className={classes.heading}>{heading}</div>
                        </Grid>
                    )}
                    {data && list ? (
                        list.map((item, index) => (
                            <Grid key={index} item xl={3} lg={3} md={3} sm={6} xs={6}>
                                <div className={classes.title}>
                                    <span>{item.title}</span>
                                </div>
                                <div className={`${classes.value} ${data && data[item.field]?.length > 205 && !isPrint ? classes.commonValue : ''}`}>
                                    <CPToolTip title={data[item.tooltipField] || ''}>
                                        <span>{data[item.field] || (data.pickupDeliveryInfo && data.pickupDeliveryInfo[item.field]) || ''}</span>
                                    </CPToolTip>
                                </div>
                                {item.extraFieldName && item.extraField && (
                                    <div>
                                        <CPButton variant="containerLess" styles={{ padding: '0rem 0' }} onClick={() => showorHideExtraFields(item.title)}>
                                            {item.showExtraField ? (
                                                <>
                                                    <RemoveCircleOutlineIcon />
                                                    <span style={{ marginLeft: '2px' }}>hide {item.extraFieldName}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <AddCircleOutlineOutlinedIcon />
                                                    <span style={{ marginLeft: '2px' }}>show {item.extraFieldName}</span>
                                                </>
                                            )}
                                        </CPButton>
                                        {item.showExtraField ? (
                                            <div className={classes.value}>
                                                <span>{data[item.extraField] || (
                                                    <div className={classes.noRecordsFound}>
                                                        <span>{intl.formatMessage({ id: 'noInformationFound' })}</span>
                                                    </div>
                                                )}</span>
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            </Grid>
                        ))
                    ) : (
                        <div className={classes.noRecordsFound}>
                            <span>{intl.formatMessage({ id: 'noInformationFound' })}</span>
                        </div>
                    )}
                </Grid>
            ) : (
                data && data.length > 0 ? (
                    <Grid container spacing={2} flex={true} direction="row" className={classes.container}>
                        {data.map((item, index) => (
                            <Grid key={index} item xl={3} lg={3} md={3} sm={6} xs={6}>
                                <div className={classes.title}>
                                    <span>{item.remarkTypeDesc}</span>
                                </div>
                                <div className={`${classes.value} ${(item?.remark.length > 205 && !printShipment) ? classes.commonValue : ''}`}>
                                    <span>{item.remark}</span>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <div className={classes.noRecordsFound}>
                        <span>{intl.formatMessage({ id: 'noRecordsFound' })}</span>
                    </div>
                )
            )}
        </>
    )
}

export default injectIntl(withStyles(style)(NonEditableDataGrid))

import React from 'react';
import CpEditableTable from '../../common/CpEditableTable';
import { injectIntl } from 'react-intl';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';
import Box from '../../common/Box';

function Header(props) {
    const { headerData, intl } = props;
    const mblColoumns = [
        { 'width': '50%', 'title': intl.formatMessage({ id: 'search_shipments_search_mbNo' }), 'field': "mblMawbNo", 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': intl.formatMessage({ id: 'search_shipments_search_hblNo' }), 'field': "hblHawbNo", 'showLabel': true, 'type': 'label' }
    ]
    const hblColoumns = [
        { 'width': '50%', 'title': intl.formatMessage({ id: 'search_shipments_search_hblNo' }), 'field': "hblHawbNo", 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': intl.formatMessage({ id: 'containerno' }), 'field': "containerNos", 'showLabel': true, 'type': 'label' }
    ]
    const containerColoumns = [
        { 'width': '15%', 'title': intl.formatMessage({ id: 'containerno' }), 'field': "containerNos", 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': intl.formatMessage({ id: 'inbound_no' }), 'field': "inBondNo", 'showLabel': true, 'type': 'label' },
        { 'width': '8%', 'title': intl.formatMessage({ id: 'type' }), 'field': "type", 'showLabel': true, 'type': 'label' },
        { 'width': '8%', 'title': intl.formatMessage({ id: 'seal' }), 'field': "seal", 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': intl.formatMessage({ id: 'pcs' }), 'field': "pieces", 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': intl.formatMessage({ id: 'weight' }), 'field': "weight", 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'container_dates' }), 'field': "containerDates", 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': intl.formatMessage({ id: 'search_shipments_search_hblNo' }), 'field': "hblHawbNos", 'showLabel': true, 'type': 'label' }
    ]
    const headerCoulumnList = [
        { title: "File No.", field: "fileNo" },
        { title: "Shipper", field: "shipper" },
        { title: "Consignee", field: "consignee" },
        { title: "Carrier", field: "carrier" },
        { title: "Mode of Transport", field: "methodOfTransport" },
        { title: "Port of Loading", field: "portOfLoading" },
        { title: "Origin", field: "countryOfOrigin" },
        { title: "Country of Export", field: "countryOfExport" },
        { title: "Port of Discharge", field: "portOfDischarge" },
        { title: "Port of Entry", field: "portOfEntry" },
        { title: "Description of Goods", field: "descriptionOfGoods" },
        { title: "Total Pieces", field: "totalPieces" },
        { title: "Total Weight", field: "totalWeight" },
        { title: "Entry No.", field: "entryNumber" },
        { title: "Vessel/Airline", field: "vesselAirline" },
        { title: "Voyage/Flight", field: "voyageFlight" },
        { title: "ISF Transaction No.", field: "isfTransactionNo" },
        { title: "Charges/Currency", field: "chargesCurrency" },
        { title: "Duty/Taxes/Fees", field: "dutyTaxesFees" },
        //CPOR-830 changes - Breakout of Duty/Taxes/Fees Amount
        { title: "Total Duty", field: "dutyTotAmt" },
        { title: "Total Taxes", field: "totalTaxes" },
        { title: "Total Fees", field: "feesTotAmt" },
        { title: "Total ADA", field: "totalAda" },
        { title: "Total CVD", field: "totalCvd" },
        { title: "Invoice Total/Currency", field: "invoiceTotalCurrency" },
        { title: "Exchange Rate", field: "exchangeRate" },
        { title: "Customs Value", field: "customsValue" },
        { title: "Entry Type", field: "entryType" },
        { title: "Payment Type", field: "paymentType" },
        { title: "Payment Month", field: "paymentMonth" }]

    return (
        <div>
            <Box header={intl.formatMessage({ id: 'header' })}>
                <NonEditableDataGrid list={headerCoulumnList} data={headerData.header ? headerData.header : {}} />
            </Box>
            <Box header={intl.formatMessage({ id: 'search_shipments_search_mbNo' })}>
                <CpEditableTable readOnly={true} headers={mblColoumns} data={headerData.mblDetails ? headerData.mblDetails : []} />
            </Box>
            <Box header={intl.formatMessage({ id: 'search_shipments_search_hblNo' })}>
                <CpEditableTable readOnly={true} headers={hblColoumns} data={headerData.hblDetails ? headerData.hblDetails : []} />
            </Box>
            <Box header={intl.formatMessage({ id: 'ContainerDetail' })}>
                <CpEditableTable readOnly={true} headers={containerColoumns} data={headerData.containerDetails ? headerData.containerDetails : []} />
            </Box>
        </div >
    )
}

export default injectIntl(Header)
import React from 'react'
import Box from '../common/Box'
import DetailedMileStoneData from '../../components/ShipmentDetails/TMFF/DetailedMileStoneData'
import RouteDetails from '../../components/ShipmentDetails/TMFF/RouteDetails'
import ItemsTable from '../eBooking/ItemsTable';
import DocumentTable from '../common/DocumentTable';
import ContainersTable from '../ShipmentDetails/TMFF/ContainersTable'
import NonEditableDataGrid from './NonEditableDataGrid'

class PrintShipment extends React.Component {

    render() {

        const { trackingData, printClasses, headerDivToFocus, milstoneDivToFocus, containerDivToFocus,
            routeDivToFocus, remarkDivToFocus, itemDivToFocus, documentDivToFocus,
             docTypes, tenant, pickupDivToFocus, pickupDeliveryList, deliveryData, pickupData,cpSupUser,SupDeliveryList,supTrackingData,supHeaderTime} = this.props
        return (

            <div>
                {tenant.printLogo !== 'false' ?
                    <div style={{ paddingBottom: '30px' }}>
                        {/* for deployment or commit */}
                        <img className="img" src={`/secure-ui/themes/${tenant.tenant}/company.gif`} height='40px' width='160px' alt="Logo" style={{ marginTop: '2px', objectFit: 'scale-down' }} />
                        {/* for local devlopment */}
                        {/* <img className="img" src={require(`../../images/Dhl/company.gif`)} height='40px' width='160px' alt="Logo" style={{ marginTop: '2px' }} /> */}
                    </div>
                    :
                    null}
                {
                    trackingData === '' || trackingData === undefined || trackingData === null ?
                        <div className={printClasses.noRecordsDiv}>
                            <span>"No Data Found"</span>
                        </div>
                        :
                        <>
                            <div ref={headerDivToFocus}>
                                <Box header="header">
                                    <NonEditableDataGrid list={supTrackingData} data={supHeaderTime} isPrint={true} printClasses={printClasses} />
                                </Box>
                            </div>
                            <div ref={milstoneDivToFocus}>
                                <Box header="milestones">
                                    <DetailedMileStoneData data={trackingData.statusList} type="milestones" isPrint={true} printClasses={printClasses} />
                                </Box>
                            </div>
                            {trackingData.bizType !== 'Air Export' ?
                                <div ref={containerDivToFocus}>
                                    <Box header="containers">
                                        <ContainersTable data={trackingData.containers} handleRowClickContainer={this.handleRowClickContainer} milstone={true} isPrint={true} printClasses={printClasses} />
                                    </Box>
                                </div>
                                :
                                null
                            }
                            <div ref={routeDivToFocus}>
                                <Box header="route">
                                    <RouteDetails data={trackingData && trackingData.jobRoutes ? trackingData.jobRoutes : null} isPrint={true} printClasses={printClasses} />
                                </Box>
                            </div>

                            <div ref={remarkDivToFocus}>
                                <Box header="remarks">
                                    <NonEditableDataGrid data={trackingData && trackingData.remarks ? trackingData.remarks : []} isPrint={true} printClasses={printClasses} detailedShipment={true} printShipment={true} />
                                </Box>
                            </div>
                            <div ref={itemDivToFocus}>
                                <Box header="Items">
                                    <ItemsTable data={trackingData && trackingData.cargoItems ? trackingData.cargoItems : null} readOnly={true} isPrint={true} printClasses={printClasses} />
                                </Box>
                            </div>
                            <div ref={pickupDivToFocus}>
                                <Box header="Pickup/Delivery">
                                    <NonEditableDataGrid list={cpSupUser ? SupDeliveryList :pickupDeliveryList} data={pickupData} heading="Pickup" />
                                    <NonEditableDataGrid list={cpSupUser ? SupDeliveryList :pickupDeliveryList} data={deliveryData} heading="Delivery" />
                                </Box>
                            </div>
                            <div ref={documentDivToFocus}>
                                <Box header="documents" showloading={true}>
                                    <DocumentTable data={trackingData.documents ? trackingData.documents : null} uploadRequired={false} docTypes={docTypes} readOnly={false} isPrint={true} printClasses={printClasses} />
                                </Box>
                            </div>

                        </>
                }

            </div>

        )
    }
}

export default PrintShipment
import React, { Component } from 'react'
import CPCollectiveContainer from '../universal/CPCollectiveContainer'

export class BookingTemplate extends Component {
    render() {
        return <CPCollectiveContainer {...this.props} formFor="bookingTemplate" />
    }
}

export default BookingTemplate


import axios from 'axios';
import { fetchUser } from '../../common/utils'
import {
    NEW_LOGIN_URL,
    MONITOR_SERCICE_URL, GET_THEME_DETAILS_URL,
    GET_THEMES_GATEWAY_DETAILS_URL, CHANGE_PASSWORD, FORGOT_PASSWORD, RESET_PASSWORD, LOGOUT_URL, METRIC_URL
} from './serviceURLs'
import { TMFFWidgetEvictCache } from '../TMFF/Services';
import { CMUSWidgetEvictCache } from '../CMUS/Services';
const customConfig = require(`../../config/custom-config.json`)
export const getHost = async () => {
    // forDeployment
    let basePath = window.location.protocol + '//' + window.location.host;
    basePath = basePath + '/secure-ui/themes/custom-config.json'

    return await axios.get(basePath).then(res => {
        window.$appConfig = { ...res.data }
        sessionStorage.setItem("custom-config", JSON.stringify(res.data))
        return res
    })

    // forLocal
    // let config = require(`../../config/custom-config.json`);
    // window.$appConfig = config
    // return config
}

export const getConfig = () => {
    const configData = sessionStorage.getItem('custom-config');
    const { ipAddress, port, gatewayport, lang, themePort, themeIpAddress, extAlbPort } = window.$appConfig ? window.$appConfig : (configData ? JSON.parse(configData) : customConfig)
    const config = {
        ipAddress: ipAddress,
        port: port,
        gatewayport: gatewayport,
        lang: lang,
        themePort: themePort,
        themeIpAddress: themeIpAddress,
        extAlbPort: extAlbPort

    }
    return config
}

export const getHeaders = () => {
    const headers = {
        "Authorization": "" + sessionStorage.getItem('Auth-Token'),
        "tenant": "" + sessionStorage.getItem('tenant'),
        "Accept": "application/json",
        'Content-Type': 'application/json',
    }
    return headers
}

export const getAuthorizationAndTenantAsHeaders = () => {
    const headers = {
        "Authorization": "" + sessionStorage.getItem('Auth-Token'),
        "tenant": "" + sessionStorage.getItem('tenant')
    }
    return headers
}

export const getHeadersWithUser = () => {
    const headers = {
        "Authorization": "" + sessionStorage.getItem('Auth-Token'),
        "tenant": "" + sessionStorage.getItem('tenant'),
        "Accept": "application/json",
        'Content-Type': 'application/json',
        "userName": sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : fetchUser()
    }
    return headers
}

export const logMetrics = async (reqBody) => {
    const { ipAddress, gatewayport } = getConfig()
    const headers = {
        'tenant': "" + sessionStorage.getItem('tenant'),
        "Authorization": "" + sessionStorage.getItem('Auth-Token'),
    }
    const URL = `${ipAddress}:${gatewayport}${METRIC_URL}${reqBody.service}`
    return axios.post(URL, reqBody, { 'headers': headers }).then(res => {
        return res
    }).catch(res => {
        logError(res, URL);
        return res
    });
}

export const fetchMetrics = async (groupBy, filterBy, filterByVal, dateDuration, widgetType, dataSource) => {
    const { ipAddress, gatewayport } = getConfig()
    let url = `${ipAddress}:${gatewayport}${METRIC_URL}${dataSource}/${groupBy}?filter=${filterBy}&value=${filterByVal}&dateFilter=${dateDuration}&widgetType=${widgetType}`;
    return axios.get(url, { 'headers': getHeaders() }).then(res => {
        return res
    }).catch(res => {
        logError(res, url);
    });

}

export const loginAPI = async (reqBody, history) => {
    const { ipAddress, gatewayport } = getConfig()
    const URL = `${ipAddress}:${gatewayport}${NEW_LOGIN_URL}`;
    const headers = {
        'Content-Type': 'application/json',
        'tenant': "" + sessionStorage.getItem('tenant'),
    }
    const res = await axios.post(`${URL}`, reqBody, { headers: headers });
    const {userDefaults,moduleAccess} = res.data.customerPortal;
    if (res.status === 200) {
        sessionStorage.setItem('Auth-Token', "Bearer " + res.data.authToken);
        const doAccess = res.data && res.data.preferences ? res.data.preferences.find((preference)=> preference.preferenceKey === 'DO_ACCESS') : {}
        const documentUpload = res.data && res.data.preferences ? res.data.preferences.find((preference)=> preference.preferenceKey === 'DOCUMENT_UPLOAD') : {}
        const billingAccess = res.data && res.data.preferences ? res.data.preferences.find((preference)=> preference.preferenceKey === 'BILLING_ACCESS') : {}
        const excludedEntryTypes = res.data && res.data.preferences ? res.data.preferences.find((preference)=> preference.preferenceKey === 'EXCLUDED_ENTRY_TYPES') : {}
        const shipmentAgeLimitDays = res.data && res.data.preferences ? res.data.preferences.find((preference)=> preference.preferenceKey === 'SHIPMENT_AGE_LIMIT_IN_DAYS') : {}
        sessionStorage.setItem('userName', reqBody.userId ? reqBody.userId : '')
        sessionStorage.setItem('userEmail', reqBody.email ? reqBody.email : '')
        sessionStorage.setItem('userType', res.data.userType ? res.data.userType : '');
        sessionStorage.setItem('isCpSupUser', res.data.customerPortal ? res.data.customerPortal.isCpSupUser : false);
        sessionStorage.setItem('isCPAdmin', res.data.customerPortal ? res.data.customerPortal.isCPAdmin : false);
        sessionStorage.setItem('isCustAdmin', res.data.customerPortal ? res.data.customerPortal.isCustAdmin : false);
        sessionStorage.setItem("forceChangePassword", res.data.forceChangePassword);
        sessionStorage.setItem('preferences',res.data.preferences);
        sessionStorage.setItem('excludedEntryTypes',excludedEntryTypes.preferenceValue )
        sessionStorage.setItem('shipmentAgeLimitNoOfDays',shipmentAgeLimitDays.preferenceValue )
        sessionStorage.setItem('doAccess',doAccess.preferenceValue)
        sessionStorage.setItem('documentUpload',documentUpload.preferenceValue)
        sessionStorage.setItem('billingAccess',billingAccess.preferenceValue)
        sessionStorage.setItem('shipmentAgeLimitDats',res.data && res.data.shipmentAgeLimitDays ? res.data.shipmentAgeLimitDays : '')
       
        if (moduleAccess && moduleAccess.length > 0) {
            moduleAccess.forEach(element => {
                if (element !== undefined && element.moduleCode) {
                    if (element.moduleCode === 'CP-QUOTE') {
                        sessionStorage.setItem('quotesAccess', element.accessible);
                    } else if (element.moduleCode === 'CP-BOOKING') {
                        sessionStorage.setItem('bookingAccess', element.accessible);
                    } else if (element.moduleCode === 'CP-TRACKANDTRACE') {
                        sessionStorage.setItem('tracktraceAccess', element.accessible);
                    } else if (element.moduleCode === 'CP-BOOKINGTEMPLATE') {
                        sessionStorage.setItem('templatesAccess', element.accessible);
                    } else if (element.moduleCode === 'CP-INLANDWIDGET') {
                        sessionStorage.setItem('inlandAccess', element.accessible);
                    }
                }
            });
        }
        if(userDefaults){
            sessionStorage.setItem("userDefaults",JSON.stringify(userDefaults));
        }
        checkLoginValidations();
        window.$signInError = ''
    }
    return res;
}

export const logError = (errorMessage, url) => {
    const user = sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : fetchUser();
    const { ipAddress, gatewayport } = getConfig()
    const logServiceURL = `${ipAddress}:${gatewayport}${MONITOR_SERCICE_URL}`
    const headers = {
        'Content-Type': 'application/json',
        'tenant': "" + sessionStorage.getItem('tenant'),
        'userName': user ? user : sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : "UNKNOWN"
    }
    const postBody = {
        "component": url,
        "message": errorMessage.message ? errorMessage.message : "null",
    }
    axios.post(`${logServiceURL}`, postBody, { 'headers': headers })
}

export const getThemeDetails = async () => {
    const { ipAddress, gatewayport, themeIpAddress, extAlbPort } = getConfig()
    const tenant = sessionStorage.getItem('tenant')
    let URL = `${themeIpAddress}:${extAlbPort}${GET_THEME_DETAILS_URL}${tenant}/${tenant}.json`;
    return axios.get(URL, { timeout: 1500 })
        .then(res => {
            return res.data
        }).catch(res => {
            logError(res, URL);
            URL = `${ipAddress}:${gatewayport}${GET_THEMES_GATEWAY_DETAILS_URL}${tenant}/${tenant}.json`;
            return axios.get(URL).then(res => {
                return res.data
            }).catch(res, URL)
        });
}

export const logout = (history, from, msg, baseSystem) => {
    const metricsReqBody = {
        "tenant": sessionStorage.getItem('tenant'),
        "userId": sessionStorage.getItem('userName'),
        "service": "login",
        "operation": "expireToken",
        "status": "SUCCESS"
    }
    logMetrics(metricsReqBody);
    baseSystem === "TMFF" ? TMFFWidgetEvictCache() : CMUSWidgetEvictCache()

    if (from === "changePassword") {
        history.push({
            pathname: `/${sessionStorage.getItem('tenant-url')}/login`,
            state: {
                msg: msg,
            }
        })
    }
    else {
        history.push(`/${sessionStorage.getItem('tenant-url')}/login`);
    }
    const { ipAddress, gatewayport } = getConfig()
    let URL = `${ipAddress}:${gatewayport}${LOGOUT_URL}`
    const headers = {
        "Authorization": "" + sessionStorage.getItem('Auth-Token'),
        "tenant": "" + sessionStorage.getItem('tenant')
    }
    const tenantValue = sessionStorage.getItem('tenant');
    const tenantUrl = sessionStorage.getItem('tenant-url')
    sessionStorage.clear();
    sessionStorage.setItem('tenant', tenantValue);
    sessionStorage.setItem('tenant-url', tenantUrl)
    return axios.post(URL, null, { 'headers': headers }).then(res => {
        return res
    }).catch(res => {
        logError(res, URL);
        return res
    });

}


export const changePassword = async (oldPass, newPass) => {
    const { ipAddress, gatewayport } = getConfig()
    const payloadForPass = {
        oldPassword: oldPass,
        newPassword: newPass
    }
    const URL = `${ipAddress}:${gatewayport}${CHANGE_PASSWORD}`
    return axios.post(URL, payloadForPass, { 'headers': getHeadersWithUser() }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    });
}

export const forgotPassword = async (reqBody) => {
    const { ipAddress, gatewayport } = getConfig()
    const headers = {
        'Content-Type': 'application/json',
        'tenant': "" + sessionStorage.getItem('tenant'),
    }
    const URL = `${ipAddress}:${gatewayport}${FORGOT_PASSWORD}`
    return axios.post(URL, reqBody, { headers: headers }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    });
}

export const resetPassword = async (reqBody) => {
    const { ipAddress, gatewayport } = getConfig()
    const headers = {
        'Content-Type': 'application/json',
        'tenant': "" + sessionStorage.getItem('tenant'),
    }
    const URL = `${ipAddress}:${gatewayport}${RESET_PASSWORD}`
    return axios.post(URL, reqBody, { headers: headers }).then(res => {
        return res
    }).catch(error => {
        logError(error, URL);
        return error.response
    });
}




function checkLoginValidations() {
    sessionStorage.setItem('allowPortal', false)
    sessionStorage.setItem('allowUM', false)
    if (sessionStorage.getItem('isCPAdmin') === 'true' && sessionStorage.getItem('isCustAdmin') === 'false' && (sessionStorage.getItem('userType') === 'I' || sessionStorage.getItem('userType') === '')) {
        sessionStorage.setItem('allowPortal', true)
        sessionStorage.setItem('allowUM', true)
    }
    else if (sessionStorage.getItem('isCPAdmin') === 'false' && (sessionStorage.getItem('userType') === 'ELSU' || sessionStorage.getItem('userType') === 'ELSP')) {
        if (sessionStorage.getItem('isCustAdmin') === 'true') {
            sessionStorage.setItem('allowPortal', true)
            sessionStorage.setItem('allowUM', true)
        } else {
            sessionStorage.setItem('allowPortal', true)
        }
    }
}

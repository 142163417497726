import React from 'react';

const defaultTenant = {
    primaryColor: "#417F90",
    secondaryColor: "#1a5178",
    redirectUrl: "",
    printLogo: true,
    accessLevels: {
        accessToBookings: true,
        accessToQuotes: true,
        accessToShipments: true,
        accessToBookingTemplates: true,
        accessToILWidget: true
    },
    baseSystem: "",
    captchaCount: 5
}

const { Provider: TenantProvider, Consumer: TenantConsumer } = React.createContext(defaultTenant);

export { TenantConsumer, TenantProvider, defaultTenant }
import React from 'react'
import { injectIntl } from 'react-intl';
import CpEditableTable from '../../common/CpEditableTable';
import { withStyles } from '@material-ui/core';
import PageNumbers from '../../common/Pagination/PageNumbers';
import withLoading from '../../common/withLoading';
import Box from '../../common/Box';

const styles = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
})
function BillsOfLading(props) {
    const bolheaders = [
        { 'width': '15%', 'title': 'MBL/MAWB No.', 'field': 'mblMawbNo', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': 'HBL/HAWB No.', 'field': 'hblHawbNo', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Sub House Bill', 'field': 'subHouseBill', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Previous IT No.', 'field': 'prevItNo', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Quantity/UOM', 'field': 'quantityUom', 'showLabel': true, 'type': 'label' },
        { 'width': '10%', 'title': 'Weight/UOM', 'field': 'weightUom', 'showLabel': true, 'type': 'label' }
    ]

    const { handleDisplayForward, bolData, noOfPages, selectedPage, handlePageClick, intl } = props
    return (
        <Box showloading={true} header={intl.formatMessage({ id: 'bills_Of_Lading' })}>
            <div>
                <CpEditableTable headers={bolheaders} readOnly={true} data={bolData ? bolData : []} navigateIcon={true} handleDisplayForward={(row, index) => handleDisplayForward(1, row, index)} />
            </div>
            <PageNumbers noOfPages={noOfPages} selected={selectedPage} onClick={(index) => handlePageClick(index, 0)} />
        </Box>
    )
}

export default injectIntl(withLoading(withStyles(styles)(BillsOfLading)))
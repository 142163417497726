import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { withStyles, Fab, IconButton, Link, Typography } from '@material-ui/core'
import withLoading from '../common/withLoading'
import { fetchQuotes } from '../../sevices/TMFF/Services'
import CardsTable from '../mobileComponents/CardsTable';
import ScrollTop from '../mobileComponents/ScrollTop';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import greyStopIcon from '../../images/greyStopIcon.png';
import yellowStopIcon from '../../images/yellowStopIcon.png';
import redStopIcon from '../../images/redStopIcon.png';
import greenStopIcon from '../../images/greenStopIcon.png';
import { getContrastColor, voidHandler } from '../../common/utils'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import CPCSVDownloader from '../common/CPCSVDownloader'
import { QuoteStatusMap } from '../../common/DropDownValues'
import HelpIcon from '@material-ui/icons/Help';
import CpBreadcrumb from '../common/CpBreadcrumb'

const style = ({ breakpoints, palette }) => ({
    containerDiv: {
        background: 'white',
        padding: '0 2%',
        overflow: 'auto'
    },
    heading: {
        background: 'white',
        height: '4rem',
        padding: '0.75%',
        display: 'flex'
    },
    underline: {
        '&:hover': {
            textDecoration: "underline !important",
            color: '#277AB5'
        }
    },
    gridText: {
        fontWeight: '700',
        fontStyle: 'normal',
        marginTop: '2%',
        marginBottom: '2%',
        height: '4rem',
        display: 'flex'
    },
    buttonWarpper: {
        padding: '1% 0',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#007ad9'
    },
    recordCount: {
        marginLeft: '10px',
        //color: 'dimgrey',
        fontSize: '14px',
        fontWeight: 400,
        fontStyle: 'normal',
        color: '#545F66'
    },
    tableWrapper: {
        border: '1px solid #E8F0F4 !important'
    },
    sectionDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        justifyContent: 'center',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    textColor: {
        color: '#4A4E50'
    },
    fabIcon: {
        backgroundColor: palette.primary.main,
        color: getContrastColor(palette.primary.main)
    },
    detailText: {
        paddingLeft: '0.75%',
        fontSize: '24px'
    },
    recordsText: {
        flexGrow: '1',
        paddingLeft: '0.75%',
        paddingTop: '0.65%',
        fontSize: '14px'
    }
})

const mobileHeaders = [
    { label: "Shipper", key: "shprName" },
    { label: "MOT", key: "bizType" },
    { label: "Origin", key: "porName" },
    { label: "Total Peices", key: "totPcs" },
    { label: "Total Weight", key: "totgWgt" },
    { label: "Total TEU", key: "teu" },
    { label: "Total Containers", key: "totCtnQty" },
    { label: "Destination", key: "destName" },
    { label: "Status", key: "status" },
    { label: "Quote No.", key: "quoteNo" },
    { label: "Booking No.", key: "bookingNo" },
    { label: "Shipment No.", key: "shipmentNo" },
    { label: "Expiration Date", key: "expirationDate" },
    { label: "Last Updated", key: "lastUpdatedDate" },
    { label: "Source", key: "source" },
    { label: "Created By", key: "createByName" },
]

class Quotes extends Component {
    constructor(props) {
        super(props);
        const { intl } = props;
        this.state = {
            selectedRow: null,
            heading: '',
            fromDashBoard: false,
            columnDefs: [
                { headerName: intl.formatMessage({ id: 'search_shipments_search_shipper' }), field: "shprName", floatingFilter: true, minWidth: 160, suppressMenu: true, tooltipField: "shprName" },
                { headerName: intl.formatMessage({ id: 'shipments_bizType' }), field: "bizType", floatingFilter: true, maxWidth: 90, suppressMenu: true },
                { headerName: intl.formatMessage({ id: 'origin' }), field: "porName", floatingFilter: true, suppressMenu: true, tooltipField: "porName" },
                {
                    headerName: intl.formatMessage({ id: 'total_pc' }), sortable: false, floatingFilter: false, maxWidth: 100, suppressMenu: true, tooltipValueGetter: (params) => (params.data.totPcs !== undefined ? params.data.totPcs + " " + params.data.totPcsUt : ""), valueGetter: function (params) {
                        return (params.data.totPcs !== undefined ? params.data.totPcs + " " + params.data.totPcsUt : "");
                    },
                },
                {
                    headerName: intl.formatMessage({ id: 'total_wt' }), sortable: false, floatingFilter: false, maxWidth: 100, suppressMenu: true, tooltipValueGetter: (params) => (params.data.totgWgt !== undefined ? params.data.totgWgt + " " + params.data.totgWgtUt : ""), valueGetter: function (params) {
                        return (params.data.totgWgt !== undefined ? params.data.totgWgt + " " + params.data.totgWgtUt : "");
                    },
                },
                { headerName: intl.formatMessage({ id: 'totalTeu' }), field: "teu", floatingFilter: false, suppressMenu: true, tooltipField: "teu" },
                { headerName: intl.formatMessage({ id: 'totalContainers' }), minWidth: 100, field: "totCtnQty", floatingFilter: false, suppressMenu: true, tooltipField: "totCtnQty" },
                { headerName: intl.formatMessage({ id: 'destination' }), minWidth: 110, field: "destName", floatingFilter: true, suppressMenu: true, tooltipField: "destName" },
                { headerName: intl.formatMessage({ id: 'status' }), minWidth: 120, field: "status", floatingFilter: true, suppressMenu: true, cellRenderer: this.StatusRender, tooltipField: "status" },
                { headerName: intl.formatMessage({ id: 'quoteNo' }), maxWidth: 100, field: "quoteNo", floatingFilter: true, suppressMenu: true, tooltipField: "quoteNo" },
                { headerName: intl.formatMessage({ id: 'booking_no' }), field: "bookingNo", floatingFilter: true, suppressMenu: true, tooltipField: "bookingNo" },
                { headerName: intl.formatMessage({ id: 'shipment_no' }), field: "shipmentNo", floatingFilter: false, suppressMenu: true, tooltipField: "shipmentNo" },
                { headerName: intl.formatMessage({ id: 'expiry_date' }), field: "expirationDate", floatingFilter: false, suppressMenu: true, valueFormatter: (params) => params.value, tooltipField: "expirationDate" },
                { headerName: intl.formatMessage({ id: 'last_updated' }), field: "lastUpdatedDate", floatingFilter: false, suppressMenu: true, valueFormatter: (params) => params.value, tooltipField: "lastUpdatedDate" },
                { headerName: intl.formatMessage({ id: 'source' }), field: "source", floatingFilter: true, suppressMenu: true, maxWidth: 80, tooltipField: "source" },
                { headerName: intl.formatMessage({ id: 'create_by' }), field: "createByName", floatingFilter: true, suppressMenu: true, maxWidth: 100, tooltipField: "createByName" }
            ],
            gridOptions: {
                components: {
                    statusRenderer: this.StatusRender
                }
            },
            mobileData: null,
            rowData: null,
            defaultColDef: {
                flex: 1,
                sortable: true,
                filter: true,
                floatingFilter: true,

            },
            icons: {
                filter: '<i class="fa ArrowDropDownIcon"/>'
            },
            nextPageIndex: 0,
            priorDays: 60,
            filteredData: []
        }
    }
    onGridReady = params => {
        this.gridApi = params.api;
    };
    selectedRow = async () => {
        const { handleRowClick } = this.props;
        let row = this.gridApi.getSelectedRows();
        if (row) {
            this.setState({
                selectedRow: row
            })
            handleRowClick(this.gridApi.getSelectedRows(), 'quotes', this.state.fromDashBoard);
        }
        else {
            handleRowClick(this.state.selectedRow, 'quotes', this.state.fromDashBoard);
        }
    }
    filterchange = async (event) => {
        const filteredData = event.api.rowModel.rowsToDisplay.map(row => {
            return row.data
        })
        this.setState({
            filteredData: this.processQuotesData(filteredData)
        })
    }
    onCardClick = async (id) => {
        const { handleRowClick } = this.props;
        handleRowClick([this.state.mobileData[id]], 'quotes', this.state.fromDashBoard);
    }
    processQuotesData = (data) => {
        const updatedData = data ?
            data.map(row => {
                return {
                    ...row,
                    totPcs: row.hasOwnProperty("totPcs") && row.hasOwnProperty("totPcsUt") ? (row.totPcs + ' ' + row.totPcsUt) : row.hasOwnProperty("totPcs") && row.totPcsUt === undefined ? row.totPcs : '',
                    totgWgt: row.hasOwnProperty("totgWgt") && row.hasOwnProperty("totgWgtUt") ? (row.totgWgt + ' ' + row.totgWgtUt) : row.hasOwnProperty("totgWgt") && row.totgWgtUt === undefined ? row.totgWgt : ''
                }
            }) :
            []
        return updatedData
    }
    componentDidMount = async () => {
        const { location } = this.props
        if (location.state) {
            let status = undefined;
            let data = undefined
            if (location.state.heading.status) {
                QuoteStatusMap.forEach(el => {
                    el.label === location.state.heading.status ? status = el.value : voidHandler()
                })
            }
            else {
                data = location.state.heading["Expiry Date"]
            }
            const fetchedQuotes = await fetchQuotes('', status, data);
            sessionStorage.setItem("fromDashBoard", JSON.stringify(location.state.fromDashBoard))
            sessionStorage.setItem("quotesHeading", JSON.stringify(location.state.heading))
            await this.setState({
                rowData: fetchedQuotes,
                filteredData: this.processQuotesData(fetchedQuotes),
                heading: location.state.heading,
                mobileData: this.processQuotesData(fetchedQuotes),
                fromDashBoard: location.state.fromDashBoard
            })
        }
        else if (sessionStorage.getItem("quotesHeading")) {
            const heading = JSON.parse(sessionStorage.getItem("quotesHeading"))
            let status = undefined;
            let data = undefined
            if (heading.status) {
                QuoteStatusMap.forEach(el => {
                    el.label === heading.status ? status = el.value : voidHandler()
                })
            }
            else {
                data = heading["Expiry Date"]
            }
            const fetchedQuotes = await fetchQuotes('', status, data);
            await this.setState({
                rowData: fetchedQuotes,
                filteredData: this.processQuotesData(fetchedQuotes),
                heading: JSON.parse(sessionStorage.getItem("quotesHeading")),
                mobileData: this.processQuotesData(fetchedQuotes),
                fromDashBoard: JSON.parse(sessionStorage.getItem("fromDashBoard"))
            })
        }
        else {
            const fetchedQuotes = await fetchQuotes();
            await this.setState({
                rowData: fetchedQuotes,
                filteredData: this.processQuotesData(fetchedQuotes),
                mobileData: await this.processQuotesData(fetchedQuotes)
            })
        }
    }
    handleClickMore = async () => {
        const { priorDays } = this.state
        const { loading } = this.props
        loading.setLoadingmsg(true)
        const fetchedQuotes = await fetchQuotes(priorDays);
        await this.setState({
            rowData: fetchedQuotes,
            filteredData: fetchedQuotes,
            mobileData: fetchedQuotes,
            priorDays: priorDays + 30
        })
        loading.setLoadingmsg(false)
    }
    StatusRender = (params) => {
        let element = document.createElement("span");
        let imageElement = ''
        const obj = {
            'In Progress': yellowStopIcon, "Awaiting Acceptance": yellowStopIcon,
            "Submitted": greyStopIcon, "Accepted": greyStopIcon,
            "Rejected": redStopIcon, "Cancelled": redStopIcon, 
            "Booked": greenStopIcon
        };
        if (params.value) {
            imageElement =obj[params.value] ? '<img width="16px" src="' + obj[params.value] + '" />' :'';
            element.innerHTML = imageElement + ' ' + params.value
        } else {
            element.innerHTML = params.value
        }
        return element;
    }

    render() {
        const { classes, backToHome, intl } = this.props
        const { columnDefs, rowData, defaultColDef, rowClassRules, priorDays, heading, mobileData, filteredData } = this.state
        return (

            <div className={classes.containerDiv}>
                <div className={classes.heading}>
                    <CpBreadcrumb>
                        <Link color='inherit' classes={{ underlineHover: classes.underline }} href="#" onClick={() => backToHome('quotes')}>{intl.formatMessage({ id: 'home' })}</Link>
                        <Typography color='textPrimary' >{intl.formatMessage({ id: 'quotes_header' })}</Typography>
                    </CpBreadcrumb>
                    <a href={`${window.location.protocol + '//' + window.location.host}/webHelp/cp-manage-quotes.html`} target="_blank" style={{ color: "inherit", flexGrow: '1' }} rel="noopener noreferrer">
                        <IconButton style={{ float: 'right', padding: '0px' }}
                            aria-label="account of current user"
                            aria-controls="primary-search-account-menu"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <HelpIcon style={{ fontSize: '28px', color: "#6E787D" }} />
                        </IconButton>
                    </a>
                </div>
                <div className={classes.gridText}>
                    <span className={classes.detailText}> {heading !== '' ? `${intl.formatMessage({ id: Object.keys(heading)[0] })} | ${heading[Object.keys(heading)[0]]}` : intl.formatMessage({ id: 'quotes_header' })}</span> <span className={classes.recordsText}>{rowData ? rowData.length : '0'} {intl.formatMessage({ id: 'records' })}</span>
                    {filteredData ? filteredData.length > 0 ? <CPCSVDownloader data={filteredData} headers={mobileHeaders} filename="quotes" style={{ float: 'right' }} /> : null : null}
                </div>
                <div className={classes.tableWrapper}>
                    {/* <div style={{ height: '30px', display: 'flex', alignItems: "center" }}> <span className={classes.recordCount}>{intl.formatMessage({ id: 'shipments_noOfRecords' })} : {rowData ? rowData.length : '0'}</span></div> */}
                    <div className={classes.sectionDesktop}>
                        <div
                            className="ag-theme-balham"
                            style={{ height: 360, width: '100%', backgroundColor: 'white' }} >
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={rowData}
                                defaultColDef={defaultColDef}
                                rowSelection={'single'}
                                onRowClicked={this.selectedRow}
                                rowClassRules={rowClassRules}
                                onGridReady={this.onGridReady}
                                headerHeight='48'
                                rowHeight='30'
                                floatingFiltersHeight='40'
                                onFilterChanged={this.filterchange}
                                gridOptions={this.state.gridOptions}
                                tooltipShowDelay={0}
                            />
                        </div>
                    </div>
                    <div className={classes.sectionMobile}>
                        <CardsTable data={mobileData} headers={mobileHeaders} onCardClick={this.onCardClick} />
                        <ScrollTop >
                            <Fab className={classes.fabIcon} size="medium" aria-label="scroll back to top">
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </div>
                    <div style={{ height: '30px', display: 'flex', alignItems: "center" }}>
                        {heading === '' ?
                            <span className={`${classes.recordCount} ${classes.textColor}`} style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={this.handleClickMore}>{intl.formatMessage({ id: 'clickToFetch' })} {priorDays} {intl.formatMessage({ id: 'daysData' })}</span>
                            : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(withStyles(style)(withLoading(Quotes)))

import moment from 'moment';
import React from 'react';
import CPSnackBar from '../components/common/CPSnackBar'


export const getContrastColor = (hexcolor) => {
    hexcolor = hexcolor.replace("#", "");
    let r = parseInt(hexcolor.substr(0, 2), 16);
    let g = parseInt(hexcolor.substr(2, 2), 16);
    let b = parseInt(hexcolor.substr(4, 2), 16);
    let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    let color = yiq >= 128 ? 'black' : 'white'
    return color;
}
export const processDate = (input, resultRequired) => {
    let tempdate = "";
    let dateTimeFormat = "DD MMMM YYYY HH:mm"
    let dateformat = "DD MMMM YYYY";
    if (input !== undefined) {
        if (resultRequired === 'dateTime') {
            tempdate = moment(input, 'YYYYMMDDHHmmSS').format(dateTimeFormat)
        }
        else if (resultRequired === 'YYYYMMDD') {
            tempdate = moment(input).format("YYYYMMDD")
        }
        else if (resultRequired === 'HHmm') {
            tempdate = moment(input, "HH:mm").format("HHmm")
        }
        else if (resultRequired === 'DD MMM') {
            tempdate = moment(input).format("DD MMM")
        }
        else if (resultRequired === 'HH:mm'){
            tempdate = moment(input,'HHmm').format("HH:mm")
        }
        else {
            tempdate = moment(input, "YYYYMMDD").format(dateformat)
        }
    }
    return tempdate !== "Invalid date" ? tempdate : " ";
}

export const getSnackBar = (message, open, severity, errorList,snackBarId) => {
    return (
        <CPSnackBar
            open={open}
            message={message}
            severity={severity}
            errorList={errorList}
            snackBarId={snackBarId}
        >
        </CPSnackBar>);
}
export const getStatusClass = (status) => {
    let statusClass = ""
    switch (status ? status.toUpperCase() : '') {
        case 'PICKUP':
            statusClass = " statusPickUp"
            break;
        case 'PICKED UP':
            statusClass = " statusPickUp"
            break;
        case 'ARRIVED':
            statusClass = " statusArrived"
            break;
        case 'DEPARTED':
            statusClass = " statusDeparted"
            break;
        case 'RELEASED':
            statusClass = " statusReleased"
            break;
        case 'DELAYED':
            statusClass = " statusDelayed"
            break;
        default:
            break;
    }

    return statusClass
}
export const updateProgress = (item) => {
    const { selectedItem } = item;
    const x = 0;
    let progressBars = {}
    if (selectedItem[x].data.desc === 'Picked up') {
        progressBars = [{ 'Pickup': '100' }]
    }
    else if (selectedItem[x].data.desc === 'Departed from DHL hub') {
        progressBars = {
            pickup: 100,
            departed: 100
        }

    }
    else if (selectedItem[x].data.desc === 'Arrived at DHL localition') {
        progressBars = [{ 'pickup': '100' },
        { 'departed': '100' },
        { 'arrived': '50' }]


    }
    else if (selectedItem[x].data.desc === 'Arrived at DHL localition') {
        progressBars = [
            { 'pickup': '100' },
            { 'departed': '100' },
            { 'arrived': '100' }]
    }
    else if (selectedItem[x].data.desc === 'Released to Customer') {

        progressBars = [
            { 'pickup': '100' },
            { 'departed': '100' },
            { 'arrived': '100' },
            { 'relased': '100' }
        ]
    }
    return progressBars;
}
export const generateBookingDelete = (unId) => {
    const bookingRequest = {
        "funcCode": 1,
        "unId": unId
    }
    return bookingRequest
}
export const generateShipmentRequest = (shipmentState) => {
    let updatedDocs = []
    shipmentState.documents.forEach(document => {
        let docList = document.actionCode === 'A' || document.actionCode === 'D' || document.actionCode === 'U';
        if (document.action && docList ) {
            updatedDocs = [...updatedDocs, {
                "docSNo": document.docSNo,
                "action": document.action,
                "docActionCode": document.actionCode,
                "externalId":document.externalID,
                "type": document.type,
                "name": document.fileName,
                "source":"CP",
                "description":document.description,
                "uploadBy": document.uploadBy,
                "refKey": `${shipmentState.trackingData.createJobUnId}|JOB|${document.docSNo}`,
                "fileData" : document.fileData,
            }]
        }
    })
    const shipmentRequest = {
        "jobDoc": {
            "jobDocInformation": {
                "unId": shipmentState.trackingData.createJobUnId,
                "docList": {
                    "doc": updatedDocs
                }
            }
        }
    }
    return shipmentRequest
}
const isAddreessEmpty = (address) => {
    return (address.addr1 === undefined && address.addr2 === undefined && address.addr3 === undefined && address.addr4 === undefined)
}

export const generateBookingRequest = (bookingState, type) => {
    let updatedDocs = bookingState.documents.filter(document => {
        return document.actionCode === 'A' || document.actionCode === 'D' || document.actionCode === 'U'
    })
    updatedDocs = updatedDocs.map(document => {
        return { ...document, uploadDate: undefined }
    })
    let updatedItems = bookingState.itemslist.filter(item => {
        return item.itemActionCode === 'A' || item.itemActionCode === 'D' || item.itemActionCode === 'U'
    })

    const shipperAddressFlag = isAddreessEmpty(bookingState.details.shipperAddress);
    const consigneeAddressFlag = isAddreessEmpty(bookingState.details.consigneeAddress);
    const notifyAddressFlag = isAddreessEmpty(bookingState.details.notifyAddress)
    const bookingRequest = {

        "unId": bookingState.unId,
        "shipper": bookingState.details.shipper,
        "shipperName": bookingState.details.shipperName,
        "consignee": bookingState.details.consignee,
        "consigneeName": bookingState.details.consigneeName,
        "notify": bookingState.details.notify,
        "notifyName" : bookingState.details.notifyName,
        "bizType": bookingState.details.bizType,
        "shipmentType": bookingState.details.shipmentType,
        "moveType": bookingState.details.moveType,
        "destCountry": bookingState.details.destCountry,
        "destCityName": bookingState.details.destCityName,
        "destCity": bookingState.details.destCity,
        "orgCountry": bookingState.details.orgCountry,
        "orgCity": bookingState.details.orgCity,
        "orgCityName": bookingState.details.orgCityName,
        "podCtry": bookingState.details.bizType === 'IL' ? '' : bookingState.details.podCountry,
        "podCity": bookingState.details.bizType === 'IL' ? '' : bookingState.details.podCity,
        "podName": bookingState.details.bizType === 'IL' ? '' : bookingState.details.podCityName,
        "polCtry": bookingState.details.bizType === 'IL' ? '' : bookingState.details.polCountry,
        "polCity": bookingState.details.bizType === 'IL' ? '' : bookingState.details.polCity,
        "polName": bookingState.details.bizType === 'IL' ? '' : bookingState.details.polCityName,
        "bkPcs": bookingState.details.totalPcs,
        "bkPcsUt": bookingState.details.totalPcsUt,
        "bkgWgt": bookingState.details.totalWgt,
        "bkgWgtUt": bookingState.details.totalWgtUt,
        "bkgVol": bookingState.details.totalVol,
        "bkgVolUt": bookingState.details.totalVolUt,
        "referenceNumber": (bookingState.details.backupRefNo === '' && bookingState.details.referenceNumber === '') ? undefined : bookingState.details.referenceNumber,
        "refCode": (bookingState.details.backupRefNo === '' && bookingState.details.referenceNumber === '') ? undefined : bookingState.details.refCode,
        //"desiredDelDate": null, // need to be revisited
        "bookStatus": type === 'save' ? 'D' : (type === 'submit' && bookingState.details.status === "Accepted") ? 'A' : 'P',//need to be revisited after new deployment
        "commodityCode": bookingState.details.commodityCode ?? "",
        "commodity": bookingState.details.commodity,
        //"serviceRequest" : bookingState.details?.serviceRequest.join(', ')  ?? "", 
        "serviceRequest" : (bookingState.details.status === "Submitted" || bookingState.details.status === "Accepted") ? (bookingState.details?.serList.join(', ') ?? "" ): (bookingState.details.serviceRequest.join(', ') ?? ""), 

        "deliveryTerms": bookingState.details.deliveryTerms,

        "shprAddr1": !shipperAddressFlag ? (bookingState.details.shipperAddress.addr1 ? bookingState.details.shipperAddress.addr1 : '') : bookingState.details.shipperDefaultAddress.hasOwnProperty('addr1') ? bookingState.details.shipperDefaultAddress.addr1 : '',
        "shprAddr2": !shipperAddressFlag ? (bookingState.details.shipperAddress.addr2 ? bookingState.details.shipperAddress.addr2 : '') : bookingState.details.shipperDefaultAddress.hasOwnProperty('addr2') ? bookingState.details.shipperDefaultAddress.addr2 : '',
        "shprAddr3": !shipperAddressFlag ? (bookingState.details.shipperAddress.addr3 ? bookingState.details.shipperAddress.addr3 : '') : bookingState.details.shipperDefaultAddress.hasOwnProperty('addr3') ? bookingState.details.shipperDefaultAddress.addr3 : '',
        "shprAddr4": !shipperAddressFlag ? (bookingState.details.shipperAddress.addr4 ? bookingState.details.shipperAddress.addr4 : '') : bookingState.details.shipperDefaultAddress.hasOwnProperty('addr4') ? bookingState.details.shipperDefaultAddress.addr4 : '',

        "csgnAddr1": !consigneeAddressFlag ? (bookingState.details.consigneeAddress.addr1 ? bookingState.details.consigneeAddress.addr1 : '') : bookingState.details.consigneeDefaultAddress.hasOwnProperty('addr1') ? bookingState.details.consigneeDefaultAddress.addr1 : '',
        "csgnAddr2": !consigneeAddressFlag ? (bookingState.details.consigneeAddress.addr2 ? bookingState.details.consigneeAddress.addr2 : '') : bookingState.details.consigneeDefaultAddress.hasOwnProperty('addr2') ? bookingState.details.consigneeDefaultAddress.addr2 : '',
        "csgnAddr3": !consigneeAddressFlag ? (bookingState.details.consigneeAddress.addr3 ? bookingState.details.consigneeAddress.addr3 : '') : bookingState.details.consigneeDefaultAddress.hasOwnProperty('addr3') ? bookingState.details.consigneeDefaultAddress.addr3 : '',
        "csgnAddr4": !consigneeAddressFlag ? (bookingState.details.consigneeAddress.addr4 ? bookingState.details.consigneeAddress.addr4 : '') : bookingState.details.consigneeDefaultAddress.hasOwnProperty('addr4') ? bookingState.details.consigneeDefaultAddress.addr4 : '',
        
        "ntfyAddr1": !notifyAddressFlag ? (bookingState.details.notifyAddress.addr1 ? bookingState.details.notifyAddress.addr1 : '') : bookingState.details.notifyDefaultAddress.hasOwnProperty('addr1') ? bookingState.details.notifyDefaultAddress.addr1 : '',
        "ntfyAddr2": !notifyAddressFlag ? (bookingState.details.notifyAddress.addr2 ? bookingState.details.notifyAddress.addr2 : '') : bookingState.details.notifyDefaultAddress.hasOwnProperty('addr2') ? bookingState.details.notifyDefaultAddress.addr2 : '',
        "ntfyAddr3": !notifyAddressFlag ? (bookingState.details.notifyAddress.addr3 ? bookingState.details.notifyAddress.addr3 : '') : bookingState.details.notifyDefaultAddress.hasOwnProperty('addr3') ? bookingState.details.notifyDefaultAddress.addr3 : '',
        "ntfyAddr4": !notifyAddressFlag ? (bookingState.details.notifyAddress.addr4 ? bookingState.details.notifyAddress.addr4 : '') : bookingState.details.notifyDefaultAddress.hasOwnProperty('addr4') ? bookingState.details.notifyDefaultAddress.addr4 : '',
        
        "pickupInfo":
        {
            "required": bookingState.details.pickupRequired ? 1 : 0,
            "readyDate": processDate(bookingState.details.readyDate, 'YYYYMMDD'),//from ui it should come in this pattern YYYYMMDD
            "fromTime": processDate(bookingState.details.fromTime, 'HHmm'),//HHmm
            "toTime": processDate(bookingState.details.toTime, 'HHmm'),
            "collectFrom": Object.keys(bookingState.details.collectFromObj).length !== 0 ? bookingState.details.collectFromObj : null,
            "deliverTo": Object.keys(bookingState.details.pickupdelivertoObj).length !== 0 ? bookingState.details.pickupdelivertoObj : null
        },
        "deliveryInfo":
        {
            "required": bookingState.details.deliveryRequired ? 1 : 0,
            "readyDate": processDate(bookingState.details.deliveryreadyDate, 'YYYYMMDD'),//from ui it should come in this pattern YYYYMMDD
            "fromTime": processDate(bookingState.details.deliveryfromTime, 'HHmm'),//HHmm
            "toTime": processDate(bookingState.details.deliverytoTime, 'HHmm'),
            "collectFrom": Object.keys(bookingState.details.deliverycollectfromObj).length !== 0 ? bookingState.details.deliverycollectfromObj : null,
            "deliverTo": Object.keys(bookingState.details.deliverydelivertoObj).length !== 0 ? bookingState.details.deliverydelivertoObj : null
        },
        "containers": (bookingState.details.bizType === "SE" || bookingState.details.bizType === "SI") ? bookingState.containerslist : undefined,
        "vehicles": bookingState.details.bizType === "IL" ? bookingState.vehiclesContainersList : undefined,
        "documents": updatedDocs,
        "cargoItems": updatedItems,
        "funcCode": bookingState.funcCode,
        "jobStageCode": bookingState.jobStageCode,
        "templateName": bookingState.templateName,
        "templateNo": bookingState.templateNo,
        "hblAwbNo": bookingState.details.hblAwbNo,
        //all types of remarks mapping
        "remarkList": [{
            "remarkType": !bookingState.unId && bookingState.details.specialRqs.trim() === "" ? "" : "CPRMKTYPE",
            "remarkText": bookingState.details.specialRqs.trim(),
            "remActionCode": bookingState.details.specialRqs.trim() === "" && bookingState.unId ? "D" : ""
        },
        {
            "remarkType": !bookingState.unId && bookingState.details.carrier.trim() === "" ? "" : "PC",
            "remarkText": bookingState.details.carrier.trim(),
            "remActionCode": bookingState.details.carrier.trim() === "" && bookingState.unId ? "D" : ""
        },
        {
            "remarkType": !bookingState.unId && bookingState.details.pickupRemarks.trim() === "" ? "" : "CPPURMK",
            "remarkText": bookingState.details.pickupRemarks.trim(),
            "remActionCode": bookingState.details.pickupRemarks.trim() === "" && bookingState.unId ? "D" : ""
        },
        {
            "remarkType": !bookingState.unId && bookingState.details.deliveryRemarks.trim() === "" ? "" : "CPDLRMK",
            "remarkText": bookingState.details.deliveryRemarks.trim(),
            "remActionCode": bookingState.details.deliveryRemarks.trim() === "" && bookingState.unId ? "D" : ""
        }],
        "isCpSupUser": bookingState.cpSupUser

    }
    return bookingRequest
}


export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let result = []
        reader.readAsDataURL(file);
        reader.onload = () => {
            result = reader.result.split(';')
            const base64Strings = result[1].split(',')
            resolve(base64Strings[1]);
        }
        reader.onerror = error => reject(error);
    });
}

export const fetchUser = () => {
    if (sessionStorage.getItem('Auth-Token')) {
        let base64Url = sessionStorage.getItem('Auth-Token').split('.')[1];
        if (base64Url) {

            let base64 = base64Url.replace('-', '+').replace('_', '/');
            let decodedData = JSON.parse(Buffer.from(base64, 'base64').toString('binary'));
            return decodedData.TOKEN_USER_ID;
        }
        else {
            return "CPUSER"
        }
    }

}


export const LightenDarkenColor = (color, percent) => {
    if (color) {
        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        var RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
        var GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
        var BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

        return "#" + RR + GG + BB;
    }
    return ''

}

export const generateColorGradiants = (color) => {
    let colorsArray = [color]
    let gradiantChange = 40;
    while (colorsArray.length < 5) {
        if (gradiantChange === 0) {
            gradiantChange = -20
        }
        colorsArray.push(LightenDarkenColor(color, gradiantChange));
        gradiantChange = gradiantChange - 20;

    }
    return colorsArray;
}
export const generateQuoteRequest = (quoteState, type) => {
    let updatedDocs = quoteState.documents.filter(document => {
        return document.actionCode === 'A' || document.actionCode === 'D' || document.actionCode === 'U'
    })
    updatedDocs = updatedDocs.map(document => {
        return { ...document, uploadDate: undefined }
    })
    let updatedItems = quoteState.itemslist.filter(item => {
        return item.itemActionCode === 'A' || item.itemActionCode === 'D' || item.itemActionCode === 'U'
    })


    const shipperAddressFlag = isAddreessEmpty(quoteState.details.shipperAddress);
    const consigneeAddressFlag = isAddreessEmpty(quoteState.details.consigneeAddress);
    const notifyAddressFlag = isAddreessEmpty(quoteState.details.notifyAddress)
    const quoteRequest = {
        "unId": quoteState.unId,
        "partyIdShpr": quoteState.details.shipper,
        "shprName": quoteState.details.shipperName,
        "partyIdCsgn": quoteState.details.consignee,
        "csgnName": quoteState.details.consigneeName,
        "partyIdNtfy": quoteState.details.notify,
        "ntfyName": quoteState.details.notifyName,
        "bizType": quoteState.details.bizType,
        "loadTerm": quoteState.details.shipmentType,
        "moveType": quoteState.details.moveType,
        "destCtry": quoteState.details.destCountry,
        "destName": quoteState.details.destCityName,
        "destCity": quoteState.details.destCity,
        "porCtry": quoteState.details.orgCountry,
        "porCity": quoteState.details.orgCity,
        "porName": quoteState.details.orgCityName,
        "podCtry": quoteState.details.podCountry,
        "podCity": quoteState.details.podCity,
        "podName": quoteState.details.podCityName,
        "polCtry": quoteState.details.polCountry,
        "polCity": quoteState.details.polCity,
        "polName": quoteState.details.polCityName,
        "totPcs": quoteState.details.totalPcs,
        "totPcsUt": quoteState.details.totalPcsUt,
        "totgWgt": quoteState.details.totalWgt,
        "totgWgtUt": quoteState.details.totalWgtUt,
        "totVol": quoteState.details.totalVol,
        "totVolUt": quoteState.details.totalVolUt,
        //"serviceRequest" : quoteState.details?.serviceRequest.join(', ')  ?? "", 
        "serviceRequest" : (quoteState.details.status === "Submitted" || quoteState.details.status === "Accepted" || quoteState.details.status === "Cancelled") ? (quoteState.details?.serList.join(', ') ?? "" ): (quoteState.details.serviceRequest.join(', ') ?? ""),
        "referenceNumber": (quoteState.details.backupRefNo === '' && quoteState.details.referenceNumber === '') ? undefined : quoteState.details.referenceNumber,
        "refCode": (quoteState.details.backupRefNo === '' && quoteState.details.referenceNumber === '') ? undefined : quoteState.details.refCode,
        //"quoteStatus": type === 'save' ? 'N' : 'D',//need to be revisited 
        "quoteStatus": type === 'save' ? 'N' : (type === 'submit' && quoteState.details.status === "Accepted") ? 'Q' : (type === 'submit' && quoteState.details.status === "Cancelled") ? 'C' : 'D',
        "commodity": quoteState.details.commodityCode ?? "",
        "comm": quoteState.details.commodity,
        "frtType": quoteState.details.deliveryTerms,
        "shprAddr1": !shipperAddressFlag ? (quoteState.details.shipperAddress.addr1 ? quoteState.details.shipperAddress.addr1 : '') : quoteState.details.shipperDefaultAddress.hasOwnProperty('addr1') ? quoteState.details.shipperDefaultAddress.addr1 : '',
        "shprAddr2": !shipperAddressFlag ? (quoteState.details.shipperAddress.addr2 ? quoteState.details.shipperAddress.addr2 : '') : quoteState.details.shipperDefaultAddress.hasOwnProperty('addr2') ? quoteState.details.shipperDefaultAddress.addr2 : '',
        "shprAddr3": !shipperAddressFlag ? (quoteState.details.shipperAddress.addr3 ? quoteState.details.shipperAddress.addr3 : '') : quoteState.details.shipperDefaultAddress.hasOwnProperty('addr3') ? quoteState.details.shipperDefaultAddress.addr3 : '',
        "shprAddr4": !shipperAddressFlag ? (quoteState.details.shipperAddress.addr4 ? quoteState.details.shipperAddress.addr4 : '') : quoteState.details.shipperDefaultAddress.hasOwnProperty('addr4') ? quoteState.details.shipperDefaultAddress.addr4 : '',

        "csgnAddr1": !consigneeAddressFlag ? (quoteState.details.consigneeAddress.addr1 ? quoteState.details.consigneeAddress.addr1 : '') : quoteState.details.consigneeDefaultAddress.hasOwnProperty('addr1') ? quoteState.details.consigneeDefaultAddress.addr1 : '',
        "csgnAddr2": !consigneeAddressFlag ? (quoteState.details.consigneeAddress.addr2 ? quoteState.details.consigneeAddress.addr2 : '') : quoteState.details.consigneeDefaultAddress.hasOwnProperty('addr2') ? quoteState.details.consigneeDefaultAddress.addr2 : '',
        "csgnAddr3": !consigneeAddressFlag ? (quoteState.details.consigneeAddress.addr3 ? quoteState.details.consigneeAddress.addr3 : '') : quoteState.details.consigneeDefaultAddress.hasOwnProperty('addr3') ? quoteState.details.consigneeDefaultAddress.addr3 : '',
        "csgnAddr4": !consigneeAddressFlag ? (quoteState.details.consigneeAddress.addr4 ? quoteState.details.consigneeAddress.addr4 : '') : quoteState.details.consigneeDefaultAddress.hasOwnProperty('addr4') ? quoteState.details.consigneeDefaultAddress.addr4 : '',
        
        "ntfyAddr1": !notifyAddressFlag ? (quoteState.details.notifyAddress.addr1 ? quoteState.details.notifyAddress.addr1 : '') : quoteState.details.notifyDefaultAddress.hasOwnProperty('addr1') ? quoteState.details.notifyDefaultAddress.addr1 : '',
        "ntfyAddr2": !notifyAddressFlag ? (quoteState.details.notifyAddress.addr2 ? quoteState.details.notifyAddress.addr2 : '') : quoteState.details.notifyDefaultAddress.hasOwnProperty('addr2') ? quoteState.details.notifyDefaultAddress.addr2 : '',
        "ntfyAddr3": !notifyAddressFlag ? (quoteState.details.notifyAddress.addr3 ? quoteState.details.notifyAddress.addr3 : '') : quoteState.details.notifyDefaultAddress.hasOwnProperty('addr3') ? quoteState.details.notifyDefaultAddress.addr3 : '',
        "ntfyAddr4": !notifyAddressFlag ? (quoteState.details.notifyAddress.addr4 ? quoteState.details.notifyAddress.addr4 : '') : quoteState.details.notifyDefaultAddress.hasOwnProperty('addr4') ? quoteState.details.notifyDefaultAddress.addr4 : '',
        "pickupInfo":
        {
            "required": quoteState.details.pickupRequired ? 1 : 0,
            "readyDate": processDate(quoteState.details.readyDate, 'YYYYMMDD'),
            "fromTime": processDate(quoteState.details.fromTime, 'HHmm'),
            "toTime": processDate(quoteState.details.toTime, 'HHmm'),
            "collectFrom": Object.keys(quoteState.details.collectFromObj).length !== 0 ? quoteState.details.collectFromObj : null,
            "deliverTo": Object.keys(quoteState.details.pickupdelivertoObj).length !== 0 ? quoteState.details.pickupdelivertoObj : null,
        },
        "deliveryInfo":
        {
            "required": quoteState.details.deliveryRequired ? 1 : 0,
            "readyDate": processDate(quoteState.details.deliveryreadyDate, 'YYYYMMDD'),
            "fromTime": processDate(quoteState.details.deliveryfromTime, 'HHmm'),
            "toTime": processDate(quoteState.details.deliverytoTime, 'HHmm'),
            "collectFrom": Object.keys(quoteState.details.deliverycollectfromObj).length !== 0 ? quoteState.details.deliverycollectfromObj : null,
            "deliverTo": Object.keys(quoteState.details.deliverydelivertoObj).length !== 0 ? quoteState.details.deliverydelivertoObj : null,
        },
        "containers": (quoteState.details.bizType === "SE" || quoteState.details.bizType === "SI") ? quoteState.containerslist : undefined,
        "vehicles": quoteState.details.bizType === "IL" ? quoteState.vehiclesContainersList : undefined,
        "revenueList": quoteState.revenueList,
        "documents": updatedDocs,
        "cargoItems": updatedItems,
        "funcCode": quoteState.funcCode,
        "jobStageCode": quoteState.jobStageCode,
        //All remarks data mapping
        "remarkList": [{
            "remarkType": !quoteState.unId && quoteState.details.specialRqs.trim() === "" ? "" : "CPRMKTYPE",
            "remarkText": quoteState.details.specialRqs.trim(),
            "remActionCode": quoteState.details.specialRqs.trim() === "" && quoteState.unId ? "D" : ""
        },
        {
            "remarkType": !quoteState.unId && quoteState.details.carrier.trim() === "" ? "" : "PC",
            "remarkText": quoteState.details.carrier.trim(),
            "remActionCode": quoteState.details.carrier.trim() === "" && quoteState.unId ? "D" : ""
        },
        {
            "remarkType": !quoteState.unId && quoteState.details.pickupRemarks.trim() === "" ? "" : "CPPURMK",
            "remarkText": quoteState.details.pickupRemarks.trim(),
            "remActionCode": quoteState.details.pickupRemarks.trim() === "" && quoteState.unId ? "D" : ""
        },
        {
            "remarkType": !quoteState.unId && quoteState.details.deliveryRemarks.trim() === "" ? "" : "CPDLRMK",
            "remarkText": quoteState.details.deliveryRemarks.trim(),
            "remActionCode": quoteState.details.deliveryRemarks.trim() === "" && quoteState.unId ? "D" : ""
        }],
        "isCpSupUser": quoteState.cpSupUser


    }
    return quoteRequest
}

export const generateQuoteDelete = (unId) => {
    const quoteRequest = {
        "funcCode": 1,
        "unId": unId,
        "quoteStatus": "C",
    }
    return quoteRequest
}
export const generateQuoteReject = (unId, reason) => {
    const quoteRequest = {
        "unId": unId,
        "funcCode": 5,
        "quoteStatus": "R",
        "remarkList": [{
            "remarkType": "CPREJRMK",
            "remarkText": reason
        }]
    }
    return quoteRequest
}
export const generateQuoteAccept = (unId) => {
    const quoteRequest = {
        "unId": unId,
        "funcCode": 5,
        "quoteStatus": "Q",
        "jobStageCode": "Q",
    }
    return quoteRequest
}
export const generateQuoteBook = (unId) => {
    const quoteRequest = {
        "unId": unId,
        "funcCode": 5,
        "quoteStatus": "Q",
        "jobStageCode": "B",
    }
    return quoteRequest
}
export const voidHandler = () => {

}
export const getFileFiltersData = async (fileFilters) => {
    const filterData = {
        fileSize: '',
        fileTypes: ''
    }
    let finalFilter = ''
    if (fileFilters !== null && fileFilters !== undefined && fileFilters.totalRecords >= 1) {
        let lookupItems = fileFilters.lookupItems;
        for (let i = 0; i < lookupItems.length; i++) {
            if (lookupItems[i] !== null && lookupItems[i] !== undefined) {
                if (lookupItems[i].ruleCode === 'CMCHECKFILESIZE') {
                    filterData.fileSize = lookupItems[i].value;
                }
                if (lookupItems[i].ruleCode === 'CMCHKFILEEXTENSION') {
                    let itemList = lookupItems[i].value.split(";")
                    let types = []
                    for (let j = 0; j < itemList.length; j++) {
                        if (itemList[j] !== null && itemList[j].includes("=")) {
                            let data = itemList[j].split("=");
                            data = data[1].split("|")
                            for (let k = 0; k < data.length; k++) {
                                types.push(data[k])
                            }
                        }
                    }
                    if (types !== null && types.length > 0) {
                        for (let j = 0; j < types.length; j++) {
                            finalFilter = finalFilter.concat(".").concat(types[j]);
                            if (j < types.length - 1) {
                                finalFilter = finalFilter.concat(",")
                            }
                        }
                    }
                }
            }
        }
    }
    const defaultTypes = '.pdf,.txt,.doc,.docx,.xls,.xlsx,.xlsm,.ppt,.csv,.odt,.eml,.emlx,.msg,.zip,.jpg,.jpeg,.png,.gif,.tif,.bmp,.ico'
    filterData.fileTypes = finalFilter === '' ? defaultTypes : finalFilter
    filterData.fileSize = filterData.fileSize === '' ? '10M' : filterData.fileSize
    return filterData;
}

export const getIsValidFileTypeandSize = async (file, fileSizeState, fileTypesState) => {
    const res = {
        invType: false,
        sizeExceded: false,
        isValid: true
    }
    if (file !== null && file !== undefined) {
        if (file.name.includes(".")) {
            let type = file.name.slice(file.name.lastIndexOf('.') + 1)
            if (type !== '' && !(fileTypesState.includes(type) || fileTypesState.includes(type.toLowerCase()))) {
                res.invType = true;
                res.isValid = false;
            }
        }
        if (res.invType !== true) {
            if (fileSizeState.includes("M")) {
                let filelength = fileSizeState.replace("M", "")
                filelength = parseFloat(fileSizeState)
                if (file.size / 1024 / 1024 > filelength) {
                    res.sizeExceded = true
                    res.isValid = false;
                }
            }
            else if (fileSizeState.includes("K")) {
                let filelength = fileSizeState.replace("K", "")
                filelength = parseInt(fileSizeState)
                if (file.size / 1024 >= filelength) {
                    res.sizeExceded = true
                    res.isValid = false;
                }
            }
        }
    }
    return res
}
export const generateBookingTemplateRequest = (templateState, type) => {
    let updatedItems = templateState.itemslist.filter(item => {
        return item.itemActionCode === 'A' || item.itemActionCode === 'D' || item.itemActionCode === 'U'
    })
    const shipperAddressFlag = isAddreessEmpty(templateState.details.shipperAddress);
    const consigneeAddressFlag = isAddreessEmpty(templateState.details.consigneeAddress);
    const notifyAddressFlag = isAddreessEmpty(templateState.details.notifyAddress);
    const templateRequest = {
        "unId": type === "Save as New" ? null : templateState.unId,
        "templateNo": type === "Save as New" ? null : templateState.templateNo,
        "templateName": templateState.templateName,
        "isPrivate": templateState.availablePublicly ? 0 : 1,
        "templateStatus": type === "Save as New" ? 'A' : templateState.templateStatus,
        "customerName": templateState.details.customerName,
        "customer": templateState.details.customer,
        "shipper": templateState.details.shipper,
        "shipperName": templateState.details.shipperName,
        "consignee": templateState.details.consignee,
        "consigneeName": templateState.details.consigneeName,
        "notify": templateState.details.notify,
        "notifyName": templateState.details.notifyName,
        "bizType": templateState.details.bizType,
        "shipmentType": templateState.details.shipmentType,
        "moveType": templateState.details.moveType,
        "destCountry": templateState.details.destCountry,
        "destCityName": templateState.details.destCityName,
        "destCity": templateState.details.destCity,
        "orgCountry": templateState.details.orgCountry,
        "orgCity": templateState.details.orgCity,
        "orgCityName": templateState.details.orgCityName,
        "podCtry": templateState.details.bizType === 'IL' ? '' : templateState.details.podCountry,
        "podCity": templateState.details.bizType === 'IL' ? '' : templateState.details.podCity,
        "podName": templateState.details.bizType === 'IL' ? '' : templateState.details.podCityName,
        "polCtry": templateState.details.bizType === 'IL' ? '' : templateState.details.polCountry,
        "polCity": templateState.details.bizType === 'IL' ? '' : templateState.details.polCity,
        "polName": templateState.details.bizType === 'IL' ? '' : templateState.details.polCityName,
        "bkPcs": templateState.details.totalPcs,
        "bkPcsUt": templateState.details.totalPcsUt,
        "bkgWgt": templateState.details.totalWgt,
        "bkgWgtUt": templateState.details.totalWgtUt,
        "bkgVol": templateState.details.totalVol,
        "bkgVolUt": templateState.details.totalVolUt,
        "serviceRequest" : templateState.details?.serviceRequest.join(', ')  ?? "", 
        "referenceNumber": (templateState.details.backupRefNo === '' && templateState.details.referenceNumber === '') ? undefined : templateState.details.referenceNumber,
        "refCode": (templateState.details.backupRefNo === '' && templateState.details.referenceNumber === '') ? undefined : templateState.details.refCode,
        "commodityCode":templateState.details.commodityCode ?? "",
        "commodity": templateState.details.commodity,
        "deliveryTerms": templateState.details.deliveryTerms,
        "shprAddr1": !shipperAddressFlag ? (templateState.details.shipperAddress.addr1 ? templateState.details.shipperAddress.addr1 : '') : templateState.details.shipperDefaultAddress.hasOwnProperty('addr1') ? templateState.details.shipperDefaultAddress.addr1 : '',
        "shprAddr2": !shipperAddressFlag ? (templateState.details.shipperAddress.addr2 ? templateState.details.shipperAddress.addr2 : '') : templateState.details.shipperDefaultAddress.hasOwnProperty('addr2') ? templateState.details.shipperDefaultAddress.addr2 : '',
        "shprAddr3": !shipperAddressFlag ? (templateState.details.shipperAddress.addr3 ? templateState.details.shipperAddress.addr3 : '') : templateState.details.shipperDefaultAddress.hasOwnProperty('addr3') ? templateState.details.shipperDefaultAddress.addr3 : '',
        "shprAddr4": !shipperAddressFlag ? (templateState.details.shipperAddress.addr4 ? templateState.details.shipperAddress.addr4 : '') : templateState.details.shipperDefaultAddress.hasOwnProperty('addr4') ? templateState.details.shipperDefaultAddress.addr4 : '',

        "csgnAddr1": !consigneeAddressFlag ? (templateState.details.consigneeAddress.addr1 ? templateState.details.consigneeAddress.addr1 : '') : templateState.details.consigneeDefaultAddress.hasOwnProperty('addr1') ? templateState.details.consigneeDefaultAddress.addr1 : '',
        "csgnAddr2": !consigneeAddressFlag ? (templateState.details.consigneeAddress.addr2 ? templateState.details.consigneeAddress.addr2 : '') : templateState.details.consigneeDefaultAddress.hasOwnProperty('addr2') ? templateState.details.consigneeDefaultAddress.addr2 : '',
        "csgnAddr3": !consigneeAddressFlag ? (templateState.details.consigneeAddress.addr3 ? templateState.details.consigneeAddress.addr3 : '') : templateState.details.consigneeDefaultAddress.hasOwnProperty('addr3') ? templateState.details.consigneeDefaultAddress.addr3 : '',
        "csgnAddr4": !consigneeAddressFlag ? (templateState.details.consigneeAddress.addr4 ? templateState.details.consigneeAddress.addr4 : '') : templateState.details.consigneeDefaultAddress.hasOwnProperty('addr4') ? templateState.details.consigneeDefaultAddress.addr4 : '',
        
        "ntfyAddr1": !notifyAddressFlag ? (templateState.details.notifyAddress.addr1 ? templateState.details.notifyAddress.addr1 : '') : templateState.details.notifyDefaultAddress.hasOwnProperty('addr1') ? templateState.details.notifyDefaultAddress.addr1 : '',
        "ntfyAddr2": !notifyAddressFlag ? (templateState.details.notifyAddress.addr2 ? templateState.details.notifyAddress.addr2 : '') : templateState.details.notifyDefaultAddress.hasOwnProperty('addr2') ? templateState.details.notifyDefaultAddress.addr2 : '',
        "ntfyAddr3": !notifyAddressFlag ? (templateState.details.notifyAddress.addr3 ? templateState.details.notifyAddress.addr3 : '') : templateState.details.notifyDefaultAddress.hasOwnProperty('addr3') ? templateState.details.notifyDefaultAddress.addr3 : '',
        "ntfyAddr4": !notifyAddressFlag ? (templateState.details.notifyAddress.addr4 ? templateState.details.notifyAddress.addr4 : '') : templateState.details.notifyDefaultAddress.hasOwnProperty('addr4') ? templateState.details.notifyDefaultAddress.addr4 : '',
        
        "pickupInfo":
        {
            "required": templateState.details.pickupRequired ? 1 : 0,
            "readyDate": processDate(templateState.details.readyDate, 'YYYYMMDD'),//from ui it should come in this pattern YYYYMMDD
            "fromTime": processDate(templateState.details.fromTime, 'HHmm'),//HHmm
            "toTime": processDate(templateState.details.toTime, 'HHmm'),
            "collectFrom": Object.keys(templateState.details.collectFromObj).length !== 0 ? templateState.details.collectFromObj : null,
            "deliverTo": Object.keys(templateState.details.pickupdelivertoObj).length !== 0 ? templateState.details.pickupdelivertoObj : null,
        },
        "deliveryInfo":
        {
            "required": templateState.details.deliveryRequired ? 1 : 0,
            "readyDate": processDate(templateState.details.deliveryreadyDate, 'YYYYMMDD'),//from ui it should come in this pattern YYYYMMDD
            "fromTime": processDate(templateState.details.deliveryfromTime, 'HHmm'),//HHmm
            "toTime": processDate(templateState.details.deliverytoTime, 'HHmm'),
            "collectFrom": Object.keys(templateState.details.deliverycollectfromObj).length !== 0 ? templateState.details.deliverycollectfromObj : null,
            "deliverTo": Object.keys(templateState.details.deliverydelivertoObj).length !== 0 ? templateState.details.deliverydelivertoObj : null,
        },
        "containers": (templateState.details.bizType === "SE" || templateState.details.bizType === "SI") ? templateState.containerslist : undefined,
        "vehicles": templateState.details.bizType === "IL" ? templateState.vehiclesContainersList : undefined,
        "cargoItems": updatedItems,
        "funcCode": type === "Save as New" ? 9 : templateState.funcCode,
        "jobStageCode": type === "Save as New" ? "T" : templateState.jobStageCode,
        //All remarks data mapping
        "remarkList": [{
            "remarkType": !templateState.unId && templateState.details.specialRqs.trim() === "" ? "" : "CPRMKTYPE",
            "remarkText": templateState.details.specialRqs.trim(),
            "remActionCode": templateState.details.specialRqs.trim() === "" && templateState.unId ? "D" : ""
        },
        {
            "remarkType": !templateState.unId && templateState.details.carrier.trim() === "" ? "" : "PC",
            "remarkText": templateState.details.carrier.trim(),
            "remActionCode": templateState.details.carrier.trim() === "" && templateState.unId ? "D" : ""
        },
        {
            "remarkType": !templateState.unId && templateState.details.pickupRemarks.trim() === "" ? "" : "CPPURMK",
            "remarkText": templateState.details.pickupRemarks.trim(),
            "remActionCode": templateState.details.pickupRemarks.trim() === "" && templateState.unId ? "D" : ""
        },
        {
            "remarkType": !templateState.unId && templateState.details.deliveryRemarks.trim() === "" ? "" : "CPDLRMK",
            "remarkText": templateState.details.deliveryRemarks.trim(),
            "remActionCode": templateState.details.deliveryRemarks.trim() === "" && templateState.unId ? "D" : ""
        }]
    }
    return templateRequest
}
export const vadlidateEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (regex.test(email)) {
        return true
    }
    else {
        return false
    }
}
export const validatePasswordComplexity = (password) => {
    var uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lowercase = "abcdefghijklmnopqrstuvwxyz";
    var digits = "0123456789";
    var splChars = "!@#$%^&*()";

    const ucaseStrength = (contains(password, uppercase)) ? 1 : 0;
    const lcaseStrength = (contains(password, lowercase)) ? 1 : 0;
    const digitsStrength = (contains(password, digits)) ? 1 : 0;
    const splCharStrength = (contains(password, splChars)) ? 1 : 0;
    const defaultLengthStrenth = (password.length >= 8 && password.length <= 30) ? 1 : 0;
    const lengthStrength = ((password.length >= 12)) ? 1 : 0;

    return {
        "ucaseFlag": contains(password, uppercase),
        "lcaseFlag": contains(password, lowercase),
        "digitsFlag": contains(password, digits),
        "splCharsFlag": contains(password, splChars),
        "lengthFlag": (password.length >= 8 && password.length <= 30) ? true : false,
        "pwdStrength": (ucaseStrength + lcaseStrength + digitsStrength + splCharStrength + defaultLengthStrenth + lengthStrength)
    }
}
function contains(password, allowedChars) {
    for (let i = 0; i < password.length; i++) {
        var char = password.charAt(i);
        if (allowedChars.indexOf(char) >= 0) {
            return true;
        }
    }
    return false;
}
export const getProgressBar = (strength, marginLeft, width, increment) => {
    if (strength === 1) {
        return (<div style={{ width: width + '%', textAlign: 'center', fontSize: '8px !important', marginLeft: marginLeft, color: "white", backgroundColor: '#DAF7A6' }}>very weak</div>)

    } else if (strength === 2) {
        return (<div style={{ width: (width + increment[0]) + '%', textAlign: 'center', fontSize: '8px !important', marginLeft: marginLeft, color: "white", backgroundColor: '#FF5733' }}>weak</div>)

    } else if (strength >= 3 && strength < 5) {
        return (<div style={{ width: (width + increment[1]) + '%', textAlign: 'center', marginLeft: marginLeft, fontSize: '8px !important', color: "white", backgroundColor: '#C70039' }}>can be better</div>)
    }
    else if (strength === 5) {
        return (<div style={{ width: (width + increment[2]) + '%', textAlign: 'center', marginLeft: marginLeft, fontSize: '8px !important', color: "white", backgroundColor: '#75CA84' }}>strong</div>)
    }
    else if (strength === 6) {
        return (<div style={{ width: (width + increment[3]) + '%', textAlign: 'center', marginLeft: marginLeft, color: "white", fontSize: '8px !important', backgroundColor: '#278939' }}>very strong</div>)
    }
}
export const generateTemplateEnableorDisableRequest = (templateState) => {
    const templateRequest = {
        "unId": templateState.unId,
        "templateStatus": templateState.templateStatus === 'A' ? 'I' : 'A',
        "funcCode": 5,
        "bizType": templateState.details.bizType,
        "isPrivate": templateState.availablePubliclyPreviousState ? 0 : 1
    }
    return templateRequest

}
export const generateDocSaveRequest = (shipmentState, fileNo) => {
    let updatedDocs = []
    shipmentState.documentList.forEach(document => {
        if (document.action && document.action === 'new') {
            updatedDocs.push({
                type: 'IE',
                documentType: document.documentType,
                description: (document.description && document.description.label ? document.description.label : undefined),
                refNo: fileNo,
                fileName: document.fileName,
                documentTypeCode: (document.description && document.description.value ? document.description.value : undefined),
                documentData: document.fileData
            })
        }
    })
    return { "documentList": updatedDocs }
}
import React from 'react'
import { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import ConcurPPTArrivalList from './ConcurPPTArrivalList';
import ConcurPPTArrivalDetails from './ConcurPPTArrivalDetails';
import { getConcurPPTArrivals, getConcurPPTArrivalDetails } from '../../../sevices/CMUS/Services'
import withLoading from '../../common/withLoading';

const styles = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
})
class ConcurPPTArrivalSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displaySection: {
                level0: { visible: true, data: null, noOfPages: 0, selectedPage: 1, totalRecords: 0, selectedRecord: 0 },
                level1: { visible: false, data: null },
            }
        }
    }
    componentDidMount = async () => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        await this.getConcurPPTArrivalListInfo(this.state.displaySection.level0.selectedPage);
        await loading.setLoadindDoc(false);
    }
    getConcurPPTArrivalListInfo = async (pageSelected) => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        let concurPpt = await getConcurPPTArrivals(pageSelected);
        if (concurPpt && concurPpt.status === 200 && concurPpt.data) {
            await this.setState({
                displaySection: {
                    ...this.state.displaySection,
                    level0: {
                        visible: true,
                        data: concurPpt.data.data ? concurPpt.data.data : [],
                        noOfPages: concurPpt.data.maxResults !== 0 ? Math.ceil(concurPpt.data.totalRecords / concurPpt.data.maxResults) : 1,
                        selectedPage: pageSelected ? pageSelected : 1,
                        totalRecords: concurPpt.data.totalRecords ? concurPpt.data.totalRecords : 0
                    }
                }
            })
        }
        else {
            await this.setState({ displaySection: { ...this.state.displaySection, level0: { visible: true, data: [], noOfPages: 0, selectedPage: 0 } } })
        }
        loading.setLoadindDoc(false);
    }
    getConcurPPTArrivalDetailInfo = async (data, index) => {
        const { loading } = this.props
        loading.setLoadindDoc(true);
        const concurDetails = await getConcurPPTArrivalDetails(data)
        if (concurDetails && concurDetails.status === 200 && concurDetails.data) {
            await this.setState({
                displaySection: {
                    level0: { ...this.state.displaySection.level0, visible: false, selectedRecord: index ? index : 0 },
                    level1: {
                        visible: true, data: concurDetails.data.data ? concurDetails.data.data[0] : null
                    },
                }
            });
        }
        else {
            await this.setState({ displaySection: { level0: { ...this.state.displaySection.level0, visible: false }, level1: { visible: true, data: null } } });
        }
        loading.setLoadindDoc(false);
    }
    handleDisplayBack = async (level) => {
        switch (level) {
            case 0:
                await this.setState({
                    displaySection: {
                        level0: { ...this.state.displaySection.level0, visible: true },
                        level1: { ...this.state.displaySection.level1, visible: false }
                    }
                });
                break;
            default:
                this.setState({
                    displaySection: {
                        level0: { visible: false, data: null, noOfPages: 0, selectedPage: 1 },
                        level1: { visible: false, data: null }
                    }
                });
                break;
        }
    }

    handleDisplayForward = async (level, data, index) => {
        switch (level) {
            case 1:
                await this.getConcurPPTArrivalDetailInfo(data, index)
                break;
            default:
                this.setState({
                    displaySection: {
                        level0: { visible: false, data: null, noOfPages: 0, selectedPage: 1 },
                        level1: { visible: false, data: null }
                    }
                });
                break;
        }
    }
    handlePageClick = (pageIndex, level) => {
        if (level === 0) {
            this.getConcurPPTArrivalListInfo(pageIndex);
        }
    }
    render() {
        const { displaySection } = this.state
        return (
            <div>
                {displaySection.level0.visible ? <ConcurPPTArrivalList concurPPTArvlData={displaySection.level0.data}
                    handleDisplayForward={this.handleDisplayForward} noOfPages={displaySection.level0.noOfPages}
                    selectedPage={displaySection.level0.selectedPage} handlePageClick={this.handlePageClick} /> : null}
                {displaySection.level1.visible ? <ConcurPPTArrivalDetails handleDisplayBack={this.handleDisplayBack}
                    concurPPTArvlDetails={displaySection.level1.data} totalRecords={displaySection.level0.totalRecords}
                    selectedRecord={displaySection.level0.selectedRecord} /> : null}
            </div>
        )
    }

}

export default injectIntl(withLoading(withStyles(styles)(ConcurPPTArrivalSection)))
import React, { Component } from 'react'
import { evictShipmentsCache, widgetsMetaData } from '../../../sevices/CMUS/Services'
import DonutWidget from '../../common/DonutWidget'

export class ShipmentsByStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shipmentsbyStatusData: [],
            loading: true
        }
    }

    componentDidMount = async () => {
        const { location } = this.props;
        const Widgets = location.state ? location.state.widgets : true
        if (Widgets) {
            this.fetchWidgetData()
        }

    }

    fetchWidgetData = async () => {
        const shipments = await widgetsMetaData('pgAgency')
        await this.setState({
            shipmentsbyStatusData: shipments && shipments.pieChartWidgetDetails ? shipments.pieChartWidgetDetails : [],
            loading: false
        })
    }

    handleWidgetClick = async (event) => {
        const { history, CpError } = this.props
        let header = {}
        let data = {}
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
        header = { "PGA Status": event.data.name }
        data = { dataFor: "pgAgency", value: event.data.code }
        history.push({
            pathname: '/shipments',
            state: {
                heading: header,
                data: data,
                fromDashBoard: true
            }
        })
    }

    handleRefresh = async () => {
        await evictShipmentsCache('pgAgency');
        await evictShipmentsCache('pgAgency');
        await this.setState({
            loading: true
        })
        this.fetchWidgetData()
    }

    render() {
        const { intl } = this.props;
        const { shipmentsbyStatusData, loading } = this.state
        return (
            <DonutWidget
                heading={intl.formatMessage({ id: 'shipmentsbyStatus' })}
                data={shipmentsbyStatusData} name={intl.formatMessage({ id: 'shipmentsbyStatus' })}
                loading={loading}
                handleClick={this.handleWidgetClick}
                handleRefresh={this.handleRefresh} />
        )
    }
}

export default ShipmentsByStatus

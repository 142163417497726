import React, { Component } from 'react'
import { evictShipmentsCache, ShipmentsWidgetByStatus } from '../../../sevices/TMFF/Services';
import PieChart from '../../common/PieChart';

export class InlandShipmentWidget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shipmentsByCountryMetaData: [],
            loading: true
        }
    }
    componentDidMount = async () => {
        const { location } = this.props;
        const Widgets = location.state ? location.state.widgets : true
        if (Widgets) {
            const shipmentData = await ShipmentsWidgetByStatus('IL');
            await this.setState({
                shipmentsByCountryMetaData: shipmentData && shipmentData.pieChartWidgetDetails ? shipmentData.pieChartWidgetDetails : [],
                loading: false
            })
        }

    }
    handleShipments = (event) => {
        const { history, CpError, intl } = this.props;
        const status = event.data.name
        const shpNosConverted = event.data && event.data.shpNos ? event.data.shpNos.toString() :''
        if (CpError.trackingError !== '') {
            CpError.setTrackingError('')
        }
        let header = { [intl.formatMessage({ id: 'inlandWidget' })]: status }
        history.push({
            pathname: '/shipments',
            state: {
                data: {
                    status: status,
                    shpNos: shpNosConverted
                },
                heading: header
            }
        })
    }
    handleRefresh = async () => {
        //Need to remove one evict method for PROD
        //Using two evict for Pre prod
        evictShipmentsCache("status");
        this.setState({
            loading: true
        })
        const shippingMetaData = await ShipmentsWidgetByStatus('IL')
        this.setState({
            shipmentsByCountryMetaData: shippingMetaData && shippingMetaData.pieChartWidgetDetails ? shippingMetaData.pieChartWidgetDetails : [],
            loading: false
        })
    }

    render() {
        const { intl } = this.props;
        const { shipmentsByCountryMetaData, loading } = this.state
        return (
            <PieChart heading={intl.formatMessage({ id: 'inlandWidget' })}
                data={shipmentsByCountryMetaData}
                loading={loading}
                handleRefresh={this.handleRefresh}
                handleClick={this.handleShipments} />
        )
    }
}

export default InlandShipmentWidget

import React from 'react'
import { Grid, withStyles, IconButton, Divider, Chip } from '@material-ui/core'
import ReactToPrint from "react-to-print";
import { injectIntl } from "react-intl";
import CPButton from '../../common/Button'
import MenuList from '../../common/MenuList'
import Box from '../../common/Box'
import NonEditableDataGrid from '../../common/NonEditableDataGrid'
import DocumentTable from '../../common/DocumentTable';
import DocumentsTableMobile from '../../common/DocumentsTableMobile'
import PrintShipments from '../../common/PrintShipments'
import { getBase64 } from '../../../common/utils'
import { generateShipmentRequest, getFileFiltersData, getIsValidFileTypeandSize, getSnackBar } from '../../../common/utils'
import CPConfirmBox from '../../common/CPConfirmBox';
import withTenant from '../../common/withTenant';
import PageNumbers from '../../common/Pagination/PageNumbers'
import DetailedMileStoneData from '../TMFF/DetailedMileStoneData'
import RouteDetails from './RouteDetails'
import ItemsTable from '../../eBooking/ItemsTable';
import ContainersTable from './../TMFF/ContainersTable';
import { fetchSystemCodes, fetchFileFilters, updateShipmentDoc } from '../../../sevices/TMFF/Services'
import { ReactComponent as Copy } from '../../../images/copy.svg'
import { ReactComponent as PushPinIcon } from '../../../images/pushpin.svg'
import PrintIcon from '@material-ui/icons/Print';
import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';
import CPToolTip from '../../common/CPToolTip';

const style = ({ breakpoints, palette }) => ({
    heading: {
        fontSize: '24px',
        fontWeight: 700,
        marginLeft: '1rem'
    },
    heading1: {
        fontSize: '12px',
        fontWeight: 700,
        color: '#417F90',
        width: '-webkit-fill-available',
        marginTop: '1%'
    },
    buttonWarpper: {
        padding: '1%',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#007ad9'
    },
    noRecordsDiv: {
        display: 'flex',
        justifyContent: 'center',
        height: '165vh',
        alignItems: 'center',
        fontSize: '28px'
    },
    sectionDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'block',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    sectionButtonsMobile: {
        display: 'flow-root',
        [breakpoints.up('md')]: {
            display: 'none'
        }
    },
    sectionButtonsDesktop: {
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'center'
        }
    },
    errorDiv: {
        color: 'red',
        fontWeight: 'bold',
        marginLeft: '40%',
        textAlign: 'left'
    },
    sectionDesktopForPrint: {
        display: 'flex'
    },
    sectionMobileForPrint: {
        display: 'none'
    },
    noRecordsForPrint: {
        display: ' flex !important',
        justifyContent: 'center',
        alignItems: 'center'
    },
    myClass: {
        fontSize: '20px',
        color: '#2C697A !important'
    },
    button: {
        padding: '0 8%'
    },
    status: {
        fontSize: '12px',
        fontWeight: '500',
        color: 'white',
        margin: '0 1rem',
        backgroundColor: '#277AB5',
        height: '28px'
    },
    header: {
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#E8F0F4'
    },
    headingBox: {
        display: 'flex',
        alignItems: 'center'
    },
    pushIcon: {
        marginBottom: '0',
        marginRight: '0.5rem'
    },
    divider: {
        margin: '0.5rem 0 0.5rem 1rem',
        backgroundColor: '#979797'
    },
    proccessing: {
        margin: "0 25% 0 0",
        fontWeight: "600",
        fontSize: '20px',
        color: palette.primary.main
    },

    icon: {
        fontSize: '24px',
        color: '#2C697A'
    },
    body: {
        minHeight: '40vh',
        maxHeight: '90vh',
        overflow: 'auto',
        border: '1px solid #E5E8E8'
    }
})


class DetailedShipment extends React.Component {
    constructor(props) {
        super(props);

        const { intl,data,cpContext } = this.props
        this.headerDivToFocus = React.createRef()
        this.containerDivToFocus = React.createRef()
        this.milstoneDivToFocus = React.createRef()
        this.routeDivToFocus = React.createRef()
        this.remarkDivToFocus = React.createRef()
        this.itemDivToFocus = React.createRef()
        this.documentDivToFocus = React.createRef()
        this.pickupDivToFocus = React.createRef()

        const serReq = data?.serviceRequest ?? ""
        const bizTypeList = {"Air":["AE","AI"],"Sea":["SE","SI"],"Inland":["IL"]};
        const list = data?.bizType ? bizTypeList[data?.bizType].flatMap(x=>  
         cpContext.serviceRequests[x]
        ) :[]
        const mergedList = [...new Set(list)]
        const finShptSerReqsLabels = data?.status !== "In Progress" ? serReq?.split(",").map(value=>{
            const foundItem  = mergedList?.find(item => item?.value === value);
            return foundItem ? foundItem.label: ''
        }).filter(label =>label !== '').join(', ') || '' : (serReq?.split(",") || []);

        this.state = {
            cpSupUser: JSON.parse(sessionStorage.getItem('isCpSupUser')),
            supTrackingData:null,
            documents: [],
            uploadRequired: true,
            totalUpdatedDocs: 0,
            docTypes: [],
            uploadError: '',
            addresses: {},
            isDesktop: window.innerWidth >= 1028 ? true : false,
            headerList: [
                { title: "Job No.", field: "jobNo" },
                { title: "Booking No.", field: "bookingNo" },
                { title: "MBL/MAWB No.", field: "masterNo" },
                { title: "HBL/HAWB No.", field: "shpNo" },
                { title: "Ref No.", field: "refNo" },
                { title: "Shipper", field: "shprName" },
                { title: "Consignee", field: "csgnName" },
                { title: "Notify", field: "ntfyName"},
                { title: "Carrier", field: "carrier" },
                { title: "Mode of Transport", field: "bizType" },
                { title: "Origin", field: "origin" },
                { title: "Port of Loading/ETD", field: "polName" },
                { title: "Port of Discharge/ETA", field: "podName" },
                { title: "Destination", field: "destination" },
                { title: "Total Pieces", field: "totPcs" },
                { title: "Total Weight", field: "totGWgt" },
                { title: "Service Request", field:"serviceRequest"}
            ],
            pickupDeliveryList: [
                { title: "Collect From", field: "collectFrom" },
                { title: "Deliver To", field: "deliverTo" },
                { title: "Collection Date", field: "readyDate" },
                { title: "From/To", field: "readyTime" }],
            SupDeliveryList: [
                { title: "Collect From", field: "collectFrom" },
                { title: "Deliver To", field: "deliverTo" }],

            SupCargoReadyDate:[
                { title: "Cargo Ready Date", field: "readyDate" },
                { title: "From/To", field: "pickupReadyTime" },
                { title: "Need Date at Destination", field: "deliveryReadyDate" },
                { title: "From/To", field: "deliveryReadytime" }
            ],
            pickupData: {},
            trackingData: {...props.data, serviceRequest: finShptSerReqsLabels || []},
            supHeaderTime:{},
            fileTypes: '',
            fileSize: '10M',
            fileErrors: {
                invalidType: false,
                limitExceded: false
            },
            list: [
                { 'name': intl.formatMessage({ id: 'header' }), 'selected': 'true', 'isDisabled': false },
                { 'name': intl.formatMessage({ id: 'milestones' }), 'selected': 'false', 'isDisabled': false },
                { 'name': intl.formatMessage({ id: 'containers' }), 'selected': 'false', 'isDisabled': false },
                { 'name': intl.formatMessage({ id: 'route' }), 'selected': 'false', 'isDisabled': false },
                { 'name': intl.formatMessage({ id: 'remarkHeader' }), 'selected': 'false', 'isDisabled': false },
                { 'name': intl.formatMessage({ id: 'items' }), 'selected': 'false', 'isDisabled': false },
                { 'name': intl.formatMessage({ id: 'pickup-Delivery' }), 'selected': 'false', 'isDisabled': false },
                { 'name': intl.formatMessage({ id: 'documents' }), 'selected': 'false', 'isDisabled': false }],
            showSnackBar: false,
            snackBarMsg: '',
            snackBarType: 'info'
        }
    }
    getColourforStatus = (status) => {
        switch (status ? status.toUpperCase() : '') {
            case 'PICKUP':
            case 'PICKED UP':
            case 'ARRIVED':
            case 'DEPARTED':
                return '#277AB5'
            case "DELIVERED":
            case 'RELEASED':
                return "green"
            case 'DELAYED':
                return 'red'
            default:
                return '#277AB5';
        }
    }
    componentWillReceiveProps = (nexProps) => {
        this.setState({
            trackingData: nexProps.data
        })
        const { intl } = this.props
        if (nexProps.data.bizType === "Air") {
            this.setState({
                list: [
                    { 'name': intl.formatMessage({ id: 'header' }), 'selected': 'true', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'milestones' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'route' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'remarkHeader' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'items' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'pickup-Delivery' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'documents' }), 'selected': 'false', 'isDisabled': false }],
                headerList: [
                    { title: "Job No.", field: "jobNo" },
                    { title: "Booking No.", field: "bookingNo" },
                    { title: "MBL/MAWB No.", field: "masterNo" },
                    { title: "HBL/HAWB No.", field: "shpNo" },
                    { title: "Ref No.", field: "refNo" },
                    { title: "Shipper", field: "shprName" },
                    { title: "Consignee", field: "csgnName" },
                    { title: "Notify", field: "ntfyName"},
                    { title: "Carrier", field: "carrier" },
                    { title: 'Flight No', field: "fltNo" },
                    { title: "Mode Of Transport", field: "bizType" },
                    { title: "Origin", field: "origin" },
                    { title: "Port of Loading/ETD", field: "polName" },
                    { title: "Port of Discharge/ETA", field: "podName" },
                    { title: "Destination", field: "destination" },
                    { title: "Total Pieces", field: "totPcs" },
                    { title: "Total Weight", field: "totGWgt" },
                    { title: "Service Request", field: "serviceRequest"}]
            })
        }
        if (nexProps.data.bizType === 'Sea' || nexProps.data.bizType === 'Inland') {
            this.setState({
                list: [
                    { 'name': intl.formatMessage({ id: 'header' }), 'selected': 'true', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'milestones' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'containers' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'route' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'remarkHeader' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'items' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'pickup-Delivery' }), 'selected': 'false', 'isDisabled': false },
                    { 'name': intl.formatMessage({ id: 'documents' }), 'selected': 'false', 'isDisabled': false }],
                headerList: [
                    { title: "Job No.", field: "jobNo" },
                    { title: "Booking No.", field: "bookingNo" },
                    { title: "MBL/MAWB No.", field: "masterNo" },
                    { title: "HBL/HAWB No.", field: "shpNo" },
                    { title: "Ref No.", field: "refNo" },
                    { title: "Shipper", field: "shprName" },
                    { title: "Consignee", field: "csgnName" },
                    { title: "Notify", field: "ntfyName"},
                    { title: "Carrier", field: "carrier" },
                    { title: 'Vessel/Voyage', field: "vesselName" },
                    { title: "Mode Of Transport", field: "bizType" },
                    { title: "Origin", field: "origin" },
                    { title: "Port of Loading/ETD", field: "polName" },
                    { title: "Port of Discharge/ETA", field: "podName" },
                    { title: "Destination", field: "destination" },
                    { title: "Total Pieces", field: "totPcs" },
                    { title: "Total Weight", field: "totGWgt" },
                    { title: "Service Request", field: "serviceRequest"}]
            })
        }
    }
    componentDidMount = async () => {
        const {cpSupUser,headerList,SupCargoReadyDate,trackingData} = this.state;
        const filetereddata = await this.derivePickuporDeliveryInfo(this.state.trackingData.pickupDeliveryAddresses ? this.state.trackingData.pickupDeliveryAddresses : []);
        if(cpSupUser){
            this.setState({
                supTrackingData :[ ...headerList, ...SupCargoReadyDate],
                supHeaderTime: {
                    ...trackingData,
                    pickupReadyTime: trackingData.pickupEnabled && trackingData.pickupDeliveryInfo ? ((trackingData.pickupDeliveryInfo.readyTimeFrom ? trackingData.pickupDeliveryInfo.readyTimeFrom : '') + '-' + (trackingData.pickupDeliveryInfo.readyTimeTo ? trackingData.pickupDeliveryInfo.readyTimeTo : '')) : '', 
                    deliveryReadytime: trackingData.deliveryEnabled && trackingData.pickupDeliveryInfo ? trackingData.pickupDeliveryInfo.deliveryReadyTimeFrom + '-' + trackingData.pickupDeliveryInfo.deliveryReadyTimeTo : ''
                }
            })
        }
        else{
            this.setState({
                supTrackingData : headerList,
                supHeaderTime: {...trackingData}
            })
        }
        this.setState({
            disableButton: false
        })
        const docTypes = await fetchSystemCodes("JOBDOCTYPE")
        this.setState({
            documents: this.state.trackingData.documents ? this.state.trackingData.documents : [],
            docTypes: docTypes ? docTypes.lookupItems ? docTypes.lookupItems : [] : []
        })
        let fileFilters = await fetchFileFilters(this.state.trackingData.createOwnerId);
        const res = await getFileFiltersData(fileFilters);
        this.setState({
            fileSize: res.fileSize,
            fileTypes: res.fileTypes
        })
        this.setState({
            addresses: filetereddata,
            pickupData: trackingData.pickupEnabled ? {
                collectFrom: filetereddata.pickupCollectFrom && filetereddata.pickupCollectFrom.address ? filetereddata.pickupCollectFrom.address : '',
                deliverTo: filetereddata.pickupDeliverTo && filetereddata.pickupDeliverTo.address ? filetereddata.pickupDeliverTo.address : '',
                readyDate: trackingData.pickupDeliveryInfo && trackingData.pickupDeliveryInfo.readyDate ? trackingData.pickupDeliveryInfo.readyDate : '',
                readyTime: trackingData.pickupDeliveryInfo ? ((trackingData.pickupDeliveryInfo.readyTimeFrom ? trackingData.pickupDeliveryInfo.readyTimeFrom : '') + '-' + (trackingData.pickupDeliveryInfo.readyTimeTo ? trackingData.pickupDeliveryInfo.readyTimeTo : '')) : ''
            } : null,
            deliveryData: trackingData.deliveryEnabled ? {
                collectFrom: filetereddata.deliveryCollectFrom && filetereddata.deliveryCollectFrom.address ? filetereddata.deliveryCollectFrom.address : '',
                deliverTo: filetereddata.deliveryCollectFrom && filetereddata.deliveryDeliverTo.address ? filetereddata.deliveryDeliverTo.address : '',
                readyDate: trackingData.pickupDeliveryInfo && trackingData.pickupDeliveryInfo.deliveryReadyDate ? trackingData.pickupDeliveryInfo.deliveryReadyDate : '',
                readyTime: trackingData.pickupDeliveryInfo ? trackingData.pickupDeliveryInfo.deliveryReadyTimeFrom + '-' + trackingData.pickupDeliveryInfo.deliveryReadyTimeTo : ''
            } : null
        })
    }
    componentDidUpdate = (prevProps)=> {
        if (prevProps !== this.props) {
          const { cpSupUser, headerList, SupCargoReadyDate, trackingData } = this.state;
          if (cpSupUser) {
            this.setState({
              supTrackingData: [...headerList, ...SupCargoReadyDate],
              supHeaderTime: {
                ...trackingData,
                pickupReadyTime: trackingData.pickupEnabled && trackingData.pickupDeliveryInfo ? ((trackingData.pickupDeliveryInfo.readyTimeFrom ? trackingData.pickupDeliveryInfo.readyTimeFrom : '') + '-' + (trackingData.pickupDeliveryInfo.readyTimeTo ? trackingData.pickupDeliveryInfo.readyTimeTo : '')) : '',
                deliveryReadytime: trackingData.deliveryEnabled && trackingData.pickupDeliveryInfo ? trackingData.pickupDeliveryInfo.deliveryReadyTimeFrom + '-' + trackingData.pickupDeliveryInfo.deliveryReadyTimeTo : ''
              }
            });
          } else {
            this.setState({
              supTrackingData: headerList,
              supHeaderTime: { ...trackingData }
            });
          }
        }
      }
      
    derivePickuporDeliveryInfo = (data) => {
        return ({
            pickupCollectFrom: data && data.length > 0 ? data.find((party) => { return party.partyType === 'CL' }) : {},
            pickupDeliverTo: data && data.length > 0 ? data.find((party) => { return party.partyType === "PDT" }) : {},
            deliveryCollectFrom: data && data.length > 0 ? data.find((party) => { return party.partyType === "DCF" }) : {},
            deliveryDeliverTo: data && data.length > 0 ? data.find((party) => { return party.partyType === "DDT" }) : {}
        })
    }
    handleSave = async () => {
        const { intl } = this.props
        this.setState({
            disableButton: true,
            uploadError: ''
        })
        const shipmentReqBody = generateShipmentRequest(this.state);
        const res = await updateShipmentDoc(shipmentReqBody)
        if (res !== undefined && res !== null && (res.status === 201 || res.status === 200)) {
            this.setState({
                uploadError: ''
            })
            this.props.handleDrawer()
            this.props.handleSubmit(sessionStorage.getItem('tenant'), 'reloadFromShipment')
        }
        else {
            this.setState({
                disableButton: false,
                uploadError: intl.formatMessage({ id: 'somethingWentWrong' })
            })
        }
    }
    handleDeleteExistingDocs = (id) => {
        const { documents } = this.state
        let updatedDocuments = documents.map(document => {
            return (document.docSNo === id ? { ...document, actionCode: 'D', selected: false } : document)
        })
        this.setState({
            enableIcons: false,
            documents: updatedDocuments,
            docsDeleted: updatedDocuments.length >= 1 ? true : false
        })
    }

    handleExpandCollapseContainer = (containerIndex) => {
        const { trackingData } = this.state;
        const updatedContainerData = trackingData.containers.map((data, index) => {
            let expandedRow = false;
            if (index === containerIndex) {
                expandedRow = !data.expandedRow;
            }
            return { ...data, expandedRow: expandedRow }
        })
        let updatedTrackingData = { ...trackingData };
        updatedTrackingData.containers = updatedContainerData;
        this.setState({
            trackingData: updatedTrackingData
        })
    }
    handleMenuClick = (event) => {
        // const menuList = this.state.list
    }

    handleOnClickMenuItem = async (event) => {
        const mentList = this.state.list;
        await mentList.map((listItem) => {
            return event === listItem.name ? listItem.selected = 'true' : listItem.selected = 'false'
        })
        await this.setState({
            list: mentList
        })

        switch (event) {
            case "Header": this.headerDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            case "Milestones": this.milstoneDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            case "Route": this.routeDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            case "Containers": this.containerDivToFocus.current.scrollIntoView({
                top: 40,
                behavior: "smooth",

            })
                break;
            case "Remarks": this.remarkDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            case "Items": this.itemDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            case "Pickup/Delivery": this.pickupDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            case "Documents": this.documentDivToFocus.current.scrollIntoView({
                top: 0,
                behavior: "smooth",

            })
                break;
            default:
                break;
        }
    }

    handleEnableIconsForDocs = (event, docSNo) => {
        //Commenting code can be used later for delete and save using check box

        // const { documents } = this.state
        // let isAnyChecked = false
        // let updatedDocuments = documents.map(document => {
        //     if (document.selected) {
        //         isAnyChecked = true
        //     }
        //     return (document.externalID === docSNo ? { ...document, selected: event.target.checked } : document.selected ? { ...document, selected: true } : { ...document, selected: false })
        // })
        // if (isAnyChecked) {
        //     this.setState({
        //         enableIcons: true,
        //         documents: updatedDocuments
        //     })
        // }
        // else{
        //     this.setState({
        //         enableIcons: false,
        //         documents: updatedDocuments
        //     })
        // }

    }

    handleremoveNewDocFunc = (docSNo) => {
        const { documents, totalUpdatedDocs } = this.state
        let updatedDocuments = documents.filter((document) => {
            return document.docSNo !== docSNo;
        });
        let newtotalupdatedDocs = totalUpdatedDocs - 1
        this.setState({
            totalUpdatedDocs: newtotalupdatedDocs,
            documents: updatedDocuments,
            newFileAdded: newtotalupdatedDocs > 0 ? true : false
        })
    }
    onChangeDocumentfields = (docSNo, value) => {
        const { documents } = this.state
        let updatedDocuments = documents.map(document => {
            return document.docSNo === docSNo ? { ...document, description: value, type: value } : document
        })
        this.setState({
            documents: updatedDocuments
        })

    }
    handleConfirmClick = () => {
        this.setState({
            uploadRequired: true
        })
    }
    handleConfirmBox = () => {
        this.setState({
            uploadRequired: false
        })
    }
    handleUploadFile = async (event, from) => {
        const file = event && event.target ? event.target.files[0] : event[0];
        const { documents } = this.state
        let updateddocuments = []
        if (this.state.totalUpdatedDocs >= 5) {
            this.setState({
                uploadRequired: false
            })
        }
        else {
            this.setState({
                fileErrors: {
                    invalidType: false,
                    limitExceded: false
                }
            })
            const res = await getIsValidFileTypeandSize(file, this.state.fileSize, this.state.fileTypes)
            this.setState({
                fileErrors: {
                    invalidType: res.invType,
                    limitExceded: res.sizeExceded
                }
            })
            if (res.isValid) {
                this.setState({ newFileAdded: true, totalUpdatedDocs: this.state.totalUpdatedDocs + 1 });
                getBase64(file).then(data => {
                    if (from === 'add') {
                        let docSNo = documents[0] ? documents[0].docSNo ? documents[0].docSNo + 1 : 1 : 1
                        //  let docSNo = documents[documents.length - 1] ? documents[documents.length - 1].docSNo ? documents[documents.length - 1].docSNo + 1 : 1 : 1
                        updateddocuments = [{
                            docSNo: docSNo,
                            description: '',
                            fileName: file.name,
                            type: '',
                            uploadDate: '',
                            downloadableURL: '',
                            action: 'new',
                            selected: false,
                            actionCode: 'A',
                            enablefields: true,
                            "fileData": {
                                "text":
                                    [data]
                            }
                        },
                        ...documents]
                    }

                    this.setState({
                        documents: updateddocuments
                    })
                })
            }
        }
    }
    copyToClipBoard = async (url) => {
        const { intl, shipmentLinkData } = this.props
        await this.setState({ showSnackBar: false, snackBarMsg: '', snackBarType: 'info' })
        if (shipmentLinkData && shipmentLinkData !== '') {
            if (navigator && navigator.clipboard) {
                navigator.clipboard.writeText(url)
                const msg = intl.formatMessage({ id: 'copy_shipment_link_msg' }, { val: shipmentLinkData })
                await this.setState({ showSnackBar: true, snackBarMsg: msg })
            } else {
                let inputc = document.getElementById('detailedShipmentTMFF').parentNode.appendChild(document.createElement("input"));
                inputc.value = url
                inputc.select();
                document.execCommand('copy')
                inputc.parentNode.removeChild(inputc);
                const msg = intl.formatMessage({ id: 'copy_shipment_link_msg' }, { val: shipmentLinkData })
                await this.setState({ showSnackBar: true, snackBarMsg: msg })
            }
        } else {
            const msg = intl.formatMessage({ id: 'copy_shipment_link_error' }, { val: shipmentLinkData })
            await this.setState({ showSnackBar: true, snackBarMsg: msg, snackBarType: 'error' })
        }
    }
    getDocDownloadErrorDetails = async (msg, status) => {
        await this.setState({ showSnackBar: false })
        await this.setState({ showSnackBar: true, snackBarMsg: msg, snackBarType: status === 204 ? 'info' : 'error' })
    }
    render() {
        const { handleDrawer, classes, intl, tenantDetails, resultCount, recordIndex, handleShipmentNavigation, shipmentRetrivalLink, handlePinOrUnPin } = this.props
        const { trackingData, headerList, pickupDeliveryList, pickupData, deliveryData, list, docTypes, totalUpdatedDocs, enableIcons, documents, fileTypes, fileSize, fileErrors, showSnackBar, snackBarMsg, snackBarType,
                cpSupUser ,SupDeliveryList,supTrackingData,supHeaderTime} = this.state;
        return (
            < div id='detailedShipmentTMFF'>
                <div className={classes.header}>
                    <div className={classes.headingBox}>
                        {trackingData.createJobUnId ?
                            <>
                                <CPButton variant="secondary" id='shpPinUnpin' title={intl.formatMessage({ id: 'pinnedShipment' })} className={classes.button} onClick={() => handlePinOrUnPin(trackingData.createJobUnId, "shipment", trackingData.favoriteShp ? !trackingData.favoriteShp : true)} >
                                    {trackingData.favoriteShp ?
                                        <>
                                            <CloseIcon className={classes.pushIcon} />
                                            <span>
                                                Unpin
                                            </span>
                                        </>
                                        :
                                        <>
                                            <PushPinIcon className={classes.pushIcon} />
                                            <span>
                                                Pin
                                            </span>
                                        </>
                                    }
                                </CPButton>
                                <Divider orientation="vertical" flexItem className={classes.divider} />
                            </> : null}
                        <span className={classes.heading} > {intl.formatMessage({ id: 'shipment' })} </span>
                        {trackingData.currentStatus ? <Chip style={{ backgroundColor: this.getColourforStatus(trackingData.currentStatus) }} className={classes.status} label={trackingData.currentStatus} /> : null}
                    </div>
                    {resultCount > 1 ?
                        <div className={classes.heading1}>
                            <PageNumbers noOfPages={resultCount} selected={recordIndex} onClick={handleShipmentNavigation} message={intl.formatMessage({ id: 'shipment' })} />
                        </div>
                        : null
                    }
                    {this.state.disableButton ?
                        <span className={classes.proccessing}>{intl.formatMessage({ id: 'processing' })}</span> : null}
                    <div className={classes.sectionButtonsDesktop}>
                        {/* help section */}
                        <a href={`${window.location.protocol + '//' + window.location.host}/webHelp/cp-track-and-trace.html`} target="_blank" rel="noopener noreferrer" style={{ color: "inherit", float: "right" }}>
                            <IconButton className={classes.iconButton}
                                aria-label="account of current user"
                                aria-controls="primary-search-account-menu"
                                aria-haspopup="true"
                                color="inherit"
                            >
                                <HelpIcon className={classes.icon} style={{ color: "#6E787D" }} />
                            </IconButton>
                        </a>
                        {/* end of help section */}
                        {shipmentRetrivalLink && shipmentRetrivalLink !== '' ?
                            <CPToolTip title={intl.formatMessage({ id: 'copy_shipment_link' })} arrow>
                                <IconButton id="link" className={classes.iconButton} onClick={() => this.copyToClipBoard(shipmentRetrivalLink)}>
                                    <Copy className={classes.icon} />
                                </IconButton>
                            </CPToolTip>
                            : null}

                        <IconButton className={classes.iconButton} disabled={this.state.disableButton ? true : false}>
                            <ReactToPrint
                                trigger={() => <PrintIcon className={classes.icon} />}
                                content={() => this.componentRef}
                            />
                        </IconButton>
                        <CPButton disabled={this.state.disableButton ? true : false} variant='primary' onClick={() => handleDrawer()}>{intl.formatMessage({ id: 'close' })}</CPButton>
                        {(this.state.newFileAdded || this.state.docsDeleted) ?
                            <CPButton disabled={this.state.disableButton ? true : false} variant='primary' onClick={() => this.handleSave()}>{intl.formatMessage({ id: 'saveAndSubmit' })}</CPButton> 
                        : null}
                    </div>
                </div>
                {showSnackBar ? getSnackBar(snackBarMsg, true, snackBarType) : null}
                <Grid flex='true' container spacing={0}>
                    {
                        trackingData === '' || trackingData === undefined || trackingData === null ? "" :
                            <Grid item xs={false} sm={false} md={2} lg={2} xl={2}  >
                                <div className={classes.sectionDesktop}>
                                    <MenuList list={list} handleOnClickMenuItem={this.handleOnClickMenuItem} />
                                </div>
                            </Grid>
                    }

                    <div style={{ display: 'none' }}>
                        <PrintShipments ref={el => (this.componentRef = el)} printClasses={classes} trackingData={trackingData} headerDivToFocus={this.headerDivToFocus}
                            milstoneDivToFocus={this.milstoneDivToFocus} containerDivToFocus={this.containerDivToFocus}
                            routeDivToFocus={this.routeDivToFocus} remarkDivToFocus={this.remarkDivToFocus}
                            itemDivToFocus={this.itemDivToFocus} documentDivToFocus={this.documentDivToFocus} headerList={headerList}
                            docTypes={docTypes} tenant={tenantDetails} pickupDivToFocus={this.pickupDivToFocus} pickupDeliveryList={pickupDeliveryList} deliveryData={deliveryData} 
                            pickupData={pickupData} cpSupUser={cpSupUser} SupDeliveryList={SupDeliveryList} supTrackingData={supTrackingData} supHeaderTime={supHeaderTime}
                        />
                    </div>
                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10} className={classes.body} id="scrollGrid">
                        <CPConfirmBox show={!this.state.uploadRequired} message={intl.formatMessage({ id: 'saveDocs' })} handleRejected={() => { this.setState({ showConfirm: false }) }} handleAccepted={() => this.handleConfirmClick()} type="alert" />
                        <div className={classes.errorDiv}> {this.state.uploadError || this.state.uploadError !== '' ? this.state.uploadError : ''}</div>
                        <div className={classes.sectionButtonsMobile}>
                            <CPButton styles={{ float: 'right' }} variant='primary' disabled={this.state.disableButton ? true : false}>
                                <ReactToPrint
                                    trigger={() => <span >{intl.formatMessage({ id: 'print' })}</span>}
                                    content={() => this.componentRef}
                                />
                            </CPButton>
                            {this.state.newFileAdded ?
                                <CPButton disabled={this.state.disableButton ? true : false} styles={{ float: 'right' }} variant='primary' onClick={() => this.handleSave()}>{intl.formatMessage({ id: 'save' })}</CPButton> : null}
                            <CPButton disabled={this.state.disableButton ? true : false} styles={{ float: 'right' }} variant='secondary' onClick={() => handleDrawer()}>{intl.formatMessage({ id: 'close' })}</CPButton>
                            {/* help section */}
                            <a href={`${window.location.protocol + '//' + window.location.host}/webHelp/cp-track-and-trace.html`} target="_blank" rel="noopener noreferrer" style={{ color: "inherit", float: "right" }}>
                                <IconButton className={classes.iconButton}
                                    aria-label="account of current user"
                                    aria-controls="primary-search-account-menu"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <HelpIcon style={{ fontSize: '28px', color: "#6E787D" }} />
                                </IconButton>
                            </a>
                            {/* end of help section */}
                            {shipmentRetrivalLink && shipmentRetrivalLink !== '' ?
                                <CPToolTip title={intl.formatMessage({ id: 'copy_shipment_link' })} arrow>
                                    <IconButton id="link" style={{ padding: '5px', float: 'right' }} onClick={() => this.copyToClipBoard(shipmentRetrivalLink)}>
                                        <Copy className={classes.icon} />
                                    </IconButton>
                                </CPToolTip>
                                : null}
                        </div>
                        {
                            trackingData === '' || trackingData === undefined || trackingData === null ?
                                <div className={classes.noRecordsDiv}>
                                    <span>{intl.formatMessage({ id: 'no_data' })}</span>
                                </div>
                                :
                                <>
                                    <div ref={this.headerDivToFocus}>
                                        <Box header={intl.formatMessage({ id: 'header' })}>
                                            <NonEditableDataGrid list={supTrackingData} data={supHeaderTime} />
                                        </Box>
                                    </div>
                                    <div ref={this.milstoneDivToFocus}>
                                        <Box header={intl.formatMessage({ id: 'milestones' })}>
                                            <DetailedMileStoneData data={trackingData.statusList} type="milestones" />
                                        </Box>
                                    </div>
                                    {trackingData.bizType !== 'Air' ?
                                        <div ref={this.containerDivToFocus}>
                                            <Box header={intl.formatMessage({ id: 'containers' })}>
                                                <ContainersTable data={trackingData.containers} handleExpandCollapseContainer={this.handleExpandCollapseContainer} handlePinOrUnPin={handlePinOrUnPin} />
                                            </Box>
                                        </div>
                                        :
                                        null
                                    }
                                    <div ref={this.routeDivToFocus}>
                                        <Box header={intl.formatMessage({ id: 'route' })}>
                                            <RouteDetails data={trackingData && trackingData.jobRoutes ? trackingData.jobRoutes : []} />
                                        </Box>
                                    </div>
                                    <div ref={this.remarkDivToFocus}>
                                        <Box header={intl.formatMessage({ id: 'remarks' })}>
                                            <NonEditableDataGrid data={trackingData && trackingData.remarks ? trackingData.remarks : []} detailedShipment={true}/>
                                        </Box>
                                    </div>
                                    <div ref={this.itemDivToFocus}>
                                        <Box header={intl.formatMessage({ id: 'items' })}>
                                            <ItemsTable data={trackingData && trackingData.cargoItems ? trackingData.cargoItems : null} readOnly={true} />
                                        </Box>
                                    </div>
                                    <div ref={this.pickupDivToFocus}>
                                        <Box header={intl.formatMessage({ id: 'pickup-Delivery' })} >
                                            <NonEditableDataGrid list={cpSupUser? SupDeliveryList: pickupDeliveryList} data={pickupData} heading="Pickup" />
                                            <NonEditableDataGrid list={ cpSupUser? SupDeliveryList : pickupDeliveryList} data={deliveryData} heading="Delivery" />
                                        </Box>
                                    </div>
                                    <div className={classes.sectionDesktop} ref={this.documentDivToFocus}>
                                        <Box header={intl.formatMessage({ id: 'documents' })} showloading={true}>
                                            <DocumentTable data={documents ? documents : null} uploadRequired={true} docTypes={docTypes} fileErrors={fileErrors} fileTypes={fileTypes} fileSize={fileSize} conCloseBy={trackingData.conCloseBy}
                                                finCloseBy={trackingData.finCloseBy} readOnly={false} onUpload={this.handleUploadFile} removeNewDocFunc={this.handleremoveNewDocFunc} onChangeDocfields={this.onChangeDocumentfields} fromShipment={true} 
                                                enableIconsForDocs={this.handleEnableIconsForDocs} enableIcons={enableIcons} unId={trackingData.createJobUnId} updatedDocs={totalUpdatedDocs} handleConfirmBox={this.handleConfirmBox} 
                                                docErrorHandle={this.getDocDownloadErrorDetails} handleDeleteExistingDocs={this.handleDeleteExistingDocs} />
                                        </Box>
                                    </div>
                                    <div className={classes.sectionMobile} >
                                        <Box header={intl.formatMessage({ id: 'documents' })} showloading={true}>
                                            <DocumentsTableMobile data={documents ? documents : null} uploadRequired={true} docTypes={docTypes} fileErrors={fileErrors} fileTypes={fileTypes} fileSize={fileSize} readOnly={false} onUpload={this.handleUploadFile} removeNewDocFunc={this.handleremoveNewDocFunc} onChangeDocfields={this.onChangeDocumentfields} fromShipment={true} enableIconsForDocs={this.handleEnableIconsForDocs} enableIcons={enableIcons} unId={trackingData.createJobUnId} updatedDocs={totalUpdatedDocs} handleConfirmBox={this.handleConfirmBox} docErrorHandle={this.getDocDownloadErrorDetails} />
                                        </Box>
                                    </div>
                                </>
                        }
                    </Grid>
                </Grid>

            </div >
        )
    }
}

export default injectIntl(withTenant(withStyles(style)(DetailedShipment)))

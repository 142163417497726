import React, { useState } from "react";
import {
  withStyles,
  FormHelperText,
  useTheme,
  useMediaQuery,
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const style = theme => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
      width: "35%",
      fontWeight: "bold",
      fontSize: "14px"
    }
  },
  dropDownWrapper: {
    width: "100%",
    display: "inline-flex",
    padding: "0%",
    alignItems: 'center',
    margin: '0.5rem 2px'
  },
  dropDownWrapper1: {
    width: "100%",
    display: "inline-flex",
    alignItems: 'center'
  },
  fieldLabel: {
    display: "inline-block",
    width: "35%",
    fontWeight: "bold",
    fontSize: "14px"
  },
  fieldContent: {
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: '56%'
    }
  },
  fieldContentWOL: {
    width: "100%"
  },
  required: {
    color: "red",
    width: "2%"
  },
  paper: {
    background: 'white',
    bordeRadius: '4px',
    border: '2px solid #C8CFD2',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 20%)',
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    overflow: 'auto',
    transition: 'max-height 250ms',
    zIndex: '200',
    [theme.breakpoints.up("md")]: {
      maxWidth: "28%"
    }
  },
  option: {
    backgroundColor: "white",
    padding: "0.25rem 1.5rem",
    textIndent: "5px",
    fontWeight: "400",
    fontSize: '14px',
    textShadow: "0 0 #545F66",
    overflow: "hidden",
    textOverflow: "ellipsis",

    cursor: 'pointer',
    display: 'block',
    lineHeight: '2rem',
    minHeight: '3rem',
    paddingLeft: '1.25rem',
    userSelect: 'none',
    "&:hover": {
      backgroundColor: "#E3ECEF"
    },
    "&:checked": {
      backgroundColor: "#FEFAF0"
    }
  },
  selected: {
    backgroundColor: "#FEFAF0"
  },

});

const CpDropDown = props => {
  const [anchorEL, setanchorEL] = useState(null)
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {
    items,
    width,
    id,
    classes,
    label,
    isRequired,
    onChange,
    name,
    value,
    register,
    helperText,
    error,
    isDisabled,
    backgroundColor,
    canBlank,
    sendLabel,
    address,
    emptyMessage
  } = props;
  return (
    <div
      style={{ width: width }}
      className={label ? classes.dropDownWrapper : classes.dropDownWrapper1}
    >
      {label ? (
        <>
          
          <div className={classes.sectionDesktop}>
            <label style={{fontWeight:500}}htmlFor={name}>{label} {isRequired ? <span className={classes.required}>*</span> : ""}</label>
          </div>
        </>
      ) : null}
      <div className={label ? classes.fieldContent : classes.fieldContentWOL}>
        <FormControl style={{ width: "100%" }}>
          {isDesktop ?
            null
            :
            label !== undefined ?
              <InputLabel variant="outlined" margin='dense' id={name}>
                {label}
              </InputLabel>
              : null
          }
          <Select
            style={{ backgroundColor: backgroundColor ? `${backgroundColor}` : '' }}
            id={id ? id : name}
            labelId={name}
            disabled={isDisabled}
            onChange={onChange}
            name={name}
            variant="outlined"
            onClose={(event) => setanchorEL(null)}
            onOpen={(event) => setanchorEL(event.currentTarget)}
            value={value}
            error={error}
            IconComponent={KeyboardArrowDownIcon}
            inputRef={register}
            MenuProps={{
              anchorEl: anchorEL,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              classes: { paper: classes.paper },
              getContentAnchorEl: null
            }}
          >

            {
              (canBlank === true ?
                <MenuItem
                  className={classes.option}
                  value={address ? 'none' : ''}
                >
                  {emptyMessage ?
                    emptyMessage
                    :
                    '-- None --'}
                </MenuItem>
                : null)
            }
            {items.map((item, index) => {
              return (
                <MenuItem
                  key={address ? item.uniqueAddrCode : item.value}
                  className={classes.option}
                  value={sendLabel ? item : (address ? item.uniqueAddrCode : item.value)}
                  data-index={index}
                >
                  <span style={{ fontWeight: 'bold' }}>{address && item.addressType ? item.addressType + ': ' : ""}</span>
                  {address && item.address ? item.address : item.label}
                </MenuItem>
              );
            })}
          </Select>
          {error ? (
            <FormHelperText error={true}>{helperText}</FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </div>
    </div>
  );
};

export default withStyles(style)(CpDropDown);

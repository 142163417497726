import React, { useState, useMemo, useEffect } from 'react';
import { Route, HashRouter as Router, Switch } from 'react-router-dom'
import './App.css';
import Homepage from './components/homePage';
import Signin from './components/auth/signin';
import { initialError, ErrorProvider } from './common/error';
import PrivateRoute from './components/auth/PrivateRoute';
import { initialLoading, LoadingProvider } from './common/loading';
import withTenant from './components/common/withTenant';
import { getThemeDetails, getHost } from './sevices/common/CpServices'
import { defaultTenant } from "./common/Tenant"
import ResetPasswordRoute from './components/auth/ResetPasswordRoute';
import ResetPassword from './components/auth/ResetPassword';
import { createTheme, ThemeProvider } from '@material-ui/core';

const App = (props) => {

  const setErrormsg = (message, code) => {
    setError({ ...error, msg: message, code: code })

  }
  const setTrackingError = (msg) => {
    setError({ ...error, trackingError: msg })
  }

  const [details, setdetails] = useState({ primaryColor: '', secondaryColor: '', redirectUrl: '', printLogo: '', accessLevels: defaultTenant.accessLevels, baseSystem: '', captchaCount: '' })
  const [error, setError] = useState({ ...initialError, setErrormsg: setErrormsg, setTrackingError: setTrackingError });
  const [delayedLoading, setDelayedLoading] =useState(false);
  const setLoadingmsg = (loadingmsg) => {
    setLoading({ ...loading, loadingmsg: loadingmsg })

  }
  const setLoadindDoc = (value) => {
    setLoading({ ...loading, loadingDoc: value })
  }
  const setCustomMsg = (value) => {
    setLoading({ ...loading, customMsg: value })
  }
  const [loading, setLoading] = useState({ ...initialLoading, setLoadingmsg: setLoadingmsg, setLoadindDoc: setLoadindDoc, setCustomMsg: setCustomMsg })
  const { tenantDetails } = props
  if (details.primaryColor === '') {
    getHost().then(res => {
      //deployment
      const customData = res.data;
      //const customData= res; //local
      getThemeDetails().then(data => {
        setdetails({ ...data, ...customData })
      })
    }).catch(err => {
    })
  }

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setDelayedLoading(true);
    }, 1000);

    return () => clearTimeout(delayTimer);
  }, []);

  tenantDetails.primaryColor = details.primaryColor ? details.primaryColor : defaultTenant.primaryColor
  tenantDetails.secondaryColor = details.secondaryColor ? details.secondaryColor : defaultTenant.secondaryColor
  tenantDetails.redirectUrl = details.redirectUrl ? details.redirectUrl : defaultTenant.redirectUrl
  tenantDetails.printLogo = details.printLogo ? details.printLogo : defaultTenant.printLogo
  tenantDetails.accessLevels = details.accessLevels ? details.accessLevels : defaultTenant.accessLevels
  tenantDetails.baseSystem = details.baseSystem
  tenantDetails.version = details.version
  tenantDetails.build = details.buildDate
  tenantDetails.captchaCount = details.captchaCount ? details.captchaCount : defaultTenant.captchaCount
  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: 'open sans',
          body1: {
            fontSize: '14px'
          }
        },
        palette: {
          primary: {
            main: details.primaryColor ? details.primaryColor : '#417F90'
          },
          secondary: {
            main: details.secondaryColor ? details.secondaryColor : '#1a5178'
          },
          text: {
            primary: '#4A4E50'
          }
        }
      }),
    [details]
  );

  return (
    tenantDetails.baseSystem !== '' && tenantDetails.baseSystem !== undefined && delayedLoading  ?
      <ThemeProvider theme={theme}>
        < ErrorProvider value={error} >
          <LoadingProvider value={loading}>
            <div>
              <Router>
                <Switch>
                  {/* <Route exact path="/" component={App} /> */}
                  <Route path="/*/login" render={() => { return <Signin /> }} />
                  <ResetPasswordRoute path="/*/reset" match >
                    <ResetPassword />
                  </ResetPasswordRoute>
                  <PrivateRoute path="/" match enableRoute={true}>
                    <Homepage />
                  </PrivateRoute>
                </Switch>
              </Router>
            </div>
          </LoadingProvider>
        </ErrorProvider >
      </ThemeProvider >
      : null
  );
}

export default withTenant(App);

import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { useTheme, withStyles } from '@material-ui/core';
import { ReactComponent as LoadingIcon } from '../../../src/images/loading.svg'
import RefreshIcon from '@material-ui/icons/Refresh';
import { injectIntl } from 'react-intl';
import { generateColorGradiants } from '../../common/utils';

const style = {
    topDiv: {
        display: 'flex'
    },
    noDataDiv: {
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Open Sans Italic',
        fontStyle: 'italic'
    },
    header: {
        marginTop: '10px',
        marginLeft: '50px',
        fontWeight: '600'
    },
    refreshIcon: {
        marginTop: '10px',
        marginLeft: 'auto',
        cursor: 'pointer',
        marginRight: '8px',
        color: '#417F90'
    },
    loadingDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '25rem',
        flexDirection: 'column'
    }
}

function DonutWidget(props) {
    const { classes, data, heading, handleClick, color, loading, handleRefresh, intl, noRefresh } = props;
    const theme = useTheme()
    const primaryColor = theme.palette.primary.main
    const option = {
        animation: false,
        legend: {
            show: false
        },
        tooltip: {
            trigger: 'item',
            position: ['30%', '50%'],
            appendToBody: true,
            formatter: '{b} : {c} ',
            borderColor: '#2C697A',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            padding: 15,
            textStyle: {
                color: '#4A4E50',
                fontFamily: 'Open Sans',
                fontSize: 14
            }

        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '60%'],
                avoidLabelOverlap: true,
                clockwise: true,
                hoverOffset: 2,
                left: 'auto',
                right: 'auto',
                top: '5%',
                bottom: 'auto',
                label: {
                    show: true,
                    fontSize: '12 px',
                    color: "rgba(10, 0, 0, 1)",
                    padding: [0, 0, 0, 0],
                    ellipsis: '...',
                    overflow: 'truncate'
                },
                labelLine: {
                    show: true
                },
                data: data,
                color: color ? color : generateColorGradiants(primaryColor),
                animationTypeUpdate: 'expansion',
                animationEasingUpdate: 'cubicOut',
                animationDurationUpdate: 700,
                animationDelayUpdate: 50,
            }
        ]
    };

    const onEvents = {
        click: (event) => handleClick ? handleClick(event) : null,
    };
    return (

        <div>
            {loading ?
                <div className={classes.loadingDiv} >
                    <LoadingIcon />
                    <span>Loading...</span>
                </div> :
                <>
                    <div className={classes.topDiv} >
                        <span className={classes.header}>{heading}</span>
                        {noRefresh ? null : <RefreshIcon onClick={handleRefresh} fontSize="large" className={classes.refreshIcon} />}
                    </div>
                    {
                        data && data.length > 0 ?
                            <div >
                                <ReactEcharts onEvents={onEvents} option={option} style={{ height: "200px" }} />
                            </div>
                            :
                            <div className={classes.noDataDiv}>{intl.formatMessage({ id: 'no_data' })}</div>
                    }

                </>
            }
        </div>
    )
}

export default injectIntl(withStyles(style)(DonutWidget))

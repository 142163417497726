import React,{useState,useEffect} from 'react'
import { injectIntl } from 'react-intl';
import CpEditableTable from '../../common/CpEditableTable';
import { Link, Typography } from '@material-ui/core';
import Box from '../../common/Box';
import Cards from './Cards';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';
import CpBreadcrumb from '../../common/CpBreadcrumb';

function CommericalInvoicePGA(props) {
    const [FDAMainSectionHeader,setFDAMainSectionHeader] = useState([]);
    useEffect(()=>{
        const {pGAData} = props
        const FDACode= pGAData?.pgAgencyCd === "FDA"? [...mainSectionHeader, ...[{title:"FDA Processing Code",field:"agencyProcessingCd"}]] : mainSectionHeader;
        setFDAMainSectionHeader(FDACode);
    },[props.pGAData.pgAgencyCd])

    const mainSectionHeader = [
        { title: "Agency", field: "agencyName" },
        { title: "Program Code", field: "pgProgramCd", tooltipField: "pgProgramCdDesc" },
        { title: "Is Disclaimer", field: "isDisclaimer" },
        { title: "Disclaimer Type", field: "disclaimerTypeCd" },
        { title: "Disposition Status", field: "dispositionActionCd" },
        { title: "Disposition Message", field: "narrativeMessage" },
        { title: "Commercial Description", field: "commercialDesc" }
    ]

    const APHISCoreHeaders = [
        { title: "Processing code", field: "agencyProcessingCd", tooltipField: "agencyProcessingCdDesc" },
        { title: "Product Category", field: "productCategoryTypeCd" },
        { title: "Intended Use Base", field: "pgIntendedUseBaseCd", tooltipField: "pgIntendedUseBaseDesc" },
        { title: "Intended Use Sub", field: "pgIntendedUseSubCd", tooltipField: "pgIntendedUseDesc" },
        { title: "Intended Use Addl", field: "pgIntendedUseAddCd" },
        { title: "Genus", field: "scientificGenusName" },
        { title: "Species", field: "scientificSpeciesName" },
        { title: "Variety", field: "scientificVarietyName" },
        { title: "Common Name Specific/General", field: "commonName" }
    ]

    const packagingHeader = [
        { 'width': '50%', 'title': 'Packaging QTY', 'field': 'packagingQuantityAmt', 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': 'UOM', 'field': 'packagingUomCd', 'showLabel': true, 'type': 'label' }
    ]
    const productCodeHeader = [
        { 'width': '50%', 'title': 'Product Code Qualifier', 'field': 'productCodeQual', 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': 'Product Code ID', 'field': 'productCodeId', 'showLabel': true, 'type': 'label' }
    ]

    const CommoditiesHeader = [
        { 'width': '25%', 'title': 'Category', 'field': 'categoryCd', 'showLabel': true, 'type': 'label', 'tooltipField': 'categoryCdDesc' },
        { 'width': '25%', 'title': 'Qualifier', 'field': 'commodityQualCd', 'showLabel': true, 'type': 'label', 'tooltipField': 'commodityQualCdDesc' },
        { 'width': '25%', 'title': 'Characteristic', 'field': 'commodityCharQualCd', 'showLabel': true, 'type': 'label', 'tooltipField': 'commodityCharQualCdDesc' },
        { 'width': '25%', 'title': 'Description', 'field': 'commodityCharDescr', 'showLabel': true, 'type': 'label' }
    ]

    const PermitsHeader = [
        { 'width': '25%', 'title': 'Permit Type', 'field': 'permitTypeCode', 'showLabel': true, 'type': 'label' },
        { 'width': '25%', 'title': 'Permit No.', 'field': 'permitNumber', 'showLabel': true, 'type': 'label' },
        { 'width': '25%', 'title': 'Permit Date', 'field': 'permitDate', 'showLabel': true, 'type': 'label' },
        { 'width': '25%', 'title': 'Permit Date Type', 'field': 'permitDateType', 'showLabel': true, 'type': 'label', 'tooltipField': 'permitDateTypeDesc' }
    ]

    const APHISLaceyHeaders = [
        { title: "Description", field: "detailedDescription" },
        { title: "Line Value", field: "lineValue" }
    ]

    const componentsHeader = [
        { 'width': '20%', 'title': 'Component Name', 'field': 'componentName', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': 'Quantity/UOM', 'field': 'quantity', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': '%Recycled Material', 'field': 'percentRecycledMaterialAmt', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': 'Genus Name', 'field': 'scientificGenusName', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': 'Species Name', 'field': 'scientificSpeciesName', 'showLabel': true, 'type': 'label' }
    ]

    const ATFHeader = [
        { title: "Commodity", field: "commodityDescr" },
        { title: "Category Code", field: "categoryCd" },
        { title: "Manufacturer Name", field: "manufacturerName" },
        { title: "Manufacturer Country", field: "manufacturerCountry" },
        { title: "Model", field: "atfModel" },
        { title: "Caliber Gauge Size", field: "caliberGuageSize" },
        { title: "Barrel Length", field: "barrelLengthAmt" },
        { title: "Quantity", field: "quantityAmt" }
    ]

    const LicensesHeader = [
        { 'width': '50%', 'title': 'License Type', 'field': 'lpcoTypeCode', 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': 'License No.', 'field': 'lpcoNumber', 'showLabel': true, 'type': 'label' }
    ]

    const DTCHeader = [
        { title: "License Type", field: "licenseType", tooltipField: "licenseTypeDesc" },
        { title: "License No.", field: "licenseNo" },
        { title: "Registration No.", field: "registrationNo" },
        { title: "Temp. Exemption No.", field: "temporaryExemptionCd", tooltipField: "temporayExamptionCdDesc" }
    ]

    const DEAHeader = [
        { title: "Country", field: "country" },
        { title: "DEA Permit No.", field: "deaPermitNo" },
        { title: "DEA Registration No.", field: "deaRegistrationNo" },
        { title: "Document ID", field: "documentId" }
    ]

    const DEAComponentsHeader = [
        { 'width': '25%', 'title': 'DEA Drug Code', 'field': 'deaDrugCode', 'showLabel': true, 'type': 'label' },
        { 'width': '25%', 'title': 'Quantity', 'field': 'componentQuantityAmt', 'showLabel': true, 'type': 'label' },
        { 'width': '25%', 'title': 'UOM', 'field': 'componentUom', 'showLabel': true, 'type': 'label' }
    ]

    const EPAPesticidesHeader = [
        { title: "Intended Use Base", field: "pgIntendedUseBaseCd", tooltipField: "pgIntendedUseBaseDesc" },
        { title: "Intended Use Sub", field: "pgIntendedUseSubCd", tooltipField: "pgIntendedUseDesc" },
        { title: "Intended Use Addl", field: "pgIntendedUseAddCd" },
        { title: "Trade/Brand Name", field: "epaTradeBrandName" }
    ]

    const EPATSCAHeader = [
        { title: "Declaration Code", field: "declarationCd" }
    ]

    const EPVEHeader = [
        { title: "Document Type", field: "documentIdCd" },
        { title: "Model/Year", field: "epaModel" },
        { title: "Item ID Type/No.", field: "itemIdentity" }
    ]

    const FDAHeader = [
        { title: "Product Code", field: "productCodeNumber" },
        { title: "Product Description", field: "productDescr" },        
        { title: "Prior Notice Confirmation No.", field: "priorNoticeConfirmationNo" },
        { title: "Intended Use Base", field: "pgIntendedUseBase" },
        { title: "Intended Use Sub", field: "pgIntendedUseSub" },
        { title: "Intended Use Description", field: "pgIntendedUseBaseDesc" }
    ]

    const FDAHearAffrimation = [
        { 'width': '25%', 'title': 'Affirmation of Compliance', 'field': 'complianceCode', 'showLabel': true, 'type': 'label' },
        { 'width': '25%', 'title': 'Description', 'field': 'complianceCodeDesc', 'showLabel': true, 'type': 'label' },
        { 'width': '25%', 'title': 'Qualifier', 'field': 'complianceQualifier', 'showLabel': true, 'type': 'label' }
    ]

    const FSISHeader = [
        { title: "Product Id Type", field: "globalProductIdQual", tooltipField: "globalProductIdQualDesc" },
        { title: "Product ID", field: "globalProductId" },
        { title: "Intended Use Base", field: "pgIntendedUseBaseCd", tooltipField: "pgIntendedUseBaseDesc" },
        { title: "Intended Use Sub", field: "pgIntendedUseSubCd", tooltipField: "pgIntendedUseDesc" },
        { title: "Intended Use Addl", field: "pgIntendedUseAddCd" }
    ]

    const CountriesHeader = [
        { 'width': '50%', 'title': 'Producing Country', 'field': 'producingCountryCd', 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': 'Source Country', 'field': 'sourceCountryCd', 'showLabel': true, 'type': 'label' }
    ]

    const CertificatesHeader = [
        { 'width': '50%', 'title': 'Certificate No.', 'field': 'certificateNumber', 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': 'Issuing Country/State', 'field': 'issuerLocationCd', 'showLabel': true, 'type': 'label' }
    ]

    const FWSHeader = [
        { title: "Agency Processing Code", field: "agencyProcessingCd", tooltipField: "agencyProcessingCdDesc" },
        { title: "Intended Use Base", field: "pgIntendedUseBaseCd", tooltipField: "pgIntendedUseBaseDesc" },
        { title: "Intended Use Sub", field: "pgIntendedUseSubCd", tooltipField: "pgIntendedUseDesc" },
        { title: "Intended Use Addl", field: "pgIntendedUseAddCd" },
        { title: "Scientific Genus", field: "fwsScientificGenusName" },
        { title: "Scientific Species", field: "fwsscientificSpeciesName" },
        { title: "Commodity Net Quantity/UOM", field: "commodityNetQuantityAmt" },
        { title: "FWS Description Code", field: "fwsDescriptionCd", tooltipField: "fwsDescriptionCdDesc" },
        { title: "Species Country of Origin", field: "countryWhereBorn" },
        { title: "Common Name General", field: "commonNameGeneral" },
        { title: "Common Name Specific", field: "commonNameSpecific" }
    ]
    const LCPOPermitsHeader = [
        { 'width': '50%', 'title': 'LPCO Type', 'field': 'lpcoType', 'showLabel': true, 'type': 'label', 'tooltipField': 'lpcoTypeDesc' },
        { 'width': '50%', 'title': 'LPCO No.', 'field': 'lpcoNumber', 'showLabel': true, 'type': 'label' }
    ]

    const NHTSAHeader = [
        { title: "HS-7 Box No.", field: "boxNo" },
        { title: "Registered Importer No.", field: "registeredImporterNo" },
        { title: "Vehicle Eligibility No.", field: "vehicleEligibilityNo" },
        { title: "Category Type", field: "nhtsaCategoryTypeCd", tooltipField: "nhtsaCategoryTypeCdDesc" },
        { title: "Category", field: "nhtsaCategoryCd", tooltipField: "nhtsaCategoryCdDesc" },
        { title: "Make/Brand Name", field: "nhtsaTradeBrandName" },
        { title: "Model", field: "nhtsaModel" },
        { title: "Model Year", field: "nhtsaModelYear" },
        { title: "Month/Year Manufactured", field: "manufactureMonthYear" }
    ]
    const AMSHeader = [
        { title: "Program", field: "pgProgramCd", tooltipField: "pgProgramCdDesc" },
        { title: "Processing code", field: "agencyProcessingCd", tooltipField: "agencyProcessingCdDesc" },
        { title: "Global Product Code Type", field: "globalProductIdQual" },
        { title: "Global Product Code", field: "globalProductId" },
        { title: "Product Code Type", field: "productCodeQualifier" },
        { title: "Product Code", field: "productCodeNumber" },
        { title: "Product Code Description", field: "productCodeNumberDesc" }
    ]

    const IDCodesHeader = [
        { 'width': '25%', 'title': 'ID Type', 'field': 'itemIdentityQual', 'showLabel': true, 'type': 'label' },
        { 'width': '25%', 'title': 'ID No.', 'field': 'itemIdentityNbr', 'showLabel': true, 'type': 'label' },
        { 'width': '50%', 'title': 'Additional Item ID No.', 'field': 'additionItemIdentityNbr', 'showLabel': true, 'type': 'label' }
    ]

    const NMFSHeader = [
        { title: "Program", field: "pgProgramCd", tooltipField: "pgProgramCdDesc" },
        { title: "Processing Code", field: "agencyProcessingCd", tooltipField: "agencyProcessingCdDesc" },
        { title: "Category Type", field: "nmfsCategoryTypeCd", tooltipField: "nmfsCategoryTypeCdDesc" },
        { title: "Category", field: "categoryCd", tooltipField: "nmfsCategoryCdDesc" },
        { title: "Harvesting Country", field: "harvestingCountryCd" },
        { title: "Commodity Description", field: "commodityDesc" },
        { title: "Processing Description", field: "processingDescription" },
        { title: "Scientific Name", field: "speciesCode" }
    ]

    const NMFSPermitsHeader = [
        { 'width': '33%', 'title': 'Permit Type', 'field': 'permitTypeCdDesc', 'showLabel': true, 'type': 'label' },
        { 'width': '33%', 'title': 'Permit Use', 'field': 'permitTypeDesc', 'showLabel': true, 'type': 'label' },
        { 'width': '33%', 'title': 'Permit No.', 'field': 'permitNumber', 'showLabel': true, 'type': 'label' }
    ]
    const NMFSDocumentsHeader = [
        { 'width': '33%', 'title': 'Document Type', 'field': 'pgDocumentCd', 'showLabel': true, 'type': 'label', 'tooltipField': 'pgDocumentCdDesc' },
        { 'width': '33%', 'title': 'Declaration', 'field': 'conformanceDeclaration', 'showLabel': true, 'type': 'label' }
    ]

    const TTBHeader = [
        { title: "Program", field: "pgProgramCd", tooltipField: "pgProgramCdDesc" },
        { title: "Processing Code", field: "agencyProcessingCd", tooltipField: "agencyProcessingCdDesc" }
    ]
    const OMCHeader = [
        { title: "Box Number", field: "boxNumber", tooltipField: "boxNumberDesc" },
        { title: "Country", field: "countryCd" },
        { title: "Certification Date", field: "certSignatureDate" },
        { title: "Departure Date", field: "departureDate" }
    ]
    const CertPermitHeader = [
        { 'width': '15%', 'title': 'Certificate/Permit Type', 'field': 'certificateTypeCd', 'showLabel': true, 'type': 'label', 'tooltipField': 'certificateTypeCdDesc' },
        { 'width': '15%', 'title': 'Certificate/Permit No.', 'field': 'certificateNumber', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': 'Exemption Code', 'field': 'exemptionCd', 'showLabel': true, 'type': 'label', 'tooltipField': 'exemptionCdDesc' },
        { 'width': '15%', 'title': 'Issuer Country', 'field': ' issuerCountryCd', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': 'Consignee', 'field': 'consigneeName', 'showLabel': true, 'type': 'label' },
        { 'width': '15%', 'title': 'Consignee Address', 'field': 'consigneeAddress', 'showLabel': true, 'type': 'label' }
    ]

    const { intl, handleDisplayBack, l1totalRecords, l1selectedRecord, l2totalRecords, l2selectedRecord, l3totalRecords, l3selectedRecord, l4selectedRecord, l4totalRecords, pgaType, pGAData } = props

    return (
        <div>
            <div style={{ display: 'flex', margin: '2rem 0 0 2rem' }}>
                <CpBreadcrumb >
                    <Link color='inherit' onClick={() => handleDisplayBack(1)}> {intl.formatMessage({ id: 'commercial_invoice_item_caps' }, { val1: l1selectedRecord, val2: l1totalRecords })} </Link>
                    <Link color='inherit' onClick={() => handleDisplayBack(2)}> {intl.formatMessage({ id: 'commercial_invoice_line' }, { val1: l2selectedRecord, val2: l2totalRecords })} </Link>
                    <Link color='inherit' onClick={() => handleDisplayBack(3)}> {intl.formatMessage({ id: 'tarrif_caps' }, { val1: l3selectedRecord, val2: l3totalRecords })} </Link>
                    <Typography color='textPrimary'>{intl.formatMessage({ id: 'PGA' }, { val1: l4selectedRecord, val2: l4totalRecords })}</Typography>
                </CpBreadcrumb>
            </div>
            <Box showloading={true} header={intl.formatMessage({ id: 'PGA' }, { val1: l4selectedRecord, val2: l4totalRecords })} >
                <NonEditableDataGrid list={FDAMainSectionHeader} data={pGAData ? { ...pGAData, "agencyName": pgaType } : {}} />
                <Cards data={pGAData ? pGAData.partiesList : []} />
            </Box>
            {pgaType === 'APHIS Core' ?
                <>
                    <Box>
                        <NonEditableDataGrid list={APHISCoreHeaders} data={pGAData ? pGAData : {}} />
                        <CpEditableTable headers={packagingHeader} readOnly={true} data={pGAData && pGAData.packagingQuantityUomList ? pGAData.packagingQuantityUomList : []} />
                        <CpEditableTable headers={productCodeHeader} readOnly={true} data={pGAData && pGAData.aphProductList ? pGAData.aphProductList : []} />
                    </Box>
                    <Box header={intl.formatMessage({ id: 'commodities' })}>
                        <CpEditableTable headers={CommoditiesHeader} readOnly={true} data={pGAData && pGAData.commodityList ? pGAData.commodityList : []} />
                    </Box>
                    <Box header={intl.formatMessage({ id: 'permits' })}>
                        <CpEditableTable headers={PermitsHeader} readOnly={true} data={pGAData && pGAData.aphPermitList ? pGAData.aphPermitList : []} />
                    </Box>
                </>
                : null}

            {pgaType === 'APHIS Lacey' ?
                <>
                    <Box>
                        <NonEditableDataGrid list={APHISLaceyHeaders} data={pGAData ? pGAData : {}} />
                    </Box>
                    <Box header={intl.formatMessage({ id: 'permits' })}>
                        <CpEditableTable headers={PermitsHeader} readOnly={true} data={pGAData && pGAData.aphPermitList ? pGAData.aphPermitList : []} />
                    </Box>
                    <Box header={intl.formatMessage({ id: 'components' })}>
                        <CpEditableTable headers={componentsHeader} readOnly={true} data={pGAData && pGAData.aphComponentList ? pGAData.aphComponentList : []} />
                    </Box>
                </>
                : null
            }

            {
                pgaType === 'ATF' ?
                    <>
                        <Box>
                            <NonEditableDataGrid list={ATFHeader} data={pGAData ? pGAData : {}} />
                        </Box>
                        <Box header={intl.formatMessage({ id: 'licenses' })}>
                            <CpEditableTable headers={LicensesHeader} readOnly={true} data={pGAData && pGAData.licenseList ? pGAData.licenseList : []} />
                        </Box>
                    </>
                    : null
            }

            {
                pgaType === 'DDTC' ?
                    <Box>
                        <NonEditableDataGrid list={DTCHeader} data={pGAData ? pGAData : {}} />
                    </Box>
                    : null
            }
            {
                pgaType === 'OMC' ?
                    <Box>
                        <NonEditableDataGrid list={OMCHeader} data={pGAData ? pGAData : {}} />
                    </Box>
                    : null
            }

            {
                pgaType === 'DEA' ?
                    <>
                        <Box>
                            <NonEditableDataGrid list={DEAHeader} data={pGAData ? pGAData : {}} />
                        </Box>
                        <Box header={intl.formatMessage({ id: 'components' })}>
                            <CpEditableTable headers={DEAComponentsHeader} readOnly={true} data={pGAData && pGAData.deaComponentList ? pGAData.deaComponentList : []} />
                        </Box>
                    </>
                    : null
            }

            {
                pgaType === 'EPA Pesticides' ?
                    <Box>
                        <NonEditableDataGrid list={EPAPesticidesHeader} data={pGAData ? pGAData : {}} />
                    </Box>
                    : null
            }

            {
                pgaType === 'EPA TSCA' ?
                    <Box>
                        <NonEditableDataGrid list={EPATSCAHeader} data={pGAData ? pGAData : {}} />
                    </Box>
                    : null
            }

            {
                pgaType === 'EPA Vehicles and Engines' ?
                    <Box>
                        <NonEditableDataGrid list={EPVEHeader} data={pGAData ? pGAData : {}} />
                    </Box>
                    : null
            }

            {
                pgaType === 'FDA' ?
                <>
                    <Box>
                        <NonEditableDataGrid list={FDAHeader} data={pGAData ? pGAData : {}} />
                    </Box>
                     <Box>
                     <CpEditableTable headers={FDAHearAffrimation} readOnly={true} data={pGAData && pGAData.fdaComplianceList? pGAData.fdaComplianceList :[]} navigateIcon={false}  />
                 </Box>
                 </>
                    : null
            }

            {
                pgaType === 'FSIS' ?
                    <>
                        <Box>
                            <NonEditableDataGrid list={FSISHeader} data={pGAData ? pGAData : {}} />
                        </Box>
                        <Box header={intl.formatMessage({ id: 'countries' })}>
                            <CpEditableTable headers={CountriesHeader} readOnly={true} data={pGAData && pGAData.countryList ? pGAData.countryList : []} />
                        </Box>
                        <Box header={intl.formatMessage({ id: 'certificates' })}>
                            <CpEditableTable headers={CertificatesHeader} readOnly={true} data={pGAData && pGAData.fsisCertificateList ? pGAData.fsisCertificateList : []} />
                        </Box>
                    </>
                    : null
            }

            {
                pgaType === 'FWS' ?
                    <>
                        <Box>
                            <NonEditableDataGrid list={FWSHeader} data={pGAData ? pGAData : {}} />
                        </Box>
                        <Box header={intl.formatMessage({ id: 'lpcoPermits' })}>
                            <CpEditableTable headers={LCPOPermitsHeader} readOnly={true} data={pGAData && pGAData.lpcoPermitList ? pGAData.lpcoPermitList : []} />
                        </Box>
                    </>
                    : null
            }

            {
                pgaType === 'NHTSA' ?
                    <>
                        <Box>
                            <NonEditableDataGrid list={NHTSAHeader} data={pGAData ? pGAData : {}} />
                        </Box>
                        <Box header={intl.formatMessage({ id: 'idCodes' })}>
                            <CpEditableTable headers={IDCodesHeader} readOnly={true} data={pGAData && pGAData.idCodeList ? pGAData.idCodeList : []} />
                        </Box>
                    </>
                    : null
            }

            {
                pgaType === 'NMFS' ?
                    <>
                        <Box>
                            <NonEditableDataGrid list={NMFSHeader} data={pGAData ? pGAData : {}} />
                        </Box>
                        <Box header={intl.formatMessage({ id: 'documents' })}>
                            <CpEditableTable headers={NMFSDocumentsHeader} readOnly={true} data={pGAData && pGAData.documentList ? pGAData.documentList : []} />
                        </Box>
                        <Box header={intl.formatMessage({ id: 'permits' })}>
                            <CpEditableTable headers={NMFSPermitsHeader} readOnly={true} data={pGAData && pGAData.nmfsPermitList ? pGAData.nmfsPermitList : []} />
                        </Box>
                    </>
                    : null
            }

            {
                pgaType === 'TTB' ?
                    <>
                        <Box>
                            <NonEditableDataGrid list={TTBHeader} data={pGAData ? pGAData : {}} />
                        </Box>
                        <Box header={intl.formatMessage({ id: 'certificate_permit_Info' })}>
                            <CpEditableTable headers={CertPermitHeader} readOnly={true} data={pGAData && pGAData.ttbCertificateList ? pGAData.ttbCertificateList : []} />
                        </Box>
                    </>
                    : null
            }
            {
                pgaType === 'AMS' ?
                    <Box>
                        <NonEditableDataGrid list={AMSHeader} data={pGAData ? pGAData : {}} />
                    </Box>
                    : null
            }

        </div >
    );
}

export default injectIntl(CommericalInvoicePGA)
import React from 'react'
import CpEditableTable from '../../common/CpEditableTable';
import { Link, Typography } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Box from '../../common/Box';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';
import CpBreadcrumb from '../../common/CpBreadcrumb';

function ConvCommodity(props) {

    const { convComdityDetails, intl, handleDisplayBack, l1totalRecords, l1selectedRecord,
        l2totalRecords, l2selectedRecord, l3totalRecords, l3selectedRecord } = props;

    const commodityHeaderList = [
        { title: "Line No", field: "lineNo" },
        { title: "Part No.", field: "styleNo" },
        { title: "Assembler", field: "assembler" },
        { title: "Description", field: "descrOfGoods" },
        { title: "Additional Description", field: "additionalDescription" },
        { title: "Tariff No.", field: "tariffNo" },
        { title: "SPI 1", field: "spiPrimary" },
        { title: "SPI 2", field: "spiSecondary" },
        { title: "Manufacturer ID", field: "manufacturerId" },
        { title: "Value", field: "lineValueUsAmt" },
        { title: "Value Foreign", field: "lineValueForeignAmt" },
        { title: "Charges", field: "chargesAmt" },
        { title: "Charges Foreign", field: "chargesForeignAmt" },
        { title: "Country of Origin", field: "countryOrigin" },
        { title: "Zone Status", field: "ftzZoneStatus" },
        { title: "License/Certificate", field: "licenseCertificate" },
        { title: "License Indicator", field: "licenseInd" },
        { title: "Disclaim Prior Notice", field: "disclaimPriorNotice" },
        { title: "HMF Charges", field: "hmfCharges" }
    ]

    const quantityColumns = [
        { 'width': '100%', 'title': intl.formatMessage({ id: 'quantity' }), 'field': 'quantityUom', 'showLabel': true, 'type': 'label' }
    ]
    return (
        <div>
            <div style={{ display: 'flex', margin: '2rem 0 0 2rem' }}>
                <CpBreadcrumb >
                    <Link color='inherit' onClick={() => handleDisplayBack(1)}>{intl.formatMessage({ id: 'conveyanceSelection' }, { val1: l1selectedRecord, val2: l1totalRecords })}</Link>
                    <Link color='inherit' onClick={() => handleDisplayBack(2)}>{intl.formatMessage({ id: 'billofLadingSelection' }, { val1: l2selectedRecord, val2: l2totalRecords })}</Link>
                    <Typography color='textPrimary'>{intl.formatMessage({ id: 'commoditySelection' }, { val1: l3selectedRecord, val2: l3totalRecords })}</Typography>
                </CpBreadcrumb>
            </div>
            <Box showloading={true} header={intl.formatMessage({ id: 'commoditySelection' }, { val1: l3selectedRecord, val2: l3totalRecords })}>
                <div>
                    <NonEditableDataGrid list={commodityHeaderList} data={convComdityDetails ? convComdityDetails : {}} />
                    <CpEditableTable readOnly={true} headers={quantityColumns} data={convComdityDetails.quantityUomList ? convComdityDetails.quantityUomList : []} />
                </div>
            </Box>
        </div>
    )
}

export default injectIntl(ConvCommodity)
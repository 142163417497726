import React from 'react'
import { injectIntl } from 'react-intl'
import { withStyles, Card, CardActions, IconButton, CardContent } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    root: {
        minWidth: 275,
        width: '98%',
        margin: '1%'
    },
    cardRoot: {
        padding: '0 0 0 0 !important'
    },
    line: {
        margin: 0,
        display: 'flex'
    },
    titleNormal: {
        fontWeight: 'Normal',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important'
    },
    title: {
        fontWeight: 'Bold',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important'
    },
    value: {
        width: '50%',
        padding: '1%',
        display: 'inline-block',
        fontSize: '12px !important'
    },
    odd: {
        backgroundColor: 'rgba(237, 239, 239, 1)'
    },
    even: {
        backgroundColor: 'white'
    },
    iconButton: {
        marginLeft: '8px',
        cursor: 'pointer',
        padding: '0px',
        '&:disabled': {
            color: '#545F66',
            cursor: 'not-allowed'
        }
    },
    edit: {
        color: '#007ad9'
    },
    Delete: {
        color: 'red'
    }
})
function BookingContainerView(props) {
  
    const { data, classes, intl, readOnly, onEdit, onDelete } = props;
    const getOddorEven = (index) => {
        return index % 2 === 0 ? "even" : "odd"
    }
    return (
        <Card className={classes.root} variant="outlined">
            {
                data ?
                    data.map((container, index) => {
                        return (
                            <div key={index} style={{ display: 'flex' }}>
                                {readOnly
                                    ? null
                                    : <CardActions className={classes[getOddorEven(index)]} style={{ justifyContent: 'center', width: '20%' }}>
                                        <IconButton className={`${classes.iconButton} ${classes.Delete}`} onClick={() => onDelete(container.id)}>
                                            <DeleteIcon style={{ fontSize: '14px' }} />
                                        </IconButton>
                                        <IconButton className={`${classes.iconButton} ${classes.edit}`} onClick={() => onEdit(container.id, "CONTAINER", container)} >
                                            <EditIcon style={{ fontSize: '14px' }} />
                                        </IconButton>
                                    </CardActions>}
                                <CardContent style={{ width: readOnly ? '100%' : '80%' }} className={`${classes.cardRoot} ${classes[getOddorEven(index)]}`}>
                                    <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                        <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'type' })}</span></div>
                                        <div className={classes.value}> <span >{` ${container.type}`}</span></div>
                                    </div>
                                    <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                        <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'qty' })}</span></div>
                                        <div className={classes.value}> <span >{`${container.qty}`}</span></div>
                                    </div>

                                </CardContent>
                            </div>)
                    }) : null
            }
        </Card >
    )
}

export default injectIntl(withStyles(style)(BookingContainerView));

import React from 'react';
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';
import Box from '../../common/Box';

const style = theme => ({
    header: {
        fontSize: '14px',
        fontWeight: 'bold',
        paddingLeft: '5px',
        paddingTop: '10px',
        paddingBottom: ' 5px',
        color: "#333333",
        display: 'flex !important'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
})

function Admissions(props) {
    const { intl, headerData
    } = props;

    const headerCoulumnList = [{ title: "File No.", field: "fileNo" },
    { title: "Description of Goods", field: "descriptionOfGoods" },
    { title: "Customer", field: "custName" },
    { title: "FTZ Admission Number", field: "ftzAdmissionNumber" },
    { title: "Zone Operator Name/ID", field: "zoneOperatorId" },
    { title: "Direct Delivery", field: "directDelivery" },
    { title: "FTZ Port", field: "ftzPort" },
    { title: "Signature", field: "signature" },
    { title: "FIRMS Code", field: "firmsCode" },
    { title: "Applicant for Admission", field: "custName", tooltipField: 'custNo' }]

    return (

        <Box header={intl.formatMessage({ id: 'admissions' })}>
            <NonEditableDataGrid list={headerCoulumnList} data={headerData.header ? headerData.header : {}} />
        </Box>
    )
}

export default injectIntl(withStyles(style)(Admissions))
import React from 'react'
import withCPContext from '../common/withCPContext'
import { injectIntl } from "react-intl";
import { withStyles, CardContent, Card, CardActions, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    root: {
        minWidth: 275,
        width: '98%',
        margin: '1%'
    },
    cardRoot: {
        padding: '0 0 0 0 !important'
    },
    line: {
        margin: 0,
        display: 'flex'
    },
    titleNormal: {
        fontWeight: 'Normal',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important'
    },
    title: {
        fontWeight: 'Bold',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important'
    },
    value: {
        width: '50%',
        padding: '1%',
        display: 'inline-block',
        fontSize: '12px !important'
    },
    odd: {
        backgroundColor: 'rgba(237, 239, 239, 1)'
    },
    even: {
        backgroundColor: 'white'
    },
    iconButton: {
        marginLeft: '8px',
        cursor: 'pointer',
        padding: '0px',
        '&:disabled': {
            color: '#545F66',
            cursor: 'not-allowed'
        }
    },
    edit: {
        color: '#007ad9'
    },
    Delete: {
        color: 'red'
    },
    noRecordsFound: {
        padding: '2% !important',
        textAlign: 'center'
    }
})

const ItemsMobileView = (props) => {
    const { data, classes, intl, readOnly, onEdit, onDelete, hideDimensions } = props;
    const getOddorEven = (index) => {
        return index % 2 === 0 ? "even" : "odd"
    }
    return (
        <Card className={classes.root} variant="outlined">
            {
                data ?
                    data.map((item, index) => {
                        return (
                            <div key={index} style={{ display: 'flex' }}>
                                {item.actionCode !== 'D' ?
                                    <>
                                        {readOnly
                                            ? null
                                            :

                                            <CardActions className={classes[getOddorEven(index)]} style={{ justifyContent: 'center', width: '20%' }}>
                                                <IconButton className={`${classes.iconButton} ${classes.Delete}`} onClick={() => onDelete(item.id, item)}>
                                                    <DeleteIcon style={{ fontSize: '14px' }} />
                                                </IconButton>
                                                <IconButton className={`${classes.iconButton} ${classes.edit}`} onClick={() => onEdit(item.id ? item.id : item.itemUniqueRefNo, "ITEM", item)} >
                                                    <EditIcon style={{ fontSize: '14px' }} />
                                                </IconButton>
                                            </CardActions>}
                                        < CardContent style={{ width: readOnly ? '100%' : '80%' }} className={`${classes.cardRoot} ${classes[getOddorEven(index)]}`}>
                                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                                <div className={classes.title}> <span > {item.hasOwnProperty('description') ? item.description : ''}</span></div>
                                            </div>
                                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                                <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'pcs' })}</span></div>
                                                <div className={classes.value}>
                                                    <span >
                                                        {item.hasOwnProperty("itemPcs") && item.hasOwnProperty("itemPcsUt")
                                                            ? (item.itemPcs + ' ' + item.itemPcsUt)
                                                            : item.hasOwnProperty("itemPcs") && item.itemPcsUt === undefined
                                                                ? item.itemPcs : ''
                                                        }</span>
                                                </div>
                                            </div>
                                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                                <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'netWeight' })}</span></div>
                                                <div className={classes.value}>
                                                    <span >
                                                        {item.hasOwnProperty("itemWgt") && item.hasOwnProperty("itemWgtUt")
                                                            ? (item.itemWgt + ' ' + item.itemWgtUt)
                                                            : item.hasOwnProperty("itemWgt") && item.itemWgtUt === undefined
                                                                ? item.itemWgt : ''
                                                        }</span>
                                                </div>
                                            </div>
                                            {hideDimensions ? null :
                                                <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                                    <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'dimensions' })}</span></div>
                                                    <div className={classes.value}> <span > {`L ${item.hasOwnProperty("itemDimLength") ? item.itemDimLength : ''} W ${item.hasOwnProperty("itemDimWidth") ? item.itemDimWidth : ''} H ${item.hasOwnProperty("itemDimHeight") ? item.itemDimHeight : ''} ${item.hasOwnProperty("itemDimUnit") ? item.itemDimUnit : ''}`}</span></div>
                                                </div>
                                            }
                                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                                <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'volume' })}</span></div>
                                                <div className={classes.value}>
                                                    <span >
                                                        {item.hasOwnProperty("itemVol") && item.hasOwnProperty("itemVolUt")
                                                            ? (item.itemVol + ' ' + item.itemVolUt)
                                                            : item.hasOwnProperty("itemVol") && item.itemVolUt === undefined
                                                                ? item.itemVol : ''
                                                        }</span>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </>
                                    : null}
                            </div>)
                    }) : <CardContent className={classes.noRecordsFound}>{intl.formatMessage({ id: 'noRecordsFound' })}</CardContent>
            }
        </Card >
    )
}

export default injectIntl(withCPContext(withStyles(style)(ItemsMobileView)))

import React from 'react'
import CpEditableTable from '../../common/CpEditableTable';
import { Link, Typography } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Box from '../../common/Box';
import NonEditableDataGrid from '../../common/NonEditableDataGrid';
import CpBreadcrumb from '../../common/CpBreadcrumb';

function ConvBillofLading(props) {

    const { convBolDetails, intl, handleDisplayBack, handleDisplayForward, l1totalRecords, l1selectedRecord,
        l2totalRecords, l2selectedRecord } = props;

    const bolHeaderList = [
        { title: "BOL/AWB", field: "idSeqNo" },
        { title: "House Bill", field: "ftzHouseBill" },
        { title: "IT No.", field: "ftzItNo" },
        { title: "IT Date", field: "itDateDt" },
        { title: "IT Port", field: "itPort" },
        { title: "IT Carrier", field: "itCarrier" },
        { title: "Quantity/UOM", field: "quantityUom" },
        { title: "Export Country", field: "countryExport" },
        { title: "Port of Lading", field: "portLading" },
        { title: "PTT", field: "pttRequest" },
        { title: "PTT Carrier", field: "pttCarrier" },
        { title: "PTT Carrier IRS", field: "carrierIrsNo" },
        { title: "Unique ID", field: "pttUniqueIdentifier" }
    ]
    const containerColumns = [
        { 'width': '20%', 'title': intl.formatMessage({ id: 'search_shipments_search_containerNo' }), 'field': 'noContainer', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'dispositionCode' }), 'field': 'dispositionCode', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'pttUniqueId' }), 'field': 'pttUniqueIdentifier', 'showLabel': true, 'type': 'label' },
        { 'width': '25%', 'title': intl.formatMessage({ id: 'pttdispositionCode' }), 'field': 'pttDispositionCd', 'showLabel': true, 'type': 'label' }
    ]
    const cntCommodityColumns = [
        { 'width': '10%', 'title': intl.formatMessage({ id: 'Line No.' }), 'field': 'lineNo', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'Part No.' }), 'field': 'styleNo', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'value' }), 'field': 'lineValueUs', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'description' }), 'field': 'descrOfGoods', 'showLabel': true, 'type': 'label' },
        { 'width': '20%', 'title': intl.formatMessage({ id: 'additionalDescription' }), 'field': 'additionalDescription', 'showLabel': true, 'type': 'label' }
    ]
    return (
        <div>
            <div style={{ display: 'flex', margin: '2rem 0 0 2rem' }}>
                <CpBreadcrumb >
                    <Link color='inherit' onClick={() => handleDisplayBack(1)}>{intl.formatMessage({ id: 'conveyanceSelection' }, { val1: l1selectedRecord, val2: l1totalRecords })}</Link>
                    <Typography color='textPrimary'>{intl.formatMessage({ id: 'billofLadingSelection' }, { val1: l2selectedRecord, val2: l2totalRecords })}</Typography>
                </CpBreadcrumb>
            </div>
            <Box showloading={true} header={intl.formatMessage({ id: 'billofLadingSelection' }, { val1: l2selectedRecord, val2: l2totalRecords })}>

                <NonEditableDataGrid list={bolHeaderList} data={convBolDetails ? convBolDetails : {}} />
            </Box>
            <Box header={intl.formatMessage({ id: 'Containers' })}>
                <CpEditableTable headers={containerColumns} readOnly={true} data={(convBolDetails && convBolDetails.ftzContainersList) ? convBolDetails.ftzContainersList : []} navigateIcon={false} />
            </Box>
            <Box header={intl.formatMessage({ id: 'Commodities' })}>
                <CpEditableTable headers={cntCommodityColumns} readOnly={true} data={(convBolDetails && convBolDetails.ftzLinesList) ? convBolDetails.ftzLinesList : []} navigateIcon={true} handleDisplayForward={(row, index) => handleDisplayForward(3, row, index)} />
            </Box>
        </div>
    )
}

export default injectIntl(ConvBillofLading)
import React from 'react'
import { injectIntl } from "react-intl";
import { withStyles, CardContent, Card } from '@material-ui/core';

const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    root: {
        minWidth: 275,
        width: '98%',
        margin: '1%'
    },
    cardRoot: {
        padding: '0 0 0 0 !important',
        marginBottom: '10px !important'
    },
    line: {
        margin: 0,
        display: 'flex'
    },
    titleNormal: {
        fontWeight: 'Normal',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important'
    },
    title: {
        fontWeight: 'Bold',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '12px !important'
    },
    value: {
        width: '60%',
        display: 'inline-block',
        fontSize: '12px !important'
    },
    odd: {
        backgroundColor: 'rgba(237, 239, 239, 1)'
    },
    even: {
        backgroundColor: 'white'
    },
    noRecordsFound: {
        padding: '2% !important',
        textAlign: 'center'
    }
})

const RouteTableMobileView = (props) => {
    const { data, classes, intl } = props;
    const getOddorEven = (index) => {
        return index % 2 === 0 ? "even" : "odd"
    }
    return (

        <Card className={classes.root} variant="outlined">
            {
                data ? data.map((route, index) => {
                    return (
                        <CardContent key={index} className={`${classes.cardRoot} ${classes[getOddorEven(index)]}`}>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.title}> <span >{route.destCity}</span></div>
                                <div className={classes.title}> <span >{route.destCtry}</span></div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'expectedDepatureDateTime' })}</span></div>
                                <div className={classes.value}> <span >{route.etd}</span></div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'expectedArrivalDateTime' })}</span></div>
                                <div className={classes.value}> <span >{route.eta}</span></div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'actualDepatureDateTime' })}</span></div>
                                <div className={classes.value}> <span >{route.atd}</span></div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.titleNormal}> <span >{intl.formatMessage({ id: 'actualArrivalDateTime' })}</span></div>
                                <div className={classes.value}> <span >{route.ata}</span></div>
                            </div>
                        </CardContent>)
                }) :
                    <CardContent className={classes.noRecordsFound}>{intl.formatMessage({ id: 'noRecordsFound' })}</CardContent>
            }

        </Card>
    )
}

export default injectIntl(withStyles(style)(RouteTableMobileView))
